import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '../icons'

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

type DropDownProps = {
    selectValue?: string,
    disable?: string
    data: {
        name: string
    }[],
    previousValue?: string,
    onChangeValue?: (val: string) => void,
    onDelete?: () => void,
    onArchived?: () => void;
    onActive?: () => void;
    onSuspend?: () => void;
    onPending?:  () => void;
    channel?: boolean;
    style?: React.CSSProperties;
    disabled?: boolean,  // Added new disabled prop

}

export default function DropDown({ data, disable, onChangeValue, previousValue, onDelete, onSuspend, onArchived, onActive, onPending, channel, style, disabled }: DropDownProps) {
    const [value, setValue] = useState(previousValue ? previousValue : "")
    const isDisabled = disabled || disable === "admin"

    // Common button styles
    const buttonStyles = `
        flex w-full items-center justify-between gap-x-1.5 rounded-[0.375rem] 
        mt-[-1px] py-[8px] h-[36px] px-3 text-sm font-semibold
        ${isDisabled
            ? 'bg-slate-200 cursor-not-allowed opacity-50'
            : 'bg-white hover:border-[#a9a9bc] hover:ring-surface-300 hover:bg-white'
        }
        border-solid border-[1px] border-[#d1d1db]
        focus:outline focus:outline-2 focus:-outline-offset-2
        focus:outline-indigo-600
    `

    // Render static button when disabled
    if (isDisabled) {
        return (
            <div className={`${channel ? '' : 'relative text-left'}`}>
                <button
                    type="button"
                    disabled
                    className={buttonStyles}
                >
                    <p className='text-sm fontfamily font-[400] capitalize'>
                        {value}
                    </p>

                    <ChevronDownIcon />
                </button>
            </div>
        )
    }

    const handleItemClick = (itemName: string) => {
        setValue(itemName);
        if (onChangeValue) {
            onChangeValue(itemName);
        }
        if (itemName === "deleted" && onDelete) {
            onDelete();
        } else if (itemName === "suspended" && onSuspend) {
            onSuspend();
        } else if (itemName === "deleted" && onDelete) {
            onDelete();
        } else if (itemName === "archived" && onArchived) {
            onArchived()
        } else if (itemName === "active" && onActive) {
            onActive()
        }
        else if (itemName === "pending" && onPending) {
            onPending()
        }
    };

    return (
        <Menu as="div" className={`${channel ? '' : 'relative text-left'}`}>
            {disable === "admin" ?
                <button type="button" disabled className="capitalize w-20 flex items-center justify-between cursor-not-allowed rounded bg-slate-200 px-2 py-1 text-xs font-semibold text-white shadow-sm
             hover:bg-slate-200
             focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <p className="block text-[12px] md:text-sm lg:text-sm font-medium leading-6 text-gray-900">{value}</p>
                    <ChevronDownIcon  />
                </button>

                :
                <div>
                    <Menu.Button className={buttonStyles}>
                        <p className='text-sm fontfamily font-[400] capitalize'>
                            {value}
                        </p>
                        <ChevronDownIcon  />
                    </Menu.Button>
                </div>
            }
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items style={style} className="w-full absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {data && (<>
                            {data.map((item: { name: string }, index) => {
                                return (
                                    <Menu.Item key={index}>
                                        {({ active }) => (
                                            <button
                                                // onClick={() => {
                                                //     setValue(item.name);
                                                //     onChangeValue?.(item.name);
                                                //     if (item.name === "suspended" && onDelete) {
                                                //         onDelete(); 
                                                //     }
                                                // }}
                                                onClick={() => handleItemClick(item.name)}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900 capitalize w-full text-left' : 'text-gray-700 capitalize w-full text-left',
                                                    'block px-4 py-2 text-sm w-full text-left line-clamp-1 overflow-ellipsis'
                                                )}
                                            >
                                                {/* {item.name === "suspended" ? "Delete" : item.name} */}
                                                {item.name}
                                            </button>
                                        )}
                                    </Menu.Item>
                                )
                            })}

                        </>)}

                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
