import { CreateDocumentActionPayload, DocumentResponse } from '../features/documentItem/documentItemTypes';
import { get, post } from '../utils/api';
import { formatUrl } from '../utils/urlFormatter';


const fetchDocumentEndpoint = '/{0}/{1}/itemMember.list';
const fetchItemMemberDetails = '/{0}/{1}/itemMember.list';
const createItemMember = '/{0}/itemMember.create';
const deleteItemMember = '/{0}/{1}/itemMember.delete';

const credentialsConfig = {
  withCredentials: true,
};

  

export const fetchDocumentTemplate = async (
  baseId: string,
  id: string,
  type?: string // Make `type` optional
): Promise<DocumentResponse> => {
  const endpoint = formatUrl(fetchDocumentEndpoint, [baseId, id]);
  const urlWithParams = type ? `${endpoint}?type=${type}` : endpoint; // Append `type` only if it exists
  return get<DocumentResponse>(urlWithParams, credentialsConfig);
};

export const fetchItemMemberTemplate = async (
  baseId: string,
  id: string,
  type?: string
): Promise<DocumentResponse> => {
  const endpoint = formatUrl(fetchItemMemberDetails, [baseId, id]);
  const urlWithParams = type ? `${endpoint}?type=${type}` : endpoint;
  return get<DocumentResponse>(urlWithParams, credentialsConfig);
};


export const createItemMmeberActionTemplate = async (
  payload: CreateDocumentActionPayload
): Promise<DocumentResponse> => {
  const endpoint = formatUrl(createItemMember, [payload.baseId.toString()]);
  return post<DocumentResponse>(
    endpoint,
    payload.actionPayload,
    credentialsConfig
  );
};

export const deleteItemMemberActionTemplate = async (
  baseId: string,
  id: string
): Promise<DocumentResponse> => {
  const endpoint = formatUrl(deleteItemMember, [baseId, id]);
  return post<DocumentResponse>(endpoint, credentialsConfig);
};