import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fetchBaseData as getBaseData, createBaseData, updateBaseData, uploadBaseCoverData, uploadBaseLogoData, fetchBaseDetailsData, fetchBaseMemberData, baseMemberInvite, fetchBaseMemberInvitationStatus, deleteMemberInvitation, fetchBaseInvite, updateBaseInvitationAccept, deleteBaseInvitationReject, updateBaseMemberRole, deleteBaseMemberStatus, fetchBaseMemberData1, fetcSwitchBaseMemberData } from '../../services/baseService';
import { BaseState, BaseResponse, CreateBasePayload, ApiError, UpdateBasePayload, UploadBaseCoverPayload, UploadBaseLogoPayload, baseMemberInvitePayload, BaseInvitationStatusPayload } from './baseTypes';

export const fetchBaseData = createAsyncThunk<BaseResponse, void, { rejectValue: string }>(
  'base/fetchBaseData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getBaseData();
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch base data');
    }
  }
);

export const createBase = createAsyncThunk<BaseResponse, CreateBasePayload, { rejectValue: string }>(
  'base/createBase',
  async (payload, { rejectWithValue }) => {
    try {
      if (!payload.name) {
        return rejectWithValue('Name is required');
      }
      const response = await createBaseData(payload);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to create base');
    }
  }
);

export const updateBase = createAsyncThunk<BaseResponse, { payload: UpdateBasePayload; userId: number }, { rejectValue: string }>(
  'base/updateBase',
  async ({ payload, userId }, { rejectWithValue }) => {
    try {
      if (!payload.name) {
        return rejectWithValue('Name is required');
      }
      const response = await updateBaseData(payload, userId); // Pass userId to updateBaseData
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to update base');
    }
  }
);

export const uploadBaseCover = createAsyncThunk<BaseResponse, { payload: UploadBaseCoverPayload; userId: number }, { rejectValue: string }>(
  'base/uploadBaseCover',
  async ({ payload, userId }, { rejectWithValue }) => {
    try {
      if (!payload.image) {
        return rejectWithValue('Image is required');
      }
      const response = await uploadBaseCoverData(payload, userId);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to upload base cover');
    }
  }
);


export const uploadBaseLogo = createAsyncThunk<BaseResponse, { payload: UploadBaseLogoPayload; userId: number }, { rejectValue: string }>(
  'base/uploadBaseLogo',
  async ({ payload, userId }, { rejectWithValue }) => {
    try {
      if (!payload.image) {
        return rejectWithValue('Image is required');
      }
      const response = await uploadBaseLogoData(payload, userId);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to upload base cover');
    }
  }
);

// base details
export const baseDetailsData = createAsyncThunk<
  BaseResponse,
  number | undefined,
  { rejectValue: string }>('base/fetchBaseDetails', async (userId, { rejectWithValue }) => {
    try {
      if (userId === undefined) {
        throw new Error('User ID is undefined');
      }
      const response = await fetchBaseDetailsData(userId);
      return response;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message || 'Failed to fetch base data');
    }
  });

// // base member details
export const baseMemberDetailsData = createAsyncThunk<
  BaseResponse,
  number | undefined,
  { rejectValue: string }>('base/fetchBaseMemberDetails', async (userId, { rejectWithValue }) => {
    try {
      if (userId === undefined) {
        throw new Error('User ID is undefined');
      }
      const response = await fetchBaseMemberData(userId);
      return response;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message || 'Failed to fetch base data');
    }
  });



export const baseMemberDetailsDataStatus1 = createAsyncThunk<
  BaseResponse,
  { userId: number; status?: string },
  { rejectValue: string }
>(
  'base/fetchBaseMemberDetails1',
  async ({ userId, status }, { rejectWithValue }) => {
    try {
      const response = await fetchBaseMemberData1(userId, status);
      return response;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message || 'Failed to fetch base data');
    }
  }
);


// base member invite 
export const baseMemberInviteData = createAsyncThunk<BaseResponse, { payload: baseMemberInvitePayload; userId: number },
  { rejectValue: string }
>(
  'base/baseMemberInviteData',
  async ({ payload, userId }, { rejectWithValue }) => {
    try {
      const response = await baseMemberInvite(payload, userId);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to invite member');
    }
  }
);

// base invitation status

export const baseInvitationStatusData = createAsyncThunk<
  BaseResponse,
  number | undefined,
  { rejectValue: string }>('base/fetchInvitationStatus', async (baseId, { rejectWithValue }) => {
    try {
      if (baseId === undefined) {
        throw new Error('User ID is undefined');
      }
      const response = await fetchBaseMemberInvitationStatus(baseId);
      return response;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message || 'Failed to fetch base data');
    }
  });


// base member invitation remove 
export const baseMemberRemove = createAsyncThunk<BaseResponse, { baseId: number, invitationId: number }, { rejectValue: string }>(
  'base/baseMemberRemove',
  async ({ baseId, invitationId }, { rejectWithValue }) => {
    try {
      const response = await deleteMemberInvitation(baseId, invitationId);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to remove member');
    }
  }
);

// fetch invitation
export const fetchBaseInviteData = createAsyncThunk<BaseResponse, void, { rejectValue: string }>(
  'base/fetchInviteData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchBaseInvite();
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch base data');
    }
  }
);

// accept invite 
export const acceptBaseInvite = createAsyncThunk<BaseResponse, number, { rejectValue: string }>(
  'base/acceptInvite',
  async (baseId, { rejectWithValue }) => {
    try {
      const response = await updateBaseInvitationAccept(baseId);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to accept invitation');
    }
  }
);

// reject invite 
export const rejectBaseInvite = createAsyncThunk<BaseResponse, number, { rejectValue: string }>(
  'base/rejectInvite',
  async (baseId, { rejectWithValue }) => {
    try {
      const response = await deleteBaseInvitationReject(baseId);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to accept invitation');
    }
  }
);

// basemember role update 
export const updateBaseMemberRoleChnage = createAsyncThunk<BaseResponse, { baseId: number, userId: number, roleId: string }, { rejectValue: string }>(
  'base/updateBaseMemberRole',
  async ({ baseId, userId, roleId }, { rejectWithValue }) => {
    try {
      // Pass individual arguments: baseId, userId, and roleId (role)
      const response = await updateBaseMemberRole(baseId, userId, roleId);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to update base member role');
    }
  }
);

// delete base member status action
export const deleteBaseMemberStatusData = createAsyncThunk<BaseResponse, { baseId: number, memberId: number, status: string }, { rejectValue: string }>(
  'base/deleteBaseMemberStatus',
  async ({ baseId, memberId, status }, { rejectWithValue }) => {
    try {
      const response = await deleteBaseMemberStatus(baseId, memberId, status);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to delete base member');
    }
  }
);

// switch base
export const baseSwitchMemberDetailsData = createAsyncThunk<
  BaseResponse,
  number | undefined,
  { rejectValue: string }>('base/fetchSwichBaseMemberDetails', async (userId, { rejectWithValue }) => {
    try {
      if (userId === undefined) {
        throw new Error('User ID is undefined');
      }
      const response = await fetcSwitchBaseMemberData(userId);
      return response;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message || 'Failed to fetch base data');
    }
  });



const initialState: BaseState = {
  data: null,
  status: 'idle',
  error: null,
  hasFetchedData: false,
  createStatus: 'idle',
  createError: null,
  updateStatus: 'idle',
  updateError: null,
  uploadStatus: 'idle',
  uploadError: null,
  uploadLogoStatus: 'idle',
  uploadLogoError: null,
  baseDetails: null,
  baseDetailsStatus: 'idle',
  baseDetailsError: null,
  baseMemberDetails: null,
  baseMemberStatus: 'idle',
  baseMemberError: null,
  baseMemberInviteStatus: 'idle',
  baseMemberInviteError: null,
  baseMemberInviteData: null,
  baseInvitationStatusDetails: null,
  baseInvitationStatus: 'idle',
  baseInvitationStatusError: null,
  baseMemberRemoveStatus: 'idle',
  baseMemberRemoveError: null,
  baseMemberRemoveData: null,
  dataInvite: null,
  statusInvite: 'idle',
  errorInvite: null,
  hasFetchedDataInvite: false,
  baseInvitationAccept: null,
  baseInvitationAcceptStatus: 'idle',
  baseInvitationAcceptStatusError: null,
  baseInvitationReject: null,
  baseInvitationRejectStatus: 'idle',
  baseInvitationRejectStatusError: null,
  baseMemberRoleUpdate: null,
  baseMemberRoleUpdateStatus: 'idle',
  baseMemberRoleUpdateError: null,
  // delete base mmeber status
  baseMemberDeleteStatus: 'idle',
  baseMemberDeleteError: null,
  baseMemberDeleteData: null,
  baseMemberDetails1: null,
  baseMemberStatus1: 'idle',
  baseMemberError1: null,
  baseSwitchMemberDetails: null,
  baseSwitchMemberStatus: 'idle',
  baseSwitchMemberError: null,

};

const baseSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBaseData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBaseData.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.hasFetchedData = true;
      })
      .addCase(fetchBaseData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Something went wrong';
      })
      .addCase(createBase.pending, (state) => {
        state.createStatus = 'loading';
      })
      .addCase(createBase.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.createStatus = 'succeeded';
        state.data = action.payload;
      })
      .addCase(createBase.rejected, (state, action) => {
        state.createStatus = 'failed';
        state.createError = action.payload || 'Failed to create base';
      })
      .addCase(updateBase.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateBase.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.updateStatus = 'succeeded';
        state.data = action.payload;
      })
      .addCase(updateBase.rejected, (state, action) => {
        state.updateStatus = 'failed';
        state.updateError = action.payload || 'Failed to update base';
      })
      .addCase(uploadBaseCover.pending, (state) => {
        state.uploadStatus = 'loading';
      })
      .addCase(uploadBaseCover.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.uploadStatus = 'succeeded';
      })
      .addCase(uploadBaseCover.rejected, (state, action) => {
        state.uploadStatus = 'failed';
        state.uploadError = action.payload || 'Upload failed';
      })
      .addCase(uploadBaseLogo.pending, (state) => {
        state.uploadLogoStatus = 'loading';
      })
      .addCase(uploadBaseLogo.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.uploadLogoStatus = 'succeeded';
      })
      .addCase(uploadBaseLogo.rejected, (state, action) => {
        state.uploadLogoStatus = 'failed';
        state.uploadLogoError = action.payload || 'Upload failed';
      })
      .addCase(baseDetailsData.pending, (state) => {
        state.baseDetailsStatus = 'loading';
      })
      .addCase(baseDetailsData.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.baseDetailsStatus = 'succeeded';
        state.baseDetails = action.payload.data.base;
      })
      .addCase(baseDetailsData.rejected, (state, action) => {
        state.baseDetailsStatus = 'failed';
        state.baseDetailsError = action.payload || 'Failed to fetch base details';
      })
      .addCase(baseMemberDetailsData.pending, (state) => {
        state.baseMemberStatus = 'loading';
      })
      .addCase(baseMemberDetailsData.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.baseMemberStatus = 'succeeded';
        state.baseMemberDetails = action.payload.data.base;
        localStorage.setItem('baseMembersDetails', JSON.stringify(action.payload));
      })
      .addCase(baseMemberDetailsData.rejected, (state, action) => {
        state.baseMemberStatus = 'failed';
        state.baseMemberError = action.payload || 'Failed to fetch base details';
      })
      .addCase(baseMemberInviteData.pending, (state) => {
        state.baseMemberInviteStatus = 'loading';
      })
      .addCase(baseMemberInviteData.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.baseMemberInviteStatus = 'succeeded';
        state.baseMemberInviteData = action.payload;
      })
      .addCase(baseMemberInviteData.rejected, (state, action) => {
        state.baseMemberInviteStatus = 'failed';
        state.baseMemberInviteError = action.payload || 'Failed to update base';
      })
      .addCase(baseInvitationStatusData.pending, (state) => {
        state.baseInvitationStatus = 'loading';
      })
      .addCase(baseInvitationStatusData.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.baseInvitationStatus = 'succeeded';
        state.baseInvitationStatusDetails = action.payload.data.base;
      })
      .addCase(baseInvitationStatusData.rejected, (state, action) => {
        state.baseInvitationStatus = 'failed';
        state.baseInvitationStatusError = action.payload || 'Failed to update base';
      })
      .addCase(baseMemberRemove.pending, (state) => {
        state.baseMemberRemoveStatus = 'loading';
      })
      .addCase(baseMemberRemove.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.baseMemberRemoveStatus = 'succeeded';
        state.baseMemberRemoveData = action.payload;
      })
      .addCase(baseMemberRemove.rejected, (state, action) => {
        state.baseMemberRemoveStatus = 'failed';
        state.baseMemberRemoveError = action.payload || 'Failed to update base';
      })
      .addCase(fetchBaseInviteData.pending, (state) => {
        state.statusInvite = 'loading';
      })
      .addCase(fetchBaseInviteData.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.statusInvite = 'succeeded';
        state.dataInvite = action.payload;
        state.hasFetchedDataInvite = true;
      })
      .addCase(fetchBaseInviteData.rejected, (state, action) => {
        state.statusInvite = 'failed';
        state.errorInvite = action.payload || 'Something went wrong';
      })
      .addCase(acceptBaseInvite.pending, (state) => {
        state.baseInvitationAcceptStatus = 'loading';
      })
      .addCase(acceptBaseInvite.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.baseInvitationAcceptStatus = 'succeeded';
        state.baseInvitationAccept = action.payload.data.base;
      })
      .addCase(acceptBaseInvite.rejected, (state, action) => {
        state.baseInvitationAcceptStatus = 'failed';
        state.baseInvitationAcceptStatusError = action.payload || 'Failed to update base';
      })
      .addCase(rejectBaseInvite.pending, (state) => {
        state.baseInvitationRejectStatus = 'loading';
      })
      .addCase(rejectBaseInvite.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.baseInvitationRejectStatus = 'succeeded';
        state.baseInvitationReject = action.payload.data.base;
      })
      .addCase(rejectBaseInvite.rejected, (state, action) => {
        state.baseInvitationRejectStatus = 'failed';
        state.baseInvitationRejectStatusError = action.payload || 'Failed to update base';
      })
      .addCase(updateBaseMemberRoleChnage.pending, (state) => {
        state.baseMemberRoleUpdateStatus = 'loading';
      })
      .addCase(updateBaseMemberRoleChnage.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.baseMemberRoleUpdateStatus = 'succeeded';
        state.baseMemberRoleUpdate = action.payload.data.base;
      })
      .addCase(updateBaseMemberRoleChnage.rejected, (state, action) => {
        state.baseMemberRoleUpdateStatus = 'failed';
        state.baseMemberRoleUpdateError = action.payload || 'Failed to update base';
      })
      .addCase(deleteBaseMemberStatusData.pending, (state) => {
        state.baseMemberDeleteStatus = 'loading';
      })
      .addCase(deleteBaseMemberStatusData.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.baseMemberDeleteStatus = 'succeeded';
        state.baseMemberDeleteData = action.payload.data.base;
      })
      .addCase(deleteBaseMemberStatusData.rejected, (state, action) => {
        state.baseMemberDeleteStatus = 'failed';
        state.baseMemberDeleteError = action.payload || 'Failed to update base';
      })
      .addCase(baseMemberDetailsDataStatus1.pending, (state) => {
        state.baseMemberStatus1 = 'loading';
      })
      .addCase(baseMemberDetailsDataStatus1.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.baseMemberStatus1 = 'succeeded';
        state.baseMemberDetails1 = action.payload.data.base;
        // localStorage.setItem('baseMembersDetails', JSON.stringify(action.payload));
      })
      .addCase(baseMemberDetailsDataStatus1.rejected, (state, action) => {
        state.baseMemberStatus1 = 'failed';
        state.baseMemberError1 = action.payload || 'Failed to fetch base details';
      })
      .addCase(baseSwitchMemberDetailsData.pending, (state) => {
        state.baseSwitchMemberStatus = 'loading';
      })
      .addCase(baseSwitchMemberDetailsData.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
        state.baseSwitchMemberStatus = 'succeeded';
        state.baseSwitchMemberDetails = action.payload.data.base;
        // localStorage.setItem('baseMembersDetails', JSON.stringify(action.payload));
      })
      .addCase(baseSwitchMemberDetailsData.rejected, (state, action) => {
        state.baseSwitchMemberStatus = 'failed';
        state.baseSwitchMemberError = action.payload || 'Failed to fetch base details';
      })
      
  }
});

export default baseSlice.reducer;