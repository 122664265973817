//@ts-nocheck
import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { CrossIcon } from '../icons/Icons'
import { useAppDispatch } from '../../app/hooks'
import { toast } from 'sonner'
import { fetchDocumentAction, updateActionStatusDocument } from '../../features/document/documentSlice'
import { useParams } from 'react-router-dom'

function PendingAction({ isAsideOpen1, setIsAsideOpen1, documentDetailsSave, actionWithFilter, setActionWithFilter }: any) {
    const [approveStatus, setApproveStatus] = useState(false)
    const [rejectStatus, setRejectStatus] = useState(false)
    const [acceptStatus, setAcceptStatus] = useState(false)
    const [rejectAcceptStatus, setRejectAcceptStatus] = useState(false)
    const [stateApproveLoading, setStateApproveLoading] = useState(false)
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();
    const { baseId } = useParams<{ baseId: string }>();
    const [stateRejectLoading, setStateRejectLoading] = useState(false)

    const handleApprove = () => {
        setApproveStatus(true)
    }

    const handleReject = () => {
        setRejectStatus(true)
    }

    const handleAccept = () => {
        setAcceptStatus(true)
    }

    const handleAcceptReject = () => {
        setRejectAcceptStatus(true)
    }

    const handleInputOpen = () => {
        setIsAsideOpen1(!isAsideOpen1)
    }

    const handleSignInput = () => {
        setIsAsideOpen1(!isAsideOpen1)
    }

    // Filter actions for "approval" when document status is "draft"
    const approvalAction = documentDetailsSave.doc_status === "draft"
        ? actionWithFilter?.data?.approval?.find((action: any) => action.primary_action === "approval")
        : null;


    const handleActionStatusApprove = async () => {
        setStateApproveLoading(true);
        try {
            const payload = {
                id: approvalAction?.id,
                primary_action_status: 'complete',
                baseId: baseId, // Replace `someBaseId` with the actual value
            };

            const result = await dispatch(updateActionStatusDocument(payload)).unwrap();
            toast.success('Action status update successfully');
            setApproveStatus(false);
            const fetchResponse = await dispatch(
                fetchDocumentAction({ baseId, id, type: "true" }) // Pass "true" as type
            ).unwrap();
            setActionWithFilter(fetchResponse);
        } catch (error) {
            console.error('Failed to save document:', error);
            toast.error('Failed to save action');
        } finally {
            setStateApproveLoading(false);
        }
    };


    // reject 
    const handleApproveReject = async() => {
        setStateRejectLoading(true);
        try {
            const payload = {
                id: approvalAction?.id,
                primary_action_status: 'reject',
                baseId: baseId, // Replace `someBaseId` with the actual value
            };

            const result = await dispatch(updateActionStatusDocument(payload)).unwrap();
            toast.success('Action status reject');
            const fetchResponse = await dispatch(
                fetchDocumentAction({ baseId, id, type: "true" }) // Pass "true" as type
            ).unwrap();
            setActionWithFilter(fetchResponse);
            setRejectStatus(false)
        } catch (error) {
            console.error('Failed to save document:', error);
            toast.error('Failed to reject action');
        } finally {
            setStateRejectLoading(false);
        }
    }

    return (
        <>
            <div className=' mx-[-24px] min-w-[348px] w-full px-[1rem]'>

                {/* approve */}
                {
                    approvalAction &&

                    <div>
                        <h2 className='font-medium text-dark-black-100 mr-1 text-[13px] mt-4'>
                            Pending Action
                        </h2>
                        <a
                            href="#"
                            className=" mt-4 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 
                font-medium text-[0.8125rem]  focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                        >
                            <span className="flex mr-[6px] h-[8px] w-[8px] shrink-0 items-center justify-center rounded-[20px] 
                border-none bg-[#15803d]" />
                            Approval

                        </a>
                        <p className='font-normal text-dark-black-200 text-[12px] py-1'>Approve this document</p>
                        <div className='flex items-center gap-x-2 mt-2'>
                            <button
                                onClick={handleApprove}
                                className='group inline-flex px-2 shrink-0 select-none items-center transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 
                focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8px py-1 rounded-lg text-white outline-primary disabled:opacity-50 bg-indigo-600
                 hover:bg-indigo-500 focus-visible:outline-indigo-600 justify-start text-[13px] font-medium'>
                                Approve
                            </button>
                            <button
                                onClick={handleReject}
                                className='group inline-flex px-2 shrink-0 select-none items-center transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 
                focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8px py-1 rounded-lg text-white outline-primary disabled:opacity-50 bg-red-600
                 hover:bg-red-500 focus-visible:outline-red-600 justify-start text-[13px] font-medium'>
                                Reject
                            </button>
                        </div>
                        <Dialog open={approveStatus} onClose={setApproveStatus} className="relative z-10">
                            <DialogBackdrop
                                transition
                                className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                            />

                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <DialogPanel
                                        transition
                                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                                    >
                                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                            <button
                                                type="button"
                                                onClick={() => setApproveStatus(false)}
                                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                <span className="sr-only">Close</span>
                                                <CrossIcon />
                                            </button>
                                        </div>


                                        <div className="mt-3 pb-2">
                                            <p className="text-sm text-gray-500">
                                                Are you sure you want to approve this?
                                            </p>
                                        </div>

                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                            <button
                                                type="button"
                                                onClick={handleActionStatusApprove}
                                                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                                            >
                                                {stateApproveLoading ? (
                                                    <div className='flex items-center gap-[6px]'>
                                                        Approve
                                                        <div className="simple-spinner">
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    'Approve'
                                                )}

                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => setApproveStatus(false)}
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </DialogPanel>
                                </div>
                            </div>
                        </Dialog>

                        <Dialog open={rejectStatus} onClose={setRejectStatus} className="relative z-10">
                            <DialogBackdrop
                                transition
                                className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                            />

                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <DialogPanel
                                        transition
                                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                                    >
                                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                            <button
                                                type="button"
                                                onClick={() => setRejectStatus(false)}
                                                
                                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                <span className="sr-only">Close</span>
                                                <CrossIcon />
                                            </button>
                                        </div>


                                        <div className="mt-3 pb-2">
                                            <p className="text-sm text-gray-500">
                                                Are you sure you want to reject this?
                                            </p>
                                        </div>

                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                            <button
                                                type="button"
                                                onClick={handleApproveReject}
                                                className="inline-flex w-full justify-center rounded-md bg-red-600 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                            >
                                                
                                                {stateRejectLoading ? (
                                                    <div className='flex items-center gap-[6px]'>
                                                        Reject
                                                        <div className="simple-spinner">
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    'Reject'
                                                )}
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => setRejectStatus(false)}
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </DialogPanel>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                }


                {/* accept */}
                {/* <div>
                    <a
                        href="#"
                        className=" mt-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 
                font-medium text-[0.8125rem]  focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                    >
                        <span className="flex mr-[6px] h-[8px] w-[8px] shrink-0 items-center justify-center rounded-[20px] 
                border-none bg-[#15803d]" />
                        Accept

                    </a>
                    <p className='font-normal text-dark-black-200 text-[12px] py-1'>Approve this document</p>
                    <div className='flex items-center gap-x-2 mt-2'>
                        <button
                            onClick={handleAccept}
                            className='group inline-flex px-2 shrink-0 select-none items-center transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 
                focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8px py-1 rounded-lg text-white outline-primary disabled:opacity-50 bg-indigo-600
                 hover:bg-indigo-500 focus-visible:outline-indigo-600 justify-start text-[13px] font-medium'>
                            Approve
                        </button>
                        <button
                            onClick={handleAcceptReject}
                            className='group inline-flex px-2 shrink-0 select-none items-center transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 
                focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8px py-1 rounded-lg text-white outline-primary disabled:opacity-50 bg-red-600
                 hover:bg-red-500 focus-visible:outline-red-600 justify-start text-[13px] font-medium'>
                            Reject
                        </button>
                    </div>
                    <Dialog open={acceptStatus} onClose={setAcceptStatus} className="relative z-10">
                        <DialogBackdrop
                            transition
                            className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                        />

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <DialogPanel
                                    transition
                                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                                >
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            onClick={() => setAcceptStatus(false)}
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            <span className="sr-only">Close</span>
                                            <CrossIcon />
                                        </button>
                                    </div>


                                    <div className="mt-3 pb-2">
                                        <p className="text-sm text-gray-500">
                                            Are you sure you want to accept this?
                                        </p>
                                    </div>

                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            onClick={() => setAcceptStatus(false)}
                                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                                        >
                                            Approve
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => setAcceptStatus(false)}
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </DialogPanel>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog open={rejectAcceptStatus} onClose={setRejectAcceptStatus} className="relative z-10">
                        <DialogBackdrop
                            transition
                            className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                        />

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <DialogPanel
                                    transition
                                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                                >
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            onClick={() => setRejectAcceptStatus(false)}
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            <span className="sr-only">Close</span>
                                            <CrossIcon />
                                        </button>
                                    </div>


                                    <div className="mt-3 pb-2">
                                        <p className="text-sm text-gray-500">
                                            Are you sure you want to reject this?
                                        </p>
                                    </div>

                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            onClick={() => setRejectAcceptStatus(false)}
                                            className="inline-flex w-full justify-center rounded-md bg-red-600 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                        >
                                            Reject
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => setRejectStatus(false)}
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </DialogPanel>
                            </div>
                        </div>
                    </Dialog>
                </div> */}


                {/* fill */}
                {/* <div>
                    <a
                        href="#"
                        className=" mt-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 
                font-medium text-[0.8125rem]  focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                    >
                        <span className="flex mr-[6px] h-[8px] w-[8px] shrink-0 items-center justify-center rounded-[20px] 
                border-none bg-[#d7d7d7]" />
                        Fill

                    </a>
                    <p className='font-normal text-dark-black-200 text-[12px] py-1'>Complete this document</p>
                    <div className='flex items-center gap-x-2 mt-2'>
                        <button
                            onClick={handleInputOpen}
                            className='group inline-flex px-2 shrink-0 select-none items-center transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 
                focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8px py-1 rounded-lg text-white outline-primary disabled:opacity-50 bg-indigo-600
                 hover:bg-indigo-500 focus-visible:outline-indigo-600 justify-start text-[13px] font-medium'>
                            Complete
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-narrow-right transition-opacity duration-150 hidden group-hover:block ml-1 w-[1rem] h-[1rem] text-[#fff]"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M5 12l14 0" />
                                <path d="M15 16l4 -4" />
                                <path d="M15 8l4 4" />
                            </svg>
                        </button>

                    </div>


                </div> */}

                {/* send */}
                {/* <div>
                    <a
                        href="#"
                        className=" mt-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 
                font-medium text-[0.8125rem]  focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                    >
                        <span className="flex mr-[6px] h-[8px] w-[8px] shrink-0 items-center justify-center rounded-[20px] 
                border-none bg-[#311010]" />
                        Send

                    </a>
                    <p className='font-normal text-dark-black-200 text-[12px] py-1'>Complete this document</p>
                    <div className='flex items-center gap-x-2 mt-2'>
                        <button
                            onClick={handleSignInput}
                            className='group inline-flex px-2 shrink-0 select-none items-center transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 
    focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 py-1 rounded-lg text-white outline-primary disabled:opacity-50 bg-indigo-600
    hover:bg-indigo-500 focus-visible:outline-indigo-600 justify-start text-[13px] font-medium'>
                            Complete
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-narrow-right transition-opacity duration-150 hidden group-hover:block ml-1 w-[1rem] h-[1rem] text-[#fff]"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M5 12l14 0" />
                                <path d="M15 16l4 -4" />
                                <path d="M15 8l4 4" />
                            </svg>
                        </button>


                    </div>


                </div> */}

            </div>




        </>
    )
}

export default PendingAction