// src/services/baseService.ts
import { ChangePassword, UpdateUserProfilePayload, UploadUserLogoPayload, UserResponse, UserTwoStep } from '../features/users/userTypes';
import {  del, get, post, put } from '../utils/api';
import { formatUrl } from '../utils/urlFormatter';

// Endpoint paths
const upload_user_logo = '/profile/pic';
const user_details = '/profile';
const update_user_profile = '/profile/update';
const two_step_verification = '/profile/two/step';
const user_change_password = '/profile/change/password';
const user_session = '/auth/sessions';
const logout_userSession = "/auth/logoutDevice/{0}"
const delete_all_user_session = "/auth/session";

const credentialsConfig = {
  withCredentials: true, 
};

export const uploadUserLogoData = async (data: UploadUserLogoPayload, userId: number): Promise<UserResponse> => {
  const update_base_url = formatUrl(upload_user_logo, [userId]);
  const formData = new FormData();
  formData.append('image', data.image);
  return post<UserResponse>(update_base_url, formData, {
    ...credentialsConfig,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const fetchUserDetails = async (): Promise<UserResponse> => {
  return get<UserResponse>(user_details, credentialsConfig);
};

export const updateUserProfileData = async (data: UpdateUserProfilePayload, userId: number): Promise<UserResponse> => {
  const update_base_url = formatUrl(update_user_profile, [userId]);
  return put<UserResponse>(update_base_url, data, credentialsConfig);
};


export const UserTwoStepVerification = async (data: boolean): Promise<UserResponse> => {
  const update_two_step_verification_url = two_step_verification; // No need to use formatUrl
  return put<UserResponse>(update_two_step_verification_url, { two_step: data }, credentialsConfig);
};

export const UserChangePassword = async (data: ChangePassword): Promise<UserResponse> => {
  const update_password = user_change_password; // No need to use formatUrl
  return put<UserResponse>(update_password, data , credentialsConfig);
};

export const fetchUserSession = async (): Promise<UserResponse> => {
  return get<UserResponse>(user_session, credentialsConfig);
};

export const logoutUserSession = async (sessionId: number): Promise<UserResponse> => {
  const formattedUrl = formatUrl(logout_userSession, [sessionId]);
  return del<UserResponse>(formattedUrl, credentialsConfig);
};

export const logoutAllUserSession = async (): Promise<UserResponse> => {
  return del<UserResponse>(delete_all_user_session, credentialsConfig); // No sessionId needed
};
