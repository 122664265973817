import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ChangePassword, UpdateUserProfilePayload, UploadUserLogoPayload, UserResponse, UserState, UserTwoStep } from './userTypes';
import { ApiError } from '../base/baseTypes';
import { UserChangePassword, UserTwoStepVerification, fetchUserDetails, fetchUserSession, logoutAllUserSession, logoutUserSession, updateUserProfileData, uploadUserLogoData } from '../../services/userServices';





export const uploadUserLogo = createAsyncThunk<UserResponse, { payload: UploadUserLogoPayload; userId: number }, { rejectValue: string }>(
  'base/uploadBaseLogo',
  async ({ payload, userId }, { rejectWithValue }) => {
    try {
      if (!payload.image) {
        return rejectWithValue('Image is required');
      }
      const response = await uploadUserLogoData(payload, userId);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to upload base cover');
    }
  }
);

export const fetchUserData = createAsyncThunk<UserResponse, void, { rejectValue: string }>(
  'base/fetchBaseData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchUserDetails();
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch base data');
    }
  }
);


export const updateUserProfile = createAsyncThunk<UserResponse, { payload: UpdateUserProfilePayload; userId: number }, { rejectValue: string }>(
  'base/updateUser',
  async ({ payload, userId }, { rejectWithValue }) => {
    try {
      const response = await updateUserProfileData(payload, userId); 
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to update base');
    }
  }
);

// Async thunk action
export const updateUserTwoStep = createAsyncThunk<UserResponse, boolean, { rejectValue: string }>(
  'base/userTwoStep',
  async (twoAuthValue, { rejectWithValue }) => {
    try {
      const response = await UserTwoStepVerification(twoAuthValue); 
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to update base');
    }
  }
);


// update password 
export const updateUserPassword = createAsyncThunk<UserResponse, ChangePassword, { rejectValue: string }>(
  'base/userUpdatePassword',
  async (payload: ChangePassword, { rejectWithValue }) => {
    try {
      const response = await UserChangePassword(payload); // Pass the payload directly
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to update password');
    }
  }
);


// user session 
export const fetchUserSessionData = createAsyncThunk<UserResponse, void, { rejectValue: string }>(
  'base/fetchUserSession',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchUserSession();
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch base data');
    }
  }
);

// user delete session 
export const deleteUserSessionData = createAsyncThunk<
  UserResponse, 
  { sessionId: number }, 
  { rejectValue: string }
>(
  'base/deleteUserSession',
  async ({ sessionId }, { rejectWithValue }) => {
    try {
      const response = await logoutUserSession(sessionId); // Pass sessionId to the API
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to delete session');
    }
  }
);

// logout all user sessions
export const deleteUserAllSessionData = createAsyncThunk<
  UserResponse,
  void, // No parameters needed
  { rejectValue: string }
>(
  'base/deleteUserAllSession',
  async (_, { rejectWithValue }) => {
    try {
      const response = await logoutAllUserSession(); // Call API without sessionId
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to delete all sessions');
    }
  }
);


const initialState: UserState = {
  data: null,
  status: 'idle',
  error: null,
  hasFetchedData: false,
  uploadLogoStatus: 'idle',
  uploadLogoError: null,
  updateStatus: 'idle',
  updateError: null,
  twoStep: false,
  twoStepError: null,
  passwordStatus: 'idle',
  passwordError: null,
  passwordSuccess: false,
  session: null,
  sessionStatus: 'idle',
  sessionError: null,
  deleteSessionStatus: 'idle',
  deleteSessionError: null,
  deleteAllSessionStatus: 'idle',
  deleteAllSessionError: null,



};

const userSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadUserLogo.pending, (state) => {
        state.uploadLogoStatus = 'loading'; 
      })
      .addCase(uploadUserLogo.fulfilled, (state, action: PayloadAction<UserResponse>) => {
        state.uploadLogoStatus = 'succeeded';
      })
      .addCase(uploadUserLogo.rejected, (state, action) => {
        state.uploadLogoStatus = 'failed';
        state.uploadLogoError = action.payload || 'Upload failed'; 
      })
      .addCase(fetchUserData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserData.fulfilled, (state, action: PayloadAction<UserResponse>) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.hasFetchedData = true;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Something went wrong';
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.updateStatus = 'loading'; 
      })
      .addCase(updateUserProfile.fulfilled, (state, action: PayloadAction<UserResponse>) => {
        state.updateStatus = 'succeeded';
        state.data = action.payload;  
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.updateStatus = 'failed';
        state.updateError = action.payload || 'Failed to update base';
      })
      .addCase(updateUserTwoStep.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateUserTwoStep.fulfilled, (state, action: PayloadAction<UserResponse>) => {
        state.updateStatus = 'succeeded';
        state.twoStep = action.payload.twoStepVerification;
      })
      .addCase(updateUserTwoStep.rejected, (state, action) => {
        state.updateStatus = 'failed';
        state.twoStepError = action.payload || 'Failed to update base';
      })
      .addCase(updateUserPassword.pending, (state) => {
        state.passwordStatus = 'loading';
      })
      .addCase(updateUserPassword.fulfilled, (state, action: PayloadAction<UserResponse>) => {
        state.passwordStatus = 'succeeded';
        state.passwordSuccess = action.payload.passwordUpdated;
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.passwordStatus = 'failed';
        state.passwordError = action.payload || 'Failed to update base';
      })
      .addCase(fetchUserSessionData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserSessionData.fulfilled, (state, action: PayloadAction<UserResponse>) => {
        state.status = 'succeeded';
        state.session = action.payload;
      })
      .addCase(fetchUserSessionData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Something went wrong';
      })
      .addCase(deleteUserSessionData.pending, (state) => {
        state.deleteSessionStatus = 'loading';
      })
      .addCase(deleteUserSessionData.fulfilled, (state, action: PayloadAction<UserResponse>) => {
        state.deleteSessionStatus = 'succeeded';
      })
      .addCase(deleteUserSessionData.rejected, (state, action) => {
        state.deleteSessionStatus = 'failed';
        state.deleteSessionError = action.payload || 'Failed to delete session';
      })
      .addCase(deleteUserAllSessionData.pending, (state) => {
        state.deleteAllSessionStatus = 'loading';
      })
      .addCase(deleteUserAllSessionData.fulfilled, (state, action: PayloadAction<UserResponse>) => {
        state.deleteAllSessionStatus = 'succeeded';
      })
      .addCase(deleteUserAllSessionData.rejected, (state, action) => {
        state.deleteAllSessionStatus = 'failed';
        state.deleteAllSessionError = action.payload || 'Failed to delete all sessions';
      });
    
  }
});

export default userSlice.reducer;