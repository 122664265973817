// @ts-nocheck
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom';
import { Toaster, toast } from 'sonner';
import { UserDetails } from '../../auth/authTypes';
import { useAppDispatch } from '../../../app/hooks';
import { deleteTeams, fetchBoardsList } from '../../teams/teamsSlice';
import CreateTeams from './CreateTeams';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { ExclamationIcon, SearchIcon, ThreeDotIcon } from '../../../components/icons';
import Badge from '../../../components/common/Badge';

type ChannelProps = {
  userDetails: UserDetails | null;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const Teams: React.FC<ChannelProps> = React.memo(({ }) => {
  const baseIdString = localStorage.getItem('UserBaseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const navigate = useNavigate()
  const [searchVal, setSearchVal] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editBoardData, setEditBoardData] = useState<{ id: number; baseId: number } | null>(null);
  const [boardsFetched, setBoardsFetched] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [dataLoading, setDataLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false)

  // // In Teams component
  // useEffect(() => {
  //   const fetchTeams = async () => {
  //     setDataLoading(true);
  //     try {
  //       const baseResponse = await dispatch(fetchBoardsList(baseId));
  //       if (baseResponse?.payload) {

  //         setBoardsFetched(baseResponse.payload.data);
  //         localStorage.setItem('baseTeamsData', JSON.stringify(baseResponse.payload));
  //       } else {
  //         setBoardsFetched([]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching teams:", error);
  //       setBoardsFetched([]);
  //     } finally {
  //       setDataLoading(false);
  //     }
  //   };
  //   if (baseId) {
  //     fetchTeams();
  //   }
  // }, [baseId, dispatch]);

  // In Teams component
  useEffect(() => {
    const teamInfoString = localStorage.getItem('baseTeamsData');
    if (teamInfoString) {
      const teamInfo = JSON.parse(teamInfoString);
      setBoardsFetched(teamInfo.data);
      setDataLoading(false);
    } else {
      const fetchTeams = async () => {
        setDataLoading(true);
        try {
          const baseResponse = await dispatch(fetchBoardsList(baseId));

          if (baseResponse?.payload) {
            setBoardsFetched(baseResponse.payload.data);
            localStorage.setItem('baseTeamsData', JSON.stringify(baseResponse.payload));
          } else {
            setBoardsFetched([]);
          }
        } catch (error) {
          console.error("Error fetching teams:", error);
          setBoardsFetched([]);
        } finally {
          setDataLoading(false);
        }
      };

      if (baseId) {
        fetchTeams();
      }
    }
  }, [baseId, dispatch]);



  // Add a new useEffect to handle updates from localStorage
  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'baseTeamsData' && e.newValue) {
        const teamsDataArray = JSON.parse(e.newValue);
        setBoardsFetched(teamsDataArray.data || []);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);


  // const handleDeleteBoard = useCallback(async (id: number, baseId: number) => {
  //   try {
  //     await dispatch(deleteTeams({ id, userId: baseId }));
  //     toast.success('Item deleted successfully!');
  //     dispatch(fetchBoardsList(baseId));
  //   } catch (error) {
  //     console.error("Error deleting item:", error);
  //     toast.error('Error deleting item!');
  //   }
  // }, [dispatch]);

  const handleDeleteBoard = useCallback(async (id: number, baseId: number) => {
    try {
      await dispatch(deleteTeams({ id, userId: baseId }));
      // Update local state to reflect deletion
      setBoardsFetched(prevBoards => prevBoards.filter(board => board.id !== id));
      // Update local storage if you're keeping data there
      localStorage.setItem('baseTeamsData', JSON.stringify({ data: boardsFetched }));
      toast.success('Item deleted successfully!');
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error('Error deleting item!');
    }
  }, [dispatch, boardsFetched]);




  const handleEdit = useCallback((id: number, baseId: number) => {
    const teamToEdit = boardsFetched.find(team => team.id === id);
    if (teamToEdit) {
      setEditBoardData(teamToEdit);
      setIsModalOpen(true);
    }
  }, [boardsFetched]);

  // const openModal = () => setIsModalOpen(true);
  // In Teams component
  const openModal = () => {
    setEditBoardData(null);  // Add this line
    setIsModalOpen(true);
  };

  const handleTeamsData = (id) => {
    navigate(`/${baseId}/settings/channel/${id}/detail`);
  }

  return (
    <>
      <Toaster richColors />
      <div className='h-full'>
        <main className='py-[34px] lg:py-[70px] px-0 lg:px-10 h-full flex'>
          <div className='px-[2px] sm:px-6 lg:px-8 w-full flex justify-center'>
            <div className='w-full max-w-[640px]'>
              <div className='flex flex-col gap-y-[6px]'>
                <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>Base Channel</h2>
                <p className='text-sm leading-6 text-[#5c5c5e] font-medium'>
                  Base Channel Description
                </p>
              </div>
              <div className='mt-6 lg:mt-10 pb-10'>
                <div className=' mt-6 lg:mt-10 flex items-center  w-full gap-x-3 mb-5'>
                  <div className='w-full lg:w-[40%]'>
                    <div className="relative items-center w-full">
                      <input
                        type="text"
                        value={searchVal}
                        onChange={(e) => setSearchVal(e.target.value)}
                        name="search"
                        id="search"
                        placeholder='Search name and email'
                        className="flex w-full grow border pl-8 px-4 py-2 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
                      focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                      border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                      />
                      <div className="absolute inset-y-0 left-0 flex py-1.5 pr-1.5">

                        <SearchIcon />

                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-[60%]'>
                    <div className='flex justify-end'>
                      <button
                        onClick={openModal}
                        type='button'
                        className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Create Channel
                      </button>
                    </div>
                  </div>

                </div>

                {dataLoading ? (
                  <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 191px)' }}>
                    <div className="simple-spinner-loader">
                      <span></span>
                    </div>
                  </div>
                ) : boardsFetched.length === 0 ? (
                  <div className='w-full mt-5 flex h-[300px] justify-center items-center flex-col gap-y-3'>
                    <ExclamationIcon />
                    <p className='text-[#0000007a] text-[13px]'>No results found</p>
                  </div>
                ) : (
                  <>
                    {boardsFetched
                      .filter((post: { name: String }) => {
                        if (searchVal === "") {
                          return post;
                        } else if (post.name.toLowerCase().includes(searchVal.toLowerCase())) {
                          return post;
                        }
                      })
                      .map((items: any, index: number) => {

                        return (
                          <div key={index} className='w-full flex border-b border-light-gray-200'>
                            <ul className='list-none w-full'>
                              <li className='flex flex-row items-center justify-between text-[0.875rem] px-[0rem] py-4 w-full'>
                                <div className='flex shrink-0 pr-2'>
                                  <span
                                    className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize"
                                    style={{ backgroundColor: items?.color }}
                                  >
                                    {items.name[0]}
                                  </span>
                                </div>
                                <div className='flex-1 px-4' onClick={() => handleTeamsData(items?.id)}>
                                  <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b] capitalize'>{items.name}</p>
                                  <p className='text-[0.8125rem] font-normal leading-4 text-[#5c5c5e] capitalize'>{items?.description}</p>
                                </div>
                                <div>
                                  <Badge status={items?.privacy} />

                                  {items.channel_status === "active" ? null : (
                                    <span className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs mx-2 ring-1 ring-inset ring-gray-500/10 text-red-600 border-red-600 capitalize font-[500]'>
                                      {items?.channel_status}
                                    </span>
                                  )}
                                </div>
                                <div className='px-2 flex items-center'>
                                  <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                      <MenuButton className="rounded-md flex items-center justify-center hover:bg-light-gray-200 focus:bg-light-gray-200 w-[32px] h-[32px]">
                                        <span className="sr-only">Open options</span>
                                        <ThreeDotIcon />
                                      </MenuButton>
                                    </div>

                                    <Transition
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                          <MenuItem>
                                            {({ focus }) => (
                                              <div
                                                onClick={() => handleEdit(items.id, items.base_id)}
                                                className={classNames(
                                                  focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm'
                                                )}
                                              >
                                                Edit
                                              </div>
                                            )}
                                          </MenuItem>
                                          <MenuItem>
                                            {({ focus }) => (
                                              <Link
                                                to="#"
                                                className={classNames(
                                                  focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm'
                                                )}
                                                onClick={() => handleDeleteBoard(items.id, items.base_id)}
                                              >
                                                Delete
                                              </Link>
                                            )}
                                          </MenuItem>
                                          <MenuItem>
                                            {({ focus }) => (
                                              <Link
                                                to="#"
                                                className={classNames(
                                                  focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm'
                                                )}
                                              >
                                                Suspended
                                              </Link>
                                            )}
                                          </MenuItem>
                                          <MenuItem>
                                            {({ focus }) => (
                                              <Link
                                                to="#"
                                                className={classNames(
                                                  focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm'
                                                )}
                                              >
                                                Archived
                                              </Link>
                                            )}
                                          </MenuItem>
                                        </div>
                                      </MenuItems>
                                    </Transition>
                                  </Menu>
                                </div>
                              </li>
                            </ul>
                          </div>
                        );
                      })}
                  </>
                )}
              </div>

            </div>
          </div>
        </main>
      </div>

      <CreateTeams
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        editBoardData={editBoardData}
        baseId={baseId}
        setEditBoardData={setEditBoardData}
      />
    </>
  )
});
export default Teams
