// @ts-nocheck

import React, { Fragment, useEffect, useRef, useState } from 'react';
// import { _base_update, _uploadBaseCover, _uploadBaseLogo, fetchBaseDetails } from './baseAPI';
import { Toaster, toast } from 'sonner';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { Dialog, Transition } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { baseDetailsData, updateBase, uploadBaseCover, uploadBaseLogo } from '../../base/baseSlice';
import { UpdateBasePayload } from '../../base/baseTypes';

interface BaseDetailsProps {
  userDetails: UserDetails;
}


const BaseDetails: React.FC<BaseDetailsProps> = React.memo(({ userDetails }) => {

  const [name, setName] = useState('');
  const [data, setData] = useState<any>();
  const [showAlert, setShowAlert] = useState(false);
  const baseInfo = useAppSelector((state: RootState) => state.base.baseDetails);
  const [file, setFile] = useState<File | null>(null);
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [cropSrc, setCropSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    width: 250,
    height: 250,
    x: 0,
    y: 0,
    aspect: 1,
  });
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [fileCover, setFileCover] = useState<File | null>(null);
  const [logoUrlCover, setLogoUrlCover] = useState<string>('');
  const [loadingCover, setLoadingCover] = useState<boolean>(false);
  const [cropSrcCover, setCropSrcCover] = useState<string | null>(null);
  const [cropCover, setCropCover] = useState<Crop>({
    unit: 'px',
    width: 720,
    height: 350,
    x: 0,
    y: 0,
    aspect: 16 / 9,
  });
  const [completedCropCover, setCompletedCropCover] = useState<PixelCrop | null>(null);
  const [showCropModalCover, setShowCropModalCover] = useState(false);
  const imageRefCover = useRef<HTMLImageElement | null>(null);
  const canvasRefCover = useRef<HTMLCanvasElement | null>(null);
  const [logoLoading, setLogoLoading] = useState(true);
  const [coverLoading, setCoverLoading] = useState(true);
  const [description, setDescription] = useState('')
  const dispatch = useAppDispatch();
  const baseIdString = localStorage.getItem('UserBaseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [baseLoading, setBaseLoading] = useState(false)


  useEffect(() => {
    const baseInfoString = localStorage.getItem('baseDetails');
    if (baseInfoString) {
      const baseInfo = JSON.parse(baseInfoString);
      setName(baseInfo?.data?.base?.name);
    } else  {
      setName(baseInfo.name);
    }

  }, [baseInfo]);

  useEffect(() => {
    const baseInfoString = localStorage.getItem('baseDetails');
    if (baseInfoString) {
      const baseInfo = JSON.parse(baseInfoString);
      setDescription(baseInfo?.data?.base?.description);
    } else  {
      setDescription(baseInfo.description);
    }
 
  }, [baseInfo]);


  useEffect(() => {
    if (baseId) {
      dispatch(baseDetailsData(baseId));
    }
  }, [baseId, dispatch]);


  useEffect(() => {
    const baseInfoString = localStorage.getItem('baseDetails');
    if (baseInfoString) {
      const baseInfo = JSON.parse(baseInfoString);  
      if (baseInfo?.data?.base?.logo) {
        setLogoLoading(true);
        const newLogoUrl = `${process.env.REACT_APP_API_BASE_URL}/${baseInfo?.data?.base?.logo}`;
        setLogoUrl(newLogoUrl);
  
        // Preload the image
        const img = new Image();
        img.onload = () => {
          setLogoLoading(false);
        };
        img.onerror = () => {
          setLogoLoading(false);
          setLogoUrl(''); // Reset to no logo if load fails
        };
        img.src = newLogoUrl;
      } else {
        setLogoUrl('');
        setLogoLoading(false);
      }
    } else {
      setLogoUrl('');
      setLogoLoading(false);
    }
  }, []);




  useEffect(() => {
    const baseInfoString = localStorage.getItem('baseDetails');
    if (baseInfoString) {
      const baseInfo = JSON.parse(baseInfoString);  
      if (baseInfo?.data?.base?.cover_image) {
        setCoverLoading(true);
        const newCoverUrl = `${process.env.REACT_APP_API_BASE_URL}/${baseInfo?.data?.base?.cover_image}`;
        setLogoUrlCover(newCoverUrl);
  
        // Preload the image
        const img = new Image();
        img.onload = () => {
          setCoverLoading(false);
        };
        img.onerror = () => {
          setCoverLoading(false);
          setLogoUrlCover(''); 
        };
        img.src = newCoverUrl;
      } else {
        setLogoUrlCover('');
        setCoverLoading(false);
      }
    } else {
      setLogoUrlCover('');
      setCoverLoading(false);
    }
  }, []);
  


  useEffect(() => {
    const baseInfoString = localStorage.getItem('baseDetails');
    if (baseInfoString) {
      const baseInfo = JSON.parse(baseInfoString);
      setName(baseInfo?.data?.base?.name);
      localStorage.setItem('name', baseInfo.name || '');
    }
  }, []);


  const _updateHandler = async () => {
    setBaseLoading(true)
    const payload: UpdateBasePayload = {
      name,
      description
    };

    const userId = baseId;
    const resultAction = await dispatch(updateBase({ payload, userId }));
    setBaseLoading(false)
    if (updateBase.fulfilled.match(resultAction)) {
      localStorage.setItem('baseDetails', JSON.stringify(resultAction.payload));
      setData(resultAction.payload);
      setShowAlert(true);
      toast.success('Base updated successfully!');
    } else {
      // Handle the error
      const errorMessage = resultAction.payload as string || 'Failed to update base';
      setShowAlert(true);
      toast.error(errorMessage);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setName(newName);
    localStorage.setItem('name', newName);
  }

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDescription = e.target.value;
    setDescription(newDescription);
    localStorage.setItem('base_description', newDescription);
  }



  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      setCropSrc(URL.createObjectURL(selectedFile));
      setShowCropModal(true);
    }
  };

  const handleFileChangeCover = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFileCover(selectedFile);
      setCropSrcCover(URL.createObjectURL(selectedFile));
      setShowCropModalCover(true);
    }
  };


  const onImageLoaded = (event: React.SyntheticEvent<HTMLImageElement>) => {
    imageRef.current = event.currentTarget;
    setCrop(centerCrop(makeAspectCrop({ unit: 'px', width: 250, aspect: 1 }, 1, imageRef.current.width, imageRef.current.height)));

  };

  const onImageLoadedCover = (event: React.SyntheticEvent<HTMLImageElement>) => {
    imageRefCover.current = event.currentTarget;
    setCropCover(centerCrop(makeAspectCrop({ unit: 'px', width: 720, aspect: 16 / 9 }, 16 / 9, imageRefCover.current.width, imageRefCover.current.height)));

  };

  const getCroppedImageCover = (): Promise<File | null> => {

    if (!completedCropCover || !imageRefCover.current || !canvasRefCover.current || !fileCover) {
      return Promise.resolve(null);
    }

    const image = imageRefCover.current;
    const canvas = canvasRefCover.current;
    const crop = completedCropCover;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return Promise.resolve(null);
    }

    canvas.width = 720;
    canvas.height = 350;

    return new Promise((resolve) => {
      if (image.complete) {
        drawCanvas();
      } else {
        image.onload = drawCanvas;
      }

      function drawCanvas() {
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          720,
          350
        );

        canvas.toBlob((blob) => {
          if (!blob) {
            resolve(null);
            return;
          }
          const croppedFile = new File([blob], fileCover.name, { type: fileCover.type });
          resolve(croppedFile);
        },
          fileCover.type,
          1
        );
      }
    });
  };



  const getCroppedImage = (): Promise<File | null> => {

    if (!completedCrop || !imageRef.current || !canvasRef.current || !file) {
      return Promise.resolve(null);
    }

    const image = imageRef.current;
    const canvas = canvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return Promise.resolve(null);
    }

    canvas.width = 250; // Set canvas size to 150x150 for final output
    canvas.height = 250;

    return new Promise((resolve) => {
      if (image.complete) {
        drawCanvas();
      } else {
        image.onload = drawCanvas;
      }

      function drawCanvas() {
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          250,
          250
        );

        canvas.toBlob((blob) => {
          if (!blob) {
            resolve(null);
            return;
          }
          const croppedFile = new File([blob], file.name, { type: file.type });
          resolve(croppedFile);
        }, file.type,
          1.0
        );
      }
    });
  };

  const handleCropSave = async () => {
    if (!completedCrop) {
      toast.error('Please complete the crop before saving.');
      return;
    }

    setLogoLoading(true);
    try {
      const croppedFile = await getCroppedImage();
      if (!croppedFile) {
        throw new Error("Cropping failed or file is null.");
      }
      const result = await dispatch(uploadBaseLogo({
        payload: { image: croppedFile },
        userId: baseId
      }));

      if (uploadBaseLogo.fulfilled.match(result)) {
        toast.success('Upload successful!');
        const newLogoUrl = `${process.env.REACT_APP_API_BASE_URL}/${result.payload.data.base.logo}`;
        setLogoUrl(newLogoUrl);        
        localStorage.setItem('baseDetails', JSON.stringify(result.payload));

        // Preload the new image
        const img = new Image();
        img.onload = () => {
          setLogoLoading(false);
        };
        img.onerror = () => {
          setLogoLoading(false);
          toast.error('Failed image to upload');
        };
        img.src = newLogoUrl;
      } else {
        throw new Error(result.payload || 'Upload failed');
      }
    } catch (error) {
      console.error('Error during API call:', error);
      toast.error(error.message || 'Upload failed. Please try again.');
      setLogoLoading(false);
    } finally {
      setShowCropModal(false);
    }
  };

  const handleCropSaveCover = async () => {
    if (!completedCropCover) {
      toast.error('Please complete the crop before saving.');
      return;
    }

    setCoverLoading(true);
    try {
      const croppedFile = await getCroppedImageCover();
      if (!croppedFile) {
        throw new Error("Cropping failed or file is null.");
      }

      const result = await dispatch(uploadBaseCover({
        payload: { image: croppedFile },
        userId: baseId
      }));

      if (uploadBaseCover.fulfilled.match(result)) {
        toast.success('Upload successful!');
        const newCoverUrl = `${process.env.REACT_APP_API_BASE_URL}/${result.payload?.data?.base?.cover_image}`;
        setLogoUrlCover(newCoverUrl);
        localStorage.setItem('baseDetails', JSON.stringify(result.payload));

        // Preload the new image
        const img = new Image();
        img.onload = () => {
          setCoverLoading(false);
        };
        img.onerror = () => {
          setCoverLoading(false);
          toast.error('Failed image to upload');
        };
        img.src = newCoverUrl;

      } else {
        throw new Error(result.payload || 'Upload failed');
      }
    } catch (error) {
      console.error('Error during API call:', error);
      toast.error(error.message || 'Upload failed. Please try again.');
      setCoverLoading(false);
    } finally {
      setShowCropModalCover(false);
    }
  };

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  return (
    <>
      <main className='h-full flex'>
        <div className=' w-full flex justify-center'>
          <div className='w-full '>
            <div className='flex flex-col gap-y-[6px]'>
              <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>
                Base Details
              </h2>
              <p className='text-sm leading-6 text-[#5c5c5e] font-medium'>
                This information will be displayed publicly so be careful what you share.
              </p>
            </div>
            <div className='bg-[#e0e0e0] w-full h-[1px] my-6'></div>
            <div className="min-h-[150px] max-h-[150px] h-auto relative">
              <label className="h-[150px] relative block">
                <input
                  className="opacity-0 focus:outline-none absolute top-[118px] left-[36px] h-[70px] w-[70px] flex justify-center items-center"
                  type="file"
                  onChange={handleFileChangeCover}
                  accept="image/*"
                />
                {coverLoading ? (
                  <div className="flex items-center justify-center bg-[#f5f5f5] rounded-2xl h-full w-full">
                    <div className="simple-spinner-loader">
                      <span className='!w-4 !h-4'></span>
                    </div>
                  </div>
                ) : logoUrlCover ? (
                  <img
                    src={logoUrlCover}
                    alt="Base Logo"
                    className="h-full w-full border border-[#e0e0e0] border-solid rounded-lg object-cover cursor-move"
                    onLoad={() => setCoverLoading(false)}
                  />
                ) : (
                  <div className="h-full w-full border-[5px] border-solid border-[#f5f5f5] rounded-2xl bg-gray-200 flex items-center justify-center">
                    <span className="text-gray-500 text-sm">No cover</span>
                  </div>
                )}

              </label>

              <label className="flex flex-inherit h-[70px] w-[70px] z-10 absolute top-[118px] left-[36px] cursor-move">
                <input className="opacity-0 focus:outline-none cursor-move" type="file" onChange={handleFileChange} accept="image/*" />
              </label>
              <div className="absolute top-[118px] left-[36px] h-[70px] w-[70px]">
                {logoLoading ? (
                  <div className="flex items-center justify-center bg-[#f5f5f5] rounded-2xl h-full w-full">
                    <div className="simple-spinner-loader">
                      <span className='!w-4 !h-4'></span>
                    </div>
                  </div>
                ) : logoUrl ? (
                  <img
                    src={logoUrl}
                    alt="Base Logo"
                    className="h-full w-full border-[5px] border-solid border-[#f5f5f5] rounded-2xl object-cover cursor-move"
                    onLoad={() => setLogoLoading(false)}
                  />
                ) : (
                  <div className="h-full w-full border-[5px] border-solid border-[#f5f5f5] rounded-2xl bg-gray-200 flex items-center justify-center">
                    <span className="text-gray-500 text-sm">No Logo</span>
                  </div>
                )}
              </div>
            </div>

            <div className='sm:col-span-4 mt-14 lg:mt-16'>
              <label htmlFor='username' className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5'>
                Base
              </label>
              <div className='mt-[6px]'>
                <input
                  id='name'
                  name='name'
                  type='name'
                  autoComplete='name'
                  onChange={handleNameChange}
                  placeholder='Enter Name'
                  value={name}
                  className="flex grow rounded-lg border bg-background px-4 py-[5px] text-sm leading-6 shadow-wg-xs transition-colors duration-100 placeholder:text-surface-500
				               outline-primary focus:outline focus:outline-2 focus:-outline-offset-2 w-full
				                text-surface-900 hover:border-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100 focus:outline-indigo-600"
                />
              </div>
            </div>
            <div className='sm:col-span-4 mt-5'>
              <label htmlFor='description' className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5'>
                Base Description
              </label>
              <div className='mt-[6px]'>
                <textarea
                  id='description'
                  name='description'
                  type='description'
                  rows={2}
                  maxLength={1500}
                  autoComplete='description'
                  onChange={handleDescriptionChange}
                  placeholder='Enter Description'
                  value={description}
                  className="flex grow rounded-lg border border-[#e5e7eb] bg-background px-4 py-[5px] text-sm leading-6 shadow-wg-xs transition-colors duration-100 placeholder:text-surface-500
				               outline-primary focus:outline focus:outline-2 focus:-outline-offset-2 w-full
				                text-surface-900 hover:border-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100 focus:outline-indigo-600"
                />
              </div>
            </div>
            {/* <div className='sm:col-span-4 mt-4'>
              <label htmlFor='username' className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased leading-5'>
                Timezone
              </label>
              <div className='mt-[6px]'>
                <DropDown previousValue={timeZone} onChangeValue={handleTimeZoneChange} data={timeZoneList} />
              </div>
            </div> */}
            <div className='mt-12 flex items-center justify-end'>
              <button
                onClick={_updateHandler}
                type='submit'
                className='group inline-flex shrink-0 w-full select-none items-center justify-center text-sm_small font-semibold leading-6 transition-colors
                duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-[8px]
                 py-[6px] rounded-lg text-white outline-indigo-600
                disabled:opacity-50 bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'                size="sm"
              >

                {baseLoading ? (
                  <div className='flex items-center  gap-[6px]'>
                    Update
                    <div className="simple-spinner">
                      <span></span>
                    </div>
                  </div>
                ) : (
                  'Update'
                )}
              </button>
            </div>
          </div>
        </div>
      </main>

      <Transition.Root show={showCropModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setShowCropModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-y-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>

                    <ReactCrop
                      crop={crop}
                      onChange={(_, percentCrop) => setCrop(percentCrop)}
                      onComplete={(c) => {
                        setCompletedCrop(c);
                      }}
                      className='w-full'
                      aspect={1}
                    >
                      <img src={cropSrc} alt="Crop preview" onLoad={onImageLoaded} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    </ReactCrop>
                    <canvas ref={canvasRef} className="hidden" />
                  </div>
                  <div className='flex justify-end mt-5'>
                    <button
                      onClick={handleCropSave}
                      className='group inline-flex shrink-0 select-none items-center justify-center text-sm font-medium
    leading-6 transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 
    focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8  py-2 rounded-lg text-white outline-primary
    disabled:opacity-50 bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                      disabled={logoLoading || !completedCrop}
                    >
                      {logoLoading ? (
                        <div className='flex items-center  gap-[6px]'>
                          Save
                          <div className="simple-spinner">
                            <span></span>
                          </div>
                        </div>
                      ) : (
                        'Save'
                      )}

                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>


      <Transition.Root show={showCropModalCover} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setShowCropModalCover(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-y-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[800px] sm:p-6">
                  <div>

                    <ReactCrop
                      crop={cropCover}
                      onChange={(_, percentCrop) => setCropCover(percentCrop)}
                      onComplete={(c) => {
                        setCompletedCropCover(c);
                      }}
                      className='w-full'
                      aspect={16 / 9}
                    >
                      <img src={cropSrcCover} alt="Crop preview" style={{ maxWidth: '100%', maxHeight: '100%' }} onLoad={onImageLoadedCover} />
                    </ReactCrop>
                    <canvas ref={canvasRefCover} className="hidden" />
                  </div>
                  <div className='flex justify-end mt-5'>
                    <button
                      onClick={handleCropSaveCover}
                      className='group inline-flex shrink-0 select-none items-center justify-center text-sm font-medium
                    leading-6 transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 
                    focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8  py-2 rounded-lg text-white outline-primary
                    disabled:opacity-50 bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                      disabled={coverLoading || !completedCropCover}
                    >
                      {coverLoading ? (
                        <div className='flex items-center  gap-[6px]'>
                          Save
                          <div className="simple-spinner">
                            <span></span>
                          </div>
                        </div>
                      ) : (
                        'Save'
                      )}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
});

export default BaseDetails;