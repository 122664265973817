import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor, store } from './app/store';
import AppRoutes from './routes/AppRoutes';
import { Toaster, toast } from 'sonner';
import { PersistGate } from 'redux-persist/integration/react';

const App: React.FC = () => {
  return (
    
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <div className='h-[calc(100vh_-_0rem)]'>
            <Toaster richColors />
            <AppRoutes />
          </div>
        </Router>
      </PersistGate>

    </Provider>
  );
};

export default App;