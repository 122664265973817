// @ts-nocheck
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Listbox, ListboxButton, ListboxOptions, Transition } from '@headlessui/react'
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { createDocumentAction, createDocumentContact, fetchDocument, fetchDocumentAction, fetchDocumentContact, updateDocument } from '../../../features/document/documentSlice';
import { toast } from 'sonner';
import { debounce } from 'lodash';
import { RootState } from '../../../app/store';
import { ChevronDownIcon } from '../../../components/icons';
import CustomMenuDropdown from '../../../components/common/CustomMenuDropdown';

export interface DropdownItem {
  className?: string;
  icon?: ReactNode;
  label: string;
  shortcut?: any;
  disabled?: boolean;
  onClick?: () => void;
  href?: string; // Add href property with type string
}

interface DocumentDetailsProps {

}


export interface DropdownGroup {
  options: DropdownItem[];
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const variableTypeList = [
  { id: 1, name: 'Text' },
  { id: 2, name: 'Underline' },
  { id: 3, name: 'Email' },
  { id: 4, name: 'Phone Number' },
  { id: 5, name: 'Date' },
  { id: 6, name: 'Signature' },
  { id: 7, name: 'Image' },
  { id: 8, name: 'File' },
  { id: 9, name: 'Checkbox' },
]

const tabs1 = [
  { name: 'internal team', icon: 'fas fa-user' },
  { name: 'external contact', icon: 'fas fa-users' },
];

const tabs2 = [
  { name: 'internal team', icon: 'fas fa-user' },
  { name: 'external contact', icon: 'fas fa-users' },
];

const tabs3 = [
  { name: 'internal team', icon: 'fas fa-user' },
  { name: 'external contact', icon: 'fas fa-users' },
];

const tabs4 = [
  { name: 'internal team', icon: 'fas fa-user' },
  { name: 'external contact', icon: 'fas fa-users' },
];

const tabs5 = [
  { name: 'internal team', icon: 'fas fa-user' },
  { name: 'external contact', icon: 'fas fa-users' },
];

const tabs6 = [
  { name: 'internal team', icon: 'fas fa-user' },
  { name: 'external contact', icon: 'fas fa-users' },
];


const dropdownGroups1: DropdownGroup[] = [
  {
    options: [
      {
        label: `Delete`,
        // icon: CustomUserIcon,
        // className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
        onClick: () => console.log('Delete clicked'),
      }
    ],
  },
];


const SendDocumentDetailsAction: React.FC<DocumentDetailsProps> = React.memo(({
  isAsideOpen4,
  isActionList,
  setIsActionList,
  variants,
  isActionListNew,
  baseMemberDetails,
  recipients,
  setRecipients,
  recipients1,
  setRecipients1,
  recipient2,
  setRecipients2,
  recipients3,
  setRecipients3,
  recipients4,
  setRecipients4,
  recipients5,
  setRecipients5
}) => {
  const { baseId } = useParams<{ baseId: string }>(); // Extract baseId from URL
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data, fetchStatus, fetchError } = useAppSelector((state: RootState) => state.documentData);
  const [collapsed4, setCollapsed4] = useState(false);
  const [collapsed5, setCollapsed5] = useState(false);
  const [collapsed6, setCollapsed6] = useState(false);
  const [collapsed7, setCollapsed7] = useState(false);
  const [collapsed8, setCollapsed8] = useState(false);
  const [collapsed9, setCollapsed9] = useState(false);
  const [newAction, setNewAction] = useState(false)
  const [openActionTab, setOpenActionTab] = useState('internal team')
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState(''); // New state for selected recipient
  const [newActionEnteral, setNewActionEnteral] = useState(false)
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [limit] = useState(25);
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [isActionLoading1, setIsActionLoading1] = useState(false)
  const [contactInfo, setContactInfo] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });

  const [contactLoading, setContactLoading] = useState(false)
  const [newAction1, setNewAction1] = useState(false)
  const [openActionTab1, setOpenActionTab1] = useState('internal team')
  const [isActionLoading2, setIsActionLoading2] = useState(false)
  const [selectedRecipient1, setSelectedRecipient1] = useState('');
  const [newActionEnteral1, setNewActionEnteral1] = useState(false)
  const [search1, setSearch1] = useState('');
  const [limit1] = useState(25);
  const [isOpen1, setIsOpen1] = useState(false);
  const [contactInfo1, setContactInfo1] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });
  const [contactLoading1, setContactLoading1] = useState(false)


  const [newAction2, setNewAction2] = useState(false)
  const [openActionTab2, setOpenActionTab2] = useState('internal team')
  const [isActionLoading3, setIsActionLoading3] = useState(false)
  const [selectedRecipient2, setSelectedRecipient2] = useState('');
  const [newActionEnteral2, setNewActionEnteral2] = useState(false)
  const [search2, setSearch2] = useState('');
  const [limit2] = useState(25);
  const [isOpen2, setIsOpen2] = useState(false);
  const [contactInfo2, setContactInfo2] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });
  const [contactLoading2, setContactLoading2] = useState(false)

  const [newAction3, setNewAction3] = useState(false)
  const [openActionTab3, setOpenActionTab3] = useState('internal team')
  const [isActionLoading4, setIsActionLoading4] = useState(false)
  const [selectedRecipient3, setSelectedRecipient3] = useState('');
  const [newActionEnteral3, setNewActionEnteral3] = useState(false)
  const [search3, setSearch3] = useState('');
  const [isOpen3, setIsOpen3] = useState(false);
  const [contactInfo3, setContactInfo3] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });
  const [contactLoading3, setContactLoading3] = useState(false)


  const [newAction4, setNewAction4] = useState(false)
  const [openActionTab4, setOpenActionTab4] = useState('internal team')
  const [isActionLoading5, setIsActionLoading5] = useState(false)
  const [selectedRecipient4, setSelectedRecipient4] = useState('');
  const [newActionEnteral4, setNewActionEnteral4] = useState(false)
  const [search4, setSearch4] = useState('');
  const [limit4] = useState(25);
  const [isOpen4, setIsOpen4] = useState(false);
  const [contactInfo4, setContactInfo4] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });
  const [contactLoading4, setContactLoading4] = useState(false)


  const [newAction5, setNewAction5] = useState(false)
  const [openActionTab5, setOpenActionTab5] = useState('internal team')
  const [isActionLoading6, setIsActionLoading6] = useState(false)
  const [selectedRecipient5, setSelectedRecipient5] = useState('');
  const [newActionEnteral5, setNewActionEnteral5] = useState(false)
  const [search5, setSearch5] = useState('');
  const [page5, setPage5] = useState(1);
  const [limit5] = useState(25);
  const [isOpen5, setIsOpen5] = useState(false);
  const [contactInfo5, setContactInfo5] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });
  const [contactLoading5, setContactLoading5] = useState(false)


  const [baseMemberDetailsData, setBaseMemberDetailsData] = useState<any>([]);
  const [variableType1, setVariableType1] = useState(baseMemberDetailsData[0]?.name || null);

  const [baseMemberDetailsData1, setBaseMemberDetailsData1] = useState<any>([]);
  const [variableType2, setVariableType2] = useState(baseMemberDetailsData1[0]?.name || null);

  const [baseMemberDetailsData2, setBaseMemberDetailsData2] = useState<any>([]);
  const [variableType3, setVariableType3] = useState(baseMemberDetailsData2[0]?.name || null);

  const [baseMemberDetailsData3, setBaseMemberDetailsData3] = useState<any>([]);
  const [variableType4, setVariableType4] = useState(baseMemberDetailsData3[0]?.name || null);

  const [baseMemberDetailsData4, setBaseMemberDetailsData4] = useState<any>([]);
  const [variableType5, setVariableType5] = useState(baseMemberDetailsData4[0]?.name || null);

  const [baseMemberDetailsData5, setBaseMemberDetailsData5] = useState<any>([]);
  const [variableType6, setVariableType6] = useState(baseMemberDetailsData5[0]?.name || null);

  const [baseMemberDetailsData6, setBaseMemberDetailsData6] = useState<any>([]);
  const [selectMember, setSelectMember] = useState('')
  const [selectMember1, setSelectMember1] = useState('')
  const [selectMember2, setSelectMember2] = useState('')
  const [selectMember3, setSelectMember3] = useState('')
  const [selectMember4, setSelectMember4] = useState('')
  const [selectMember5, setSelectMember5] = useState('')
  const handleRecipientSelect = (recipient) => {
    setSelectMember(recipient);
    setIsOpen(false);
    setSearch(''); // Clear the search term when selecting a recipient
    setNewActionEnteral(false);
  };


  const handleRecipientSelect1 = (recipient) => {
    setSelectMember1(recipient);
    setIsOpen1(false);
    setSearch1(''); // Clear the search term when selecting a recipient
    setNewActionEnteral1(false);
  };


  const toggleCollapse4 = () => {
    setCollapsed4(!collapsed4);
  };

  const toggleCollapse5 = () => {
    setCollapsed5(!collapsed5);
  };

  const toggleCollapse6 = () => {
    setCollapsed6(!collapsed6);
  };

  const toggleCollapse7 = () => {
    setCollapsed7(!collapsed7);
  };

  const toggleCollapse8 = () => {
    setCollapsed8(!collapsed8);
  };

  const toggleCollapse9 = () => {
    setCollapsed9(!collapsed9);
  };



  const handleNewAction = () => {
    setNewAction(!newAction)
  }


  const handleNewActionEnteral = () => {
    setNewActionEnteral(true)
    setIsOpen(false)
    setOpenActionTab('external contact')
  }


  const debouncedSearch = useCallback(
    debounce((searchTerm: string) => {
      setPage(1);
      dispatch(fetchDocumentContact({
        baseId,
        page: 1,
        limit,
        search: searchTerm
      }));
    }, 500),
    [dispatch, baseId, limit]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    debouncedSearch(searchTerm);
  };



  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setContactInfo((prevState) => ({ ...prevState, [name]: value }));
  };


  // document action
  const handleDocumentContactSave = async (e: any) => {
    e.preventDefault();
    setContactLoading(true);

    if (contactInfo.contact_email.trim().length === 0) {
      toast.error("Email is required");
      setContactLoading(false);
      return;
    }

    const payload = {
      contact_first_name: contactInfo.contact_first_name,
      contact_last_name: contactInfo.contact_last_name,
      contact_email: contactInfo.contact_email,
      contact_phone: contactInfo.contact_phone,
      contact_company: contactInfo.contact_company,
      contact_job_title: contactInfo.contact_job_title,
      item_type: "doc",
      item_id: id
    };

    try {
      const response = await dispatch(createDocumentContact({
        data: payload,
        userId: baseId,
      }));



      if (response?.meta?.requestStatus === 'fulfilled') {
        setContactInfo({
          contact_first_name: "",
          contact_last_name: "",
          contact_email: "",
          contact_phone: "",
          contact_company: "",
          contact_job_title: "",
        });
        toast.success('Contact updated successfully!');
        setNewActionEnteral(false);
        if (response?.payload?.data) {
          setSelectedRecipient(response?.payload?.data);
        }

        // Call fetchDocumentContact again
        const fetchResponse = await dispatch(fetchDocumentContact({
          baseId,
          page,
          limit,
          search
        }));

        if (fetchResponse.payload?.data?.contact) {
          setRecipients(fetchResponse.payload.data.contact);
        }
        else {
          toast.error('Failed to fetch updated contacts.');
        }
      } else if (response?.meta?.requestStatus === "rejected") {
        toast.error(response?.payload || "Contact update request was rejected.");
      } else {
        toast.error('Failed to update contact.');
      }

    } catch (error) {
      console.error("Failed to save contact:", error);
      toast.error(`An error occurred while saving: ${error.message || error}`);
    } finally {
      setContactLoading(false);
    }

  };

  const handleActionExternalContact = async () => {
    try {
      setIsActionLoading(true);
      if (!selectMember?.id) {
        toast.error("Please select an external contact.");
        return; // Stop the function if external_contact_id is missing
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "collab",
        external_contact_id: selectMember.content.id,
        member_position: selectMember.member_position
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      if (result.status === 200) {
        const fetchResponse = await dispatch(
          fetchDocumentAction({ baseId, id })
        ).unwrap();

        setIsActionList(fetchResponse);
        toast.success("Action created successfully!");
        setNewAction(false);
      } else {
        throw new Error("Failed to collect action");
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'action already exists';
      toast.error(err || errorMessage);
    } finally {
      setIsActionLoading(false);
    }
  };
  // console.log(variableType1,"variableType1");

  const handleActionInternalTeam = async () => {
    try {
      setIsActionLoading1(true);
      if (!variableType1?.id) {
        toast.error("Please select an internal team.");
        return;
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "collab",
        internal_user_id: variableType1.id,
        member_position: variableType1?.member_position
      };

      // Dispatch the action to create a document action
      const result = await dispatch(
        createDocumentAction({ baseId, actionPayload })
      ).unwrap();

      // Check the response status
      if (result.status === 200) {
        const fetchResponse = await dispatch(
          fetchDocumentAction({ baseId, id })
        ).unwrap();

        setIsActionList(fetchResponse);
        toast.success("Action created successfully!");
        setNewAction(false);
      } else {
        throw new Error("Failed to collect action");
      }
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : "action already exists";
      toast.error(errorMessage);
      console.error("Error:", errorMessage);
    } finally {
      setIsActionLoading1(false);
    }
  };


  const handleNewAction1 = () => {
    setNewAction1(!newAction1)
  }


  const handleNewActionEnteral1 = () => {
    setNewActionEnteral1(true)
    setIsOpen1(false)
    setOpenActionTab1('external contact')
  }

  const handleSearchChange1 = (e: any) => {
    const searchTerm = e.target.value;
    setSearch1(searchTerm);
    dispatch(fetchDocumentContact({
      baseId,
      page: 1,
      limit1,
      search: searchTerm
    }));
  };


  const handleInputChange1 = (e: any) => {
    const { name, value } = e.target;
    setContactInfo1((prevState) => ({ ...prevState, [name]: value }));
  };


  // document action
  const handleDocumentContactSave1 = async (e: any) => {
    e.preventDefault();
    setContactLoading1(true);

    if (contactInfo1.contact_email.trim().length === 0) {
      toast.error("Email is required");
      setContactLoading1(false);
      return;
    }

    const payload = {
      contact_first_name: contactInfo1.contact_first_name,
      contact_last_name: contactInfo1.contact_last_name,
      contact_email: contactInfo1.contact_email,
      contact_phone: contactInfo1.contact_phone,
      contact_company: contactInfo1.contact_company,
      contact_job_title: contactInfo1.contact_job_title,
      item_type: "doc",
      item_id: id
    };

    try {
      const response = await dispatch(createDocumentContact({
        data: payload,
        userId: baseId,
      }));



      if (response?.meta?.requestStatus === 'fulfilled') {
        setContactInfo1({
          contact_first_name: "",
          contact_last_name: "",
          contact_email: "",
          contact_phone: "",
          contact_company: "",
          contact_job_title: "",
        });
        toast.success('Contact updated successfully!');
        setNewActionEnteral1(false);
        if (response?.payload?.data) {
          setSelectedRecipient1(response?.payload?.data);
        }

        // Call fetchDocumentContact again
        const fetchResponse = await dispatch(fetchDocumentContact({
          baseId,
          page,
          limit,
          search
        }));

        if (fetchResponse.payload?.data?.contact) {
          setRecipients1(fetchResponse.payload.data.contact);
        } else {
          toast.error('Failed to fetch updated contacts.');
        }

      } else if (response?.meta?.requestStatus === "rejected") {
        toast.error(response?.payload || "Contact update request was rejected.");
      } else {
        toast.error('Failed to update contact.');
      }

    } catch (error) {
      console.error("Failed to save contact:", error);
      toast.error(`An error occurred while saving: ${error.message || error}`);
    } finally {
      setContactLoading1(false);
    }

  };


  const handleActionInternalTeam1 = async () => {
    try {
      setIsActionLoading2(true);

      if (!variableType2?.id) {
        toast.error("Please select an internal team.");
        return;
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "approval",
        internal_user_id: variableType2.id,
        member_position: variableType2?.member_position
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      // Check the response status
      if (result.status === 200) {
        const fetchResponse = await dispatch(
          fetchDocumentAction({ baseId, id })
        ).unwrap();

        setIsActionList(fetchResponse);
        toast.success("Action created successfully!");
        setNewAction1(false);
      } else {
        throw new Error("Failed to collect action");
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'action already exists';
      toast.error(err || errorMessage);
      console.error("Error:", errorMessage);
    } finally {
      setIsActionLoading2(false);
    }
  };

  const handleActionExternalContact1 = async () => {
    try {
      setIsActionLoading2(true);

      if (!selectMember1?.id) {
        toast.error("Please select an external contact.");
        return; // Stop the function if external_contact_id is missing
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "approval",
        external_contact_id: selectMember1.content.id,
        member_position: selectMember1.member_position
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      // Check the response status
      if (result.status === 200) {
        const fetchResponse = await dispatch(
          fetchDocumentAction({ baseId, id })
        ).unwrap();

        setIsActionList(fetchResponse);
        toast.success("Action created successfully!");
        setNewAction1(false);
      } else {
        throw new Error("Failed to collect action");
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'action already exists';
      toast.error(err || errorMessage);
      console.error("Error:", errorMessage);
    } finally {
      setIsActionLoading2(false);
    }
  };


  const handleRecipientSelect2 = (recipient: any) => {
    setSelectMember2(recipient);
    setIsOpen2(false);
    setSearch2(''); // Clear the search term when selecting a recipient
    setNewActionEnteral2(false);
  };


  const handleNewAction2 = () => {
    setNewAction2(!newAction2)
  }


  const handleNewActionEnteral2 = () => {
    setNewActionEnteral2(true)
    setIsOpen2(false)
    setOpenActionTab2('external contact')
  }

  const handleSearchChange2 = (e: any) => {
    const searchTerm = e.target.value;
    setSearch2(searchTerm);
    dispatch(fetchDocumentContact({
      baseId,
      page: 1,
      limit2,
      search: searchTerm
    }));
  };


  const handleInputChange2 = (e: any) => {
    const { name, value } = e.target;
    setContactInfo2((prevState) => ({ ...prevState, [name]: value }));
  };



  // document action
  const handleDocumentContactSave2 = async (e: any) => {
    e.preventDefault();
    setContactLoading2(true);

    if (contactInfo2.contact_email.trim().length === 0) {
      toast.error("Email is required");
      setContactLoading2(false);
      return;
    }

    const payload = {
      contact_first_name: contactInfo2.contact_first_name,
      contact_last_name: contactInfo2.contact_last_name,
      contact_email: contactInfo2.contact_email,
      contact_phone: contactInfo2.contact_phone,
      contact_company: contactInfo2.contact_company,
      contact_job_title: contactInfo2.contact_job_title,
      item_type: "doc",
      item_id: id
    };

    try {
      const response = await dispatch(createDocumentContact({
        data: payload,
        userId: baseId,
      }));



      if (response?.meta?.requestStatus === 'fulfilled') {
        setContactInfo2({
          contact_first_name: "",
          contact_last_name: "",
          contact_email: "",
          contact_phone: "",
          contact_company: "",
          contact_job_title: "",
        });
        toast.success('Contact updated successfully!');
        setNewActionEnteral2(false);
        if (response?.payload?.data) {
          setSelectedRecipient2(response?.payload?.data);
        }

        // Call fetchDocumentContact again
        const fetchResponse = await dispatch(fetchDocumentContact({
          baseId,
          page,
          limit,
          search
        }));

        if (fetchResponse.payload?.data?.contact) {
          setRecipients2(fetchResponse.payload.data.contact);


        } else {
          toast.error('Failed to fetch updated contacts.');
        }
      } else if (response?.meta?.requestStatus === "rejected") {
        toast.error(response?.payload || "Contact update request was rejected.");
      } else {
        toast.error('Failed to update contact.');
      }

    } catch (error) {
      console.error("Failed to save contact:", error);
      toast.error(`An error occurred while saving: ${error.message || error}`);
    } finally {
      setContactLoading2(false);
    }

  };


  const handleActionInternalTeam2 = async () => {
    try {
      setIsActionLoading3(true);

      if (!variableType3?.id) {
        toast.error("Please select an internal team.");
        return;
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "view",
        internal_user_id: variableType3.id,
        member_position: variableType3?.member_position
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      // Check the response status
      if (result.status === 200) {
        const fetchResponse = await dispatch(
          fetchDocumentAction({ baseId, id })
        ).unwrap();

        setIsActionList(fetchResponse);
        toast.success("Action created successfully!");
        setNewAction2(false);
      } else {
        throw new Error("Failed to collect action");
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'action already exists';
      toast.error(err || errorMessage);
      console.error("Error:", errorMessage);
    } finally {
      setIsActionLoading3(false);
    }
  };

  const handleActionExternalContact2 = async () => {
    try {
      setIsActionLoading3(true);

      if (!selectMember2?.id) {
        toast.error("Please select an external contact.");
        return; // Stop the function if external_contact_id is missing
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "view",
        external_contact_id: selectMember2.content.id,
        member_position: selectMember2.member_position
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      // Check the response status
      if (result.status === 200) {
        const fetchResponse = await dispatch(
          fetchDocumentAction({ baseId, id })
        ).unwrap();

        setIsActionList(fetchResponse);
        toast.success("Action created successfully!");
        setNewAction2(false);
      } else {
        throw new Error("Failed to collect action");
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'action already exists';
      toast.error(err || errorMessage);
      console.error("Error:", errorMessage);
    } finally {
      setIsActionLoading3(false);
    }
  };


  /////

  const handleRecipientSelect3 = (recipient: any) => {
    setSelectMember3(recipient);
    setIsOpen3(false);
    setSearch3(''); // Clear the search term when selecting a recipient
    setNewActionEnteral3(false);
  };


  const handleNewAction3 = () => {
    setNewAction3(!newAction3)
  }


  const handleNewActionEnteral3 = () => {
    setNewActionEnteral3(true)
    setIsOpen3(false)
    setOpenActionTab3('external contact')
  }


  const handleSearchChange3 = (e: any) => {
    const searchTerm = e.target.value;
    setSearch3(searchTerm);
    dispatch(fetchDocumentContact({
      baseId,
      page: 1,
      limit3,
      search: searchTerm
    }));
  };




  const handleInputChange3 = (e: any) => {
    const { name, value } = e.target;
    setContactInfo3((prevState) => ({ ...prevState, [name]: value }));
  };


  // document action
  const handleDocumentContactSave3 = async (e: any) => {
    e.preventDefault();
    setContactLoading3(true);

    if (contactInfo3.contact_email.trim().length === 0) {
      toast.error("Email is required");
      setContactLoading3(false);
      return;
    }

    const payload = {
      contact_first_name: contactInfo3.contact_first_name,
      contact_last_name: contactInfo3.contact_last_name,
      contact_email: contactInfo3.contact_email,
      contact_phone: contactInfo3.contact_phone,
      contact_company: contactInfo3.contact_company,
      contact_job_title: contactInfo3.contact_job_title,
      item_type: "doc",
      item_id: id
    };

    try {
      const response = await dispatch(createDocumentContact({
        data: payload,
        userId: baseId,
      }));



      if (response?.meta?.requestStatus === 'fulfilled') {
        setContactInfo3({
          contact_first_name: "",
          contact_last_name: "",
          contact_email: "",
          contact_phone: "",
          contact_company: "",
          contact_job_title: "",
        });
        toast.success('Contact updated successfully!');
        setNewActionEnteral3(false);
        if (response?.payload?.data) {
          setSelectedRecipient3(response?.payload?.data);
        }

        // Call fetchDocumentContact again
        const fetchResponse = await dispatch(fetchDocumentContact({
          baseId,
          page,
          limit,
          search
        }));

        if (fetchResponse.payload?.data?.contact) {
          setRecipients3(fetchResponse.payload.data.contact);
        } else {
          toast.error('Failed to fetch updated contacts.');
        }

      } else if (response?.meta?.requestStatus === "rejected") {
        toast.error(response?.payload || "Contact update request was rejected.");
      } else {
        toast.error('Failed to update contact.');
      }

    } catch (error) {
      console.error("Failed to save contact:", error);
      toast.error(`An error occurred while saving: ${error.message || error}`);
    } finally {
      setContactLoading3(false);
    }

  };


  const handleActionInternalTeam3 = async () => {
    try {
      setIsActionLoading4(true);

      if (!variableType4?.id) {
        toast.error("Please select an internal team.");
        return;
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "fill",
        internal_user_id: variableType4.id,
        member_position: variableType4?.member_position
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      // Check the response status
      if (result.status === 200) {
        const fetchResponse = await dispatch(
          fetchDocumentAction({ baseId, id })
        ).unwrap();

        setIsActionList(fetchResponse);
        toast.success("Action created successfully!");
        setNewAction3(false);
      } else {
        throw new Error("Failed to collect action");
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'action already exists';
      toast.error(err || errorMessage);
      console.error("Error:", errorMessage);
    } finally {
      setIsActionLoading4(false);
    }
  };

  const handleActionExternalContact3 = async () => {
    try {
      setIsActionLoading4(true);

      if (!selectMember3?.id) {
        toast.error("Please select an external contact.");
        return; // Stop the function if external_contact_id is missing
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "fill",
        external_contact_id: selectMember3.content.id,
        member_position: selectMember3.member_position
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      // Check the response status
      if (result.status === 200) {
        const fetchResponse = await dispatch(
          fetchDocumentAction({ baseId, id })
        ).unwrap();

        setIsActionList(fetchResponse);
        toast.success("Action created successfully!");
        setNewAction3(false);
      } else {
        throw new Error("Failed to collect action");
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'action already exists';
      toast.error(err || errorMessage);
      console.error("Error:", errorMessage);
    } finally {
      setIsActionLoading4(false);
    }
  };



  ///////

  const handleRecipientSelect4 = (recipient: any) => {
    setSelectMember4(recipient);
    setIsOpen4(false);
    setSearch4(''); // Clear the search term when selecting a recipient
    setNewActionEnteral4(false);
  };


  const handleNewAction4 = () => {
    setNewAction4(!newAction4)
  }


  const handleNewActionEnteral4 = () => {
    setNewActionEnteral4(true)
    setIsOpen4(false)
    setOpenActionTab4('external contact')
  }



  const handleSearchChange4 = (e: any) => {
    const searchTerm = e.target.value;
    setSearch4(searchTerm);
    dispatch(fetchDocumentContact({
      baseId,
      page: 1,
      limit4,
      search: searchTerm
    }));
  };



  const handleInputChange4 = (e: any) => {
    const { name, value } = e.target;
    setContactInfo4((prevState) => ({ ...prevState, [name]: value }));
  };


  // document action
  const handleDocumentContactSave4 = async (e: any) => {
    e.preventDefault();
    setContactLoading4(true);

    if (contactInfo4.contact_email.trim().length === 0) {
      toast.error("Email is required");
      setContactLoading4(false);
      return;
    }

    const payload = {
      contact_first_name: contactInfo4.contact_first_name,
      contact_last_name: contactInfo4.contact_last_name,
      contact_email: contactInfo4.contact_email,
      contact_phone: contactInfo4.contact_phone,
      contact_company: contactInfo4.contact_company,
      contact_job_title: contactInfo4.contact_job_title,
      item_type: "doc",
      item_id: id
    };

    try {
      const response = await dispatch(createDocumentContact({
        data: payload,
        userId: baseId,
      }));



      if (response?.meta?.requestStatus === 'fulfilled') {
        setContactInfo4({
          contact_first_name: "",
          contact_last_name: "",
          contact_email: "",
          contact_phone: "",
          contact_company: "",
          contact_job_title: "",
        });
        toast.success('Contact updated successfully!');
        setNewActionEnteral4(false);
        if (response?.payload?.data) {
          setSelectedRecipient4(response?.payload?.data);
        }

        // Call fetchDocumentContact again
        const fetchResponse = await dispatch(fetchDocumentContact({
          baseId,
          page,
          limit,
          search
        }));

        if (fetchResponse.payload?.data?.contact) {
          setRecipients4(fetchResponse.payload.data.contact);
        } else {
          toast.error('Failed to fetch updated contacts.');
        }

      } else if (response?.meta?.requestStatus === "rejected") {
        toast.error(response?.payload || "Contact update request was rejected.");
      } else {
        toast.error('Failed to update contact.');
      }

    } catch (error) {
      console.error("Failed to save contact:", error);
      toast.error(`An error occurred while saving: ${error.message || error}`);
    } finally {
      setContactLoading4(false);
    }

  };


  const handleActionInternalTeam4 = async () => {
    try {
      setIsActionLoading5(true);

      if (!variableType5?.id) {
        toast.error("Please select an internal team.");
        return;
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "accept",
        internal_user_id: variableType5.id,
        member_position: variableType5?.member_position
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      // Check the response status
      if (result.status === 200) {
        const fetchResponse = await dispatch(
          fetchDocumentAction({ baseId, id })
        ).unwrap();

        setIsActionList(fetchResponse);
        toast.success("Action created successfully!");
        setNewAction4(false);
      } else {
        throw new Error("Failed to collect action");
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'action already exists';
      toast.error(err || errorMessage);
      console.error("Error:", errorMessage);
    } finally {
      setIsActionLoading5(false);
    }
  };

  const handleActionExternalContact4 = async () => {
    try {
      setIsActionLoading5(true);

      if (!selectMember4?.id) {
        toast.error("Please select an external contact.");
        return; // Stop the function if external_contact_id is missing
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "accept",
        external_contact_id: selectMember4.content.id,
        member_position: selectMember4.member_position
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      // Check the response status
      if (result.status === 200) {
        const fetchResponse = await dispatch(
          fetchDocumentAction({ baseId, id })
        ).unwrap();

        setIsActionList(fetchResponse);
        toast.success("Action created successfully!");
        setNewAction4(false);
      } else {
        throw new Error("Failed to collect action");
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'action already exists';
      toast.error(err || errorMessage);
      console.error("Error:", errorMessage);
    } finally {
      setIsActionLoading5(false);
    }
  };





  ////

  const handleRecipientSelect5 = (recipient: any) => {
    setSelectMember5(recipient);
    setIsOpen5(false);
    setSearch5(''); // Clear the search term when selecting a recipient
    setNewActionEnteral5(false);
  };


  const handleNewAction5 = () => {
    setNewAction5(!newAction5)
  }


  const handleNewActionEnteral5 = () => {
    setNewActionEnteral5(true)
    setIsOpen5(false)
    setOpenActionTab5('external contact')
  }


  const handleSearchChange5 = (e: any) => {
    const searchTerm = e.target.value;
    setSearch5(searchTerm);
    dispatch(fetchDocumentContact({
      baseId,
      page: 1,
      limit5,
      search: searchTerm
    }));
  };



  const handleInputChange5 = (e: any) => {
    const { name, value } = e.target;
    setContactInfo5((prevState) => ({ ...prevState, [name]: value }));
  };


  // document action
  const handleDocumentContactSave5 = async (e: any) => {
    e.preventDefault();
    setContactLoading5(true);

    if (contactInfo5.contact_email.trim().length === 0) {
      toast.error("Email is required");
      setContactLoading5(false);
      return;
    }

    const payload = {
      contact_first_name: contactInfo5.contact_first_name,
      contact_last_name: contactInfo5.contact_last_name,
      contact_email: contactInfo5.contact_email,
      contact_phone: contactInfo5.contact_phone,
      contact_company: contactInfo5.contact_company,
      contact_job_title: contactInfo5.contact_job_title,
      item_type: "doc",
      item_id: id
    };

    try {
      const response = await dispatch(createDocumentContact({
        data: payload,
        userId: baseId,
      }));



      if (response?.meta?.requestStatus === 'fulfilled') {
        setContactInfo5({
          contact_first_name: "",
          contact_last_name: "",
          contact_email: "",
          contact_phone: "",
          contact_company: "",
          contact_job_title: "",
        });
        toast.success('Contact updated successfully!');
        setNewActionEnteral5(false);
        if (response?.payload?.data) {
          setSelectedRecipient5(response?.payload?.data);
        }

        // Call fetchDocumentContact again
        const fetchResponse = await dispatch(fetchDocumentContact({
          baseId,
          page,
          limit,
          search
        }));

        if (fetchResponse.payload?.data?.contact) {
          setRecipients5(fetchResponse.payload.data.contact);
        } else {
          toast.error('Failed to fetch updated contacts.');
        }

      } else if (response?.meta?.requestStatus === "rejected") {
        toast.error(response?.payload || "Contact update request was rejected.");
      } else {
        toast.error('Failed to update contact.');
      }

    } catch (error) {
      console.error("Failed to save contact:", error);
      toast.error(`An error occurred while saving: ${error.message || error}`);
    } finally {
      setContactLoading5(false);
    }

  };


  const handleActionInternalTeam5 = async () => {
    try {
      setIsActionLoading6(true);

      if (!variableType6?.id) {
        toast.error("Please select an internal team.");
        return;
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "sign",
        internal_user_id: variableType6.id,
        member_position: variableType6?.member_position
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      // Check the response status
      if (result.status === 200) {
        const fetchResponse = await dispatch(
          fetchDocumentAction({ baseId, id })
        ).unwrap();

        setIsActionList(fetchResponse);
        toast.success("Action created successfully!");
        setNewAction5(false);
      } else {
        throw new Error("Failed to collect action");
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'action already exists';
      toast.error(err || errorMessage);
      console.error("Error:", errorMessage);
    } finally {
      setIsActionLoading6(false);
    }
  };

  const handleActionExternalContact5 = async () => {
    try {
      setIsActionLoading6(true);

      if (!selectMember5?.id) {
        toast.error("Please select an external contact.");
        return; // Stop the function if external_contact_id is missing
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "sign",
        external_contact_id: selectMember5.content.id,
        member_position: selectMember5.member_position
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      // Check the response status
      if (result.status === 200) {
        const fetchResponse = await dispatch(
          fetchDocumentAction({ baseId, id })
        ).unwrap();

        setIsActionList(fetchResponse);
        toast.success("Action created successfully!");
        setNewAction5(false);
      } else {
        throw new Error("Failed to collect action");
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'action already exists';
      toast.error(err || errorMessage);
      console.error("Error:", errorMessage);
    } finally {
      setIsActionLoading6(false);
    }
  };


  // console.log(isActionListNew,"isActionListNew");

  // base team channel data
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [baseMemberDetailsArray, setBaseMemberDetailsArray] = useState<any[]>([]);


  const handlebaseChannelList = () => {
    const baseMembers = baseMemberDetails?.data?.baseMembers || [];
    const matchingMembers = isActionListNew.data.internal.filter(teamMember => {
      return baseMembers.some(baseMember => baseMember.id == teamMember.member_id);
    });

    const matchingIds = matchingMembers.map(member => ({
      base_member_details: baseMembers.find(baseMember =>
        baseMember.id == member.member_id
      )
    }));

    const filteredMembers = matchingIds.map(item => item.base_member_details);
    setBaseMemberDetailsArray(filteredMembers);
  };

  // Effect to call handlebaseChannelList when dropdown opens
  useEffect(() => {
    if (isDropdownOpen) {
      handlebaseChannelList();
    }
  }, [isDropdownOpen]);



  if (fetchStatus === 'loading') {
    return <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 50px)', width: 'calc(100% - 350px)' }}>
      <div className="simple-spinner-loader">
        <span className='!w-6 !h-6'></span>
      </div>
    </div>;
  }

  if (fetchStatus === 'failed') {
    return <div>Error loading document: {fetchError}</div>;
  }


  return (
    <>

      <div
        className={`main-feedback-side djfhdfhjh flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 
                        border-solid absolute  lg:fixed top-[60px] max-w-[80%] border-r-0 !overflow-y-auto overflow-x-hidden ' `}
        style={{
          width: isAsideOpen4 ? '350px' : '350px',
          transform: isAsideOpen4 ? 'none' : 'none',
          right: isAsideOpen4 ? '51px' : '0px',
          marginRight: isAsideOpen4 ? '0px' : '-350px',
          // transition: 'all 0.3s ease-in-out',

          height: isAsideOpen4 ? 'calc(100vh - +60px)' : '',
          ...(window.innerWidth <= 768 && {
            width: isAsideOpen4 ? '300px' : '300px',
            marginRight: isAsideOpen4 ? '0' : '-300px',
            // transition: 'all 0.3s ease-in-out'
          }),
        }}
      >

        <div className='h-full'>
          <div className='h-auto flex flex-col justify-stretch min-h-[3rem] 
                                           border-b-0 border-l-0 border-solid border-light-gray-200 p-[1rem] pt-[0.9rem] items-start gap-4'>
            <div className=" flex items-start justify-center w-full">
              <div className="mx-auto w-full">
                <p className="text-center text-wedges-gray text-sm" />
                <div className="flex items-center max-w-full w-fit mb-5 font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] ml-[-4px] py-[5px] gap-2">
                  <span className="text-[#52555a] capitalize"> Action</span>
                </div>
                <div className="mb-2">
                  <div className='flex justify-between items-center'>
                    <a
                      href="#"
                      onClick={toggleCollapse4}
                      className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                    >
                      <span className='flex mr-[6px] h-[8px] w-[8px] shrink-0
                            items-center justify-center rounded-[20px] border-none
                             bg-[#15803d]'>

                      </span>
                      Collaborator

                      <div className={`${collapsed4 ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                        <svg
                          width={24}
                          height={24}
                          fill="none"
                          className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M15.25 10.75 12 14.25l-3.25-3.5"
                          />
                        </svg>
                      </div>
                    </a>
                    {/* <button className='underline text-[13px] text-[#266df0]' onClick={handleNewAction}>
                      Add
                    </button> */}

                  </div>
                  {
                    newAction &&
                    <>
                      <nav className='relative w-[100%] my-5 h-full bg-[#f4f5f7] rounded-md p-[6px]' aria-label='Tabs'>
                        {tabs1.map((tab) => (
                          <motion.a
                            key={tab.name}
                            onClick={() => setOpenActionTab(tab.name)}
                            href={tab.href}
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: 0.3 }}
                            className={classNames(
                              tab.name === openActionTab
                                ? 'border-none bg-[#4a5562] rounded-md text-[#fff]'
                                : 'py-1 w-1/2 cursor-pointer select-none focus:outline-none',
                              'py-1 w-1/2 cursor-pointer select-none group inline-flex capitalize items-center text-[12px] font-medium rounded-md justify-center'
                            )}
                          >
                            <span>{tab.name}</span>
                          </motion.a>
                        ))}

                      </nav>
                      {openActionTab === 'internal team' && (
                        <motion.div
                          className="mt-4"
                          initial={{ opacity: 1, y: 0 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.8 }}
                        >
                          <div className="relative w-[100%]">
                            <Listbox value={variableType1} onChange={setVariableType1}>
                              {({ open }) => {
                                setIsDropdownOpen(open);
                                return (
                                  <div>
                                    <label
                                      className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
                  before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
                  peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
                  after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
                  before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                    >
                                      Input type
                                    </label>
                                    <Listbox.Button
                                      className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
                focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
                disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                    >
                                      <div className='flex items-center justify-between'>
                                        <span
                                          className="flex capitalize items-center gap-x-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
                    overflow-hidden text-ellipsis text-start font-medium"
                                        >
                                          {variableType1 ? variableType1.name : "Select an option"}

                                        </span>
                                        <ChevronDownIcon />
                                      </div>
                                    </Listbox.Button>
                                    <Transition
                                      show={open}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Listbox.Options
                                        className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                      >

                                        <>
                                          {/* Check if both datasets are present */}
                                          {isActionListNew?.data?.internal && baseMemberDetailsArray.length > 0 ? (
                                            baseMemberDetailsArray.map((board: any, index: number) => {
                                              // Fetch the `member_colour` for the current `board` (you can adjust logic if needed)
                                              const color =
                                                isActionListNew?.data?.internal[index]?.member_colour || '#f4f5f7'; // Fallback color
                                              const position = isActionListNew?.data?.internal[index]?.member_position
                                              return (
                                                <Listbox.Option
                                                  key={board.id}
                                                  className={({ active }) =>
                                                    classNames(
                                                      active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                      !active ? 'text-dark-black-100 text-[13px]' : '',
                                                      'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                    )
                                                  }
                                                  value={{
                                                    id: board.id,
                                                    name: board.name,
                                                    member_position: position, // Include position here
                                                  }}
                                                >
                                                  {({ selected }) => (
                                                    <span
                                                      className={classNames(
                                                        selected ? 'font-semibold capitalize' : 'font-normal capitalize',
                                                        'flex items-center gap-x-2 truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[280px] w-full overflow-hidden text-ellipsis text-start'
                                                      )}
                                                    >
                                                      <span className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 
                                                       ring-1 ring-inset ring-gray-500/10 w-fit'>
                                                        {position}
                                                      </span>
                                                      {board.profile_pic ? (
                                                        <div className="relative flex justify-center items-center">
                                                          <img
                                                            src={`${process.env.REACT_APP_API_BASE_URL}/${board.profile_pic}`}
                                                            alt={`${board.name}'s profile`}
                                                            className="w-6 h-6 object-cover rounded-full"
                                                          />
                                                          <span
                                                            className="absolute right-0 bottom-0 w-[10px] h-[10px] rounded-full block"
                                                            style={{ backgroundColor: color }}
                                                          ></span>
                                                        </div>
                                                      ) : (
                                                        <div className="relative flex justify-center items-center">
                                                          <span className="w-6 h-6 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                                            {board.name
                                                              .split(' ')
                                                              .map((word: string) => word.charAt(0))
                                                              .join('')
                                                              .toUpperCase()}
                                                          </span>
                                                          <span
                                                            className="absolute right-0 bottom-0 w-[10px] h-[10px] rounded-full block"
                                                            style={{ backgroundColor: color }}
                                                          ></span>
                                                        </div>
                                                      )}
                                                      {board?.name}
                                                    </span>
                                                  )}
                                                </Listbox.Option>
                                              );
                                            })
                                          ) : (
                                            <div className="px-3 py-2 text-[13px] text-gray-500 text-center">
                                              Nothing here
                                            </div>
                                          )}
                                        </>

                                      </Listbox.Options>
                                    </Transition>
                                  </div>
                                );
                              }}
                            </Listbox>
                          </div>
                          <div className='mt-5 flex justify-end'>
                            <button
                              onClick={handleActionInternalTeam}
                              className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                              {isActionLoading1 ? (
                                <>
                                  Create action
                                  <div className="simple-spinner">
                                    <span></span>
                                  </div>
                                </>
                              ) : (
                                'Create action'
                              )}
                            </button>
                          </div>
                        </motion.div>
                      )}
                      {openActionTab === 'external contact' && (
                        <motion.div
                          className="mt-4"
                          initial={{ opacity: 1, y: 0 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.8 }}
                        >
                          <div className="relative">
                            <button
                              type="button"
                              className="flex items-center justify-between h-[40px] px-4 w-full py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                              onClick={() => setIsOpen(!isOpen)}
                            >
                              <span className="mr-2 text-[13px] text-[#374151] new-action">
                                {
                                  selectMember
                                    ? (
                                      <span className=" font-semibold">
                                        {selectMember.content.first_name || ''}
                                        {selectMember.content.last_name || ''}
                                        {!selectMember.content.last_name && !selectMember.content.first_name || ' - '}
                                      </span>
                                    )
                                    : 'Start typing name or email'
                                }
                                {selectMember?.content?.email && (
                                  <span className="text-[14px]">
                                    {selectMember?.content?.email}
                                  </span>
                                )}

                              </span>

                              <svg
                                className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${isOpen ? 'transform rotate-180' : ''}`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            {isOpen && (
                              <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                  <div className="px-4 py-2">
                                    <input
                                      type="text"
                                      className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                      placeholder="Start typing name or email"
                                      value={search}
                                      onChange={(e) => setSearch(e.target.value)}
                                    />
                                  </div>
                                  <div className='max-h-[200px] overflow-auto'>
                                    {isActionListNew?.data?.external
                                      .filter((recipient: any) => {
                                        const searchTerm = search.toLowerCase();
                                        const fullName = `${recipient?.content?.first_name || ''} ${recipient?.content?.last_name || ''}`.toLowerCase();
                                        const email = recipient?.content?.email?.toLowerCase() || '';
                                        return fullName.includes(searchTerm) || email.includes(searchTerm);
                                      })

                                      .map((recipient, index) => (
                                        <a
                                          key={index}
                                          href="#"
                                          className="flex items-center gap-x-3 px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                          onClick={() => handleRecipientSelect(recipient)} // Update the onClick to handle selection
                                        >
                                          <div className='items-center rounded-md bg-gray-50 py-1 px-1 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                            {recipient.member_position}
                                          </div>
                                          <div className="relative flex items-center">
                                            <span
                                              className="flex h-[26px] w-[26px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-black capitalize bg-gray-300">
                                              {
                                                recipient?.content?.first_name && recipient?.content?.last_name
                                                  ? recipient?.content.first_name.charAt(0).toLowerCase() // Show the first letter of first name in lowercase
                                                  : recipient?.content?.last_name
                                                    ? recipient?.content.last_name.charAt(0).toLowerCase() // Show the first letter of last name in lowercase
                                                    : recipient?.content?.email
                                                      ? recipient?.content.email.charAt(0).toLowerCase() // Show the first letter of email in lowercase
                                                      : null // If none of the above exist, return null
                                              }
                                            </span>
                                            <span className='absolute left-[17px] bottom-[0px]
                                               w-[10px] h-[10px] rounded-full' style={{ backgroundColor: recipient.member_colour }}></span>
                                            <div className='flex flex-col gap-y-[2px] ml-2'>
                                              <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  whitespace-nowrap w-[190px] 
                                            overflow-hidden text-ellipsis capitalize">{recipient?.content?.first_name} {recipient?.content.last_name}</span>
                                              <span className="text-[12px] text-[#374151] leading-4 truncate  whitespace-nowrap w-[190px] overflow-hidden text-ellipsis">{recipient?.content.email}</span>
                                            </div>
                                          </div>
                                        </a>
                                      ))}
                                  </div>
                                  <a href="#"
                                    //  onClick={handleNewActionEnteral}
                                    className="flex items-center gap-x-1 px-4 py-2 hover:bg-gray-100">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus w-[1rem] h-[1rem] text-[#ff591e]"
                                    >
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                      <path d="M9 12h6" />
                                      <path d="M12 9v6" />
                                    </svg>

                                    <span className='font-semibold text-[#ff591e] hover:text-[#ff591e]'>
                                      Create new recipient
                                    </span>
                                  </a>
                                </div>
                              </div>
                            )}
                            <div className='mt-5 flex justify-end'>
                              <button
                                onClick={handleActionExternalContact}
                                className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                {isActionLoading ? (
                                  <>
                                    Create action
                                    <div className="simple-spinner">
                                      <span></span>
                                    </div>
                                  </>
                                ) : (
                                  'Create action'
                                )}
                              </button>
                            </div>
                          </div>
                          {
                            newActionEnteral &&
                            <>
                              <div className="">
                                <div className='w-full mt-5'>
                                  <div className='flex gap-y-[24px] flex-col'>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_first_name"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        First Name
                                      </label>
                                      <input
                                        id="contact_first_name"
                                        name="contact_first_name"
                                        type="text"
                                        maxLength={30}
                                        value={contactInfo.contact_first_name}
                                        onChange={handleInputChange}
                                        // placeholder="Jane Smith"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_last_name"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Last Name
                                      </label>
                                      <input
                                        id="contact_last_name"
                                        name="contact_last_name"
                                        type="text"
                                        maxLength={30}
                                        value={contactInfo.contact_last_name}
                                        onChange={handleInputChange}
                                        // placeholder="Jane Smith"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_email"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Email
                                      </label>
                                      <input
                                        id="contact_email"
                                        name="contact_email"
                                        value={contactInfo.contact_email}
                                        onChange={handleInputChange}
                                        maxLength={30}
                                        type="email"

                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_phone"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Phone no.
                                      </label>
                                      <input
                                        id="contact_phone"
                                        name="contact_phone"
                                        type="number"
                                        maxLength={30}
                                        value={contactInfo.contact_phone}
                                        onChange={handleInputChange}
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                            ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_company"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Company
                                      </label>
                                      <input
                                        id="contact_company"
                                        name="contact_company"
                                        value={contactInfo.contact_company}
                                        onChange={handleInputChange}
                                        maxLength={30}
                                        type="text"
                                        // placeholder="1234567890"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_job_title"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Job Title
                                      </label>
                                      <input
                                        id="contact_job_title"
                                        name="contact_job_title"
                                        value={contactInfo.contact_job_title}
                                        onChange={handleInputChange}
                                        type="text"
                                        maxLength={1000}
                                        // placeholder="Enter meta value"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>


                                    <div className='w-auto flex justify-end'>
                                      <button
                                        type="button"
                                        onClick={handleDocumentContactSave}
                                        className=" flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      // onClick={() => handleSubmitItemCustomer(companyInfo)}
                                      // disabled={isUpdating}
                                      >

                                        {contactLoading ? (
                                          <>
                                            Create contact
                                            <div className="simple-spinner">
                                              <span></span>
                                            </div>
                                          </>
                                        ) : (
                                          'Create contact'
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          }



                        </motion.div>
                      )}
                    </>
                  }



                  {
                    !newAction &&
                    <AnimatePresence initial={false}>
                      {!collapsed4 && (
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{ height: 'auto' }}
                          exit={{ height: 0, }}
                          transition={{ height: { duration: 0.5 }, }}
                          className="overflow-hidden"
                        >
                          {
                            isActionList?.data?.collab?.internal.length > 0 &&
                            <span className='font-normal text-dark-black-200 text-[12px]'>Internal - </span>
                          }

                          {isActionList?.data?.collab?.internal &&
                            isActionList.data.collab.internal.map((internalAction, index) => {
                              // Assuming `baseMemberDetails` is an array and you want to find the member matching `internal_user_id`
                              const baseMember = baseMemberDetails?.data?.baseMembers?.find(
                                (member) => member.id === internalAction.internal_user_id
                              );
                              return (
                                <div
                                  key={internalAction.id || index}
                                  className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                >
                                  <div className='flex items-center gap-x-2'>
                                    <div className='items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                      {internalAction.member_position}
                                    </div>
                                    <div className="flex items-center gap-3">
                                      {
                                        baseMember?.profile_pic ? (
                                          <div className=' relative flex justify-center items-center'>
                                            <img
                                              src={`${process.env.REACT_APP_API_BASE_URL}/${baseMember.profile_pic}`}
                                              alt={`${baseMember.name}'s profile`}
                                              className="w-6 h-6 object-cover rounded-full"
                                            />
                                            <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                        rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                          </div>
                                        ) : (
                                          <div className=' relative flex justify-center items-center'>
                                            <span className="w-6 h-6 rounded-full capitalize bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                              {baseMember?.name?.charAt(0)}
                                            </span>
                                            <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                          rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                          </div>
                                        )
                                      }

                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium capitalize">
                                          {baseMember?.name && ` ${baseMember.name}`}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className=' relative'>
                                    {
                                      internalAction.primary_action_status === "pending" &&
                                      <button data-title={'Pending'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-34px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-check w-[1.2rem] h-[1.2rem] text-[#808080] "
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" />
                                          <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" />
                                          <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" />
                                          <path d="M8.56 20.31a9 9 0 0 0 3.44 .69" />
                                          <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" />
                                          <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" />
                                          <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" />
                                          <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }

                                    {
                                      internalAction.primary_action_status === "complete" &&
                                      <button
                                        data-title={'Completed'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-42px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }


                                    {
                                      internalAction.primary_action_status === "lock" &&
                                      <button
                                        data-title={'Locked'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                                        </svg>
                                      </button>
                                    }


                                    {
                                      internalAction.primary_action_status === "reject" &&
                                      <button
                                        data-title={'Reject'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-x w-[1.2rem] h-[1.2rem] text-[#c10007]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" />
                                        </svg>

                                      </button>
                                    }

                                  </div>
                                  {/* <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                    <CustomMenuDropdown
                                      icon={
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="12" cy="5" r="1"></circle>
                                          <circle cx="12" cy="19" r="1"></circle>
                                        </svg>
                                      }
                                      altText="User profile"
                                      sections={dropdownGroups1}
                                      menuItemsClassName="left-auto top-[10px]"
                                    />
                                  </div> */}
                                </div>
                              );
                            })}

                          {
                            isActionList?.data?.collab?.external?.length > 0 && (
                              <>
                                {
                                  [
                                    ...(isActionList?.data?.collab?.external || []),
                                    ...(isActionList?.data?.collab?.external || [])
                                  ]
                                    .slice(0, 1) // Take only the first item
                                    .map((item, index) => {
                                      return (
                                        <div key={index} className="flex items-center space-x-2">
                                          {item.external_contact_id && (
                                            <span className="font-normal text-dark-black-200 text-[12px]">External - </span>
                                          )}
                                        </div>
                                      );
                                    })
                                }
                              </>
                            )
                          }





                          {
                            isActionList?.data?.collab?.external?.length > 0 &&
                            isActionList.data.collab.external.map((internalAction, index) => {
                              const contact = internalAction.external_contact_id
                                ? internalAction.contact
                                : null; // Check if external_contact_id exists and assign contact

                              if (!contact) {
                                return null; // Skip rendering if contact is null
                              }

                              return (
                                <div
                                  key={internalAction.id || index}
                                  className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                >
                                  <div className="flex items-center gap-3">
                                    <div className='items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                      {internalAction.member_position}
                                    </div>
                                    <div className="relative flex-none w-6 h-6 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                      <span className="text-[10px] font-medium text-gray-700 capitalize">
                                        {
                                          contact.contact_first_name
                                            ? contact.contact_first_name.charAt(0).toLowerCase()
                                            : contact.contact_last_name
                                              ? contact.contact_last_name.charAt(0).toLowerCase()
                                              : contact.contact_email
                                                ? contact.contact_email.charAt(0).toLowerCase()
                                                : ''
                                        }
                                      </span>
                                      <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                        rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                    </div>
                                    <div className="flex flex-col">
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium  capitalize">
                                          {contact.contact_first_name && `${contact.contact_first_name} `}
                                          {contact.contact_last_name && `${contact.contact_last_name} `}
                                        </span>
                                      </p>
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                          {contact.contact_email && `${contact.contact_email}`}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className=' relative'>
                                    {
                                      internalAction.primary_action_status === "pending" &&
                                      <button data-title={'Pending'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-34px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-check w-[1.2rem] h-[1.2rem] text-[#808080] "
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" />
                                          <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" />
                                          <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" />
                                          <path d="M8.56 20.31a9 9 0 0 0 3.44 .69" />
                                          <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" />
                                          <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" />
                                          <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" />
                                          <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }

                                    {
                                      internalAction.primary_action_status === "complete" &&
                                      <button
                                        data-title={'Completed'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-42px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }


                                    {
                                      internalAction.primary_action_status === "lock" &&
                                      <button
                                        data-title={'Locked'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                                        </svg>
                                      </button>
                                    }


                                    {
                                      internalAction.primary_action_status === "reject" &&
                                      <button
                                        data-title={'Reject'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-x w-[1.2rem] h-[1.2rem] text-[#c10007]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" />
                                        </svg>

                                      </button>
                                    }

                                  </div>
                                  {/* <div className="[&>div]:shrink feedback-delete-btn-hover">
                                    <CustomMenuDropdown
                                      icon={
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="12" cy="5" r="1"></circle>
                                          <circle cx="12" cy="19" r="1"></circle>
                                        </svg>
                                      }
                                      altText="User profile"
                                      sections={dropdownGroups1}
                                      menuItemsClassName="left-auto top-[5px]"
                                    />
                                  </div> */}
                                </div>
                              );
                            })
                          }





                        </motion.div>
                      )}
                    </AnimatePresence>
                  }
                </div>
                <div className='bg-[#e5e7eb] w-[-webkit-fill-available] h-[1px] mx-[-20px] px-[20px] my-5'></div>
                <div className="mb-2">
                  <div className='flex justify-between items-center'>
                    <a
                      href="#"
                      onClick={toggleCollapse5}
                      className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                    >
                      <span className='flex mr-[6px] h-[8px] w-[8px] shrink-0
                            items-center justify-center rounded-[20px] border-none
                             bg-[#1d4ed8]'>

                      </span>
                      Approval

                      <div className={`${collapsed5 ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                        <svg
                          width={24}
                          height={24}
                          fill="none"
                          className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M15.25 10.75 12 14.25l-3.25-3.5"
                          />
                        </svg>
                      </div>
                    </a>
                    {/* <button className='underline text-[13px] text-[#266df0]' onClick={handleNewAction1}>
                      Add
                    </button> */}
                  </div>

                  {
                    newAction1 &&
                    <>
                      <nav className='relative w-[100%] my-5 h-full bg-[#f4f5f7] rounded-md p-[6px]' aria-label='Tabs'>
                        {tabs2.map((tab) => (
                          <motion.a
                            key={tab.name}
                            onClick={() => setOpenActionTab1(tab.name)}
                            href={tab.href}
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: 0.3 }}
                            className={classNames(
                              tab.name === openActionTab1
                                ? 'border-none bg-[#4a5562] rounded-md text-[#fff]'
                                : 'py-1 w-1/2 cursor-pointer select-none focus:outline-none',
                              'py-1 w-1/2 cursor-pointer select-none group inline-flex capitalize items-center text-[12px] font-medium rounded-md justify-center'
                            )}
                          >
                            <span>{tab.name}</span>
                          </motion.a>
                        ))}

                      </nav>
                      {openActionTab1 === 'internal team' && (
                        <motion.div
                          className="mt-4"
                          initial={{ opacity: 1, y: 0 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.8 }}
                        >
                          <div className="relative w-[100%]">
                            <Listbox value={variableType2} onChange={setVariableType2}>
                              {({ open }) => (
                                <div>
                                  <label
                                    className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
    before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
     peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
     after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
    before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                  >
                                    Input type

                                  </label>
                                  <ListboxButton
                                    onClick={handlebaseChannelList}
                                    className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
                                           focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
                                                  disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                  >
                                    <div className='flex items-center justify-between'>
                                      <span
                                        className="flex capitalize items-center gap-x-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
                                                  overflow-hidden text-ellipsis text-start font-medium"
                                      >
                                        {variableType2 ? variableType2.name : "Select an option"}
                                      </span>
                                      <ChevronDownIcon />
                                    </div>
                                  </ListboxButton>
                                  <Transition
                                    show={open}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <ListboxOptions
                                      className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                    >
                                      {isActionListNew?.data?.internal && baseMemberDetailsArray.length > 0 ? (
                                        baseMemberDetailsArray.map((board: any, index: number) => {
                                          // Fetch the `member_colour` for the current `board` (you can adjust logic if needed)
                                          const color =
                                            isActionListNew?.data?.internal[index]?.member_colour || '#f4f5f7'; // Fallback color
                                          const position = isActionListNew?.data?.internal[index]?.member_position
                                          return (
                                            <Listbox.Option
                                              key={board.id}
                                              className={({ active }) =>
                                                classNames(
                                                  active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                  !active ? 'text-dark-black-100 text-[13px]' : '',
                                                  'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                )
                                              }
                                              value={{
                                                id: board.id,
                                                name: board.name,
                                                member_position: position, // Include position here
                                              }}
                                            >
                                              {({ selected }) => (
                                                <span
                                                  className={classNames(
                                                    selected ? 'font-semibold capitalize' : 'font-normal capitalize',
                                                    'flex items-center gap-x-2 truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[280px] w-full overflow-hidden text-ellipsis text-start'
                                                  )}
                                                >
                                                  <span className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 
                                                       ring-1 ring-inset ring-gray-500/10 w-fit'>
                                                    {position}
                                                  </span>
                                                  {board.profile_pic ? (
                                                    <div className="relative flex justify-center items-center">
                                                      <img
                                                        src={`${process.env.REACT_APP_API_BASE_URL}/${board.profile_pic}`}
                                                        alt={`${board.name}'s profile`}
                                                        className="w-6 h-6 object-cover rounded-full"
                                                      />
                                                      <span
                                                        className="absolute right-0 bottom-0 w-[10px] h-[10px] rounded-full block"
                                                        style={{ backgroundColor: color }}
                                                      ></span>
                                                    </div>
                                                  ) : (
                                                    <div className="relative flex justify-center items-center">
                                                      <span className="w-6 h-6 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                                        {board.name
                                                          .split(' ')
                                                          .map((word: string) => word.charAt(0))
                                                          .join('')
                                                          .toUpperCase()}
                                                      </span>
                                                      <span
                                                        className="absolute right-0 bottom-0 w-[10px] h-[10px] rounded-full block"
                                                        style={{ backgroundColor: color }}
                                                      ></span>
                                                    </div>
                                                  )}
                                                  {board?.name}
                                                </span>
                                              )}
                                            </Listbox.Option>
                                          );
                                        })
                                      ) : (
                                        <div className="px-3 py-2 text-[13px] text-gray-500 text-center">
                                          Nothing here
                                        </div>
                                      )}
                                    </ListboxOptions>
                                  </Transition>
                                </div>
                              )}
                            </Listbox>
                          </div>
                          <div className='mt-5 flex justify-end'>
                            <button
                              onClick={handleActionInternalTeam1}
                              className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                              {isActionLoading2 ? (
                                <>
                                  Create action
                                  <div className="simple-spinner">
                                    <span></span>
                                  </div>
                                </>
                              ) : (
                                'Create action'
                              )}
                            </button>
                          </div>
                        </motion.div>
                      )}
                      {openActionTab1 === 'external contact' && (
                        <motion.div
                          className="mt-4"
                          initial={{ opacity: 1, y: 0 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.8 }}
                        >
                          <div className="relative">
                            <button
                              type="button"
                              className="flex items-center justify-between h-[40px] px-4 w-full py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                              onClick={() => setIsOpen1(!isOpen1)}
                            >
                              <span className="mr-2 text-[13px] text-[#374151] new-action">
                                {
                                  selectMember1
                                    ? (
                                      <span className=" font-semibold">
                                        {selectMember1.content.first_name || ''}
                                        {selectMember1.content.last_name || ''}
                                        {!selectMember1.content.last_name && !selectMember1.content.first_name || ' - '}
                                      </span>
                                    )
                                    : 'Start typing name or email'
                                }
                                {selectMember1?.content?.email && (
                                  <span className="text-[14px]">
                                    {selectMember1?.content?.email}
                                  </span>
                                )}

                              </span>


                              <svg
                                className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${isOpen1 ? 'transform rotate-180' : ''}`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            {isOpen1 && (
                              <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                  <div className="px-4 py-2">
                                    <input
                                      type="text"
                                      className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                      placeholder="Start typing name or email"
                                      value={search1}
                                      onChange={(e) => setSearch1(e.target.value)}
                                    />
                                  </div>
                                  <div className='max-h-[200px] overflow-auto'>
                                    {isActionListNew?.data?.external
                                      .filter((recipient: any) => {
                                        const searchTerm = search1.toLowerCase();
                                        const fullName = `${recipient?.content?.first_name || ''} ${recipient?.content?.last_name || ''}`.toLowerCase();
                                        const email = recipient?.content?.email?.toLowerCase() || '';
                                        return fullName.includes(searchTerm) || email.includes(searchTerm);
                                      })

                                      .map((recipient, index) => (
                                        <a
                                          key={index}
                                          href="#"
                                          className="flex items-center gap-x-3 px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                          onClick={() => handleRecipientSelect1(recipient)} // Update the onClick to handle selection
                                        >
                                          <div className='items-center rounded-md bg-gray-50 py-1 px-1 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                            {recipient.member_position}
                                          </div>
                                          <div className="relative flex items-center">
                                            <span
                                              className="flex h-[26px] w-[26px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-black capitalize bg-gray-300">
                                              {
                                                recipient?.content?.first_name && recipient?.content?.last_name
                                                  ? recipient?.content.first_name.charAt(0).toLowerCase() // Show the first letter of first name in lowercase
                                                  : recipient?.content?.last_name
                                                    ? recipient?.content.last_name.charAt(0).toLowerCase() // Show the first letter of last name in lowercase
                                                    : recipient?.content?.email
                                                      ? recipient?.content.email.charAt(0).toLowerCase() // Show the first letter of email in lowercase
                                                      : null // If none of the above exist, return null
                                              }
                                            </span>
                                            <span className='absolute left-[17px] bottom-[0px]
                                               w-[10px] h-[10px] rounded-full' style={{ backgroundColor: recipient.member_colour }}></span>
                                            <div className='flex flex-col gap-y-[2px] ml-2'>
                                              <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  whitespace-nowrap w-[190px] 
                                            overflow-hidden text-ellipsis capitalize">{recipient?.content?.first_name} {recipient?.content.last_name}</span>
                                              <span className="text-[12px] text-[#374151] leading-4 truncate  whitespace-nowrap w-[190px] overflow-hidden text-ellipsis">{recipient?.content.email}</span>
                                            </div>
                                          </div>
                                        </a>
                                      ))}
                                  </div>
                                  <a href="#"
                                    // onClick={handleNewActionEnteral1}
                                    className="flex items-center gap-x-1 px-4 py-2 hover:bg-gray-100">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus w-[1rem] h-[1rem] text-[#ff591e]"
                                    >
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                      <path d="M9 12h6" />
                                      <path d="M12 9v6" />
                                    </svg>

                                    <span className='font-semibold text-[#ff591e] hover:text-[#ff591e]'>
                                      Create new recipient
                                    </span>
                                  </a>
                                </div>
                              </div>
                            )}
                            <div className='mt-5 flex justify-end'>
                              <button
                                onClick={handleActionExternalContact1}
                                className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                {isActionLoading2 ? (
                                  <>
                                    Create action
                                    <div className="simple-spinner">
                                      <span></span>
                                    </div>
                                  </>
                                ) : (
                                  'Create action'
                                )}
                              </button>
                            </div>
                          </div>
                          {
                            newActionEnteral1 &&
                            <>
                              <div className="">
                                <div className='w-full mt-5'>
                                  <div className='flex gap-y-[24px] flex-col'>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_first_name"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        First Name
                                      </label>
                                      <input
                                        id="contact_first_name"
                                        name="contact_first_name"
                                        type="text"
                                        maxLength={30}
                                        value={contactInfo1.contact_first_name}
                                        onChange={handleInputChange1}
                                        // placeholder="Jane Smith"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_last_name"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Last Name
                                      </label>
                                      <input
                                        id="contact_last_name"
                                        name="contact_last_name"
                                        type="text"
                                        maxLength={30}
                                        value={contactInfo1.contact_last_name}
                                        onChange={handleInputChange1}
                                        // placeholder="Jane Smith"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_email"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Email
                                      </label>
                                      <input
                                        id="contact_email"
                                        name="contact_email"
                                        value={contactInfo1.contact_email}
                                        onChange={handleInputChange1}
                                        maxLength={30}
                                        type="email"

                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_phone"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Phone no.
                                      </label>
                                      <input
                                        id="contact_phone"
                                        name="contact_phone"
                                        type="number"
                                        maxLength={30}
                                        value={contactInfo1.contact_phone}
                                        onChange={handleInputChange1}
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                            ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_company"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Company
                                      </label>
                                      <input
                                        id="contact_company"
                                        name="contact_company"
                                        value={contactInfo1.contact_company}
                                        onChange={handleInputChange1}
                                        maxLength={30}
                                        type="text"
                                        // placeholder="1234567890"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_job_title"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Job Title
                                      </label>
                                      <input
                                        id="contact_job_title"
                                        name="contact_job_title"
                                        value={contactInfo1.contact_job_title}
                                        onChange={handleInputChange1}
                                        type="text"
                                        maxLength={1000}
                                        // placeholder="Enter meta value"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>


                                    <div className='w-auto flex justify-end'>
                                      <button
                                        type="button"
                                        onClick={handleDocumentContactSave1}
                                        className=" flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      // onClick={() => handleSubmitItemCustomer(companyInfo)}
                                      // disabled={isUpdating}
                                      >

                                        {contactLoading1 ? (
                                          <>
                                            Create contact
                                            <div className="simple-spinner">
                                              <span></span>
                                            </div>
                                          </>
                                        ) : (
                                          'Create contact'
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          }



                        </motion.div>
                      )}
                    </>
                  }



                  {
                    !newAction1 &&
                    <AnimatePresence initial={false}>
                      {!collapsed5 && (
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{ height: 'auto' }}
                          exit={{ height: 0, }}
                          transition={{ height: { duration: 0.5 }, }}
                          className="overflow-hidden"
                        >
                          {isActionList?.data?.approval?.internal.length > 0 &&
                            <span className='font-normal text-dark-black-200 text-[12px]'>Internal - </span>
                          }
                          {isActionList?.data?.approval?.internal &&
                            isActionList.data.approval.internal.map((internalAction, index) => {
                              // Assuming `baseMemberDetails` is an array and you want to find the member matching `internal_user_id`
                              const baseMember = baseMemberDetails?.data?.baseMembers?.find(
                                (member) => member.id === internalAction.internal_user_id
                              );

                              return (
                                <div
                                  key={internalAction.id || index}
                                  className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                >
                                  <div className='flex items-center gap-x-2'>
                                    <div className='items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                      {internalAction.member_position}
                                    </div>
                                    <div className="flex items-center gap-3">
                                      {
                                        baseMember?.profile_pic ? (
                                          <div className=' relative flex justify-center items-center'>
                                            <img
                                              src={`${process.env.REACT_APP_API_BASE_URL}/${baseMember.profile_pic}`}
                                              alt={`${baseMember.name}'s profile`}
                                              className="w-6 h-6 object-cover rounded-full"
                                            />
                                            <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                        rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                          </div>
                                        ) : (
                                          <div className=' relative flex justify-center items-center'>
                                            <span className="w-6 h-6 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                              {baseMember?.name?.charAt(0)}
                                            </span>
                                            <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                          rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                          </div>
                                        )
                                      }
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium capitalize">
                                          {baseMember?.name && ` ${baseMember.name}`}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className=' relative'>
                                    {
                                      internalAction.primary_action_status === "pending" &&
                                      <button data-title={'Pending'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-34px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-check w-[1.2rem] h-[1.2rem] text-[#808080] "
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" />
                                          <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" />
                                          <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" />
                                          <path d="M8.56 20.31a9 9 0 0 0 3.44 .69" />
                                          <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" />
                                          <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" />
                                          <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" />
                                          <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }

                                    {
                                      internalAction.primary_action_status === "complete" &&
                                      <button
                                        data-title={'Completed'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-42px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }


                                    {
                                      internalAction.primary_action_status === "lock" &&
                                      <button
                                        data-title={'Locked'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                                        </svg>
                                      </button>
                                    }


                                    {
                                      internalAction.primary_action_status === "reject" &&
                                      <button
                                        data-title={'Reject'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-x w-[1.2rem] h-[1.2rem] text-[#c10007]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" />
                                        </svg>

                                      </button>
                                    }

                                  </div>
                                  {/* <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                    <CustomMenuDropdown
                                      icon={
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="12" cy="5" r="1"></circle>
                                          <circle cx="12" cy="19" r="1"></circle>
                                        </svg>
                                      }
                                      altText="User profile"
                                      sections={dropdownGroups1}
                                      menuItemsClassName="left-auto top-[10px]"
                                    />
                                  </div> */}
                                </div>
                              );
                            })}
                          {
                            isActionList?.data?.approval?.external?.length > 0 && (
                              <>
                                {
                                  // Combine the two lists and get only the first one.
                                  [
                                    ...(isActionList?.data?.approval?.external || []),
                                    ...(isActionList?.data?.approval?.external || [])
                                  ]
                                    .slice(0, 1) // Take only the first item
                                    .map((item, index) => {

                                      return (
                                        <div key={index} className="flex items-center space-x-2">
                                          {item.external_contact_id && (
                                            <span className="font-normal text-dark-black-200 text-[12px]">External - </span>
                                          )}
                                        </div>
                                      );
                                    })
                                }
                              </>
                            )
                          }
                          {
                            isActionList?.data?.approval?.external?.length > 0 &&
                            isActionList.data.approval.external.map((internalAction, index) => {

                              // This assumes internalAction contains the 'contact_first_name', 'contact_last_name', 'contact_email', etc.
                              return (
                                <div
                                  key={internalAction.id || index}
                                  className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                >
                                  <div className="flex items-center gap-3">
                                    <div className='items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                      {internalAction.member_position}
                                    </div>
                                    <div className="relative flex-none w-6 h-6 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                      <span className="text-[10px] font-medium text-gray-700 capitalize">
                                        {
                                          internalAction?.contact?.contact_first_name
                                            ? internalAction.contact.contact_first_name.charAt(0).toLowerCase()
                                            : internalAction?.contact?.contact_last_name
                                              ? internalAction.contact.contact_last_name.charAt(0).toLowerCase()
                                              : internalAction?.contact?.contact_email
                                                ? internalAction.contact.contact_email.charAt(0).toLowerCase()
                                                : ''
                                        }
                                      </span>
                                      <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                        rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                    </div>
                                    <div className='flex flex-col'>
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium capitalize">
                                          {internalAction?.contact?.contact_first_name && `${internalAction?.contact?.contact_first_name} `}
                                          {internalAction?.contact?.contact_last_name && `${internalAction?.contact?.contact_last_name} `}
                                        </span>
                                      </p>
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                          {internalAction?.contact?.contact_email && `${internalAction?.contact?.contact_email}`}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className=' relative'>
                                    {
                                      internalAction.primary_action_status === "pending" &&
                                      <button data-title={'Pending'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-34px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-check w-[1.2rem] h-[1.2rem] text-[#808080] "
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" />
                                          <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" />
                                          <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" />
                                          <path d="M8.56 20.31a9 9 0 0 0 3.44 .69" />
                                          <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" />
                                          <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" />
                                          <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" />
                                          <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }

                                    {
                                      internalAction.primary_action_status === "complete" &&
                                      <button
                                        data-title={'Completed'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-42px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }


                                    {
                                      internalAction.primary_action_status === "lock" &&
                                      <button
                                        data-title={'Locked'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                                        </svg>
                                      </button>
                                    }


                                    {
                                      internalAction.primary_action_status === "reject" &&
                                      <button
                                        data-title={'Reject'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-x w-[1.2rem] h-[1.2rem] text-[#c10007]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" />
                                        </svg>

                                      </button>
                                    }

                                  </div>
                                  {/* <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                    <CustomMenuDropdown
                                      icon={
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="12" cy="5" r="1"></circle>
                                          <circle cx="12" cy="19" r="1"></circle>
                                        </svg>
                                      }
                                      altText="User profile"
                                      sections={dropdownGroups1}
                                      menuItemsClassName="left-auto top-[5px]"
                                    />
                                  </div> */}
                                </div>
                              );
                            })
                          }
                        </motion.div>
                      )}
                    </AnimatePresence>
                  }

                </div>
                <div className='bg-[#e5e7eb] w-[-webkit-fill-available] h-[1px] mx-[-20px] px-[20px] my-5'></div>
                <div className="mb-2">
                  <div className='flex justify-between items-center'>
                    <a
                      href="#"
                      onClick={toggleCollapse6}
                      className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                    >
                      <span className='flex mr-[6px] h-[8px] w-[8px] shrink-0
                            items-center justify-center rounded-[20px] border-none
                             bg-[#1d4ed8]'>

                      </span>
                      View

                      <div className={`${collapsed6 ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                        <svg
                          width={24}
                          height={24}
                          fill="none"
                          className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M15.25 10.75 12 14.25l-3.25-3.5"
                          />
                        </svg>
                      </div>
                    </a>
                    {/* <button className='underline text-[13px] text-[#266df0]' onClick={handleNewAction2}>
                      Add
                    </button> */}
                  </div>

                  {
                    newAction2 &&
                    <>
                      <nav className='relative w-[100%] my-5 h-full bg-[#f4f5f7] rounded-md p-[6px]' aria-label='Tabs'>
                        {tabs3.map((tab) => (
                          <motion.a
                            key={tab.name}
                            onClick={() => setOpenActionTab2(tab.name)}
                            href={tab.href}
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: 0.3 }}
                            className={classNames(
                              tab.name === openActionTab2
                                ? 'border-none bg-[#4a5562] rounded-md text-[#fff]'
                                : 'py-1 w-1/2 cursor-pointer select-none focus:outline-none',
                              'py-1 w-1/2 cursor-pointer select-none group inline-flex capitalize items-center text-[12px] font-medium rounded-md justify-center'
                            )}
                          >
                            <span>{tab.name}</span>
                          </motion.a>
                        ))}

                      </nav>
                      {openActionTab2 === 'internal team' && (
                        <motion.div
                          className="mt-4"
                          initial={{ opacity: 1, y: 0 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.8 }}
                        >
                          <div className="relative w-[100%]">
                            <Listbox value={variableType3} onChange={setVariableType3}>
                              {({ open }) => (
                                <div>
                                  <label
                                    className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
    before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
     peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
     after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
    before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                  >
                                    Input type

                                  </label>
                                  <ListboxButton
                                    onClick={handlebaseChannelList}
                                    className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
                                           focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
                                                  disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                  >
                                    <div className='flex items-center justify-between'>
                                      <span
                                        className="flex capitalize items-center gap-x-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
                                                  overflow-hidden text-ellipsis text-start font-medium"
                                      >
                                        {variableType3 ? variableType3.name : "Select an option"}
                                      </span>
                                      <ChevronDownIcon />
                                    </div>
                                  </ListboxButton>
                                  <Transition
                                    show={open}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <ListboxOptions
                                      className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                    >
                                      {isActionListNew?.data?.internal && baseMemberDetailsArray.length > 0 ? (
                                        baseMemberDetailsArray.map((board: any, index: number) => {
                                          // Fetch the `member_colour` for the current `board` (you can adjust logic if needed)
                                          const color =
                                            isActionListNew?.data?.internal[index]?.member_colour || '#f4f5f7'; // Fallback color
                                          const position = isActionListNew?.data?.internal[index]?.member_position
                                          return (
                                            <Listbox.Option
                                              key={board.id}
                                              className={({ active }) =>
                                                classNames(
                                                  active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                  !active ? 'text-dark-black-100 text-[13px]' : '',
                                                  'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                )
                                              }
                                              value={{
                                                id: board.id,
                                                name: board.name,
                                                member_position: position, // Include position here
                                              }}
                                            >
                                              {({ selected }) => (
                                                <span
                                                  className={classNames(
                                                    selected ? 'font-semibold capitalize' : 'font-normal capitalize',
                                                    'flex items-center gap-x-2 truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[280px] w-full overflow-hidden text-ellipsis text-start'
                                                  )}
                                                >
                                                  <span className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 
                                                       ring-1 ring-inset ring-gray-500/10 w-fit'>
                                                    {position}
                                                  </span>
                                                  {board.profile_pic ? (
                                                    <div className="relative flex justify-center items-center">
                                                      <img
                                                        src={`${process.env.REACT_APP_API_BASE_URL}/${board.profile_pic}`}
                                                        alt={`${board.name}'s profile`}
                                                        className="w-6 h-6 object-cover rounded-full"
                                                      />
                                                      <span
                                                        className="absolute right-0 bottom-0 w-[10px] h-[10px] rounded-full block"
                                                        style={{ backgroundColor: color }}
                                                      ></span>
                                                    </div>
                                                  ) : (
                                                    <div className="relative flex justify-center items-center">
                                                      <span className="w-6 h-6 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                                        {board.name
                                                          .split(' ')
                                                          .map((word: string) => word.charAt(0))
                                                          .join('')
                                                          .toUpperCase()}
                                                      </span>
                                                      <span
                                                        className="absolute right-0 bottom-0 w-[10px] h-[10px] rounded-full block"
                                                        style={{ backgroundColor: color }}
                                                      ></span>
                                                    </div>
                                                  )}
                                                  {board?.name}
                                                </span>
                                              )}
                                            </Listbox.Option>
                                          );
                                        })
                                      ) : (
                                        <div className="px-3 py-2 text-[13px] text-gray-500 text-center">
                                          Nothing here
                                        </div>
                                      )}
                                    </ListboxOptions>
                                  </Transition>
                                </div>
                              )}
                            </Listbox>
                          </div>
                          <div className='mt-5 flex justify-end'>
                            <button
                              onClick={handleActionInternalTeam2}
                              className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                              {isActionLoading3 ? (
                                <>
                                  Create action
                                  <div className="simple-spinner">
                                    <span></span>
                                  </div>
                                </>
                              ) : (
                                'Create action'
                              )}
                            </button>
                          </div>
                        </motion.div>
                      )}
                      {openActionTab2 === 'external contact' && (
                        <motion.div
                          className="mt-4"
                          initial={{ opacity: 1, y: 0 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.8 }}
                        >
                          <div className="relative">
                            <button
                              type="button"
                              className="flex items-center justify-between h-[40px] px-4 w-full py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                              onClick={() => setIsOpen2(!isOpen2)}
                            >
                              <span className="mr-2 text-[13px] text-[#374151] new-action">
                                {
                                  selectMember2
                                    ? (
                                      <span className=" font-semibold">
                                        {selectMember2.content.first_name || ''}
                                        {selectMember2.content.last_name || ''}
                                        {!selectMember2.content.last_name && !selectMember2.content.first_name || ' - '}
                                      </span>
                                    )
                                    : 'Start typing name or email'
                                }
                                {selectMember2?.content?.email && (
                                  <span className="text-[14px]">
                                    {selectMember2?.content?.email}
                                  </span>
                                )}

                              </span>

                              <svg
                                className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${isOpen2 ? 'transform rotate-180' : ''}`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            {isOpen2 && (
                              <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                  <div className="px-4 py-2">
                                    <input
                                      type="text"
                                      className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                      placeholder="Start typing name or email"
                                      value={search2}
                                      onChange={(e) => setSearch2(e.target.value)}
                                    />
                                  </div>
                                  <div className='max-h-[200px] overflow-auto'>
                                    {isActionListNew?.data?.external
                                      .filter((recipient: any) => {
                                        const searchTerm = search2.toLowerCase();
                                        const fullName = `${recipient?.content?.first_name || ''} ${recipient?.content?.last_name || ''}`.toLowerCase();
                                        const email = recipient?.content?.email?.toLowerCase() || '';
                                        return fullName.includes(searchTerm) || email.includes(searchTerm);
                                      })

                                      .map((recipient, index) => (
                                        <a
                                          key={index}
                                          href="#"
                                          className="flex items-center gap-x-3 px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                          onClick={() => handleRecipientSelect2(recipient)} // Update the onClick to handle selection
                                        >
                                          <div className='items-center rounded-md bg-gray-50 py-1 px-1 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                            {recipient.member_position}
                                          </div>
                                          <div className="relative flex items-center">
                                            <span
                                              className="flex h-[26px] w-[26px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-black capitalize bg-gray-300">
                                              {
                                                recipient?.content?.first_name && recipient?.content?.last_name
                                                  ? recipient?.content.first_name.charAt(0).toLowerCase() // Show the first letter of first name in lowercase
                                                  : recipient?.content?.last_name
                                                    ? recipient?.content.last_name.charAt(0).toLowerCase() // Show the first letter of last name in lowercase
                                                    : recipient?.content?.email
                                                      ? recipient?.content.email.charAt(0).toLowerCase() // Show the first letter of email in lowercase
                                                      : null // If none of the above exist, return null
                                              }
                                            </span>
                                            <span className='absolute left-[17px] bottom-[0px]
                                               w-[10px] h-[10px] rounded-full' style={{ backgroundColor: recipient.member_colour }}></span>
                                            <div className='flex flex-col gap-y-[2px] ml-2'>
                                              <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  whitespace-nowrap w-[190px] 
                                            overflow-hidden text-ellipsis capitalize">{recipient?.content?.first_name} {recipient?.content.last_name}</span>
                                              <span className="text-[12px] text-[#374151] leading-4 truncate  whitespace-nowrap w-[190px] overflow-hidden text-ellipsis">{recipient?.content.email}</span>
                                            </div>
                                          </div>
                                        </a>
                                      ))}
                                  </div>
                                  <a href="#"
                                    // onClick={handleNewActionEnteral2} 
                                    className="flex items-center gap-x-1 px-4 py-2 hover:bg-gray-100">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus w-[1rem] h-[1rem] text-[#ff591e]"
                                    >
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                      <path d="M9 12h6" />
                                      <path d="M12 9v6" />
                                    </svg>

                                    <span className='font-semibold text-[#ff591e] hover:text-[#ff591e]'>
                                      Create new recipient
                                    </span>
                                  </a>
                                </div>
                              </div>
                            )}
                            <div className='mt-5 flex justify-end'>
                              <button
                                onClick={handleActionExternalContact2}
                                className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                {isActionLoading3 ? (
                                  <>
                                    Create action
                                    <div className="simple-spinner">
                                      <span></span>
                                    </div>
                                  </>
                                ) : (
                                  'Create action'
                                )}
                              </button>
                            </div>
                          </div>
                          {
                            newActionEnteral2 &&
                            <>
                              <div className="">
                                <div className='w-full mt-5'>
                                  <div className='flex gap-y-[24px] flex-col'>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_first_name"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        First Name
                                      </label>
                                      <input
                                        id="contact_first_name"
                                        name="contact_first_name"
                                        type="text"
                                        maxLength={30}
                                        value={contactInfo2.contact_first_name}
                                        onChange={handleInputChange2}
                                        // placeholder="Jane Smith"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_last_name"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Last Name
                                      </label>
                                      <input
                                        id="contact_last_name"
                                        name="contact_last_name"
                                        type="text"
                                        maxLength={30}
                                        value={contactInfo2.contact_last_name}
                                        onChange={handleInputChange2}
                                        // placeholder="Jane Smith"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_email"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Email
                                      </label>
                                      <input
                                        id="contact_email"
                                        name="contact_email"
                                        value={contactInfo2.contact_email}
                                        onChange={handleInputChange2}
                                        maxLength={30}
                                        type="email"

                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_phone"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Phone no.
                                      </label>
                                      <input
                                        id="contact_phone"
                                        name="contact_phone"
                                        type="number"
                                        maxLength={30}
                                        value={contactInfo2.contact_phone}
                                        onChange={handleInputChange2}
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                            ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_company"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Company
                                      </label>
                                      <input
                                        id="contact_company"
                                        name="contact_company"
                                        value={contactInfo2.contact_company}
                                        onChange={handleInputChange2}
                                        maxLength={30}
                                        type="text"
                                        // placeholder="1234567890"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_job_title"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Job Title
                                      </label>
                                      <input
                                        id="contact_job_title"
                                        name="contact_job_title"
                                        value={contactInfo2.contact_job_title}
                                        onChange={handleInputChange2}
                                        type="text"
                                        maxLength={1000}
                                        // placeholder="Enter meta value"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>


                                    <div className='w-auto flex justify-end'>
                                      <button
                                        type="button"
                                        onClick={handleDocumentContactSave2}
                                        className=" flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      // onClick={() => handleSubmitItemCustomer(companyInfo)}
                                      // disabled={isUpdating}
                                      >

                                        {contactLoading2 ? (
                                          <>
                                            Create contact
                                            <div className="simple-spinner">
                                              <span></span>
                                            </div>
                                          </>
                                        ) : (
                                          'Create contact'
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          }



                        </motion.div>
                      )}
                    </>
                  }



                  {
                    !newAction2 &&
                    <AnimatePresence initial={false}>
                      {!collapsed6 && (
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{ height: 'auto' }}
                          exit={{ height: 0, }}
                          transition={{ height: { duration: 0.5 }, }}
                          className="overflow-hidden"
                        >
                          {isActionList?.data?.view?.internal.length > 0 &&
                            <span className='font-normal text-dark-black-200 text-[12px]'>Internal - </span>
                          }
                          {isActionList?.data?.view?.internal &&
                            isActionList.data.view.internal.map((internalAction, index) => {
                              // Assuming `baseMemberDetails` is an array and you want to find the member matching `internal_user_id`
                              const baseMember = baseMemberDetails?.data?.baseMembers?.find(
                                (member) => member.id === internalAction.internal_user_id
                              );

                              return (
                                <div
                                  key={internalAction.id || index}
                                  className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                >
                                  <div className='flex items-center gap-x-2'>
                                    <div className='items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                      {internalAction.member_position}
                                    </div>
                                    <div className="flex items-center gap-3">
                                      {
                                        baseMember?.profile_pic ? (
                                          <div className=' relative flex justify-center items-center'>
                                            <img
                                              src={`${process.env.REACT_APP_API_BASE_URL}/${baseMember.profile_pic}`}
                                              alt={`${baseMember.name}'s profile`}
                                              className="w-6 h-6 object-cover rounded-full"
                                            />
                                            <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                        rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                          </div>
                                        ) : (
                                          <div className=' relative flex justify-center items-center'>
                                            <span className="w-6 h-6 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                              {baseMember?.name?.charAt(0)}
                                            </span>
                                            <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                          rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                          </div>
                                        )
                                      }
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium capitalize">
                                          {baseMember?.name && ` ${baseMember.name}`}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className=' relative'>
                                    {
                                      internalAction.primary_action_status === "pending" &&
                                      <button data-title={'Pending'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-34px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-check w-[1.2rem] h-[1.2rem] text-[#808080] "
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" />
                                          <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" />
                                          <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" />
                                          <path d="M8.56 20.31a9 9 0 0 0 3.44 .69" />
                                          <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" />
                                          <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" />
                                          <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" />
                                          <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }

                                    {
                                      internalAction.primary_action_status === "complete" &&
                                      <button
                                        data-title={'Completed'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-42px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }


                                    {
                                      internalAction.primary_action_status === "lock" &&
                                      <button
                                        data-title={'Locked'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                                        </svg>
                                      </button>
                                    }


                                    {
                                      internalAction.primary_action_status === "reject" &&
                                      <button
                                        data-title={'Reject'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-x w-[1.2rem] h-[1.2rem] text-[#c10007]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" />
                                        </svg>

                                      </button>
                                    }

                                  </div>
                                  {/* <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                    <CustomMenuDropdown
                                      icon={
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="12" cy="5" r="1"></circle>
                                          <circle cx="12" cy="19" r="1"></circle>
                                        </svg>
                                      }
                                      altText="User profile"
                                      sections={dropdownGroups1}
                                      menuItemsClassName="left-auto top-[10px]"
                                    />
                                  </div> */}
                                </div>
                              );
                            })}

                          {
                            isActionList?.data?.view?.external?.length > 0 && (
                              <>
                                {
                                  // Combine the two lists and get only the first one.
                                  [
                                    ...(isActionList?.data?.view?.external || []),
                                    ...(isActionList?.data?.view?.external || [])
                                  ]
                                    .slice(0, 1) // Take only the first item
                                    .map((item, index) => {

                                      return (
                                        <div key={index} className="flex items-center space-x-2">
                                          {item.external_contact_id && (
                                            <span className="font-normal text-dark-black-200 text-[12px]">External - </span>
                                          )}
                                        </div>
                                      );
                                    })
                                }
                              </>
                            )
                          }
                          {
                            isActionList?.data?.view?.external?.length > 0 &&
                            isActionList.data.view.external.map((internalAction, index) => {


                              // This assumes internalAction contains the 'contact_first_name', 'contact_last_name', 'contact_email', etc.
                              return (
                                <div
                                  key={internalAction.id || index}
                                  className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                >

                                  <div className="flex items-center gap-3">
                                    <div className='items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                      {internalAction.member_position}
                                    </div>
                                    <div className="relative flex-none w-6 h-6 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                      <span className="text-[10px] font-medium text-gray-700 capitalize">
                                        {
                                          internalAction?.contact?.contact_first_name
                                            ? internalAction.contact.contact_first_name.charAt(0).toLowerCase()
                                            : internalAction?.contact?.contact_last_name
                                              ? internalAction.contact.contact_last_name.charAt(0).toLowerCase()
                                              : internalAction?.contact?.contact_email
                                                ? internalAction.contact.contact_email.charAt(0).toLowerCase()
                                                : ''
                                        }


                                      </span>
                                      <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                        rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                    </div>
                                    <div className='flex flex-col'>
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium capitalize">
                                          {internalAction?.contact?.contact_first_name && `${internalAction?.contact?.contact_first_name} `}
                                          {internalAction?.contact?.contact_last_name && `${internalAction?.contact?.contact_last_name} `}
                                        </span>
                                      </p>
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                          {internalAction?.contact?.contact_email && `${internalAction?.contact?.contact_email}`}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className=' relative'>
                                    {
                                      internalAction.primary_action_status === "pending" &&
                                      <button data-title={'Pending'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-34px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-check w-[1.2rem] h-[1.2rem] text-[#808080] "
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" />
                                          <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" />
                                          <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" />
                                          <path d="M8.56 20.31a9 9 0 0 0 3.44 .69" />
                                          <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" />
                                          <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" />
                                          <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" />
                                          <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }

                                    {
                                      internalAction.primary_action_status === "complete" &&
                                      <button
                                        data-title={'Completed'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-42px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }


                                    {
                                      internalAction.primary_action_status === "lock" &&
                                      <button
                                        data-title={'Locked'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                                        </svg>
                                      </button>
                                    }


                                    {
                                      internalAction.primary_action_status === "reject" &&
                                      <button
                                        data-title={'Reject'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-x w-[1.2rem] h-[1.2rem] text-[#c10007]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" />
                                        </svg>

                                      </button>
                                    }

                                  </div>
                                  {/* <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                    <CustomMenuDropdown
                                      icon={
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="12" cy="5" r="1"></circle>
                                          <circle cx="12" cy="19" r="1"></circle>
                                        </svg>
                                      }
                                      altText="User profile"
                                      sections={dropdownGroups1}
                                      menuItemsClassName="left-auto top-[5px]"
                                    />
                                  </div> */}
                                </div>
                              );
                            })
                          }


                        </motion.div>
                      )}
                    </AnimatePresence>
                  }

                </div>
                <div className='bg-[#e5e7eb] w-[-webkit-fill-available] h-[1px] mx-[-20px] px-[20px] my-5'></div>
                <div className="mb-2">
                  <div className='flex justify-between items-center'>
                    <a
                      href="#"
                      onClick={toggleCollapse7}
                      className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                    >
                      <span className='flex mr-[6px] h-[8px] w-[8px] shrink-0
                            items-center justify-center rounded-[20px] border-none
                             bg-[#7e22ce]'>

                      </span>
                      Fill

                      <div className={`${collapsed7 ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                        <svg
                          width={24}
                          height={24}
                          fill="none"
                          className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M15.25 10.75 12 14.25l-3.25-3.5"
                          />
                        </svg>
                      </div>
                    </a>
                    {/* <button className='underline text-[13px] text-[#266df0]' onClick={handleNewAction3}>
                      Add
                    </button> */}
                  </div>

                  {
                    newAction3 &&
                    <>
                      <nav className='relative w-[100%] my-5 h-full bg-[#f4f5f7] rounded-md p-[6px]' aria-label='Tabs'>
                        {tabs4.map((tab) => (
                          <motion.a
                            key={tab.name}
                            onClick={() => setOpenActionTab3(tab.name)}
                            href={tab.href}
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: 0.3 }}
                            className={classNames(
                              tab.name === openActionTab3
                                ? 'border-none bg-[#4a5562] rounded-md text-[#fff]'
                                : 'py-1 w-1/2 cursor-pointer select-none focus:outline-none',
                              'py-1 w-1/2 cursor-pointer select-none group inline-flex capitalize items-center text-[12px] font-medium rounded-md justify-center'
                            )}
                          >
                            <span>{tab.name}</span>
                          </motion.a>
                        ))}

                      </nav>
                      {openActionTab3 === 'internal team' && (
                        <motion.div
                          className="mt-4"
                          initial={{ opacity: 1, y: 0 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.8 }}
                        >
                          <div className="relative w-[100%]">
                            <Listbox value={variableType4} onChange={setVariableType4}>
                              {({ open }) => (
                                <div>
                                  <label
                                    className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
    before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
     peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
     after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
    before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                  >
                                    Input type

                                  </label>
                                  <ListboxButton
                                    onClick={handlebaseChannelList}
                                    className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
                                           focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
                                                  disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                  >
                                    <div className='flex items-center justify-between'>
                                      <span
                                        className="flex capitalize items-center gap-x-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
                                                  overflow-hidden text-ellipsis text-start font-medium"
                                      >
                                        {variableType4 ? variableType4.name : "Select an option"}
                                      </span>
                                      <ChevronDownIcon />
                                    </div>
                                  </ListboxButton>
                                  <Transition
                                    show={open}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <ListboxOptions
                                      className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                    >
                                      <>
                                        {/* Check if both datasets are present */}
                                        {isActionListNew?.data?.internal && baseMemberDetailsArray.length > 0 ? (
                                          baseMemberDetailsArray.map((board: any, index: number) => {
                                            // Fetch the `member_colour` for the current `board` (you can adjust logic if needed)
                                            const color =
                                              isActionListNew?.data?.internal[index]?.member_colour || '#f4f5f7'; // Fallback color
                                            const position = isActionListNew?.data?.internal[index]?.member_position
                                            return (
                                              <Listbox.Option
                                                key={board.id}
                                                className={({ active }) =>
                                                  classNames(
                                                    active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                    !active ? 'text-dark-black-100 text-[13px]' : '',
                                                    'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                  )
                                                }
                                                value={{
                                                  id: board.id,
                                                  name: board.name,
                                                  member_position: position, // Include position here
                                                }}
                                              >
                                                {({ selected }) => (
                                                  <span
                                                    className={classNames(
                                                      selected ? 'font-semibold capitalize' : 'font-normal capitalize',
                                                      'flex items-center gap-x-2 truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[280px] w-full overflow-hidden text-ellipsis text-start'
                                                    )}
                                                  >
                                                    <span className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 
                                                       ring-1 ring-inset ring-gray-500/10 w-fit'>
                                                      {position}
                                                    </span>
                                                    {board.profile_pic ? (
                                                      <div className="relative flex justify-center items-center">
                                                        <img
                                                          src={`${process.env.REACT_APP_API_BASE_URL}/${board.profile_pic}`}
                                                          alt={`${board.name}'s profile`}
                                                          className="w-6 h-6 object-cover rounded-full"
                                                        />
                                                        <span
                                                          className="absolute right-0 bottom-0 w-[10px] h-[10px] rounded-full block"
                                                          style={{ backgroundColor: color }}
                                                        ></span>
                                                      </div>
                                                    ) : (
                                                      <div className="relative flex justify-center items-center">
                                                        <span className="w-6 h-6 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                                          {board.name
                                                            .split(' ')
                                                            .map((word: string) => word.charAt(0))
                                                            .join('')
                                                            .toUpperCase()}
                                                        </span>
                                                        <span
                                                          className="absolute right-0 bottom-0 w-[10px] h-[10px] rounded-full block"
                                                          style={{ backgroundColor: color }}
                                                        ></span>
                                                      </div>
                                                    )}
                                                    {board?.name}
                                                  </span>
                                                )}
                                              </Listbox.Option>
                                            );
                                          })
                                        ) : (
                                          <div className="px-3 py-2 text-[13px] text-gray-500 text-center">
                                            Nothing here
                                          </div>
                                        )}
                                      </>
                                    </ListboxOptions>
                                  </Transition>
                                </div>
                              )}
                            </Listbox>
                          </div>
                          <div className='mt-5 flex justify-end'>
                            <button
                              onClick={handleActionInternalTeam3}
                              className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                              {isActionLoading4 ? (
                                <>
                                  Create action
                                  <div className="simple-spinner">
                                    <span></span>
                                  </div>
                                </>
                              ) : (
                                'Create action'
                              )}
                            </button>
                          </div>
                        </motion.div>
                      )}
                      {openActionTab3 === 'external contact' && (
                        <motion.div
                          className="mt-4"
                          initial={{ opacity: 1, y: 0 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.8 }}
                        >
                          <div className="relative">
                            <button
                              type="button"
                              className="flex items-center justify-between h-[40px] px-4 w-full py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                              onClick={() => setIsOpen3(!isOpen3)}
                            >
                              <span className="mr-2 text-[13px] text-[#374151] new-action">
                                {
                                  selectMember3
                                    ? (
                                      <span className=" font-semibold">
                                        {selectMember3.content.first_name || ''}
                                        {selectMember3.content.last_name || ''}
                                        {!selectMember3.content.last_name && !selectMember3.content.first_name || ' - '}
                                      </span>
                                    )
                                    : 'Start typing name or email'
                                }
                                {selectMember3?.content?.email && (
                                  <span className="text-[14px]">
                                    {selectMember3?.content?.email}
                                  </span>
                                )}

                              </span>

                              <svg
                                className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${isOpen3 ? 'transform rotate-180' : ''}`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            {isOpen3 && (
                              <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                  <div className="px-4 py-2">
                                    <input
                                      type="text"
                                      className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                      placeholder="Start typing name or email"
                                      value={search3}
                                      onChange={(e) => setSearch3(e.target.value)}
                                    />
                                  </div>
                                  <div className='max-h-[200px] overflow-auto'>
                                    {isActionListNew?.data?.external
                                      .filter((recipient: any) => {
                                        const searchTerm = search3.toLowerCase();
                                        const fullName = `${recipient?.content?.first_name || ''} ${recipient?.content?.last_name || ''}`.toLowerCase();
                                        const email = recipient?.content?.email?.toLowerCase() || '';
                                        return fullName.includes(searchTerm) || email.includes(searchTerm);
                                      })

                                      .map((recipient, index) => (
                                        <a
                                          key={index}
                                          href="#"
                                          className="flex items-center gap-x-3 px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                          onClick={() => handleRecipientSelect3(recipient)} // Update the onClick to handle selection
                                        >
                                          <div className='items-center rounded-md bg-gray-50 py-1 px-1 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                            {recipient.member_position}
                                          </div>
                                          <div className="relative flex items-center">
                                            <span
                                              className="flex h-[26px] w-[26px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-black capitalize bg-gray-300">
                                              {
                                                recipient?.content?.first_name && recipient?.content?.last_name
                                                  ? recipient?.content.first_name.charAt(0).toLowerCase() // Show the first letter of first name in lowercase
                                                  : recipient?.content?.last_name
                                                    ? recipient?.content.last_name.charAt(0).toLowerCase() // Show the first letter of last name in lowercase
                                                    : recipient?.content?.email
                                                      ? recipient?.content.email.charAt(0).toLowerCase() // Show the first letter of email in lowercase
                                                      : null // If none of the above exist, return null
                                              }
                                            </span>
                                            <span className='absolute left-[17px] bottom-[0px]
                                               w-[10px] h-[10px] rounded-full' style={{ backgroundColor: recipient.member_colour }}></span>
                                            <div className='flex flex-col gap-y-[2px] ml-2'>
                                              <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  whitespace-nowrap w-[190px] 
                                            overflow-hidden text-ellipsis capitalize">{recipient?.content?.first_name} {recipient?.content.last_name}</span>
                                              <span className="text-[12px] text-[#374151] leading-4 truncate  whitespace-nowrap w-[190px] overflow-hidden text-ellipsis">{recipient?.content.email}</span>
                                            </div>
                                          </div>
                                        </a>
                                      ))}
                                  </div>
                                  <a href="#"
                                    // onClick={handleNewActionEnteral3}
                                    className="flex items-center gap-x-1 px-4 py-2 hover:bg-gray-100">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus w-[1rem] h-[1rem] text-[#ff591e]"
                                    >
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                      <path d="M9 12h6" />
                                      <path d="M12 9v6" />
                                    </svg>

                                    <span className='font-semibold text-[#ff591e] hover:text-[#ff591e]'>
                                      Create new recipient
                                    </span>
                                  </a>
                                </div>
                              </div>
                            )}
                            <div className='mt-5 flex justify-end'>
                              <button
                                onClick={handleActionExternalContact3}
                                className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                {isActionLoading4 ? (
                                  <>
                                    Create action
                                    <div className="simple-spinner">
                                      <span></span>
                                    </div>
                                  </>
                                ) : (
                                  'Create action'
                                )}
                              </button>
                            </div>
                          </div>
                          {
                            newActionEnteral3 &&
                            <>
                              <div className="">
                                <div className='w-full mt-5'>
                                  <div className='flex gap-y-[24px] flex-col'>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_first_name"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        First Name
                                      </label>
                                      <input
                                        id="contact_first_name"
                                        name="contact_first_name"
                                        type="text"
                                        maxLength={30}
                                        value={contactInfo3.contact_first_name}
                                        onChange={handleInputChange3}
                                        // placeholder="Jane Smith"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_last_name"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Last Name
                                      </label>
                                      <input
                                        id="contact_last_name"
                                        name="contact_last_name"
                                        type="text"
                                        maxLength={30}
                                        value={contactInfo3.contact_last_name}
                                        onChange={handleInputChange3}
                                        // placeholder="Jane Smith"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_email"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Email
                                      </label>
                                      <input
                                        id="contact_email"
                                        name="contact_email"
                                        value={contactInfo3.contact_email}
                                        onChange={handleInputChange3}
                                        maxLength={30}
                                        type="email"

                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_phone"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Phone no.
                                      </label>
                                      <input
                                        id="contact_phone"
                                        name="contact_phone"
                                        type="number"
                                        maxLength={30}
                                        value={contactInfo3.contact_phone}
                                        onChange={handleInputChange3}
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                            ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_company"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Company
                                      </label>
                                      <input
                                        id="contact_company"
                                        name="contact_company"
                                        value={contactInfo3.contact_company}
                                        onChange={handleInputChange3}
                                        maxLength={30}
                                        type="text"
                                        // placeholder="1234567890"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_job_title"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Job Title
                                      </label>
                                      <input
                                        id="contact_job_title"
                                        name="contact_job_title"
                                        value={contactInfo3.contact_job_title}
                                        onChange={handleInputChange3}
                                        type="text"
                                        maxLength={1000}
                                        // placeholder="Enter meta value"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>


                                    <div className='w-auto flex justify-end'>
                                      <button
                                        type="button"
                                        onClick={handleDocumentContactSave3}
                                        className=" flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      // onClick={() => handleSubmitItemCustomer(companyInfo)}
                                      // disabled={isUpdating}
                                      >

                                        {contactLoading3 ? (
                                          <>
                                            Create contact
                                            <div className="simple-spinner">
                                              <span></span>
                                            </div>
                                          </>
                                        ) : (
                                          'Create contact'
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          }



                        </motion.div>
                      )}
                    </>
                  }



                  {
                    !newAction3 &&
                    <AnimatePresence initial={false}>
                      {!collapsed7 && (
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{ height: 'auto' }}
                          exit={{ height: 0, }}
                          transition={{ height: { duration: 0.5 }, }}
                          className="overflow-hidden"
                        >
                          {isActionList?.data?.fill?.internal.length > 0 &&
                            <span className='font-normal text-dark-black-200 text-[12px]'>Internal - </span>
                          }
                          {isActionList?.data?.fill?.internal &&
                            isActionList.data.fill.internal.map((internalAction, index) => {
                              // Assuming `baseMemberDetails` is an array and you want to find the member matching `internal_user_id`
                              const baseMember = baseMemberDetails?.data?.baseMembers?.find(
                                (member) => member.id === internalAction.internal_user_id
                              );

                              return (
                                <div
                                  key={internalAction.id || index}
                                  className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                >
                                  <div className='flex items-center gap-x-2'>
                                    <div className='items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                      {internalAction.member_position}
                                    </div>
                                    <div className="flex items-center gap-3">
                                      {
                                        baseMember?.profile_pic ? (
                                          <div className=' relative flex justify-center items-center'>
                                            <img
                                              src={`${process.env.REACT_APP_API_BASE_URL}/${baseMember.profile_pic}`}
                                              alt={`${baseMember.name}'s profile`}
                                              className="w-6 h-6 object-cover rounded-full"
                                            />
                                            <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                        rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                          </div>
                                        ) : (
                                          <div className=' relative flex justify-center items-center'>
                                            <span className="w-6 h-6 rounded-full capitalize bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                              {baseMember?.name?.charAt(0)}
                                            </span>
                                            <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                          rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                          </div>
                                        )
                                      }
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium capitalize ">
                                          {baseMember?.name && ` ${baseMember.name}`}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  {/* <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                    <CustomMenuDropdown
                                      icon={
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="12" cy="5" r="1"></circle>
                                          <circle cx="12" cy="19" r="1"></circle>
                                        </svg>
                                      }
                                      altText="User profile"
                                      sections={dropdownGroups1}
                                      menuItemsClassName="left-auto top-[10px]"
                                    />
                                  </div> */}

                                  <div className=' relative'>
                                    {
                                      internalAction.primary_action_status === "pending" &&
                                      <button data-title={'Pending'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-34px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-check w-[1.2rem] h-[1.2rem] text-[#808080] "
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" />
                                          <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" />
                                          <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" />
                                          <path d="M8.56 20.31a9 9 0 0 0 3.44 .69" />
                                          <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" />
                                          <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" />
                                          <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" />
                                          <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }

                                    {
                                      internalAction.primary_action_status === "complete" &&
                                      <button
                                        data-title={'Completed'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-42px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }


                                    {
                                      internalAction.primary_action_status === "lock" &&
                                      <button
                                        data-title={'Locked'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                                        </svg>
                                      </button>
                                    }


                                    {
                                      internalAction.primary_action_status === "reject" &&
                                      <button
                                        data-title={'Reject'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-x w-[1.2rem] h-[1.2rem] text-[#c10007]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" />
                                        </svg>

                                      </button>
                                    }

                                  </div>



                                </div>
                              );
                            })}


                          {
                            isActionList?.data?.fill?.external?.length > 0 && (
                              <>
                                {
                                  // Combine the two lists and get only the first one.
                                  [
                                    ...(isActionList?.data?.fill?.external || []),
                                    ...(isActionList?.data?.fill?.external || [])
                                  ]
                                    .slice(0, 1) // Take only the first item
                                    .map((item, index) => {

                                      return (
                                        <div key={index} className="flex items-center space-x-2">
                                          {item.external_contact_id && (
                                            <span className="font-normal text-dark-black-200 text-[12px]">External - </span>
                                          )}
                                        </div>
                                      );
                                    })
                                }
                              </>
                            )
                          }
                          {
                            isActionList?.data?.fill?.external?.length > 0 &&
                            isActionList.data.fill.external.map((internalAction, index) => {


                              // This assumes internalAction contains the 'contact_first_name', 'contact_last_name', 'contact_email', etc.
                              return (
                                <div
                                  key={internalAction.id || index}
                                  className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                >
                                  <div className="flex items-center gap-3">
                                    <div className='items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                      {internalAction.member_position}
                                    </div>
                                    <div className="relative flex-none w-6 h-6 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                      <span className="text-[10px] font-medium text-gray-700 capitalize">
                                        {
                                          internalAction?.contact?.contact_first_name
                                            ? internalAction.contact.contact_first_name.charAt(0).toLowerCase()
                                            : internalAction?.contact?.contact_last_name
                                              ? internalAction.contact.contact_last_name.charAt(0).toLowerCase()
                                              : internalAction?.contact?.contact_email
                                                ? internalAction.contact.contact_email.charAt(0).toLowerCase()
                                                : ''
                                        }
                                      </span>
                                      <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                        rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                    </div>
                                    <div className='flex flex-col'>
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium capitalize">
                                          {internalAction?.contact?.contact_first_name && `${internalAction?.contact?.contact_first_name} `}
                                          {internalAction?.contact?.contact_last_name && `${internalAction?.contact?.contact_last_name} `}
                                        </span>
                                      </p>
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                          {internalAction?.contact?.contact_email && `${internalAction?.contact?.contact_email}`}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className=' relative'>
                                    {
                                      internalAction.primary_action_status === "pending" &&
                                      <button data-title={'Pending'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-34px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-check w-[1.2rem] h-[1.2rem] text-[#808080] "
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" />
                                          <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" />
                                          <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" />
                                          <path d="M8.56 20.31a9 9 0 0 0 3.44 .69" />
                                          <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" />
                                          <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" />
                                          <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" />
                                          <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }

                                    {
                                      internalAction.primary_action_status === "complete" &&
                                      <button
                                        data-title={'Completed'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-42px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }


                                    {
                                      internalAction.primary_action_status === "lock" &&
                                      <button
                                        data-title={'Locked'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                                        </svg>
                                      </button>
                                    }


                                    {
                                      internalAction.primary_action_status === "reject" &&
                                      <button
                                        data-title={'Reject'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-x w-[1.2rem] h-[1.2rem] text-[#c10007]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" />
                                        </svg>

                                      </button>
                                    }

                                  </div>
                                  {/* <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                    <CustomMenuDropdown
                                      icon={
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="12" cy="5" r="1"></circle>
                                          <circle cx="12" cy="19" r="1"></circle>
                                        </svg>
                                      }
                                      altText="User profile"
                                      sections={dropdownGroups1}
                                      menuItemsClassName="left-auto top-[5px]"
                                    />
                                  </div> */}
                                </div>
                              );
                            })
                          }

                        </motion.div>
                      )}
                    </AnimatePresence>
                  }

                </div>
                <div className='bg-[#e5e7eb] w-[-webkit-fill-available] h-[1px] mx-[-20px] px-[20px] my-5'></div>

                <div className="mb-2">
                  <div className='flex justify-between items-center'>
                    <a
                      href="#"
                      onClick={toggleCollapse8}
                      className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                    >
                      <span className='flex mr-[6px] h-[8px] w-[8px] shrink-0
                            items-center justify-center rounded-[20px] border-none
                             bg-[#854d0e]'>

                      </span>
                      Accept

                      <div className={`${collapsed8 ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                        <svg
                          width={24}
                          height={24}
                          fill="none"
                          className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M15.25 10.75 12 14.25l-3.25-3.5"
                          />
                        </svg>
                      </div>
                    </a>
                    {/* <button className='underline text-[13px] text-[#266df0]' onClick={handleNewAction4}>
                      Add
                    </button> */}
                  </div>

                  {
                    newAction4 &&
                    <>
                      <nav className='relative w-[100%] my-5 h-full bg-[#f4f5f7] rounded-md p-[6px]' aria-label='Tabs'>
                        {tabs5.map((tab) => (
                          <motion.a
                            key={tab.name}
                            onClick={() => setOpenActionTab4(tab.name)}
                            href={tab.href}
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: 0.3 }}
                            className={classNames(
                              tab.name === openActionTab4
                                ? 'border-none bg-[#4a5562] rounded-md text-[#fff]'
                                : 'py-1 w-1/2 cursor-pointer select-none focus:outline-none',
                              'py-1 w-1/2 cursor-pointer select-none group inline-flex capitalize items-center text-[12px] font-medium rounded-md justify-center'
                            )}
                          >
                            <span>{tab.name}</span>
                          </motion.a>
                        ))}

                      </nav>
                      {openActionTab4 === 'internal team' && (
                        <motion.div
                          className="mt-4"
                          initial={{ opacity: 1, y: 0 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.8 }}
                        >
                          <div className="relative w-[100%]">
                            <Listbox value={variableType5} onChange={setVariableType5}>
                              {({ open }) => (
                                <div>
                                  <label
                                    className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
    before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
     peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
     after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
    before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                  >
                                    Input type

                                  </label>
                                  <ListboxButton
                                    onClick={handlebaseChannelList}
                                    className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
                                           focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
                                                  disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                  >
                                    <div className='flex items-center justify-between'>
                                      <span
                                        className="flex capitalize items-center gap-x-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
                                                  overflow-hidden text-ellipsis text-start font-medium"
                                      >
                                        {variableType5 ? variableType5.name : "Select an option"}
                                      </span>
                                      <ChevronDownIcon />
                                    </div>
                                  </ListboxButton>
                                  <Transition
                                    show={open}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <ListboxOptions
                                      className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                    >
                                      <>
                                        {/* Check if both datasets are present */}
                                        {isActionListNew?.data?.internal && baseMemberDetailsArray.length > 0 ? (
                                          baseMemberDetailsArray.map((board: any, index: number) => {
                                            // Fetch the `member_colour` for the current `board` (you can adjust logic if needed)
                                            const color =
                                              isActionListNew?.data?.internal[index]?.member_colour || '#f4f5f7'; // Fallback color
                                            const position = isActionListNew?.data?.internal[index]?.member_position
                                            return (
                                              <Listbox.Option
                                                key={board.id}
                                                className={({ active }) =>
                                                  classNames(
                                                    active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                    !active ? 'text-dark-black-100 text-[13px]' : '',
                                                    'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                  )
                                                }
                                                value={{
                                                  id: board.id,
                                                  name: board.name,
                                                  member_position: position, // Include position here
                                                }}
                                              >
                                                {({ selected }) => (
                                                  <span
                                                    className={classNames(
                                                      selected ? 'font-semibold capitalize' : 'font-normal capitalize',
                                                      'flex items-center gap-x-2 truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[280px] w-full overflow-hidden text-ellipsis text-start'
                                                    )}
                                                  >
                                                    <span className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 
                                                       ring-1 ring-inset ring-gray-500/10 w-fit'>
                                                      {position}
                                                    </span>
                                                    {board.profile_pic ? (
                                                      <div className="relative flex justify-center items-center">
                                                        <img
                                                          src={`${process.env.REACT_APP_API_BASE_URL}/${board.profile_pic}`}
                                                          alt={`${board.name}'s profile`}
                                                          className="w-6 h-6 object-cover rounded-full"
                                                        />
                                                        <span
                                                          className="absolute right-0 bottom-0 w-[10px] h-[10px] rounded-full block"
                                                          style={{ backgroundColor: color }}
                                                        ></span>
                                                      </div>
                                                    ) : (
                                                      <div className="relative flex justify-center items-center">
                                                        <span className="w-6 h-6 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                                          {board.name
                                                            .split(' ')
                                                            .map((word: string) => word.charAt(0))
                                                            .join('')
                                                            .toUpperCase()}
                                                        </span>
                                                        <span
                                                          className="absolute right-0 bottom-0 w-[10px] h-[10px] rounded-full block"
                                                          style={{ backgroundColor: color }}
                                                        ></span>
                                                      </div>
                                                    )}
                                                    {board?.name}
                                                  </span>
                                                )}
                                              </Listbox.Option>
                                            );
                                          })
                                        ) : (
                                          <div className="px-3 py-2 text-[13px] text-gray-500 text-center">
                                            Nothing here
                                          </div>
                                        )}
                                      </>
                                    </ListboxOptions>
                                  </Transition>
                                </div>
                              )}
                            </Listbox>
                          </div>
                          <div className='mt-5 flex justify-end'>
                            <button
                              onClick={handleActionInternalTeam4}
                              className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                              {isActionLoading5 ? (
                                <>
                                  Create action
                                  <div className="simple-spinner">
                                    <span></span>
                                  </div>
                                </>
                              ) : (
                                'Create action'
                              )}
                            </button>
                          </div>
                        </motion.div>
                      )}
                      {openActionTab4 === 'external contact' && (
                        <motion.div
                          className="mt-4"
                          initial={{ opacity: 1, y: 0 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.8 }}
                        >
                          <div className="relative">
                            <button
                              type="button"
                              className="flex items-center justify-between h-[40px] px-4 w-full py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                              onClick={() => setIsOpen4(!isOpen4)}
                            >
                              <span className="mr-2 text-[13px] text-[#374151] new-action">
                                {
                                  selectMember4
                                    ? (
                                      <span className=" font-semibold">
                                        {selectMember4.content.first_name || ''}
                                        {selectMember4.content.last_name || ''}
                                        {!selectMember4.content.last_name && !selectMember4.content.first_name || ' - '}
                                      </span>
                                    )
                                    : 'Start typing name or email'
                                }
                                {selectMember4?.content?.email && (
                                  <span className="text-[14px]">
                                    {selectMember4?.content?.email}
                                  </span>
                                )}

                              </span>

                              <svg
                                className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${isOpen4 ? 'transform rotate-180' : ''}`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            {isOpen4 && (
                              <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                  <div className="px-4 py-2">
                                    <input
                                      type="text"
                                      className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                      placeholder="Start typing name or email"
                                      value={search4}
                                      onChange={(e) => setSearch4(e.target.value)}
                                    />
                                  </div>
                                  <div className='max-h-[200px] overflow-auto'>
                                    {isActionListNew?.data?.external
                                      .filter((recipient: any) => {
                                        const searchTerm = search4.toLowerCase();
                                        const fullName = `${recipient?.content?.first_name || ''} ${recipient?.content?.last_name || ''}`.toLowerCase();
                                        const email = recipient?.content?.email?.toLowerCase() || '';
                                        return fullName.includes(searchTerm) || email.includes(searchTerm);
                                      })

                                      .map((recipient, index) => (
                                        <a
                                          key={index}
                                          href="#"
                                          className="flex items-center gap-x-3 px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                          onClick={() => handleRecipientSelect4(recipient)} // Update the onClick to handle selection
                                        >
                                          <div className='items-center rounded-md bg-gray-50 py-1 px-1 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                            {recipient.member_position}
                                          </div>
                                          <div className="relative flex items-center">
                                            <span
                                              className="flex h-[26px] w-[26px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-black capitalize bg-gray-300">
                                              {
                                                recipient?.content?.first_name && recipient?.content?.last_name
                                                  ? recipient?.content.first_name.charAt(0).toLowerCase() // Show the first letter of first name in lowercase
                                                  : recipient?.content?.last_name
                                                    ? recipient?.content.last_name.charAt(0).toLowerCase() // Show the first letter of last name in lowercase
                                                    : recipient?.content?.email
                                                      ? recipient?.content.email.charAt(0).toLowerCase() // Show the first letter of email in lowercase
                                                      : null // If none of the above exist, return null
                                              }
                                            </span>
                                            <span className='absolute left-[17px] bottom-[0px]
                                               w-[10px] h-[10px] rounded-full' style={{ backgroundColor: recipient.member_colour }}></span>
                                            <div className='flex flex-col gap-y-[2px] ml-2'>
                                              <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  whitespace-nowrap w-[190px] 
                                            overflow-hidden text-ellipsis capitalize">{recipient?.content?.first_name} {recipient?.content.last_name}</span>
                                              <span className="text-[12px] text-[#374151] leading-4 truncate  whitespace-nowrap w-[190px] overflow-hidden text-ellipsis">{recipient?.content.email}</span>
                                            </div>
                                          </div>
                                        </a>
                                      ))}
                                  </div>
                                  <a href="#"
                                    // onClick={handleNewActionEnteral4}
                                    className="flex items-center gap-x-1 px-4 py-2 hover:bg-gray-100">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus w-[1rem] h-[1rem] text-[#ff591e]"
                                    >
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                      <path d="M9 12h6" />
                                      <path d="M12 9v6" />
                                    </svg>

                                    <span className='font-semibold text-[#ff591e] hover:text-[#ff591e]'>
                                      Create new recipient
                                    </span>
                                  </a>
                                </div>
                              </div>
                            )}
                            <div className='mt-5 flex justify-end'>
                              <button
                                onClick={handleActionExternalContact4}
                                className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                {isActionLoading5 ? (
                                  <>
                                    Create action
                                    <div className="simple-spinner">
                                      <span></span>
                                    </div>
                                  </>
                                ) : (
                                  'Create action'
                                )}
                              </button>
                            </div>
                          </div>
                          {
                            newActionEnteral4 &&
                            <>
                              <div className="">
                                <div className='w-full mt-5'>
                                  <div className='flex gap-y-[24px] flex-col'>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_first_name"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        First Name
                                      </label>
                                      <input
                                        id="contact_first_name"
                                        name="contact_first_name"
                                        type="text"
                                        maxLength={30}
                                        value={contactInfo4.contact_first_name}
                                        onChange={handleInputChange4}
                                        // placeholder="Jane Smith"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_last_name"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Last Name
                                      </label>
                                      <input
                                        id="contact_last_name"
                                        name="contact_last_name"
                                        type="text"
                                        maxLength={30}
                                        value={contactInfo4.contact_last_name}
                                        onChange={handleInputChange4}
                                        // placeholder="Jane Smith"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_email"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Email
                                      </label>
                                      <input
                                        id="contact_email"
                                        name="contact_email"
                                        value={contactInfo4.contact_email}
                                        onChange={handleInputChange4}
                                        maxLength={30}
                                        type="email"

                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_phone"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Phone no.
                                      </label>
                                      <input
                                        id="contact_phone"
                                        name="contact_phone"
                                        type="number"
                                        maxLength={30}
                                        value={contactInfo4.contact_phone}
                                        onChange={handleInputChange4}
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                            ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_company"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Company
                                      </label>
                                      <input
                                        id="contact_company"
                                        name="contact_company"
                                        value={contactInfo4.contact_company}
                                        onChange={handleInputChange4}
                                        maxLength={30}
                                        type="text"
                                        // placeholder="1234567890"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_job_title"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Job Title
                                      </label>
                                      <input
                                        id="contact_job_title"
                                        name="contact_job_title"
                                        value={contactInfo4.contact_job_title}
                                        onChange={handleInputChange4}
                                        type="text"
                                        maxLength={1000}
                                        // placeholder="Enter meta value"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>


                                    <div className='w-auto flex justify-end'>
                                      <button
                                        type="button"
                                        onClick={handleDocumentContactSave4}
                                        className=" flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      // onClick={() => handleSubmitItemCustomer(companyInfo)}
                                      // disabled={isUpdating}
                                      >

                                        {contactLoading4 ? (
                                          <>
                                            Create contact
                                            <div className="simple-spinner">
                                              <span></span>
                                            </div>
                                          </>
                                        ) : (
                                          'Create contact'
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          }



                        </motion.div>
                      )}
                    </>
                  }



                  {
                    !newAction4 &&
                    <AnimatePresence initial={false}>
                      {!collapsed8 && (
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{ height: 'auto' }}
                          exit={{ height: 0, }}
                          transition={{ height: { duration: 0.5 }, }}
                          className="overflow-hidden"
                        >
                          {isActionList?.data?.accept?.internal.length > 0 &&
                            <span className='font-normal text-dark-black-200 text-[12px]'>Internal - </span>
                          }
                          {isActionList?.data?.accept?.internal &&
                            isActionList.data.accept.internal.map((internalAction, index) => {
                              // Assuming `baseMemberDetails` is an array and you want to find the member matching `internal_user_id`
                              const baseMember = baseMemberDetails?.data?.baseMembers?.find(
                                (member) => member.id === internalAction.internal_user_id
                              );

                              return (
                                <div
                                  key={internalAction.id || index}
                                  className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                >
                                  <div className='flex items-center gap-x-2'>
                                    <div className='items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                      {internalAction.member_position}
                                    </div>
                                    <div className="flex items-center gap-3">
                                      {
                                        baseMember?.profile_pic ? (
                                          <div className=' relative flex justify-center items-center'>
                                            <img
                                              src={`${process.env.REACT_APP_API_BASE_URL}/${baseMember.profile_pic}`}
                                              alt={`${baseMember.name}'s profile`}
                                              className="w-6 h-6 object-cover rounded-full"
                                            />
                                            <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                        rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                          </div>
                                        ) : (
                                          <div className=' relative flex justify-center items-center'>
                                            <span className="w-6 h-6 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                              {baseMember?.name?.charAt(0)}
                                            </span>
                                            <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                        rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                          </div>
                                        )
                                      }
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium capitalize">
                                          {baseMember?.name && ` ${baseMember.name}`}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className=' relative'>
                                    {
                                      internalAction.primary_action_status === "pending" &&
                                      <button data-title={'Pending'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-34px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-check w-[1.2rem] h-[1.2rem] text-[#808080] "
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" />
                                          <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" />
                                          <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" />
                                          <path d="M8.56 20.31a9 9 0 0 0 3.44 .69" />
                                          <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" />
                                          <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" />
                                          <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" />
                                          <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }

                                    {
                                      internalAction.primary_action_status === "complete" &&
                                      <button
                                        data-title={'Completed'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-42px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }


                                    {
                                      internalAction.primary_action_status === "lock" &&
                                      <button
                                        data-title={'Locked'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                                        </svg>
                                      </button>
                                    }


                                    {
                                      internalAction.primary_action_status === "reject" &&
                                      <button
                                        data-title={'Reject'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-x w-[1.2rem] h-[1.2rem] text-[#c10007]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" />
                                        </svg>

                                      </button>
                                    }

                                  </div>
                                  {/* <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                    <CustomMenuDropdown
                                      icon={
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="12" cy="5" r="1"></circle>
                                          <circle cx="12" cy="19" r="1"></circle>
                                        </svg>
                                      }
                                      altText="User profile"
                                      sections={dropdownGroups1}
                                      menuItemsClassName="left-auto top-[10px]"
                                    />
                                  </div> */}
                                </div>
                              );
                            })}
                          {
                            isActionList?.data?.accept?.external?.length > 0 && (
                              <>
                                {
                                  // Combine the two lists and get only the first one.
                                  [
                                    ...(isActionList?.data?.accept?.external || []),
                                    ...(isActionList?.data?.accept?.external || [])
                                  ]
                                    .slice(0, 1) // Take only the first item
                                    .map((item, index) => {

                                      return (
                                        <div key={index} className="flex items-center space-x-2">
                                          {item.external_contact_id && (
                                            <span className="font-normal text-dark-black-200 text-[12px]">External - </span>
                                          )}
                                        </div>
                                      );
                                    })
                                }
                              </>
                            )
                          }
                          {
                            isActionList?.data?.accept?.external?.length > 0 &&
                            isActionList.data.accept.external.map((internalAction, index) => {


                              // This assumes internalAction contains the 'contact_first_name', 'contact_last_name', 'contact_email', etc.
                              return (
                                <div
                                  key={internalAction.id || index}
                                  className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                >
                                  <div className="flex items-center gap-3">
                                    <div className='items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                      {internalAction.member_position}
                                    </div>
                                    <div className="relative flex-none w-6 h-6 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                      <span className="text-[10px] font-medium text-gray-700 capitalize">
                                        {
                                          internalAction?.contact?.contact_first_name
                                            ? internalAction.contact.contact_first_name.charAt(0).toLowerCase()
                                            : internalAction?.contact?.contact_last_name
                                              ? internalAction.contact.contact_last_name.charAt(0).toLowerCase()
                                              : internalAction?.contact?.contact_email
                                                ? internalAction.contact.contact_email.charAt(0).toLowerCase()
                                                : ''
                                        }
                                      </span>
                                      <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                        rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                    </div>
                                    <div className='flex flex-col'>
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium capitalize">
                                          {internalAction?.contact?.contact_first_name && `${internalAction?.contact?.contact_first_name} `}
                                          {internalAction?.contact?.contact_last_name && `${internalAction?.contact?.contact_last_name} `}
                                        </span>
                                      </p>
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                          {internalAction?.contact?.contact_email && `${internalAction?.contact?.contact_email}`}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className=' relative'>
                                    {
                                      internalAction.primary_action_status === "pending" &&
                                      <button data-title={'Pending'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-34px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-check w-[1.2rem] h-[1.2rem] text-[#808080] "
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" />
                                          <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" />
                                          <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" />
                                          <path d="M8.56 20.31a9 9 0 0 0 3.44 .69" />
                                          <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" />
                                          <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" />
                                          <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" />
                                          <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }

                                    {
                                      internalAction.primary_action_status === "complete" &&
                                      <button
                                        data-title={'Completed'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-42px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }


                                    {
                                      internalAction.primary_action_status === "lock" &&
                                      <button
                                        data-title={'Locked'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                                        </svg>
                                      </button>
                                    }


                                    {
                                      internalAction.primary_action_status === "reject" &&
                                      <button
                                        data-title={'Reject'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-x w-[1.2rem] h-[1.2rem] text-[#c10007]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" />
                                        </svg>

                                      </button>
                                    }

                                  </div>
                                  {/* <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                    <CustomMenuDropdown
                                      icon={
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="12" cy="5" r="1"></circle>
                                          <circle cx="12" cy="19" r="1"></circle>
                                        </svg>
                                      }
                                      altText="User profile"
                                      sections={dropdownGroups1}
                                      menuItemsClassName="left-auto top-[5px]"
                                    />
                                  </div> */}
                                </div>
                              );
                            })
                          }
                        </motion.div>
                      )}
                    </AnimatePresence>
                  }

                </div>
                <div className='bg-[#e5e7eb] w-[-webkit-fill-available] h-[1px] mx-[-20px] px-[20px] my-5'></div>
                <div className="mb-2">
                  <div className='flex justify-between items-center'>
                    <a
                      href="#"
                      onClick={toggleCollapse9}
                      className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                    >
                      <span className='flex mr-[6px] h-[8px] w-[8px] shrink-0
                            items-center justify-center rounded-[20px] border-none
                             bg-[#be185d]'>

                      </span>
                      Sign

                      <div className={`${collapsed9 ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                        <svg
                          width={24}
                          height={24}
                          fill="none"
                          className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M15.25 10.75 12 14.25l-3.25-3.5"
                          />
                        </svg>
                      </div>
                    </a>
                    {/* <button className='underline text-[13px] text-[#266df0]' onClick={handleNewAction5}>
                      Add
                    </button> */}
                  </div>

                  {
                    newAction5 &&
                    <>
                      <nav className='relative w-[100%] my-5 h-full bg-[#f4f5f7] rounded-md p-[6px]' aria-label='Tabs'>
                        {tabs6.map((tab) => (
                          <motion.a
                            key={tab.name}
                            onClick={() => setOpenActionTab5(tab.name)}
                            href={tab.href}
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: 0.3 }}
                            className={classNames(
                              tab.name === openActionTab5
                                ? 'border-none bg-[#4a5562] rounded-md text-[#fff]'
                                : 'py-1 w-1/2 cursor-pointer select-none focus:outline-none',
                              'py-1 w-1/2 cursor-pointer select-none group inline-flex capitalize items-center text-[12px] font-medium rounded-md justify-center'
                            )}
                          >
                            <span>{tab.name}</span>
                          </motion.a>
                        ))}

                      </nav>
                      {openActionTab5 === 'internal team' && (
                        <motion.div
                          className="mt-4"
                          initial={{ opacity: 1, y: 0 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.8 }}
                        >
                          <div className="relative w-[100%]">
                            <Listbox value={variableType6} onChange={setVariableType6}>
                              {({ open }) => (
                                <div>
                                  <label
                                    className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
    before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
     peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
     after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
    before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                  >
                                    Input type

                                  </label>
                                  <ListboxButton
                                    onClick={handlebaseChannelList}
                                    className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
                                           focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
                                                  disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                  >
                                    <div className='flex items-center justify-between'>
                                      <span
                                        className="flex capitalize items-center gap-x-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
                                                  overflow-hidden text-ellipsis text-start font-medium"
                                      >
                                        {variableType6 ? variableType6.name : "Select an option"}
                                      </span>
                                      <ChevronDownIcon />
                                    </div>
                                  </ListboxButton>
                                  <Transition
                                    show={open}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <ListboxOptions
                                      className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                    >
                                      <>
                                        {/* Check if both datasets are present */}
                                        {isActionListNew?.data?.internal && baseMemberDetailsArray.length > 0 ? (
                                          baseMemberDetailsArray.map((board: any, index: number) => {
                                            // Fetch the `member_colour` for the current `board` (you can adjust logic if needed)
                                            const color =
                                              isActionListNew?.data?.internal[index]?.member_colour || '#f4f5f7'; // Fallback color
                                            const position = isActionListNew?.data?.internal[index]?.member_position
                                            return (
                                              <Listbox.Option
                                                key={board.id}
                                                className={({ active }) =>
                                                  classNames(
                                                    active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                    !active ? 'text-dark-black-100 text-[13px]' : '',
                                                    'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                  )
                                                }
                                                value={{
                                                  id: board.id,
                                                  name: board.name,
                                                  member_position: position, // Include position here
                                                }}
                                              >
                                                {({ selected }) => (
                                                  <span
                                                    className={classNames(
                                                      selected ? 'font-semibold capitalize' : 'font-normal capitalize',
                                                      'flex items-center gap-x-2 truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[280px] w-full overflow-hidden text-ellipsis text-start'
                                                    )}
                                                  >
                                                    <span className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 
                                                       ring-1 ring-inset ring-gray-500/10 w-fit'>
                                                      {position}
                                                    </span>
                                                    {board.profile_pic ? (
                                                      <div className="relative flex justify-center items-center">
                                                        <img
                                                          src={`${process.env.REACT_APP_API_BASE_URL}/${board.profile_pic}`}
                                                          alt={`${board.name}'s profile`}
                                                          className="w-6 h-6 object-cover rounded-full"
                                                        />
                                                        <span
                                                          className="absolute right-0 bottom-0 w-[10px] h-[10px] rounded-full block"
                                                          style={{ backgroundColor: color }}
                                                        ></span>
                                                      </div>
                                                    ) : (
                                                      <div className="relative flex justify-center items-center">
                                                        <span className="w-6 h-6 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                                          {board.name
                                                            .split(' ')
                                                            .map((word: string) => word.charAt(0))
                                                            .join('')
                                                            .toUpperCase()}
                                                        </span>
                                                        <span
                                                          className="absolute right-0 bottom-0 w-[10px] h-[10px] rounded-full block"
                                                          style={{ backgroundColor: color }}
                                                        ></span>
                                                      </div>
                                                    )}
                                                    {board?.name}
                                                  </span>
                                                )}
                                              </Listbox.Option>
                                            );
                                          })
                                        ) : (
                                          <div className="px-3 py-2 text-[13px] text-gray-500 text-center">
                                            Nothing here
                                          </div>
                                        )}
                                      </>
                                    </ListboxOptions>
                                  </Transition>
                                </div>
                              )}
                            </Listbox>
                          </div>
                          <div className='mt-5 flex justify-end'>
                            <button
                              onClick={handleActionInternalTeam5}
                              className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                              {isActionLoading6 ? (
                                <>
                                  Create action
                                  <div className="simple-spinner">
                                    <span></span>
                                  </div>
                                </>
                              ) : (
                                'Create action'
                              )}
                            </button>
                          </div>
                        </motion.div>
                      )}
                      {openActionTab5 === 'external contact' && (
                        <motion.div
                          className="mt-4"
                          initial={{ opacity: 1, y: 0 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.8 }}
                        >
                          <div className="relative">
                            <button
                              type="button"
                              className="flex items-center justify-between h-[40px] px-4 w-full py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                              onClick={() => setIsOpen5(!isOpen5)}
                            >
                              <span className="mr-2 text-[13px] text-[#374151] new-action">
                                {
                                  selectMember5
                                    ? (
                                      <span className=" font-semibold">
                                        {selectMember5.content.first_name || ''}
                                        {selectMember5.content.last_name || ''}
                                        {!selectMember5.content.last_name && !selectMember5.content.first_name || ' - '}
                                      </span>
                                    )
                                    : 'Start typing name or email'
                                }
                                {selectMember5?.content?.email && (
                                  <span className="text-[14px]">
                                    {selectMember5?.content?.email}
                                  </span>
                                )}

                              </span>

                              <svg
                                className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${isOpen5 ? 'transform rotate-180' : ''}`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            {isOpen5 && (
                              <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                  <div className="px-4 py-2">
                                    <input
                                      type="text"
                                      className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                      placeholder="Start typing name or email"
                                      value={search5}
                                      onChange={(e) => setSearch5(e.target.value)}
                                    />
                                  </div>
                                  <div className='max-h-[200px] overflow-auto'>
                                    {isActionListNew?.data?.external
                                      .filter((recipient: any) => {
                                        const searchTerm = search5.toLowerCase();
                                        const fullName = `${recipient?.content?.first_name || ''} ${recipient?.content?.last_name || ''}`.toLowerCase();
                                        const email = recipient?.content?.email?.toLowerCase() || '';
                                        return fullName.includes(searchTerm) || email.includes(searchTerm);
                                      })

                                      .map((recipient, index) => (
                                        <a
                                          key={index}
                                          href="#"
                                          className="flex items-center gap-x-3 px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                          onClick={() => handleRecipientSelect5(recipient)} // Update the onClick to handle selection
                                        >
                                          <div className='items-center rounded-md bg-gray-50 py-1 px-1 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                            {recipient.member_position}
                                          </div>
                                          <div className="relative flex items-center">
                                            <span
                                              className="flex h-[26px] w-[26px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-black capitalize bg-gray-300">
                                              {
                                                recipient?.content?.first_name && recipient?.content?.last_name
                                                  ? recipient?.content.first_name.charAt(0).toLowerCase() // Show the first letter of first name in lowercase
                                                  : recipient?.content?.last_name
                                                    ? recipient?.content.last_name.charAt(0).toLowerCase() // Show the first letter of last name in lowercase
                                                    : recipient?.content?.email
                                                      ? recipient?.content.email.charAt(0).toLowerCase() // Show the first letter of email in lowercase
                                                      : null // If none of the above exist, return null
                                              }
                                            </span>
                                            <span className='absolute left-[17px] bottom-[0px]
                                               w-[10px] h-[10px] rounded-full' style={{ backgroundColor: recipient.member_colour }}></span>
                                            <div className='flex flex-col gap-y-[2px] ml-2'>
                                              <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  whitespace-nowrap w-[190px] 
                                            overflow-hidden text-ellipsis capitalize">{recipient?.content?.first_name} {recipient?.content.last_name}</span>
                                              <span className="text-[12px] text-[#374151] leading-4 truncate  whitespace-nowrap w-[190px] overflow-hidden text-ellipsis">{recipient?.content.email}</span>
                                            </div>
                                          </div>
                                        </a>
                                      ))}
                                  </div>
                                  <a href="#"
                                    // onClick={handleNewActionEnteral5}
                                    className="flex items-center gap-x-1 px-4 py-2 hover:bg-gray-100">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus w-[1rem] h-[1rem] text-[#ff591e]"
                                    >
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                      <path d="M9 12h6" />
                                      <path d="M12 9v6" />
                                    </svg>

                                    <span className='font-semibold text-[#ff591e] hover:text-[#ff591e]'>
                                      Create new recipient
                                    </span>
                                  </a>
                                </div>
                              </div>
                            )}
                            <div className='mt-5 flex justify-end'>
                              <button
                                onClick={handleActionExternalContact5}
                                className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                {isActionLoading6 ? (
                                  <>
                                    Create action
                                    <div className="simple-spinner">
                                      <span></span>
                                    </div>
                                  </>
                                ) : (
                                  'Create action'
                                )}
                              </button>
                            </div>
                          </div>
                          {
                            newActionEnteral5 &&
                            <>
                              <div className="">
                                <div className='w-full mt-5'>
                                  <div className='flex gap-y-[24px] flex-col'>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_first_name"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        First Name
                                      </label>
                                      <input
                                        id="contact_first_name"
                                        name="contact_first_name"
                                        type="text"
                                        maxLength={30}
                                        value={contactInfo5.contact_first_name}
                                        onChange={handleInputChange5}
                                        // placeholder="Jane Smith"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_last_name"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Last Name
                                      </label>
                                      <input
                                        id="contact_last_name"
                                        name="contact_last_name"
                                        type="text"
                                        maxLength={30}
                                        value={contactInfo5.contact_last_name}
                                        onChange={handleInputChange5}
                                        // placeholder="Jane Smith"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_email"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Email
                                      </label>
                                      <input
                                        id="contact_email"
                                        name="contact_email"
                                        value={contactInfo5.contact_email}
                                        onChange={handleInputChange5}
                                        maxLength={30}
                                        type="email"

                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_phone"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Phone no.
                                      </label>
                                      <input
                                        id="contact_phone"
                                        name="contact_phone"
                                        type="number"
                                        maxLength={30}
                                        value={contactInfo5.contact_phone}
                                        onChange={handleInputChange5}
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                            ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_company"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Company
                                      </label>
                                      <input
                                        id="contact_company"
                                        name="contact_company"
                                        value={contactInfo5.contact_company}
                                        onChange={handleInputChange5}
                                        maxLength={30}
                                        type="text"
                                        // placeholder="1234567890"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>
                                    <div className="relative">
                                      <label
                                        htmlFor="contact_job_title"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                      >
                                        Job Title
                                      </label>
                                      <input
                                        id="contact_job_title"
                                        name="contact_job_title"
                                        value={contactInfo5.contact_job_title}
                                        onChange={handleInputChange5}
                                        type="text"
                                        maxLength={1000}
                                        // placeholder="Enter meta value"
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                      />
                                    </div>


                                    <div className='w-auto flex justify-end'>
                                      <button
                                        type="button"
                                        onClick={handleDocumentContactSave5}
                                        className=" flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      // onClick={() => handleSubmitItemCustomer(companyInfo)}
                                      // disabled={isUpdating}
                                      >

                                        {contactLoading5 ? (
                                          <>
                                            Create contact
                                            <div className="simple-spinner">
                                              <span></span>
                                            </div>
                                          </>
                                        ) : (
                                          'Create contact'
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          }



                        </motion.div>
                      )}
                    </>
                  }



                  {
                    !newAction5 &&
                    <AnimatePresence initial={false}>
                      {!collapsed9 && (
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{ height: 'auto' }}
                          exit={{ height: 0, }}
                          transition={{ height: { duration: 0.5 }, }}
                          className="overflow-hidden"
                        >
                          {isActionList?.data?.sign?.internal.length > 0 &&
                            <span className='font-normal text-dark-black-200 text-[12px]'>Internal - </span>
                          }
                          {isActionList?.data?.sign?.internal &&
                            isActionList.data.sign.internal.map((internalAction, index) => {
                              // Assuming `baseMemberDetails` is an array and you want to find the member matching `internal_user_id`
                              const baseMember = baseMemberDetails?.data?.baseMembers?.find(
                                (member) => member.id === internalAction.internal_user_id
                              );

                              return (
                                <div
                                  key={internalAction.id || index}
                                  className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                >
                                  <div className='flex items-center gap-x-2'>
                                    <div className='items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                      {internalAction.member_position}
                                    </div>
                                    <div className="flex items-center gap-3">
                                      {
                                        baseMember?.profile_pic ? (
                                          <div className=' relative flex justify-center items-center'>
                                            <img
                                              src={`${process.env.REACT_APP_API_BASE_URL}/${baseMember.profile_pic}`}
                                              alt={`${baseMember.name}'s profile`}
                                              className="w-6 h-6 object-cover rounded-full"
                                            />
                                            <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                        rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                          </div>
                                        ) : (
                                          <div className=' relative flex justify-center items-center'>
                                            <span className="w-6 h-6 rounded-full capitalize bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                              {baseMember?.name?.charAt(0)}
                                            </span>
                                            <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                        rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                          </div>
                                        )
                                      }
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium capitalize">
                                          {baseMember?.name && ` ${baseMember.name}`}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  {/* <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                    <CustomMenuDropdown
                                      icon={
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="12" cy="5" r="1"></circle>
                                          <circle cx="12" cy="19" r="1"></circle>
                                        </svg>
                                      }
                                      altText="User profile"
                                      sections={dropdownGroups1}
                                      menuItemsClassName="left-auto top-[10px]"
                                    />
                                  </div> */}
                                   <div className=' relative'>
                                    {
                                      internalAction.primary_action_status === "pending" &&
                                      <button data-title={'Pending'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-34px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-check w-[1.2rem] h-[1.2rem] text-[#808080] "
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" />
                                          <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" />
                                          <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" />
                                          <path d="M8.56 20.31a9 9 0 0 0 3.44 .69" />
                                          <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" />
                                          <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" />
                                          <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" />
                                          <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }

                                    {
                                      internalAction.primary_action_status === "complete" &&
                                      <button
                                        data-title={'Completed'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-42px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }


                                    {
                                      internalAction.primary_action_status === "lock" &&
                                      <button
                                        data-title={'Locked'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                                        </svg>
                                      </button>
                                    }


                                    {
                                      internalAction.primary_action_status === "reject" &&
                                      <button
                                        data-title={'Reject'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-x w-[1.2rem] h-[1.2rem] text-[#c10007]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" />
                                        </svg>

                                      </button>
                                    }

                                  </div>
                                </div>
                              );
                            })}
                          {
                            isActionList?.data?.sign?.external?.length > 0 && (
                              <>
                                {
                                  // Combine the two lists and get only the first one.
                                  [
                                    ...(isActionList?.data?.sign?.external || []),
                                    ...(isActionList?.data?.sign?.external || [])
                                  ]
                                    .slice(0, 1) // Take only the first item
                                    .map((item, index) => {

                                      return (
                                        <div key={index} className="flex items-center space-x-2">
                                          {item.external_contact_id && (
                                            <span className="font-normal text-dark-black-200 text-[12px]">External - </span>
                                          )}
                                        </div>
                                      );
                                    })
                                }
                              </>
                            )
                          }

                          {
                            isActionList?.data?.sign?.external?.length > 0 &&
                            isActionList.data.sign.external.map((internalAction, index) => {
                              const contact = internalAction.external_contact_id
                                ? internalAction.contact
                                : null; // Check if external_contact_id exists and assign contact

                              if (!contact) {
                                return null; // Skip rendering if contact is null
                              }

                              return (
                                <div
                                  key={internalAction.id || index}
                                  className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                >
                                  <div className="flex items-center gap-3">
                                    <div className='items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                      {internalAction.member_position}
                                    </div>
                                    <div className="relative flex-none w-6 h-6 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                      <span className="text-[10px] font-medium text-gray-700 capitalize">
                                        {
                                          contact.contact_first_name
                                            ? contact.contact_first_name.charAt(0).toLowerCase()
                                            : contact.contact_last_name
                                              ? contact.contact_last_name.charAt(0).toLowerCase()
                                              : contact.contact_email
                                                ? contact.contact_email.charAt(0).toLowerCase()
                                                : ''
                                        }
                                      </span>
                                      <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                        rounded-full' style={{ backgroundColor: internalAction.item_member_colour }}></span>
                                    </div>
                                    <div className="flex flex-col">
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium capitalize">
                                          {contact.contact_first_name && `${contact.contact_first_name} `}
                                          {contact.contact_last_name && `${contact.contact_last_name} `}
                                        </span>
                                      </p>
                                      <p className="leading-relaxed">
                                        <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                          {contact.contact_email && `${contact.contact_email}`}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  {/* <div className="[&>div]:shrink feedback-delete-btn-hover">
                                    <CustomMenuDropdown
                                      icon={
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="12" cy="5" r="1"></circle>
                                          <circle cx="12" cy="19" r="1"></circle>
                                        </svg>
                                      }
                                      altText="User profile"
                                      sections={dropdownGroups1}
                                      menuItemsClassName="left-auto top-[5px]"
                                    />
                                  </div> */}
                                   <div className=' relative'>
                                    {
                                      internalAction.primary_action_status === "pending" &&
                                      <button data-title={'Pending'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-34px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-check w-[1.2rem] h-[1.2rem] text-[#808080] "
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" />
                                          <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" />
                                          <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" />
                                          <path d="M8.56 20.31a9 9 0 0 0 3.44 .69" />
                                          <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" />
                                          <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" />
                                          <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" />
                                          <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }

                                    {
                                      internalAction.primary_action_status === "complete" &&
                                      <button
                                        data-title={'Completed'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-42px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </button>

                                    }


                                    {
                                      internalAction.primary_action_status === "lock" &&
                                      <button
                                        data-title={'Locked'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                                        </svg>
                                      </button>
                                    }


                                    {
                                      internalAction.primary_action_status === "reject" &&
                                      <button
                                        data-title={'Reject'}
                                        className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="icon icon-tabler icons-tabler-filled icon-tabler-circle-x w-[1.2rem] h-[1.2rem] text-[#c10007]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" />
                                        </svg>

                                      </button>
                                    }

                                  </div>
                                </div>
                              );
                            })
                          }

                        </motion.div>
                      )}
                    </AnimatePresence>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
});

export default SendDocumentDetailsAction;