// // @ts-nocheck
// import React, { useState, useEffect } from 'react';
// import { format, formatDistanceToNow } from 'date-fns';
// import { useAppDispatch, useAppSelector } from '../../../app/hooks';
// import { RootState } from '../../../app/store';
// import { fetchDocumentActivityItem } from '../../../features/docuementItemActivity/docuementItemActivitySlice';
// import { useParams } from 'react-router-dom';
// import CustomMenuDropdown from '../../../components/common/CustomMenuDropdown';
// import { createItemchatData } from '../../../features/documentItemChat/documentItemChatSlice';
// import { toast } from 'sonner';
// import { motion, AnimatePresence } from 'framer-motion';
// import { addValueInputData } from '../../../features/documentInput/documentInputSlice';
// import { fetchExecuteItemList } from '../../../features/documentItemFill/documentItemFillSlice';

// interface DocumentDetailsChatProps {
//   isAsideOpen6: boolean;
//   inputFill: any;
//   itemFillLoading: boolean;
//   externalMembers: any;
//   setInputFill: any;
// }

// interface DropdownItem {
//   className?: string;
//   icon?: ReactNode;
//   label: string;
//   shortcut?: any;
//   disabled?: boolean;
//   onClick?: () => void;
//   href?: string;
// }


// const SendDocumentFill: React.FC<DocumentDetailsChatProps> = React.memo(({ isAsideOpen6,
//   inputFill,
//   itemFillLoading,
//   externalMembers,
//   setInputFill
//   //  itemActivityChat, itemActivityLoading 
// }) => {
//   const dispatch = useAppDispatch();
//   const { baseId } = useParams<{ baseId: string }>();
//   const { id } = useParams<{ id: string }>();
//   const localStorageData = JSON.parse(localStorage.getItem('baseMembersDetails') || '{}');
//   const baseMembers = localStorageData?.data?.baseMembers || [];
//   const getInternalDetails = inputFill.internal;
//   const [collapsedCustomers, setCollapsedCustomers] = useState<Record<number, boolean>>({}); // Use object to store collapsed state per index
//   const combinedDetails = [...(inputFill.internal || []), ...(inputFill.external || [])];
//   const userLoggedIn = JSON.parse(localStorage.getItem('userDetails') || '{}');
//   const [inputValues, setInputValues] = useState<Record<string, string>>({});
//   const [isLoading, setIsLoading] = useState(false);

//   // console.log(inputFill,"inputFill");

//   //   const toggleCollapseCustomer = (index: number) => {
//   //     // Assuming inputFill is an array and we need to find the user_id
//   //     const matchedInputFill = combinedDetails.find(item => console.log(item,"sdsfd")
//   //     ); // Replace with actual condition

//   //     // Check if userLoggedIn and matchedInputFill exist, and if their IDs match
//   //     if (userLoggedIn?.id === matchedInputFill?.user_id) {
//   //       setCollapsedCustomers((prevCollapsed) => ({
//   //         ...prevCollapsed,
//   //         [index]: !prevCollapsed[index], // Toggle the collapse state for the specific index
//   //       }));
//   //     }
//   //   };


//   const toggleCollapseCustomer = (index: number) => {
//     setCollapsedCustomers((prevState) => ({
//       ...prevState,
//       [index]: !prevState[index],
//     }));
//   }



//   const getInternalMembers = (memberId) => {
//     const members = baseMembers.filter((m) => m.id == memberId);
//     return members.map((m) => m.name);
//   };

//   const getInternalMembersPic = (memberId) => {
//     const members = baseMembers.filter((m) => m.id == memberId);
//     return members.map((m) => m.profile_pic);
//   };

//   const getExternalMembers = (memberId) => {
//     const members = externalMembers.filter((m) => m.member_id == memberId);
//     return members.map((m) => m.content);
//   };

//   const handleInputChange = (memberId: string, inputId: string, value: string) => {
//     setInputValues(prev => ({
//       ...prev,
//       [memberId]: {
//         ...(prev[memberId] || {}),
//         [inputId]: value
//       }
//     }));
//   };


//   const handleSaveCustomer = async (memberId: string, memberInputs: any[]) => {
//     if (!baseId || !id) {
//       toast.error('Base ID or Input ID is missing!');
//       return;
//     }

//     const memberValues = inputValues[memberId] || {};

//     // Filter inputs to only save modified or empty inputs
//     const modifiedInputs = memberInputs.filter(input => {
//       const currentValue = memberValues[`item_input_${input.id}`] || '';
//       const originalValue = input.input_value || '';

//       // Save if:
//       // 1. The input is empty in the original data
//       // 2. The input value has been changed from the original
//       return originalValue === '' || currentValue !== originalValue;
//     });

//     if (modifiedInputs.length === 0) {
//       toast.info('No new changes to save');
//       return;
//     }

//     try {
//       setIsLoading(true);

//       // Prepare payload with only modified inputs
//       const payload = modifiedInputs.map(input => ({
//         id: input.id,
//         input_value: memberValues[`item_input_${input.id}`] || ''
//       }));

//       // Single API call to save modified inputs
//       await dispatch(
//         addValueInputData({
//           baseId: Number(baseId),
//           inputId: id,
//           payload: payload
//         })
//       ).unwrap();

//       toast.success(`${modifiedInputs.length} input(s) saved successfully!`);

//       const res = await dispatch(fetchExecuteItemList({ baseId, id })).unwrap();
//       setInputFill(res.data);
//     } catch (error) {
//       console.error('Error handling customer:', error);
//       toast.error(error.message || 'Failed to save responses');
//     } finally {
//       setIsLoading(false);
//     }
//   };
//   // const handleSaveCustomer = async (memberId: string, memberInputs: any[]) => {
//   //   if (!baseId || !id) {
//   //     toast.error('Base ID or Input ID is missing!');
//   //     return;
//   //   }

//   //   const memberValues = inputValues[memberId] || {};

//   //   if (Object.keys(memberValues).length === 0) {
//   //     toast.error('No input values to save!');
//   //     return;
//   //   }

//   //   try {
//   //     setIsLoading(true);

//   //     // Prepare a single payload with all input values for this member
//   //     const payload = memberInputs.map(input => ({
//   //       id: input.id,
//   //       input_value: memberValues[`item_input_${input.id}`] || ''
//   //     }));

//   //     // Single API call to save all inputs
//   //     await dispatch(
//   //       addValueInputData({
//   //         baseId: Number(baseId),
//   //         inputId: id,
//   //         payload: payload
//   //       })
//   //     ).unwrap();

//   //     toast.success('Responses saved successfully!');

//   //     const res = await dispatch(fetchExecuteItemList({ baseId, id })).unwrap();
//   //     setInputFill(res.data);
//   //   } catch (error) {
//   //     console.error('Error handling customer:', error);
//   //     toast.error(error.message || 'Failed to save responses');
//   //   } finally {
//   //     setIsLoading(false);
//   //   }
//   // };

//   return (
//     <div
//       className={`main-feedback-side flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 
//                 border-solid absolute  lg:fixed top-[60px] max-w-[80%] view-feedback-right-side border-r-0 ' `}
//       style={{
//         width: isAsideOpen6 ? '350px' : '350px',
//         transform: isAsideOpen6 ? 'none' : 'none',
//         right: isAsideOpen6 ? '51px' : '0px',
//         marginRight: isAsideOpen6 ? '0px' : '-350px',

//         height: isAsideOpen6 ? 'calc(100vh - +60px)' : '',
//         ...(window.innerWidth <= 768 && {
//           width: isAsideOpen6 ? '300px' : '300px',
//           marginRight: isAsideOpen6 ? '0' : '-300px',
//         }),
//       }}
//     >
//       <div className='h-full'>
//         <div className='h-auto flex flex-col justify-stretch min-h-[3rem] 
//                border-b-0 border-l-0 border-solid border-light-gray-200 p-[1rem] pt-[0.9rem] items-start gap-4'>
//           <div className=" flex items-start justify-center w-full">
//             <div className="mx-auto w-full">
//               <div className='flex items-center max-w-full w-fit mb-5 font-[500] leading-[1.2] bg-light-gray-100
//  rounded-full text-[0.75rem] px-[0.5rem] ml-[-4px] py-[5px] gap-2'>
//                 Input
//               </div>

//               {itemFillLoading ? (
//                 <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 120px)' }}>
//                   <div className="simple-spinner-loader">
//                     <span className="!w-6 !h-6"></span>
//                   </div>
//                 </div>
//               ) : (
//                 combinedDetails.map((item, index) => {
//                   // Declare firstMember outside the return JSX
//                   const memberNames = getInternalMembers(item.member_id);
//                   const membersPic = getInternalMembersPic(item.member_id);
//                   const memberNamesExternal = getExternalMembers(item.member_id);

//                   return (
//                     <div key={index} className="mb-8 mx-[-6px] w-full">
//                       <div className="flex justify-between">
//                         <a
//                           href="#"
//                           onClick={() => toggleCollapseCustomer(index)}
//                           className="h-8 gap-x-2 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
//             focus:outline-none px-2 justify-start inline-flex"
//                         >
//                           <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 
//             ring-1 ring-inset ring-gray-500/10 w-fit">
//                             {item.member_position}
//                           </span>

//                           {/* Conditionally render the profile picture or initials */}
//                           {membersPic.length > 0 ? (
//                             <div className=' relative flex justify-center items-center'>
//                               <img
//                                 className="h-6 w-6 rounded-full"
//                                 src={`${process.env.REACT_APP_API_BASE_URL}/${membersPic}`}
//                                 alt="Member Profile"
//                               />
//                               <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px] rounded-full' style={{
//                                 background: item.member_colour
//                               }}>

//                               </span>
//                             </div>

//                           ) : (
//                             <div className=' relative flex justify-center items-center'>
//                               <div className="size-6 rounded-full ring-2 ring-white bg-gray-300 flex items-center justify-center text-[11px] 
//     font-[500] text-[#52555a]">
//                                 {memberNames && memberNames.length > 0
//                                   ? memberNames[0].charAt(0).toUpperCase()
//                                   : (memberNamesExternal && memberNamesExternal.length > 0
//                                     ? `${memberNamesExternal[0].first_name?.charAt(0).toUpperCase() || memberNamesExternal[0].last_name?.charAt(0).toUpperCase() || memberNamesExternal[0].email?.charAt(0).toUpperCase()}`
//                                     : '')
//                                 }
//                               </div>
//                               <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px] rounded-full' style={{
//                                 background: item.member_colour
//                               }}>
//                               </span>
//                             </div>
//                           )}

//                           <span className="capitalize whitespace-nowrap max-w-[212px] overflow-hidden text-ellipsis">
//                             {memberNames && memberNames.length > 0
//                               ? memberNames
//                               : (memberNamesExternal && memberNamesExternal.length > 0
//                                 ? `${memberNamesExternal[0].first_name || ''} ${memberNamesExternal[0].last_name || ''} ${memberNamesExternal[0].email || ''}`.trim() || 'Unknown'
//                                 : 'Unknown')
//                             }
//                           </span>


//                           {/* Collapse Arrow */}
//                           <div
//                             className={`${collapsedCustomers[index] ? 'rotate-180' : 'rotate-0'} 
//                 h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}
//                           >
//                             <svg
//                               width={24}
//                               height={24}
//                               fill="none"
//                               className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
//                             >
//                               <path
//                                 stroke="currentColor"
//                                 strokeLinecap="round"
//                                 strokeLinejoin="round"
//                                 strokeWidth="1.5"
//                                 d="M15.25 10.75 12 14.25l-3.25-3.5"
//                               />
//                             </svg>
//                           </div>
//                         </a>
//                       </div>

//                       {/* Conditional Rendering of Collapsible Content */}
//                       <AnimatePresence initial={false}>
//                         {/* {userLoggedIn.id == item.member_id && !collapsedCustomers[index] && ( */}

//                         {!collapsedCustomers[index] && (
//                           <motion.div
//                             initial={{ height: 0 }}
//                             animate={{ height: 'auto' }}
//                             exit={{ height: 0 }}
//                             transition={{ height: { duration: 0.5 } }}
//                             className="overflow-hidden"
//                           >
//                             <div className="w-full mt-5">
//                               <div className="flex gap-y-[24px] flex-col">
//                                 {/* <div className="relative">
//                                   <label
//                                     htmlFor={`item_customer_name_${index}`}
//                                     className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
//                                   >
//                                     Name
//                                   </label>
//                                   <input
//                                     id={`item_customer_name_${index}`}
//                                     name="item_customer_name"
//                                     type="text"
//                                     maxLength={30}
//                                     className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
//                                     defaultValue=""
//                                     disabled={userLoggedIn.id != item.member_id}
//                                   />
//                                 </div> */}
//                                 {item.inputs && item.inputs.map((input, inputIndex) => (
//                                     <div key={inputIndex} className="relative">
//                                       <label
//                                         data-title={input.input_variable}
//                                         htmlFor={`item_input_${index}_${inputIndex}`}
//                                         className=" absolute -top-2 left-2 inline-block bg-white px-1 capitalize text-xs font-normal text-dark-black-200 text-[13px] max-w-[250px] text-ellipsis whitespace-nowrap overflow-hidden"
//                                       >
//                                         {input.input_variable || `Input ${inputIndex + 1}`}
//                                       </label>
//                                       <input
//                                         id={`item_input_${index}_${inputIndex}`}
//                                         name={`item_input_${index}_${inputIndex}`}
//                                         type={input.type || 'text'}
//                                         maxLength={input.maxLength || 30}
//                                         className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
//                                         disabled={userLoggedIn.id != item.member_id}
//                                         value={
//                                           inputValues[item.member_id]?.[`item_input_${input.id}`] ?? 
//                                           input.input_value ?? 
//                                           ''
//                                         }
//                                         onChange={(e) => handleInputChange(
//                                           item.member_id, 
//                                           `item_input_${input.id}`, 
//                                           e.target.value
//                                         )}
//                                       />

//                                     </div>

//                                 ))}
//                                   {userLoggedIn.id == item.member_id && (
//                                       <div className="w-auto flex justify-end">
//                                         <button
//                                           className="flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
//                                           type="button"
//                                           onClick={() => handleSaveCustomer(item.member_id, item.inputs)}

//                                           >


//                                           {isLoading ? (
//                                             <div className='flex items-center  gap-[6px]'>
//                                               Save
//                                               <div className="simple-spinner">
//                                                 <span></span>
//                                               </div>
//                                             </div>
//                                           ) : (
//                                             'Save'
//                                           )}

//                                         </button>
//                                       </div>
//                                     )}
//                               </div>
//                             </div>
//                           </motion.div>
//                         )}
//                       </AnimatePresence>
//                     </div>
//                   );
//                 })
//               )}

//             </div>

//           </div>
//         </div>
//       </div>
//     </div>
//   );
// });

// export default SendDocumentFill;


// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { format, formatDistanceToNow } from 'date-fns';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { fetchDocumentActivityItem } from '../../../features/docuementItemActivity/docuementItemActivitySlice';
import { useParams } from 'react-router-dom';
import CustomMenuDropdown from '../../../components/common/CustomMenuDropdown';
import { createItemchatData } from '../../../features/documentItemChat/documentItemChatSlice';
import { toast } from 'sonner';
import { motion, AnimatePresence } from 'framer-motion';
import { addValueInputData } from '../../../features/documentInput/documentInputSlice';
import { fetchExecuteItemList } from '../../../features/documentItemFill/documentItemFillSlice';
import { deleteMentionList, getMentionList, getEditorInstance, updateMentionCount } from '../../../editor'
import { updateDocument } from '../../../features/document/documentSlice';

interface DocumentDetailsChatProps {
  isAsideOpen6: boolean;
  inputFill: any;
  itemFillLoading: boolean;
  externalMembers: any;
  setInputFill: any;
}

interface DropdownItem {
  className?: string;
  icon?: ReactNode;
  label: string;
  shortcut?: any;
  disabled?: boolean;
  onClick?: () => void;
  href?: string;
}


const SendDocumentFill: React.FC<DocumentDetailsChatProps> = React.memo(({ isAsideOpen6,
  inputFill,
  itemFillLoading,
  externalMembers,
  setInputFill
  //  itemActivityChat, itemActivityLoading 
}) => {
  const dispatch = useAppDispatch();
  const { baseId } = useParams<{ baseId: string }>();
  const { id } = useParams<{ id: string }>();
  const localStorageData = JSON.parse(localStorage.getItem('baseMembersDetails') || '{}');
  const baseMembers = localStorageData?.data?.baseMembers || [];
  const getInternalDetails = inputFill.internal;
  const [collapsedCustomers, setCollapsedCustomers] = useState<Record<number, boolean>>({}); // Use object to store collapsed state per index
  const combinedDetails = [...(inputFill.internal || []), ...(inputFill.external || [])];
  const userLoggedIn = JSON.parse(localStorage.getItem('userDetails') || '{}');
  const [inputValues, setInputValues] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(false);

  // console.log(inputFill,"inputFill");

  //   const toggleCollapseCustomer = (index: number) => {
  //     // Assuming inputFill is an array and we need to find the user_id
  //     const matchedInputFill = combinedDetails.find(item => console.log(item,"sdsfd")
  //     ); // Replace with actual condition

  //     // Check if userLoggedIn and matchedInputFill exist, and if their IDs match
  //     if (userLoggedIn?.id === matchedInputFill?.user_id) {
  //       setCollapsedCustomers((prevCollapsed) => ({
  //         ...prevCollapsed,
  //         [index]: !prevCollapsed[index], // Toggle the collapse state for the specific index
  //       }));
  //     }
  //   };


  const toggleCollapseCustomer = (index: number) => {
    setCollapsedCustomers((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  }



  const getInternalMembers = (memberId) => {
    const members = baseMembers.filter((m) => m.id == memberId);
    return members.map((m) => m.name);
  };

  const getInternalMembersPic = (memberId) => {
    const members = baseMembers.filter((m) => m.id == memberId);
    return members.map((m) => m.profile_pic);
  };

  const getExternalMembers = (memberId) => {
    const members = externalMembers.filter((m) => m.member_id == memberId);
    return members.map((m) => m.content);
  };

  const handleInputChange = (memberId: string, inputId: string,name :string ,value: string) => {
    const editor = getEditorInstance()
    const currentHTML = editor.getHTML();

    const tempElement = document.createElement('div');
    tempElement.innerHTML = currentHTML;

    const spans = tempElement?.querySelectorAll(`span.mention[data-name="${name}"]`);
    spans.forEach(span => {
      span.innerHTML = `${value}`; // Set the new value inside the span
    });

    editor.commands.setContent(tempElement.innerHTML);

    setInputValues(prev => ({
      ...prev,
      [memberId]: {
        ...(prev[memberId] || {}),
        [inputId]: value
      }
    }));
  };
  const handleSave = async () => {
    console.log("handle Save Called");
    const editorInstance = getEditorInstance()
    if (!editorInstance) {
      toast.error('Editor not initialized');
      return;
    }
    // setIsSaving(true);
    try {
      // Get both HTML and JSON content from editor
      const htmlContent = editorInstance.getHTML();
      console.log(htmlContent)
      const jsonContent = editorInstance.getJSON();

      const payload = {
        id: id,
        baseId: baseId,
        doc_content: JSON.stringify(jsonContent),
        doc_html: htmlContent,
        // channel_id: channelId,
        // doc_content: JSON.stringify({
        //   html: htmlContent,
        //   json: 
        //   jsonContent,
        //   timestamp: new Date().toISOString()
        // })
      };

      const result = await dispatch(updateDocument(payload)).unwrap();
      toast.success('Document saved successfully');
      // setIsEditingEditor(false)
    } catch (error) {
      console.error('Failed to save document:', error);
      toast.error('Failed to save document');
    } 
  };

  const handleSaveCustomer = async (memberId: string, memberInputs: any[]) => {
    if (!baseId || !id) {
      toast.error('Base ID or Input ID is missing!');
      return;
    }

    const memberValues = inputValues[memberId] || {};

    // Check for mandatory inputs
    // const mandatoryInputsMissing = memberInputs.filter(input =>
    //   input.input_mandatory === true &&
    //   (!memberValues[`item_input_${input.id}`] ||
    //     memberValues[`item_input_${input.id}`].trim() === '')
    // );

    const mandatoryInputsMissing = memberInputs.filter(input => {
      // If input is mandatory
      if (input.input_mandatory === true) {
        // Get the current value (either from input values or original value)
        const currentValue =
          memberValues[`item_input_${input.id}`] ||
          input.input_value ||
          '';

        // Check if the current value is empty
        return currentValue.trim() === '';
      }
      return false;
    });

    if (mandatoryInputsMissing.length > 0) {
      const missingInputNames = mandatoryInputsMissing
        .map(input => input.input_variable || `Input ${input.id}`)
        .join(', ');
      toast.error(`Please fill in the mandatory fields`);
      return;
    }



    // Filter inputs to only save modified or empty inputs
    const modifiedInputs = memberInputs.filter(input => {
      const currentValue = memberValues[`item_input_${input.id}`] || '';
      const originalValue = input.input_value || '';

      // Save if:
      // 1. The input is empty in the original data
      // 2. The input value has been changed from the original
      return originalValue === '' || currentValue !== originalValue;
    });

    if (modifiedInputs.length === 0) {
      toast.info('No new changes to save');
      return;
    }

    try {
      setIsLoading(true);


      // modifiedInputs.map(input => {
      //   const editor = getEditorInstance()
      //   const currentHTML = editor.getHTML();
      //   const tempElement = document.createElement('div');
      //   tempElement.innerHTML = currentHTML;

      //   const spans = tempElement?.querySelectorAll(`span.mention[data-name="${input.input_title}"]`);
      //   spans.forEach(span => {
      //     span.innerHTML = `${input.input_value}`; // Set the new value inside the span
      //   });
      //   editor.commands.setContent(tempElement.innerHTML);
      // })

      // Prepare payload with only modified inputs
      const payload = modifiedInputs.map(input => ({
        id: input.id,
        input_value: memberValues[`item_input_${input.id}`] || ""
      }));




      // Single API call to save modified inputs
      await dispatch(
        addValueInputData({
          baseId: Number(baseId),
          inputId: id,
          payload: payload
        })
      ).unwrap();
      await handleSave()
      toast.success(`Input(s) saved successfully!`);

      const res = await dispatch(fetchExecuteItemList({ baseId, id })).unwrap();
      setInputFill(res.data);
    } catch (error) {
      console.error('Error handling customer:', error);
      toast.error(error.message || 'Failed to save responses');
    } finally {
      setIsLoading(false);
    }
  };

  // Determine if input should be animated
  const shouldAnimate = (item: any) => {
    // Animate only for logged-in user's inputs
    return userLoggedIn.id === item.internal_member_id;
  };



  return (
    <div
      className={`main-feedback-side flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 
                border-solid absolute  lg:fixed top-[60px] max-w-[80%] view-feedback-right-side border-r-0 ' `}
      style={{
        width: isAsideOpen6 ? '350px' : '350px',
        transform: isAsideOpen6 ? 'none' : 'none',
        right: isAsideOpen6 ? '51px' : '0px',
        marginRight: isAsideOpen6 ? '0px' : '-350px',

        height: isAsideOpen6 ? 'calc(100vh - +60px)' : '',
        ...(window.innerWidth <= 768 && {
          width: isAsideOpen6 ? '300px' : '300px',
          marginRight: isAsideOpen6 ? '0' : '-300px',
        }),
      }}
    >
      <div className='h-full'>
        <div className='h-auto flex flex-col justify-stretch min-h-[3rem] 
               border-b-0 border-l-0 border-solid border-light-gray-200 p-[1rem] pt-[0.9rem] items-start gap-4'>
          <div className=" flex items-start justify-center w-full">
            <div className="mx-auto w-full">
              <div className='flex items-center max-w-full w-fit mb-5 font-[500] leading-[1.2] bg-light-gray-100
 rounded-full text-[0.75rem] px-[0.5rem] ml-[-4px] py-[5px] gap-2'>
                Input
              </div>

              {itemFillLoading ? (
                <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 120px)' }}>
                  <div className="simple-spinner-loader">
                    <span className="!w-6 !h-6"></span>
                  </div>
                </div>
              ) : (
                combinedDetails.map((item, index) => {
                  // Declare firstMember outside the return JSX
                  const memberNames = getInternalMembers(item.member_id);
                  const membersPic = getInternalMembersPic(item.member_id);
                  const memberNamesExternal = getExternalMembers(item.member_id);

                  const isUserInput = shouldAnimate(item);
                  const isCollapsed = collapsedCustomers[index];

                  return (
                    <div key={index} className="mb-8  w-full">
                      <div className="flex justify-between">
                        <a
                          href="#"
                          // onClick={() => toggleCollapseCustomer(index)}
                          className=" gap-x-2 items-center  justify-between inline-flex w-full"
                        >
                          <button className='h-8 gap-x-2 items-center flex justify-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
            focus:outline-none px-0'
                            onClick={() => toggleCollapseCustomer(index)}
                          >
                            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 
            ring-1 ring-inset ring-gray-500/10 w-fit">
                              {item.member_position}
                            </span>


                            {
                              (item?.user || item?.contact) && (
                                <div className="relative flex justify-center items-center">
                                  {item?.user ? (
                                    item.user.profile_pic ? (
                                      <img
                                        className="h-6 w-6 rounded-full"
                                        src={`${process.env.REACT_APP_API_BASE_URL}/${item.user.profile_pic}`}
                                        alt="Member Profile"
                                      />
                                    ) : (
                                      <div className="size-6 rounded-full ring-2 ring-white bg-gray-300 flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                        {item?.user?.name.charAt(0).toUpperCase()}
                                      </div>
                                    )
                                  ) : (
                                    <div className="size-6 rounded-full ring-2 ring-white bg-gray-300 flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                      {item?.contact?.contact_first_name?.charAt(0).toUpperCase() || item?.contact?.contact_email?.charAt(0).toUpperCase()}
                                    </div>
                                  )}
                                  <span
                                    className="absolute right-[0px] bottom-[0px] w-[10px] h-[10px] rounded-full"
                                    style={{ background: item.member_colour }}
                                  ></span>
                                </div>
                              )
                            }

                            {
                              item.user &&
                              <span className="capitalize whitespace-nowrap max-w-[212px] overflow-hidden text-ellipsis">
                                {item.user.name}
                              </span>
                            }

                            {
                              item.contact &&
                              <span className="capitalize whitespace-nowrap max-w-[212px] overflow-hidden text-ellipsis">
                                {item.contact.contact_first_name || item.contact.contact_last_name || item.contact.contact_email}
                              </span>
                            }


                            {/* Collapse Arrow */}
                            <div
                              className={`${collapsedCustomers[index] ? 'rotate-180' : 'rotate-0'} 
                h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}
                            >
                              <svg
                                width={24}
                                height={24}
                                fill="none"
                                className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                  d="M15.25 10.75 12 14.25l-3.25-3.5"
                                />
                              </svg>
                            </div>
                          </button>


                          <div className=' relative'>
                            {
                              item.action_status === "pending" &&
                              <button data-title={'Pending'}
                                className='table-tooltip before:!bottom-[-5px] before:!left-[-34px]'
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-check w-[1.2rem] h-[1.2rem] text-[#808080] "
                                >
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" />
                                  <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" />
                                  <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" />
                                  <path d="M8.56 20.31a9 9 0 0 0 3.44 .69" />
                                  <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" />
                                  <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" />
                                  <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" />
                                  <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" />
                                  <path d="M9 12l2 2l4 -4" />
                                </svg>
                              </button>

                            }

                            {
                              item.action_status === "complete" &&
                              <button
                                data-title={'Completed'}
                                className='table-tooltip before:!bottom-[-5px] before:!left-[-42px]'
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="icon icon-tabler icons-tabler-outline icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                >
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                  <path d="M9 12l2 2l4 -4" />
                                </svg>
                              </button>

                            }


                            {
                              item.action_status === "lock" &&
                              <button
                                data-title={'Locked'}
                                className='table-tooltip before:!bottom-[-5px] before:!left-[-30px]'
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="icon icon-tabler icons-tabler-filled icon-tabler-circle-check w-[1.2rem] h-[1.2rem] text-[#008236]"
                                >
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                                </svg>
                              </button>
                            }

                          </div>


                        </a>
                      </div>

                      {/* Conditional Rendering of Collapsible Content */}
                      <AnimatePresence initial={false}>
                        {/* {userLoggedIn.id == item.member_id && !collapsedCustomers[index] && ( */}

                        {isUserInput ? (
                          !isCollapsed && (
                            <motion.div
                              initial={{ height: 0 }}
                              animate={{ height: 'auto' }}
                              exit={{ height: 0 }}
                              transition={{ height: { duration: 0.5 } }}
                              className="overflow-hidden"
                            >
                              <div className="w-full mt-5">
                                <div className="flex gap-y-[24px] flex-col">
                                  {/* <div className="relative">
                                  <label
                                    htmlFor={`item_customer_name_${index}`}
                                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                  >
                                    Name
                                  </label>
                                  <input
                                    id={`item_customer_name_${index}`}
                                    name="item_customer_name"
                                    type="text"
                                    maxLength={30}
                                    className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                    defaultValue=""
                                    disabled={userLoggedIn.id != item.member_id}
                                  />
                                </div> */}
                                  {item.inputs && item.inputs.map((input, inputIndex) => (
                                    <div key={inputIndex} className="relative">
                                      <label
                                        data-title={input.input_variable}
                                        htmlFor={`item_input_${index}_${inputIndex}`}
                                        className=" absolute -top-2 left-2 inline-block bg-white px-1 capitalize text-xs font-normal text-dark-black-200 text-[13px] max-w-[250px] text-ellipsis whitespace-nowrap overflow-hidden"
                                      >
                                        {input.input_variable || `Input ${inputIndex + 1}`}
                                        {
                                          input.input_mandatory == true &&
                                          <span className='text-red-500 ml-1'>*</span>
                                        }
                                      </label>
                                      <input
                                        id={`item_input_${index}_${inputIndex}`}
                                        name={`item_input_${index}_${inputIndex}`}
                                        type={input.type || 'text'}
                                        maxLength={input.maxLength || 30}
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                        disabled={userLoggedIn.id != item.internal_member_id}
                                        value={
                                          inputValues[item.internal_member_id]?.[`item_input_${input.id}`] ??
                                          input.input_value ??
                                          ''
                                        }
                                        onChange={(e) => handleInputChange(
                                          item.internal_member_id,
                                          `item_input_${input.id}`,input.input_variable,
                                          e.target.value
                                        )}
                                      />
                                    </div>
                                  ))}
                                  {userLoggedIn.id == item.internal_member_id && (
                                    <div className="w-auto flex justify-end">
                                      <button
                                        className="flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        type="button"
                                        onClick={() => handleSaveCustomer(item.internal_member_id, item.inputs)}

                                      >


                                        {isLoading ? (
                                          <div className='flex items-center  gap-[6px]'>
                                            Save
                                            <div className="simple-spinner">
                                              <span></span>
                                            </div>
                                          </div>
                                        ) : (
                                          'Save'
                                        )}

                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </motion.div>
                          )
                        ) : (
                          // For non-logged-in user, simple div without animation
                          isCollapsed && (
                            <motion.div
                              initial={{ height: 0 }}
                              animate={{ height: 'auto' }}
                              exit={{ height: 0 }}
                              transition={{ height: { duration: 0.5 } }}
                              className="overflow-hidden"
                            >
                              <div className="w-full mt-5">
                                <div className="flex gap-y-[24px] flex-col">
                                  {/* <div className="relative">
                                  <label
                                    htmlFor={`item_customer_name_${index}`}
                                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                  >
                                    Name
                                  </label>
                                  <input
                                    id={`item_customer_name_${index}`}
                                    name="item_customer_name"
                                    type="text"
                                    maxLength={30}
                                    className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                    defaultValue=""
                                    disabled={userLoggedIn.id != item.member_id}
                                  />
                                </div> */}
                                  {item.inputs && item.inputs.map((input, inputIndex) => (
                                    <div key={inputIndex} className="relative">
                                      <label
                                        data-title={input.input_variable}
                                        htmlFor={`item_input_${index}_${inputIndex}`}
                                        className=" absolute -top-2 left-2 inline-block bg-white px-1 capitalize text-xs font-normal text-dark-black-200 text-[13px] max-w-[250px] text-ellipsis whitespace-nowrap overflow-hidden"
                                      >
                                        {input.input_variable || `Input ${inputIndex + 1}`}
                                        {
                                          input.input_mandatory == true &&
                                          <span className='text-red-500 ml-1'>*</span>
                                        }
                                      </label>
                                      <input
                                        id={`item_input_${index}_${inputIndex}`}
                                        name={`item_input_${index}_${inputIndex}`}
                                        type={input.type || 'text'}
                                        maxLength={input.maxLength || 30}
                                        className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                        disabled={userLoggedIn.id != item.internal_member_id}
                                        value={
                                          inputValues[item.internal_member_id]?.[`item_input_${input.id}`] ??
                                          input.input_value ??
                                          ''
                                        }
                                        onChange={(e) => handleInputChange(
                                          item.internal_member_id,
                                          `item_input_${input.id}`,input.input_variable,
                                          e.target.value
                                        )}
                                      />
                                    </div>
                                  ))}
                                  {userLoggedIn.id == item.internal_member_id && (
                                    <div className="w-auto flex justify-end">
                                      <button
                                        className="flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        type="button"
                                        onClick={() => handleSaveCustomer(item.internal_member_id, item.inputs)}

                                      >


                                        {isLoading ? (
                                          <div className='flex items-center  gap-[6px]'>
                                            Save
                                            <div className="simple-spinner">
                                              <span></span>
                                            </div>
                                          </div>
                                        ) : (
                                          'Save'
                                        )}

                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </motion.div>
                          )
                        )}
                      </AnimatePresence>
                    </div>
                  );
                })
              )}

            </div>

          </div>
        </div>
      </div>
    </div>
  );
});

export default SendDocumentFill;
