import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import Modal from '../../../components/common/Modal';
import { useAppDispatch } from '../../../app/hooks';
import { createTeamsPayload } from '../../pipelineStages/pipelineStagesTypes';
import { toast } from 'sonner';
import { createLocation, fetchPipelineStagesList, updateLocation } from '../../pipelineStages/pipelineStagesSlice';
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckCircleColorIcon, ChevronDownIcon, ChevronDownIconWhite, CustomCheckIcon, CustomPlusColorIcon, EllipsisHorizontalCircleColorIcon, XCircleColorIcon } from '../../../components/icons';

interface BoardPageProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    editBoardData?: any;
    baseId: number;
    setEditBoardData: (value: any) => void;
    userId: number;
}



const people = [
    { id: 1, name: 'inbox', icon: CustomPlusColorIcon, color: 'text-[#4b5563]' },
    { id: 2, name: 'screening', icon: EllipsisHorizontalCircleColorIcon, color: 'text-[#4338ca]' },
    { id: 3, name: 'hired', icon: CheckCircleColorIcon, color: 'text-[#15803d]' },
    { id: 4, name: 'reject', icon: XCircleColorIcon, color: 'text-[#b91c1c]' }
];

const CreatePipelineStages: React.FC<BoardPageProps> = ({ isModalOpen, setIsModalOpen, editBoardData, baseId, setEditBoardData, userId }) => {
    const [name, setName] = useState('');
    const [purpose, setPurpose] = useState('');
    const [color, setColor] = useState('bg-[#3b82f6]');
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState('');
    const [purposeError, setPurposeError] = useState('');
    const dispatch = useAppDispatch();
    const userStatus = localStorage.getItem('userDetails');
    const userStatusId = userStatus ? JSON.parse(userStatus) : null;
    const [baseTeamsData, setBaseTeamsData] = useState<any[]>([]);
    const [selected, setSelected] = useState(people[0])


    const colorList = [
        { name: "bg-[#3b82f6]" },
        { name: "bg-[#6b7280]" },
        { name: "bg-[#bfdbfe]" },
        { name: "bg-[#ec4899]" },
        { name: "bg-[#6366f1]" },
        { name: "bg-[#eab308]" },
        { name: "bg-[#22c55e]" },
        { name: "bg-[#06b6d4]" },
        { name: "bg-[#dc2626]" },
    ];


    // Load edit data when available
    useEffect(() => {
        if (editBoardData) {
            setName(editBoardData.stage_name || '');
            setColor(`bg-[${editBoardData.stage_colour}]` || 'bg-[#3b82f6]');

            // Find the matching bucket from the people array
            const matchingBucket = people.find(person => person.name === editBoardData.bucket);
            if (matchingBucket) {
                setSelected(matchingBucket);
            } else {
                setSelected(people[0]); // Default to the first item if no match is found
            }
        }
    }, [editBoardData]);


    useEffect(() => {
        const storedData = localStorage.getItem('basePiplineStagesData');
        if (storedData) {
            setBaseTeamsData(JSON.parse(storedData));
        }
    }, []);

    const handleSave = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setNameError("");
        setPurposeError("");


        if (name.trim().length === 0) {
            setNameError("Name is required");
            setLoading(false);
            return;
        }
        // Check if bucket is selected
        if (!selected || !selected.name) {
            setPurposeError("Bucket is required"); // Assuming this error should be shown for bucket
            setLoading(false);
            return;
        }
        // else if (purpose.trim().length === 0) {
        //     // setPurposeError("Description is required");
        //     setLoading(false);
        //     return;
        // }

        const colorValue = color.substring(color.indexOf("[#") + 1, color.indexOf("]"));

        const payload: createTeamsPayload = {
            base_id: baseId,
            stage_name: name,
            stage_colour: colorValue,
            pipeline_id: userId,
            bucket: selected.name
        };

        try {
            if (editBoardData) {
                // Update existing team
                const response = await dispatch(updateLocation({
                    payload,
                    baseId: baseId,
                    id: editBoardData.id
                }));

                if (response.meta.requestStatus === 'fulfilled') {
                    toast.success('Team updated successfully!');
                } else {
                    toast.error('Failed to update team.');
                }
            } else {
                // Create new team
                const response = await dispatch(createLocation({ payload, userId: baseId }));
                if (response.meta.requestStatus === 'fulfilled') {
                    toast.success('Create Stages successfully!');
                } else if (response.meta.requestStatus === 'rejected') {
                    toast.error('Pipeline Stage exists in base');
                } 
                
                else {
                    toast.error('Failed to create team.');
                }
            }

            setIsModalOpen(false);
            resetForm();

            // Fetch updated list
            const baseResponse = await dispatch(fetchPipelineStagesList({ baseId: Number(baseId), userId }));
            // console.log(baseResponse, ":::");

            if (baseResponse?.payload) {
                localStorage.setItem('basePiplineStagesData', JSON.stringify(baseResponse.payload));
                window.dispatchEvent(new StorageEvent('storage', {
                    key: 'basePiplineStagesData',
                    newValue: JSON.stringify(baseResponse.payload)
                }));
            }

        } catch (error) {
            console.error("Failed to save team:", error);
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        setName("");
        setPurpose("");
        setColor("bg-[#3b82f6]");
        // setChecked(false);
        setEditBoardData(null);  // Add this line
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setName(value);
            setNameError(value.trim().length === 0 ? "Name is required" : "");
        }
        else if (name === 'purpose') {
            setPurpose(value);
            // setPurposeError(value.trim().length === 0 ? "Description is required" : "");
        }
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={() => {
                setIsModalOpen(false);
                resetForm();
            }}
            title={editBoardData ? "Edit Pipeline" : "Create Stages"}
            onSave={handleSave}
            loading={loading}
        >
            <form action="#" onSubmit={handleSave} className="space-y-6 mt-5">
                <div className='flex flex-col gap-y-2'>
                    <label className='wg-label inline-flex cursor-pointer items-center gap-1 text-sm font-medium leading-6'>Name</label>
                    <div className="relative">
                        <Menu as='div' className='flex items-center'>
                            <Menu.Button className='absolute inset-y-0 left-0 flex items-center px-2'>
                                <div className={`h-6 w-6 rounded-full ${color} border flex justify-center items-center`}>
                                <ChevronDownIconWhite/>
                                </div>
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter='transition ease-out duration-100'
                                enterFrom='transform opacity-0 scale-95'
                                enterTo='transform opacity-100 scale-100'
                                leave='transition ease-in duration-75'
                                leaveFrom='transform opacity-100 scale-100'
                                leaveTo='transform opacity-0 scale-95'>
                                <Menu.Items className='absolute flex flex-wrap left-0 top-8 z-10 mt-2.5 w-44 p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                                    {colorList.map((value, index) => (
                                        <div
                                            onClick={() => setColor(value.name)}
                                            key={index}
                                            className={`flex h-6 w-6 border ${value.name} rounded-full m-2 justify-center items-center cursor-pointer`}
                                        >
                                            {color === value.name && <CustomCheckIcon/>}
                                        </div>
                                    ))}
                                </Menu.Items>
                            </Transition>
                            <input
                                id='name'
                                name='name'
                                type='text'
                                value={name}
                                onChange={handleInputChange}
                                placeholder='Enter Name'
                                className="w-full px-4 py-2 pl-10 text-sm leading-6 rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                            />
                        </Menu>
                    </div>
                    {nameError && <p className="text-sm text-red-600">{nameError}</p>}



                    <Listbox value={selected} onChange={setSelected}>
                        <Label className="wg-label inline-flex cursor-pointer items-center gap-1 text-sm font-medium leading-6">Bucket</Label>
                        <div className="relative">
                            <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-white-900 shadow-sm ring-1 
                        ring-inset ring-gray-300 focus:outline-none  sm:text-sm sm:leading-6">
                                <span className="flex items-center">
                                    <selected.icon className={`h-4 w-4 text-black mr-2 ${selected.color}`} />
                                    <span className={`block truncate capitalize ${selected.color}`}>{selected.name}</span>
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronDownIcon  />
                                </span>
                            </ListboxButton>

                            <ListboxOptions
                                transition
                                className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-opacity-5 focus:outline-none"
                            >
                                {people.map((person) => (
                                    <ListboxOption
                                        key={person.id}
                                        value={person}
                                        className="group relative cursor-pointer select-none py-2 pl-3 pr-9 text-[#374151] data-[focus]:bg-gray-200 data-[focus]:text-[#374151] text-[14px]"
                                    >
                                        <span className="flex items-center">
                                            {/* <person.icon className="h-4 w-4 text-[#374151] mr-2" /> */}
                                            <person.icon className={`h-4 w-4 ${person.color} mr-2`} aria-hidden="true" />
                                            <span className={`block truncate capitalize text-[14px] ${person.color}`} >
                                                {person.name}
                                            </span>
                                        </span>
                                    </ListboxOption>
                                ))}
                            </ListboxOptions>
                            {purposeError && <p className="text-sm text-red-600">{purposeError}</p>}
                        </div>
                    </Listbox>
                </div>
            </form>
        </Modal>
    );
};

export default CreatePipelineStages;