// import React, { Suspense, useEffect, useState } from 'react';
// import { Routes, Route, useParams, useNavigate, useLocation } from 'react-router-dom';
// import PrivateRoute from './PrivateRoute'; // Keep your private route logic intact
// import LoadingSpinner from '../components/common/LoadingSpinner';
// import BaseDetails from '../features/setting/baseSetting/BaseDetails';
// import SettingLayout from '../components/setting/SettingLayout';
// import { useAppSelector } from '../app/hooks';
// import DetailsLayout from '../features/setting/baseSetting/details/DetailsLayout';
// import BaseTest from '../features/setting/baseSetting/BaseTest';
// import BaseMember from '../features/setting/baseSetting/BaseMember';
// import UserLayout from '../components/user/UserLayout';
// import UserProfile from '../features/setting/profileSetting/UserProfile';
// import UserSecurity from '../features/setting/profileSetting/UserSecurity';
// import { isDotDotDotToken } from 'typescript';
// import MemberLayout from '../features/setting/baseSetting/members/MemberLayout';
// import BaseMemberPending from '../features/setting/baseSetting/members/BaseMemberPending';
// import Teams from '../features/setting/baseSetting/Teams';
// import TeamDetails from '../features/setting/baseSetting/teamMember/TeamMemberDataDetails';
// import TeamMemberLayout from '../features/setting/baseSetting/teamMember/TeamMemberLayout';
// import TeamMemberDetails from '../features/setting/baseSetting/teamMember/TeamMemberDetails';
// import TeamMemberDataDetails from '../features/setting/baseSetting/teamMember/TeamMemberDataDetails';
// import TeamMemberPending from '../features/setting/baseSetting/teamMember/TeamMemberPending';


// // Lazy load components
// const LoginForm = React.lazy(() => import('../components/auth/LoginForm'));
// const RegisterForm = React.lazy(() => import('../components/auth/RegisterForm'));
// const ForgotForm = React.lazy(() => import('../components/auth/ForgotForm'));
// const ResetForm = React.lazy(() => import('../components/auth/ResetForm'));
// const OtpForm = React.lazy(() => import('../components/auth/OtpForm'));
// const DashboardLayout = React.lazy(() => import('../components/dashboard/DashboardLayout'));
// const DashboardHome = React.lazy(() => import('../components/dashboard/DashboardHome'));
// const BaseList = React.lazy(() => import('../components/base/BaseList'));
// const NotFound = React.lazy(() => import('../components/errors/NotFound'));
// const UserPage = React.lazy(() => import('../components/user'));
// const CreateBase = React.lazy(() => import('../components/base/CreateBase'));


// const AppRoutes: React.FC = () => {
//   const [userDetails, setUserDetails] = useState<any>(null);
//   const userDetailsFromRedux = useAppSelector((state: any) => state.auth.userDetails); // Ensure auth slice contains userDetails
//   const { base, user, isAuthenticated } = useAppSelector((state: any) => state.auth);
//   const { baseId } = useParams<{ baseId: string }>(); // Use baseId instead of id
//   const [userData, setUserData] = useState<any>(null);
//   const [userBaseData, setUserBaseData] = useState('')
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     const storedUserDetails = JSON.parse(localStorage.getItem("UserBaseId") || "{}");
//     setUserDetails(storedUserDetails);
//   }, []);

//   const UserDetailsData = user || userDetails;


//   // test
//   // const navigate = useNavigate();

//   // const CheckUserBaseId: React.FC<{ children: React.ReactNode }> = ({ children }) => {
//   //   useEffect(() => {
//   //     const storedBaseId = localStorage.getItem("UserBaseId");
//   //     if (baseId && storedBaseId && baseId !== storedBaseId) {
//   //       navigate('/404');
//   //     }
//   //   }, [baseId]);

//   //   return <>{children}</>;
//   // };


//   // console.log(CheckUserBaseId,"check base id");

//   useEffect(() => {
//     const userBaseDetailsString = localStorage.getItem('baseDetails');
//     if (userBaseDetailsString) {
//       try {
//         const userBaseDetails = JSON.parse(userBaseDetailsString);
//         setUserBaseData(userBaseDetails?.data);
//       } catch (e) {
//         console.error("Error parsing userDetails:", e);
//       }
//     }
//   }, []);

//   // user details
//   useEffect(() => {
//     const userDetailsString = localStorage.getItem('userDetails');
//     if (userDetailsString) {
//       try {
//         const userDetailsData = JSON.parse(userDetailsString);
//         setUserData(userDetailsData);
//       } catch (e) {
//         console.error("Error parsing userDetails:", e);
//       }
//     }
//   }, []);

//   // Redirect if user is not an admin and not on the details settings route
//   useEffect(() => {
//     if (userData && userData.role !== 'admin') {
//       const isDetailsRoute = location.pathname.includes(`/user/${userData?.id}/security`) ||
//         location.pathname.includes(`/user/${userData?.id}/profile`);
//       if (!isDetailsRoute) {
//         navigate(`/${userData?.current_base}/dashboard`);
//       }
//     }
//   }, [userData, navigate, location.pathname]);


//   return (
//     <Suspense fallback={<LoadingSpinner />}>
//       <Routes>
//         {/* Public routes */}
//         <Route path="/" element={<LoginForm />} />
//         <Route path="/signup" element={<RegisterForm />} />
//         <Route path="/forgotpassword" element={<ForgotForm />} />
//         <Route path="/reset-password" element={<ResetForm />} />
//         <Route path="/reset-password/:token" element={<ResetForm />} />
//         <Route path="/otp" element={<OtpForm />} />

//         {/* Private routes (protected) */}
//         <Route
//           path="/base"
//           element={
//             <PrivateRoute>
//               <BaseList userDetails1={UserDetailsData} />
//             </PrivateRoute>
//           }
//         />

//         <Route path="/:baseId/settings/*" element={
//           <PrivateRoute>
//             <SettingLayout userDetails={UserDetailsData}>
//               <Routes>
//                 <Route path="/details/*" element={
//                   <DetailsLayout userDetails={UserDetailsData}>
//                     <Routes>
//                       <Route index element={<BaseDetails userDetails={UserDetailsData} />} />
//                       <Route path="label" element={<BaseTest />} />
//                       <Route path="board" element={<UserPage />} />
//                       <Route path="status" element={<UserPage />} />
//                     </Routes>
//                   </DetailsLayout>
//                 } />

//                 <Route path="/member/*" element={
//                   <MemberLayout userDetails={UserDetailsData}>
//                     <Routes>
//                       <Route index element={<BaseMember userDetails={UserDetailsData} />} />
//                       <Route path="pending" element={<BaseMemberPending userDetails={UserDetailsData} />} />
//                     </Routes>
//                   </MemberLayout>
//                 } />


//                 <Route path="teams/*" element={<Teams userDetails={UserDetailsData} />} />
//                 <Route path="teams/:userId/detail/*"
//                   // element={<TeamDetails userDetails={UserDetailsData} />}
//                   element={
//                     <TeamMemberLayout userDetails={UserDetailsData}>
//                       <Routes>
//                         <Route index element={<TeamMemberDetails userDetails={UserDetailsData} />} />
//                         <Route path="member" element={<TeamMemberDataDetails userDetails={UserDetailsData} />} />
//                         <Route path="pending" element={<TeamMemberPending userDetails={UserDetailsData} />} />
//                       </Routes>
//                     </TeamMemberLayout>
//                   }


//                 />
//                 <Route path="status" element={<UserPage />} />
//               </Routes>
//             </SettingLayout>
//           </PrivateRoute>
//         } />
//         <Route
//           path="/createbase"
//           element={
//             <PrivateRoute>
//               <CreateBase userDetails={UserDetailsData} />
//             </PrivateRoute>}
//         />
//         {/* Dashboard routes */}
//         <Route
//           path="/:baseId/*"
//           element={
//             <PrivateRoute>
//               {/* <CheckUserBaseId> */}
//               <DashboardLayout userDetails={UserDetailsData}>
//                 <Suspense fallback={<LoadingSpinner />}>
//                   <Routes>
//                     <Route path="dashboard" element={<DashboardHome />} />
//                     <Route path="user1" element={<UserPage />} />
//                     <Route path="projects" element={<DashboardHome />} />
//                   </Routes>
//                 </Suspense>
//               </DashboardLayout>
//               {/* </CheckUserBaseId> */}
//             </PrivateRoute>
//           }
//         />

//         {/* user route */}

//         <Route path="/user/:baseId/*" element={
//           <PrivateRoute>
//             <UserLayout userDetails={UserDetailsData}>
//               <Routes>
//                 <Route path="profile" element={<UserProfile />} />
//                 <Route path="security" element={<UserSecurity />} />
//               </Routes>
//             </UserLayout>
//           </PrivateRoute>
//         } />

//         {/* Fallback for 404 Not Found */}
//         <Route path="*" element={<NotFound />} />
//       </Routes>
//     </Suspense>
//   );
// };

// export default AppRoutes;



// // import React, { Suspense, useEffect, useState } from 'react';
// // import { Routes, Route, useParams, Navigate } from 'react-router-dom';
// // import PrivateRoute from './PrivateRoute';
// // import LoadingSpinner from '../components/common/LoadingSpinner';
// // import BaseDetails from '../features/setting/baseSetting/BaseDetails';
// // import SettingLayout from '../components/setting/SettingLayout';
// // import { useAppSelector } from '../app/hooks';
// // import DetailsLayout from '../features/setting/baseSetting/details/DetailsLayout';
// // import BaseTest from '../features/setting/baseSetting/BaseTest';
// // import BaseMember from '../features/setting/baseSetting/BaseMember';


// // // Lazy load components
// // const LoginForm = React.lazy(() => import('../components/auth/LoginForm'));
// // const RegisterForm = React.lazy(() => import('../components/auth/RegisterForm'));
// // const ForgotForm = React.lazy(() => import('../components/auth/ForgotForm'));
// // const ResetForm = React.lazy(() => import('../components/auth/ResetForm'));
// // const OtpForm = React.lazy(() => import('../components/auth/OtpForm'));
// // const DashboardLayout = React.lazy(() => import('../components/dashboard/DashboardLayout'));
// // const DashboardHome = React.lazy(() => import('../components/dashboard/DashboardHome'));
// // const BaseList = React.lazy(() => import('../components/base/BaseList'));
// // const NotFound = React.lazy(() => import('../components/errors/NotFound'));
// // const UserPage = React.lazy(() => import('../components/user'));
// // const CreateBase = React.lazy(() => import('../components/base/CreateBase'));

// // const AppRoutes: React.FC = () => {
// //   const [userDetails, setUserDetails] = useState<any>(null);
// //   const userDetailsFromRedux = useAppSelector((state: any) => state.auth.userDetails); // Ensure auth slice contains userDetails
// //   const { base, user, isAuthenticated } = useAppSelector((state: any) => state.auth);

// //   useEffect(() => {
// //     const storedUserDetails = JSON.parse(localStorage.getItem("UserBaseId") || "{}");
// //     setUserDetails(storedUserDetails);
// //   }, []);

// //   const UserDetailsData = user || userDetails;

// //   // Helper component to validate baseId
// //   const ValidateBaseId: React.FC<{ children: React.ReactNode }> = ({ children }) => {
// //     const { baseId } = useParams<{ baseId: string }>();

// //     // Check if baseId is a valid number
// //     if (!/^\d+$/.test(baseId || '')) {
// //       return <Navigate to="/404" replace />;
// //     }

// //     return <>{children}</>;
// //   };

// //   return (
// //     <Suspense fallback={<LoadingSpinner />}>
// //       <Routes>
// //         {/* Public routes */}
// //         <Route path="/" element={<LoginForm />} />
// //         <Route path="/signup" element={<RegisterForm />} />
// //         <Route path="/forgotpassword" element={<ForgotForm />} />
// //         <Route path="/reset-password" element={<ResetForm />} />
// //         <Route path="/reset-password/:token" element={<ResetForm />} />
// //         <Route path="/otp" element={<OtpForm />} />

// //         {/* Private routes (protected) */}
// //         <Route
// //           path="/base"
// //           element={
// //             <PrivateRoute>
// //               <BaseList userDetails1={UserDetailsData} />
// //             </PrivateRoute>
// //           }
// //         />
// //         <Route path="/:baseId/settings/*" element={
// //           <PrivateRoute>
// //             <ValidateBaseId>
// //               <SettingLayout userDetails={UserDetailsData}>
// //                 <Routes>
// //                   <Route path="/details/*" element={
// //                     <DetailsLayout userDetails={UserDetailsData}>
// //                       <Routes>
// //                         <Route index element={<BaseDetails userDetails={UserDetailsData}/>} />
// //                         <Route path="label" element={<BaseTest />} />
// //                         <Route path="board" element={<UserPage />} />
// //                         <Route path="status" element={<UserPage />} />
// //                       </Routes>
// //                     </DetailsLayout>
// //                   } />
// //                   <Route path="member" element={<BaseMember />} />
// //                   <Route path="board" element={<UserPage />} />
// //                   <Route path="status" element={<UserPage />} />
// //                 </Routes>
// //               </SettingLayout>
// //             </ValidateBaseId>
// //           </PrivateRoute>
// //         } />
// //         <Route
// //           path="/createbase"
// //           element={
// //             <PrivateRoute>
// //               <CreateBase userDetails={UserDetailsData} />
// //             </PrivateRoute>
// //           }
// //         />
// //         {/* Dashboard routes */}
// //         <Route
// //           path="/:baseId/*"
// //           element={
// //             <PrivateRoute>
// //               <ValidateBaseId>
// //                 <DashboardLayout userDetails={UserDetailsData}>
// //                   <Suspense fallback={<LoadingSpinner />}>
// //                     <Routes>
// //                       <Route path="dashboard" element={<DashboardHome />} />
// //                       <Route path="user" element={<UserPage />} />
// //                       <Route path="projects" element={<DashboardHome />} />
// //                     </Routes>
// //                   </Suspense>
// //                 </DashboardLayout>
// //               </ValidateBaseId>
// //             </PrivateRoute>
// //           }
// //         />

// //         {/* user route */}
// //         <Route path="/:userId/user/*" element={
// //           <PrivateRoute>
// //             <SettingLayout userDetails={UserDetailsData}>
// //               <Routes>
// //                 <Route path="member" element={<BaseMember />} />
// //                 <Route path="board" element={<UserPage />} />
// //                 <Route path="status" element={<UserPage />} />
// //               </Routes>
// //             </SettingLayout>
// //           </PrivateRoute>
// //         } />

// //         {/* 404 Not Found route */}
// //         <Route path="/404" element={<NotFound />} />

// //         {/* Fallback for any unmatched routes */}
// //         <Route path="*" element={<Navigate to="/404" replace />} />
// //       </Routes>
// //     </Suspense>
// //   );
// // };

// // export default AppRoutes;


// @ts-nocheck



import React, { Suspense, useEffect, useState } from 'react';
import { Routes, Route, useParams, useNavigate, useLocation, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute'; // Keep your private route logic intact
import LoadingSpinner from '../components/common/LoadingSpinner';
import BaseDetails from '../features/setting/baseSetting/BaseDetails';
import SettingLayout from '../components/setting/SettingLayout';
import { useAppSelector } from '../app/hooks';
import DetailsLayout from '../features/setting/baseSetting/details/DetailsLayout';
import BaseTest from '../features/setting/baseSetting/BaseTest';
import BaseMember from '../features/setting/baseSetting/BaseMember';
import UserLayout from '../components/user/UserLayout';
import UserProfile from '../features/setting/profileSetting/UserProfile';
import UserSecurity from '../features/setting/profileSetting/UserSecurity';
import { isDotDotDotToken } from 'typescript';
import MemberLayout from '../features/setting/baseSetting/members/MemberLayout';
import BaseMemberPending from '../features/setting/baseSetting/members/BaseMemberPending';
import Teams from '../features/setting/baseSetting/Teams';
import TeamDetails from '../features/setting/baseSetting/teamMember/TeamMemberDataDetails';
import TeamMemberLayout from '../features/setting/baseSetting/teamMember/TeamMemberLayout';
import TeamMemberDetails from '../features/setting/baseSetting/teamMember/TeamMemberDetails';
import TeamMemberDataDetails from '../features/setting/baseSetting/teamMember/TeamMemberDataDetails';
import TeamMemberPending from '../features/setting/baseSetting/teamMember/TeamMemberPending';
import Pipeline from '../features/setting/baseSetting/Pipeline';
import PipelineStages from '../features/setting/baseSetting/PipelineStages';
import Tags from '../features/setting/baseSetting/Tags';
import Questionnaire from '../features/setting/baseSetting/Questionnaire';
import QuestionList from '../features/setting/baseSetting/QuestionList';
import Documents from '../pages/Documents';
import DocumentList from '../pages/Documents/DocumentList';
import DocumentDetailsData from '../pages/Documents/DocumentDetailsData';
import { SearchProvider } from '../context/SearchContext';
import DocumentDetails from '../components/documentDetail/DocumentDetails';
import SendDocument from '../pages/Send/SendDocument';
import DocumentDetailsLayout from '../components/documentDetail/DocumentDetailsLayout';


// Lazy load components
const LoginForm = React.lazy(() => import('../components/auth/LoginForm'));
const RegisterForm = React.lazy(() => import('../components/auth/RegisterForm'));
const ForgotForm = React.lazy(() => import('../components/auth/ForgotForm'));
const ResetForm = React.lazy(() => import('../components/auth/ResetForm'));
const OtpForm = React.lazy(() => import('../components/auth/OtpForm'));
const DashboardLayout = React.lazy(() => import('../components/dashboard/DashboardLayout'));
const DashboardHome = React.lazy(() => import('../components/dashboard/TipTapEditor'));
const BaseList = React.lazy(() => import('../components/base/BaseList'));
const NotFound = React.lazy(() => import('../components/errors/NotFound'));
const UserPage = React.lazy(() => import('../components/user'));
const CreateBase = React.lazy(() => import('../components/base/CreateBase'));

// Import all your components here (keeping the existing imports)

// New AdminRoute component
const AdminRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>(null);
  const [userBaseData, setUserBaseData] = useState<any>(null)
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);

  useEffect(() => {
    const userBaseDetailsString = localStorage.getItem('baseDetails');
    if (userBaseDetailsString) {
      try {
        const userBaseDetails = JSON.parse(userBaseDetailsString);
        setUserBaseData(userBaseDetails?.data);
      } catch (e) {
        console.error("Error parsing userDetails:", e);
      }
    }
  }, []);

  // user details
  // useEffect(() => {
  //   const userDetailsString = localStorage.getItem('userDetails');
  //   if (userDetailsString) {
  //     try {
  //       const userDetailsData = JSON.parse(userDetailsString);
  //       setUserData(userDetailsData);
  //     } catch (e) {
  //       console.error("Error parsing userDetails:", e);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    const userDetailsString = localStorage.getItem('userDetails');
    if (userDetailsString) {
      try {
        const userDetailsData = JSON.parse(userDetailsString);
        setIsAdmin(userDetailsData.role === 'admin');
        setUserData(userDetailsData);
      } catch (e) {
        console.error("Error parsing userDetails:", e);
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
    }
  }, []);

  // useEffect(() => {
  //   if (userData?.role !== 'admin') {
  //     navigate(`/${userData?.current_base}/dashboard`);
  //   }
  // }, [userData, navigate]);

  // if (userData?.role !== 'admin') {
  //   return <Navigate to={`/${userData?.current_base}/dashboard`} />;
  // }

  // return <>{children}</>;
  if (isAdmin === null) {
    return <LoadingSpinner />;
  }


  return isAdmin ? <>{children}</> : <Navigate to={`/${userData?.current_base}/dashboard`} replace />;
};

const AppRoutes: React.FC = () => {
  const [userDetails, setUserDetails] = useState<any>(null);
  const userDetailsFromRedux = useAppSelector((state: any) => state.auth.userDetails);
  const { base, user, isAuthenticated } = useAppSelector((state: any) => state.auth);
  const { baseId } = useParams<{ baseId: string }>();
  const [userData, setUserData] = useState<any>(null);
  const [userBaseData, setUserBaseData] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // Your existing useEffects here

  useEffect(() => {
    const storedUserDetails = JSON.parse(localStorage.getItem("UserBaseId") || "{}");
    setUserDetails(storedUserDetails);
  }, []);

  const UserDetailsData = user || userDetails;

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <SearchProvider>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="/signup" element={<RegisterForm />} />
          <Route path="/forgotpassword" element={<ForgotForm />} />
          <Route path="/reset-password" element={<ResetForm />} />
          <Route path="/reset-password/:token" element={<ResetForm />} />
          <Route path="/otp" element={<OtpForm />} />

          {/* Private routes (protected) */}
          <Route
            path="/base"
            element={
              <PrivateRoute>
                <BaseList userDetails1={UserDetailsData} />
              </PrivateRoute>
            }
          />

          <Route path="/:baseId/settings/*" element={
            <PrivateRoute>
              <SettingLayout userDetails={UserDetailsData}>
                <Routes>
                  <Route path="/details/*" element={
                    <AdminRoute>
                      <DetailsLayout userDetails={UserDetailsData}>
                        <Routes>
                          <Route index element={<BaseDetails userDetails={UserDetailsData} />} />
                          <Route path="label" element={<BaseTest />} />
                          <Route path="board" element={<UserPage />} />
                          <Route path="tags" element={<UserPage />} />
                        </Routes>
                      </DetailsLayout>
                    </AdminRoute>
                  } />

                  <Route path="/member/*" element={
                    <AdminRoute>
                      <MemberLayout userDetails={UserDetailsData}>
                        <Routes>
                          <Route index element={<BaseMember userDetails={UserDetailsData} />} />
                          <Route path="pending" element={<BaseMemberPending userDetails={UserDetailsData} />} />
                        </Routes>
                      </MemberLayout>
                    </AdminRoute>
                  } />

                  <Route path="channel/*" element={
                    <AdminRoute>
                      <Teams userDetails={UserDetailsData} />
                    </AdminRoute>
                  } />

                  <Route path="channel/:userId/detail/*" element={
                    <AdminRoute>
                      <TeamMemberLayout userDetails={UserDetailsData}>
                        <Routes>
                          <Route index element={<TeamMemberDetails userDetails={UserDetailsData} />} />
                          <Route path="member" element={<TeamMemberDataDetails userDetails={UserDetailsData} />} />
                          <Route path="pending" element={<TeamMemberPending userDetails={UserDetailsData} />} />
                        </Routes>
                      </TeamMemberLayout>
                    </AdminRoute>
                  } />

                  <Route path="tags" element={
                    <AdminRoute>
                      <Tags />
                    </AdminRoute>
                  }
                  />

                  <Route path="pipeline" element={
                    <AdminRoute>
                      <Pipeline userDetails={UserDetailsData} />
                    </AdminRoute>
                  }
                  />
                  <Route path="pipeline/:userId" element={
                    <AdminRoute>
                      <PipelineStages userDetails={UserDetailsData} />
                    </AdminRoute>
                  } />

                  <Route path="questionnaire" element={
                    <AdminRoute>
                      <Questionnaire userDetails={UserDetailsData} />
                    </AdminRoute>
                  }
                  />
                  <Route path="questionnaire/:userId" element={
                    <AdminRoute>
                      <QuestionList userDetails={UserDetailsData} />
                    </AdminRoute>
                  } />
                </Routes>
              </SettingLayout>
            </PrivateRoute>
          } />

          {/* Other routes remain unchanged */}
          <Route
            path="/createbase"
            element={
              <PrivateRoute>
                <CreateBase userDetails={UserDetailsData} />
              </PrivateRoute>
            }
          />

          <Route
            path="/:baseId/*"
            element={
              <PrivateRoute>
                <DashboardLayout userDetails={UserDetailsData}>
                  <Suspense fallback={<LoadingSpinner />}>
                    <Routes>
                      <Route path="dashboard" element={<DashboardHome />} />
                      <Route path="user1" element={<UserPage />} />
                      {/* document */}
                      <Route path="documents/*" element={<Documents />}>
                        {/* <Route index element={<DocumentList />} /> */}
                        <Route path=":id" element={<DocumentDetailsData />} />
                      </Route>
                      {/* <Route path="d/:id" element={<DocumentDetails />} />
                    <Route path="doc/:id" element={<SendDocument />} /> */}
                    </Routes>
                  </Suspense>
                </DashboardLayout>
              </PrivateRoute>
            }
          />



          <Route
            path="/:baseId/d/:id/*"
            element={
              <PrivateRoute>
                <DocumentDetailsLayout userDetails={UserDetailsData}>
                  <Suspense fallback={<LoadingSpinner />}>
                    <Routes>
                      <Route path="" element={<DocumentDetails />} />
                      <Route path="pending" element={<SendDocument />} />
                    </Routes>
                  </Suspense>
                </DocumentDetailsLayout>
              </PrivateRoute>
            }
          />

          

          <Route path="/user/:baseId/*" element={
            <PrivateRoute>
              <UserLayout userDetails={UserDetailsData}>
                <Routes>
                  <Route path="profile" element={<UserProfile />} />
                  <Route path="security" element={<UserSecurity />} />
                </Routes>
              </UserLayout>
            </PrivateRoute>
          } />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </SearchProvider>

    </Suspense>
  );
};

export default AppRoutes;