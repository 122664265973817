//@ts-nocheck

import React from 'react';
import { Surface } from '../../../components/ui/Surface';

const ColorButton = ({ color, label, bg = '#f2f2f2', onClick, isSelected }) => (
  <button
    onClick={onClick}
    className={`flex items-center gap-2 w-full px-2 py-1.5 hover:bg-gray-100 rounded-sm ${
      isSelected ? 'bg-gray-100' : ''
    }`}
  >
    <div 
      className="w-6 h-6 rounded flex items-center justify-center text-xs font-medium"
      style={{ background: bg }}
    >
      <span style={{ color }}>{label[0].toUpperCase()}</span>
    </div>
    <span className="text-sm">{label}</span>
  </button>
);

const ColorPickerDropdown = ({ 
  currentColor, 
  currentHighlight,
  onColorChange,
  onHighlightChange,
  onClearColor,
  onClearHighlight 
}) => {
  const colors = [
    { label: 'Default', value: '', textColor: '#000000' },
    { label: 'Grey', value: '#787774' },
    { label: 'Brown', value: '#9F6B53' },
    { label: 'Orange', value: '#D9730D' },
    { label: 'Yellow', value: '#CB912F' },
    { label: 'Green', value: '#448361' },
    { label: 'Blue', value: '#337EA9' },
    { label: 'Purple', value: '#9065B0' },
    { label: 'Pink', value: '#C14C8A' },
    { label: 'Red', value: '#D44C47' }
  ];

  const highlights = [
    { label: 'Default', value: '', bg: '#ffffff' },
    { label: 'Grey', value: '#F1F1EF' },
    { label: 'Brown', value: '#F4EEEE' },
    { label: 'Orange', value: '#FBECDD' },
    { label: 'Yellow', value: '#FBF3DB' },
    { label: 'Green', value: '#EDF3EC' },
    { label: 'Blue', value: '#E7F3F8' },
    { label: 'Purple', value: '#F4F0F7' },
    { label: 'Pink', value: '#F9EEF3' },
    { label: 'Red', value: '#FDEBEC' }
  ];

  return (
    <Surface className="w-48 py-1 divide-y divide-gray-200 h-[400px] overflow-auto">
      <div className="pb-1">
        <div className="px-2 py-1 text-xs font-medium text-gray-500">Text Color</div>
        <div className="mt-1">
          {colors.map((color) => (
            <ColorButton
              key={color.label}
              color={color.value || '#000000'}
              label={color.label}
              onClick={() => color.value ? onColorChange(color.value) : onClearColor()}
              isSelected={currentColor === color.value}
            />
          ))}
        </div>
      </div>
      
      <div className="pt-1">
        <div className="px-2 py-1 text-xs font-medium text-gray-500">Background Color</div>
        <div className="mt-1">
          {highlights.map((highlight) => (
            <ColorButton
              key={highlight.label}
              color="#000000"
              bg={highlight.value || '#ffffff'}
              label={highlight.label}
              onClick={() => {
                if (highlight.value) {
                  onHighlightChange(highlight.value);
                } else {
                  onClearHighlight();
                }
              }}
              isSelected={currentHighlight === highlight.value}
            />
          ))}
        </div>
      </div>
    </Surface>
  );
};

export default ColorPickerDropdown;