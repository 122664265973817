// @ts-nocheck
import './EmojiList.css';

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

// Define the types for the props and ref handlers
interface EmojiItem {
  name: string;
  emoji?: string;
  fallbackImage?: string;
}

interface Props {
  items: EmojiItem[];
  command: (item: { name: string }) => void;
}

interface RefHandlers {
  onKeyDown: (event: { event: KeyboardEvent }) => boolean;
}

// ForwardRef component with proper typing
export const EmojiList = forwardRef<RefHandlers, Props>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command({ name: item.name });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => {
    setSelectedIndex(0);
  }, [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: KeyboardEvent }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }), [upHandler, downHandler, enterHandler]);

  return (
    <div className="dropdown-menu emoji">
      {props.items.map((item, index) => (
        <button
          className={index === selectedIndex ? 'is-selected' : ''}
          key={index}
          onClick={() => selectItem(index)}
        >
          {item.fallbackImage ? (
            <img src={item.fallbackImage} align="absmiddle" alt={item.name} />
          ) : (
            item.emoji
          )}
          :{item.name}:
        </button>
      ))}
    </div>
  );
});
