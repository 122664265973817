

// src/components/dashboard/Sidebar.tsx
import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Transition } from '@headlessui/react'
import { Dialog } from '@headlessui/react'
import { Toaster, toast } from 'sonner';
import { useAppDispatch } from '../../../../app/hooks';
import { UserDetails } from '../../../auth/authTypes';
import { BaseBoardIcon, BaseDetailsIcon, BaseMemberIcon, BaseStatusIcon } from '../../../../components/icons/Icons';


function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}


interface SidebarProps {
    userDetails: UserDetails;
}

const Sidebar: React.FC<SidebarProps> = ({ userDetails }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const location = useLocation();
    const [updatedPathname, setUpdatedPathname] = useState<string>("");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const userBaseIdMain = userDetails?.id || userDetails

    useEffect(() => {
        if (userDetails && userBaseIdMain) {
            let newPathname = location.pathname;

            if (newPathname === `/${userBaseIdMain}/dashboard`) {
                newPathname = "dashboard";  // Change this line
            } else if (newPathname.startsWith(`/${userBaseIdMain}/`)) {
                newPathname = newPathname.replace(`/${userBaseIdMain}/`, "");
            }

            setUpdatedPathname(newPathname);
        } else {
            setUpdatedPathname(location.pathname);
        }
    }, [userDetails, location.pathname]);




    const navigation = [
        {
            name: 'Detail',
            to: `/${userBaseIdMain}/settings/details`,
            icon: BaseDetailsIcon,
            current: location.pathname === `/${userBaseIdMain}/settings/detail`
        },
        {
            name: 'Member',
            to: `/${userBaseIdMain}/settings/details/label`,
            icon: BaseMemberIcon,
            current: location.pathname === `/${userBaseIdMain}/settings/label`
        },
        {
            name: 'Board',
            to: `/${userBaseIdMain}/settings/details/board`,
            icon: BaseBoardIcon,
            current: location.pathname === `/${userBaseIdMain}/settings/board`
        },
        {
            name: 'Status',
            to: `/${userBaseIdMain}/settings/details/status`,
            icon: BaseStatusIcon,
            current: location.pathname === `/${userBaseIdMain}/settings/status`
        },
    ];



    return (
        <div>
            {/* mobile sidebar */}
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-[82%] top-[-14px] flex w-16 justify-center pt-5">
                                        <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>

                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex grow flex-col gap-y-4 overflow-y-auto bg-light-pink px-6 pb-4 py-4">
                                    <div className='flex gap-[0.5rem] items-center h-[32px] px-[0.25rem]'>
                                        <button
                                            onClick={() => navigate(`/dashboard/dashboard/${userBaseIdMain}`)}
                                            type='button'
                                            className="bg-light-pink  hover:bg-gray-100 text-black-100
             focus-visible:outline-light-pink [&>span]:w-full  [&>span]:flex px-0  [&>span]:items-center"
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                strokeWidth={1.5}
                                                stroke='currentColor'
                                                className='w-4 h-4 mr-0'>
                                                <path strokeLinecap='round' strokeLinejoin='round' d='M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18' />
                                            </svg>

                                        </button>
                                        <span className='leading-[1.2] font-semibold 
                whitespace-nowrap text-[0.8125rem]'> Settings   </span>
                                    </div>
                                    <nav className="flex flex-1 flex-col">


                                        <ul role="list" className="-mx-2 space-y-1">
                                            {navigation.map((item) => (
                                                <li key={item.name}>
                                                    <a
                                                        href={item.to}
                                                        className={classNames(
                                                            item.current
                                                                ? ' text-black-100 bg-[#00000014] hover:bg-[rgb(0 0 0 / 6%)] cursor-pointer'
                                                                : 'text-black-100 hover:text-black-100 hover:bg-gray-100 cursor-pointer',
                                                            'group flex gap-x-[10px] p-0 text-[13px]  m-0 min-h-[28px] w-full cursor-pointer leading-5 items-center rounded-[0.375rem] font-medium text-wedges-gray-900 focus:outline-none px-2'
                                                        )}
                                                    >
                                                        {/* <item.icon
                                                            aria-hidden="true"
                                                            className={classNames(
                                                                item.current ? 'text-[#0000007a]' : 'text-red-100 group-hover:text-black-100',
                                                                'h-[1.1em] w-[1.1em] shrink-0'
                                                            )}
                                                        /> */}
                                                        {item.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:flex lg:flex-col h-full">
                <div className="flex grow flex-col bg-white pt-0 px-0 pb-4 sidebar-des">

                    <nav className="flex overflow-y-auto h-1/2 flex-col">
                        <ul role="list" className="flex flex-1 flex-col gap-y-2">
                            <li>
                                <ul role="list" className=" space-y-1 flex flex-col gap-y-3">
                                    {navigation.map((item) => {
                                        const isActive = location.pathname === item.to;
                                        return (
                                            <li key={item.name} className='sidebar-nav'>
                                                <Link
                                                    to={item.to}
                                                    className={classNames(
                                                        isActive
                                                            ? 'text-black-100 bg-transparent hover:bg-[rgb(0 0 0 / 6%)] capitalize'  
                                                            : 'text-[#0000007a] hover:text-black-100 hover:bg-gray-10 capitalize',  
                                                        'group flex gap-x-[10px] p-0 text-[0.8125rem] m-0 capitalize w-full cursor-pointer leading-5 items-center rounded-[4px] font-medium focus:outline-none pr-[2px] pl-[6px]'
                                                    )}
                                                >
                                                    <item.icon
                                                        className={classNames(
                                                            isActive ? 'text-black-100' : 'text-[#0000007a] group-hover:text-black-100',  
                                                            'h-[1.1em] w-[1.1em] shrink-0'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </Link>
                                            </li>
                                        );
                                    })}

                                </ul>
                            </li>

                        </ul>
                    </nav>

                </div>
            </div>
        </div>
    );
};

export default Sidebar;