// @ts-nocheck

import React, { useState, Fragment, useEffect, useCallback } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { MenuItem, MenuItems, MenuButton } from '@headlessui/react'
import { Link, useParams } from 'react-router-dom'
import { Toaster, toast } from 'sonner'
import { UserDetails } from '../../auth/authTypes'
import { useAppDispatch } from '../../../app/hooks'
import { fetchPipelineDetailsData } from '../../pipeline/pipelineSlice'
import CreatePipelineStages from './CreatePipelineStages'
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { deleteLocation, fetchPipelineStagesList, updateLocation, updatePipelineOrder } from '../../pipelineStages/pipelineStagesSlice';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Badge from '../../../components/common/Badge'
import { useDispatch } from 'react-redux'
import { CheckCircleColorIcon, CustomCheckIcon, CustomPlusColorIcon, EllipsisHorizontalCircleColorIcon, ExclamationIcon, SearchIcon, XCircleColorIcon } from '../../../components/icons'


const colorList = [
  { name: "bg-blue-500" },
  { name: "bg-gray-500" },
  { name: "bg-blue-200" },
  { name: "bg-pink-500" },
  { name: "bg-indigo-500" },
  { name: "bg-yellow-500" },
  { name: "bg-green-500" },
  { name: "bg-cyan-500" },
  { name: "bg-red-600" },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

type ChannelProps = {
  userDetails: UserDetails | null;
}

const DraggableItem = ({ item, index, handleEdit, handleItemTagsDelete, editInput,
  color, setColor, setLabelName, labelName, handleCancel, handleUpdateSave, loading, isDragActive, handleMakeDefault }: any) => {
  return (

    <div>


      {
        isDragActive && (
          <Draggable draggableId={item.id.toString()} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                  ...provided.draggableProps.style,
                  opacity: snapshot.isDragging ? 0.5 : 1
                }}
                className='p-2 px-4 rounded-[3px] bg-white border border-solid border-[#e3e3e3] mb-1 relative feedback-tags-hover last-of-type:mb-[10px]'
              >
                {editInput === item.id ? (

                  <div className='w-full'>
                    <form>
                      <div className='flex flex-row items-center gap-2 flex-1'>
                        {/* <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'> */}

                        <Menu as='div' className='relative flex items-center '>
                          <Menu.Button className=''>
                            <span className='sr-only'>Open user menu</span>
                            <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'>
                              <div
                                className={`border-0 bg-none text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm w-[9px] h-[9px] rounded-full ${stage_colour} border flex justify-center items-center`}
                              >
                              </div>
                            </div>

                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter='transition ease-out duration-100'
                            enterFrom='transform opacity-0 scale-95'
                            enterTo='transform opacity-100 scale-100'
                            leave='transition ease-in duration-75'
                            leaveFrom='transform opacity-100 scale-100'
                            leaveTo='transform opacity-0 scale-95'>
                            <Menu.Items className='absolute flex flex-wrap left-0 top-8 z-10 mt-2.5 w-44 p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                              {colorList.map((value: any, index) => {
                                return (
                                  <div onClick={() => setColor(value.name)} key={index} className={`flex h-6 w-6 border ${value.name} rounded-full m-2 justify-center items-center`}>
                                    {(color === value.name) ? <CustomCheckIcon /> : null}
                                  </div>
                                )
                              })}
                            </Menu.Items>
                          </Transition>

                        </Menu>
                        <div className='w-full'>
                          <input
                            name='text'
                            type='text'
                            required
                            value={labelName}
                            onChange={(e) => setLabelName(e.target.value)}
                            placeholder='Enter Purpose'
                            className="flex w-full grow border px-4 py-2 pl-8 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
                            focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                            border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                          />
                        </div>
                        <span className='bg-[#e3e3e3] w-[1px] h-[30px]'></span>
                        <div className='flex items-center gap-x-2 flex-1'>
                          <button
                            // onClick={() => navigate(`/${baseId}/create/project`)}
                            type='button'
                            variant="outline"
                            onClick={handleCancel}
                            className=" inline-flex shrink-0  justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm hover:bg-gray-500 
                            focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                          >
                            Cancel
                          </button>
                          <button
                            // onClick={() => navigate(`/${baseId}/create/project`)}
                            onClick={handleUpdateSave}
                            type='button'
                            className={` inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 
                            focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
          [&>span]:flex [&>span]:items-center [&>span]:gap-[8px] ${!labelName && 'opacity-50 cursor-not-allowed'
                              }`}

                            disabled={!labelName} // Disable button when labelName is falsy


                          >
                            Save
                            {loading && (
                              <div className="simple-spinner">
                                <span></span>
                              </div>
                            )}
                          </button>
                        </div>

                      </div>
                    </form>
                  </div>
                ) : (
                  <>
                    <div className='flex flex-row items-center justify-between flex-grow shrink-0'>


                      <div className='flex items-center gap-x-[16px]'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 text-[#0000007a]">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5" />
                        </svg>
                        <div
                          className={` shrink-0 w-[9px] h-[9px] rounded-full bg-[${item.stage_colour}]`}

                        ></div>
                        <span className='flex-shrink whitespace-nowrap overflow-hidden text-ellipsis font-medium text-[#1b1b1b] text-sm leading-5'>{item.stage_name}</span>

                        <div className=' cursor-default select-none flex flex-row'>
                          <button className='p-0 h-auto text-[#5e5e5f] inline-flex items-center justify-center whitespace-nowrap rounded-[5px] font-medium leading-5
          min-w-[24px] text-[0.75rem] bg-transparent' style={{ transitionDuration: '0.15s', transitionProperty: 'border, background-color, color, opacity' }}>
                            {
                              item.isDefault === true && <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                                Default
                              </span>
                            }
                          </button>
                        </div>
                      </div>
                      <div className='mr-[0px] flex  flex-row items-center flex-grow-0'>
                        <div className='mr-2'>
                          {
                            item.bucket === 'inbox' &&
                            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                              <CustomPlusColorIcon />
                              Inbox
                            </span>
                          }
                          {
                            item.bucket === 'screening' &&
                            <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                              <EllipsisHorizontalCircleColorIcon />
                              Screening
                            </span>
                          }
                          {
                            item.bucket === 'hired' &&
                            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                              <CheckCircleColorIcon />
                              Hired
                            </span>
                          }
                          {
                            item.bucket === 'reject' &&
                            <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                              <XCircleColorIcon />
                              Reject
                            </span>
                          }
                        </div>
                        <div className='flex flex-row gap-[8px]'>
                          <button className='feedback-info-btn-hover' onClick={() => handleMakeDefault(item.id)} title="Make Default">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#0000007a"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="icon icon-tabler icons-tabler-outline icon-tabler-info-circle  w-[1rem] h-[1rem] text-[#0000007a]"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                              <path d="M12 9h.01" />
                              <path d="M11 12h1v4h1" />
                            </svg>
                          </button>
                          <button className='feedback-edit-btn-hover'>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() => handleEdit(item.id)}
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#0000007a"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="icon icon-tabler icons-tabler-outline icon-tabler-pencil w-[1rem] h-[1rem] text-[#0000007a]"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" />
                              <path d="M13.5 6.5l4 4" />
                            </svg>

                          </button>
                          <button className='feedback-more-btn-hover'>
                            <Menu as="div" className="relative inline-block text-left">
                              <div>
                                <MenuButton className="rounded-md flex items-center justify-center">
                                  <span className="sr-only">Open options</span>
                                  <svg stroke="currentColor" fill="#a0a0a2" className='text-[#0000007a]' strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle>
                                  </svg>
                                </MenuButton>
                              </div>

                              <Transition
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">

                                    <MenuItem>
                                      {({ focus }) => (
                                        <Link
                                          to="#"
                                          className={classNames(
                                            focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                          )}
                                          onClick={() => handleItemTagsDelete(item.id)}
                                        >
                                          Delete
                                        </Link>
                                      )}
                                    </MenuItem>
                                  </div>
                                </MenuItems>
                              </Transition>
                            </Menu>


                          </button>
                        </div>
                      </div>
                    </div>

                  </>
                )
                }
              </div>
            )}
          </Draggable>
        )
      }
    </div>
  )

}

const PipelineStages: React.FC<ChannelProps> = React.memo(({ }) => {
  const [editInput, setEditInput] = useState<string | null>(null)
  const [labelName, setLabelName] = useState("")
  const [color, setColor] = useState("bg-gray-500")
  const [newGroup, setNewGroup] = useState(false)
  // const [newLabel, setNewLabel] = useState(false)
  const [itemTagBaseId, setItemTagBaseId] = useState<any>([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [isItemId, setIsItemId] = useState<string | null>(null)
  const [dataLoading, setDataLoading] = useState(false);
  const { userId } = useParams<{ userId: string }>();
  const baseIdString = localStorage.getItem('UserBaseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editBoardData, setEditBoardData] = useState<{ id: number; baseId: number } | null>(null);
  const [boardsFetched, setBoardsFetched] = useState<any[]>([]);
  const [dataLoading1, setDataLoading1] = useState(false);
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);


  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  //   useEffect(() => {

  //     setItemTagBaseId(baseId);

  //     const savedItemTagsList = localStorage.getItem('itemStatusList');

  //     if (savedItemTagsList) {
  //       try {
  //         const parsedList = JSON.parse(savedItemTagsList);

  //         if (Array.isArray(parsedList)) {
  //           setItemTagsList(parsedList);
  //         } else {
  //           setItemTagsList([]);
  //         }
  //       } catch (error) {
  //         setItemTagsList([]);
  //       }
  //     } else if (baseId) {
  //       fetchItemStatusList(parseInt(baseId));
  //     } else {
  //       setItemTagsList([]);
  //     }
  //   }, []);




  const handleCancel = () => {
    setEditInput(null)
    setNewGroup(false)
    // setNewLabel(false)
  }


  const openModal = () => {
    setEditBoardData(null);  // Add this line
    setIsModalOpen(true);
  };


  // useEffect(() => {
  //   const fetchPipelineDetails = async () => {
  //     if (!baseId || !userId) return;

  //     setDataLoading(true);
  //     try {
  //       const baseResponse = await dispatch(fetchPipelineDetailsData({ baseId: Number(baseId), userId }));

  //       if (fetchPipelineDetailsData.fulfilled.match(baseResponse)) {
  //         // Handle successful response
  //         setItemTagBaseId(baseResponse.payload.data);
  //       } else {
  //         // Handle error
  //         console.error("Error fetching pipeline details:", baseResponse.payload);
  //         setItemTagBaseId([]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching pipeline details:", error);
  //       setItemTagBaseId([]);
  //     } finally {
  //       setDataLoading(false);
  //     }
  //   };

  //   fetchPipelineDetails();
  // }, [baseId, dispatch, userId]);


  useEffect(() => {
    const fetchPipelineDetails = async () => {
      if (!baseId || !userId) return;

      // Retrieve basePipelineData from local storage
      const localDataString = localStorage.getItem('basePiplineData');
      const localData = localDataString ? JSON.parse(localDataString) : null;

      if (localData && localData.data) {
        // Check if the pipeline ID matches the userId
        const pipeline = localData.data.find(p => p.id === userId); // Find pipeline by userId
        if (pipeline) {
          setItemTagBaseId(pipeline);
          return;
        } else {
          console.error("Pipeline not found in local storage");
        }
      }

      // If data is not found in local storage, make the API call
      setDataLoading(true);
      try {
        const baseResponse = await dispatch(fetchPipelineDetailsData({ baseId: Number(baseId), userId }));

        if (fetchPipelineDetailsData.fulfilled.match(baseResponse)) {
          // Handle successful response
          const fetchedData = baseResponse.payload.data;

          // Set the state with the fetched data
          setItemTagBaseId(fetchedData);

          // Optionally save fetched data to local storage
          localStorage.setItem('basePiplineData', JSON.stringify(baseResponse.payload)); // Save the entire response
        } else {
          // Handle error
          console.error("Error fetching pipeline details:", baseResponse.payload);
          setItemTagBaseId([]);
        }
      } catch (error) {
        console.error("Error fetching pipeline details:", error);
        setItemTagBaseId([]);
      } finally {
        setDataLoading(false);
      }
    };

    fetchPipelineDetails();
  }, [baseId, dispatch, userId]); // Ensure dependencies include baseId, dispatch, userId



  useEffect(() => {
    const fetchTeams = async () => {
      if (!baseId || !userId) return;

      setDataLoading1(true);
      try {
        const baseResponse = await dispatch(fetchPipelineStagesList({ baseId: Number(baseId), userId }));

        if (fetchPipelineStagesList.fulfilled.match(baseResponse)) {
          // Handle successful response
          setBoardsFetched(baseResponse.payload.data);
          localStorage.setItem('basePiplineStagesData', JSON.stringify(baseResponse.payload));
        } else {
          // Handle error
          console.error("Error fetching pipeline details:", baseResponse.payload);
          setBoardsFetched([]);
        }
      } catch (error) {
        console.error("Error fetching pipeline details:", error);
        setBoardsFetched([]);
      } finally {
        setDataLoading1(false);
      }
    };

    fetchTeams();
  }, [baseId, dispatch, userId]);


  // const onDragEnd = async (result: { destination: any; source: any }) => {
  //   const { destination, source } = result;

  //   if (!destination || destination.index === source.index) {
  //     return;
  //   }

  //   const newItems = Array.from(boardsFetched);
  //   const [movedItem] = newItems.splice(source.index, 1);
  //   newItems.splice(destination.index, 0, movedItem);

  //   setBoardsFetched(newItems);

  //   const updates = newItems.map((item, index) => ({
  //     // id: parseInt(item.id), 
  //     order: index + 1,
  //   }));

  //   if (baseId) {
  //     try {
  //       const resultAction = await dispatch(
  //         updatePipelineOrder({
  //           baseId: parseInt(baseId),
  //           updates: updates
  //         })
  //       );

  //       if (updatePipelineOrder.fulfilled.match(resultAction)) {
  //         toast.success('Order updated successfully');
  //         // Optionally refetch the updated list
  //         // await dispatch(fetchPipelineStagesList({ baseId: parseInt(baseId), userId }));
  //       } else if (updatePipelineOrder.rejected.match(resultAction)) {
  //         const error = resultAction.payload;
  //         toast.error(error || 'Failed to update order');
  //       }
  //     } catch (error) {
  //       console.error('Failed to update order:', error);
  //       toast.error('Failed to update order');
  //     }
  //   }
  // };

  const onDragEnd = (result: { destination: any; source: any }) => {
    const { destination, source } = result;

    if (!destination || destination.index === source.index) {
      return;
    }

    const newItems = Array.from(boardsFetched);
    const [movedItem] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, movedItem);

    setBoardsFetched(newItems);
  };

  const updateOrder = async () => {
    if (baseId) {
      setIsSaving(true);
      const updates = boardsFetched.map((item, index) => ({
        id: item.id,
        order: index + 1,
      }));



      if (baseId) {
        try {
          const resultAction = await dispatch(
            updatePipelineOrder({
              baseId: parseInt(baseId),
              updates: updates
            })
          );

          if (updatePipelineOrder.fulfilled.match(resultAction)) {
            toast.success('Order updated successfully');
            setIsDragActive(false);
          } else if (updatePipelineOrder.rejected.match(resultAction)) {
            const error = resultAction.payload;
            toast.error(error || 'Failed to update order');
          }
        } catch (error) {
          console.error('Failed to update order:', error);
          toast.error('Failed to update order');
        } finally {
          setIsSaving(false);
        }
      }
    }
  };

  const toggleDragAndDrop = () => {
    if (isDragActive) {
      // If currently active, we're saving
      updateOrder();
    } else {
      // If currently inactive, we're entering re-order mode
      setIsDragActive(true);
    }
  };

  const handleItemTagsDelete = async (itemId: number) => {
    if (!baseId) {
      toast.error('Base ID is not available');
      return;
    }

    try {
      const resultAction = await dispatch(deleteLocation({ baseId: parseInt(baseId), id: itemId }));

      if (deleteLocation.fulfilled.match(resultAction)) {
        toast.success('Item deleted successfully');
        // Refresh the list after deletion
        const res = await dispatch(fetchPipelineStagesList({ baseId: parseInt(baseId), userId }));

        if (fetchPipelineStagesList.fulfilled.match(res)) {
          // console.log(res, '::::');
          if (res.payload) {
            localStorage.setItem('basePiplineStagesData', JSON.stringify(res.payload));
            window.dispatchEvent(
              new StorageEvent('storage', {
                key: 'basePiplineStagesData',
                newValue: JSON.stringify(res.payload),
              })
            );
          }
        } else if (fetchPipelineStagesList.rejected.match(res)) {
          toast.error('Failed to refresh the pipeline stages list');
        }
      } else if (deleteLocation.rejected.match(resultAction)) {
        const error = resultAction.payload;
        toast.error(error || 'Failed to delete item');
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      toast.error('An error occurred while deleting the item');
    }
  };


  const handleUpdateSave = async () => {
    // setLoading(true);

    // try {
    //   if (!itemTagBaseId || !editInput) {
    //     console.error('Base ID or Item ID not available.');
    //     return;
    //   }

    //   const response = await _updateItemStatusById(parseInt(itemTagBaseId), parseInt(editInput), undefined, labelName, color,);
    //   if (response) {
    //     toast.success('Update tags successfully');
    //     fetchItemStatusList(parseInt(itemTagBaseId));
    //     setEditInput(null);
    //   }
    // } catch (error) {
    //   console.error('Error updating item tag:', error);
    //   toast.error('Failed to update item tag.');
    // }
    // finally {
    //   setLoading(false);
    // }
  }

  const handleMakeDefault = async (itemId: string) => {
    // console.log(itemId, "Item ID");
    setIsItemId(itemId);

    try {
      if (!baseId || !itemId) {
        console.error('Base ID or Item ID not available.');
        return;
      }

      const payload = { isDefault: true, pipeline_id: userId };
      const response = await dispatch(updateLocation({ payload, baseId: Number(baseId), id: itemId })).unwrap();

      if (response.status === 200) {
        const baseResponse = await dispatch(fetchPipelineStagesList({ baseId: Number(baseId), userId }));

        if (baseResponse?.payload) {
          localStorage.setItem('basePiplineStagesData', JSON.stringify(baseResponse.payload));
          window.dispatchEvent(new StorageEvent('storage', {
            key: 'basePiplineStagesData',
            newValue: JSON.stringify(baseResponse.payload)
          }));
        }
      }


    } catch (error) {
      console.error('Error updating pipeline stage:', error);
      toast.error('Failed to update pipeline stage.');
    }
  };

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'basePiplineStagesData' && e.newValue) {
        const teamsDataArray = JSON.parse(e.newValue);
        setBoardsFetched(teamsDataArray.data || []);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);


  const handleEdit = useCallback((id: number, baseId: number) => {
    const teamToEdit = boardsFetched.find(team => team.id === id);
    if (teamToEdit) {
      setEditBoardData(teamToEdit);
      setIsModalOpen(true);
    }
  }, [boardsFetched]);



  return (
    <>
      <Toaster richColors />
      <main className='py-[34px] lg:py-[70px] px-0 lg:px-10 h-full flex'>
        <div className='px-[2px] sm:px-6 lg:px-8 w-full flex justify-center'>
          <div className='w-full max-w-[640px]'>
            <Link to={`/${baseId}/settings/pipeline`}
              className=' flex items-center gap-x-1 w-full mb-4'
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-left w-[0.9rem] h-[0.9rem]"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M15 6l-6 6l6 6" />
              </svg>
              <span className='text-black-100 text-[13px]'>
                Back to pipeline
              </span>
              {/* <ArrowLeftIcon /> */}
            </Link>
            <div className='flex flex-col gap-y-[6px]'>
              <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>{itemTagBaseId.pipeline_name}</h2>
              <p className='text-sm leading-6 text-[#5c5c5e] font-medium capitalize'>{itemTagBaseId.pipeline_description}</p>
            </div>
            <div className='bg-[#e0e0e0] w-full h-[1px] my-6'></div>
            <div className=' mt-6 lg:mt-10 flex items-center  w-full gap-x-3 mb-5'>
              <div className='w-full lg:w-[40%]'>
                <div className="relative items-center w-full">
                  <input
                    type="text"
                    //   value={searchVal}
                    //   onChange={(e) => setSearchVal((e.target as HTMLInputElement).value)}
                    value={searchQuery}
                    onChange={handleSearchChange}
                    name="search"
                    id="search"
                    placeholder='Search name and email'
                    className="flex w-full grow border px-4 py-2 pl-8 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
                  focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                  border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                  />
                  <div className="absolute inset-y-0 left-0 flex py-1.5 pr-1.5">

                    <SearchIcon />
                  </div>
                </div>
              </div>
              <div className='w-full lg:w-[60%]'>
                <div className='flex justify-end gap-x-2'>
                  <div className=''>
                    <button
                      onClick={toggleDragAndDrop}
                      className="inline-flex shrink-0 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      disabled={isSaving} // Disable button while saving to prevent multiple clicks
                    >
                      {isDragActive ? (isSaving ? 'Save' : 'Save') : 'Re-order'}
                    </button>
                  </div>
                  <div className=''>
                    <button
                      type='button'
                      onClick={openModal}
                      className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 
                      focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"

                    >
                      Create status
                    </button>
                  </div>
                </div>
              </div>

            </div>

            {dataLoading1 ? (
              <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 191px)' }}>
                <div className="simple-spinner-loader">
                  <span></span>
                </div>
              </div>
            ) : (
              <div>

                {/* <div className='mt-6 lg:mt-10 mb-8'>
                  <p className=' text-[#5c5c5e] text-[13px] leading-[18px]'>
                    Use labels and label groups to help organize and filter issues in your workspace.
                    Labels created in this section are available for all teams to use. To create labels or label
                    groups that only apply to certain teams, add them in the team-specific label settings.
                  </p>
                </div> */}


                <div className='flex flex-col gap-y-2'>
                  {newGroup && (
                    <div className='bg-[#edeef2] p-2 border border-solid border-[#e0e0e0] rounded mb-1'>

                      <div className='w-full'>
                        <form>
                          <div className='flex flex-row items-center gap-2 flex-1'>
                            {/* <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'> */}

                            <Menu as='div' className='relative flex items-center '>
                              <Menu.Button className=''>
                                <span className='sr-only'>Open user menu</span>
                                <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'>
                                  <div
                                    className={`border-0 bg-none text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm w-[9px] h-[9px] rounded-full ${color} border flex justify-center items-center`}
                                  >
                                  </div>
                                </div>

                              </Menu.Button>
                              <Transition
                                as={Fragment}
                                enter='transition ease-out duration-100'
                                enterFrom='transform opacity-0 scale-95'
                                enterTo='transform opacity-100 scale-100'
                                leave='transition ease-in duration-75'
                                leaveFrom='transform opacity-100 scale-100'
                                leaveTo='transform opacity-0 scale-95'>
                                <Menu.Items className='absolute flex flex-wrap left-0 top-8 z-10 mt-2.5 w-44 p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                                  {colorList.map((value: any, index) => {
                                    return (
                                      <div onClick={() => setColor(value.name)} key={index} className={`flex h-6 w-6 border ${value.name} rounded-full m-2 justify-center items-center`}>
                                        {(color === value.name) ? <CustomCheckIcon /> : null}
                                      </div>
                                    )
                                  })}
                                </Menu.Items>
                              </Transition>

                            </Menu>
                            <div className='w-full'>
                              <input
                                name='text'
                                type='text'
                                required
                                value={labelName}
                                onChange={(e) => setLabelName(e.target.value)}
                                placeholder='Enter Purpose'
                                // req
                                className="flex w-full grow border px-4 py-2 pl-8 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
                  focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                  border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                              />
                            </div>
                            <span className='bg-[#e3e3e3] w-[1px] h-[30px]'></span>
                            <div className='flex items-center gap-x-2 flex-1'>
                              <button
                                className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 
                                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                type='button'

                              // onClick={handleCancel}

                              >
                                Cancel
                              </button>
                              <button
                                // onClick={handleSave}
                                type='button'
                                className={`inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 
                                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
                           [&>span]:flex [&>span]:items-center [&>span]:gap-[8px] ${!labelName && 'opacity-50 cursor-not-allowed'
                                  }`}

                                disabled={!labelName}


                              >
                                Save
                                {loading && (
                                  <div className="simple-spinner">
                                    <span></span>
                                  </div>
                                )}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list">
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {boardsFetched?.filter(item => item.status.toLowerCase().includes(searchQuery.toLowerCase())).length > 0 ? (
                            boardsFetched
                              ?.filter(item => item.status.toLowerCase().includes(searchQuery.toLowerCase()))
                              .map((item: any, index: number) => (
                                <DraggableItem
                                  key={item.id}
                                  item={item}
                                  index={index}
                                  handleEdit={handleEdit}
                                  handleItemTagsDelete={handleItemTagsDelete}
                                  editInput={editInput}
                                  setEditInput={setEditInput}
                                  setLabelName={setLabelName}
                                  setColor={setColor}
                                  setNewGroup={setNewGroup}
                                  loading={loading}
                                  color={color}
                                  labelName={labelName}
                                  handleCancel={handleCancel}
                                  handleUpdateSave={handleUpdateSave}
                                  isDragActive={isDragActive}
                                  handleMakeDefault={handleMakeDefault}
                                />
                              ))
                          ) : (
                            // Render this if there are no pipeline stages available
                            <div className='w-full mt-5 flex h-[300px] justify-center items-center flex-col gap-y-3'>
                              <ExclamationIcon />
                              <p className='text-[#0000007a] text-[13px]'>No results found</p>
                            </div>
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  {
                    !isDragActive &&
                    (boardsFetched?.filter(item => item.stage_name.toLowerCase().includes(searchQuery.toLowerCase()))
                      .map((item: any) => (
                        <div className='p-2 rounded-[3px] bg-white border border-solid border-[#e3e3e3] mb-1 relative pl-4 feedback-tags-hover last-of-type:mb-[70px]'>
                          {editInput === item.id ? (
                            <div className='w-full'>
                              <form>
                                <div className='flex flex-row items-center gap-2 flex-1'>

                                  <Menu as='div' className='relative flex items-center '>
                                    <Menu.Button className=''>
                                      <span className='sr-only'>Open user menu</span>
                                      <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'>
                                        <div
                                          className={`border-0 bg-none text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm w-[9px] h-[9px] rounded-full ${color} border flex justify-center items-center`}
                                        >
                                        </div>
                                      </div>

                                    </Menu.Button>
                                    <Transition
                                      as={Fragment}
                                      enter='transition ease-out duration-100'
                                      enterFrom='transform opacity-0 scale-95'
                                      enterTo='transform opacity-100 scale-100'
                                      leave='transition ease-in duration-75'
                                      leaveFrom='transform opacity-100 scale-100'
                                      leaveTo='transform opacity-0 scale-95'>
                                      <Menu.Items className='absolute flex flex-wrap left-0 top-8 z-10 mt-2.5 w-44 p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                                        {colorList.map((value: any, index) => {
                                          return (
                                            <div onClick={() => setColor(value.name)} key={index} className={`flex h-6 w-6 border ${value.name} rounded-full m-2 justify-center items-center`}>
                                              {(color === value.name) ? <CustomCheckIcon /> : null}
                                            </div>
                                          )
                                        })}
                                      </Menu.Items>
                                    </Transition>

                                  </Menu>
                                  <div className='w-full'>
                                    <input
                                      name='text'
                                      type='text'
                                      required
                                      value={labelName}
                                      onChange={(e) => setLabelName(e.target.value)}
                                      placeholder='Enter Purpose'
                                      className="flex w-full grow border px-4 py-2 pl-8 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
                                            focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                                            border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                                    />
                                  </div>
                                  <span className='bg-[#e3e3e3] w-[1px] h-[30px]'></span>
                                  <div className='flex items-center gap-x-2 flex-1'>
                                    <button
                                      // onClick={() => navigate(`/${baseId}/create/project`)}
                                      type='button'
                                      variant="outline"
                                      onClick={handleCancel}
                                      className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 
                                            focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      // onClick={() => navigate(`/${baseId}/create/project`)}
                                      onClick={handleUpdateSave}
                                      type='button'
                                      className={`bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 relative
                                         [&>span]:flex [&>span]:items-center [&>span]:gap-[8px] ${!labelName && 'opacity-50 cursor-not-allowed'
                                        }`}
                                      disabled={!labelName} // Disable button when labelName is falsy
                                      className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 
                                            focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                      Save
                                      {loading && (
                                        <div className="simple-spinner">
                                          <span></span>
                                        </div>
                                      )}
                                    </button>
                                  </div>

                                </div>
                              </form>
                            </div>
                          ) : (
                            <>


                              <div className='flex flex-row items-center justify-between flex-grow shrink-0'>

                                <div className='flex items-center gap-x-[16px]'>
                                  <div
                                    className={` shrink-0 w-[9px] h-[9px] rounded-full bg-[${item.stage_colour}]`}

                                  ></div>
                                  <span className='flex-shrink whitespace-nowrap overflow-hidden text-ellipsis font-medium text-[#1b1b1b] text-sm leading-5 capitalize'>{item.stage_name}</span>

                                  <div className=' cursor-default select-none flex flex-row'>
                                    <button className='p-0 h-auto text-[#5e5e5f] inline-flex items-center justify-center whitespace-nowrap rounded-[5px] font-medium leading-5
                                          min-w-[24px] text-[0.75rem] bg-transparent' style={{ transitionDuration: '0.15s', transitionProperty: 'border, background-color, color, opacity' }}>
                                      {
                                        item.isDefault === true && <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                                          Default
                                        </span>
                                      }
                                    </button>
                                  </div>
                                </div>
                                <div className='mr-[0px] flex  flex-row items-center flex-grow-0'>
                                  <div className='mr-2'>
                                    {
                                      item.bucket === 'inbox' &&
                                      <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                        <CustomPlusColorIcon />
                                        Inbox
                                      </span>
                                    }
                                    {
                                      item.bucket === 'screening' &&
                                      <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                        <EllipsisHorizontalCircleColorIcon />
                                        Screening
                                      </span>
                                    }
                                    {
                                      item.bucket === 'hired' &&
                                      <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        <CheckCircleColorIcon />
                                        Hired
                                      </span>
                                    }
                                    {
                                      item.bucket === 'reject' &&
                                      <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                                        <XCircleColorIcon />
                                        Reject
                                      </span>
                                    }
                                  </div>

                                  <div className='flex flex-row gap-[8px] items-center'>
                                    <div data-title="Make Default" className='table-tooltip h-full flex items-center'>
                                      <button className='feedback-info-btn-hover' onClick={() => {
                                        handleMakeDefault(item.id);
                                      }}

                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#0000007a"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-info-circle w-[1rem] h-[1rem] text-[#0000007a]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                          <path d="M12 9h.01" />
                                          <path d="M11 12h1v4h1" />
                                        </svg>





                                      </button>
                                    </div>
                                    <div data-title="Edit" className='table-tooltip h-full flex items-center'>
                                      <button className='feedback-edit-btn-hover'>
                                        <svg
                                          onClick={() => handleEdit(item.id, item.base_id)}
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#0000007a"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-pencil w-[1rem] h-[1rem] text-[#0000007a]"
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" />
                                          <path d="M13.5 6.5l4 4" />
                                        </svg>

                                      </button>
                                    </div>
                                    <button className='feedback-more-btn-hover'>
                                      <Menu as="div" className="relative inline-block text-left">
                                        <div>
                                          <MenuButton className="rounded-md flex items-center justify-center">
                                            <span className="sr-only">Open options</span>
                                            <svg stroke="currentColor" fill="#a0a0a2" className='text-[#0000007a]' strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                              <circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle>
                                            </svg>
                                          </MenuButton>
                                        </div>

                                        <Transition
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">

                                              <MenuItem>
                                                {({ focus }) => (
                                                  <Link
                                                    to="#"
                                                    className={classNames(
                                                      focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                      'block px-4 py-2 text-sm'
                                                    )}
                                                    onClick={() => handleItemTagsDelete(item.id)}
                                                  >
                                                    Delete
                                                  </Link>
                                                )}
                                              </MenuItem>
                                            </div>
                                          </MenuItems>
                                        </Transition>
                                      </Menu>


                                    </button>
                                  </div>
                                </div>
                              </div>

                            </>
                          )
                          }
                        </div>
                      ))

                    )

                  }
                </div>

              </div>
            )}
          </div>



        </div>
      </main>

      <CreatePipelineStages
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        editBoardData={editBoardData}
        baseId={baseId}
        setEditBoardData={setEditBoardData}
        userId={userId}
      />


    </>
  )
});

export default PipelineStages





