// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'sonner';
import { RootState } from '../../../../app/store'
import { baseInvitationStatusData, baseMemberRemove } from '../../../base/baseSlice'
import { useAppDispatch } from '../../../../app/hooks';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { baseTeamMemberInvitationStatusData, baseTeamMemberRemove } from '../../../teamsMembers/teamsMemberSlice';
import { UserDetails } from '../../../auth/authTypes';
import { MemberData } from '../../../base/baseTypes';
import { useParams } from 'react-router-dom';
import { CrossIcon, DeleteIcon, ExclamationIcon } from '../../../../components/icons/Icons';


interface MemberProps {
  userDetails: UserDetails | null;
}


const TeamMemberPending: React.FC<MemberProps> = React.memo(({ userDetails }) => {
  const [memberData, setMemberData] = useState<MemberData[]>([]);
  const dispatch = useAppDispatch();
  const { error } = useSelector((state: RootState) => state.base);
  const baseIdString = localStorage.getItem('UserBaseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [statusPending, setStatusPending] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [removeMember, setRemoveMember] = useState('')
  const [dataLoading, setDataLoading] = useState(false);
  const { userId } = useParams<{ userId: any }>();

  const userIdNumber = parseInt(userId, 10); // Convert to number if necessary
console.log(userId,"userr");

console.log(baseId,"baseid");

  useEffect(() => {
    if (baseId && userId) {
      const fetchData = async () => {
        setDataLoading(true);
        try {
          const baseResponse = await dispatch(baseTeamMemberInvitationStatusData({ baseId, teamId: userId }));
          setMemberData(baseResponse.payload?.data?.invitations);
        } catch (error) {
          console.error("Error fetching base member details:", error);
        } finally {
          setDataLoading(false);
        }
      };
      fetchData();
    } else {
      console.error('Base ID or User ID is undefined'); // Log if either is undefined
    }
  }, [baseId, dispatch, userId]);



  if (error) {
    return <div>Error: {error}</div>;
  }


  const handleAllStatusDelete = (id: any) => {
    setStatusPending(true)
    setRemoveMember(id)
  }

  const handleAllUserSessionDelete = async () => {
    setIsLoading(true);
    try {
      await dispatch(baseTeamMemberRemove({ baseId, invitationId: removeMember })).unwrap();
      toast.success("Invitation deleted successfully");
      setStatusPending(false);
      setRemoveMember('');

      // Fetch updated data and update state
      const res = await dispatch(baseTeamMemberInvitationStatusData({ baseId, teamId: userIdNumber })).unwrap();

      if (res?.data?.invitations) {
        setMemberData(res.data.invitations);
      }
    } catch (err: any) {

      const errorMessage = err.response?.data?.message || "Failed to delete invitation";
      toast.error(errorMessage);
      console.error("Error deleting invitation", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className='h-full flex'>
      <div className=' w-full flex justify-center'>
        <div className='w-full'>
          <div className='flex flex-col gap-y-[6px]'>
            <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>Pending Information</h2>
            <p className='text-sm leading-6 text-[#5c5c5e] font-medium'>
              Use a permanent address where you can receive mail.
            </p>
          </div>

          {dataLoading ? (
            <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 191px)' }}>
              <div className="simple-spinner-loader">
                <span></span>
              </div>
            </div>
          ) : memberData.length > 0 ? (
            <ul
              role="list"
              className="mt-8 divide-y divide-gray-100  bg-white rounded-md border-solid border-[1px]  border-[#d1d1db] shadow-none
            hover:ring-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100 focus:outline-indigo-600"
            >
              {memberData.map((items: any, index) => {

                return (
                  <li key={index} className="flex justify-between items-center gap-x-6 p-2 flex-wrap lg:flex-nowrap">
                    <div className="flex min-w-0 gap-x-3 items-center">

                      <div className="min-w-0 flex-auto">

                        <p className=" flex text-xs leading-5 text-gray-500">
                          {items?.email}
                        </p>
                      </div>
                    </div>
                    <div className='flex items-center justify-end'>
                      <div className='sm:col-span-3 flex items-center gap-x-4'>
                        <span className="inline-flex capitalize items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                          {items?.role}
                        </span>
                        <button className=' cursor-pointer' onClick={() => handleAllStatusDelete(items?.id)}>
                         <DeleteIcon />
                        </button>
                      </div>
                    </div>
                  </li>

                )
              })}
            </ul>
          ) : (
            <div className='w-full mt-5 flex h-[300px] justify-center items-center flex-col gap-y-3'>
              <ExclamationIcon />
              <p className='text-[#0000007a] text-[13px]'>No results found</p>
            </div>
          )}



        </div>


      </div>
      {/* <AlertPopup data={data} show={showAlert} setShow={() => setShowAlert(false)} /> */}
      {/* <SliderOvers setData={(e: any) => setData(e)} memberData={memberData} memberId={memberId} baseId={baseId} show={showSlider} setShow={() => setShowSlider(false)} setShowAlert={() => setShowAlert(true)} /> */}


      <Dialog open={statusPending} onClose={setStatusPending} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  onClick={() => setStatusPending(false)}
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">Close</span>
                  <CrossIcon />
                </button>
              </div>
              <div className="sm:flex sm:items-start">

                <div className="mt-3 text-center sm:ml-0 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    Pending Team Members
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleAllUserSessionDelete}

                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  {isLoading ? (
                    <div className='flex items-center  gap-[6px]'>
                      Logout
                      <div className="simple-spinner">
                        <span></span>
                      </div>
                    </div>
                  ) : (
                    'Logout'
                  )}
                </button>
                <button
                  type="button"
                  onClick={() => setStatusPending(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>


    </main>

  )
});
export default TeamMemberPending
