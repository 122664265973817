// src/services/baseService.ts
import { TeamsResponse, baseTeamMemberInvitePayload, createTeamsPayload, updateTeamsPayload } from '../features/teams/teamsTypes';
import { del, get, post, put } from '../utils/api';
import { formatUrl } from '../utils/urlFormatter';


const fetch_boards = '/channel/{0}/Channels';
const create_teams = '/channel/{0}/addChannel';
const delete_teams = '/channel/{0}/{1}/deleteChannel';
const update_teams = '/channel/{0}/{1}/updateChannel';
const fetch_teams_by_id = '/channel/{0}/{1}/channelDetail';
const base_member_invite_url = '/channel/{0}/invite';


const credentialsConfig = {
  withCredentials: true,
}


export const fetchBoardsData = async (baseId: number): Promise<TeamsResponse> => {
  const fetch_boards_data = formatUrl(fetch_boards, [baseId]);
  return get<TeamsResponse>(fetch_boards_data, credentialsConfig);
};

export const createTeamsData = async (data: createTeamsPayload, userId: number): Promise<TeamsResponse> => {
  const base_member_invite = formatUrl(create_teams, [userId]);
  return post<TeamsResponse>(base_member_invite, data, credentialsConfig);
};

export const deleteTeamsData = async (id: number, baseId: number): Promise<TeamsResponse> => {
  const delete_teams_data = formatUrl(delete_teams, [baseId, id]);
  return del<TeamsResponse>(delete_teams_data, credentialsConfig);
};


export const updateTeamsData = async (id: number, baseId: number, data: updateTeamsPayload): Promise<TeamsResponse> => {
  const update_teams_data = formatUrl(update_teams, [baseId, id]);
  return put<TeamsResponse>(update_teams_data, data, credentialsConfig);
};

export const fetchTeamsByIdData = async (id: number, baseId: number): Promise<TeamsResponse> => {
  const fetch_teams_by_Id_data = formatUrl(fetch_teams_by_id, [id, baseId]);
  return get<TeamsResponse>(fetch_teams_by_Id_data, credentialsConfig);
};

export const baseTeamMemberInvite = async (data: baseTeamMemberInvitePayload, userId: number): Promise<TeamsResponse> => {
  const base_member_invite = formatUrl(base_member_invite_url, [userId]);
  return post<TeamsResponse>(base_member_invite, data, credentialsConfig);
};