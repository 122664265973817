import React, { useState } from 'react';
import { Menu } from '@headlessui/react';

interface MenuOption {
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  label: string;
  onClick: () => void;
}

interface MenuSectionProps {
  options: MenuOption[];
}

interface ProfileDropdownProps {
  imageUrl?: string;
  altText: string;
  sections: MenuSectionProps[];
  icon?: React.ReactNode;
  menuItemsClassName?: string;

}

const MenuSection: React.FC<MenuSectionProps> = ({ options }) => (
  <div className="py-[6px]">
    {options.map((option, index) => (
      <Menu.Item key={index}>
        {({ active }) => (
          <a
            href="#"
            className={`group gap-x-3 flex items-center px-4 py-[6px] text-sm ${active ? 'bg-gray-100 text-text-100' : 'text-text-100'
              }`}
            onClick={option.onClick}
          >
            {option.icon && (
              <option.icon
                aria-hidden="true"
                className="mr-0 h-4 w-4 text-text-100 group-hover:text-text-100 opacity-40"
              />
            )}
            {option.label}
          </a>
        )}
      </Menu.Item>
    ))}
  </div>
);

const CustomMenuDropdown: React.FC<ProfileDropdownProps> = ({ icon, imageUrl, altText, sections, menuItemsClassName }) => {
  
  return (
    <Menu as="button" className=" group relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="flex items-center rounded-full bg-transparent outline-none focus:outline-none hover:outline-none  text-sm">
              <span className="sr-only">Open user menu</span>
              {imageUrl ? (
                <img className="h-6 w-6 rounded-full" src={imageUrl} alt={altText} />
              ) : (
                icon && <span className="h-6 w-6 rounded-full flex items-center justify-center">{icon}</span>
              )}
            </Menu.Button>
          </div>
          <Menu.Items
            // className="absolute capitalize right-0 z-10 mt-2 w-56 left-0 origin-top-right divide-y divide-[#ebebef] rounded-md bg-white shadow-lg ring-1
            //  ring-black ring-opacity-5 focus:outline-none custom-dropdown-header"

            className={`absolute capitalize right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-[#ebebef] rounded-md bg-white shadow-lg ring-1
            ring-black ring-opacity-5 focus:outline-none custom-dropdown-header ${menuItemsClassName || 'left-0'
              }`} 
          >
            {sections.map((section, index) => (
              <MenuSection key={index} options={section.options} />
            ))}
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};

export default CustomMenuDropdown;