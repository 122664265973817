

// import React, { useState, Fragment, useEffect } from 'react'
// import { Menu, Transition } from '@headlessui/react'
// import { MenuItem, MenuItems, MenuButton } from '@headlessui/react'
// import { Link } from 'react-router-dom'
// import { Toaster, toast } from 'sonner'
// import { UserDetails } from '../../auth/authTypes'
// // import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'


// const colorList = [
//   { name: "bg-blue-500" },
//   { name: "bg-gray-500" },
//   { name: "bg-blue-200" },
//   { name: "bg-pink-500" },
//   { name: "bg-indigo-500" },
//   { name: "bg-yellow-500" },
//   { name: "bg-green-500" },
//   { name: "bg-cyan-500" },
//   { name: "bg-red-600" },
// ]

// function classNames(...classes: any) {
//   return classes.filter(Boolean).join(' ')
// }

// type ChannelProps = {
//   userDetails: UserDetails | null;
// }

// const DraggableItem = ({ item, index, handleEdit, handleItemTagsDelete, editInput,
//   color, setColor, setLabelName, labelName, handleCancel, handleUpdateSave, loading, isDragActive, handleMakeDefault }: any) => {
//   return (

//     <div>


//       {/* {
//         isDragActive && (
//           <Draggable draggableId={item.id.toString()} index={index}>
//             {(provided, snapshot) => (
//               <div
//                 ref={provided.innerRef}
//                 {...provided.draggableProps}
//                 {...provided.dragHandleProps}
//                 style={{
//                   ...provided.draggableProps.style,
//                   opacity: snapshot.isDragging ? 0.5 : 1
//                 }}
//                 className='p-2 rounded-[3px] bg-white border border-solid border-[#e3e3e3] mb-1 relative pl-7 feedback-tags-hover last-of-type:mb-[10px]'
//               >
//                 {editInput === item.id ? (

//                   <div className='w-full'>
//                     <form>
//                       <div className='flex flex-row items-center gap-2 flex-1'>

//                         <Menu as='div' className='relative flex items-center '>
//                           <Menu.Button className=''>
//                             <span className='sr-only'>Open user menu</span>
//                             <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'>
//                               <div
//                                 className={`border-0 bg-none text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm w-[9px] h-[9px] rounded-full ${color} border flex justify-center items-center`}
//                               >
//                               </div>
//                             </div>

//                           </Menu.Button>
//                           <Transition
//                             as={Fragment}
//                             enter='transition ease-out duration-100'
//                             enterFrom='transform opacity-0 scale-95'
//                             enterTo='transform opacity-100 scale-100'
//                             leave='transition ease-in duration-75'
//                             leaveFrom='transform opacity-100 scale-100'
//                             leaveTo='transform opacity-0 scale-95'>
//                             <Menu.Items className='absolute flex flex-wrap left-0 top-8 z-10 mt-2.5 w-44 p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
//                               {colorList.map((value: any, index) => {
//                                 return (
//                                   <div onClick={() => setColor(value.name)} key={index} className={`flex h-6 w-6 border ${value.name} rounded-full m-2 justify-center items-center`}>
//                                     {(color === value.name) ? <CheckIcon className=' h-3 w-3 text-white' aria-hidden='true' /> : null}
//                                   </div>
//                                 )
//                               })}
//                             </Menu.Items>
//                           </Transition>

//                         </Menu>
//                         <div className='w-full'>
//                           <input
//                             name='text'
//                             type='text'
//                             required
//                             value={labelName}
//                             onChange={(e) => setLabelName((e.target as HTMLInputElement).value)}
//                             placeholder='Enter Purpose'
//                             className="flex w-full grow border px-4 py-2 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
//                             focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
//                             border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
//                           />
//                         </div>
//                         <span className='bg-[#e3e3e3] w-[1px] h-[30px]'></span>
//                         <div className='flex items-center gap-x-2 flex-1'>
//                           <button
//                             type='button'
//                             className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"

//                             onClick={handleCancel}

//                             >
//                             Cancel
//                           </button>
//                           <button
//                             onClick={handleUpdateSave}
//                             type='button'
//                             className={`inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm
//                              hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 relative
//           [&>span]:flex [&>span]:items-center [&>span]:gap-[8px] ${!labelName && 'opacity-50 cursor-not-allowed'
//                               }`}
//                             disabled={!labelName} 


//                           >
//                             Save
//                             {loading && (
//                               <div className="simple-spinner">
//                                 <span></span>
//                               </div>
//                             )}
//                           </button>
//                         </div>

//                       </div>
//                     </form>
//                   </div>
//                 ) : (
//                   <>


//                     <div className='flex flex-row items-center justify-between flex-grow shrink-0'>
//                       <div className='flex items-center gap-x-[16px]'>
//                         <div
//                           className={` shrink-0 w-[9px] h-[9px] rounded-full ${item.color}`}

//                         ></div>
//                         <span className='flex-shrink whitespace-nowrap overflow-hidden text-ellipsis font-medium text-[#1b1b1b] text-sm leading-5'>{item.base_status_title}</span>

//                         <div className=' cursor-default select-none flex flex-row'>
//                           <button className='p-0 h-auto text-[#5e5e5f] inline-flex items-center justify-center whitespace-nowrap rounded-[5px] font-medium leading-5
//           min-w-[24px] text-[0.75rem] bg-transparent' style={{ transitionDuration: '0.15s', transitionProperty: 'border, background-color, color, opacity' }}>
//                             {
//                               item.isDefault === true && <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
//                                 Default
//                               </span>
//                             }
//                           </button>
//                         </div>
//                       </div>
//                       <div className='mr-[6px] flex  flex-row items-center flex-grow-0'>
//                         <div className='flex flex-row gap-[8px]'>
//                           <button className='feedback-info-btn-hover' onClick={() => handleMakeDefault(item.id)} title="Make Defaultt">
//                             <svg
//                               className=""
//                               width={16}
//                               height={16}
//                               viewBox="0 0 16 16"
//                               fill="#a0a0a2"
//                               role="img"
//                               focusable="false"
//                               aria-hidden="true"
//                             >
//                               <path
//                                 fillRule="evenodd"
//                                 clipRule="evenodd"
//                                 d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4ZM8 7C7.44772 7 7 7.44772 7 8V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V8C9 7.44772 8.55228 7 8 7Z"
//                               />
//                             </svg>
//                           </button>
//                           <button className='feedback-edit-btn-hover'>
//                             <svg
//                               onClick={() => handleEdit(item.id)}
//                               width={16}
//                               height={16}
//                               viewBox="0 0 16 16"
//                               fill="#a0a0a2"
//                               role="img"
//                               focusable="false"
//                               aria-hidden="true"
//                             >
//                               <path d="M10.1805 3.34195L4.14166 9.416C5.32948 9.77021 6.29238 10.6629 6.74008 11.8184L12.6877 5.8425C11.6642 5.22123 10.8043 4.36352 10.1805 3.34195Z" />
//                               <path d="M13.7391 4.71631C14.1575 4.02948 14.0727 3.11738 13.4846 2.5219C12.8908 1.92072 11.9784 1.83892 11.298 2.27649C11.8547 3.31132 12.7037 4.15999 13.7391 4.71631Z" />
//                               <path d="M3.03104 10.7502C4.30296 10.7658 5.36645 11.7423 5.49783 13.0114C4.83268 13.426 3.40197 13.7922 2.53114 13.9886C2.2001 14.0632 1.92026 13.7602 2.02075 13.4373C2.25326 12.6902 2.64592 11.5136 3.03104 10.7502Z" />
//                             </svg>
//                           </button>
//                           <button className='feedback-more-btn-hover'>
//                             <Menu as="div" className="relative inline-block text-left">
//                               <div>
//                                 <MenuButton className="rounded-md flex items-center justify-center">
//                                   <span className="sr-only">Open options</span>
//                                   <svg stroke="currentColor" fill="#a0a0a2" className='text-[#a0a0a2]' strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
//                                     <circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle>
//                                   </svg>
//                                 </MenuButton>
//                               </div>

//                               <Transition
//                                 enter="transition ease-out duration-100"
//                                 enterFrom="transform opacity-0 scale-95"
//                                 enterTo="transform opacity-100 scale-100"
//                                 leave="transition ease-in duration-75"
//                                 leaveFrom="transform opacity-100 scale-100"
//                                 leaveTo="transform opacity-0 scale-95"
//                               >
//                                 <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
//                                   <div className="py-1">

//                                     <MenuItem>
//                                       {({ focus }) => (
//                                         <Link
//                                           to="#"
//                                           className={classNames(
//                                             focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
//                                             'block px-4 py-2 text-sm'
//                                           )}
//                                           onClick={() => handleItemTagsDelete(item.id)}
//                                         >
//                                           Delete
//                                         </Link>
//                                       )}
//                                     </MenuItem>
//                                   </div>
//                                 </MenuItems>
//                               </Transition>
//                             </Menu>


//                           </button>
//                         </div>
//                       </div>
//                     </div>

//                   </>
//                 )
//                 }
//               </div>
//             )}
//           </Draggable>
//         )
//       } */}
//     </div>
//   )

// }

// const Pipeline: React.FC<ChannelProps> = React.memo(({ }) => {
//   const [editInput, setEditInput] = useState<string | null>(null)
//   const [labelName, setLabelName] = useState("")
//   const [color, setColor] = useState("bg-gray-500")
//   const [newGroup, setNewGroup] = useState(false)
//   // const [newLabel, setNewLabel] = useState(false)
//   const [itemTagBaseId, setItemTagBaseId] = useState<string | null>(null);
//   const [itemTagsList, setItemTagsList] = useState<any[]>([]);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [loading, setLoading] = useState(false);
//   const baseId = localStorage.getItem('baseId');
//   const [isDragActive, setIsDragActive] = useState(false);
//   const [isItemId, setIsItemId] = useState<string | null>(null)

//   const toggleDragAndDrop = () => {
//     setIsDragActive((prevState) => !prevState);
//   };


//   const handleSearchChange = (e: any) => {
//     setSearchQuery(e.target.value);
//   };

//   //   useEffect(() => {

//   //     setItemTagBaseId(baseId);

//   //     const savedItemTagsList = localStorage.getItem('itemStatusList');

//   //     if (savedItemTagsList) {
//   //       try {
//   //         const parsedList = JSON.parse(savedItemTagsList);

//   //         if (Array.isArray(parsedList)) {
//   //           setItemTagsList(parsedList);
//   //         } else {
//   //           setItemTagsList([]);
//   //         }
//   //       } catch (error) {
//   //         setItemTagsList([]);
//   //       }
//   //     } else if (baseId) {
//   //       fetchItemStatusList(parseInt(baseId));
//   //     } else {
//   //       setItemTagsList([]);
//   //     }
//   //   }, []);




//   const handleCancel = () => {
//     setEditInput(null)
//     setNewGroup(false)
//     // setNewLabel(false)
//   }



//   const handleNewGroup = () => {
//     setNewGroup(true)
//     setLabelName("")
//     setColor("bg-gray-500")
//   }








//   return (
//     <>
//       <Toaster richColors />
//       <main className='py-[34px] lg:py-[70px] px-0 lg:px-10 h-full flex'>
//         <div className='px-[2px] sm:px-6 lg:px-8 w-full flex justify-center'>
//           <div className='w-full max-w-[640px]'>
//             <div className='flex flex-col gap-y-[6px]'>
//               <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>Feedback Status</h2>
//               <p className='text-sm leading-6 text-[#5c5c5e] font-medium capitalize'>manage feedback tags</p>
//             </div>
//             <div className='bg-[#e0e0e0] w-full h-[1px] my-6'></div>
//             <div className='mt-6 lg:mt-10 mb-8'>
//               <p className=' text-[#5c5c5e] text-[13px] leading-[18px]'>
//                 Use labels and label groups to help organize and filter issues in your workspace.
//                 Labels created in this section are available for all teams to use. To create labels or label
//                 groups that only apply to certain teams, add them in the team-specific label settings.
//               </p>
//             </div>
//             <div className='w-full mt-6 mb-3 flex pb-4 justify-end md:justify-between lg:justify-between items-center shrink-0 flex-wrap lg:flex-nowrap gap-2'>
//               <div className="relative items-center">
//                 <input
//                   type="text"
//                   //   value={searchVal}
//                   //   onChange={(e) => setSearchVal((e.target as HTMLInputElement).value)}
//                   value={searchQuery}
//                   onChange={handleSearchChange}
//                   name="search"
//                   id="search"
//                   placeholder='Search name and email'
//                   className="flex w-full grow border px-4 py-2 pl-8 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
//                   focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
//                   border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
//                 />
//                 <div className="absolute inset-y-0 left-0 flex py-1.5 pr-1.5">

//<SearchIcon/>

//                 </div>
//               </div>
//               <div className='flex items-center gap-x-2'>
//                 <button
//                   // onClick={() => navigate(`/${baseId}/create/project`)}
//                   type='button'

//                   onClick={handleNewGroup}
//                   // disabled={newGroup || newLabel}
//                   disabled={newGroup}
//                   className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"

//                 >
//                   Create status
//                 </button>

//               </div>

//             </div>
//             <div className='flex justify-end mb-5'>
//               <button
//                 onClick={toggleDragAndDrop}
//                 className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
//               >
//                 {isDragActive ? 'Save' : 'Re-order'}
//               </button>
//             </div>
//             <div className='flex flex-col gap-y-2'>
//               <>
//                 <div className="w-full max-w-[640px]">





//                 </div>
//                 <div className="p-2 rounded-[3px] bg-white border border-solid border-[#e3e3e3] mb-1 relative pl-4 feedback-tags-hover 

//   ">
//                   <div className="flex flex-row items-center justify-between flex-grow shrink-0">
//                     <div className="flex items-center gap-x-[16px]">
//                       <div className=" shrink-0 w-[9px] h-[9px] rounded-full bg-pink-500" />
//                       <span className="flex-shrink whitespace-nowrap overflow-hidden text-ellipsis font-medium text-[#1b1b1b] text-sm leading-5">
//                         ToDo
//                       </span>
//                       <div className=" cursor-default select-none flex flex-row">
//                         <button
//                           className="p-0 h-auto text-[#5e5e5f] inline-flex items-center justify-center whitespace-nowrap rounded-[5px] font-medium leading-5 min-w-[24px] text-[0.75rem] bg-transparent"
//                           style={{
//                             transitionDuration: "0.15s",
//                             transitionProperty: "border, background-color, color, opacity"
//                           }}
//                         />
//                       </div>
//                     </div>
//                     <div className="mr-[6px] flex  flex-row items-center flex-grow-0">
//                       <div className="flex flex-row gap-[8px]">
//                         <button className="feedback-info-btn-hover" title="Make Default">
//                           <svg
//                             className=""
//                             width={16}
//                             height={16}
//                             viewBox="0 0 16 16"
//                             fill="#a0a0a2"
//                             role="img"
//                             focusable="false"
//                             aria-hidden="true"
//                           >
//                             <path
//                               fillRule="evenodd"
//                               clipRule="evenodd"
//                               d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4ZM8 7C7.44772 7 7 7.44772 7 8V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V8C9 7.44772 8.55228 7 8 7Z"
//                             />
//                           </svg>
//                         </button>
//                         <button className="feedback-edit-btn-hover">
//                           <svg
//                             width={16}
//                             height={16}
//                             viewBox="0 0 16 16"
//                             fill="#a0a0a2"
//                             role="img"
//                             focusable="false"
//                             aria-hidden="true"
//                           >
//                             <path d="M10.1805 3.34195L4.14166 9.416C5.32948 9.77021 6.29238 10.6629 6.74008 11.8184L12.6877 5.8425C11.6642 5.22123 10.8043 4.36352 10.1805 3.34195Z" />
//                             <path d="M13.7391 4.71631C14.1575 4.02948 14.0727 3.11738 13.4846 2.5219C12.8908 1.92072 11.9784 1.83892 11.298 2.27649C11.8547 3.31132 12.7037 4.15999 13.7391 4.71631Z" />
//                             <path d="M3.03104 10.7502C4.30296 10.7658 5.36645 11.7423 5.49783 13.0114C4.83268 13.426 3.40197 13.7922 2.53114 13.9886C2.2001 14.0632 1.92026 13.7602 2.02075 13.4373C2.25326 12.6902 2.64592 11.5136 3.03104 10.7502Z" />
//                           </svg>
//                         </button>
//                         <button className="feedback-more-btn-hover">
//                           <div
//                             className="relative inline-block text-left"
//                             data-headlessui-state=""
//                           >
//                             <div />
//                           </div>
//                         </button>
//                         <button
//                           className="rounded-md flex items-center justify-center"
//                           id="headlessui-menu-button-:r4f:"
//                           type="button"
//                           aria-haspopup="menu"
//                           aria-expanded="false"
//                           data-headlessui-state=""
//                         >
//                           <span className="sr-only">Open options</span>
//                           <svg
//                             stroke="currentColor"
//                             fill="#a0a0a2"
//                             className="text-[#a0a0a2]"
//                             strokeWidth={2}
//                             viewBox="0 0 24 24"
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             aria-hidden="true"
//                             focusable="false"
//                             height="1em"
//                             width="1em"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <circle cx={12} cy={12} r={1} />
//                             <circle cx={12} cy={5} r={1} />
//                             <circle cx={12} cy={19} r={1} />
//                           </svg>
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="p-2 rounded-[3px] bg-white border border-solid border-[#e3e3e3] mb-1 relative pl-4 feedback-tags-hover 
//   ">
//                   <div className="flex flex-row items-center justify-between flex-grow shrink-0">
//                     <div className="flex items-center gap-x-[16px]">
//                       <div className=" shrink-0 w-[9px] h-[9px] rounded-full bg-cyan-500" />
//                       <span className="flex-shrink whitespace-nowrap overflow-hidden text-ellipsis font-medium text-[#1b1b1b] text-sm leading-5">
//                         Backlog
//                       </span>
//                       <div className=" cursor-default select-none flex flex-row">
//                         <button
//                           className="p-0 h-auto text-[#5e5e5f] inline-flex items-center justify-center whitespace-nowrap rounded-[5px] font-medium leading-5 min-w-[24px] text-[0.75rem] bg-transparent"
//                           style={{
//                             transitionDuration: "0.15s",
//                             transitionProperty: "border, background-color, color, opacity"
//                           }}
//                         >
//                           <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
//                             Default
//                           </span>
//                         </button>
//                       </div>
//                     </div>
//                     <div className="mr-[6px] flex  flex-row items-center flex-grow-0">
//                       <div className="flex flex-row gap-[8px]">
//                         <button className="feedback-info-btn-hover" title="Make Default">
//                           <svg
//                             className=""
//                             width={16}
//                             height={16}
//                             viewBox="0 0 16 16"
//                             fill="#a0a0a2"
//                             role="img"
//                             focusable="false"
//                             aria-hidden="true"
//                           >
//                             <path
//                               fillRule="evenodd"
//                               clipRule="evenodd"
//                               d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4ZM8 7C7.44772 7 7 7.44772 7 8V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V8C9 7.44772 8.55228 7 8 7Z"
//                             />
//                           </svg>
//                         </button>
//                         <button className="feedback-edit-btn-hover">
//                           <svg
//                             width={16}
//                             height={16}
//                             viewBox="0 0 16 16"
//                             fill="#a0a0a2"
//                             role="img"
//                             focusable="false"
//                             aria-hidden="true"
//                           >
//                             <path d="M10.1805 3.34195L4.14166 9.416C5.32948 9.77021 6.29238 10.6629 6.74008 11.8184L12.6877 5.8425C11.6642 5.22123 10.8043 4.36352 10.1805 3.34195Z" />
//                             <path d="M13.7391 4.71631C14.1575 4.02948 14.0727 3.11738 13.4846 2.5219C12.8908 1.92072 11.9784 1.83892 11.298 2.27649C11.8547 3.31132 12.7037 4.15999 13.7391 4.71631Z" />
//                             <path d="M3.03104 10.7502C4.30296 10.7658 5.36645 11.7423 5.49783 13.0114C4.83268 13.426 3.40197 13.7922 2.53114 13.9886C2.2001 14.0632 1.92026 13.7602 2.02075 13.4373C2.25326 12.6902 2.64592 11.5136 3.03104 10.7502Z" />
//                           </svg>
//                         </button>
//                         <button className="feedback-more-btn-hover">
//                           <div
//                             className="relative inline-block text-left"
//                             data-headlessui-state=""
//                           >
//                             <div />
//                           </div>
//                         </button>
//                         <button
//                           className="rounded-md flex items-center justify-center"
//                           id="headlessui-menu-button-:r4h:"
//                           type="button"
//                           aria-haspopup="menu"
//                           aria-expanded="false"
//                           data-headlessui-state=""
//                         >
//                           <span className="sr-only">Open options</span>
//                           <svg
//                             stroke="currentColor"
//                             fill="#a0a0a2"
//                             className="text-[#a0a0a2]"
//                             strokeWidth={2}
//                             viewBox="0 0 24 24"
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             aria-hidden="true"
//                             focusable="false"
//                             height="1em"
//                             width="1em"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <circle cx={12} cy={12} r={1} />
//                             <circle cx={12} cy={5} r={1} />
//                             <circle cx={12} cy={19} r={1} />
//                           </svg>
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="p-2 rounded-[3px] bg-white border border-solid border-[#e3e3e3] mb-1 relative pl-4 feedback-tags-hover ">
//                   <div className="flex flex-row items-center justify-between flex-grow shrink-0">
//                     <div className="flex items-center gap-x-[16px]">
//                       <div className=" shrink-0 w-[9px] h-[9px] rounded-full bg-yellow-500" />
//                       <span className="flex-shrink whitespace-nowrap overflow-hidden text-ellipsis font-medium text-[#1b1b1b] text-sm leading-5">
//                         Review
//                       </span>
//                       <div className=" cursor-default select-none flex flex-row">
//                         <button
//                           className="p-0 h-auto text-[#5e5e5f] inline-flex items-center justify-center whitespace-nowrap rounded-[5px] font-medium leading-5 min-w-[24px] text-[0.75rem] bg-transparent"
//                           style={{
//                             transitionDuration: "0.15s",
//                             transitionProperty: "border, background-color, color, opacity"
//                           }}
//                         />
//                       </div>
//                     </div>
//                     <div className="mr-[6px] flex  flex-row items-center flex-grow-0">
//                       <div className="flex flex-row gap-[8px]">
//                         <button className="feedback-info-btn-hover" title="Make Default">
//                           <svg
//                             className=""
//                             width={16}
//                             height={16}
//                             viewBox="0 0 16 16"
//                             fill="#a0a0a2"
//                             role="img"
//                             focusable="false"
//                             aria-hidden="true"
//                           >
//                             <path
//                               fillRule="evenodd"
//                               clipRule="evenodd"
//                               d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4ZM8 7C7.44772 7 7 7.44772 7 8V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V8C9 7.44772 8.55228 7 8 7Z"
//                             />
//                           </svg>
//                         </button>
//                         <button className="feedback-edit-btn-hover">
//                           <svg
//                             width={16}
//                             height={16}
//                             viewBox="0 0 16 16"
//                             fill="#a0a0a2"
//                             role="img"
//                             focusable="false"
//                             aria-hidden="true"
//                           >
//                             <path d="M10.1805 3.34195L4.14166 9.416C5.32948 9.77021 6.29238 10.6629 6.74008 11.8184L12.6877 5.8425C11.6642 5.22123 10.8043 4.36352 10.1805 3.34195Z" />
//                             <path d="M13.7391 4.71631C14.1575 4.02948 14.0727 3.11738 13.4846 2.5219C12.8908 1.92072 11.9784 1.83892 11.298 2.27649C11.8547 3.31132 12.7037 4.15999 13.7391 4.71631Z" />
//                             <path d="M3.03104 10.7502C4.30296 10.7658 5.36645 11.7423 5.49783 13.0114C4.83268 13.426 3.40197 13.7922 2.53114 13.9886C2.2001 14.0632 1.92026 13.7602 2.02075 13.4373C2.25326 12.6902 2.64592 11.5136 3.03104 10.7502Z" />
//                           </svg>
//                         </button>
//                         <button className="feedback-more-btn-hover">
//                           <div
//                             className="relative inline-block text-left"
//                             data-headlessui-state=""
//                           >
//                             <div />
//                           </div>
//                         </button>
//                         <button
//                           className="rounded-md flex items-center justify-center"
//                           id="headlessui-menu-button-:r4j:"
//                           type="button"
//                           aria-haspopup="menu"
//                           aria-expanded="false"
//                           data-headlessui-state=""
//                         >
//                           <span className="sr-only">Open options</span>
//                           <svg
//                             stroke="currentColor"
//                             fill="#a0a0a2"
//                             className="text-[#a0a0a2]"
//                             strokeWidth={2}
//                             viewBox="0 0 24 24"
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             aria-hidden="true"
//                             focusable="false"
//                             height="1em"
//                             width="1em"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <circle cx={12} cy={12} r={1} />
//                             <circle cx={12} cy={5} r={1} />
//                             <circle cx={12} cy={19} r={1} />
//                           </svg>
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="p-2 rounded-[3px] bg-white border border-solid border-[#e3e3e3] mb-1 relative pl-4 feedback-tags-hover">
//                   <div className="flex flex-row items-center justify-between flex-grow shrink-0">
//                     <div className="flex items-center gap-x-[16px]">
//                       <div className=" shrink-0 w-[9px] h-[9px] rounded-full bg-green-500" />
//                       <span className="flex-shrink whitespace-nowrap overflow-hidden text-ellipsis font-medium text-[#1b1b1b] text-sm leading-5">
//                         Done
//                       </span>
//                       <div className=" cursor-default select-none flex flex-row">
//                         <button
//                           className="p-0 h-auto text-[#5e5e5f] inline-flex items-center justify-center whitespace-nowrap rounded-[5px] font-medium leading-5 min-w-[24px] text-[0.75rem] bg-transparent"
//                           style={{
//                             transitionDuration: "0.15s",
//                             transitionProperty: "border, background-color, color, opacity"
//                           }}
//                         />
//                       </div>
//                     </div>
//                     <div className="mr-[6px] flex  flex-row items-center flex-grow-0">
//                       <div className="flex flex-row gap-[8px]">
//                         <button className="feedback-info-btn-hover" title="Make Default">
//                           <svg
//                             className=""
//                             width={16}
//                             height={16}
//                             viewBox="0 0 16 16"
//                             fill="#a0a0a2"
//                             role="img"
//                             focusable="false"
//                             aria-hidden="true"
//                           >
//                             <path
//                               fillRule="evenodd"
//                               clipRule="evenodd"
//                               d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4ZM8 7C7.44772 7 7 7.44772 7 8V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V8C9 7.44772 8.55228 7 8 7Z"
//                             />
//                           </svg>
//                         </button>
//                         <button className="feedback-edit-btn-hover">
//                           <svg
//                             width={16}
//                             height={16}
//                             viewBox="0 0 16 16"
//                             fill="#a0a0a2"
//                             role="img"
//                             focusable="false"
//                             aria-hidden="true"
//                           >
//                             <path d="M10.1805 3.34195L4.14166 9.416C5.32948 9.77021 6.29238 10.6629 6.74008 11.8184L12.6877 5.8425C11.6642 5.22123 10.8043 4.36352 10.1805 3.34195Z" />
//                             <path d="M13.7391 4.71631C14.1575 4.02948 14.0727 3.11738 13.4846 2.5219C12.8908 1.92072 11.9784 1.83892 11.298 2.27649C11.8547 3.31132 12.7037 4.15999 13.7391 4.71631Z" />
//                             <path d="M3.03104 10.7502C4.30296 10.7658 5.36645 11.7423 5.49783 13.0114C4.83268 13.426 3.40197 13.7922 2.53114 13.9886C2.2001 14.0632 1.92026 13.7602 2.02075 13.4373C2.25326 12.6902 2.64592 11.5136 3.03104 10.7502Z" />
//                           </svg>
//                         </button>
//                         <button className="feedback-more-btn-hover">
//                           <div
//                             className="relative inline-block text-left"
//                             data-headlessui-state=""
//                           >
//                             <div />
//                           </div>
//                         </button>
//                         <button
//                           className="rounded-md flex items-center justify-center"
//                           id="headlessui-menu-button-:r4l:"
//                           type="button"
//                           aria-haspopup="menu"
//                           aria-expanded="false"
//                           data-headlessui-state=""
//                         >
//                           <span className="sr-only">Open options</span>
//                           <svg
//                             stroke="currentColor"
//                             fill="#a0a0a2"
//                             className="text-[#a0a0a2]"
//                             strokeWidth={2}
//                             viewBox="0 0 24 24"
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             aria-hidden="true"
//                             focusable="false"
//                             height="1em"
//                             width="1em"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <circle cx={12} cy={12} r={1} />
//                             <circle cx={12} cy={5} r={1} />
//                             <circle cx={12} cy={19} r={1} />
//                           </svg>
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="p-2 rounded-[3px] bg-white border border-solid border-[#e3e3e3] mb-1 relative pl-4 feedback-tags-hover ">
//                   <div className="flex flex-row items-center justify-between flex-grow shrink-0">
//                     <div className="flex items-center gap-x-[16px]">
//                       <div className=" shrink-0 w-[9px] h-[9px] rounded-full bg-indigo-500" />
//                       <span className="flex-shrink whitespace-nowrap overflow-hidden text-ellipsis font-medium text-[#1b1b1b] text-sm leading-5">
//                         Cancelled
//                       </span>
//                       <div className=" cursor-default select-none flex flex-row">
//                         <button
//                           className="p-0 h-auto text-[#5e5e5f] inline-flex items-center justify-center whitespace-nowrap rounded-[5px] font-medium leading-5 min-w-[24px] text-[0.75rem] bg-transparent"
//                           style={{
//                             transitionDuration: "0.15s",
//                             transitionProperty: "border, background-color, color, opacity"
//                           }}
//                         />
//                       </div>
//                     </div>
//                     <div className="mr-[6px] flex  flex-row items-center flex-grow-0">
//                       <div className="flex flex-row gap-[8px]">
//                         <button className="feedback-info-btn-hover" title="Make Default">
//                           <svg
//                             className=""
//                             width={16}
//                             height={16}
//                             viewBox="0 0 16 16"
//                             fill="#a0a0a2"
//                             role="img"
//                             focusable="false"
//                             aria-hidden="true"
//                           >
//                             <path
//                               fillRule="evenodd"
//                               clipRule="evenodd"
//                               d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4ZM8 7C7.44772 7 7 7.44772 7 8V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V8C9 7.44772 8.55228 7 8 7Z"
//                             />
//                           </svg>
//                         </button>
//                         <button className="feedback-edit-btn-hover">
//                           <svg
//                             width={16}
//                             height={16}
//                             viewBox="0 0 16 16"
//                             fill="#a0a0a2"
//                             role="img"
//                             focusable="false"
//                             aria-hidden="true"
//                           >
//                             <path d="M10.1805 3.34195L4.14166 9.416C5.32948 9.77021 6.29238 10.6629 6.74008 11.8184L12.6877 5.8425C11.6642 5.22123 10.8043 4.36352 10.1805 3.34195Z" />
//                             <path d="M13.7391 4.71631C14.1575 4.02948 14.0727 3.11738 13.4846 2.5219C12.8908 1.92072 11.9784 1.83892 11.298 2.27649C11.8547 3.31132 12.7037 4.15999 13.7391 4.71631Z" />
//                             <path d="M3.03104 10.7502C4.30296 10.7658 5.36645 11.7423 5.49783 13.0114C4.83268 13.426 3.40197 13.7922 2.53114 13.9886C2.2001 14.0632 1.92026 13.7602 2.02075 13.4373C2.25326 12.6902 2.64592 11.5136 3.03104 10.7502Z" />
//                           </svg>
//                         </button>
//                         <button className="feedback-more-btn-hover">
//                           <div
//                             className="relative inline-block text-left"
//                             data-headlessui-state=""
//                           >
//                             <div />
//                           </div>
//                         </button>
//                         <button
//                           className="rounded-md flex items-center justify-center"
//                           id="headlessui-menu-button-:r4n:"
//                           type="button"
//                           aria-haspopup="menu"
//                           aria-expanded="false"
//                           data-headlessui-state=""
//                         >
//                           <span className="sr-only">Open options</span>
//                           <svg
//                             stroke="currentColor"
//                             fill="#a0a0a2"
//                             className="text-[#a0a0a2]"
//                             strokeWidth={2}
//                             viewBox="0 0 24 24"
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             aria-hidden="true"
//                             focusable="false"
//                             height="1em"
//                             width="1em"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <circle cx={12} cy={12} r={1} />
//                             <circle cx={12} cy={5} r={1} />
//                             <circle cx={12} cy={19} r={1} />
//                           </svg>
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </>

//               {/* {newGroup && (
//                 <div className='bg-[#edeef2] p-2 border border-solid border-[#e0e0e0] rounded mb-1'>
//                   <div className='w-full'>
//                     <form>
//                       <div className='flex flex-row items-center gap-2 flex-1'>

//                         <Menu as='div' className='relative flex items-center '>
//                           <Menu.Button className=''>
//                             <span className='sr-only'>Open user menu</span>
//                             <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'>
//                               <div
//                                 className={`border-0 bg-none text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm w-[9px] h-[9px] rounded-full ${color} border flex justify-center items-center`}
//                               >
//                               </div>
//                             </div>

//                           </Menu.Button>
//                           <Transition
//                             as={Fragment}
//                             enter='transition ease-out duration-100'
//                             enterFrom='transform opacity-0 scale-95'
//                             enterTo='transform opacity-100 scale-100'
//                             leave='transition ease-in duration-75'
//                             leaveFrom='transform opacity-100 scale-100'
//                             leaveTo='transform opacity-0 scale-95'>
//                             <Menu.Items className='absolute flex flex-wrap left-0 top-8 z-10 mt-2.5 w-44 p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
//                               {colorList.map((value: any, index) => {
//                                 return (
//                                   <div onClick={() => setColor(value.name)} key={index} className={`flex h-6 w-6 border ${value.name} rounded-full m-2 justify-center items-center`}>
//                                     {(color === value.name) ? <CheckIcon className=' h-3 w-3 text-white' aria-hidden='true' /> : null}
//                                   </div>
//                                 )
//                               })}
//                             </Menu.Items>
//                           </Transition>

//                         </Menu>
//                         <div className='w-full'>
//                           <input
//                             name='text'
//                             type='text'
//                             required
//                             value={labelName}
//                             onChange={(e) => setLabelName(e.target.value)}
//                             placeholder='Enter Purpose'
//                             className="flex w-full grow border px-4 py-2 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
//                             focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
//                             border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
//                           />
//                         </div>
//                         <span className='bg-[#e3e3e3] w-[1px] h-[30px]'></span>
//                         <div className='flex items-center gap-x-2 flex-1'>
//                           <button
//                             className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"

//                             onClick={handleCancel}

//                             >
//                             Cancel
//                           </button>
//                           <button
//                             type='button'
//                             className={`inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold
//                              leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 
//                              focus-visible:outline-offset-2 focus-visible:outline-indigo-600  relative
//                            [&>span]:flex [&>span]:items-center [&>span]:gap-[8px] ${!labelName && 'opacity-50 cursor-not-allowed'
//                               }`}
//                             disabled={!labelName}

//                             >
//                             Save
//                             {loading && (
//                               <div className="simple-spinner">
//                                 <span></span>
//                               </div>
//                             )}
//                           </button>
//                         </div>
//                       </div>
//                     </form>
//                   </div>
//                 </div>
//               )} */}
//               {/* <DragDropContext onDragEnd={onDragEnd}>
//                 <Droppable droppableId="list">
//                   {(provided) => (
//                     <div ref={provided.innerRef} {...provided.droppableProps}>
//                       {Array.isArray(itemTagsList) && itemTagsList?.length > 0 && (
//                         itemTagsList?.filter(item => item.base_status_title.toLowerCase().includes(searchQuery.toLowerCase()))
//                           .map((item: any, index: number) => (
//                             <DraggableItem
//                               key={item.id}
//                               item={item}
//                               index={index}
//                               handleEdit={handleEdit}
//                               handleItemTagsDelete={handleItemTagsDelete}
//                               editInput={editInput}
//                               setEditInput={setEditInput}
//                               setLabelName={setLabelName}
//                               setColor={setColor}
//                               setNewGroup={setNewGroup}
//                               loading={loading}
//                               color={color}
//                               labelName={labelName}
//                               handleCancel={handleCancel}
//                               handleUpdateSave={handleUpdateSave}
//                               isDragActive={isDragActive}
//                               handleMakeDefault={handleMakeDefault}

//                             />
//                           ))
//                       )}
//                       {provided.placeholder}
//                     </div>
//                   )}
//                 </Droppable>
//               </DragDropContext> */}
//               {
//                 !isDragActive &&
//                 (
//                   <>
//                     {Array.isArray(itemTagsList) && itemTagsList?.length > 0 && (
//                       itemTagsList?.filter(item => item.base_status_title.toLowerCase().includes(searchQuery.toLowerCase()))
//                         .map((item: any) => (
//                           <div className='p-2 rounded-[3px] bg-white border border-solid border-[#e3e3e3] mb-1 relative pl-4 feedback-tags-hover'>


//                             {editInput === item.id ? (

//                               <div className='w-full'>
//                                 <form>
//                                   <div className='flex flex-row items-center gap-2 flex-1'>
//                                     {/* <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'> */}

//                                     <Menu as='div' className='relative flex items-center '>
//                                       <Menu.Button className=''>
//                                         <span className='sr-only'>Open user menu</span>
//                                         <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'>
//                                           <div
//                                             className={`border-0 bg-none text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm w-[9px] h-[9px] rounded-full ${color} border flex justify-center items-center`}
//                                           >
//                                           </div>
//                                         </div>

//                                       </Menu.Button>
//                                       <Transition
//                                         as={Fragment}
//                                         enter='transition ease-out duration-100'
//                                         enterFrom='transform opacity-0 scale-95'
//                                         enterTo='transform opacity-100 scale-100'
//                                         leave='transition ease-in duration-75'
//                                         leaveFrom='transform opacity-100 scale-100'
//                                         leaveTo='transform opacity-0 scale-95'>
//                                         <Menu.Items className='absolute flex flex-wrap left-0 top-8 z-10 mt-2.5 w-44 p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
//                                           {colorList.map((value: any, index) => {
//                                             return (
//                                               <div onClick={() => setColor(value.name)} key={index} className={`flex h-6 w-6 border ${value.name} rounded-full m-2 justify-center items-center`}>
//                                                 {(color === value.name) ? <CheckIcon className=' h-3 w-3 text-white' aria-hidden='true' /> : null}
//                                               </div>
//                                             )
//                                           })}
//                                         </Menu.Items>
//                                       </Transition>

//                                     </Menu>
//                                     <div className='w-full'>
//                                       <input
//                                         name='text'
//                                         type='text'
//                                         required
//                                         value={labelName}
//                                         onChange={(e: any) => setLabelName(e.target.value)}
//                                         placeholder='Enter Purpose'
//                                         className="flex w-full grow border px-4 py-2 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
//                                         focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
//                                         border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"                                      />
//                                     </div>
//                                     <span className='bg-[#e3e3e3] w-[1px] h-[30px]'></span>
//                                     <div className='flex items-center gap-x-2 flex-1'>
//                                       <button
//                                         // onClick={() => navigate(`/${baseId}/create/project`)}
//                                         type='button'
//                                         className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white 
//                                         shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"

//                                         onClick={handleCancel}

//                                       >
//                                         Cancel
//                                       </button>
//                                       <button
//                                         // onClick={() => navigate(`/${baseId}/create/project`)}
//                                         // onClick={handleUpdateSave}
//                                         type='button'
//                                         className={` inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white 
//                                         shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 relative
//      [&>span]:flex [&>span]:items-center [&>span]:gap-[8px] ${!labelName && 'opacity-50 cursor-not-allowed'
//                                           }`}
//                                         disabled={!labelName}
//                                       >
//                                         Save
//                                         {loading && (
//                                           <div className="simple-spinner">
//                                             <span></span>
//                                           </div>
//                                         )}
//                                       </button>
//                                     </div>

//                                   </div>
//                                 </form>
//                               </div>
//                             ) : (
//                               <>
//                                 <div className='flex flex-row items-center justify-between flex-grow shrink-0'>
//                                   <div className='flex items-center gap-x-[16px]'>
//                                     <div
//                                       className={` shrink-0 w-[9px] h-[9px] rounded-full ${item.color}`}

//                                     ></div>
//                                     <span className='flex-shrink whitespace-nowrap overflow-hidden text-ellipsis font-medium text-[#1b1b1b] text-sm leading-5'>{item.base_status_title}</span>

//                                     <div className=' cursor-default select-none flex flex-row'>
//                                       <button className='p-0 h-auto text-[#5e5e5f] inline-flex items-center justify-center whitespace-nowrap rounded-[5px] font-medium leading-5
//                                           min-w-[24px] text-[0.75rem] bg-transparent' style={{ transitionDuration: '0.15s', transitionProperty: 'border, background-color, color, opacity' }}>
//                                         {
//                                           item.isDefault === true && <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
//                                             Default
//                                           </span>
//                                         }
//                                       </button>
//                                     </div>
//                                   </div>
//                                   <div className='mr-[6px] flex  flex-row items-center flex-grow-0'>
//                                     <div className='flex flex-row gap-[8px]'>
//                                       <button className='feedback-info-btn-hover '
//                                         //   onClick={() => {
//                                         //     handleMakeDefault(item.id);
//                                         //   }}

//                                         title="Make Default">
//                                         <svg
//                                           className=""
//                                           width={16}
//                                           height={16}
//                                           viewBox="0 0 16 16"
//                                           fill="#a0a0a2"
//                                           role="img"
//                                           focusable="false"
//                                           aria-hidden="true"
//                                         >
//                                           <path
//                                             fillRule="evenodd"
//                                             clipRule="evenodd"
//                                             d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4ZM8 7C7.44772 7 7 7.44772 7 8V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V8C9 7.44772 8.55228 7 8 7Z"
//                                           />
//                                         </svg>
//                                       </button>
//                                       <button className='feedback-edit-btn-hover'>
//                                         <svg
//                                           //   onClick={() => handleEdit(item.id)}
//                                           width={16}
//                                           height={16}
//                                           viewBox="0 0 16 16"
//                                           fill="#a0a0a2"
//                                           role="img"
//                                           focusable="false"
//                                           aria-hidden="true"
//                                         >
//                                           <path d="M10.1805 3.34195L4.14166 9.416C5.32948 9.77021 6.29238 10.6629 6.74008 11.8184L12.6877 5.8425C11.6642 5.22123 10.8043 4.36352 10.1805 3.34195Z" />
//                                           <path d="M13.7391 4.71631C14.1575 4.02948 14.0727 3.11738 13.4846 2.5219C12.8908 1.92072 11.9784 1.83892 11.298 2.27649C11.8547 3.31132 12.7037 4.15999 13.7391 4.71631Z" />
//                                           <path d="M3.03104 10.7502C4.30296 10.7658 5.36645 11.7423 5.49783 13.0114C4.83268 13.426 3.40197 13.7922 2.53114 13.9886C2.2001 14.0632 1.92026 13.7602 2.02075 13.4373C2.25326 12.6902 2.64592 11.5136 3.03104 10.7502Z" />
//                                         </svg>
//                                       </button>
//                                       <button className='feedback-more-btn-hover'>
//                                         <Menu as="div" className="relative inline-block text-left">
//                                           <div>
//                                             <MenuButton className="rounded-md flex items-center justify-center">
//                                               <span className="sr-only">Open options</span>
//                                               <svg stroke="currentColor" fill="#a0a0a2" className='text-[#a0a0a2]' strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
//                                                 <circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle>
//                                               </svg>
//                                             </MenuButton>
//                                           </div>

//                                           <Transition
//                                             enter="transition ease-out duration-100"
//                                             enterFrom="transform opacity-0 scale-95"
//                                             enterTo="transform opacity-100 scale-100"
//                                             leave="transition ease-in duration-75"
//                                             leaveFrom="transform opacity-100 scale-100"
//                                             leaveTo="transform opacity-0 scale-95"
//                                           >
//                                             <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
//                                               <div className="py-1">

//                                                 <MenuItem>
//                                                   {({ focus }) => (
//                                                     <Link
//                                                       to="#"
//                                                       className={classNames(
//                                                         focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
//                                                         'block px-4 py-2 text-sm'
//                                                       )}
//                                                     //   onClick={() => handleItemTagsDelete(item.id)}
//                                                     >
//                                                       Delete
//                                                     </Link>
//                                                   )}
//                                                 </MenuItem>
//                                               </div>
//                                             </MenuItems>
//                                           </Transition>
//                                         </Menu>


//                                       </button>
//                                     </div>
//                                   </div>
//                                 </div>

//                               </>
//                             )
//                             }
//                           </div>
//                         ))
//                     )}
//                   </>
//                 )

//               }
//             </div>
//           </div>
//         </div>
//       </main>

//     </>
//   )
// });

// export default Pipeline





// @ts-nocheck

import React, { useState, useEffect, useCallback } from 'react'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { Link, useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'sonner';
import { UserDetails } from '../../auth/authTypes';
import { useAppDispatch } from '../../../app/hooks';
import { fetchCountryList, fetchLocationListStatus, fetchPipelineList, updateLocation } from '../../pipeline/pipelineSlice';
import Badge from '../../../components/common/Badge';
import DropDown from '../../../components/common/DropDown';
import CreatePipeline from './CreatePipeline';
import { ExclamationIcon, ThreeDotIcon } from '../../../components/icons';

type ChannelProps = {
  userDetails: UserDetails | null;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const roleSearchList = [{ name: "active" }, { name: "deleted" },
{ name: "archived" }]

const Pipeline: React.FC<ChannelProps> = React.memo(({ }) => {
  const baseIdString = localStorage.getItem('UserBaseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [searchVal, setSearchVal] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editBoardData, setEditBoardData] = useState<{ id: number; baseId: number } | null>(null);
  const [boardsFetched, setBoardsFetched] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [dataLoading, setDataLoading] = useState(false);
  const [countryList, setCountryList] = useState<any[]>([]);
  const [selectSearchRole, setSelectSearchRole] = useState("active")
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [filteredMemberData, setFilteredMemberData] = useState<MemberData[]>([])
  const navigate = useNavigate();
  // In Teams component
  useEffect(() => {
    const teamInfoString = localStorage.getItem('basePiplineData');
    if (teamInfoString) {
      const teamInfo = JSON.parse(teamInfoString);
      setBoardsFetched(teamInfo.data);
      setDataLoading(false);
    } else {
      const fetchTeams = async () => {
        setDataLoading(true);
        try {
          const baseResponse = await dispatch(fetchPipelineList(baseId));

          if (baseResponse?.payload) {
            setBoardsFetched(baseResponse.payload.data);
            localStorage.setItem('basePiplineData', JSON.stringify(baseResponse.payload));
          } else {
            setBoardsFetched([]);
          }
        } catch (error) {
          console.error("Error fetching teams:", error);
          setBoardsFetched([]);
        } finally {
          setDataLoading(false);
        }
      };

      if (baseId) {
        fetchTeams();
      }
    }
  }, [baseId, dispatch]);


  const handleDeleteBoard = useCallback(async (id: number, baseId: number) => {
    const payload: createTeamsPayload = {
      status: 'deleted',
      id: id
    };

    try {
      // Update existing team
      const response = await dispatch(updateLocation({
        payload,
        userId: baseId,
        id: id
      }));

      if (response?.payload) {
        toast.success('Status has been updated successfully');
        // Update the local storage and state
        const updatedBoards = boardsFetched.map(board =>
          board.id === id ? { ...board, status: 'deleted' } : board
        );

        localStorage.setItem('basePiplineData', JSON.stringify({ data: updatedBoards }));

        setBoardsFetched(updatedBoards);

        setIsModalOpen(false);
        setEditBoardData(null);
      } else {
        toast.error('Failed to update status');
      }
    } catch (error) {
      toast.error('An error occurred while updating the status');
      console.error(error);
    }
  }, [boardsFetched, dispatch]);

  const handleArchivedBoard = useCallback(async (id: number, baseId: number) => {
    const payload: createTeamsPayload = {
      status: 'archived',
      id: id
    };

    try {
      // Update existing team
      const response = await dispatch(updateLocation({
        payload,
        userId: baseId,
        id: id
      }));

      if (response?.payload) {
        toast.success('Status has been updated successfully');
        // Update the local storage and state
        const updatedBoards = boardsFetched.map(board =>
          board.id === id ? { ...board, status: 'archived' } : board
        );

        localStorage.setItem('basePiplineData', JSON.stringify({ data: updatedBoards }));

        setBoardsFetched(updatedBoards);

        setIsModalOpen(false);
        setEditBoardData(null);
      } else {
        toast.error('Failed to update status');
      }
    } catch (error) {
      toast.error('An error occurred while updating the status');
      console.error(error);
    }
  }, [boardsFetched, dispatch]);

  // Add a new useEffect to handle updates from localStorage
  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'basePiplineData' && e.newValue) {
        const teamsDataArray = JSON.parse(e.newValue);
        setBoardsFetched(teamsDataArray.data || []);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);


  const handleEdit = useCallback((id: number, baseId: number) => {
    const teamToEdit = boardsFetched.find(team => team.id === id);
    if (teamToEdit) {
      setEditBoardData(teamToEdit);
      setIsModalOpen(true);
    }
  }, [boardsFetched]);

  const openModal = () => {
    setEditBoardData(null);  // Add this line
    setIsModalOpen(true);
  };

  const handleTeamsData = (id: any) => {
    navigate(`/${baseId}/settings/pipeline/${id}`);
  }

  const handleSearchRoleChange = (role: string) => {
    setSelectSearchRole(role)
  }

  const handleActive = async () => {
    setLoading1(true);
    try {
      const res = await dispatch(fetchLocationListStatus({ baseId, status: 'active' }));
      if (res.payload) {
        setBoardsFetched(res.payload.data);
        localStorage.setItem('basePiplineData', JSON.stringify(res.payload));
      } else {
        console.error('No payload received');
      }
    } catch (error) {
      console.error('Delete failed:', error);
      alert('Failed to delete location data. Please try again later.');
    } finally {
      setLoading1(false);
    }
  };


  const handleDelete = async () => {
    setLoading1(true);
    try {
      const res = await dispatch(fetchLocationListStatus({ baseId, status: 'deleted' }));
      if (res.payload) {
        setBoardsFetched(res.payload.data);
        localStorage.setItem('basePiplineData', JSON.stringify(res.payload));
      } else {
        console.error('No payload received');
      }
    } catch (error) {
      console.error('Delete failed:', error);
      alert('Failed to delete location data. Please try again later.');
    } finally {
      setLoading1(false);
    }
  };


  const handleSuspend = async () => {
    setLoading(true)
    try {
      const res = await dispatch(fetchLocationListStatus({ baseId, status: 'archived' }));
      if (res.payload) {
        setBoardsFetched(res.payload.data)
        // Update local storage
        localStorage.setItem('basePiplineData', JSON.stringify(res.payload));
      } else {
        console.error('No payload received');
      }
    } catch (error) {
      console.error('Suspend failed:', error);
    } finally {
      setLoading(false)
    }
  };

  return (
    <>
      <Toaster richColors />
      <div className=''>

        <main className='py-[34px] lg:py-[70px] px-0 lg:px-10 h-full flex'>
          <div className='px-[2px] sm:px-6 lg:px-8 w-full flex justify-center'>
            <div className='w-full max-w-[640px]'>
              <div className='flex flex-col gap-y-[6px]'>
                <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>Base Pipeline</h2>
                <p className='text-sm leading-6 text-[#5c5c5e] font-medium'>
                  Base Pipeline Description
                </p>
              </div>


              <div className=' mt-6 lg:mt-10 flex items-center  w-full gap-x-3'>

                <div className='w-full lg:w-[40%]'>
                  <label htmlFor='searchEmail' className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased leading-5'>
                    Pipeline
                  </label>
                  <div className='mt-[6px]'>
                    <input
                      type="text"
                      value={searchVal}
                      onChange={(e) => setSearchVal(e.target.value)}
                      name="search"
                      id="search"
                      placeholder='Search name and email'
                      className="flex w-full grow border px-4 py-2 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
                      focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                      border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                    />
                  </div>
                </div>

                <div className='w-full lg:w-[20%]'>
                  <div className='mt-[32px]'>
                    <DropDown
                      onChangeValue={handleSearchRoleChange}
                      data={roleSearchList}
                      previousValue={selectSearchRole}
                      onDelete={handleDelete}
                      onArchived={handleSuspend}
                      onActive={handleActive}
                    />
                    {/* <DropDown onChangeValue={(value) => setSelectSearchRole(value)} data={roleSearchList} previousValue={selectSearchRole} /> */}

                  </div>
                </div>
                <div className='w-full lg:w-[40%]'>
                  <div className='mt-[32px] flex justify-end'>
                    <button
                      onClick={openModal}
                      type='button'
                      className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Create pipeline
                    </button>
                  </div>
                </div>

              </div>
              <div className='mt-6 w-full'>
                {dataLoading ? (
                  <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 191px)' }}>
                    <div className="simple-spinner-loader">
                      <span></span>
                    </div>
                  </div>
                ) : boardsFetched.length === 0 ? (
                  <div className='w-full mt-5 flex h-[300px] justify-center items-center flex-col gap-y-3'>
                    <ExclamationIcon />
                    <p className='text-[#0000007a] text-[13px]'>No results found</p>
                  </div>
                ) : (
                  <>
                    {boardsFetched?.filter((post) => {
                      if (searchVal === "") {
                        return post;
                      } else if (
                        post?.pipeline_name?.toLowerCase().includes(searchVal.toLowerCase()) ||
                        post?.pipeline_description?.toLowerCase().includes(searchVal.toLowerCase())
                      ) {
                        return post;
                      }
                    })
                      .map((items: any, index: number) => {
                        return (
                          <div key={index} className='w-full flex border-b border-light-gray-200'>
                            <ul className='list-none w-full'>
                              <li className='flex flex-row items-center justify-between text-[0.875rem] px-[0rem] py-4 w-full'>
                                <div className='flex shrink-0 pr-0'>
                                 { items?.pipeline_colour &&
                                   <span
                                   className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize"
                                   style={{ backgroundColor: items?.pipeline_colour }}
                                 >
                                   {items.pipeline_name[0]}
                                 </span>
                                 }
                                </div>
                                <div className='flex-1 px-4' onClick={() => handleTeamsData(items?.id)}>
                                  <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b] capitalize'>{items.pipeline_name}</p>
                                  <p className='text-[0.8125rem] font-normal leading-4 text-[#5c5c5e]'>{items?.pipeline_description}</p>
                                </div>
                                <div>
                                  <Badge status={items?.status} />


                                </div>
                                <div className='px-2 flex items-center'>
                                  <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                      <MenuButton className="rounded-md flex items-center justify-center hover:bg-light-gray-200 focus:bg-light-gray-200 w-[32px] h-[32px]">
                                        <span className="sr-only">Open options</span>
                                        <ThreeDotIcon />
                                      </MenuButton>
                                    </div>

                                    <Transition
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                          <MenuItem>
                                            {({ focus }) => (
                                              <div
                                                onClick={() => handleEdit(items.id, items.base_id)}
                                                className={classNames(
                                                  focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm'
                                                )}
                                              >
                                                Edit
                                              </div>
                                            )}
                                          </MenuItem>
                                          <MenuItem>
                                            {({ focus }) => (
                                              <Link
                                                to="#"
                                                className={classNames(
                                                  focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm'
                                                )}
                                                onClick={() => handleDeleteBoard(items.id, items.base_id)}
                                              >
                                                Delete
                                              </Link>
                                            )}
                                          </MenuItem>
                                          <MenuItem>
                                            {({ focus }) => (
                                              <Link
                                                to="#"
                                                onClick={() => handleArchivedBoard(items.id, items.base_id)}
                                                className={classNames(
                                                  focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm'
                                                )}
                                              >
                                                Archived
                                              </Link>
                                            )}
                                          </MenuItem>

                                        </div>
                                      </MenuItems>
                                    </Transition>
                                  </Menu>
                                </div>
                              </li>
                            </ul>
                          </div>
                        );
                      })}
                  </>
                )}
              </div>

            </div>
          </div>
        </main>
      </div>

      <CreatePipeline
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        editBoardData={editBoardData}
        baseId={baseId}
        setEditBoardData={setEditBoardData}
      />
    </>
  )
});
export default Pipeline
