// teamsSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { baseTeamMemberInvite, createTeamsData, deleteTeamsData, fetchBoardsData, fetchTeamsByIdData, updateTeamsData } from '../../services/teamService';
import { ApiError, TeamsResponse, TeamsState, baseTeamMemberInvitePayload, createTeamsPayload, updateTeamsPayload } from './teamsTypes';

export const fetchBoardsList = createAsyncThunk<TeamsResponse, number | undefined, { rejectValue: string }>(
  'base/fetchBoardList',
  async (baseId, { rejectWithValue }) => {
    try {
      if (baseId === undefined) {
        throw new Error('Base ID is undefined');
      }
      const response = await fetchBoardsData(baseId);
      return response;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message || 'Failed to fetch base data');
    }
  }
);

// create teams 
export const createTeams = createAsyncThunk<TeamsResponse, { payload: createTeamsPayload; userId: number },
  { rejectValue: string }
>(
  'base/createTeams',
  async ({ payload, userId }, { rejectWithValue }) => {
    try {
      const response = await createTeamsData(payload, userId);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to invite member');
    }
  }
);

// update teams
export const updateTeams = createAsyncThunk<TeamsResponse, { payload: updateTeamsPayload; userId: number; id: number },
  { rejectValue: string }
>(
  'base/updateTeams',
  async ({ payload, userId, id }, { rejectWithValue }) => {
    try {
      const response = await updateTeamsData( userId,id, payload);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to invite member');
    }
  }
);

// delete teams

export const deleteTeams = createAsyncThunk<TeamsResponse, { userId: number; id: number }, { rejectValue: string }>(
  'base/deleteTeams',
  async ({ userId, id }, { rejectWithValue }) => {
      try {
          // Ensure the parameters are in the correct order
          const response = await deleteTeamsData(userId, id);
          return response;
      } catch (err) {
          const error = err as ApiError;
          return rejectWithValue(error.response?.data?.message || 'Failed to delete team');
      }
  }
);

// fetch teams by id
export const fetchTeamsById = createAsyncThunk<TeamsResponse, { userId: number; id: number }, { rejectValue: string }>(
  'base/fetchTeamsById',
  async ({ userId, id }, { rejectWithValue }) => {
      try {
          const response = await fetchTeamsByIdData(userId, id);
          return response;
      } catch (err) {
          const error = err as ApiError;
          return rejectWithValue(error.response?.data?.message || 'Failed to fetch team');
      }
  }
);

// fetch team member invite 
export const baseTeamMemberInviteData = createAsyncThunk<TeamsResponse, { payload: baseTeamMemberInvitePayload; userId: number },
  { rejectValue: string }
>(
  'base/baseTeamMemberInviteData',
  async ({ payload, userId }, { rejectWithValue }) => {
    try {
      const response = await baseTeamMemberInvite(payload, userId);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to invite member');
    }
  }
);



const initialState: TeamsState = {
  boardsDetails: null,
  boardsStatus: 'idle',
  boardsStatusError: null,
  createTeamsStatus: 'idle',
  createTeamsError: null,
  createTeamsData: null,
  updateTeamsStatus: 'idle',
  updateTeamsError: null,
  updateTeamsData: null,
  deleteTeamsStatus: 'idle',
  deleteTeamsError: null,
  fetchTeamsByIdStatus: 'idle',
  fetchTeamsByIdError: null,
  fetchTeamsByIdData: null,
  baseMemberInviteStatus: 'idle',
  baseMemberInviteError: null,
  baseMemberInviteData: null,

};

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBoardsList.pending, (state) => {
        state.boardsStatus = 'loading';
      })
      .addCase(fetchBoardsList.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.boardsStatus = 'succeeded';
        state.boardsDetails = action.payload.data;
        localStorage.setItem('baseTeamsData', JSON.stringify(action.payload));
      })
      .addCase(fetchBoardsList.rejected, (state, action) => {
        state.boardsStatus = 'failed';
        state.boardsStatusError = action.payload || 'Failed to fetch boards';
      })
      .addCase(createTeams.pending, (state) => {
        state.createTeamsStatus = 'loading';
      })
      .addCase(createTeams.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.createTeamsStatus = 'succeeded';
        state.createTeamsData = action.payload;
      })
      .addCase(createTeams.rejected, (state, action) => {
        state.createTeamsStatus = 'failed';
        state.createTeamsError = action.payload || 'Failed to invite member';
      })
      .addCase(updateTeams.pending, (state) => {
        state.updateTeamsStatus = 'loading';
      })
      .addCase(updateTeams.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.updateTeamsStatus = 'succeeded';
        state.updateTeamsData = action.payload;
      })
      .addCase(updateTeams.rejected, (state, action) => {
        state.updateTeamsStatus = 'failed';
        state.updateTeamsError = action.payload || 'Failed to invite member';
      })
      .addCase(deleteTeams.pending, (state) => {
        state.deleteTeamsStatus = 'loading';
      })
      .addCase(deleteTeams.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.deleteTeamsStatus = 'succeeded';
        state.updateTeamsData = action.payload;
      })
      .addCase(deleteTeams.rejected, (state, action) => {
        state.deleteTeamsStatus = 'failed';
        state.deleteTeamsError = action.payload || 'Failed to invite member';
      })
      .addCase(fetchTeamsById.pending, (state) => {
        state.fetchTeamsByIdStatus = 'loading';
      })
      .addCase(fetchTeamsById.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.fetchTeamsByIdStatus = 'succeeded';
        state.fetchTeamsByIdData = action.payload;
      })
      .addCase(fetchTeamsById.rejected, (state, action) => {
        state.fetchTeamsByIdStatus = 'failed';
        state.fetchTeamsByIdError = action.payload || 'Failed to invite member';
      })
      .addCase(baseTeamMemberInviteData.pending, (state) => {
        state.baseMemberInviteStatus = 'loading';
      })
      .addCase(baseTeamMemberInviteData.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.baseMemberInviteStatus = 'succeeded';
        state.baseMemberInviteData = action.payload.base;
      })
      .addCase(baseTeamMemberInviteData.rejected, (state, action) => {
        state.baseMemberInviteStatus = 'failed';
        state.baseMemberInviteError = action.payload || 'Failed to invite member';
      });
  }
});

export default teamsSlice.reducer;