// @ts-nocheck

import { useState, useEffect, useRef, Fragment } from 'react'
import { _getProfileDetails, _updateProfile } from './profileAPI';
import { Toaster, toast } from 'sonner';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { Dialog, Transition } from '@headlessui/react';
import { fetchUserData, updateUserProfile, uploadUserLogo } from '../../users/userSlice';
import { useAppDispatch } from '../../../app/hooks';
import { unwrapResult } from '@reduxjs/toolkit';



const UserProfile = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [timeZone, setTimezone] = useState('America/New_York')
    const [data, setData] = useState<any>()
    const [showAlert, setShowAlert] = useState(false)
    const [file, setFile] = useState<File | null>(null);
    const [logoUrl, setLogoUrl] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [cropSrc, setCropSrc] = useState<string | null>(null);
    const [crop, setCrop] = useState<Crop>({
        unit: 'px',
        width: 250,
        height: 250,
        x: 0,
        y: 0,
        aspect: 1,
    });
    const [profileLoading, setProfileLoading] = useState(false);
    const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
    const [showCropModal, setShowCropModal] = useState(false);
    const imageRef = useRef<HTMLImageElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [logoLoading, setLogoLoading] = useState(true);
    const [profileImage, setProfileImage] = useState('')
    const dispatch = useAppDispatch();
    const baseIdString = localStorage.getItem('UserBaseId') || localStorage.getItem('createBaseId');
    const baseId = baseIdString ? JSON.parse(baseIdString) : null;

    useEffect(() => {
        const fetchData = async () => {
            // Check if userDetails already exists in local storage
            const storedUserDetails = localStorage.getItem('userDetails');

            if (storedUserDetails) {
                // If userDetails is found in local storage, set the state from local storage
                const parsedDetails = JSON.parse(storedUserDetails);
                setName(parsedDetails.name);
                setEmail(parsedDetails.email);
                setTimezone(parsedDetails.timezone);
                setProfileImage(parsedDetails.profile_pic);
            } else {
                // If userDetails is not found, fetch data from the API
                try {
                    const res = await dispatch(fetchUserData());

                    if (res.payload.status === 200) {
                        const userData = res.payload.data.user;

                        // Set state with fetched data
                        setName(userData.name);
                        setEmail(userData.email);
                        setTimezone(userData.timezone);
                        setProfileImage(userData.profile_pic);

                        // Store the fetched user data in local storage
                        localStorage.setItem('userDetails', JSON.stringify(userData));

                    }
                } catch (err) {
                    console.log("Error fetching user data", err);
                }
            }
        };

        fetchData();
    }, [dispatch]);




    const updateProfile = () => {
        setProfileLoading(true)
        dispatch(updateUserProfile({ payload: { name }, userId: baseId }))
            .then(unwrapResult)
            .then((res) => {
                setProfileLoading(false)
                if (res.status === 200) {
                    const baseMembersDetails = JSON.parse(localStorage.getItem('baseMembersDetails')) || { data: { baseMembers: [] } };

                    if (baseMembersDetails?.data?.baseMembers) {
                        const updatedMember = baseMembersDetails?.data?.baseMembers[0];

                        if (updatedMember) {
                            const index = baseMembersDetails.data.baseMembers.findIndex(member => member.id === updatedMember.id);

                            if (index !== -1) {
                                baseMembersDetails.data.baseMembers[index] = updatedMember;
                                localStorage.setItem('baseMembersDetails', JSON.stringify(baseMembersDetails));
                                setData(res);
                                toast.success('Profile updated successfully');
                                // only update profile pic         
                                const existingUserDetails = JSON.parse(localStorage.getItem('userDetails')) || {};

                                const updatedUserDetails = {
                                    ...existingUserDetails,
                                    name: res.data.user.name
                                };

                                localStorage.setItem('userDetails', JSON.stringify(updatedUserDetails));
                            } else {
                                console.error('Member not found in local storage.');
                            }
                        }
                    }

                }
            })
            .catch((err) => {
                setProfileLoading(false); // Set loading to false in case of an error
            });
    };



    useEffect(() => {
        if (profileImage) {

            setLogoLoading(true);
            const newLogoUrl = `${process.env.REACT_APP_API_BASE_URL}/${profileImage}`;
            setLogoUrl(newLogoUrl);

            // Preload the image
            const img = new Image();
            img.onload = () => {
                setLogoLoading(false);
            };
            img.onerror = () => {
                setLogoLoading(false);
                setLogoUrl(''); // Reset to no logo if load fails
            };
            img.src = newLogoUrl;
        } else {
            setLogoUrl('');
            setLogoLoading(false);
        }
    }, [profileImage]);




    // useEffect(() => {
    //     if (profileImage) {
    //         setLogoUrl(`${process.env.REACT_APP_API_BASE_URL}/${profileImage}`);
    //     }
    // }, [profileImage]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.files && event.target.files[0]) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);
            setCropSrc(URL.createObjectURL(selectedFile));
            setShowCropModal(true);
        }
    };

    const onImageLoaded = (event: React.SyntheticEvent<HTMLImageElement>) => {
        imageRef.current = event.currentTarget;
        setCrop(centerCrop(makeAspectCrop({ unit: 'px', width: 250, aspect: 1 }, 1, imageRef.current.width, imageRef.current.height)));

    };



    const getCroppedImage = (): Promise<File | null> => {
        if (!completedCrop || !imageRef.current || !canvasRef.current || !file) {
            return Promise.resolve(null);
        }

        const image = imageRef.current;
        const canvas = canvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return Promise.resolve(null);
        }

        canvas.width = 250;
        canvas.height = 250;

        return new Promise((resolve) => {
            if (image.complete) {
                drawCanvas();
            } else {
                image.onload = drawCanvas;
            }

            function drawCanvas() {
                ctx.drawImage(
                    image,
                    crop.x * scaleX,
                    crop.y * scaleY,
                    crop.width * scaleX,
                    crop.height * scaleY,
                    0,
                    0,
                    250,
                    250
                );

                canvas.toBlob((blob) => {
                    if (!blob) {
                        resolve(null);
                        return;
                    }
                    const croppedFile = new File([blob], file.name, { type: file.type });
                    resolve(croppedFile);
                }, file.type,
                    1.0
                );
            }
        });
    };

    const handleCropSave = async () => {
        if (!completedCrop) {
            toast.error('Please complete the crop before saving.');
            return;
        }

        setLoading(true);
        try {
            const croppedFile = await getCroppedImage();
            if (!croppedFile) {
                throw new Error("Cropping failed or file is null.");
            }
            const result = await dispatch(uploadUserLogo({
                payload: { image: croppedFile },
                userId: baseId
            }));


            if (uploadUserLogo.fulfilled.match(result)) {
                toast.success('Upload successful!');
                const newLogoUrl = `${process.env.REACT_APP_API_BASE_URL}/${result.payload.data.profile_pic}`;
                setLogoUrl(newLogoUrl);

                // only update profile pic         
                const existingUserDetails = JSON.parse(localStorage.getItem('userDetails')) || {};

                const updatedUserDetails = {
                    ...existingUserDetails,
                    profile_pic: result.payload.data.profile_pic
                };
                localStorage.setItem('userDetails', JSON.stringify(updatedUserDetails));

                // Preload the new image
                const img = new Image();
                img.onload = () => {
                    setLogoLoading(false);
                };
                img.onerror = () => {
                    setLogoLoading(false);
                    toast.error('Failed image to upload');
                };
                img.src = newLogoUrl;
            } else {
                throw new Error(result.payload || 'Upload failed');
            }

            // Dispatch custom event after updating local storage
            window.dispatchEvent(new Event("userProfileDetailsUpdated"));

        } catch (error) {
            toast.error('Upload failed. Please try again.');
        } finally {
            setShowCropModal(false);
        }
    };



    return (
        <>

            <main className='py-[34px] lg:py-[70px] px-0 lg:px-10 h-full flex'>
                <div className='px-[2px] sm:px-6 lg:px-8 w-full flex justify-center'>
                    <div className='w-full max-w-[640px]'>
                        <div className='flex flex-col gap-y-[6px]'>
                            <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>Profile</h2>
                            <p className='text-sm leading-6 text-[#5c5c5e] font-medium'>
                                This information will be displayed publicly so be careful what you share.
                            </p>
                        </div>
                    <div className='bg-[#ededed] h-[1px] w-full mt-6'>

                    </div>
                        <div className='flex items-center mt-6 lg:mt-8 justify-between relative'>
                            <label className="flex flex-inherit h-[70px] w-[70px] cursor-pointer z-10 absolute top-[0px] left-[0px] cursor-move">
                                <input className="opacity-0 focus:outline-none cursor-move" type="file" onChange={handleFileChange} accept="image/*" />
                            </label>
                            <div className="absolute top-[0px] left-[0px] h-[70px] w-[70px]">
                                {logoLoading ? (
                                    <div className="flex items-center justify-center bg-[#f5f5f5] rounded-full h-full">
                                        <div className="simple-spinner-loader">
                                            <span className='!w-4 !h-4'></span>
                                        </div>
                                    </div>
                                ) : logoUrl ? (
                                    <img
                                        src={logoUrl}
                                        alt="Base Logo"
                                        className="h-full w-full border-[5px] border-solid border-[#f5f5f5] rounded-full object-cover cursor-move"
                                        onLoad={() => setLogoLoading(false)}
                                    />
                                ) : (
                                    <div className="h-full w-full border-[5px] border-solid border-[#f5f5f5] rounded-full bg-gray-200 flex items-center justify-center">
                                        <span className="text-gray-500 text-sm">Logo</span>
                                    </div>
                                )}
                            </div>


                        </div>
                        <div className='mt-[76px] lg:mt-[7rem]'>
                            <label htmlFor="text" className="wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5">
                                Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="text"
                                    placeholder='Enter your name'
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="flex  grow border px-4 py-2 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
                                    focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                                    border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                                />
                            </div>
                        </div>
                        <div className='my-5'>
                            <label htmlFor="email" className="wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5">
                                Email
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoComplete="email"
                                    placeholder='Enter your email'
                                    required
                                    disabled
                                    className='flex  text-[#a9a9bc] grow rounded-lg border px-4 py-2 text-sm leading-6 shadow-wg-xs
                                    hover:border-[#a9a9bc] dark:hover:border-surface-200
                                    border-[#d1d1db]
                                    transition-colors duration-100 outline-primary focus:outline focus:outline-2 focus:-outline-offset-2 cursor-not-allowed bg-surface-50 text-surface-300 placeholder:text-surface-300 dark:bg-white/5 dark:text-surface-200 dark:placeholder:text-surface-200 border-surface-200 dark:border-surface-100 focus:outline-indigo-600 h-[36px]'
                                />
                            </div>
                        </div>
                        {/* <div>
                            <label htmlFor="password" className="wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5">
                               Timezone
                            </label>
                            <div className="mt-2">
                                <DropDown previousValue={timeZone} onChangeValue={(e: string) => setTimezone(e)} data={timeZoneList} />
                            </div>
                        </div> */}
                        <div className='flex justify-start mt-8'>
                            <button
                                onClick={updateProfile}
                                type="submit"
                                className="flex  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                size="sm"
                            >

                                {profileLoading ? (
                                    <div className='flex items-center  gap-[6px]'>
                                        Update
                                        <div className="simple-spinner">
                                            <span></span>
                                        </div>
                                    </div>
                                ) : (
                                    'Update'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </main>

            <Transition.Root show={showCropModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setShowCropModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-y-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>

                                        <ReactCrop
                                            crop={crop}
                                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                                            onComplete={(c) => {
                                                setCompletedCrop(c);
                                            }}
                                            className='w-full'
                                            aspect={1}
                                        >
                                            <img src={cropSrc} alt="Crop preview" onLoad={onImageLoaded} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                        </ReactCrop>
                                        <canvas ref={canvasRef} className="hidden" />
                                    </div>
                                    <div className='flex justify-end mt-5'>
                                        <button
                                            onClick={handleCropSave}
                                            className='group inline-flex shrink-0 select-none items-center justify-center text-sm font-medium
    leading-6 transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 
    focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8  py-2 rounded-lg text-white outline-primary
    disabled:opacity-50 bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                                            disabled={logoLoading || !completedCrop}
                                        >
                                            {loading ? (
                                                <div className='flex items-center  gap-[6px]'>
                                                    Save
                                                    <div className="simple-spinner">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            ) : (
                                                'Save'
                                            )}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
export default UserProfile
