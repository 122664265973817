// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-menu {
  background: white;
  border: 1px solid #ccc;
  border-radius: 0.7rem;
  box-shadow: inset  0 0 0 1px rgb(243, 216, 216);

  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  overflow: auto;
  padding: 0.4rem;
  position: relative;
}

.dropdown-menu button {
  align-items: center;
  background-color: transparent;
  display: flex;
  gap: 0.25rem;
  text-align: left;
  width: 100%;
 color: black;
 cursor: pointer;
}

.dropdown-menu button:hover,
.dropdown-menu button:hover.is-selected {
  background-color: rgb(230, 230, 230);
}

.dropdown-menu button.is-selected {
  background-color:  rgb(230, 230, 230);
}`, "",{"version":3,"sources":["webpack://./src/extensions/variable/VariableList.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,qBAAqB;EACrB,+CAA+C;;EAE/C,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,cAAc;EACd,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,6BAA6B;EAC7B,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,WAAW;CACZ,YAAY;CACZ,eAAe;AAChB;;AAEA;;EAEE,oCAAoC;AACtC;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":[".dropdown-menu {\r\n  background: white;\r\n  border: 1px solid #ccc;\r\n  border-radius: 0.7rem;\r\n  box-shadow: inset  0 0 0 1px rgb(243, 216, 216);\r\n\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 0.1rem;\r\n  overflow: auto;\r\n  padding: 0.4rem;\r\n  position: relative;\r\n}\r\n\r\n.dropdown-menu button {\r\n  align-items: center;\r\n  background-color: transparent;\r\n  display: flex;\r\n  gap: 0.25rem;\r\n  text-align: left;\r\n  width: 100%;\r\n color: black;\r\n cursor: pointer;\r\n}\r\n\r\n.dropdown-menu button:hover,\r\n.dropdown-menu button:hover.is-selected {\r\n  background-color: rgb(230, 230, 230);\r\n}\r\n\r\n.dropdown-menu button.is-selected {\r\n  background-color:  rgb(230, 230, 230);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
