// import { Node, mergeAttributes } from '@tiptap/core';

// export const Column = Node.create({
//   name: 'column',

//   content: 'block+',

//   isolating: true,

//   addAttributes() {
//     return {
//       position: {
//         default: '',
//         parseHTML: element => element.getAttribute('data-position'),
//         renderHTML: attributes => ({ 'data-position': attributes.position }),
//       },
//       // Add layout attribute here
//       layout: {
//         default: 'two-column', // Provide a default value or empty string
//         parseHTML: element => element.getAttribute('data-layout') || 'two-column',
//         renderHTML: attributes => ({ 'data-layout': attributes.layout }),
//       },
//     };
//   },

//   renderHTML({ HTMLAttributes }) {
//     return [
//       'div', 
//       { 'data-type': 'columns', class: `layout-${HTMLAttributes.layout || 'two-column'}` }, 
//       0
//     ];
//   },

//   parseHTML() {
//     return [
//       {
//         tag: 'div[data-type="column"]',
//       },
//     ];
//   },
// });

// export default Column;


import { Node } from '@tiptap/core';

export const Column = Node.create({
  name: 'column',

  content: 'block+',

  isolating: true,

  addAttributes() {
    return {
      position: {
        default: 'left',
        parseHTML: element => element.getAttribute('data-position') || 'left',
        renderHTML: attributes => ({ 'data-position': attributes.position }),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    
    return [
      'div', 
      { 'data-type': 'column', 'data-position': HTMLAttributes['data-position'] || 'unknown' }, // Using bracket notation to access 'data-position'
      0
    ];
  },
  

  parseHTML() {
    return [
      {
        tag: 'div[data-type="column"]',
      },
    ];
  },
});

export default Column;
