// @ts-nocheck
import React, { Fragment, ReactNode, useCallback, useEffect, useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Listbox, ListboxButton, ListboxOptions, Transition } from '@headlessui/react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Dialog } from '@headlessui/react'
import { getEditorInstance, getMentionList } from '../../editor';
import { ChevronDownIcon } from '../../components/icons/Icons';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Switch } from '@headlessui/react'
import { createDocumentAction, createDocumentContact, fetchDocument, fetchDocumentAction, fetchDocumentContact, fetchDownloadPdfAction, updateDocument } from '../../features/document/documentSlice';
import { toast } from 'sonner';
import { fetchBoardsList } from '../../features/teams/teamsSlice';
import { formatDistance, format } from 'date-fns';
import { debounce, set } from 'lodash';
import { fetchTeamsMemberList } from '../../features/teamsMembers/teamsMemberSlice';
import { createDocumentActionData, fetchDocumentItem, fetchItemMemberItem } from '../../features/documentItem/documentItemSlice';
import { deleteItemInputDetails, fetchInputData, fetchItemInputData, fetchItemInputDetails } from '../../features/documentInput/documentInputSlice';
import { RootState } from '../../../app/store';
import { v4 as uuidv4 } from 'uuid';
import { fetchDocumentActivityItem } from '../../features/docuementItemActivity/docuementItemActivitySlice';
import { fetchTagsDataList } from '../../features/tags/tagsSlice';
import CustomMenuDropdown from '../../components/common/CustomMenuDropdown';
import TipTapEditor from '../../components/dashboard/TipTapEditor';
import SendDocumentDetailsAction from './sendDocumentDetail/SendDocumentDetailsAction';
import SendDocumentDetailsActivity from './sendDocumentDetail/SendDocumentDetailsActivity';
import SendDocumentDetailsChat from './sendDocumentDetail/SendDocumentDetailsChat';
import SendDocumentFill from './sendDocumentDetail/SendDocumentFill';
import { fetchExecuteItemList } from '../../features/documentItemFill/documentItemFillSlice';
import PendingAction from '../../components/documentDetail/PendingAction';
import SendPendingAction from './sendDocumentDetail/SendPendingAction';
import axios from 'axios';


interface DocumentDetailsProps {
  // Add any props you need
}

export interface DropdownItem {
  className?: string;
  icon?: ReactNode;
  label: string;
  shortcut?: any;
  disabled?: boolean;
  onClick?: () => void;
  href?: string; // Add href property with type string

}


export interface DropdownGroup {
  options: DropdownItem[];
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const variableTypeList = [
  { id: 1, name: 'Text', backendType: 'text' },
  { id: 2, name: 'Underline', backendType: 'underline' },
  { id: 3, name: 'Email', backendType: 'email' },
  { id: 4, name: 'Phone Number', backendType: 'phone_number' },
  { id: 5, name: 'Date', backendType: 'date' },
  { id: 6, name: 'Signature', backendType: 'signature' },
  { id: 7, name: 'Image', backendType: 'image' },
  { id: 8, name: 'File', backendType: 'file' },
  { id: 9, name: 'Checkbox', backendType: 'checkbox' },
];


const tabs = [
  { name: 'internal', icon: 'fas fa-user' },
  { name: 'external', icon: 'fas fa-users' },
];

const dropdownGroups: DropdownGroup[] = [
  {
    options: [
      {
        label: `Delete`,
        // icon: CustomUserIcon,
        // className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
        onClick: () => console.log('Delete clicked'),
      }
    ],
  },
];

const dropdownGroups1: DropdownGroup[] = [
  {
    options: [
      {
        label: `Delete`,
        // icon: CustomUserIcon,
        // className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
        onClick: () => console.log('Delete clicked'),
      }
    ],
  },
];



const SendDocument: React.FC<DocumentDetailsProps> = React.memo(() => {
  let editor = getEditorInstance()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isAsideOpen, setIsAsideOpen] = useState(true);  // Initially open first aside
  const [isAsideOpen1, setIsAsideOpen1] = useState(false);  // Initially close second aside
  const [collapsed, setCollapsed] = useState(false);
  const [collapsed1, setCollapsed1] = useState(false);
  const [variables, setVariables] = useState<string[]>([]); // Initialize an empty list
  const [expandedVariable, setExpandedVariable] = useState<string[]>([]);
  const [variableType, setVariableType] = useState({})
  const [openFeedbackTab, setOpenFeedbackTab] = useState('internal')
  const [email, setEmail] = useState('');
  const [baseMemberDetails, setBaseMemberDetails] = useState<any>([]);
  const [profile, setProfile] = useState('')
  const { baseId } = useParams<{ baseId: string }>(); // Extract baseId from URL
  const [isAsideOpen2, setIsAsideOpen2] = useState(false);  // Initially open first aside
  const [isAsideOpen3, setIsAsideOpen3] = useState(false);  // Initially close second aside
  const [isAsideOpen4, setIsAsideOpen4] = useState(false);
  const [collapsed2, setCollapsed2] = useState(false);
  const [collapsed3, setCollapsed3] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { data, fetchStatus, fetchError } = useAppSelector((state: RootState) => state.documentData);
  const [teams, setTeams] = useState<any>(null);
  const [selected, setSelected] = useState('')
  const [isEditing, setIsEditing] = useState(false);
  const [docName, setDocName] = useState<string>('');
  const [deleteSpanByNameFunction, setDeleteSpanByNameFunction] = useState(null);
  const [deleteSpanByNameFunctionLocal, setDeleteSpanByNameFunctionLocal] = useState(null);
  const [showDeleteConfirmation1, setShowDeleteConfirmation1] = useState(false);
  const [showDeleteConfirmation1Local, setShowDeleteConfirmation1Local] = useState(false);
  const [newAction, setNewAction] = useState(false)
  // Reference to the editor instance
  const [editorInstance, setEditorInstance] = useState(null);
  const [openActionTab, setOpenActionTab] = useState('internal team')
  const [isOpen, setIsOpen] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [newActionEnteral, setNewActionEnteral] = useState(false)
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [limit] = useState(25);
  const [search1, setSearch1] = useState('');
  const [page1, setPage1] = useState(1);
  const [limit1] = useState(25);
  const [baseMemberDetails2, setBaseMemberDetails2] = useState<any>([]);
  const [search2, setSearch2] = useState('');
  const [page2, setPage2] = useState(1);
  const [limit2] = useState(25);
  const [isOpen2, setIsOpen2] = useState(false);
  const [recipients2, setRecipients2] = useState([]);
  const [contactInfo2, setContactInfo2] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });
  const [baseMemberDetails3, setBaseMemberDetails3] = useState<any>([]);
  const [search3, setSearch3] = useState('');
  const [page3, setPage3] = useState(1);
  const [limit3] = useState(25);
  const [isOpen3, setIsOpen3] = useState(false);
  const [recipients3, setRecipients3] = useState([]);
  const [contactInfo3, setContactInfo3] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });
  const [contactLoading3, setContactLoading3] = useState(false)
  const [baseMemberDetails4, setBaseMemberDetails4] = useState<any>([]);
  const [search4, setSearch4] = useState('');
  const [page4, setPage4] = useState(1);
  const [limit4] = useState(25);
  const [isOpen4, setIsOpen4] = useState(false);
  const [recipients4, setRecipients4] = useState([]);
  const [contactInfo4, setContactInfo4] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });

  const [baseMemberDetails5, setBaseMemberDetails5] = useState<any>([]);

  const [search5, setSearch5] = useState('');
  const [page5, setPage5] = useState(1);
  const [limit5] = useState(25);
  const [isOpen5, setIsOpen5] = useState(false);
  const [recipients5, setRecipients5] = useState([]);
  const [contactInfo5, setContactInfo5] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });


  const [baseMemberDetailsData, setBaseMemberDetailsData] = useState<any>([]);
  const [variableType1, setVariableType1] = useState(null);

  const [baseMemberDetailsData1, setBaseMemberDetailsData1] = useState<any>([]);
  const [variableType2, setVariableType2] = useState(baseMemberDetailsData1[0]?.name || null);

  const [baseMemberDetailsData2, setBaseMemberDetailsData2] = useState<any>([]);
  const [variableType3, setVariableType3] = useState(baseMemberDetailsData2[0]?.name || null);

  const [baseMemberDetailsData3, setBaseMemberDetailsData3] = useState<any>([]);
  const [variableType4, setVariableType4] = useState(baseMemberDetailsData3[0]?.name || null);

  const [baseMemberDetailsData4, setBaseMemberDetailsData4] = useState<any>([]);
  const [variableType5, setVariableType5] = useState(baseMemberDetailsData4[0]?.name || null);

  const [baseMemberDetailsData5, setBaseMemberDetailsData5] = useState<any>([]);
  const [variableType6, setVariableType6] = useState(baseMemberDetailsData5[0]?.name || null);
  const [isAsideOpen5, setIsAsideOpen5] = useState(false);
  const [toogleCollapseMem, setToogleCollapseMem] = useState(false);
  const [newActionMember, setNewActionMember] = useState(false)

  const [baseMemberDetailsData6, setBaseMemberDetailsData6] = useState<any>([]);
  const [variableType7, setVariableType7] = useState(baseMemberDetailsData6[0]?.name || null);
  const [isActionMemberLoading, setIsActionMemberLoading] = useState(false)
  const [toogleCollapseMemExternal, setToogleCollapseMemExternal] = useState(false);
  const [newActionMemberExternal, setNewActionMemberExternal] = useState(false)
  const [isOpenExternal, setIsOpenExternal] = useState(false);
  const [selectedRecipientExternal, setSelectedRecipientExternal] = useState('');
  const [searchExternal, setSearchExternal] = useState('');
  const [pageExternal, setPageExternal] = useState(1);
  const [limitExternal] = useState(25);
  const [recipientsExternal, setRecipientsExternal] = useState([]);
  const [newActionEnteralData, setNewActionEnteralData] = useState(false)
  const [contactLoadingExternal, setContactLoadingExternal] = useState(false)
  const [contactInfoExternal, setContactInfoExternal] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });

  const [isOverflowHidden, setIsOverflowHidden] = useState(true);
  const [isActionList, setIsActionList] = useState(null);
  const [isInputList, setIsInputList] = useState(null)
  const [baseMemberDetails1, setBaseMemberDetails1] = useState<any>([]);
  const [recipients1, setRecipients1] = useState([]);
  const [selectedRecipientInput, setSelectedRecipientInput] = useState('');
  const [isOpenInput, setIsOpenInput] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [recipientsInput, setRecipientsInput] = useState([]);
  const inputSequenceRef = useRef(1);
  const [inputLoading, setInputLoading] = useState<Record<string, boolean>>({});
  const [isInputChanged, setIsInputChanged] = useState(false);
  const [fetchInputList, setFetchInputList] = useState(null)
  const [selectedBaseMember, setSelectedBaseMember] = useState(null);
  const [userName, setUserName] = useState(null)
  const [matchedMembers, setMatchedMembers] = useState([]);
  const [variableTypeLocal, setVariableTypeLocal] = useState(
    Array(variables.length).fill(variableTypeList[0])
  );
  const [variableFromLocal, setVariableFromLocal] = useState<string[]>([]);
  const [variableTypes, setVariableTypes] = useState<any[]>([]); // Store selected values for each index
  const [memberColor, setMemberColor] = useState(null)
  const [itemActivity, setItemActivity] = useState(null)
  const [itemActivityLoading, setItemActivityLoading] = useState(false)
  const [itemActivityChat, setItemActivityChat] = useState(null)
  const [tagsList, setTagsList] = useState({ data: [] });
  const [selectedTags, setSelectedTags] = useState('')
  const [tagsOpen, setTagsOpen] = useState(false)
  const [searchTags, setSearchTags] = useState('');
  const [channelOpen, setChannelOpen] = useState(false)
  const [searchChannel, setSearchChannel] = useState('');
  // State for internal and external members
  const [internalMembers, setInternalMembers] = useState([]);
  const [externalMembers, setExternalMembers] = useState([]);
  const navigate = useNavigate();
  const [inputLoadingSpan, setInputLoadingSpan] = useState(false)
  // State for specific member details
  const [selectedInternalMember, setSelectedInternalMember] = useState([]);

  const [selectedExternalMember, setSelectedExternalMember] = useState([]);
  const [isEditingEditor, setIsEditingEditor] = useState(false);
  const [isAsideOpen6, setIsAsideOpen6] = useState(false);
  const [inputFill, setInputFill] = useState('')
  const [itemFillLoading, setItemFillLoading] = useState(false)
  const [actionWithFilter, setActionWithFilter] = useState('')
  const [sendPendingLoading, setSendPendingLoading] = useState(false)
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  const handleInputChange = () => {
    setIsInputChanged(true);
  };

  const handleConfirmDelete = () => {
    setShowDeleteConfirmation(true);
  }

  const toggleCollapseMember = () => {
    setToogleCollapseMem(!toogleCollapseMem);
  };

  const handleNewActionMember = () => {
    setNewActionMember(!newActionMember)
  }

  const toggleCollapseMemberExternal = () => {
    setToogleCollapseMemExternal(!toogleCollapseMemExternal);
  };

  const handleNewActionMemberExternal = () => {
    setNewActionMemberExternal(!newActionMemberExternal)
  }



  const handleFirstBar = async () => {
    setIsAsideOpen(!isAsideOpen);  // Open first aside
    setIsAsideOpen1(false); // Close second aside
    setIsAsideOpen2(false);
    setIsAsideOpen3(false);
    setIsAsideOpen4(false);
    setCollapsed1(false);  // Reset collapse state for second aside
    setIsAsideOpen5(false);
    setIsAsideOpen6(false);
    try {
      const fetchResponse = await dispatch(
        fetchDocumentAction({ baseId, id, type: "true" }) // Pass "true" as type
      ).unwrap();
      setActionWithFilter(fetchResponse);
    } catch (error) {
      console.error("Error fetching base member details:", error);
    }
  };

  const handleSecondBar = () => {
    setIsAsideOpen(false); // Close first aside
    setIsAsideOpen2(false);
    setIsAsideOpen3(false);
    setIsAsideOpen4(false);
    setIsAsideOpen1(!isAsideOpen1);  // Open second aside
    setCollapsed(false);    // Reset collapse state for first aside
    setIsAsideOpen5(false);
    setIsAsideOpen6(false);
  };

  const handleActivity = async () => {
    setIsAsideOpen2(!isAsideOpen2); // Open first aside
    setIsAsideOpen3(false); // Close second aside
    setIsAsideOpen(false);
    setIsAsideOpen1(false);
    setIsAsideOpen4(false);
    setIsAsideOpen5(false);
    setCollapsed3(false);
    setIsAsideOpen6(false);

    // Start loading
    setItemActivityLoading(true);

    try {
      const res = await dispatch(fetchDocumentActivityItem({
        baseId,
        id,
        page: 1,  // Explicitly set initial page
        limit: 25
      }));

      if (res.type.endsWith('fulfilled')) {
        setItemActivity(res.payload);
      } else {
        throw new Error(res.error?.message || 'Unknown error occurred');
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    } finally {
      setItemActivityLoading(false);
    }
  };

  const handleChat = async () => {
    setIsAsideOpen(false); // Close first aside
    setIsAsideOpen3(!isAsideOpen3);  // Open second aside
    setIsAsideOpen2(false);
    setIsAsideOpen1(false);
    setIsAsideOpen5(false);
    setIsAsideOpen4(false);
    setCollapsed2(false);
    setIsAsideOpen6(false);
    // Start loading
    setItemActivityLoading(true);

    try {
      const res = await dispatch(fetchDocumentActivityItem({
        baseId,
        id,
        activity_type: 'messaged', // Sending the activity_type
        page: 1,  // Explicitly set initial page
        limit: 25
      }));

      if (res.type.endsWith('fulfilled')) {
        setItemActivityChat(res.payload);
      } else {
        throw new Error(res.error?.message || 'Unknown error occurred');
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    } finally {
      setItemActivityLoading(false);
    }
  };


  const handleAction = async () => {
    setIsAsideOpen(false); // Close first aside
    setIsAsideOpen4(!isAsideOpen4);  // Open second aside
    setIsAsideOpen2(false);
    setIsAsideOpen3(false);
    setIsAsideOpen1(false);
    setIsAsideOpen5(false);
    setIsAsideOpen6(false);
    try {
      const fetchResponse = await dispatch(
        fetchDocumentAction({ baseId, id })
      ).unwrap();
      setIsActionList(fetchResponse);
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };


  const handleMember = async () => {
    setIsAsideOpen(false); // Close first aside
    setIsAsideOpen4(false);
    setIsAsideOpen3(false);
    setIsAsideOpen2(false);
    setIsAsideOpen1(false);
    setIsAsideOpen5(!isAsideOpen5);
    setIsAsideOpen6(false);
    try {
      //member
      const res = await dispatch(fetchItemMemberItem({ baseId, id }));
      if (res.type.endsWith('fulfilled')) {
        setIsActionListNew(res.payload);
      } else {
        throw new Error(res.error?.message || 'Unknown error occurred');
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  const handleFill = async () => {
    setIsAsideOpen(false); // Close first aside
    setIsAsideOpen5(false);
    setIsAsideOpen4(false);
    setIsAsideOpen3(false);
    setIsAsideOpen2(false);
    setIsAsideOpen1(false);
    setIsAsideOpen6(!isAsideOpen6);
    setItemFillLoading(true)
    try {
      //member
      const res = await dispatch(fetchExecuteItemList({ baseId, id }));
      if (res.type.endsWith('fulfilled')) {
        setInputFill(res.payload.data);
      } else {
        throw new Error(res.error?.message || 'Unknown error occurred');
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    } finally {
      setItemFillLoading(false)
    }
  };


  useEffect(() => {
    const fetchVariables = () => {
      const updatedList = getMentionList();
      setVariables([...updatedList]); // Update the list
      setVariableTypes(new Array(updatedList.length).fill(null)); // Initialize the `variableTypes` array with null
    };

    fetchVariables(); // Initial fetch


    // Example of how to trigger updates; replace with your actual triggers if needed
    const interval = setInterval(fetchVariables, 1000); // Check every second (adjust as needed)

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);


  const variants = {
    hidden: { opacity: 1, y: 0 },
    visible: { opacity: 1, y: 0 },
  };

  const [expandedVariable1, setExpandedVariable1] = useState<string | null>(null);

  const toggleAccordionLocal = (variable: string) => {
    setExpandedVariable1(prev => (prev === variable ? null : variable));
  };


  const toggleAccordion = (variable: string) => {
    setExpandedVariable(prev => (prev === variable ? null : variable))
    // setExpandedVariable(prev =>
    //   prev.includes(variable) ? prev.filter(item => item !== variable) : [...prev, variable]
    // );
  };

  useEffect(() => {
    if (baseId) {
      const fetchData = async () => {
        const storedBaseMember = localStorage.getItem('baseMembersDetails');
        if (storedBaseMember) {
          setBaseMemberDetails(JSON.parse(storedBaseMember));
          setBaseMemberDetails1(JSON.parse(storedBaseMember));
          setBaseMemberDetails2(JSON.parse(storedBaseMember));
          setBaseMemberDetails3(JSON.parse(storedBaseMember));
          setBaseMemberDetails4(JSON.parse(storedBaseMember));
          setBaseMemberDetails5(JSON.parse(storedBaseMember));
        } else {
          try {
            const baseResponse = await dispatch(baseMemberDetailsData(baseId));
            setBaseMemberDetails(baseResponse.payload);
            setBaseMemberDetails1(baseResponse.payload);
            setBaseMemberDetails2(baseResponse.payload);
            setBaseMemberDetails3(baseResponse.payload);
            setBaseMemberDetails4(baseResponse.payload);
            setBaseMemberDetails5(baseResponse.payload);
          } catch (error) {
            console.error("Error fetching base member details:", error);
          }
        }
      };
      fetchData();
    }
  }, [baseId, dispatch]);


  const handleToggle = (index) => {
    setFetchInputList((prevList) =>
      prevList.map((item, idx) =>
        idx === index ? { ...item, input_mandatory: !item.input_mandatory } : item
      )
    );
    handleInputChange();
  };

  const handleToggleLocal = () => {
    setIsEnabled(!isEnabled);
    handleInputChange();
  };




  const handleChangeChannel = async (tag) => {
    setSelected(tag);
    setChannelOpen(false)
    setSearchChannel('');
    try {
      const payload = {
        id: id, // Ensure this is correct for your API
        baseId,
        channel_id: tag.id,
      };
      const result = await dispatch(updateDocument(payload)).unwrap();
      toast.success('Channel updated successfully');
    } catch (error) {
      console.error('Failed to update channel:', error);
      toast.error('Failed to update channel');
    }
  };

  const [documentDetailsSave, setDocumentDetailsSave] = useState('')


  useEffect(() => {
    if (baseId) {
      const fetchTeams = async () => {
        const storedBase = localStorage.getItem('baseTeamsData');
        if (storedBase) {
          const parsedData = JSON.parse(storedBase);
          setTeams(parsedData.data);
          // setSelected(parsedData.data[0]); 
        } else {
          try {
            const baseResponse = await dispatch(fetchBoardsList(baseId));
            setTeams(baseResponse.payload.data);
            setSelected(baseResponse.payload.data[0]); // Set default
          } catch (error) {
            console.error('Error fetching teams:', error);
          }
        }
      };
      fetchTeams();
    }
  }, [baseId, dispatch]);


  // Fetch document details and synchronize channel
  useEffect(() => {
    if (baseId && id) {
      const fetchData = async () => {
        try {
          const res = await dispatch(fetchDocument({ baseId, id })).unwrap();
          setDocumentDetailsSave(res.data);

          // Match fetched channel_id with teams
          if (res.data.channel_id && teams?.length > 0) {
            const matchingTeam = teams.find((team) => team.id === res.data.channel_id);
            if (matchingTeam) {
              setSelected(matchingTeam);
            }
          }
          // Match fetched channel_id with teams
          if (res.data.doc_tag && tagsList?.data?.length > 0) {
            const matchingTags = tagsList?.data.find((tag) => tag.id == res.data.doc_tag);
            if (matchingTags) {
              setSelectedTags(matchingTags);
            }
          }
        } catch (err) {
          console.error('Failed to fetch document', err);
        }
      };
      fetchData();
    }
  }, [dispatch, baseId, id, teams, tagsList]);


  const createdTimeAgoSidebar = documentDetailsSave?.doc_createdat
    ? formatDistance(new Date(documentDetailsSave?.doc_createdat), new Date(), { includeSeconds: true }).replace(/^about\s/, '')
    : "";

  const createTimeTooltip = documentDetailsSave?.doc_createdat
    ? format(new Date(documentDetailsSave.doc_createdat), 'd MMMM yyyy, h:mm a')
    : '';

  const updatedTimeAgoSidebar = documentDetailsSave?.doc_lastEditAt
    ? formatDistance(new Date(documentDetailsSave?.doc_lastEditAt), new Date(), { includeSeconds: true }).replace(/^about\s/, '')
    : "";

  const updatedTimeTooltip = documentDetailsSave?.doc_lastEditAt
    ? format(new Date(documentDetailsSave.doc_lastEditAt), 'd MMMM yyyy, h:mm a')
    : '';


  // update doc name 
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDocName(e.target.value);
  };

  const handleNameClick = () => {
    setIsEditing(true); // Switch to editing mode when the user clicks the document name
  };

  useEffect(() => {
    if (data?.data?.doc_name) {
      setDocName(data.data.doc_name);
    }
  }, [data]);


  const handleNameBlur = async () => {
    if (docName) {
      try {
        const payload = {
          id: id,
          baseId: baseId,
          name: docName,
        };
        const result = await dispatch(updateDocument(payload)).unwrap();
        toast.success('Document Name Updated successfully');
      } catch (error) {
        console.error('Failed to save document:', error);
        toast.error('Failed to update document name');
      }
    }
    setIsEditing(false);
  };


  const handleColorChange = () => {
    if (!editorInstance?.setMentionsColor) {
      console.warn('Editor instance or color change method not available');
      return;
    }

    // Call the setMentionsColor method with your desired color
    editorInstance.setMentionsColor('#ff0000'); // or any other color
  };

  const handleEditorReady = (editor, deleteSpanByName) => {
    setEditorInstance(editor);
    setDeleteSpanByNameFunction(() => deleteSpanByName); // Set deleteSpanByName function
    setDeleteSpanByNameFunctionLocal(() => deleteSpanByName);
  };

  // Function to handle the deletion process based on count
  const handleDeleteClickLocal = (name, count) => {
    if (count === 0) {
      // Directly delete if count is 0
      handledeleteSpanByNameLocal(name);
    } else {
      // Show confirmation dialog if count is 1 or more
      setShowDeleteConfirmation1Local(true);
    }
  };

  const handleDeleteClick = (inputId, name, count) => {
    if (count === 0) {
      // Directly delete if count is 0
      handledeleteSpanByName(inputId, name);
    } else {
      // Show confirmation dialog if count is 1 or more
      setShowDeleteConfirmation1(true);
    }
  };



  const handledeleteSpanByNameLocal = (name) => {
    if (deleteSpanByNameFunctionLocal) {
      deleteSpanByNameFunctionLocal(name);
      setShowDeleteConfirmation1Local(false); // Close dialog after deletion
    } else {
      console.warn("Delete function is not available");
    }
  };

  const handledeleteSpanByName = async (
    inputId: string,
    name: string,
    payload: { id: string; baseId: number; doc_content: string } // Payload for updateDocument
  ) => {
    if (!deleteSpanByNameFunction) {
      console.warn("Delete function is not available");
      return;
    }

    setInputLoadingSpan(true)
    // Call the provided function for custom deletion logic
    deleteSpanByNameFunction(name);

    try {
      // Proceed to delete the item via API
      await dispatch(deleteItemInputDetails({ baseId, inputId })).unwrap();
      toast.success(`Item "${name}" deleted successfully!`);
      const htmlContent = editorInstance.getHTML();
      const jsonContent = editorInstance.getJSON();
      const payload = {
        id: id,
        baseId: baseId,
        doc_content: JSON.stringify(jsonContent)
      };

      await dispatch(updateDocument(payload)).unwrap();
      const res = await dispatch(fetchItemInputDetails({ baseId, id }));
      setFetchInputList(res.payload.data);
    } catch (error: unknown) {
      console.error(`Failed to process "${name}":`, error);
      toast.error(`Failed to process "${name}": ${error}`);
    } finally {
      setShowDeleteConfirmation1(false);
      setInputLoadingSpan(false)
    }
  };




  const handleNewAction = () => {
    setNewAction(!newAction)
  }


  const handleNewActionEnteral = () => {
    setNewActionEnteral(true)
    setIsOpen(false)
    setOpenActionTab('external contact')
  }


  const debouncedSearch = useCallback(
    debounce((searchTerm: string) => {
      setPage(1);
      dispatch(fetchDocumentContact({
        baseId,
        page: 1,
        limit,
        search: searchTerm
      }));
    }, 500),
    [dispatch, baseId, limit]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    debouncedSearch(searchTerm);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchDocumentContact({
          baseId,
          page,
          limit,
          search
        })).unwrap();

        setRecipients(response.data.contact);
        setRecipients1(response.data.contact);
        setRecipients2(response.data.contact);
        setRecipients3(response.data.contact);
        setRecipients4(response.data.contact);
        setRecipients5(response.data.contact);
        setRecipientsExternal(response);
      } catch (error) {
        console.error("Error fetching document contacts:", error);
      }
    };

    fetchData();
  }, [dispatch, baseId, page, limit, search,
    limit1, limit2, limit3, limit4, limit5, limitExternal, search1,
    search2, search3, search4, search5, searchExternal, page1, page2, page3, page4, page5, pageExternal]);


  const [teamMemberFetched, setTeamMemberFetched] = useState<any[]>([]);

  useEffect(() => {
    if (baseId && selected?.id) { // Add check for channel_id
      const fetchDataone = async () => {
        try {
          // Pass channel_id directly, not as an object
          const teamMemberResponse = await dispatch(
            fetchTeamsMemberList({ baseId, teamId: selected.id })
          ).unwrap();

          setTeamMemberFetched(teamMemberResponse?.data || []);
        } catch (error) {
          console.error("Error fetching base member details:", error);
        }
      };
      fetchDataone();
    }
  }, [baseId, dispatch, selected]);


  const handleInputChangeExternal = (e) => {
    const { name, value } = e.target;
    setContactInfoExternal((prevState) => ({ ...prevState, [name]: value }));
  };


  // fetchDocumentAction api 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await dispatch(fetchDocumentAction({ baseId, id }));
        if (res.type.endsWith('fulfilled')) {
          setIsActionList(res.payload); // Set only the payload if successful
        } else {
          throw new Error(res.error?.message || 'Unknown error occurred'); // Handle the error from `rejectWithValue`
        }
      } catch (error) {
        console.error('Error fetching document:', error);
        // Optionally, you can set an error state here or show a message to the user
      }
    };

    fetchData();
  }, [baseId, id, dispatch]);

  const [isActionListNew, setIsActionListNew] = useState(null);

  useEffect(() => {
    const fetchDataNew = async () => {
      try {
        // members
        const res = await dispatch(fetchItemMemberItem({ baseId, id }));
        if (res.type.endsWith('fulfilled')) {
          setIsActionListNew(res.payload);
          const { internal, external } = res.payload.data;
          // Set internal members
          setInternalMembers(internal);

          // Set external members
          setExternalMembers(external);

          // Process internal members from local storage
          processInternalMembersFromStorage(internal);

          // Process external members from local storage
          processExternalMembersFromStorage(external);

        } else {
          throw new Error(res.error?.message || 'Unknown error occurred'); // Handle the error from `rejectWithValue`
        }
      } catch (error) {
        console.error('Error fetching document:', error);
        // Optionally, you can set an error state here or show a message to the user
      }
    };

    fetchDataNew();
  }, [baseId, id, dispatch]);


  // Process internal members from local storage
  const processInternalMembersFromStorage = (internalMembers) => {
    const baseLocalStorageData = localStorage.getItem('baseMembersDetails');
    if (!baseLocalStorageData) {
      setSelectedInternalMember([]);
      return;
    }

    const parsedBaseData = JSON.parse(baseLocalStorageData);
    const matchedInternalMembers = internalMembers
      .map(member => parsedBaseData.data.baseMembers.find(baseData => baseData.id == member.member_id))
      .filter(Boolean);
    setSelectedInternalMember(matchedInternalMembers);
  };


  // Process external members from local storage
  const processExternalMembersFromStorage = (externalMembers) => {
    // Filter members with valid content and content.id
    const matchedExternalMembers = externalMembers
      .map((member) => member.content)
      .filter((content) => content && content.id);

    // Update the state with matched external members
    if (matchedExternalMembers.length > 0) {
      setSelectedExternalMember(matchedExternalMembers);
    }
  };



  useEffect(() => {
    const fetchDataNew = async () => {
      try {
        const res = await dispatch(fetchItemInputDetails({ baseId, id }));
        if (res.type.endsWith('fulfilled')) {
          const inputList = res.payload.data;
          setFetchInputList(inputList);

          // Initialize variableType with default values from input list
          const initialVariableType = {};
          inputList.forEach(item => {
            initialVariableType[item.id] = variableTypeList.find(v => v.backendType === item.input_type) || variableTypeList[0];
          });
          setVariableType(initialVariableType); // Set the initial variable type state
        } else {
          throw new Error(res.error?.message || 'Unknown error occurred');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchDataNew();
  }, [baseId, id, dispatch]);



  // base team channel data
  const [baseMemberDetailsArray, setBaseMemberDetailsArray] = useState<any[]>([]);


  const handlebaseChannelList = () => {
    const baseMembers = baseMemberDetails?.data?.baseMembers || [];
    const matchingMembers = teamMemberFetched.filter(teamMember => {
      return baseMembers.some(baseMember => baseMember.id === teamMember.user_id);
    });

    const matchingIds = matchingMembers.map(member => ({
      base_member_details: baseMembers.find(baseMember =>
        baseMember.id === member.user_id
      )
    }));

    const filteredMembers = matchingIds.map(item => item.base_member_details);
    setBaseMemberDetailsArray(filteredMembers);

    // Remove overflow-hidden
    setIsOverflowHidden(false);
  };


  const handleActionInternalMember = async () => {
    try {
      setIsActionMemberLoading(true);
      if (!variableType7?.id) {
        toast.error("Please select an internal team.");
        return;
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        // primary_action: "collab",
        member_type: "internal",
        internal_member_id: String(variableType7.id),
      };

      // Dispatch the action to create a document action
      const result = await dispatch(
        createDocumentActionData({ baseId, actionPayload })
      ).unwrap();

      // Check the response status
      if (result.status === 200) {
        // members
        const fetchResponse = await dispatch(
          fetchItemMemberItem({ baseId, id })
        ).unwrap();

        setIsActionListNew(fetchResponse);
        toast.success("Member add successfully!");
        setNewActionMember(false);
      } else {
        throw new Error("Failed to add member");
      }
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : "Failed to add member";
      toast.error(err || errorMessage);
    } finally {
      setIsActionMemberLoading(false);
    }
  };


  const handleRecipientSelectExternal = (recipient) => {
    setSelectedRecipientExternal(recipient);
    setIsOpenExternal(false);
    setSearchExternal(''); // Clear the search term when selecting a recipient
    // setNewActionMemberExternal(false);
  };

  const handleNewActionEnteralData = () => {
    setNewActionEnteralData(true)
    setIsOpenExternal(false)

  }


  const handleSearchChangeExternal = (e) => {
    const searchTerm = e.target.value;
    setSearchExternal(searchTerm);
    dispatch(fetchDocumentContact({
      baseId,
      page: 1,
      limitExternal,
      search: searchTerm
    }));
  };


  const handleActionExternalContactData = async () => {
    try {
      setContactLoadingExternal(true);

      if (!selectedRecipientExternal?.id) {
        toast.error("Please select an external contact.");
        return; // Stop the function if external_contact_id is missing
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        member_type: "external",
        external_member_id: selectedRecipientExternal.id
      };

      const result = await dispatch(createDocumentActionData({
        baseId,
        actionPayload
      })).unwrap();

      // Check the response status
      if (result.status === 200) {
        // members
        const fetchResponse = await dispatch(
          fetchItemMemberItem({ baseId, id })
        )

        setIsActionListNew(fetchResponse.payload)
        // setIsActionList(fetchResponse);
        toast.success("Action created successfully!");
        setNewActionMember(false);
        setNewActionMemberExternal(false);
      } else {
        throw new Error("Failed to collect action");
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to process action';
      toast.error(err || errorMessage);

    } finally {
      setContactLoadingExternal(false);
    }
  };



  const [contactLoadingExternal1, setContactLoadingExternal1] = useState(false)
  const [showExternalData, setShowExternalData] = useState(false)

  // document action
  const handleDocumentContactSaveExternal = async (e) => {
    e.preventDefault();
    setContactLoadingExternal1(true);

    if (contactInfoExternal.contact_email.trim().length === 0) {
      toast.error("Email is required");
      setContactLoadingExternal1(false);
      return;
    }

    const payload = {
      contact_first_name: contactInfoExternal.contact_first_name,
      contact_last_name: contactInfoExternal.contact_last_name,
      contact_email: contactInfoExternal.contact_email,
      contact_phone: contactInfoExternal.contact_phone,
      contact_company: contactInfoExternal.contact_company,
      contact_job_title: contactInfoExternal.contact_job_title,
      item_type: "doc",
      item_id: id
    };

    try {
      const response = await dispatch(
        createDocumentContact({
          data: payload,
          userId: baseId,
        })
      );

      if (response?.meta?.requestStatus === "fulfilled") {
        setContactInfoExternal({
          contact_first_name: "",
          contact_last_name: "",
          contact_email: "",
          contact_phone: "",
          contact_company: "",
          contact_job_title: "",
        });
        toast.success("Contact create successfully!");
        setNewActionMemberExternal(false);

        if (response?.payload?.data) {
          setSelectedRecipientExternal(response?.payload?.data);
        }

        // Call fetchDocumentContact again
        // members
        const fetchResponse = await dispatch(fetchItemMemberItem({ baseId, id }));


        // setNewActionMemberExternal(true)

        if (fetchResponse.payload?.data?.external) {
          setShowExternalData(true);
          // Update the recipients list
          // setRecipientsExternal(fetchResponse.payload);
          setIsActionListNew(fetchResponse.payload);

          setNewActionEnteralData(false);
          setNewActionMemberExternal(false);

          // Show newActionMemberExternal after fetching updated contacts
          // setNewActionMemberExternal(true);
        } else {
          console.error("Contacts data not found after fetching.");
          toast.error("Failed to fetch updated contacts.");
        }
      } else if (response?.meta?.requestStatus === "rejected") {
        toast.error(response?.payload || "Contact update request was rejected.");
      } else {
        toast.error("Failed to update contact.");
      }
    } catch (error) {
      console.error("Failed to save contact:", error);
      toast.error(`An error occurred while saving: ${error.message || error}`);
    } finally {
      setContactLoadingExternal1(false);
    }
  };

  useEffect(() => {
    if (openFeedbackTab === 'internal') {
      handlebaseInputlist();
    }
  }, [openFeedbackTab]);

  const [isInputListExternal, setIsInputListExternal] = useState(null)
  const handlebaseInputlistExternal = async () => {
    try {
      // input
      const res = await dispatch(fetchDocumentItem({ baseId, id, type: 'external' }));

      if (res.type.endsWith('fulfilled')) {
        setRecipientsInput(res.payload.data.external);



      } else {
        throw new Error(res.error?.message || 'Unknown error occurred');
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  const handleTabClick = (tabName) => {
    setOpenFeedbackTab(tabName);
    if (tabName === 'external') {
      handlebaseInputlistExternal();
    }
  };

  const handlebaseInputlist = async () => {
    try {
      // inputs
      const res = await dispatch(fetchItemMemberItem({ baseId, id, type: 'internal' }));

      if (res.type.endsWith('fulfilled')) {
        setIsInputList(res.payload);

        const firstInternalItem = res.payload.data?.internal.find(
          (item) => item.member_id
        );

        if (firstInternalItem) {
          const baseMember = baseMemberDetails?.data?.baseMembers?.find(
            (member) => member.id == firstInternalItem.member_id
          );



          // Set the variable type to the base member if found
          if (baseMember) {
            setVariableType1(baseMember);
          }
        }
      } else {
        throw new Error(res.error?.message || 'Unknown error occurred');
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  // console.log(variableType1,"variabletypes1");


  useEffect(() => {
    const storedBaseMemberDetails = JSON.parse(localStorage.getItem('baseMembersDetails'));

    if (Array.isArray(fetchInputList) && fetchInputList.length > 0 && storedBaseMemberDetails?.data?.baseMembers) {

      // Filter to match internal_user_id with baseMembers in localStorage
      const matchedItems = fetchInputList.filter((item) => {
        return item.internal_user_id !== null && storedBaseMemberDetails?.data?.baseMembers.some(
          (member) => member.id === item.internal_user_id
        );
      });

      if (matchedItems.length > 0) {
        // Collect all matched members from localStorage
        const matchedMembers = matchedItems.map((item) => {
          return storedBaseMemberDetails.data.baseMembers.find(
            (member) => member.id === item.internal_user_id
          );
        });

        // console.log(matchedMembers,"match");

        // Set all matched members to variableType1
        setVariableType1(matchedMembers);
      }
    }
  }, [fetchInputList]);


  // console.log(variableType1,"jkhgfkh");


  // console.log(variableType1,"fetchinput");


  const [limitInput] = useState(25);
  const [newActionEnteralInput, setNewActionEnteralInput] = useState(false)
  const [isActionLoadingInput, setIsActionLoadingInput] = useState(false)

  const handleSearchChangeInput = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchInput(searchTerm);
  };

  // Filter recipients based on the search input
  const filteredRecipients = recipientsInput.filter((recipient) => {
    const fullName = `${recipient.contact_first_name || ''} ${recipient.contact_last_name || ''}`.toLowerCase();
    const email = (recipient.contact_email || '').toLowerCase();
    return fullName?.includes(searchInput) || email?.includes(searchInput);
  });


  const handleRecipientSelectInput = (recipient) => {
    setSelectedRecipientInput(recipient);
    setIsOpenInput(false);
    setSearchInput(''); // Clear the search term when selecting a recipient
    setNewActionEnteralInput(false);
    handleInputChange();
  };

  const handleNewActionEnteralInput = () => {
    setNewActionEnteralInput(true)
    setIsOpenInput(false)
    setOpenFeedbackTab('external')
  }

  const handleActionExternalContactInput = async () => {
    try {
      setIsActionLoadingInput(true);
      if (!selectedRecipientInput?.id) {
        toast.error("Please select an external contact.");
        return; // Stop the function if external_contact_id is missing
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "collab",
        external_contact_id: selectedRecipientInput.id
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      if (result.status === 200) {
        const fetchResponse = await dispatch(
          fetchDocumentAction({ baseId, id })
        ).unwrap();

        setIsActionList(fetchResponse);
        toast.success("Action created successfully!");
        // setNewAction(false);
      } else {
        throw new Error("Failed to collect action");
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'action already exists';
      toast.error(err || errorMessage);
    } finally {
      setIsActionLoading(false);
    }
  };



  const handleEnterClick = async (variableName: string, variableCount: number, itemId: string, itemMemberColour) => {


    try {
      setInputLoading((prev) => ({ ...prev, [itemId]: true }))

      // Check if the item already exists in fetchInputList
      const existingItem = fetchInputList.find(
        (item) => item.input_title.toLowerCase() === variableName.toLowerCase()
      );

      // Use existing UUID if the item exists, otherwise generate a new UUID
      const itemId = existingItem ? existingItem.id : uuidv4();

      // Calculate input_sequence based on the current state or a counter
      const inputSequence = inputSequenceRef.current; // Use a ref to track sequence
      const baseMemberId = selectedBaseMember ? selectedBaseMember.id : null; // Get ID from selected baseMember
      const inputMandatory = existingItem ? existingItem.input_mandatory : false;
      const currentInputType = variableType[itemId] || variableTypeList[0];

      // Retrieve member colour for the item
      // Find the board item corresponding to the current input
      const currentBoard = isInputList.data.internal.find(
        board => board.member_id == baseMemberId
      );



      const payload = {
        id: itemId, // Use existing ID if updating, or a new one if creating
        base_id: Number(baseId),
        item_id: id,
        item_type: 'doc',
        input_title: variableName,
        input_variable: variableName,
        input_count: variableCount,
        input_type: currentInputType.backendType,
        input_options: '',
        input_parameter: '',
        input_mandatory: inputMandatory, // Use dynamic value
        input_sequence: inputSequence, // Use dynamic sequence
        input_status: 'pending',
        item_sync: isInputChanged,
        external_contact_id: selectedRecipientInput.id,
        internal_user_id: baseMemberId,
        item_member_colour: currentBoard.member_colour
      };

      // Dispatch the fetchInputData thunk with baseId and payload
      const result = await dispatch(
        fetchItemInputData({
          baseId,
          payload,
        })
      ).unwrap();

      // Increment the sequence for the next input if it's a new entry
      if (!existingItem) {
        inputSequenceRef.current += 1;
      }

      // Reset the input changed state after successful submission
      setIsInputChanged(false);

      // Fetch updated input list
      const res = await dispatch(fetchItemInputDetails({ baseId, id }));
      if (res.type.endsWith('fulfilled')) {
        // Set the response data after fetching input details
        setFetchInputList(res.payload.data);
      } else {
        throw new Error(res.error?.message || 'Failed to fetch input details');
      }
    } catch (error) {
      console.error('Failed to fetch input data:', error);
    } finally {
      setInputLoading((prev) => ({ ...prev, [itemId]: false }));
    }
  };

  const [inputLoading1, setInputLoading1] = useState(false);

  // this is for local  variable 
  const handleEnterClickLocal = async (variableName: string, variableCount: number, index) => {
    try {
      setInputLoading1(true);

      // Check if the item already exists in fetchInputList
      const existingItem = fetchInputList.find(
        (item) => item.input_title.toLowerCase() === variableName.toLowerCase()
      );

      // Use existing UUID if the item exists, otherwise generate a new UUID
      const itemId = existingItem ? existingItem.id : uuidv4();

      // Calculate input_sequence based on the current state or a counter
      const inputSequence = inputSequenceRef.current; // Use a ref to track sequence
      const baseMemberId = selectedBaseMember ? selectedBaseMember.id : null; // Get ID from selected baseMember
      const inputMandatory = existingItem ? existingItem.input_mandatory : false;

      const payload = {
        id: itemId, // Use existing ID if updating, or a new one if creating
        base_id: Number(baseId),
        item_id: id,
        item_type: 'doc',
        input_title: variableName,
        input_variable: variableName,
        input_count: variableCount,
        input_type: variableTypeLocal[index]?.backendType,
        input_options: '',
        input_parameter: '',
        input_mandatory: inputMandatory, // Use dynamic value
        input_sequence: inputSequence, // Use dynamic sequence
        input_status: 'pending',
        item_sync: isInputChanged,
        external_contact_id: selectedRecipientInput.id,
        internal_user_id: baseMemberId,
      };

      // Dispatch the fetchInputData thunk with baseId and payload
      const result = await dispatch(
        fetchItemInputData({
          baseId,
          payload,
        })
      ).unwrap();

      // Increment the sequence for the next input if it's a new entry
      if (!existingItem) {
        inputSequenceRef.current += 1;
      }

      // Reset the input changed state after successful submission
      setIsInputChanged(false);

      // Fetch updated input list
      const res = await dispatch(fetchItemInputDetails({ baseId, id }));
      if (res.type.endsWith('fulfilled')) {
        // Set the response data after fetching input details
        setFetchInputList(res.payload.data);
      } else {
        throw new Error(res.error?.message || 'Failed to fetch input details');
      }
    } catch (error) {
      console.error('Failed to fetch input data:', error);
    } finally {
      setInputLoading1(false);
    }
  };


  // const handleEnterClick = async (variableName: string, variableCount: number) => {
  //   try {
  //     setInputLoading(true)
  //     const generatedUuid = uuidv4();

  //     // Calculate input_sequence based on the current state or a counter
  //     const inputSequence = inputSequenceRef.current; // Use a ref to track sequence
  //     const baseMemberId = selectedBaseMember ? selectedBaseMember.id : null; // Get ID from selected baseMember

  //     const payload = {
  //       id: generatedUuid, // Use generated UUID
  //       base_id: Number(baseId),
  //       item_id: id,
  //       item_type: 'doc',
  //       input_title: variableName,
  //       input_variable: variableName,
  //       input_count: variableCount,
  //       input_type: 'text',
  //       input_options: '',
  //       input_parameter: '',
  //       input_mandatory: false,
  //       input_sequence: inputSequence, // Use dynamic sequence
  //       input_status: 'pending',
  //       item_sync: isInputChanged,
  //       external_contact_id: selectedRecipientInput.id,
  //       internal_user_id: baseMemberId
  //     };

  //     // Dispatch the fetchInputData thunk with baseId and payload
  //     const result = await dispatch(
  //       fetchItemInputData({
  //         baseId,
  //         payload
  //       })
  //     ).unwrap();

  //     // Increment the sequence for next input
  //     inputSequenceRef.current += 1;
  //     // Reset the input changed state after successful submission
  //     setIsInputChanged(false);

  //     const res = await dispatch(fetchItemInputDetails({ baseId, id }));

  //     if (res.type.endsWith('fulfilled')) {
  //       // Set the response data after fetching input details
  //       setFetchInputList(res.payload.data);
  //     } else {
  //       throw new Error(res.error?.message || 'Failed to fetch input details');
  //     }

  //   } catch (error) {
  //     console.error('Failed to fetch input data:', error);
  //   } finally {
  //     setInputLoading(false)
  //   }
  // };

  const handleVariableTypeChange = (itemId, newType) => {
    setVariableType(prevMap => ({
      ...prevMap,
      [itemId]: newType
    }));

    handleInputChange();
  };


  // const handleVariableTypeChange = (newType) => {
  //   setVariableType(newType);

  //   handleInputChange();
  // };

  const [selectedMembers, setSelectedMembers] = useState({});

  const handleMemberSelect = (member, itemId) => {
    setSelectedBaseMember(member.baseMember);
    setVariableType1(member.baseMember);
    setMemberColor(member.item)
    setIsOpen(false);
    handleInputChange();
    setSelectedMembers(prevSelectedMembers => ({
      ...prevSelectedMembers,
      [itemId]: member
    }));
  };


  const handleMemberSelectLocal = (member, index) => {
    // Update only the specific index when a value is selected
    const updatedVariableTypes = [...variableTypes];
    updatedVariableTypes[index] = member;
    setVariableFromLocal(updatedVariableTypes);
  };


  useEffect(() => {
    if (fetchInputList && baseMemberDetails?.data?.baseMembers) {
      const fetchInput = fetchInputList.map((input: any) => input.internal_user_id);


      const matchedMembers = fetchInput.map((userId: any) => {
        if (userId === null) return { id: null, name: null };
        const matchedMember = baseMemberDetails.data.baseMembers.find(
          (member: any) => member.id === userId
        );
        return matchedMember
          ? { id: userId, name: matchedMember.name }
          : { id: userId, name: null };
      });
      setMatchedMembers(matchedMembers);

    }
  }, [fetchInputList, baseMemberDetails]);

  useEffect(() => {
    // Adjust state if `variables` length changes
    setVariableTypeLocal((prev) => {
      const newState = [...prev];
      for (let i = prev.length; i < variables.length; i++) {
        newState[i] = variableTypeList[0]; // Add default value for new indices
      }
      return newState.slice(0, variables.length); // Ensure it matches `variables` length
    });
  }, [variables]);


  // fetch tags 
  useEffect(() => {
    if (baseId) {
      const fetchTeams = async () => {
        const storedBase = localStorage.getItem('baseTagsData');
        if (storedBase) {
          setTagsList(JSON.parse(storedBase));
          // setSelectedTags(JSON.parse(storedBase))
        } else {
          try {
            const baseResponse = await dispatch(fetchTagsDataList({ baseId: userDetails?.id || Number(baseId) }));
            setTagsList(baseResponse.payload);
            // setSelectedTags(baseResponse.payload)
          } catch (error) {
            console.error("Error fetching base member details:", error);
          }
        }
      };
      fetchTeams();
    }
  }, [baseId, dispatch]);

  const handleTagsSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTags(searchTerm);
  };

  // Filter recipients based on the search input
  const filteredTags = tagsList?.data?.filter((tag) => {
    const fullName = `${tag.tag_name || ''}`.toLowerCase();
    return fullName.includes(searchTags);
  }) || [];


  const handleTagsSelect = async (tag) => {
    setSelectedTags(tag);
    setTagsOpen(false);
    setSearchTags('');
    try {
      const payload = {
        id: id, // Ensure this is correct for your API
        baseId,
        doc_tag: String(tag.id),
      };
      const result = await dispatch(updateDocument(payload)).unwrap();
      toast.success('Tags updated successfully');
    } catch (error) {
      console.error('Failed to update channel:', error);
      toast.error('Failed to update channel');
    }
  };

  // channel search

  const handleChannelSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchChannel(searchTerm);
  };

  // Filter recipients based on the search input
  const filteredChannel = teams?.filter((channel) => {
    const fullName = `${channel.name || ''}`.toLowerCase();
    return fullName.includes(searchChannel);
  }) || [];

  // profile avatar 

  // Function to get full profile picture URL
  const getFullProfilePicUrl = (profilePic) => {
    // If profile pic is a full URL, return it directly
    if (profilePic?.startsWith('http')) {
      return profilePic;
    }

    // Combine base URL with profile pic path
    return profilePic ? `${process.env.REACT_APP_API_BASE_URL}/${profilePic}` : null;
  };

  const handleCustomerDelete = async () => {
    if (deleteLoading) return; // Prevent multiple calls

    setShowDeleteConfirmation(true);
    setDeleteLoading(true);

    try {
      const payload = {
        id,
        baseId,
        doc_flag: 'delete',
      };

      const result = await dispatch(updateDocument(payload)).unwrap();
      toast.success('Document deleted successfully');
      navigate(`/${baseId}/documents`);
    } catch (error) {
      console.error('Failed to delete document:', error);
      toast.error('Failed to delete document');
    } finally {
      setDeleteLoading(false);
      setShowDeleteConfirmation(false);
    }
  }

  const handleEditToggle = () => {
    setIsEditingEditor(!isEditingEditor);
  };

  const handleShare = async () => {
    setSendPendingLoading(true)
    try {
      const payload = {
        id: id,
        baseId: baseId,
        doc_status: 'pending',
      };

      // Dispatch the action to update the document
      const result = await dispatch(updateDocument(payload)).unwrap();

      if (result.status === 200) {
        toast.success('Document saved successfully');
        navigate(`/${baseId}/d/${id}/pending`);
      } else {
        toast.error(`Failed to save document. Status: ${result.status}`);
      }
    } catch (error) {
      console.error('Failed to save document:', error);
      toast.error('Failed to save document');
    } finally {
      setSendPendingLoading(false)
    }
  };


  const handleBack = () => {
    navigate(`/${baseId}/dashboard`);
  };

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const fetchResponse = await dispatch(
          fetchDocumentAction({ baseId, id, type: "true" }) // Pass "true" as type
        ).unwrap();
        setActionWithFilter(fetchResponse);
      } catch (error) {
        console.error("Error fetching base member details:", error);
      }
    };
  
    if (baseId && id) { // Ensure both baseId and id are available
      fetchTeams();
    }
  }, []); // No dependencies
  
    const handleSave = async () => {
      if (!editorInstance) {
        toast.error('Editor not initialized');
        return;
      }
  
      setIsSaving(true);
      try {
        // Get JSON content from editor
        const jsonContent = editorInstance.getJSON();
        const htmlContent = editorInstance.getHTML();
        // Create variableColors array
        const updatedVariableColors = variables.map((variable, index) => ({
          name: variable.name,
          color: selectedVariableColor[index]?.member_colour || null,
          mentionClass: `mention-${index}`,
          member: variableFromLocal[index]?.name || null
        }));
  
        // Create updated JSON content with new variable colors
        const enrichedJsonContent = {
          ...jsonContent,
          variableColors: updatedVariableColors
        };
  
        const wrappedHtmlContent = `<div class="tiptap"><div class="ProseMirror">${htmlContent}</div></div>`;
  
        const payload = {
          id: id,
          baseId: baseId,
          doc_content: JSON.stringify(enrichedJsonContent),
          doc_html: wrappedHtmlContent
        };
  
        const result = await dispatch(updateDocument(payload)).unwrap();
        toast.success('Document saved successfully');
        setIsEditingEditor(false)
      } catch (error) {
        console.error('Failed to save document:', error);
        toast.error('Failed to save document');
      } finally {
        setIsSaving(false);
      }
    };
  
  const handleDownloadPdf = async () => {
    setIsLoadingPdf(true);
    try {
      const resultAction = await dispatch(fetchDownloadPdfAction({ baseId, id })).unwrap();
      
      if (resultAction && resultAction.url) {
        let url = `${process.env.REACT_APP_API_BASE_URL}/${resultAction.url}`;
        console.log(url)

        const response = await axios({
          url,
          method: 'GET',
          responseType: 'blob'
        });
  console.log(response)
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const blobUrl = window.URL.createObjectURL(blob);
        
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${id}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        window.URL.revokeObjectURL(blobUrl);
        
        toast.success('PDF downloaded successfully');
      }
    } catch (error) {
      console.error('Download Error:', error);
      toast.error(error.message || 'Failed to download PDF');
    } finally {
      setIsLoadingPdf(false);
    }
  };
  

  if (fetchStatus === 'loading') {
    return <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 50px)', width: 'calc(100% - 350px)' }}>
      <div className="simple-spinner-loader">
        <span className='!w-6 !h-6'></span>
      </div>
    </div>;
  }

  if (fetchStatus === 'failed') {
    return <div>Error loading document: {fetchError}</div>;
  }


  return (
    <div className="h-screen bg-white">
      <div className="sticky top-0 z-10">
        <div className="flex h-[60px] shrink-0 items-center gap-x-4 bg-white px-4 shadow-custom-1 sm:gap-x-6 sm:px-6 lg:px-4">
          <div className='flex items-center justify-between w-full'>



            <div className='flex items-center gap-4'>
            
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  onClick={handleBack}
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon icon-tabler icons-tabler-outline icon-tabler-home w-[1rem] h-[1rem] text-[#808080]"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                </svg>
              </button>
              {/* {isEditing ? (
              <input
                className="text-black-100 font-medium text-smleading-8  cursor-pointer w-full
                         border-0 focus:border-0 focus:outline-none focus-within:border-none  !ring-0 !shadow-none !border-transparent "
                value={docName}
                onChange={handleNameChange}
                onBlur={handleNameBlur}
                autoFocus
                maxLength={100}
              />
            ) : ( */}
              <div
                className="text-black-100 font-medium text-sm leading-6 cursor-pointer text-ellipsis whitespace-nowrap overflow-hidden  px-1 "
                onClick={handleNameClick} // Enable editing when clicked
              >
                {docName}
              </div>
            </div>

            {/* // )} */}

            <div className='flex items-center gap-x-[100px]'>
            <button
                  className={`mr-[10px] rounded-md duration-200 font-[600] text-[0.8125rem] h-[32px] w-[32px] flex items-center justify-center border border-solid border-[#e7e7e8] ${isLoadingPdf ? 'cursor-not-allowed opacity-50' : ''}`}
                  onClick={handleDownloadPdf}
                >
                  {isLoadingPdf ? (
                    <div className="simple-spinner-pdf">
                      <span></span>
                    </div>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon icon-tabler icons-tabler-outline icon-tabler-download w-[1rem] h-[1rem] text-[#808080]"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                      <path d="M7 11l5 5l5 -5" />
                      <path d="M12 4l0 12" />
                    </svg>
                  )}
                </button>
              <div className='flex items-center gap-x-4'>
                <div className="isolate flex -space-x-1 relative">
                  {selectedInternalMember.length > 0 &&
                    selectedInternalMember.map((memberData, index) => (
                      <div key={memberData.id || index} className='w-[24px] h-[24px] relative '>
                        {memberData.profile_pic ? (
                          <div className='table-tooltip before:!bottom-[-2px] before:!left-[-27px]' data-title={memberData.name}>
                            <img
                              src={getFullProfilePicUrl(memberData.profile_pic)}
                              alt={memberData.name || 'Member Profile'}
                              className=" size-6 rounded-full ring-2 ring-white bg-gray-300 flex items-center justify-center text-[11px] font-[500] text-[#52555a]"
                            />
                          </div>
                        ) : (
                          <div
                            data-title={memberData.name}
                            className="table-tooltip before:!bottom-[-2px] before:!left-[-27px] size-6 rounded-full ring-2 ring-white bg-gray-300 flex items-center justify-center text-[11px] font-[500] text-[#52555a]"
                          >
                            {memberData.name ? memberData.name.charAt(0).toUpperCase() : '?'}
                          </div>
                        )}
                      </div>
                    ))}
                </div>


                {
                  selectedExternalMember?.length > 0 && (
                    <div className='w-[1px] h-[12px] bg-[#00000050]'></div>
                  )
                }


                <div className="isolate flex -space-x-1 relative">
                  {selectedExternalMember.length > 0 &&
                    selectedExternalMember.map((memberData, index) => (
                      <div key={memberData.id || index}>
                        {memberData.profile_pic ? (
                          <div className='table-tooltip before:!bottom-[-2px] before:!left-[-66px]' data-title={memberData.name}>
                            <img
                              src={getFullProfilePicUrl(memberData.profile_pic)}
                              alt={memberData.first_name || memberData.email || 'External Member'}
                              className="relative z-30 inline-block size-6 rounded-full ring-2 ring-white object-cover"
                            />
                          </div>
                        ) : (
                          <div
                            data-title={`${memberData.first_name} | ${memberData.last_name}  | ${memberData.email}`}
                            className="table-tooltip before:!bottom-[-2px] before:!left-[-66px] before:!max-w-[298px] size-6 rounded-full ring-2 ring-white bg-gray-300 flex items-center justify-center text-[11px] font-[500] text-[#52555a]"
                          >
                            {memberData.first_name
                              ? memberData.first_name.charAt(0).toUpperCase()
                              : memberData.email
                                ? memberData.email.charAt(0).toUpperCase()
                                : '?'}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>


              {/* <div className='flex justify-between items-center gap-2'>
                {!isEditingEditor ? (
                  <button
                    onClick={handleEditToggle}
                    className='group inline-flex px-4 shrink-0 select-none items-center transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8px py-1 rounded-lg text-white outline-primary disabled:opacity-50 bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 justify-start text-[13px] font-medium'
                  >
                    Edit
                  </button>
                ) : (
                  <button
                    onClick={handleSave}
                    className='group inline-flex px-4 shrink-0 select-none items-center transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8px py-1 rounded-lg text-white outline-primary disabled:opacity-50 bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 justify-start text-[13px] font-medium'
                  >
                    {isSaving ? (
                      <div className='flex items-center gap-[6px]'>
                        Save
                        <div className="simple-spinner">
                          <span></span>
                        </div>
                      </div>
                    ) : (
                      'Save'
                    )}
                  </button>
                )}
                <button 
                onClick={handleShare}
                className='group inline-flex px-4 shrink-0 select-none items-center transition-colors 
               duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2
             focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8px py-1 rounded-lg text-white
           outline-primary disabled:opacity-50 bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 
                justify-start text-[13px] font-medium'>
                  Send
                </button>
              </div> */}


              {/* <div className='flex justify-between items-center gap-2'>
                <button
                  onClick={handleSave}
                  className='group inline-flex px-6 shrink-0 select-none items-center transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8px py-1 rounded-lg text-white outline-primary disabled:opacity-50 bg-indigo-600 hover:bg-indigo-500
                 focus-visible:outline-indigo-600 justify-start [&>span]:flex [&>span]:items-center [&>span]:gap-[6px] text-[13px] font-medium'
                >
                  {isSaving ? (
                    <div className='flex items-center  gap-[6px]'>
                      Save
                      <div className="simple-spinner">
                        <span></span>
                      </div>
                    </div>
                  ) : (
                    'Save'
                  )}
                </button>


              </div> */}
            </div>
          </div>

        </div>
      </div>




      <div className='flex-1 p-0'>
        <div className='min-h-full max-w-full' style={{ margin: '0px auto' }}>
          <div className='flex items-stretch flex-row gap-0 w-full h-full relative overflow-x-hidden overflow-y-hidden'>
            <main className='relative flex-1 flex'>
              <div
                //       className={`bg-white mq1023:max-w-full overflow-auto flex-1 w-full view-feedback-data
                // ${isAsideOpen ? 'mq1800:max-w-[68%] mq3000:max-w-[76.8%]' : 'mq1800:max-w-[96.5%] mq3000:max-w-[97.5%]'} 
                // ${isAsideOpen1 ? 'mq1800:max-w-[78%] mq3000:max-w-[76.8%]' : 'mq1800:max-w-[96.5%] mq3000:max-w-[76.9%]'}

                // `}

                //         className={`bg-white mq1023:max-w-full flex-1 w-full view-feedback-data
                //   ${isAsideOpen && !isAsideOpen1 ? 'desktop-1800-2400-aside-open desktop-991-1800-aside-open' : ''}
                //   ${isAsideOpen1 && !isAsideOpen ? 'desktop-1800-2400-aside-open1 desktop-991-1800-aside-open1' : ''}
                //   ${!isAsideOpen && !isAsideOpen1 ? 'desktop-1800-2400-default desktop-991-1800-default' : ''}
                //   ${isAsideOpen && isAsideOpen1 ? 'desktop-1800-2400-both-open desktop-991-1800-both-open' : ''}
                // `}
                className={`bg-white mq1023:max-w-full flex-1 w-full view-feedback-data
                             ${isAsideOpen && !isAsideOpen1 && !isAsideOpen2 && !isAsideOpen3 && !isAsideOpen4 ? 'desktop-1800-2400-aside-open-details desktop-991-1800-aside-open-details' : ''}
                            ${isAsideOpen1 && !isAsideOpen && !isAsideOpen2 && !isAsideOpen3 && !isAsideOpen4 ? 'desktop-1800-2400-aside-open1-details desktop-991-1800-aside-open1-details' : ''}
                           ${isAsideOpen2 && !isAsideOpen && !isAsideOpen1 && !isAsideOpen3 && !isAsideOpen4 ? 'desktop-1800-2400-aside-open2-details desktop-991-1800-aside-open2-details' : ''}
                           ${isAsideOpen3 && !isAsideOpen && !isAsideOpen1 && !isAsideOpen2 && !isAsideOpen4 ? 'desktop-1800-2400-aside-open3-details desktop-991-1800-aside-open3-details' : ''}
                           ${isAsideOpen4 && !isAsideOpen && !isAsideOpen1 && !isAsideOpen2 && !isAsideOpen3 ? 'desktop-1800-2400-aside-open4-details desktop-991-1800-aside-open4-details' : ''}
                          ${isAsideOpen5 && !isAsideOpen && !isAsideOpen1 && !isAsideOpen2 && !isAsideOpen3 && !isAsideOpen4 ? 'desktop-1800-2400-aside-open5-details desktop-991-1800-aside-open5-details' : ''}
                          ${isAsideOpen6 && !isAsideOpen && !isAsideOpen1 && !isAsideOpen2 && !isAsideOpen3 && !isAsideOpen4 && !isAsideOpen5 ? 'desktop-1800-2400-aside-open6-details desktop-991-1800-aside-open6-details' : ''}

                             ${(isAsideOpen && isAsideOpen1) || (isAsideOpen2 && isAsideOpen3 && isAsideOpen4 && isAsideOpen5 && isAsideOpen6) ? 'desktop-1800-2400-both-open-details desktop-991-1800-both-open-details' : ''}
                      ${!isAsideOpen && !isAsideOpen1 && !isAsideOpen2 && !isAsideOpen3 && !isAsideOpen4 && !isAsideOpen5 && !isAsideOpen6 ? 'desktop-1800-2400-default-details desktop-991-1800-default-details' : ''}
                       `}
              >
                <div className='mb-14 mt-5 lg:mt-[35px]' >
                  <TipTapEditor isAsideOpen={isAsideOpen} isAsideOpen1={isAsideOpen1}
                    isAsideOpen2={isAsideOpen2} isAsideOpen3={isAsideOpen3}
                    isAsideOpen4={isAsideOpen4}
                    isAsideOpen5={isAsideOpen5}
                    isAsideOpen6={isAsideOpen6}
                    onEditorReady={handleEditorReady}
                    data={data}
                    onColorChange={handleColorChange}
                    isEditingEditor={isEditingEditor}
                    fetchInputList={fetchInputList}
                  />

                </div>
              </div>

              {/* info */}
              <div
                className={`main-feedback-side flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 
                        border-solid absolute  lg:fixed top-[60px] max-w-[80%] view-feedback-right-side border-r-0 overflow-x-hidden hover:overflow-x-hidden ' `}
                style={{
                  width: isAsideOpen ? '350px' : '350px',
                  transform: isAsideOpen ? 'none' : 'none',
                  right: isAsideOpen ? '51px' : '0px',
                  marginRight: isAsideOpen ? '0px' : '-350px',
                  // transition: 'all 0.3s ease-in-out',

                  height: isAsideOpen ? 'calc(100vh - +60px)' : '',
                  ...(window.innerWidth <= 768 && {
                    width: isAsideOpen ? '300px' : '300px',
                    marginRight: isAsideOpen ? '0' : '-300px',
                    // transition: 'all 0.3s ease-in-out'
                  }),
                }}
              >
                <div className='h-full'>
                  <div className='h-auto flex flex-col justify-stretch min-h-[3rem] 
                                           border-b-0 border-l-0 border-solid border-light-gray-200 p-[1.5rem] pt-[0.9rem] items-start gap-4'>
                    <div className=" flex items-start justify-center w-full">
                      <div className="mx-auto w-full">
                        <p className="text-center text-wedges-gray text-sm" />
                        <div className="flex items-center max-w-full w-fit mb-5 font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] ml-[-12px] py-[5px] gap-2">
                          <span className="text-[#52555a] capitalize">#{documentDetailsSave?.doc_ref_id}</span>
                        </div>
                        <div className="mb-2 mx-[-6px]">
                          {/* <a
                            href="#"
                            onClick={toggleCollapse}
                            className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                          >

                            Details

                            <div className={`${collapsed ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                              <svg
                                width={24}
                                height={24}
                                fill="none"
                                className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                  d="M15.25 10.75 12 14.25l-3.25-3.5"
                                />
                              </svg>
                            </div>
                          </a> */}

                          {/* <AnimatePresence initial={false}>
                            {!collapsed && (
                              <motion.div
                                initial={{ height: 0, overflow: 'hidden', backgroundColor: 'white' }}
                                animate={{ height: 'auto', overflow: 'inherit', backgroundColor: 'white' }}
                                exit={{ height: 0, overflow: 'hidden', backgroundColor: 'white' }}
                                transition={{ height: { duration: 0.5 }, overflow: 'hidden', backgroundColor: 'red' }}
                              > */}
                          <div className="pr-4 pl-[0px]">
                            <div className='w-full'>
                              <div className="w-full flex items-center justify-between lg:justify-start py-1">
                                <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[30%]">
                                  Channels
                                </div>
                                <div className="relative w-[70%] flex justify-end">
                                  <span
                                    className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 capitalize ml-[-26px]'
                                  >
                                    {
                                      selected.name?.length > 0 && selected.name
                                    }

                                  </span>
                                  {/* <button
                                    type="button"
                                    className="flex items-center justify-between h-[32px] px-2 py-1 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                                    onClick={() => setChannelOpen(!channelOpen)}
                                  >
                                    <span className="mr-2 text-[13px] text-[#374151] w-[150px] text-left">
                                      {
                                        selected
                                          ? (
                                            <span className="gap-2 truncate capitalize flex items-center">
                                              <span
                                                className="flex h-[8px] w-[8px] shrink-0 items-center justify-center 
                    rounded-full border-none text-[0.625rem] font-medium text-white capitalize"
                                                style={{ background: selected.color }}
                                              ></span>
                                              <span className='text-[13px] leading-4  text-ellipsis whitespace-nowrap overflow-hidden w-[140px] text-[#52555a] break-words 
                                              text-left'>
                                                {selected.name}
                                              </span>

                                            </span>
                                          )
                                          : 'Select channel'
                                      }
                                    </span>
                                    <svg
                                      className={`h-4 w-4  text-gray-400 transition-transform duration-300 ${channelOpen ? 'transform rotate-180' : ''}`}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </button>
                                  {channelOpen && (
                                    <div className="absolute top-[30px] z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                        <div className="px-2 py-1">
                                          <input
                                            type="text"
                                            className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                            placeholder="Search.."
                                            value={searchChannel}
                                            onChange={handleChannelSearch}
                                          />
                                        </div>
                                        <div className='max-h-[200px] overflow-auto'>
                                          {filteredChannel.length > 0 ? (
                                            filteredChannel.map((tag, index) => (
                                              <a
                                                key={index}
                                                href="#"
                                                className="block px-2 py-1.5 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                onClick={() => handleChangeChannel(tag)}
                                              >
                                                <div className="flex items-center gap-2">
                                                  <span
                                                    className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full
                                                         border-none text-[0.625rem] font-medium text-white capitalize bg-[${tag.color}]`}
                                                  >
                                                  </span>

                                                  <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  
                                                              whitespace-nowrap w-[140px] overflow-hidden text-ellipsis capitalize">

                                                    {tag.name}

                                                  </span>


                                                </div>
                                              </a>
                                            ))
                                          ) : (
                                            <p className="text-gray-500 text-center py-2">No channel found</p>
                                          )}
                                        </div>

                                      </div>
                                    </div>
                                  )} */}

                                </div>

                                {/* <Listbox value={selected} onChange={handleChangeChannel}>
                                  {({ open }) => (
                                    <div className="relative w-[70%] flex justify-end">
                                      <Listbox.Button className="relative justify-center flex items-center cursor-pointer capitalize rounded-md bg-white py-1 px-2 text-left text-[12px] text-[#52555a] font-medium bg-transparent border-solid hover:!border-light-gray-200 border border-white">
                                        <div className="flex items-center gap-2">
                                          {selected ? (
                                            <span className="gap-2 truncate capitalize flex items-center">
                                              <span
                                                className="flex h-[8px] w-[8px] shrink-0 items-center justify-center 
                    rounded-full border-none text-[0.625rem] font-medium text-white capitalize"
                                                style={{ background: selected.color }}
                                              ></span>
                                              {selected.name}
                                            </span>
                                          ) : (
                                            'Select a team' // Placeholder if no team is selected
                                          )}
                                        </div>
                                      </Listbox.Button>
                                      <Transition
                                        show={open}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options className="absolute z-10 w-[160px] left-[-50px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {teams?.length > 0 ? (
                                            teams?.map((team) => (
                                              <Listbox.Option
                                                key={team.id}
                                                className={({ active }) =>
                                                  classNames(
                                                    active ? 'bg-light-gray-100 text-dark-black-100' : '',
                                                    'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                  )
                                                }
                                                value={team}
                                              >
                                                {({ selected }) => (
                                                  <div className="flex items-center gap-2">
                                                    <span
                                                      className="flex h-[8px] w-[8px] shrink-0 items-center justify-center 
                          rounded-full border-none text-[0.625rem] font-medium text-white capitalize"
                                                      style={{ background: team.color }}
                                                    ></span>
                                                    <span
                                                      className={classNames(
                                                        selected ? 'font-semibold' : 'font-normal',
                                                        'block truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full overflow-hidden text-ellipsis text-start'
                                                      )}
                                                    >
                                                      {team.name}
                                                    </span>
                                                  </div>
                                                )}
                                              </Listbox.Option>
                                            ))
                                          ) : (
                                            <div className="text-center py-2 text-gray-500">No data available</div>
                                          )}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  )}
                                </Listbox> */}

                              </div>
                              <div className="w-full flex items-center justify-between lg:justify-start py-1">
                                <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[30%]">
                                  Status
                                </div>
                                <div className="relative w-[70%] flex justify-end">
                                  <span className='flex items-center justify-end gap-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px]
                                  w-full  overflow-hidden text-ellipsis text-start font-medium'>

                                    {documentDetailsSave?.doc_status === 'completed' ? (
                                      <span
                                        className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize ml-[-10px]"
                                      // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                      >
                                        {documentDetailsSave?.doc_status}
                                      </span>
                                    ) : documentDetailsSave?.doc_status === 'rejected' ? (
                                      <span
                                        className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 capitalize ml-[-10px]"
                                      // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                      >
                                        {documentDetailsSave?.doc_status}
                                      </span>
                                    ) : documentDetailsSave?.doc_status === 'terminated' ? (
                                      <span
                                        className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10 capitalize ml-[-10px]"
                                      // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                      >
                                        {documentDetailsSave?.doc_status}
                                      </span>
                                    ) : documentDetailsSave?.doc_status === 'expired' ? (
                                      <span
                                        className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 capitalize ml-[-10px]"
                                      // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                      >
                                        {documentDetailsSave?.doc_status}
                                      </span>
                                    ) : documentDetailsSave?.doc_status === 'pending' ? (
                                      <span
                                        className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize ml-[-10px]"
                                      >
                                        {documentDetailsSave?.doc_status}
                                      </span>
                                    ) : documentDetailsSave?.doc_status === 'draft' ? (
                                      <span
                                        className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 capitalize ml-[-10px]"
                                      >
                                        {documentDetailsSave?.doc_status}
                                      </span>
                                    ) :
                                      null}
                                  </span>
                                </div>
                              </div>
                              <div className="w-full flex items-center justify-between lg:justify-start py-1">
                                <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[30%]">
                                  Sub  Status
                                </div>
                                <div className="relative w-[70%] flex justify-end">
                                  <span className='flex items-center justify-end gap-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px]
                                  w-full  overflow-hidden text-ellipsis text-start font-medium'>

                                    {
                                      documentDetailsSave?.doc_sub_status === 'completed-view' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize ml-[-26px]"
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {documentDetailsSave?.doc_sub_status}
                                        </span>
                                      ) : documentDetailsSave?.doc_sub_status === 'completed-accept' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize ml-[-26px]"
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {documentDetailsSave?.doc_sub_status}
                                        </span>
                                      ) : documentDetailsSave?.doc_sub_status === 'completed-sign' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize ml-[-26px]"
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {documentDetailsSave?.doc_sub_status}
                                        </span>
                                      ) : documentDetailsSave?.doc_sub_status === 'completed-payment' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize ml-[-26px] "
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {documentDetailsSave?.doc_sub_status}
                                        </span>
                                      ) : documentDetailsSave?.doc_sub_status === 'pending-accept' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize ml-[-26px]"
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {documentDetailsSave?.doc_sub_status}
                                        </span>
                                      ) : documentDetailsSave?.doc_sub_status === 'pending-sign' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize ml-[-26px]"
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {documentDetailsSave?.doc_sub_status}
                                        </span>
                                      ) : documentDetailsSave?.doc_sub_status === 'pending-payment' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize ml-[-26px]"
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {documentDetailsSave?.doc_sub_status}
                                        </span>
                                      ) : documentDetailsSave?.doc_sub_status === 'pending-approval' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize  ml-[-26px]"
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {documentDetailsSave?.doc_sub_status}
                                        </span>
                                      ) : documentDetailsSave?.doc_sub_status === 'sent' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10 capitalize ml-[-26px]"
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {documentDetailsSave?.doc_sub_status}
                                        </span>
                                      ) : documentDetailsSave?.doc_sub_status === 'draft' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 capitalize ml-[-26px]"
                                        >
                                          {documentDetailsSave?.doc_sub_status}
                                        </span>
                                      ) :
                                        null}
                                  </span>
                                </div>
                              </div>
                              <div className="w-full flex items-center justify-between lg:justify-start py-1">
                                {
                                  selectedTags?.tag_name?.length > 0 &&


                                  <>

                                    <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[30%]">
                                      Tags
                                    </div>
                                    <div className="relative w-[70%] flex justify-end">
                                      <span className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium
                                  text-gray-600 ring-1 ring-inset ring-gray-500/10 capitalize ml-[-26px]'>
                                        {selectedTags.tag_name && selectedTags.tag_name}
                                      </span>
                                      {/* <div className="relative">
                                    <button
                                      type="button"
                                      className="flex items-center justify-between h-[32px] px-2 w-[150px] py-1 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                                      onClick={() => setTagsOpen(!tagsOpen)}
                                    >
                                      <span className="mr-2 ">
                                        {
                                          selectedTags
                                            ? (

                                              <span className="gap-2 truncate capitalize flex items-center">
                                                <span
                                                  className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full
                                                    border-none text-[0.625rem] font-medium text-white capitalize ${selectedTags.tag_colour}`}

                                                ></span>
                                                <span className="text-[13px] leading-4  text-ellipsis whitespace-nowrap
                                               overflow-hidden w-[80px] text-[#52555a] break-words  text-left capitalize">
                                                  {selectedTags.tag_name}
                                                </span>

                                              </span>

                                            )
                                            : 'Select tags'
                                        }
                                      </span>
                                      <svg
                                        className={`h-4 w-4  text-gray-400 transition-transform duration-300 ${tagsOpen ? 'transform rotate-180' : ''}`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </button>
                                    {tagsOpen && (
                                      <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                          <div className="px-2 py-1">
                                            <input
                                              type="text"
                                              className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                              placeholder="Search.."
                                              value={searchTags}
                                              onChange={handleTagsSearch}
                                            />
                                          </div>
                                          <div className='max-h-[200px] overflow-auto'>
                                            {filteredTags.length > 0 ? (
                                              filteredTags.map((tag, index) => (

                                                <a
                                                  key={index}
                                                  href="#"
                                                  className="block px-2 py-1.5 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                  onClick={() => handleTagsSelect(tag)}
                                                >
                                                  <div className="flex items-center gap-2">



                                                    <span
                                                      className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full
                                                         border-none text-[0.625rem] font-medium text-white capitalize ${tag.tag_colour}`}
                                                    >
                                                    </span>

                                                    <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  
                                                              whitespace-nowrap w-[100px] overflow-hidden text-ellipsis capitalize">

                                                      {tag.tag_name}

                                                    </span>


                                                  </div>
                                                </a>
                                              ))
                                            ) : (
                                              <p className="text-gray-500 text-center py-2">No tags found</p>
                                            )}
                                          </div>

                                        </div>
                                      </div>
                                    )}

                                  </div> */}


                                    </div>
                                  </>
                                }

                              </div>
                            </div>
                          </div>
                          {/* </motion.div>
                            )}
                          </AnimatePresence> */}
                        </div>

                        <div className=' mx-[-24px] min-w-[348px] w-full'>
                          <div className="w-full border-t border-l-0  border-solid border-light-gray-200 px-[1rem] pt-[1rem]">
                            <div className="w-full flex items-center justify-between lg:justify-start py-1 pr-4 pl-[0px]" >
                              <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[30%]">
                                Created
                              </div>
                              <div className="relative w-[70%] flex justify-end">
                                <span className=" table-tooltip before:!bottom-[20px] text-[13px] leading-4 whitespace-normal
                                 max-w-[140px] w-full text-[#52555a] break-words font-medium  text-right"
                                  data-title={createTimeTooltip}>
                                  {createdTimeAgoSidebar && createdTimeAgoSidebar} ago
                                </span>
                              </div>
                            </div>

                            <div className="w-full flex items-center justify-between lg:justify-start py-1 pr-4 pl-[0px]">
                              <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[30%]">
                                Updated
                              </div>
                              <div className="relative w-[70%] flex justify-end">
                                <span className=" table-tooltip before:!bottom-[20px] text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a] break-words font-medium text-right" data-title={updatedTimeTooltip}>
                                  {updatedTimeAgoSidebar && updatedTimeAgoSidebar} ago
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className='flex justify-start w-full mt-4 ml-[-8px]'>
                          <Link to=""
                            onClick={() => handleConfirmDelete()}
                            className="underline text-[13px] text-[#b91c1c]">Delete</Link>
                        </div> */}

                        <div className='border-t border-l-0  border-solid border-light-gray-200 mt-5 pb-1 mx-[-24px]'></div>
                        <SendPendingAction setIsAsideOpen6={setIsAsideOpen6} isAsideOpen6={isAsideOpen6} 
                         documentDetailsSave={documentDetailsSave}
                         actionWithFilter={actionWithFilter} setActionWithFilter={setActionWithFilter}
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              {/* inputs */}
              {/* <div
                className={`main-feedback-side flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 
                        border-solid absolute lg:fixed top-[60px] max-w-[80%] view-feedback-right-side border-r-0 ' `}
                style={{
                  width: isAsideOpen1 ? '350px' : '350px',
                  transform: isAsideOpen1 ? 'none' : 'none',
                  right: isAsideOpen1 ? '51px' : '0px',
                  marginRight: isAsideOpen1 ? '0' : '-350px',
                  height: isAsideOpen1 ? 'calc(100vh - (60px))' : '',
                  ...(window.innerWidth <= 768 && {
                    width: isAsideOpen1 ? '300px' : '300px',
                    marginRight: isAsideOpen1 ? '0' : '-300px',
                  }),
                }}
              >
                <div className='h-full'>
                  <div className='h-auto flex flex-col justify-stretch min-h-[3rem] 
                                           border-b-0 border-l-0 border-solid border-light-gray-200 pt-[0.9rem] items-start gap-4'>
                    <div className=" flex items-start justify-center w-full">
                      <div className="mx-auto w-full px-[1.5rem]">
                        <p className="text-center text-wedges-gray text-sm" />
                        <div className="flex items-center max-w-full w-fit mb-5 font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] mx-[-6px] px-[0.5rem] py-[5px] gap-2">
                          <span className="text-[#52555a] capitalize">Inputs</span>
                        </div>


                        <div className="variable-list overflow-y-hidden overflow-x-hidden flex flex-col gap-y-3 !pb-12">
                          {fetchInputList && fetchInputList.map((item, index) => {
                            const currentInputType = variableType[item.id] || variableTypeList[0];
                            const filteredVariableType1 = variableType1 && Array.isArray(variableType1)
                              ? variableType1.filter(user => user.id !== null)
                              : [];

                            const matchedMember = filteredVariableType1.length > 0
                              ? filteredVariableType1.find(user => user.id === item.internal_user_id)
                              : null;

                            const selectedMember = selectedMembers[item.id];

                            return (
                              <div key={index} className="flex flex-col gap-y-2">
                                <div
                                  className={`flex-auto ${expandedVariable === item?.input_title ? 'rounded-t-md' : 'rounded-md'} p-3 cursor-pointer ring-1 ring-inset ring-gray-200 flex justify-between`}

                                >
                                  <div className='flex flex-col gap-y-[4px]'>
                                    <div className='flex items-center gap-1'>
                                      <span className='text-dark-black-200 text-[12px] capitalize'>
                                        {item.input_type === 'phone_number' ? 'Phone Number' : item.input_type}
                                      </span>

                                      <span
                                        className={`ml-1 ring-1 ring-inset ring-blue-700/10 rounded-full w-4 h-4 flex items-center justify-center text-xs font-medium ${isInputChanged == false ? 'text-gray-700 bg-gray-50' : 'text-blue-700 bg-blue-50'
                                          }`}
                                      >
                                        {item?.input_count}
                                      </span>
                                    </div>
                                    <span className="text-[#52555a] text-[0.8125rem] font-medium leading-[1.2] capitalize">{item?.input_title} </span>
                                    {matchedMember?.name ? (
                                      <span
                                        className="inline-flex w-fit items-center capitalize rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ring-yellow-600/20"
                                        style={{
                                          backgroundColor: `${item?.item_member_colour}33`, 
                                          color: item?.item_member_colour,
                                        }}
                                      >
                                        {matchedMember.name}
                                      </span>
                                    ) : (selectedMember?.baseMember?.name && (
                                      <span
                                        style={{
                                          backgroundColor: `${memberColor?.member_colour}33`,
                                          color: memberColor?.member_colour,
                                        }}
                                        className="inline-flex w-fit items-center capitalize rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ring-yellow-600/20"
                                      >
                                        {selectedMember?.baseMember?.name}
                                      </span>
                                    )
                                    )}
                                    {
                                      item?.contact?.id &&
                                      <span className="inline-flex w-fit items-center capitalize rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                                        {item?.contact?.contact_email}
                                      </span>
                                    }

                                  
                                  </div>
                                  <div className='flex items-start gap-x-3'>
                                  

                                    <span onClick={() => toggleAccordion(item?.id)}>
                                      <ChevronDownIcon />
                                    </span>
                                  </div>

                                </div>

                                {expandedVariable?.includes(item?.id) && (

                                  <div className="p-3 ring-1 ring-inset ring-gray-200 rounded-b-md mt-[-9px]">


                                    <div className="w-full pt-[1rem]">
                                      <div className="w-full flex items-center justify-between lg:justify-start py-1">

                                        <div className="relative w-[100%]">
                                          <Listbox value={currentInputType}
                                            onChange={(newType) => handleVariableTypeChange(item.id, newType)}>
                                            {({ open }) => (
                                              <div>
                                                <label
                                                  className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
          before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
           peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
           after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
          before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                                >
                                                  Input type

                                                </label>
                                                <ListboxButton
                                                  className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
          focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
           disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                                >
                                                  <div className='flex items-center justify-between'>
                                                    <span
                                                      className="flex items-center truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
           overflow-hidden text-ellipsis text-start font-medium"
                                                    >
                                                      {currentInputType?.name}

                                                    </span>
                                                    <ChevronDownIcon />
                                                  </div>
                                                </ListboxButton>
                                                <Transition
                                                  show={open}
                                                  leave="transition ease-in duration-100"
                                                  leaveFrom="opacity-100"
                                                  leaveTo="opacity-0"
                                                >
                                                  <ListboxOptions
                                                    className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                  >
                                                    {variableTypeList.map((board: any) => (

                                                      <Listbox.Option
                                                        key={board.id}
                                                        className={({ active }) =>
                                                          classNames(
                                                            active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                            !active ? 'text-dark-black-100 text-[13px]' : '',
                                                            'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                          )
                                                        }
                                                        value={board}
                                                      >
                                                        {({ selected }) => (
                                                          <span
                                                            className={classNames(
                                                              selected ? 'font-semibold' : 'font-normal',
                                                              'block truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full overflow-hidden text-ellipsis text-start'
                                                            )}
                                                          >
                                                            {board?.name}
                                                          </span>

                                                        )}
                                                      </Listbox.Option>
                                                    ))}
                                                  </ListboxOptions>
                                                </Transition>
                                              </div>
                                            )}
                                          </Listbox>
                                        </div>

                                      </div>
                                      <div className="w-full flex items-center justify-between lg:justify-start py-1 mt-3 gap-x-4">
                                        <div className="py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[32%] ">
                                          Is mandatory
                                        </div>
                                        <div className="relative w-[68%] flex items-center">
                                          <Switch
                                            checked={item.input_mandatory}
                                            onChange={() => handleToggle(index)}
                                            className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                          >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                              aria-hidden="true"
                                              className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out ${item.input_mandatory ? 'bg-indigo-600' : 'bg-gray-200'
                                                }`}
                                            />
                                            <span
                                              aria-hidden="true"
                                              className={`pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border bg-white shadow ring-0 transition-transform duration-200 ease-in-out ${item.input_mandatory ? 'translate-x-5' : 'translate-x-0'
                                                }`}
                                            />
                                          </Switch>


                                        </div>
                                      </div>
                                      <div className="w-full text-[12px] flex items-center justify-between lg:justify-start py-1 gap-x-4 pt-4">

                                        <label className='w-[32%] font-normal text-dark-black-200 text-[13px] py-1 px-0 '>  Assign to </label>



                                        <nav className='relative w-[68%] h-full bg-[#f4f5f7] rounded-md p-[6px]' aria-label='Tabs'>
                                          {tabs.map((tab) => (
                                            <motion.a
                                              key={tab.name}
                                              onClick={() => handleTabClick(tab.name)}
                                              href={tab.href}
                                              initial="hidden"
                                              animate="visible"
                                              variants={variants}
                                              transition={{ duration: 0.3 }}
                                              className={classNames(
                                                tab.name === openFeedbackTab
                                                  ? 'border-none bg-[#4a5562] rounded-md text-[#fff]'
                                                  : 'py-1 w-1/2 cursor-pointer select-none focus:outline-none',
                                                'py-1 w-1/2 cursor-pointer select-none group inline-flex capitalize items-center text-[12px] font-medium rounded-md justify-center'
                                              )}

                                            >
                                              <span>{tab.name}</span>
                                            </motion.a>
                                          ))}
                                        </nav>


                                      </div>
                                      {openFeedbackTab === 'internal' && (
                                        <motion.div
                                          className="mt-4"
                                          initial={{ opacity: 1, y: 0 }}
                                          animate={{ opacity: 1, y: 0 }}
                                          transition={{ duration: 0.8 }}
                                        >
                                          <div className="relative w-[100%]">
                                            <Listbox value={selectedMember}
                                              onChange={(member) => handleMemberSelect(member, item.id)}
                                            >
                                              {({ open }) => (
                                                <div>
                                                  <label
                                                    className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
          before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
           peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
           after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
          before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                                  >
                                                    Member

                                                  </label>
                                                  <Listbox.Button
                                                    className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left border-2 rounded-[7px] px-3 py-2.5 text-sm border-indigo-600 border-t-transparent focus:outline-none disabled:bg-blue-gray-50 disabled:border-0 disabled:cursor-not-allowed"
                                                  >
                                                    <div className="flex items-center justify-between truncate text-[13px] capitalize leading-4  w-full font-medium ">
                                                  
                                                      {selectedMember?.baseMember?.name || matchedMember?.name || "Select an option"}

                                                      <ChevronDownIcon />
                                                    </div>
                                                  </Listbox.Button>
                                                  <Transition
                                                    show={open}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                  >
                                                    <Listbox.Options
                                                      className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                    >
                                                      {isInputList.data.internal.length > 0 ? (
                                                        isInputList?.data?.internal.map((board, idx) => {

                                                          const baseMember = baseMemberDetails?.data?.baseMembers?.find(
                                                            (member) => member.id == board.member_id
                                                          );


                                                          return (
                                                            <Listbox.Option
                                                              key={board.id}
                                                              className={({ active }) =>
                                                                classNames(
                                                                  active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                                  !active ? 'text-dark-black-100 text-[13px]' : '',
                                                                  'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                                )
                                                              }
                                                              value={{ baseMember, item: board }}
                                                            >

                                                              {({ selected }) => (
                                                                <span
                                                                  className={classNames(
                                                                    selected ? 'font-semibold capitalize' : 'font-normal capitalize',
                                                                    'flex items-center gap-x-2 truncate font-medium text-[13px] leading-4 text-start'
                                                                  )}
                                                                >
                                                                  <span className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 
                                                       ring-1 ring-inset ring-gray-500/10 w-fit'>
                                                                    {board.member_position}
                                                                  </span>
                                                                  {
                                                                    baseMember?.profile_pic ? (
                                                                      <div className=' relative flex justify-center items-center'>
                                                                        <img
                                                                          src={`${process.env.REACT_APP_API_BASE_URL}/${baseMember?.profile_pic}`}
                                                                          alt={`${baseMember?.name}'s profile`}
                                                                          className="w-6 h-6 object-cover rounded-full"
                                                                        />
                                                                        <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                                                     rounded-full' style={{ backgroundColor: board?.member_colour }}></span>

                                                                      </div>
                                                                    ) : (
                                                                      <div className=' relative flex justify-center items-center'>
                                                                        <span className="w-6 h-6 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                                                          {baseMember?.name?.charAt(0)}
                                                                        </span>
                                                                        <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                                                           rounded-full' style={{ backgroundColor: board.member_colour }}></span>

                                                                      </div>
                                                                    )}
                                                                  <span className='block truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[150px] w-full overflow-hidden text-ellipsis text-start'>
                                                                    {baseMember?.name && ` ${baseMember.name}`}
                                                                  </span>


                                                                </span>

                                                              )}
                                                            </Listbox.Option>
                                                          );
                                                        })
                                                      ) : (
                                                        <div className="px-3 py-2 text-[13px] text-gray-500 text-center">
                                                          Nothing here
                                                        </div>
                                                      )}
                                                    </Listbox.Options>
                                                  </Transition>
                                                </div>
                                              )}
                                            </Listbox>
                                          </div>

                                        </motion.div>
                                      )}
                                      {openFeedbackTab === 'external' && (
                                        <motion.div
                                          className="mt-4"
                                          initial={{ opacity: 1, y: 0 }}
                                          animate={{ opacity: 1, y: 0 }}
                                          transition={{ duration: 0.8 }}
                                        >
                                          <div className="relative">
                                            <button
                                              type="button"
                                              className="flex items-center justify-between h-[40px] px-4 w-full py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                                              onClick={() => setIsOpenInput(!isOpenInput)}
                                            >
                                              <span className="mr-2 text-[13px] text-[#374151] new-action">
                                                {
                                                  selectedRecipientInput
                                                    ? (
                                                      <span className=" font-semibold">
                                                        {selectedRecipientInput.contact_first_name || ''}
                                                        {selectedRecipientInput.contact_last_name || ''}
                                                        {!selectedRecipientInput.contact_last_name && !selectedRecipientInput.contact_first_name || ' - '}
                                                      </span>
                                                    )
                                                    : 'Start typing name or email'
                                                }
                                                {selectedRecipientInput?.contact_email && (
                                                  <span className="text-[14px]">
                                                    {selectedRecipientInput.contact_email}
                                                  </span>
                                                )}

                                              </span>

                                              <svg
                                                className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${isOpenInput ? 'transform rotate-180' : ''}`}
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                            </button>
                                            {isOpenInput && (
                                              <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                                  <div className="px-4 py-2">
                                                    <input
                                                      type="text"
                                                      className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                                      placeholder="Start typing name or email"
                                                      value={searchInput}
                                                      onChange={handleSearchChangeInput}
                                                    />
                                                  </div>
                                                  <div className='max-h-[200px] overflow-auto'>
                                                    {filteredRecipients.length > 0 ? (
                                                      filteredRecipients.map((recipient, index) => (
                                                        <a
                                                          key={index}
                                                          href="#"
                                                          className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                          onClick={() => handleRecipientSelectInput(recipient)} 
                                                        >
                                                          <div className="flex items-center">
                                                            <div className=' relative flex justify-center items-center'>
                                                              <span
                                                                className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-black capitalize bg-gray-300">
                                                                {
                                                                  recipient?.contact_first_name && recipient?.contact_last_name
                                                                    ? recipient.contact_first_name.charAt(0).toLowerCase() 
                                                                    : recipient?.contact_last_name
                                                                      ? recipient.contact_last_name.charAt(0).toLowerCase() 
                                                                      : recipient?.contact_email
                                                                        ? recipient.contact_email.charAt(0).toLowerCase()
                                                                        : null
                                                                }
                                                              </span>
                                                              <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                                                     rounded-full' style={{ backgroundColor: recipient.item_member_colour }}></span>
                                                            </div>
                                                            <div className='flex flex-col gap-y-[2px] ml-2'>
                                                              <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis capitalize">{recipient.contact_first_name} {recipient.contact_last_name}</span>
                                                              <span className="text-[12px] text-[#374151] leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis">{recipient.contact_email}</span>
                                                            </div>
                                                          </div>
                                                        </a>
                                                      ))
                                                    ) : (
                                                      <p className="text-gray-500 text-center py-2">No recipients found</p>
                                                    )}
                                                  </div>
                                                  <a href="#" onClick={handleNewActionEnteralInput} className="flex items-center gap-x-1 px-4 py-2 hover:bg-gray-100">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width={24}
                                                      height={24}
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      stroke="currentColor"
                                                      strokeWidth={2}
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      className="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus w-[1rem] h-[1rem] text-[#ff591e]"
                                                    >
                                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                      <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                                      <path d="M9 12h6" />
                                                      <path d="M12 9v6" />
                                                    </svg>

                                                    <span className='font-semibold text-[#ff591e] hover:text-[#ff591e]'>
                                                      Create new recipient
                                                    </span>
                                                  </a>
                                                </div>
                                              </div>
                                            )}
                                            
                                          </div>


                                        </motion.div>
                                      )}

                                      <div className='flex items-center justify-between mt-5 mb-2'>
                                        <button className='underline text-[13px] text-[#b91c1c]' onClick={() => handleDeleteClick(item.id, item.input_title, item.input_count)}>
                                          Delete
                                        </button>
                                     
                                        <Transition.Root show={showDeleteConfirmation1} as={Fragment}>
                                          <Dialog as="div" className="relative z-50" onClose={() => setShowDeleteConfirmation1(false)}>
                                            <Transition.Child
                                              as={Fragment}
                                              enter="ease-out duration-300"
                                              enterFrom="opacity-0"
                                              enterTo="opacity-100"
                                              leave="ease-in duration-200"
                                              leaveFrom="opacity-100"
                                              leaveTo="opacity-0"
                                            >
                                              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                            </Transition.Child>

                                            <div className="fixed inset-0 z-10 overflow-y-auto">
                                              <div className="flex min-h-full justify-center p-4 text-center items-center">
                                                <Transition.Child
                                                  as={Fragment}
                                                  enter="ease-out duration-300"
                                                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                  leave="ease-in duration-200"
                                                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                >
                                                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[500px] sm:p-6">
                                                    <div className="mt-2">
                                                      <p className="text-sm text-gray-500">
                                                        Are you sure you want to delete your Variable?
                                                      </p>
                                                    </div>
                                                    <div className="mt-5 sm:mt-4 flex gap-2 justify-end">
                                                      <button
                                                        type="button"
                                                        onClick={() => handledeleteSpanByName(item.id, item.input_title)}
                                                        className="w-fit flex items-center gap-2 justify-center rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-indigo-600 focus:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
                                                      >
                                                        {inputLoadingSpan ? (
                                                          <>
                                                            Delete
                                                            <div className="simple-spinner">
                                                              <span></span>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          'Delete'
                                                        )}
                                                      </button>
                                                      <button
                                                        type="button"
                                                        onClick={() => setShowDeleteConfirmation1(false)}
                                                        className="mt-3 inline-flex leading-6 w-fit justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                      >
                                                        Cancel
                                                      </button>
                                                    </div>
                                                  </Dialog.Panel>
                                                </Transition.Child>
                                              </div>
                                            </div>
                                          </Dialog>
                                        </Transition.Root>
                                        <button className=" flex items-center gap-2 w-fit  justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm
                     hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                          onClick={() => handleEnterClick(item.input_title, item.input_count, item.id)}
                                        >
                                          {inputLoading[item.id] ? (
                                            <>
                                              Save
                                              <div className="simple-spinner">
                                                <span></span>
                                              </div>
                                            </>
                                          ) : (
                                            'Save'
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>

                            )
                          })
                          }
                          <div className='bg-[#e5e7eb] w-[-webkit-fill-available] h-[1px] mx-[-18px] px-[20px] my-4'></div>

                          {variables.length > 0 && (
                            <>
                              {variables
                                .filter(variable =>
                                  !fetchInputList?.some(item => item.input_title.toLowerCase() === variable.name.toLowerCase())
                                )
                                .map((variable, index) => {

                                  return (
                                    <div key={index} className="flex flex-col gap-y-2">
                                      <div
                                        className={`flex-auto ${expandedVariable1 === variable.name ? 'rounded-t-md' : 'rounded-md'} p-3 cursor-pointer ring-1 ring-inset ring-gray-200 flex justify-between`}

                                      >
                                        <div className='flex flex-col gap-y-[4px]'>
                                          <div className='flex items-center gap-1'>
                                            <span className='text-dark-black-200 text-[12px]'> {variableTypeLocal[index]?.name}</span>
                                            <span className="ml-1 bg-blue-50 text-blue-700 ring-1 ring-inset ring-blue-700/10 rounded-full w-4 h-4 flex items-center justify-center text-xs font-medium">
                                              {variable.count}
                                            </span>
                                          </div>

                                          <span className="text-[#52555a] text-[0.8125rem] font-medium leading-[1.2] capitalize">{variable.name} </span>
                                          {variableType1?.name &&

                                            <span className="inline-flex capitalize w-fit items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                                              {variableType1?.name}
                                            </span>
                                          }

                            
                                        </div>
                                        <div className='flex items-start gap-x-3'>
                                         

                                          <span onClick={() => toggleAccordionLocal(variable.name)}>
                                            <ChevronDownIcon />
                                          </span>
                                        </div>

                                      </div>

                                      {expandedVariable1 === variable.name && (
                                        <div className="p-3 ring-1 ring-inset ring-gray-200 rounded-b-md mt-[-9px]">

                                          <div className="w-full pt-[1rem]">
                                            <div className="w-full flex items-center justify-between lg:justify-start py-1">
                              
                                              <div className="relative w-[100%]">
                                                <Listbox value={variableTypeLocal[index]} 
                                                  onChange={(newValue) => {
                                                    const updatedVariableTypes = [...variableTypeLocal];
                                                    updatedVariableTypes[index] = newValue; 
                                                    setVariableTypeLocal(updatedVariableTypes);
                                                  }}>
                                                  {({ open }) => (
                                                    <div>
                                                      <label
                                                        className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                                      >
                                                        Input type

                                                      </label>
                                                      <ListboxButton
                                                        className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                                      >
                                                        <div className='flex items-center justify-between'>
                                                          <span
                                                            className="flex items-center truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
overflow-hidden text-ellipsis text-start font-medium"
                                                          >
                                                            {variableTypeLocal[index]?.name}

                                                          </span>
                                                          <ChevronDownIcon />
                                                        </div>
                                                      </ListboxButton>
                                                      <Transition
                                                        show={open}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                      >
                                                        <ListboxOptions
                                                          className="absolute z-10 w-full left-[0px] mt-1 max-h-[150px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                        >
                                                          {variableTypeList.map((board: any) => (
                                                            <Listbox.Option
                                                              key={board.id}
                                                              className={({ active }) =>
                                                                classNames(
                                                                  active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                                  !active ? 'text-dark-black-100 text-[13px]' : '',
                                                                  'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                                )
                                                              }
                                                              value={board}
                                                            >
                                                              {({ selected }) => (
                                                                <span
                                                                  className={classNames(
                                                                    selected ? 'font-semibold' : 'font-normal',
                                                                    'block truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full overflow-hidden text-ellipsis text-start'
                                                                  )}
                                                                >
                                                                  {board?.name}
                                                                </span>

                                                              )}
                                                            </Listbox.Option>
                                                          ))}
                                                        </ListboxOptions>
                                                      </Transition>
                                                    </div>
                                                  )}
                                                </Listbox>
                                              </div>
                                            </div>
                                            <div className="w-full flex items-center justify-between lg:justify-start py-1 mt-3 gap-x-4">
                                              <div className="py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[32%] ">
                                                Is mandatory
                                              </div>
                                              <div className="relative w-[68%] flex items-center">
                                                <Switch
                                                  checked={isEnabled}
                                                  onChange={handleToggleLocal}
                                                  className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                                >
                                                  <span className="sr-only">Use setting</span>
                                                  <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                                                  <span
                                                    aria-hidden="true"
                                                    className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out group-data-[checked]:bg-indigo-600"
                                                  />
                                                  <span
                                                    aria-hidden="true"
                                                    className="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                  />
                                                </Switch>
                                              </div>
                                            </div>
                                            <div className="w-full text-[12px] flex items-center justify-between lg:justify-start py-1 gap-x-4 pt-4">

                                              <label className='w-[32%] font-normal text-dark-black-200 text-[13px] py-1 px-0 '>  Assign to </label>



                                              <nav className='relative w-[68%] h-full bg-[#f4f5f7] rounded-md p-[6px]' aria-label='Tabs'>
                                                {tabs.map((tab) => (
                                                  <motion.a
                                                    key={tab.name}
                                                    onClick={() => setOpenFeedbackTab(tab.name)}
                                                    href={tab.href}
                                                    initial="hidden"
                                                    animate="visible"
                                                    variants={variants}
                                                    transition={{ duration: 0.3 }}
                                                    className={classNames(
                                                      tab.name === openFeedbackTab
                                                        ? 'border-none bg-[#4a5562] rounded-md text-[#fff]'
                                                        : 'py-1 w-1/2 cursor-pointer select-none focus:outline-none',
                                                      'py-1 w-1/2 cursor-pointer select-none group inline-flex capitalize items-center text-[12px] font-medium rounded-md justify-center'
                                                    )}
                                                  >
                                                    <span>{tab.name}</span>
                                                  </motion.a>
                                                ))}
                                              </nav>


                                            </div>
                                            {openFeedbackTab === 'internal' && (
                                              <motion.div
                                                className="mt-4"
                                                initial={{ opacity: 1, y: 0 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: 0.8 }}
                                              >
                                                <div className="relative w-[100%]">
                                                  <Listbox
                                                    value={variableFromLocal[index]}
                                                    onChange={(member) => handleMemberSelectLocal(member, index)} 

                                                  >
                                                    {({ open }) => (
                                                      <div>
                                                        <label
                                                          className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                                        >
                                                          Member

                                                        </label>
                                                        <Listbox.Button
                                                          onClick={handlebaseInputlist}
                                                          className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left border-2 rounded-[7px] px-3 py-2.5 text-sm border-indigo-600 border-t-transparent focus:outline-none disabled:bg-blue-gray-50 disabled:border-0 disabled:cursor-not-allowed"
                                                        >
                                                          <div className="flex items-center justify-between">
                                                            <span className="truncate text-[13px] capitalize leading-4 max-w-[140px] w-full font-medium">
                                                              {variableFromLocal[index] ? variableFromLocal[index].name : "Select an option"}

                                                            </span>
                                                            <ChevronDownIcon />
                                                          </div>
                                                        </Listbox.Button>
                                                        <Transition
                                                          show={open}
                                                          leave="transition ease-in duration-100"
                                                          leaveFrom="opacity-100"
                                                          leaveTo="opacity-0"
                                                        >
                                                          <Listbox.Options
                                                            className="absolute z-10 w-full left-[0px] mt-1 max-h-[100px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                          >
                                                            {isInputList.data.internal.length > 0 ? (
                                                              isInputList?.data?.internal.map((board) => {

                                                                const baseMember = baseMemberDetails?.data?.baseMembers?.find(
                                                                  (member) => member.id === board.internal_user_id
                                                                );

                                                                return (
                                                                  <Listbox.Option
                                                                    key={board.id}
                                                                    className={({ active }) =>
                                                                      classNames(
                                                                        active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                                        !active ? 'text-dark-black-100 text-[13px]' : '',
                                                                        'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                                      )
                                                                    }
                                                                    value={baseMember}

                                                                  >

                                                                    {({ selected }) => (
                                                                      <span
                                                                        className={classNames(
                                                                          selected ? 'font-semibold capitalize' : 'font-normal capitalize',
                                                                          'flex items-center gap-x-2 truncate font-medium text-[13px] leading-4 text-start'
                                                                        )}
                                                                      >
                                                                        {
                                                                          baseMember?.profile_pic ? (
                                                                            <div className=' relative flex justify-center items-center'>
                                                                              <img
                                                                                src={`${process.env.REACT_APP_API_BASE_URL}/${baseMember?.profile_pic}`}
                                                                                alt={`${baseMember?.name}'s profile`}
                                                                                className="w-6 h-6 object-cover rounded-full"
                                                                              />
                                                                              <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                                   rounded-full' style={{ backgroundColor: board?.item_member_colour }}></span>

                                                                            </div>
                                                                          ) : (
                                                                            <div className=' relative flex justify-center items-center'>
                                                                              <span className="w-6 h-6 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                                                                {baseMember?.name?.charAt(0)}
                                                                              </span>
                                                                              <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                                         rounded-full' style={{ backgroundColor: board.item_member_colour }}></span>

                                                                            </div>
                                                                          )}
                                                                        <span className='block truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[250px] w-full overflow-hidden text-ellipsis text-start'>
                                                                          {baseMember?.name && ` ${baseMember.name}`}
                                                                        </span>


                                                                      </span>

                                                                    )}
                                                                  </Listbox.Option>
                                                                );
                                                              })
                                                            ) : (
                                                              <div className="px-3 py-2 text-[13px] text-gray-500 text-center">
                                                                Nothing here
                                                              </div>
                                                            )}
                                                          </Listbox.Options>
                                                        </Transition>
                                                      </div>
                                                    )}
                                                  </Listbox>
                                                </div>

                                              </motion.div>
                                            )}
                                            {openFeedbackTab === 'external' && (
                                              <motion.div
                                                className="mt-4"
                                                initial={{ opacity: 1, y: 0 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: 0.8 }}
                                              >
                                                <div className="relative">
                                                  <button
                                                    type="button"
                                                    className="flex items-center justify-between h-[40px] px-4 w-full py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                                                    onClick={() => setIsOpenInput(!isOpenInput)}
                                                  >
                                                    <span className="mr-2 text-[13px] text-[#374151] new-action">
                                                      {
                                                        selectedRecipientInput
                                                          ? (
                                                            <span className=" font-semibold">
                                                              {selectedRecipientInput.contact_first_name || ''}
                                                              {selectedRecipientInput.contact_last_name || ''}
                                                              {!selectedRecipientInput.contact_last_name && !selectedRecipientInput.contact_first_name || ' - '}
                                                            </span>
                                                          )
                                                          : 'Start typing name or email'
                                                      }
                                                      {selectedRecipientInput?.contact_email && (
                                                        <span className="text-[14px]">
                                                          {selectedRecipientInput.contact_email}
                                                        </span>
                                                      )}

                                                    </span>

                                                    <svg
                                                      className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${isOpenInput ? 'transform rotate-180' : ''}`}
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 20 20"
                                                      fill="currentColor"
                                                      aria-hidden="true"
                                                    >
                                                      <path
                                                        fillRule="evenodd"
                                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                      />
                                                    </svg>
                                                  </button>
                                                  {isOpenInput && (
                                                    <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                                        <div className="px-4 py-2">
                                                          <input
                                                            type="text"
                                                            className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                                            placeholder="Start typing name or email"
                                                            value={searchInput}
                                                            onChange={handleSearchChangeInput}
                                                          />
                                                        </div>
                                                        <div className='max-h-[200px] overflow-auto'>
                                                          {filteredRecipients.length > 0 ? (
                                                            filteredRecipients.map((recipient, index) => (
                                                              <a
                                                                key={index}
                                                                href="#"
                                                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                                onClick={() => handleRecipientSelectInput(recipient)} 
                                                              >
                                                                <div className="flex items-center">
                                                                  <div className=' relative flex justify-center items-center'>
                                                                    <span
                                                                      className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-black capitalize bg-gray-300">
                                                                      {
                                                                        recipient?.contact_first_name && recipient?.contact_last_name
                                                                          ? recipient.contact_first_name.charAt(0).toLowerCase()
                                                                          : recipient?.contact_last_name
                                                                            ? recipient.contact_last_name.charAt(0).toLowerCase() 
                                                                            : recipient?.contact_email
                                                                              ? recipient.contact_email.charAt(0).toLowerCase()
                                                                              : null
                                                                      }
                                                                    </span>
                                                                    <span className='absolute right-[0px] bottom-[0px] w-[10px] h-[10px]
                                                   rounded-full' style={{ backgroundColor: recipient.item_member_colour }}></span>
                                                                  </div>
                                                                  <div className='flex flex-col gap-y-[2px] ml-2'>
                                                                    <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis capitalize">{recipient.contact_first_name} {recipient.contact_last_name}</span>
                                                                    <span className="text-[12px] text-[#374151] leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis">{recipient.contact_email}</span>
                                                                  </div>
                                                                </div>
                                                              </a>
                                                            ))
                                                          ) : (
                                                            <p className="text-gray-500 text-center py-2">No recipients found</p>
                                                          )}
                                                        </div>
                                                        <a href="#" onClick={handleNewActionEnteralInput} className="flex items-center gap-x-1 px-4 py-2 hover:bg-gray-100">
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus w-[1rem] h-[1rem] text-[#ff591e]"
                                                          >
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                                            <path d="M9 12h6" />
                                                            <path d="M12 9v6" />
                                                          </svg>

                                                          <span className='font-semibold text-[#ff591e] hover:text-[#ff591e]'>
                                                            Create new recipient
                                                          </span>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  )}
                                                
                                                </div>


                                              </motion.div>
                                            )}

                                            <div className='flex items-center justify-between mt-3'>
                                              <button className='underline text-[13px] text-[#b91c1c]' onClick={() => handleDeleteClickLocal(variable.name, variable.count)}>
                                                Delete
                                              </button>
                                              <Transition.Root show={showDeleteConfirmation1Local} as={Fragment}>
                                                <Dialog as="div" className="relative z-50" onClose={() => setShowDeleteConfirmation1Local(false)}>
                                                  <Transition.Child
                                                    as={Fragment}
                                                    enter="ease-out duration-300"
                                                    enterFrom="opacity-0"
                                                    enterTo="opacity-100"
                                                    leave="ease-in duration-200"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                  >
                                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                                  </Transition.Child>

                                                  <div className="fixed inset-0 z-10 overflow-y-auto">
                                                    <div className="flex min-h-full justify-center p-4 text-center items-center">
                                                      <Transition.Child
                                                        as={Fragment}
                                                        enter="ease-out duration-300"
                                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                        leave="ease-in duration-200"
                                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                      >
                                                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[500px] sm:p-6">
                                                          <div className="mt-2">
                                                            <p className="text-sm text-gray-500">
                                                              Are you sure you want to delete your Variable?
                                                            </p>
                                                          </div>
                                                          <div className="mt-5 sm:mt-4 flex gap-2 justify-end">
                                                            <button
                                                              type="button"
                                                              onClick={() => handledeleteSpanByNameLocal(variable.name)}
                                                              className="w-fit inline-flex justify-center rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-indigo-600 focus:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
                                                            >
                                                              Delete
                                                            </button>
                                                            <button
                                                              type="button"
                                                              onClick={() => setShowDeleteConfirmation1Local(false)}
                                                              className="mt-3 inline-flex leading-6 w-fit justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                            >
                                                              Cancel
                                                            </button>
                                                          </div>
                                                        </Dialog.Panel>
                                                      </Transition.Child>
                                                    </div>
                                                  </div>
                                                </Dialog>
                                              </Transition.Root>
                                              <button className=" flex items-center gap-2 w-fit  justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm
   hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                onClick={() => handleEnterClickLocal(variable.name, variable.count, index)}
                                              >
                                                {inputLoading1 ? (
                                                  <>
                                                    Save
                                                    <div className="simple-spinner">
                                                      <span></span>
                                                    </div>
                                                  </>
                                                ) : (
                                                  'Save'
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )
                                })}
                            </>
                          )}
                        </div>




                      </div>
                    </div>
                  </div>
                </div>

              </div> */}

              {/* activity */}

              <SendDocumentFill isAsideOpen6={isAsideOpen6} inputFill={inputFill} setInputFill={setInputFill}
                itemFillLoading={itemFillLoading} externalMembers={externalMembers}
              />

              <SendDocumentDetailsActivity
                isAsideOpen2={isAsideOpen2}
                itemActivity={itemActivity}
                itemActivityLoading={itemActivityLoading} />

              {/* chat */}

              <SendDocumentDetailsChat
                isAsideOpen3={isAsideOpen3}
                itemActivityLoading={itemActivityLoading}
                itemActivityChat={itemActivityChat} />

              {/* action */}
              <SendDocumentDetailsAction
                isAsideOpen4={isAsideOpen4}
                isActionList={isActionList}
                setIsActionList={setIsActionList}
                variants={variants}
                isActionListNew={isActionListNew}
                baseMemberDetails={baseMemberDetails}
                recipients={recipients}
                setRecipients={setRecipients}
                recipients1={recipients1}
                setRecipients1={setRecipients1}
                recipients2={recipients2}
                setRecipients2={setRecipients2}
                recipients3={recipients3}
                setRecipients3={setRecipients3}
                recipients4={recipients4}
                setRecipients4={setRecipients4}
                recipients5={recipients5}
                setRecipients5={setRecipients5}

              />

              {/* member */}


              <div
                className={`main-feedback-side flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 
         border-solid absolute  lg:fixed top-[60px] max-w-[80%] border-r-0 !overflow-y-auto overflow-x-hidden ' `}
                style={{
                  width: isAsideOpen5 ? '350px' : '350px',
                  transform: isAsideOpen5 ? 'none' : 'none',
                  right: isAsideOpen5 ? '51px' : '0px',
                  marginRight: isAsideOpen5 ? '0px' : '-350px',
                  // transition: 'all 0.3s ease-in-out',

                  height: isAsideOpen5 ? 'calc(100vh - +60px)' : '',
                  ...(window.innerWidth <= 768 && {
                    width: isAsideOpen5 ? '300px' : '300px',
                    marginRight: isAsideOpen5 ? '0' : '-300px',
                    // transition: 'all 0.3s ease-in-out'
                  }),
                }}
              >
                <div className='h-full'>
                  <div className='h-auto flex flex-col justify-stretch min-h-[3rem] 
                            border-b-0 border-l-0 border-solid border-light-gray-200 p-[1rem] pt-[0.9rem] items-start gap-4'>
                    <div className=" flex items-start justify-center w-full">
                      <div className="mx-auto w-full">
                        <p className="text-center text-wedges-gray text-sm" />
                        <div className="flex items-center max-w-full w-fit mb-5 font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] ml-[-4px] py-[5px] gap-2">
                          <span className="text-[#52555a] capitalize"> Member</span>
                        </div>
                        <div className="mb-2">
                          <div className='flex justify-between items-center'>
                            <a
                              href="#"
                              onClick={toggleCollapseMember}
                              className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                 focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                            >
                              <span className='flex mr-[6px] h-[8px] w-[8px] shrink-0
             items-center justify-center rounded-[20px] border-none
              bg-[#15803d]'>

                              </span>
                              Internal

                              <div className={`${toogleCollapseMem ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                                <svg
                                  width={24}
                                  height={24}
                                  fill="none"
                                  className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M15.25 10.75 12 14.25l-3.25-3.5"
                                  />
                                </svg>
                              </div>
                            </a>
                            <button className='underline text-[13px] text-[#266df0]' onClick={handleNewActionMember}>
                              Add
                            </button>

                          </div>

                          {
                            newActionMember &&
                            <AnimatePresence initial={false}>
                              {!toogleCollapseMem && (
                                <motion.div
                                  initial={{ height: 0 }}
                                  animate={{ height: 'auto' }}
                                  exit={{ height: 0, }}
                                  transition={{ height: { duration: 0.5 }, }}
                                  className={`${isOverflowHidden ? 'overflow-hidden z-10' : ''}`}
                                >
                                  <div className="relative w-[100%] mt-5">
                                    <Listbox value={variableType7} onChange={setVariableType7}>
                                      {({ open }) => (
                                        <div>
                                          <label
                                            className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
   before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
   peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
   after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
   before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                          >
                                            Input type
                                          </label>
                                          <Listbox.Button
                                            onClick={handlebaseChannelList}
                                            className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
 disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                          >
                                            <div className='flex items-center justify-between'>
                                              <span
                                                className="flex capitalize items-center gap-x-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
     overflow-hidden text-ellipsis text-start font-medium"
                                              >
                                                {variableType7 ? variableType7.name : "Select an option"}

                                              </span>
                                              <ChevronDownIcon />
                                            </div>
                                          </Listbox.Button>
                                          <Transition
                                            show={open}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <Listbox.Options
                                              className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                            >
                                              {baseMemberDetailsArray.length > 0 ? (
                                                baseMemberDetailsArray.map((board: any) => (
                                                  <Listbox.Option
                                                    key={board.id}
                                                    className={({ active }) =>
                                                      classNames(
                                                        active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                        !active ? 'text-dark-black-100 text-[13px]' : '',
                                                        'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                      )
                                                    }
                                                    value={board}
                                                  >
                                                    {({ selected }) => (
                                                      <span
                                                        className={classNames(
                                                          selected ? 'font-semibold capitalize' : 'font-normal capitalize',
                                                          'flex items-center gap-x-2 truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full overflow-hidden text-ellipsis text-start'
                                                        )}
                                                      >
                                                        {
                                                          board?.profile_pic ? (
                                                            <img
                                                              src={`${process.env.REACT_APP_API_BASE_URL}/${board?.profile_pic}`}
                                                              alt={`${board.name}'s profile`}
                                                              className="w-5 h-5 object-cover rounded-full"
                                                            />
                                                          ) : (
                                                            <span className="w-5 h-5 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                                              {board.name.split(' ').map((word) => word.charAt(0)).join('').toUpperCase()}
                                                            </span>
                                                          )
                                                        }
                                                        {board?.name}
                                                      </span>
                                                    )}
                                                  </Listbox.Option>
                                                ))
                                              ) : (
                                                <div className="px-3 py-2 text-[13px] text-gray-500 text-center">
                                                  Nothing here
                                                </div>
                                              )}
                                            </Listbox.Options>
                                          </Transition>
                                        </div>

                                      )}
                                    </Listbox>
                                  </div>
                                  <div className='mt-5 flex justify-end'>
                                    <button
                                      onClick={handleActionInternalMember}
                                      className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                     font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                      focus-visible:outline-2 focus-visible:outline-offset-2
                       focus-visible:outline-indigo-600'>
                                      {isActionMemberLoading ? (
                                        <>
                                          Add Member
                                          <div className="simple-spinner">
                                            <span></span>
                                          </div>
                                        </>
                                      ) : (
                                        'Add Member'
                                      )}
                                    </button>
                                  </div>
                                </motion.div>
                              )}
                            </AnimatePresence>
                          }

                          {/* list data */}
                          {
                            !newActionMember &&
                            <AnimatePresence initial={false}>
                              {!toogleCollapseMem && (
                                <motion.div
                                  initial={{ height: 0 }}
                                  animate={{ height: 'auto' }}
                                  exit={{ height: 0, }}
                                  transition={{ height: { duration: 0.5 }, }}
                                  className="overflow-hidden"
                                >
                                  {/* {
                                    isActionListNew?.data?.internal.length > 0 &&
                                    <span className='font-normal text-dark-black-200 text-[12px] my-2 block'>Internal - </span>
                                  } */}

                                  {isActionListNew?.data?.internal &&
                                    isActionListNew.data?.internal.map((internalAction, index) => {

                                      // Assuming `baseMemberDetails` is an array and you want to find the member matching `internal_user_id`
                                      const baseMember = baseMemberDetails?.data?.baseMembers?.find(
                                        (member) => member.id == internalAction.member_id
                                      );
                                      return (
                                        <div
                                          key={internalAction.id || index}
                                          className="flex mt-4 gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                        >

                                          <div className="flex items-center gap-3">
                                            <div className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                              {internalAction.member_position}
                                            </div>
                                            {
                                              baseMember?.profile_pic ? (
                                                <div className=' relative flex justify-center items-center'>
                                                  <img
                                                    src={`${process.env.REACT_APP_API_BASE_URL}/${baseMember?.profile_pic}`}
                                                    alt={`${baseMember.name}'s profile`}
                                                    className="w-6 h-6 object-cover rounded-full"
                                                  />
                                                  <span className='absolute right-[-1px] bottom-[-2px] w-[10px] h-[10px] rounded-full' style={{ backgroundColor: internalAction.member_colour }}></span>
                                                </div>


                                              ) : (
                                                <div className="relative flex-none w-6 h-6 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                                  <span className="text-[10px] font-medium text-gray-700 capitalize">
                                                    {baseMember?.name?.charAt(0)}
                                                  </span>
                                                  <span className='absolute right-[-1px] bottom-[-2px] w-[10px] h-[10px] rounded-full' style={{ backgroundColor: internalAction.member_colour }}></span>
                                                </div>
                                              )
                                            }

                                            <p className="leading-relaxed">
                                              <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium capitalize">
                                                {baseMember?.name && ` ${baseMember?.name}`}
                                              </span>
                                            </p>
                                          </div>
                                          <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                            <CustomMenuDropdown
                                              icon={
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="12" cy="12" r="1"></circle>
                                                  <circle cx="12" cy="5" r="1"></circle>
                                                  <circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                              }
                                              altText="User profile"
                                              sections={dropdownGroups1}
                                              menuItemsClassName="left-auto top-[-2px] right-[20px]"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}
                                </motion.div>
                              )}
                            </AnimatePresence>
                          }
                        </div>
                        <div className='bg-[#e5e7eb] w-[-webkit-fill-available] h-[1px] mx-[-20px] px-[20px] my-5'>

                        </div>

                        <div className="mb-2">
                          <div className='flex justify-between items-center'>
                            <a
                              href="#"
                              onClick={toggleCollapseMemberExternal}
                              className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                 focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                            >
                              <span className='flex mr-[6px] h-[8px] w-[8px] shrink-0
             items-center justify-center rounded-[20px] border-none
              bg-[#15803d]'>

                              </span>
                              External

                              <div className={`${toogleCollapseMemExternal ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                                <svg
                                  width={24}
                                  height={24}
                                  fill="none"
                                  className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M15.25 10.75 12 14.25l-3.25-3.5"
                                  />
                                </svg>
                              </div>
                            </a>
                            <button className='underline text-[13px] text-[#266df0]' onClick={handleNewActionMemberExternal}>
                              Add
                            </button>

                          </div>


                          {
                            newActionMemberExternal &&
                            <AnimatePresence initial={false}>
                              {!toogleCollapseMemExternal && (
                                <motion.div
                                  // initial={{ height: 0 }}
                                  // animate={{ height: 'auto' }}
                                  exit={{ height: 0, }}
                                  transition={{ height: { duration: 0.5 }, }}
                                // className="overflow-hidden"
                                >
                                  <div className="relative mt-5">
                                    <button
                                      type="button"
                                      className="flex items-center justify-between h-[40px] px-4 w-full py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                                      onClick={() => setIsOpenExternal(!isOpenExternal)}
                                    >
                                      <span className="mr-2 text-[13px] text-[#374151] new-action">
                                        {
                                          selectedRecipientExternal
                                            ? (
                                              <span className=" font-semibold">
                                                {selectedRecipientExternal.contact_first_name || ''}
                                                {selectedRecipientExternal.contact_last_name || ''}
                                                {!selectedRecipientExternal.contact_last_name && !selectedRecipientExternal.contact_first_name || ' - '}
                                              </span>
                                            )
                                            : 'Start typing name or email'
                                        }
                                        {selectedRecipientExternal?.contact_email && (
                                          <span className="text-[14px]">
                                            {selectedRecipientExternal.contact_email}
                                          </span>
                                        )}

                                      </span>

                                      <svg
                                        className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${isOpenExternal ? 'transform rotate-180' : ''}`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </button>
                                    {isOpenExternal && (
                                      <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                          <div className="px-4 py-2">
                                            <input
                                              type="text"
                                              className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                              placeholder="Start typing name or email"
                                              value={searchExternal}
                                              onChange={handleSearchChangeExternal}
                                            />
                                          </div>
                                          <div className='max-h-[200px] overflow-auto'>
                                            {recipientsExternal.data.contact.map((recipient, index) => (
                                              <a
                                                key={index}
                                                href="#"
                                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                onClick={() => handleRecipientSelectExternal(recipient)} // Update the onClick to handle selection
                                              >
                                                <div className="flex items-center">
                                                  <span
                                                    className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-black capitalize bg-gray-300">
                                                    {
                                                      recipient?.contact_first_name && recipient?.contact_last_name
                                                        ? recipient.contact_first_name.charAt(0).toLowerCase() // Show the first letter of first name in lowercase
                                                        : recipient?.contact_last_name
                                                          ? recipient.contact_last_name.charAt(0).toLowerCase() // Show the first letter of last name in lowercase
                                                          : recipient?.contact_email
                                                            ? recipient.contact_email.charAt(0).toLowerCase() // Show the first letter of email in lowercase
                                                            : null // If none of the above exist, return null
                                                    }




                                                  </span>
                                                  <div className='flex flex-col gap-y-[2px] ml-2'>
                                                    <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis capitalize">{recipient.contact_first_name} {recipient.contact_last_name}</span>
                                                    <span className="text-[12px] text-[#374151] leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis">{recipient.contact_email}</span>
                                                  </div>
                                                </div>
                                              </a>
                                            ))}
                                          </div>
                                          <a href="#" onClick={handleNewActionEnteralData} className="flex items-center gap-x-1 px-4 py-2 hover:bg-gray-100">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={24}
                                              height={24}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth={2}
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              className="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus w-[1rem] h-[1rem] text-[#ff591e]"
                                            >
                                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                              <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                              <path d="M9 12h6" />
                                              <path d="M12 9v6" />
                                            </svg>

                                            <span className='font-semibold text-[#ff591e] hover:text-[#ff591e]'>
                                              Create new recipient
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                    <div className='mt-5 flex justify-end'>
                                      <button
                                        onClick={handleActionExternalContactData}
                                        className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                        {contactLoadingExternal ? (
                                          <>
                                            Add Member
                                            <div className="simple-spinner">
                                              <span></span>
                                            </div>
                                          </>
                                        ) : (
                                          'Add Member'
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                  {
                                    newActionEnteralData &&
                                    <>
                                      <div className="">
                                        <div className='w-full mt-5'>
                                          <div className='flex gap-y-[24px] flex-col'>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_first_name"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                First Name
                                              </label>
                                              <input
                                                id="contact_first_name"
                                                name="contact_first_name"
                                                type="text"
                                                maxLength={30}
                                                value={contactInfoExternal.contact_first_name}
                                                onChange={handleInputChangeExternal}
                                                // placeholder="Jane Smith"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_last_name"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Last Name
                                              </label>
                                              <input
                                                id="contact_last_name"
                                                name="contact_last_name"
                                                type="text"
                                                maxLength={30}
                                                value={contactInfoExternal.contact_last_name}
                                                onChange={handleInputChangeExternal}
                                                // placeholder="Jane Smith"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_email"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Email
                                              </label>
                                              <input
                                                id="contact_email"
                                                name="contact_email"
                                                value={contactInfoExternal.contact_email}
                                                onChange={handleInputChangeExternal}
                                                maxLength={30}
                                                type="email"

                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_phone"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Phone no.
                                              </label>
                                              <input
                                                id="contact_phone"
                                                name="contact_phone"
                                                type="number"
                                                maxLength={30}
                                                value={contactInfoExternal.contact_phone}
                                                onChange={handleInputChangeExternal}
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                            ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_company"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Company
                                              </label>
                                              <input
                                                id="contact_company"
                                                name="contact_company"
                                                value={contactInfoExternal.contact_company}
                                                onChange={handleInputChangeExternal}
                                                maxLength={30}
                                                type="text"
                                                // placeholder="1234567890"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_job_title"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Job Title
                                              </label>
                                              <input
                                                id="contact_job_title"
                                                name="contact_job_title"
                                                value={contactInfoExternal.contact_job_title}
                                                onChange={handleInputChangeExternal}
                                                type="text"
                                                maxLength={1000}
                                                // placeholder="Enter meta value"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>


                                            <div className='w-auto flex justify-end'>
                                              <button
                                                type="button"
                                                onClick={handleDocumentContactSaveExternal}
                                                className=" flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                              // onClick={() => handleSubmitItemCustomer(companyInfo)}
                                              // disabled={isUpdating}
                                              >

                                                {contactLoadingExternal1 ? (
                                                  <>
                                                    Create contact
                                                    <div className="simple-spinner">
                                                      <span></span>
                                                    </div>
                                                  </>
                                                ) : (
                                                  'Create contact'
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  }



                                </motion.div>
                              )}
                            </AnimatePresence>
                          }


                          {
                            !newActionMemberExternal &&
                            <AnimatePresence initial={false}>

                              {!toogleCollapseMemExternal && (
                                <motion.div
                                  initial={{ height: 0 }}
                                  animate={{ height: 'auto' }}
                                  exit={{ height: 0, }}
                                  transition={{ height: { duration: 0.5 }, }}
                                  className="overflow-hidden"
                                >

                                  {/* {
                                    isActionListNew?.data?.external.length > 0 &&
                                    <span className='font-normal text-dark-black-200 text-[12px] my-2 block'>External - </span>
                                  } */}

                                  {/* 
                                  {
                                    isActionListNew?.data?.external?.length > 0 && (
                                      <>
                                        {
                                          [
                                            ...(isActionListNew?.data?.external || []),
                                            ...(isActionListNew?.data?.external || [])
                                          ]
                                            .slice(0, 1) // Take only the first item
                                            .map((item, index) => {
                                              return (
                                                <div key={index} className="flex items-center space-x-2">
                                                  {item.external_contact_id && (
                                                    <span className="font-normal text-dark-black-200 text-[12px]">External - </span>
                                                  )}
                                                </div>
                                              );
                                            })
                                        }
                                      </>
                                    )
                                  } */}





                                  {
                                    isActionListNew?.data?.external?.length > 0 &&
                                    isActionListNew?.data?.external.map((contact, index) => {
                                      // const contact = internalAction.external_contact_id
                                      //   ? internalAction.contact
                                      //   : null; // Check if external_contact_id exists and assign contact

                                      // if (!content) {
                                      //   return null; // Skip rendering if contact is null
                                      // }
                                      // console.log(contact,"contactcontact");


                                      return (
                                        <div
                                          key={contact.content.id || index}
                                          className="flex gap-1.5 mb-4 mt-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                        >
                                          <div className="flex items-center gap-3">
                                            <div className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 
                                          ring-1 ring-inset ring-gray-500/10 w-fit'>
                                              {contact.member_position}
                                            </div>
                                            <div className="relative flex-none w-6 h-6 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">

                                              <span className="text-[10px] font-medium text-gray-700 capitalize">
                                                {
                                                  contact.content.first_name
                                                    ? contact.content.first_name.charAt(0).toLowerCase()
                                                    : contact.content.last_name
                                                      ? contact.content.last_name.charAt(0).toLowerCase()
                                                      : contact.content.email
                                                        ? contact.content.email.charAt(0).toLowerCase()
                                                        : ''
                                                }
                                              </span>
                                              <span className='absolute right-[-1px] bottom-[-2px]
                                               w-[10px] h-[10px] rounded-full' style={{ backgroundColor: contact.member_colour }}></span>
                                            </div>
                                            <div className="flex flex-col">
                                              <p className="leading-relaxed">
                                                <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium  capitalize">
                                                  {contact.content.first_name && `${contact.content.first_name} `}
                                                  {contact.content.last_name && `${contact.content.last_name} `}
                                                </span>
                                              </p>
                                              <p className="leading-relaxed">
                                                <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                  {contact.content.email && `${contact.content.email}`}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="[&>div]:shrink feedback-delete-btn-hover">
                                            <CustomMenuDropdown
                                              icon={
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="12" cy="12" r="1"></circle>
                                                  <circle cx="12" cy="5" r="1"></circle>
                                                  <circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                              }
                                              altText="User profile"
                                              sections={dropdownGroups1}
                                              menuItemsClassName="left-auto top-[-2px] right-[20px]"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })
                                  }

                                </motion.div>
                              )}
                            </AnimatePresence>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* sidebar icons  */}
              <div className='flex flex-col gap-y-4 px-[10px] pb-[10px] pt-4 fixed right-0 bg-white  h-full border-l border-solid border-light-gray-200 '>
                <button
                  onClick={handleFirstBar}
                  data-title="Info"
                  style={{ transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform' }}
                  className={`${isAsideOpen ? 'bg-gray-200' : 'bg-white'} table-tooltip-sidebar table-tooltip-info rounded-md duration-200 font-[600] text-[0.8125rem] h-[30px] w-[30px] flex items-center justify-center border border-solid border-[#e7e7e8]`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.1em"
                    width="1.1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-info-square-rounded"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 9h.01" />
                    <path d="M11 12h1v4h1" />
                    <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                  </svg>
                </button>
                {/* Fill */}
                <button
                  onClick={handleFill}
                  data-title="Input"
                  style={{
                    transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform',
                  }}
                  className={`${isAsideOpen6 ? 'bg-gray-200' : 'bg-white'} table-tooltip-sidebar before:!left-[-28px] rounded-md duration-200 font-[600] text-[0.8125rem] h-[30px] w-[30px] flex items-center justify-center border border-solid border-[#e7e7e8]`}
                >



                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.1em"
                    width="1.1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-align-box-left-bottom"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                    <path d="M9 17h-2" />
                    <path d="M13 14h-6" />
                    <path d="M11 11h-4" />
                  </svg>




                </button>
                {/* <button
                  onClick={handleSecondBar}
                  data-title="Inputs"
                  style={{
                    transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform',
                  }}
                  className={`${isAsideOpen1 ? 'bg-gray-200' : 'bg-white'} table-tooltip-sidebar table-tooltip-inputs rounded-md duration-200 font-[600] text-[0.8125rem] h-[30px] w-[30px] flex items-center justify-center border border-solid border-[#e7e7e8]`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.1em"
                    width="1.1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-variable"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M5 4c-2.5 5 -2.5 10 0 16m14 -16c2.5 5 2.5 10 0 16m-10 -11h1c1 0 1 1 2.016 3.527c.984 2.473 .984 3.473 1.984 3.473h1" />
                    <path d="M8 16c1.5 0 3 -2 4 -3.5s2.5 -3.5 4 -3.5" />
                  </svg>
                </button> */}

                {/* activity */}
                <button
                  onClick={handleActivity}
                  data-title="Activity"
                  style={{
                    transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform',
                  }}
                  className={`${isAsideOpen2 ? 'bg-gray-200' : 'bg-white'} table-tooltip-sidebar table-tooltip-sidebar1 rounded-md duration-200 font-[600] text-[0.8125rem] h-[30px] w-[30px] flex items-center justify-center border border-solid border-[#e7e7e8]`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.1em"
                    width="1.1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-arrows-sort"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 9l4 -4l4 4m-4 -4v14" />
                    <path d="M21 15l-4 4l-4 -4m4 4v-14" />
                  </svg>

                </button>
                {/* chat */}
                <button
                  onClick={handleChat}
                  data-title="Chat"
                  style={{
                    transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform',
                  }}
                  className={`${isAsideOpen3 ? 'bg-gray-200' : 'bg-white'} table-tooltip-sidebar rounded-md duration-200 font-[600] text-[0.8125rem] h-[30px] w-[30px] flex items-center justify-center border border-solid border-[#e7e7e8]`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.1em"
                    width="1.1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-message"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M8 9h8" />
                    <path d="M8 13h6" />
                    <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
                  </svg>


                </button>
                {/* Action */}
                <button
                  onClick={handleAction}
                  data-title="Action"
                  style={{
                    transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform',
                  }}
                  className={`${isAsideOpen4 ? 'bg-gray-200' : 'bg-white'} table-tooltip-sidebar before:!left-[-32px] rounded-md duration-200 font-[600] text-[0.8125rem] h-[30px] w-[30px] flex items-center justify-center border border-solid border-[#e7e7e8]`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.1em"
                    width="1.1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-repeat-once"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 12v-3a3 3 0 0 1 3 -3h13m-3 -3l3 3l-3 3" />
                    <path d="M20 12v3a3 3 0 0 1 -3 3h-13m3 3l-3 -3l3 -3" />
                    <path d="M11 11l1 -1v4" />
                  </svg>
                </button>
                {/* Member */}
                <button
                  onClick={handleMember}
                  data-title="Member"
                  style={{
                    transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform',
                  }}
                  className={`${isAsideOpen5 ? 'bg-gray-200' : 'bg-white'} table-tooltip-sidebar before:!left-[-32px] table-tooltip-inputs rounded-md
                     duration-200 font-[600] text-[0.8125rem] h-[30px] w-[30px] flex items-center justify-center 
                     border border-solid border-[#e7e7e8]`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.1em"
                    width="1.1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-users"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                </button>
              </div>
            </main>

          </div>
        </div>
      </div>



      <Transition.Root show={showDeleteConfirmation} as={Fragment}>
        <Dialog as="div" className="relative z-50 md:z-10 lg:z-10" onClose={() => setShowDeleteConfirmation(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-start h-full items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[500px] sm:p-6">
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete your Document?
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-4 flex gap-2 justify-end">
                    <button
                      type="button"
                      onClick={handleCustomerDelete}
                      className="w-fit  justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium
                       text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none 
                       focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150 flex gap-2 items-center"
                      disabled={deleteLoading}
                    >
                      {deleteLoading ? (
                        <>
                          Delete
                          <div className="simple-spinner">
                            <span></span>
                          </div>
                        </>
                      ) : (
                        'Delete'
                      )}
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowDeleteConfirmation(false)}
                      className="mt-3 inline-flex leading-6 w-fit justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>


    </div>
  );
});

export default SendDocument;