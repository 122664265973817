
import React, { useEffect, useState } from 'react';
import { ReactNode } from 'react';
import { Link, useParams } from 'react-router-dom';
import Sidebar from './Sidebar';
import { useAppSelector } from '../../../../app/hooks';

interface UserDetails {
    id: number;
}

interface MemberLayoutProps {
    children: ReactNode;
    userDetails: UserDetails;
}


const TeamMemberLayout: React.FC<MemberLayoutProps> = ({ children }) => {
    const { baseId } = useParams<{ baseId: string }>();
    const [userDetails, setUserDetails] = useState<any>(null);
    const bases = useAppSelector((state: any) => state?.base?.data?.data?.bases);

    useEffect(() => {
        if (bases && bases.length > 0) {
            const matchedBase = bases.find((base: { id: number }) => base.id.toString() === baseId);
            if (matchedBase) {
                setUserDetails(matchedBase);
            }
        }
        else if (baseId) {
            setUserDetails(baseId);
        }
        else {
            setUserDetails(null);
        }
    }, [baseId, bases]);


    return (
        <div className="">
            <div className='py-[34px] lg:py-[70px] px-0 lg:px-10 h-full flex'>
                <div className='px-[2px] sm:px-6 lg:px-8 w-full flex flex-col items-center '>
                <div className='w-full max-w-[788px] flex gap-x-[2rem] justify-center mb-5'>
                  <Link to={`/${baseId}/settings/teams`}
                        className=' flex items-center gap-x-1 w-full '
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-left w-[0.9rem] h-[0.9rem]"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M15 6l-6 6l6 6" />
                        </svg>
                        <span className='text-black-100 text-[13px]'>
                            Back to channel
                        </span>
                        {/* <ArrowLeftIcon /> */}
                    </Link>
                  </div>
                    <div className='w-full max-w-[788px] flex gap-x-[2rem] justify-center'>
                        <div className='w-[20%]'>
                            <Sidebar userDetails={userDetails} />
                        </div>
                        <div className=' w-[80%]'>
                            <main className="flex-1 bg-white h-full">
                                {children}
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamMemberLayout;
