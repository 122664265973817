//@ts-nocheck
import { Icon } from '../../../components/ui/Icon'
import { Toolbar } from '../../../components/ui/Toolbar'
import { useTextmenuCommands } from './hooks/useTextmenuCommands'
import { useTextmenuStates } from './hooks/useTextmenuStates'
import { BubbleMenu, Editor } from '@tiptap/react'
import { memo } from 'react'
import * as Popover from '@radix-ui/react-popover'
import { Surface } from '../../../components/ui/Surface'
import { FontFamilyPicker } from './components/FontFamilyPicker'
import { FontSizePicker } from './components/FontSizePicker'
import { useTextmenuContentTypes } from './hooks/useTextmenuContentTypes'
import { ContentTypePicker } from './components/ContentTypePicker'
import { AIDropdown } from './components/AIDropdown'
import { EditLinkPopover } from './components/EditLinkPopover'
import Underline from '@tiptap/extension-underline';
import ColorPickerDropdown from '../../panels/Colorpicker/Colorpicker'


// We memorize the button so each button is not rerendered
// on every editor state change
const MemoButton = memo(Toolbar.Button)
// const MemoColorPicker = memo(ColorPicker)
const MemoFontFamilyPicker = memo(FontFamilyPicker)
const MemoFontSizePicker = memo(FontSizePicker)
const MemoContentTypePicker = memo(ContentTypePicker)

export type TextMenuProps = {
  editor: Editor
}



export const TextMenu = ({ editor, extractSelectedText }: TextMenuProps) => {
  const commands = useTextmenuCommands(editor)
  const states = useTextmenuStates(editor)
  const blockOptions = useTextmenuContentTypes(editor)

  const handleExtractText = () => {
    extractSelectedText(); // Call the passed function
  };

  return (
    <BubbleMenu
      // className=' absolute top-[-50px] left-[119px]'
      tippyOptions={{
        popperOptions: {
          placement: 'top',
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                boundary: 'viewport',
                padding: 8,
              },
            },
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['bottom-start', 'top-end', 'bottom-end'],
              },
              enabled: false,
            },
            {
              name: 'computeStyles',
              options: {
                adaptive: false,  // Keeps the menu fixed relative to the editor
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        },
        maxWidth: 'calc(100vw - 16px)',
      }}
      editor={editor}
      pluginKey="textMenu"
      shouldShow={states.shouldShow}
      updateDelay={100}
      className="fixed top-[-38px] left-[140px] max-w-xs"
    >
      <Toolbar.Wrapper>
        {/* <AIDropdown
          onCompleteSentence={commands.onCompleteSentence}
          onEmojify={commands.onEmojify}
          onFixSpelling={commands.onFixSpelling}
          onMakeLonger={commands.onMakeLonger}
          onMakeShorter={commands.onMakeShorter}
          onSimplify={commands.onSimplify}
          onTldr={commands.onTldr}
          onTone={commands.onTone}
        />
        <Toolbar.Divider /> */}
        {/* <MemoContentTypePicker options={blockOptions} /> */}
        {/* <MemoFontFamilyPicker onChange={commands.onSetFont} value={states.currentFont || ''} /> */}
        {/* <MemoFontSizePicker onChange={commands.onSetFontSize} value={states.currentSize || ''} /> */}
        {/* <Toolbar.Divider /> */}
        <MemoButton data-title="Bold" className='table-tooltip' onClick={commands.onBold} active={states.isBold}>
          <Icon name="Bold" />
        </MemoButton>
        <MemoButton
          data-title="Italic"
          className='table-tooltip'
          onClick={commands.onItalic}
          active={states.isItalic}
        >
          <Icon name="Italic" />
        </MemoButton>
        <MemoButton
          data-title="Underline"
          className='table-tooltip'
          onClick={commands.onUnderline}
          active={states.isUnderline}
        >
          <Icon name="Underline" />
        </MemoButton>

        {/* <MemoButton tooltip="Undo" tooltipShortcut={['Mod', 'Z']} onClick={() => editor.chain().focus().undo().run()}>
          <Icon name="Undo" />
        </MemoButton>
        <MemoButton tooltip="Redo" tooltipShortcut={['Mod', 'Shift', 'Z']}  onClick={() => editor.chain().focus().redo().run()}>
          <Icon name="Redo" />
        </MemoButton> */}
        <Toolbar.Divider />
        <MemoButton
          data-title="Strikehrough"
          className='table-tooltip'
          onClick={commands.onStrike}
          active={states.isStrike}
        >
          <Icon name="Strikethrough" />
        </MemoButton>
        <MemoButton data-title="Code" className='table-tooltip' onClick={commands.onCode} active={states.isCode}>
          <Icon name="Code" />
        </MemoButton>
        {/* <MemoButton tooltip="Code block"
          onClick={commands.onCodeBlock}
        >
          <Icon name="FileCode" />
        </MemoButton> */}
        <EditLinkPopover onSetLink={commands.onLink} />
        {/* <MemoButton
          tooltip="Align left"
          tooltipShortcut={['Shift', 'Mod', 'L']}
          onClick={commands.onAlignLeft}
          active={states.isAlignLeft}
        >
          <Icon name="AlignLeft" />
        </MemoButton>
        <MemoButton
          tooltip="Align center"
          tooltipShortcut={['Shift', 'Mod', 'E']}
          onClick={commands.onAlignCenter}
          active={states.isAlignCenter}
        >
          <Icon name="AlignCenter" />
        </MemoButton>
        <MemoButton
          tooltip="Align right"
          tooltipShortcut={['Shift', 'Mod', 'R']}
          onClick={commands.onAlignRight}
          active={states.isAlignRight}
        >
          <Icon name="AlignRight" />
        </MemoButton> */}

        <Popover.Root>
          <Popover.Trigger asChild>
            {/* <MemoButton active={!!states.currentHighlight} tooltip="Highlight text">
              <Icon name="Highlighter" />
            </MemoButton> */}
          </Popover.Trigger>
          {/* <Popover.Content side="top" sideOffset={8} asChild>
            <Surface className="p-1">
              <MemoColorPicker
                color={states.currentHighlight}
              // onChange={commands.onChangeHighlight}
              // onClear={commands.onClearHighlight}
              />
            </Surface>
          </Popover.Content> */}
        </Popover.Root>
        <Popover.Root>
          <Popover.Root>
            <Popover.Trigger asChild>
              <MemoButton active={!!states.currentColor || !!states.currentHighlight} data-title="Text color" className='table-tooltip'>
                <Icon name="Palette" />
              </MemoButton>
            </Popover.Trigger>
            <Popover.Content side="top" sideOffset={8} className="z-50">
              <ColorPickerDropdown
                currentColor={states.currentColor}
                currentHighlight={states.currentHighlight}
                onColorChange={commands.onChangeColor}
                onHighlightChange={color => commands.onChangeHighlight({ color })}
                onClearColor={commands.onClearColor}
                onClearHighlight={commands.onClearHighlight}
              />
            </Popover.Content>
          </Popover.Root>
          <MemoButton
            data-title="Remove All Formatting"
            className='table-tooltip'
            onClick={() => {
              try {
                editor.chain()
                  .focus()
                  .unsetAllMarks()
                  .clearNodes()
                  .setParagraph()
                  .run();
              } catch (error) {
                console.error("Error in removing formatting:", error);
              }
            }}
          >
            <Icon name="RemoveFormatting" />
          </MemoButton>


          <Popover.Content side="top" sideOffset={8} asChild>
            <Surface className="p-1">
              {/* <MemoColorPicker
                color={states.currentColor}
                onChange={commands.onChangeColor}
                onClear={commands.onClearColor}
              /> */}
            </Surface>
          </Popover.Content>
          <MemoButton
            data-title="Extract Text"
            className='table-tooltip'
            onClick={handleExtractText}  // Trigger extractSelectedText when clicked
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="icon icon-tabler icons-tabler-outline icon-tabler-clipboard  w-[1rem] h-[1rem] text-[#808080]"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
              <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
            </svg>

          </MemoButton>
        </Popover.Root>
        <Popover.Root>
          <Popover.Trigger asChild>
            <MemoButton data-title="More options" className='table-tooltip'>
              <Icon name="EllipsisVertical" />
            </MemoButton>
          </Popover.Trigger>
          <Popover.Content side="top" asChild>
            <Toolbar.Wrapper>
              {/* <MemoButton
                tooltip="Subscript"
                tooltipShortcut={['Mod', '.']}
                onClick={commands.onSubscript}
                active={states.isSubscript}
              >
                <Icon name="Subscript" />
              </MemoButton>
              <MemoButton
                tooltip="Superscript"
                tooltipShortcut={['Mod', ',']}
                onClick={commands.onSuperscript}
                active={states.isSuperscript}
              >
                <Icon name="Superscript" />
              </MemoButton> */}
              {/* <Toolbar.Divider /> */}
              <MemoButton
                data-title="Align left"
                className='table-tooltip'
                onClick={commands.onAlignLeft}
                active={states.isAlignLeft}
              >
                <Icon name="AlignLeft" />
              </MemoButton>
              <MemoButton
                data-title="Align center"
                className='table-tooltip'
                onClick={commands.onAlignCenter}
                active={states.isAlignCenter}
              >
                <Icon name="AlignCenter" />
              </MemoButton>
              <MemoButton
                data-title="Align right"
                className='table-tooltip'
                onClick={commands.onAlignRight}
                active={states.isAlignRight}
              >
                <Icon name="AlignRight" />
              </MemoButton>
              <MemoButton
                data-title="Justify"
                className='table-tooltip'
                onClick={commands.onAlignJustify}
                active={states.isAlignJustify}
              >
                <Icon name="AlignJustify" />
              </MemoButton>
            </Toolbar.Wrapper>
          </Popover.Content>
        </Popover.Root>
      </Toolbar.Wrapper>
    </BubbleMenu>
  )
}
