//@ts-nocheck

// components/YouTubeEmbed.tsx
import React, { useState } from 'react';
import Modal from '../../components/ui/Modal/modal';

interface YouTubeEmbedProps {
  onSubmit: (videoId: string) => void;
}

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({ onSubmit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [url, setUrl] = useState('');

  const handleSubmit = () => {
    // Extract video ID from various YouTube URL formats
    const extractVideoId = (url) => {
      const patterns = [
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
        /^[a-zA-Z0-9_-]{11}$/
      ];
      
      for (const pattern of patterns) {
        const match = url.match(pattern);
        if (match) {
          return match[1];
        }
      }
      return null;
    };

    const videoId = extractVideoId(url);
    if (videoId) {
      // Construct the full embed URL
      const embedUrl = `https://www.youtube.com/watch?v=${videoId}`;
      onSubmit(embedUrl);
      setUrl('');
    } else {
      alert('Please enter a valid YouTube URL or video ID');
    }
  };

  return (
    <div className='relative'>
      <button
        onClick={() => setIsModalOpen(true)}
        className="image-extantaion1 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
        Add YouTube Video
      </button>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Add YouTube Video"
      >
        <div className="grid gap-4">
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Paste YouTube URL"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          
          <div className="flex justify-end space-x-2">
            <button
              onClick={() => setIsModalOpen(false)}
              className="  rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
              Add Video
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default YouTubeEmbed;