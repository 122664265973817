// @ts-nocheck
import React, { useCallback, useEffect, useRef } from 'react'
import { useState } from 'react';
import FormBuilder from './Builder/FormBuilder';
import Toolbox from '../../../components/common/Toolbox';
import { FormElement } from '../baseSetting/Builder/types';
import { v4 as uuidv4 } from 'uuid';
// import { fetchAllStepsIntergrationDetails, submitSurveyStepIntegration } from '../integration-redux/integration.actions';
// import { RootState, useAppDispatch } from '../../../store/store';
import { toast } from 'sonner';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormContext } from './Builder/FormContext';
import { CrossIcon, ExclamationIcon } from '../../../components/icons/Icons';
import { RootState } from '../../../app/store';
import { fetchPipelineDetailsData } from '../../questionnaire/questionnaireSlice';
import { useParams } from 'react-router-dom';
import { createLocation, fetchQuestionStepList } from '../../questionSteps/questionStepSlice';
import { useAppDispatch } from '../../../app/hooks';



function QuestionList({ integration }: any) {
    const [isFromAsideOpen, setIsFormAsideOpen] = useState(false);
    const [elements, setElements] = useState<FormElement[]>([]);
    const baseIdString = localStorage.getItem('UserBaseId') || localStorage.getItem('createBaseId');
    const baseId = baseIdString ? JSON.parse(baseIdString) : null;
    const [steps, setSteps] = useState([]);
    const prevIntegrationId = useRef<string | null>(null);
    const [errors, setErrors] = useState<{ isError: boolean, reason?: string }[]>([]);
    const [isFromReOrderOpen, setIsFormReOrderOpen] = useState(false);
    const [elementOrder, setElementOrder] = useState([]);
    const [itemTagBaseId, setItemTagBaseId] = useState<any>([]);
    const [dataLoading, setDataLoading] = useState(false);
    const { userId } = useParams<{ userId: string }>();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const [loading, setLoading] = useState(false)
    const [loadingOrder, setLoadingOrder] = useState(false)

    const handleAddElement = (element: FormElement) => {
        setElements([...elements, { ...element, id: uuidv4() }]);
        setElementOrder([...elementOrder, { ...element, id: uuidv4() }]);
        setErrors([...errors, false]);
    };


    const handleFormStepOpen = () => {
        setIsFormAsideOpen(true);
        setIsFormReOrderOpen(false);

    }

    const handleReOderOpen = () => {
        setIsFormReOrderOpen(true);
        setIsFormAsideOpen(false);
    }

    const updateElementMandatory = useCallback((elementId, isMandatory) => {
        setElements(prevElements =>
            prevElements.map(el =>
                el.id === elementId ? { ...el, is_mandatory: isMandatory } : el
            )
        );
    }, []);




    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);

        const integrationSteps = elements.map((element, index) => (
            // console.log(element,"element"),
            
            {
            id: element.id,
            questionset_id: userId,
            question_order: index + 1,
            question_title: element.headerText || "Default Title",
            question_description: element?.Description,
            question_input_type: element.type,
            question_input_option: JSON.stringify(element.options || {}),
            ...(element.hasOwnProperty('is_mandatory') && {
                question_mandatory: element.is_mandatory === true ? "YES" : "NO"
            })
        }));


        try {
            const response = await dispatch(createLocation({ integrationSteps, userId: baseId })).unwrap();
            const { data } = response;

            const newErrors = data.map((step: { isError: boolean; reason?: string }, index: number) => {
                if (step.isError) {
                    toast.error(`Step ${index + 1}: ${step.reason || 'Unknown error'}`);
                    return { isError: true, reason: step.reason || 'Unknown error' };
                }
                return { isError: false };
            });

            setErrors(newErrors);

            if (newErrors.every(error => !error.isError)) {
                toast.success("Integration steps saved successfully.");
            }
            
            const updatedSteps = await dispatch(fetchQuestionStepList({ baseId, userId })).unwrap();

            if (Array.isArray(updatedSteps)) {
                const formattedElements = updatedSteps.map(step => (

                    {
                        ...step,
                        id: step.id || uuidv4(),
                        type: step.question_input_type,
                        headerText: step.question_title,
                        headerDescription: step.Description,
                        required: step.question_mandatory === 'yes',
                        options: JSON.parse(step.question_input_option || '{}')
                    }));
                setElements(formattedElements);
                setElementOrder(formattedElements); 
            }

        } catch (error) {
            console.error("Error submitting integration steps:", error);
            // toast.error("An error occurred while submitting the integration steps.");
        } finally {
            setLoading(false);
        }
    };



    // all integration steps details

    useEffect(() => {
        const fetchSteps = async () => {
            if (userId && baseId) {
                setIsLoading(true);
                try {
                    const response = await dispatch(fetchQuestionStepList({ baseId, userId })).unwrap();
                    if (Array.isArray(response?.data)) {
                        const formattedElements = response.data.map(step => ({
                            ...step,
                            id: step.id || uuidv4(),
                            type: step.question_input_type,
                            headerText: step.question_title,
                            headerDescription: step.question_description,
                            required: step.question_mandatory,
                            options: JSON.parse(step.question_input_option || '{}')
                        }));
                        setElements(formattedElements);
                        setElementOrder(formattedElements);
                    } else {
                        console.log("Response is not an array:", response);
                    }
                } catch (error) {
                    console.error("Failed to fetch integration steps:", error);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        fetchSteps();
    }, [dispatch, userId, baseId]);


    // draggable 
    const handleOnDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;

        const newElementOrder = Array.from(elementOrder);
        const [movedElement] = newElementOrder.splice(source.index, 1);
        newElementOrder.splice(destination.index, 0, movedElement);

        setElementOrder(newElementOrder);
    };

    const updateReOrder = async () => {
        setLoadingOrder(true);

        const integrationSteps = elementOrder.map((element, index) => ({
            id: element.id,
            questionset_id: userId,
            question_order: index + 1,
            question_title: element.headerText || "Default Title",
            question_description: element?.Description,
            question_input_type: element.type,
            question_input_option: JSON.stringify(element.options || {}),
            question_mandatory: element.is_mandatory ? true : false,
            // step_show_condition: "No",
            // step_show_logic: ""
        }));

        try {
            const response = await dispatch(createLocation({ integrationSteps, userId: baseId })).unwrap();
            const { data } = response;

            const newErrors = data.map((step: { isError: boolean; reason?: string }, index: number) => {
                if (step.isError) {
                    toast.error(`Step ${index + 1}: ${step.reason || 'Unknown error'}`);
                    return { isError: true, reason: step.reason || 'Unknown error' };
                }
                return { isError: false };
            });

            if (newErrors.every(error => !error.isError)) {
                toast.success("Integration steps reordered successfully.");

                // Fetch updated steps after successful reorder
                const updatedSteps = await dispatch(fetchQuestionStepList({ baseId, userId })).unwrap();

                if (Array.isArray(updatedSteps)) {
                    const formattedElements = updatedSteps.map(step => ({
                        ...step,
                        id: step.id || uuidv4(),
                        type: step.question_input_type,
                        headerText: step.question_title,
                        headerDescription: steps.question_description,
                        required: step.question_mandatory === "YES",
                        options: JSON.parse(step.question_input_option || '{}')
                    }));
                    setElements(formattedElements);
                    setElementOrder(formattedElements);
                } else {
                    console.log("Updated response is not an array:", updatedSteps);
                }
            }

        } catch (error) {
            console.error("Error reordering integration steps:", error);
            toast.error("An error occurred while reordering the integration steps.");
        } finally {
            setLoadingOrder(false);
            setIsFormReOrderOpen(false);
        }
    };





    //////// new code 



    useEffect(() => {
        const fetchPipelineDetails = async () => {
            if (!baseId || !userId) return;

            const localDataString = localStorage.getItem('baseQuestionnaireData');
            const localData = localDataString ? JSON.parse(localDataString) : null;

            if (localData && localData.data) {
                const pipeline = localData.data.find(p => p.id === userId);
                if (pipeline) {
                    setItemTagBaseId(pipeline);
                    return;
                } else {
                    console.error("Pipeline not found in local storage");
                }
            }

            setDataLoading(true);
            try {
                const baseResponse = await dispatch(fetchPipelineDetailsData({ baseId: Number(baseId), userId }));

                if (fetchPipelineDetailsData.fulfilled.match(baseResponse)) {
                    const fetchedData = baseResponse.payload.data;
                    setItemTagBaseId(fetchedData);
                } else {
                    console.error("Error fetching pipeline details:", baseResponse.payload);
                    setItemTagBaseId([]);
                }
            } catch (error) {
                console.error("Error fetching pipeline details:", error);
                setItemTagBaseId([]);
            } finally {
                setDataLoading(false);
            }
        };

        fetchPipelineDetails();
    }, [baseId, dispatch, userId]);



    return (
        <FormContext.Provider value={{ elements, updateElementMandatory }}>
            <div className={isFromAsideOpen ? 'form_builder_side' : 'w-full'}>




                <div
                    className={isFromAsideOpen ? 'max-w-full md:max-w-[640px] lg:max-w-[640px] m-auto' : 'max-w-full md:max-w-[640px] lg:max-w-[640px] m-auto'}

                >
                    <div className="mb-4 mt-5 lg:mt-[55px] min-h-[40px] h-[100%] w-full p-0 text-[24px] leading-[42px] font-medium text-dark-black-100 cursor-pointer">
                        {itemTagBaseId?.questionset_name}
                    </div>
                    <button className="font-medium text-dark-black-200  mr-1 text-[14px]">
                        {itemTagBaseId?.questionset_description}
                    </button>
                    <hr className="my-3" />

                    <div className=''>
                        <div className="flex items-center justify-between my-7">
                            <h4 className="font-medium text-dark-black-200  mr-1 text-[15px] flex items-center gap-[6px]">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-dark-black-200 ">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                                </svg>
                                Steps
                            </h4>
                            <div className='flex items-center gap-x-2'>
                                <div className='flex items-center gap-x-2'>
                                    <button
                                        onClick={handleReOderOpen}
                                        className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500
                                         focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Reorder
                                    </button>

                                    <button
                                        onClick={handleFormStepOpen}
                                        className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500
                                        focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >Add Step</button>

                                </div>
                                <button
                                    type="button"
                                    onClick={handleSubmit}
                                    className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500
                                    focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    <div className='flex items-center  gap-[6px]'>
                                        Save
                                        {loading && (
                                            <div className="simple-spinner">
                                                <span></span>
                                            </div>
                                        )}
                                    </div>
                                </button>


                            </div>


                        </div>
                        {isLoading ? (
                            <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 291px)' }}>
                                <div className="simple-spinner-loader">
                                    <span></span>
                                </div>
                            </div>
                        ) : elements.length === 0 ? (
                            <div className='w-full mt-5 flex h-[300px] justify-center items-center flex-col gap-y-3'>
                                <ExclamationIcon />
                                <p className='text-[#0000007a] text-[13px]'>No results found</p>
                            </div>
                        ) : (
                            <ul>
                                {elements.map((element, index) => (

                                    <li key={index} className="relative flex gap-x-4 mt-4">
                                        <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                            <span className="text-[11px] font-medium text-gray-700">{index + 1}</span>
                                        </div>
                                        <div
                                            className={`flex-auto rounded-md p-3 cursor-pointer ring-1 ring-inset ${errors[index]?.isError ? 'ring-red-500' : 'ring-gray-200'
                                                } feedback-delete-hover`}
                                        // className="flex-auto rounded-md p-3 cursor-pointer ring-1 ring-inset ring-gray-200 feedback-delete-hover"
                                        >
                                            <div className="block items-start">
                                                <div className="w-full flex flex-col gap-1">
                                                    <div className="flex items-center">
                                                        <FormBuilder elements={[element]} setElements={setElements} index={index} setIsFormAsideOpen={setIsFormAsideOpen}
                                                            error={errors[index]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-[5%]"></div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}



                    </div>
                    <div className='flex flex-col min-w-[200px] bg-white  z-10 absolute lg:fixed right-0 max-w-[80%] view-feedback-right-side'
                        style={{
                            width: isFromAsideOpen ? '351px' : '350px',
                            transform: isFromAsideOpen ? 'none' : 'none',
                            marginRight: isFromAsideOpen ? '0' : '-400px',
                            top: isFromAsideOpen ? '0px' : '0px',
                            height: isFromAsideOpen ? '100%' : '100%',
                            ...(window.innerWidth <= 768 && {
                                width: isFromAsideOpen ? '300px' : '300px',
                                marginRight: isFromAsideOpen ? '0' : '-300px'
                            }),
                        }}
                    >
                        <div className='h-[97%] border border-light-gray-200 border-solid mt-[12px] rounded-lg  mr-[12px]'>
                            <aside className='flex flex-col justify-stretch
                     border-l-0 border-r-0 border-solid border-light-gray-200 p-[1.5rem] items-start gap-4'>

                                <div className="mx-auto w-full flex items-center justify-between">
                                    <p className="text-center text-wedges-gray text-sm font-medium">
                                        Add step
                                    </p>

                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                        onClick={() => setIsFormAsideOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <CrossIcon />
                                    </button>

                                </div>
                                <Toolbox onAddElement={handleAddElement} />

                            </aside>
                        </div>

                    </div>

                    <div className='flex flex-col min-w-[200px] bg-white z-10 absolute lg:fixed right-0 max-w-[80%] view-feedback-right-side overflow-auto'
                        style={{
                            width: isFromReOrderOpen ? '351px' : '350px',
                            transform: isFromReOrderOpen ? 'none' : 'none',
                            marginRight: isFromReOrderOpen ? '0' : '-400px',
                            top: isFromReOrderOpen ? '0' : '0px',
                            height: isFromReOrderOpen ? '100%' : '100%',
                            ...(window.innerWidth <= 768 && {
                                width: isFromReOrderOpen ? '300px' : '300px',
                                marginRight: isFromReOrderOpen ? '0' : '-300px'
                            }),
                        }}
                    >
                        <div className='h-[98%] mt-[5px] border border-light-gray-200 border-solid overflow-auto'>
                            <aside className='flex flex-col justify-stretch
                     border-l-0 border-r border-solid border-light-gray-200 p-[1.5rem] items-start gap-4'>

                                <div className="mx-auto w-full flex items-center justify-between">
                                    <p className="text-center text-wedges-gray text-sm font-medium">
                                        Reorder
                                    </p>
                                    <div className='flex items-center gap-x-2'>
                                        <button
                                            onClick={updateReOrder}
                                            className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                        focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            <div className='flex items-center  gap-[6px]'>
                                                Save reorder
                                                {loadingOrder && (
                                                    <div className="simple-spinner">
                                                        <span></span>
                                                    </div>
                                                )}
                                            </div>


                                        </button>
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                            onClick={() => setIsFormReOrderOpen(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <CrossIcon />
                                        </button>
                                    </div>

                                </div>
                                <ul className='w-full'>

                                    {isFromReOrderOpen && (
                                        <DragDropContext onDragEnd={handleOnDragEnd}>
                                            <Droppable droppableId="droppable">
                                                {(provided) => (
                                                    <ul {...provided.droppableProps} ref={provided.innerRef} className='flex flex-col gap-y-3'>
                                                        {elementOrder.map((element, index) => (
                                                            <Draggable key={element.id} draggableId={element.id} index={index}>
                                                                {(provided) => (
                                                                    <li
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        className="flex-auto rounded-md p-3 cursor-pointer ring-1 ring-inset ring-gray-200 feedback-delete-hover w-ful"
                                                                    >

                                                                        <div className='flex flex-col gap-y-2'>
                                                                            <div className='flex items-center gap-x-2'>

                                                                                <span className="text-[11px] font-medium text-gray-700">{index + 1}</span>

                                                                                <div className='cursor-pointer font-normal text-[13px] capitalize inline-flex items-center rounded-md bg-gray-50 px-2  text-gray-600 ring-1 ring-inset ring-gray-500/10 w-fit py-0'>
                                                                                    {element.type}
                                                                                </div>
                                                                            </div>
                                                                            <div className='font-[500] text-[#121217] text-[14px]'>
                                                                                {element.headerText}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </ul>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    )}
                                </ul>

                            </aside>
                        </div>

                    </div>

                </div>




            </div>
        </FormContext.Provider>
    )
}

export default QuestionList