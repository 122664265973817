// @ts-nocheck

import React from 'react';
import { FormElement } from '../../features/setting/baseSetting/Builder/types';
import { v4 as uuidv4 } from 'uuid'; // Import UUID library

interface ToolboxProps {
    onAddElement: (element: FormElement) => void;
}

const Toolbox: React.FC<ToolboxProps> = ({ onAddElement }) => {
    const textAndHeadingElements: FormElement[] = [
        { id: uuidv4(), type: 'text', label: 'Text', icon: 'T', headerText: 'Sample Text' },
        { id: uuidv4(), type: 'heading', label: 'Heading', Description: 'Enter your description here ..', icon: 'H', headerText: 'Sample Heading' }
    ];
    const allOtherElements: FormElement[] = [
        { id: uuidv4(), type: 'input', label: 'Input', headerText: 'Sample Input', Description: 'Enter your description here ..', icon: 'I', placeholder: 'Enter your details' },
        { id: uuidv4(), type: 'teatarea', label: 'Textarea', headerText: 'Sample Textarea', Description: 'Enter your description here ..', icon: 'T', placeholder: 'Enter long data' },
        // { id: uuidv4(), type: 'email', label: 'Email', headerText: 'Sample Email', icon: 'E', placeholder: 'Enter your email' },
        // { id: uuidv4(), type: 'number', label: 'Number', headerText: 'Sample Number', icon: 'N', placeholder: 'Enter a number' },
        {
            id: uuidv4(), type: 'radio', label: 'Radio', headerText: 'Sample Options', Description: 'Enter your description here ..', icon: 'R',
            options: [
                { id: uuidv4(), label: 'Strongly disagree', selected: false },
                { id: uuidv4(), label: 'Disagree', selected: false },
                { id: uuidv4(), label: 'Neither agree nor disagree', selected: false },
                { id: uuidv4(), label: 'Agreement', selected: false },
                { id: uuidv4(), label: 'Strongly agree', selected: false },
            ]
        },
        {
            id: uuidv4(), type: 'checkbox', label: 'Checkbox', headerText: 'Sample Multi select', Description: 'Enter your description here ..', icon: 'C',
            options: [
                { id: uuidv4(), label: 'Very unsatisfied', selected: false },
                { id: uuidv4(), label: 'Satisfied', selected: false },
                { id: uuidv4(), label: 'Very satisfied', selected: false },
                { id: uuidv4(), label: 'New Option', selected: false },
                { id: uuidv4(), label: 'New Option', selected: false },
                { id: uuidv4(), label: 'New Option', selected: false },
            ]
        },
        {
            id: uuidv4(), type: 'dropdown', label: 'Dropdown Single', headerText: 'Sample Single Dropdown', Description: 'Enter your description here ..', icon: 'D',
            options: [
                { id: uuidv4(), label: 'Strongly disagree', selected: false },
                { id: uuidv4(), label: 'Disagree', selected: false },
                { id: uuidv4(), label: 'Neither agree nor disagree', selected: false },
                { id: uuidv4(), label: 'Agreement', selected: false },
                { id: uuidv4(), label: 'Strongly agree', selected: false },
            ]
        },
        {
            id: uuidv4(), type: 'multi select', label: 'Dropdown Multi', headerText: 'Sample Multi Dropdown', Description: 'Enter your description here ..', icon: 'D',
            options: [
                { id: uuidv4(), label: 'Strongly disagree', selected: false },
                { id: uuidv4(), label: 'Disagree', selected: false },
                { id: uuidv4(), label: 'Neither agree nor disagree', selected: false },
                { id: uuidv4(), label: 'Agreement', selected: false },
                { id: uuidv4(), label: 'Strongly agree', selected: false },
            ]
        },
        { id: uuidv4(), type: 'video', label: 'Video', headerText: 'Sample Video', Description: 'Enter your description here ..', icon: 'V', placeholder: 'Upload your file' },
        { id: uuidv4(), type: 'audio', label: 'Audio', headerText: 'Sample Audio', Description: 'Enter your description here ..', icon: 'A', placeholder: 'Upload your file' },
        { id: uuidv4(), type: 'file', label: 'File', headerText: 'Sample File', Description: 'Enter your description here ..', icon: 'F', placeholder: 'Upload your file' },



        // how to create for thumps up


    ];

    return (
        <div className="p-0 w-full bg-transparent">
            <div className="mb-4">
                <h3 className=" text-wedges-gray text-[13px] font-medium mb-2">Display</h3>
                <ul>
                    {textAndHeadingElements.map((element) => (
                        <li
                            key={element.id}
                            onClick={() => onAddElement(element)}
                            className="flex items-center py-[6px] px-3 mb-2 cursor-pointer border border-solid border-gray-300 rounded hover:bg-gray-200"
                        >
                            <span className="mr-2 text-dark-black-200 text-[13px] font-normal">{element.icon}</span>
                            <span className='text-dark-black-200 text-[13px] font-normal'>{element.label}</span>
                        </li>
                    ))}
                </ul>
            </div>

            <div>
                <h3 className=" text-wedges-gray text-[13px] font-medium mb-2  pt-3">Input</h3>
                <ul>
                    {allOtherElements.map((element) => (
                        <li
                            key={element.id}
                            onClick={() => onAddElement(element)}
                            className="flex items-center py-[6px] px-3 mb-2 cursor-pointer border border-solid border-gray-300 rounded hover:bg-gray-200"
                        >
                            <span className="mr-2 text-dark-black-200 text-[13px] font-normal">{element.icon}</span>
                            <span className='text-dark-black-200 text-[13px] font-normal'>{element.label}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Toolbox;
