
// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, LoginPayload, RegisterPayload, AuthResponse, ForgotPayload, ResetPayload, OtpPayload, User } from './authTypes';
import {
  login as loginAPI, register as registerAPI,
  otpScreen as otpScreenAPI,
  forgotPassword as forgotPasswordAPI, resetPassword as resetPasswordAPI, logoutUser as LogoutAPI
} from '../../services/authService';
import { saveToken, removeToken, getToken } from '../../utils/localStorage';

// Function to check if the user is authenticated based on the token
const checkAuthStatus = (): boolean => {
  const token = getToken();
  return !!token;
};


// Initial auth state
const initialState: AuthState = {
  user: null,
  token: getToken(),
  isAuthenticated: checkAuthStatus(),
  loading: false,
  error: null,
  successMessage: null,
  isRegistered: false,

};



// Thunk for login
export const login = createAsyncThunk(
  'auth/login',
  async (credentials: LoginPayload, { rejectWithValue }) => {
    try {
      const response = await loginAPI(credentials);
      saveToken(response?.data?.token);
      // return {
      //   token: response.data.token,
      //   user: response.data.user,
      // };
      return response
    } catch (error: any) {
      
      if (error.response && error.response.data && error.response.data.errors && error?.response?.data?.data?.message) {
        return rejectWithValue(error?.response?.data?.data?.message);
      } else {
        return rejectWithValue(error?.response?.data?.data?.message);
      }
    }
  }
);

// Thunk for registration
export const register = createAsyncThunk(
  'auth/register',
  async (data: RegisterPayload, { rejectWithValue }) => {
    try {
      const response = await registerAPI(data);
      saveToken(response?.data?.token);
      return response;
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.errors && error.response.data.data.errors.email) {
        return rejectWithValue(error?.response?.data?.data?.errors?.email);
      } else {
        return rejectWithValue(error?.response?.data?.data?.errors?.email);
      }
    }
  }
);

// Thunk for forgot password
export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (data: ForgotPayload, { rejectWithValue }) => {
    try {
      const response = await forgotPasswordAPI(data);
      saveToken(response.token);
      return response;
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.errors && error.response.data.data.message) {
        return rejectWithValue(error?.response?.data?.data?.message);
      } else {
        return rejectWithValue(error?.response?.data?.data?.message);
      }
    }
  }
);

// Thunk for reset password
export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (data: ResetPayload, { rejectWithValue }) => {
    try {
      const response = await resetPasswordAPI(data);
      saveToken(response.token);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk for otp 
export const otpScreen = createAsyncThunk(
  'auth/otp',
  async (data: OtpPayload, { rejectWithValue }) => {
    try {
      const response = await otpScreenAPI(data);      
      saveToken(response?.data?.token);
      return response;
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.errors && error.response.data.data.message) {
        return rejectWithValue(error.response.data.data.message);
      } else {
        return rejectWithValue(error.response.data.data.message);
      }
    }
  }
);

// Thunk for logging out
export const logoutUser = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      const response = await LogoutAPI();
      return response;
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.errors && error.response?.data?.message) {
        return rejectWithValue(error.response.data.data.message);
      } else {
        return rejectWithValue(error.response.data.data.message);
      }
    }
  }
);

// Auth slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      removeToken(); // Remove token from localStorage
    },
    clearMessages: (state) => {
      state.error = null;
      state.successMessage = null;
    },
    setAuthStatus: (state) => {
      state.isAuthenticated = checkAuthStatus();
      state.token = getToken();
    },
    setRegistrationPending: (state) => {
      state.isRegistered = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<{ token: string; user: User }>) => {        
        state.loading = false;
        state.user = action.payload.user; 
        state.token = action.payload.token; 
        state.isAuthenticated = true;  
        state.successMessage = 'Login successful!';
      })      
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(register.fulfilled, (state, action: PayloadAction<AuthResponse>) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.isAuthenticated = false;
        state.isRegistered = true;
        state.successMessage = 'Registration successful! Please verify OTP.';
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action: PayloadAction<AuthResponse>) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.isAuthenticated = true;
        state.successMessage = 'Password reset email sent!';
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(otpScreen.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(otpScreen.fulfilled, (state, action: PayloadAction<AuthResponse>) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.isAuthenticated = true;
        state.isRegistered = false;
        state.successMessage = 'OTP verified successfully!';
      })
      .addCase(otpScreen.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.token = null;
        state.isAuthenticated = false;
        removeToken(); // Clear token from local storage
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

// Export actions and reducer
export const { logout, clearMessages, setAuthStatus, setRegistrationPending } = authSlice.actions;
export default authSlice.reducer;
