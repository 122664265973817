// src/app/store.ts
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from '../features/auth/authSlice';
import userReducer from '../features/users/userSlice';
import baseReducer from '../features/base/baseSlice'; 
import teamsReducers from '../features/teams/teamsSlice';
import pipelineReducer from '../features/pipeline/pipelineSlice';
import pipelineStagesReducer from '../features/pipelineStages/pipelineStagesSlice';
import tagsReducer from '../features/tags/tagsSlice';
import questionnaireReducer from '../features/questionnaire/questionnaireSlice';
import questionStepsReducers from "../features/questionSteps/questionStepSlice"
import documentReducers from "../features/document/documentSlice"

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // only persist auth state
};

const rootReducer = combineReducers({
  auth: authReducer,
  base: baseReducer,
  user: userReducer,
  teams: teamsReducers,
  pipeline: pipelineReducer,
  pipelineStages: pipelineStagesReducer,
  tags: tagsReducer,
  questionnaire : questionnaireReducer,
  questionSteps : questionStepsReducers,
  documentData : documentReducers
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

export const persistor = persistStore(store);

// Define types for state and dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;