// //@ts-nocheck
// import { TextSelection } from '@tiptap/pm/state'
// import React from 'react';

// interface ToCItemProps {
//   item: {
//     id: string;
//     isActive: boolean;
//     isScrolledOver: boolean;
//     level: number;
//     textContent: string;
//     itemIndex: number;
//   };
//   onItemClick: (e: React.MouseEvent<HTMLAnchorElement>, id: string) => void;
// }

// export const ToCItem: React.FC<ToCItemProps> = ({ item, onItemClick }) => {
//   return (
//     <div
//       className={`${item.isActive && !item.isScrolledOver ? 'is-active' : ''} ${
//         item.isScrolledOver ? 'is-scrolled-over' : ''
//       }`}
//       style={{
//         '--level': item.level,
//       } as React.CSSProperties}
//     >
//       <a
//         href={`#${item.id}`}
//         onClick={(e) => onItemClick(e, item.id)}
//         data-item-index={item.itemIndex}
//       >
//         {item.textContent}
//       </a>
//     </div>
//   );
// };

// export const ToCEmptyState: React.FC = () => {
//   return (
//     <div className="empty-state">
//       <p>Start editing your document to see the outline.</p>
//     </div>
//   );
// };

// interface ToCProps {
//   items?: {
//     id: string;
//     isActive: boolean;
//     isScrolledOver: boolean;
//     level: number;
//     textContent: string;
//     itemIndex: number;
//   }[];
//   editor?: {
//     view: {
//       dom: HTMLElement;
//       posAtDOM: (element: HTMLElement, offset: number) => number;
//       state: {
//         tr: any;
//       };
//       dispatch: (transaction: any) => void;
//       focus: () => void;
//     };
//   };
// }

// export const TableOfContent: React.FC<ToCProps> = ({ items = [], editor }) => {
//   const onItemClick = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>, id: string) => {
//     e.preventDefault();

//     if (editor) {
//       // Try multiple methods to find the target element
//       const elementByTocId = editor.view.dom.querySelector(`[data-toc-id="${id}"]`);
//       const elementByHeadingId = editor.view.dom.querySelector(`[id="${id}"]`);
//       const element = elementByTocId || elementByHeadingId;

//       if (!element) {
//         console.warn(`No element found for id: ${id}`);
//         return;
//       }

//       const pos = editor.view.posAtDOM(element, 0);

//       // set focus
//       const tr = editor.view.state.tr;

//       tr.setSelection(new TextSelection(tr.doc.resolve(pos)));

//       editor.view.dispatch(tr);

//       editor.view.focus();

//       // Update URL hash
//       window.location.hash = id;

//       // Scroll to the element
//       element.scrollIntoView({ 
//         behavior: 'smooth', 
//         block: 'start' 
//       });
//     }
//   }, [editor]);

//   if (items.length === 0) {
//     return <ToCEmptyState />;
//   }

//   return (
//     <>
//       {items.map((item) => (
//         <ToCItem 
//           onItemClick={onItemClick} 
//           key={item.id} 
//           item={item} 
//         />
//       ))}
//     </>
//   );
// };

// @ts-nocheck

// import React, { useState } from 'react';
// import { TextSelection } from '@tiptap/pm/state';

// interface ToCItemProps {
//   item: {
//     id: string;
//     isActive: boolean;
//     isScrolledOver: boolean;
//     level: number;
//     textContent: string;
//     itemIndex: number;
//   };
//   onItemClick: (e: React.MouseEvent<HTMLAnchorElement>, id: string) => void;
// }

// const ToCItem: React.FC<ToCItemProps> = ({ item, onItemClick }) => {
//   return (
//     <div
//       className={`

//      text-sm leading-6 text-[#5c5c5e] font-normal py-1
//         ${item.isActive && !item.isScrolledOver ? 'font-bold text-blue-600' : ''} 
//         ${item.isScrolledOver ? 'text-gray-500' : ''}
//       `}
//     >
//       <a
//         href={`#${item.id}`}
//         onClick={(e) => onItemClick(e, item.id)}
//         data-item-index={item.itemIndex}
//         className="hover:text-blue-700 transition-colors"
//       >
//         {item.textContent}
//       </a>
//     </div>
//   );
// };

// interface ResponsiveToCProps {
//   items?: {
//     id: string;
//     isActive: boolean;
//     isScrolledOver: boolean;
//     level: number;
//     textContent: string;
//     itemIndex: number;
//   }[];
//   editor?: {
//     view: {
//       dom: HTMLElement;
//       posAtDOM: (element: HTMLElement, offset: number) => number;
//       state: {
//         tr: any;
//       };
//       dispatch: (transaction: any) => void;
//       focus: () => void;
//     };
//   };
// }

// export const TableOfContent: React.FC<ResponsiveToCProps> = ({ 
//   items = [], 
//   editor 
// }) => {
//   const [isExpanded, setIsExpanded] = useState(false);

//   const onItemClick = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>, id: string) => {
//     e.preventDefault();

//     if (editor) {
//       const element = editor.view.dom.querySelector(`[id="${id}"]`);

//       if (!element) {
//         console.warn(`No element found for id: ${id}`);
//         return;
//       }

//       const pos = editor.view.posAtDOM(element, 0);
// console.log(pos,"pos");

//       const tr = editor.view.state.tr;
//       tr.setSelection(new TextSelection(tr.doc.resolve(pos)));

//       editor.view.dispatch(tr);
//       editor.view.focus();

//       window.location.hash = id;

//       element.scrollIntoView({ 
//         behavior: 'smooth', 
//         block: 'start' ,
//         inline: 'nearest'

//       });

//       // Close the expanded view after clicking an item
//       setIsExpanded(false);
//     }
//   }, [editor]);

//   if (items.length === 0) {
//     return null;
//   }

//   return (
//     <div 
//       className={`
//         fixed right-[400px] top-1/2 transform -translate-y-1/2 
//         bg-white shadow-lg rounded-lg 
//         transition-all duration-300 ease-in-out
//         overflow-hidden
//         ${isExpanded ? 'w-[220px] p-4' : 'w-12 p-2'}
//         hover:shadow-xl
//         z-50
//       `}
//       onMouseEnter={() => setIsExpanded(true)}
//       onMouseLeave={() => setIsExpanded(false)}
//     >
//       {/* Collapsed view - show first item or icon */}
//       {!isExpanded && (
//         <div className="flex items-center justify-center">
//           <svg 
//             xmlns="http://www.w3.org/2000/svg" 
//             fill="none" 
//             viewBox="0 0 24 24" 
//             strokeWidth={1.5} 
//             stroke="currentColor" 
//             className="w-6 h-6 text-gray-600"
//           >
//             <path 
//               strokeLinecap="round" 
//               strokeLinejoin="round" 
//               d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" 
//             />
//           </svg>
//         </div>
//       )}

//       {/* Expanded view - show full table of contents */}
//       {isExpanded && (
//         <div className="">
//           <h3 className="text-sm font-semibold mb-2 text-gray-700">Table of Contents</h3>
//           {items.map((item) => (
//             <ToCItem 
//               key={item.id}
//               item={item}
//               onItemClick={onItemClick}
//             />
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default TableOfContent;

// import React, { useState, useCallback, useEffect } from 'react';
// import { TextSelection } from '@tiptap/pm/state';

// interface ToCItemProps {
//   item: {
//     id: string;
//     isActive: boolean;
//     isScrolledOver: boolean;
//     level: number;
//     textContent: string;
//     itemIndex: number;
//   };
//   onItemClick: (e: React.MouseEvent<HTMLAnchorElement>, id: string) => void;
// }

// const ToCItem: React.FC<ToCItemProps> = ({ item, onItemClick }) => {
//   return (
//     <div
//       className={`
//        text-sm leading-6 text-[#5c5c5e] font-normal py-1
//         ${item.isActive && !item.isScrolledOver ? 'font-bold text-blue-600' : ''} 
//         ${item.isScrolledOver ? 'text-gray-500' : ''}
//       `}
//     >
//       <a
//         href={`#${item.id}`}
//         onClick={(e) => onItemClick(e, item.id)}
//         data-item-index={item.itemIndex}
//         className="hover:text-blue-700 transition-colors"
//       >
//         {item.textContent}
//       </a>
//     </div>
//   );
// };

// interface ResponsiveToCProps {
//   items?: {
//     id: string;
//     isActive: boolean;
//     isScrolledOver: boolean;
//     level: number;
//     textContent: string;
//     itemIndex: number;
//   }[];
//   editor?: {
//     view: {
//       dom: HTMLElement;
//       posAtDOM: (element: HTMLElement, offset: number) => number;
//       state: {
//         tr: any;
//       };
//       dispatch: (transaction: any) => void;
//       focus: () => void;
//     };
//   };
// }

// export const TableOfContent: React.FC<ResponsiveToCProps> = ({ 
//   items = [], 
//   editor 
// }) => {
//   const [isExpanded, setIsExpanded] = useState(false);

//   const onItemClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>, id: string) => {
//     e.preventDefault();

//     if (editor) {
//       const element = editor.view.dom.querySelector(`[id="${id}"]`) as HTMLElement;

//       if (!element) {
//         console.warn(`No element found for id: ${id}`);
//         return;
//       }

//       const pos = editor.view.posAtDOM(element, 0);

//       const tr = editor.view.state.tr;
//       tr.setSelection(new TextSelection(tr.doc.resolve(pos)));

//       editor.view.dispatch(tr);
//       editor.view.focus();

//       window.location.hash = id;

//       // Add 100px padding top when scrolling
//       const scrollToOptions: ScrollIntoViewOptions = {
//         behavior: 'smooth',
//         block: 'start',
//         inline: 'nearest'
//       };

//       // Create a temporary div for padding
//       const paddingDiv = document.createElement('div');
//       paddingDiv.style.height = '100px';
//       paddingDiv.style.position = 'relative';

//       // Insert the padding div before the target element
//       element.parentNode?.insertBefore(paddingDiv, element);

//       // Scroll to the element
//       element.scrollIntoView(scrollToOptions);

//       // Remove the temporary padding div after scrolling
//       setTimeout(() => {
//         paddingDiv.remove();
//       }, 1000);

//       // Close the expanded view after clicking an item
//       setIsExpanded(false);
//     }
//   }, [editor]);

//   if (items.length === 0) {
//     return null;
//   }

//   return (
//     <div 
//       className={`
//         fixed right-[400px] top-1/2 transform -translate-y-1/2 
//         bg-white shadow-lg rounded-lg 
//         transition-all duration-300 ease-in-out
//         overflow-hidden
//         ${isExpanded ? 'w-[220px] p-4' : 'w-12 p-2'}
//         hover:shadow-xl
//         z-50
//       `}
//       onMouseEnter={() => setIsExpanded(true)}
//       onMouseLeave={() => setIsExpanded(false)}
//     >
//       {/* Collapsed view - show first item or icon */}
//       {!isExpanded && (
//         <div className="flex items-center justify-center">
//           <svg 
//             xmlns="http://www.w3.org/2000/svg" 
//             fill="none" 
//             viewBox="0 0 24 24" 
//             strokeWidth={1.5} 
//             stroke="currentColor" 
//             className="w-6 h-6 text-gray-600"
//           >
//             <path 
//               strokeLinecap="round" 
//               strokeLinejoin="round" 
//               d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" 
//             />
//           </svg>
//         </div>
//       )}

//       {/* Expanded view - show full table of contents */}
//       {isExpanded && (
//         <div className=" overflow-y-auto max-h-[300px]">
//           <h3 className="text-sm font-semibold mb-2 text-gray-700">Table of Contents</h3>
//           {items.map((item) => (
//             <ToCItem 
//               key={item.id}
//               item={item}
//               onItemClick={onItemClick}
//             />
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default TableOfContent;

import React, { useState, useCallback, useEffect } from 'react';
import { TextSelection } from '@tiptap/pm/state';
import { motion, AnimatePresence } from 'framer-motion';

interface ToCItemProps {
  item: {
    id: string;
    isActive: boolean;
    isScrolledOver: boolean;
    level: number;
    textContent: string;
    itemIndex: number;
  };
  onItemClick: (e: React.MouseEvent<HTMLAnchorElement>, id: string) => void;
}

const ToCItem: React.FC<ToCItemProps> = ({ item, onItemClick }) => {
  const paddingLeft = `${(item.level - 1) * 10}px`;

  return (
    <div
      // style={{ paddingLeft }}
      className={`
       text-[13px] leading-6 text-[#686764] font-medium py-1
        ${item.isActive && !item.isScrolledOver ? ' text-blue-600' : ''} 
        ${item.isScrolledOver ? 'text-[#686764]' : ''}
      `}
    >
      <a
        href={`#${item.id}`}
        onClick={(e) => onItemClick(e, item.id)}
        data-item-index={item.itemIndex}
        className="hover:text-blue-700 transition-colors block truncate"
      // title={item.textContent}
      >
        <span
        // className={`
        //   inline-block w-full border-l-2 pl-2
        //   ${item.level === 1 ? 'border-blue-500' : 
        //     item.level === 2 ? 'border-green-500' : 
        //     item.level === 3 ? 'border-yellow-500' : 
        //     'border-gray-300'}
        // `}
        >
          {item.textContent}
        </span>
      </a>
    </div>
  );
};

interface ResponsiveToCProps {
  items?: {
    id: string;
    isActive: boolean;
    isScrolledOver: boolean;
    level: number;
    textContent: string;
    itemIndex: number;
  }[];
  editor?: {
    view: {
      dom: HTMLElement;
      posAtDOM: (element: HTMLElement, offset: number) => number;
      state: {
        tr: any;
      };
      dispatch: (transaction: any) => void;
      focus: () => void;
    };
  };
}

export const TableOfContent: React.FC<ResponsiveToCProps> = ({
  items = [],
  editor,
  isAsideOpen,
  isAsideOpen1,
  isAsideOpen2,
  isAsideOpen3,
  isAsideOpen4,
  isAsideOpen5,
  isAsideOpen6
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onItemClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>, id: string) => {
    e.preventDefault();

    if (editor) {
      const element = editor.view.dom.querySelector(`[id="${id}"]`) as HTMLElement;

      if (!element) {
        console.warn(`No element found for id: ${id}`);
        return;
      }

      const pos = editor.view.posAtDOM(element, 0);

      const tr = editor.view.state.tr;
      tr.setSelection(new TextSelection(tr.doc.resolve(pos)));

      editor.view.dispatch(tr);
      editor.view.focus();

      window.location.hash = id;

      const scrollToOptions: ScrollIntoViewOptions = {
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',

      };

      const paddingDiv = document.createElement('div');
      paddingDiv.style.height = '100px';
      paddingDiv.style.position = 'relative';

      element.parentNode?.insertBefore(paddingDiv, element);

      element.scrollIntoView(scrollToOptions);

      setTimeout(() => {
        paddingDiv.remove();
      }, 1000);

      setIsExpanded(false);
    }
  }, [editor]);

  if (items.length === 0) {
    return null;
  }

  // Function to calculate line width based on heading level
  const getLineWidth = (level: number) => {
    
    switch(level) {
      case 1: return 18;  // Heading 1 = 18px
      case 2: return 16;  // Heading 2 = 16px
      case 3: return 12;  // Heading 3 = 12px
      default: return 10; // Remaining levels = 10px
    }
  };

  // Group items by level to determine line widths
  const levelGroups = items.reduce((acc, item) => {
    // Use originalLevel instead of level
    if (!acc[item.originalLevel]) {
      acc[item.originalLevel] = [];
    }
    acc[item.originalLevel].push(item);
    return acc;
  }, {} as Record<number, any[]>);
  
  const headingLevelCounts = items.reduce((acc, item) => {
    // Also use originalLevel here
    acc[item.originalLevel] = (acc[item.originalLevel] || 0) + 1;
    return acc;
  }, {} as Record<number, number>);



  return (
    <div
      className={`
        fixed top-1/2 transform -translate-y-1/2 
      flex flex-col gap-3 
        transition-all duration-300 ease-in-out
        overflow-hidden scroll-smooth rounded-md 
        ${isExpanded ? 'w-[250px] p-4' : 'w-12 p-2'}
        ${isAsideOpen || isAsideOpen1 || isAsideOpen2 || isAsideOpen3 || isAsideOpen4 || isAsideOpen5 || isAsideOpen6 ? 'right-[410px]' : 'right-[50px]'}
      
        z-50
      `}
      // style={{
      //   transitionProperty: 'opacity, width, right',
      //   transitionDuration: '200ms',
      //   transitionDelay: '0ms',
      //   transitionTimingFunction: 'ease'
      // }}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      {/* Collapsed view - show total heading count with lines */}
      {!isExpanded && (
        <div className="flex flex-col items-center justify-center space-y-4 pr-[0px]">
             {[1, 2, 3, 4].map((level) => {
           
             
              const levelCount = levelGroups[level]?.length || 0;
              const displayCount = Math.min(levelCount, 5);
            
              
              return levelCount > 0 ? (
                <div 
                  key={level}
                  className="flex items-center space-y-4 flex-col"
                >
                  {[...Array(displayCount)].map((_, index) => (
                    <div
                      key={index}
                      className={`h-0.5 rounded m-auto ${
                        level === 1 && index === 0 ? 'bg-black' : 'bg-[#e3e2e0]'
                      }`}
                      style={{
                        width: `${getLineWidth(level)}px`,
                        transition: "background 0.2s, box-shadow 0.2s"
                      }}
                    />
                  ))}
                  {levelCount > 5 && (
                    <div className="text-xs text-gray-600 ml-1">
                      +{levelCount - 5}
                    </div>
                  )}
                </div>
              ) : null;
            })}
        
        </div>
      )}

      {/* Expanded view - show full table of contents */}
      {isExpanded && (
        <div className="overflow-y-auto max-h-[400px] rounded-md bg-white border-[2px] border-[#f5f5f4] p-3 shadow-sm">
          {/* <h3 className="text-sm font-semibold mb-2 text-gray-700">
            Table of Contents
          </h3> */}
          <div className="text-xs text-gray-500 mb-2">
            {/* {Object.entries(headingLevelCounts).map(([level, count]) => (
              <span key={level} className="mr-2">
                H{level}: {count}
              </span>
            ))} */}
          </div>
          {items.map((item) => (
            <ToCItem
              key={item.id}
              item={item}
              onItemClick={onItemClick}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TableOfContent;