//@ts-nocheck
import { BubbleMenu as BaseBubbleMenu, useEditorState } from '@tiptap/react'
import { useCallback, useEffect, useState } from 'react'
import { sticky } from 'tippy.js'
import { v4 as uuid } from 'uuid'

import { MenuProps } from '../../../components/menus/types'
import { getRenderContainer } from '../../../lib/utils'
import { Toolbar } from '../../../components/ui/Toolbar'
import { Icon } from '../../../components/ui/Icon'
import { ColumnLayout } from '../Columns'

export const ColumnsMenu = ({ editor, appendTo }: MenuProps) => {
  const [isVisible, setIsVisible] = useState(false)

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'columns')
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0)
    return rect
  }, [editor])

  const shouldShow = useCallback(() => {
    if (!editor.isEditable) return false

    // Check if we're inside a columns node
    const { selection } = editor.state
    let isInColumns = false
    let depth = selection.$anchor.depth

    while (depth > 0) {
      const node = selection.$anchor.node(depth)
      if (node.type.name === 'columns') {
        isInColumns = true
        break
      }
      depth--
    }

    return isInColumns
  }, [editor])



  const onColumnTwo = useCallback(() => {
    editor.chain().focus().setLayout(ColumnLayout.TwoColumn).run()
  }, [editor])
  
  const onColumnLeft = useCallback(() => {
    editor.chain().focus().setLayout(ColumnLayout.SidebarLeft).run()
  }, [editor])
  
  const onColumnRight = useCallback(() => {
    editor.chain().focus().setLayout(ColumnLayout.SidebarRight).run()
  }, [editor])

  const onColumnThree = useCallback(() => {
    editor.chain().focus().setLayout(ColumnLayout.ThreeColumn).run()
  }, [editor])

  const { isColumnLeft, isColumnRight, isColumnTwo, isColumnThree  } = useEditorState({
    editor,
    selector: ctx => {
      return {
        isColumnLeft: ctx.editor.isActive('columns', { layout: ColumnLayout.SidebarLeft }),
        isColumnRight: ctx.editor.isActive('columns', { layout: ColumnLayout.SidebarRight }),
        isColumnTwo: ctx.editor.isActive('columns', { layout: ColumnLayout.TwoColumn }),
        isColumnThree: ctx.editor.isActive('columns', { layout: ColumnLayout.ThreeColumn }),
      }
    },
  })

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`columnsMenu-${uuid()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => appendTo?.current,
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <div className="column-menu">
        <Toolbar.Wrapper>
          <Toolbar.Button 
            tooltip="Sidebar left" 
            active={isColumnLeft} 
            onClick={onColumnLeft} 
            className="column-menu-button"
            aria-label="Set layout to Sidebar left"
          >
            <Icon name="PanelLeft" />
          </Toolbar.Button>
          <Toolbar.Button 
            tooltip="Two columns" 
            active={isColumnTwo} 
            onClick={onColumnTwo} 
            className="column-menu-button"
            aria-label="Set layout to two column"

          >
            <Icon name="Columns2" />
          </Toolbar.Button>
          <Toolbar.Button 
            tooltip="Sidebar right" 
            active={isColumnRight} 
            onClick={onColumnRight} 
            className="column-menu-button"
            aria-label="Set layout to Sidebar right"

          >
            <Icon name="PanelRight" />
          </Toolbar.Button>
          {/* three column */}
          <Toolbar.Button
            tooltip="Three columns"
            active={isColumnThree}
            onClick={onColumnThree}
            className="column-menu-button"
            aria-label="Set layout to three column"

          >
            <Icon name="Columns3" />
          </Toolbar.Button>
          
        </Toolbar.Wrapper>
      </div>
    </BaseBubbleMenu>
  )
}

export default ColumnsMenu








// import { BubbleMenu } from '@tiptap/react'
// import React, { useCallback, useEffect, useRef } from 'react'
// import { Instance } from 'tippy.js'
// import { v4 as uuid } from 'uuid'

// export const DebugImageBlockMenu = ({ editor, appendTo, debug = false }) => {
//   const menuRef = useRef<HTMLDivElement>(null)
//   const tippyInstance = useRef<Instance | null>(null)

//   const getReferenceClientRect = useCallback(() => {
//     console.log('Getting reference client rect');
//     const renderContainer = document.querySelector('.debug-image-block');
//     console.log('Found render container:', renderContainer);
    
//     if (!renderContainer) {
//       console.warn('No render container found');
//       return new DOMRect(-1000, -1000, 0, 0);
//     }

//     const rect = renderContainer.getBoundingClientRect();
//     console.log('Container rect:', rect);
//     return rect;
//   }, [editor])

//   const shouldShow = useCallback(() => {
//     const isActive = editor.isActive('imageBlock');
//     console.log('Should show menu check:', {
//       isActive,
//       selection: editor.state.selection,
//       nodeType: editor.state.selection.$head.parent.type.name,
//     });
//     return isActive;
//   }, [editor]);

//   useEffect(() => {
//     if (debug) {
//       console.log('Menu mounted with props:', {
//         editorExists: !!editor,
//         appendToExists: !!appendTo?.current,
//         menuRefExists: !!menuRef.current,
//       });
//     }
//   }, [debug, editor, appendTo]);

//   return (
//     <BubbleMenu
//       editor={editor}
//       pluginKey={`debugImageBlockMenu-${uuid()}`}
//       shouldShow={shouldShow}
//       updateDelay={0}
//       tippyOptions={{
//         offset: [0, 8],
//         placement: 'top',
//         onShow: () => {
//           console.log('Tippy show triggered');
//         },
//         onCreate: (instance: Instance) => {
//           console.log('Tippy instance created');
//           tippyInstance.current = instance;
//         },
//         onMount: () => {
//           console.log('Tippy mounted');
//         },
//         appendTo: () => {
//           const container = appendTo?.current;
//           console.log('Appending to container:', container);
//           return container || 'parent';
//         },
//       }}
//     >
//       <div 
//         ref={menuRef}
//         className="bg-white shadow-lg rounded-lg p-2 flex gap-2"
//         onClick={() => console.log('Menu clicked')}
//       >
//         <button 
//           className="p-1 hover:bg-gray-100 rounded"
//           onClick={() => {
//             console.log('Align left clicked');
//             editor.chain().focus().setImageBlockAlign('left').run();
//           }}
//         >
//           Left
//         </button>
//         <button 
//           className="p-1 hover:bg-gray-100 rounded"
//           onClick={() => {
//             console.log('Align center clicked');
//             editor.chain().focus().setImageBlockAlign('center').run();
//           }}
//         >
//           Center
//         </button>
//         <button 
//           className="p-1 hover:bg-gray-100 rounded"
//           onClick={() => {
//             console.log('Align right clicked');
//             editor.chain().focus().setImageBlockAlign('right').run();
//           }}
//         >
//           Right
//         </button>
//       </div>
//     </BubbleMenu>
//   );
// };

// export default DebugImageBlockMenu;