import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Switch } from '@headlessui/react';
import Modal from '../../../components/common/Modal';
import { useAppDispatch } from '../../../app/hooks';
import { createTeams, fetchBoardsList, updateTeams } from '../../teams/teamsSlice';
import { createTeamsPayload } from '../../teams/teamsTypes';
import { toast } from 'sonner';
import { ChevronDownIconWhite, CustomCheckIcon } from '../../../components/icons';

interface BoardPageProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    editBoardData?: any;
    baseId: number;
    setEditBoardData: (value: any) => void;
}


const CreateTeams: React.FC<BoardPageProps> = ({ isModalOpen, setIsModalOpen, editBoardData, baseId, setEditBoardData }) => {
    const [name, setName] = useState('');
    const [purpose, setPurpose] = useState('');
    const [color, setColor] = useState('bg-[#3b82f6]');
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState('');
    const [purposeError, setPurposeError] = useState('');
    const dispatch = useAppDispatch();
    const userStatus = localStorage.getItem('userDetails');
    const userStatusId = userStatus ? JSON.parse(userStatus) : null;
    const [baseTeamsData, setBaseTeamsData] = useState<any[]>([]);

    const colorList = [
        { name: "bg-[#3b82f6]" },
        { name: "bg-[#6b7280]" },
        { name: "bg-[#bfdbfe]" },
        { name: "bg-[#ec4899]" },
        { name: "bg-[#6366f1]" },
        { name: "bg-[#eab308]" },
        { name: "bg-[#22c55e]" },
        { name: "bg-[#06b6d4]" },
        { name: "bg-[#dc2626]" },
    ];


    // Load edit data when available
    useEffect(() => {
        if (editBoardData) {
            setName(editBoardData.name || '');
            setPurpose(editBoardData.description || '');
            setColor(`bg-[${editBoardData.color}]` || 'bg-[#3b82f6]');
            setChecked(editBoardData.privacy === 'public');
        }
    }, [editBoardData]);

        useEffect(() => {
        const storedData = localStorage.getItem('baseTeamsData');
        if (storedData) {
            setBaseTeamsData(JSON.parse(storedData));
        }
    }, []);

    const handleSave = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setNameError("");
        setPurposeError("");

        if (name.trim().length === 0) {
            setNameError("Name is required");
            setLoading(false);
            return;
        } else if (purpose.trim().length === 0) {
            setPurposeError("Description is required");
            setLoading(false);
            return;
        }

        const privacy = checked ? "public" : "private";
        const colorValue = color.substring(color.indexOf("[#") + 1, color.indexOf("]"));

        const payload: createTeamsPayload = {
            base_id: baseId,
            name,
            description: purpose,
            privacy,
            color: colorValue,
            channel_status: userStatusId.status,
        };

        try {
            if (editBoardData) {
                // Update existing team
                const response = await dispatch(updateTeams({
                    payload,
                    userId: baseId,
                    id: editBoardData.id
                }));

                if (response.meta.requestStatus === 'fulfilled') {
                    toast.success('Team updated successfully!');
                } else {
                    toast.error('Failed to update team.');
                }
            } else {
                // Create new team
                const response = await dispatch(createTeams({ payload, userId: baseId }));
                if (response.meta.requestStatus === 'fulfilled') {
                    toast.success('Team created successfully!');
                } else {
                    toast.error('Failed to create channel.');
                }
            }

            setIsModalOpen(false);
            resetForm();

            // Fetch updated list
            const baseResponse = await dispatch(fetchBoardsList(baseId));
            if (baseResponse?.payload) {
                localStorage.setItem('baseTeamsData', JSON.stringify(baseResponse.payload));
                window.dispatchEvent(new StorageEvent('storage', {
                    key: 'baseTeamsData',
                    newValue: JSON.stringify(baseResponse.payload)
                }));
            }
            
        } catch (error) {
            console.error("Failed to save team:", error);
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        setName("");
        setPurpose("");
        setColor("bg-[#3b82f6]");
        setChecked(false);
        setEditBoardData(null);  // Add this line
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setName(value);
            setNameError(value.trim().length === 0 ? "Name is required" : "");
        } else if (name === 'purpose') {
            setPurpose(value);
            setPurposeError(value.trim().length === 0 ? "Description is required" : "");
        }
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={() => {
                setIsModalOpen(false);
                resetForm();
            }}
            title={editBoardData ? "Edit Channel" : "Create Channel"}
            onSave={handleSave}
            loading={loading}
        >
            <form action="#" onSubmit={handleSave} className="space-y-6 mt-5">
                <div className='flex flex-col gap-y-2'>
                    <label className='wg-label inline-flex cursor-pointer items-center gap-1 text-sm font-medium leading-6'>Name</label>
                    <div className="relative">
                        <Menu as='div' className='flex items-center'>
                            <Menu.Button className='absolute inset-y-0 left-0 flex items-center px-2'>
                                <div className={`h-6 w-6 rounded-full ${color} border flex justify-center items-center`}>
                                    <ChevronDownIconWhite/>
                                </div>
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter='transition ease-out duration-100'
                                enterFrom='transform opacity-0 scale-95'
                                enterTo='transform opacity-100 scale-100'
                                leave='transition ease-in duration-75'
                                leaveFrom='transform opacity-100 scale-100'
                                leaveTo='transform opacity-0 scale-95'>
                                <Menu.Items className='absolute flex flex-wrap left-0 top-8 z-10 mt-2.5 w-44 p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                                    {colorList.map((value, index) => (
                                        <div
                                            onClick={() => setColor(value.name)}
                                            key={index}
                                            className={`flex h-6 w-6 border ${value.name} rounded-full m-2 justify-center items-center cursor-pointer`}
                                        >
                                            {color === value.name &&   <CustomCheckIcon /> }
                                        </div>
                                    ))}
                                </Menu.Items>
                            </Transition>
                            <input
                                id='name'
                                name='name'
                                type='text'
                                value={name}
                                onChange={handleInputChange}
                                placeholder='Enter Name'
                                className="w-full px-4 py-2 pl-10 text-sm leading-6 rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                            />
                        </Menu>
                    </div>
                    {nameError && <p className="text-sm text-red-600">{nameError}</p>}

                    <div className='flex flex-col gap-y-2 mt-1'>
                        <label className='wg-label inline-flex cursor-pointer items-center gap-1 text-sm font-medium leading-6'>Description</label>
                        <input
                            id="purpose"
                            name='purpose'
                            type='text'
                            value={purpose}
                            onChange={handleInputChange}
                            placeholder="Team description"
                            className="w-full px-4 py-2 text-sm leading-6 rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                        />
                        {purposeError && <p className="text-sm text-red-600">{purposeError}</p>}
                    </div>

                    <div className="flex items-center mt-3">
                        <Switch
                            checked={checked}
                            onChange={() => setChecked(!checked)}
                            className={`${checked ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
                        >
                            <span className={`${checked ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 transform rounded-full bg-white transition duration-200 ease-in-out`} />
                        </Switch>
                        <span className="ml-3 text-sm font-medium">
                            {checked ? 'Public' : 'Private'}
                        </span>
                    </div>
                    <p className="text-sm text-gray-600">
                        {checked
                            ? "Everyone on your team can view and join this channel."
                            : "Only invited members will be able to view & access this channel."
                        }
                    </p>
                </div>
            </form>
        </Modal>
    );
};

export default CreateTeams;