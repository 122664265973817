// @ts-nocheck

import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { error } from 'console';
import { removeToken, getToken, removeAllLocalStorage } from './localStorage'; // Assuming localStorage utility is in this path

// Base URL for API requests
export const BASE_URL = 'https://api.teamerhr.com/v1'; // Replace with your API base URL


// Create an axios instance with default configuration
export const apiClient: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to attach the token to every request
apiClient.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = getToken(); // Retrieve token from localStorage
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    console.warn("Token missing from Authorization header");
  }
  return config;
}, error => {
  return Promise.reject(error);
});


// Add a response interceptor to handle token expiration and 403 errors
apiClient.interceptors.response.use(
  (response: AxiosResponse) => response, // On success, just return the response
  (error) => {
    // Check if the error response is 403 and the message is "token expire"
    if (error.response?.status === 403 && error.response?.data?.message === "token expire") {
      // If the token is expired or invalid, clear local storage and redirect to login
      removeAllLocalStorage(); // Clear all local storage data
      window.location.href = '/login'; // Redirect to the login page
    }
    return Promise.reject(error); // Forward the error so it can be handled elsewhere
  }
);


// Helper function to handle GET requests
export const get = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await apiClient.get(url, config);
    return response.data;
  } catch (error) {
    handleError(error);
    throw error; // Rethrow the error after handling
  }
};

// Helper function to handle POST requests
export const post = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await apiClient.post(url, data, config);
    return response.data;
  } catch (error) {
    handleError(error);
    throw error; // Rethrow the error after handling
  }
};

// Helper function to handle PUT requests
export const put = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await apiClient.put(url, data, config);
    return response.data;
  } catch (error) {
    handleError(error);
    throw error; // Rethrow the error after handling
  }
};

// Helper function to handle DELETE requests
export const del = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await apiClient.delete(url, config);
    return response.data;
  } catch (error) {
    handleError(error);
    throw error; // Rethrow the error after handling
  }
};

// Error handling function
const handleError = (error: any) => {
  // You can customize this function to handle errors globally (e.g., show notifications)
  if (axios.isAxiosError(error)) {
    console.error('API call failed:', error.message);
  } else {
    console.error('An unexpected error occurred:', error);
  }
};

