// teamsSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { TeamsMembersResponse, TeamsMembersState, TeamsMember, ApiError } from './teamsMemberTypes';
import { deleteTeamMemberInvitation, fetchBaseTeamMemberInvitationStatus, fetchTeamsMembersData } from '../../services/teamMemberService';

// Thunk definition
export const fetchTeamsMemberList = createAsyncThunk<
  TeamsMembersResponse,
  { baseId: number; teamId: number }, // Expect both baseId and teamId
  { rejectValue: string }
>(
  'base/fetchTeamsMembersList',
  async ({ baseId, teamId }, { rejectWithValue }) => {
    try {
      if (teamId === undefined || baseId === undefined) {
        throw new Error('Missing required parameters (baseId or teamId)');
      }

      // Pass both parameters to the data-fetching function
      const response = await fetchTeamsMembersData(baseId, teamId);
      return response;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message || 'Failed to fetch team members data');
    }
  }
);



// base team member invitation status


export const baseTeamMemberInvitationStatusData = createAsyncThunk<TeamsMembersResponse, { baseId: number, teamId: number }, { rejectValue: string }>(
  'base/fetchTeamMemberInvitationStatus',
  async ({ baseId, teamId }, { rejectWithValue }) => {
    try {
      if (baseId === undefined || teamId === undefined) {
        throw new Error('Base ID or User ID is undefined');
      }
      const response = await fetchBaseTeamMemberInvitationStatus(baseId, teamId);
      return response;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message || 'Failed to fetch base data');
    }
  });


// base member invitation remove 
export const baseTeamMemberRemove = createAsyncThunk<TeamsMembersResponse, { baseId: number, invitationId: number }, { rejectValue: string }>(
  'base/baseMemberRemove',
  async ({ baseId, invitationId }, { rejectWithValue }) => {
    try {
      const response = await deleteTeamMemberInvitation(baseId, invitationId);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to remove member');
    }
  }
);


const initialState: TeamsMembersState = {
  TeamsMemberDetails: null,
  TeamsMemberStatus: 'idle',
  TeamsMemberStatusError: null,
  baseInvitationStatusDetails: null,
  baseInvitationStatus: 'idle',
  baseInvitationStatusError: null,
  baseMemberRemoveStatus: 'idle',
  baseMemberRemoveError: null,
  baseMemberRemoveData: null,

};

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamsMemberList.pending, (state) => {
        state.TeamsMemberStatus = 'loading';
      })
      .addCase(fetchTeamsMemberList.fulfilled, (state, action: PayloadAction<TeamsMembersResponse>) => {
        state.TeamsMemberStatus = 'succeeded';
        state.TeamsMemberDetails = action.payload.data;
        localStorage.setItem('baseTeamsData', JSON.stringify(action.payload));
      })
      .addCase(fetchTeamsMemberList.rejected, (state, action) => {
        state.TeamsMemberStatus = 'failed';
        state.TeamsMemberStatusError = action.payload || 'Failed to fetch boards';
      })
      .addCase(baseTeamMemberInvitationStatusData.pending, (state) => {
        state.baseInvitationStatus = 'loading';
      })
      .addCase(baseTeamMemberInvitationStatusData.fulfilled, (state, action: PayloadAction<TeamsMembersResponse>) => {
        state.baseInvitationStatus = 'succeeded';
        state.baseInvitationStatusDetails = action.payload.data;
      })
      .addCase(baseTeamMemberInvitationStatusData.rejected, (state, action) => {
        state.baseInvitationStatus = 'failed';
        state.baseInvitationStatusError = action.payload || 'Failed to update base';
      })
      .addCase(baseTeamMemberRemove.pending, (state) => {
        state.baseMemberRemoveStatus = 'loading';
      })
      .addCase(baseTeamMemberRemove.fulfilled, (state, action: PayloadAction<TeamsMembersResponse>) => {
        state.baseMemberRemoveStatus = 'succeeded';
        state.baseMemberRemoveData = action.payload;
      })
      .addCase(baseTeamMemberRemove.rejected, (state, action) => {
        state.baseMemberRemoveStatus = 'failed';
        state.baseMemberRemoveError = action.payload || 'Failed to update base';
      })
  }
});

export default teamsSlice.reducer;