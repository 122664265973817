// src/utils/localStorage.ts

const TOKEN_KEY = 'authToken' ; // Key to store the token in localStorage
const AUTHORIZATION_KEY = 'authorization'; // Example key for additional authorization data


// Save token to localStorage
export const saveToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

// Retrieve token from localStorage
export const getToken = (): string | null => {
  return localStorage.getItem(TOKEN_KEY);
};

// Remove token from localStorage
export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(AUTHORIZATION_KEY); 
};

export const removeAllLocalStorage = () => {
  localStorage.clear();
};