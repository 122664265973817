// pipelineSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fetchLocationDataStatus, fetchPipelineDetails, updateLocationData } from '../../services/questionnaireService';
import { ApiError, TeamsResponse, TeamsState, createTeamsPayload, updateTeamsPayload } from './questionnaireTypes';
import { createLoationData, fetchLocationData } from '../../services/questionnaireService';



export const fetchQuestionList = createAsyncThunk<
  TeamsResponse,
  number,
  { rejectValue: string }
>(
  'base/fetchLocationList',
  async (baseId, { rejectWithValue }) => {
    try {
      if (baseId === undefined) {
        throw new Error('Base ID is undefined');
      }
      const response = await fetchLocationData(baseId);
      return response;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message || 'Failed to fetch base data');
    }
  }
);


// fetch location by status
export const fetchLocationListStatus = createAsyncThunk<
  TeamsResponse,
  { baseId: number; status?: string },
  { rejectValue: string }
>(
  'base/fetchLocationListStatus',
  async ({ baseId, status }, { rejectWithValue }) => {
    try {
      const response = await fetchLocationDataStatus(baseId, status);
      return response;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message || 'Failed to fetch base data');
    }
  }
);


// create teams 
export const createLocation = createAsyncThunk<TeamsResponse, { payload: createTeamsPayload; userId: number },
  { rejectValue: string }
>(
  'base/createLocation',
  async ({ payload, userId }, { rejectWithValue }) => {
    try {
      const response = await createLoationData(payload, userId);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to invite member');
    }
  }
);




// update teams
export const updateLocation = createAsyncThunk<TeamsResponse, { payload: updateTeamsPayload; userId: number, id: number },
  { rejectValue: string }
>(
  'base/updateLocation',
  async ({ payload, userId, id }, { rejectWithValue }) => {
    try {
      const response = await updateLocationData(userId, id, payload);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to invite member');
    }
  }
);

// pipeline details

export const fetchPipelineDetailsData = createAsyncThunk<
  TeamsResponse,
  { baseId: number; userId: string },
  { rejectValue: string }
>(
  'base/fetchPipelineDetails',
  async ({ baseId, userId }, { rejectWithValue }) => {
    try {
      if (baseId === undefined) {
        throw new Error('Base ID is undefined');
      }
      const response = await fetchPipelineDetails(baseId, userId);
      return response;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message || 'Failed to fetch pipeline details');
    }
  }
);


const initialState: TeamsState = {
  locationDetails: null,
  locationStatus: 'idle',
  locationStatusError: null,
  createTeamsStatus: 'idle',
  createTeamsError: null,
  createTeamsData: null,
  updateTeamsStatus: 'idle',
  updateTeamsError: null,
  updateTeamsData: null,
  deleteTeamsStatus: 'idle',
  deleteTeamsError: null,
  fetchTeamsByIdStatus: 'idle',
  fetchTeamsByIdError: null,
  fetchTeamsByIdData: null,
  baseMemberInviteStatus: 'idle',
  baseMemberInviteError: null,
  baseMemberInviteData: null,
  locationDetails1: null,
  locationStatus1: 'idle',
  locationStatusError1: null,
  // fetch pipeline details
  
  pipelineDetails: null,
  pipelineStatus: 'idle',
  pipelineStatusError: null,


};

const questionnaireSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestionList.pending, (state) => {
        state.locationStatus = 'loading';
      })
      .addCase(fetchQuestionList.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.locationStatus = 'succeeded';
        state.locationDetails = action.payload.data;
        // localStorage.setItem('basePiplineData', JSON.stringify(action.payload));
      })
      .addCase(fetchQuestionList.rejected, (state, action) => {
        state.locationStatus = 'failed';
        state.locationStatusError = action.payload || 'Failed to fetch boards';
      })
      .addCase(createLocation.pending, (state) => {
        state.createTeamsStatus = 'loading';
      })
      .addCase(createLocation.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.createTeamsStatus = 'succeeded';
        state.createTeamsData = action.payload;
      })
      .addCase(createLocation.rejected, (state, action) => {
        state.createTeamsStatus = 'failed';
        state.createTeamsError = action.payload || 'Failed to invite member';
      })
      .addCase(updateLocation.pending, (state) => {
        state.updateTeamsStatus = 'loading';
      })
      .addCase(updateLocation.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.updateTeamsStatus = 'succeeded';
        state.updateTeamsData = action.payload;
      })
      .addCase(updateLocation.rejected, (state, action) => {
        state.updateTeamsStatus = 'failed';
        state.updateTeamsError = action.payload || 'Failed to invite member';
      })
      .addCase(fetchLocationListStatus.pending, (state) => {
        state.locationStatus1 = 'loading';
      })
      .addCase(fetchLocationListStatus.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.locationStatus1 = 'succeeded';
        state.locationDetails1 = action.payload.data;
        // localStorage.setItem('basePiplineData', JSON.stringify(action.payload));
      })
      .addCase(fetchLocationListStatus.rejected, (state, action) => {
        state.locationStatus1 = 'failed';
        state.locationStatusError1 = action.payload || 'Failed to fetch boards';
      })
      .addCase(fetchPipelineDetailsData.pending, (state) => {
        state.pipelineStatus = 'loading';
      })
      .addCase(fetchPipelineDetailsData.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.pipelineStatus = 'succeeded';
        state.pipelineDetails = action.payload.data;
        // localStorage.setItem('basePiplineData', JSON.stringify(action.payload));
      })
      .addCase(fetchPipelineDetailsData.rejected, (state, action) => {
        state.pipelineStatus = 'failed';
        state.pipelineStatusError = action.payload || 'Failed to fetch boards';
      })
      
  }
});


export default questionnaireSlice.reducer;

