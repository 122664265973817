
// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'
import { UserDetails } from '../../../auth/authTypes';
import { useAppDispatch } from '../../../../app/hooks';
import { fetchTeamsMemberList } from '../../../teamsMembers/teamsMemberSlice';
import { useParams } from 'react-router-dom';
import { TeamsMembersResponse } from '../../../teamsMembers/teamsMemberTypes';
import DropDown from '../../../../components/common/DropDown';
import Badge from '../../../../components/common/Badge';
import { baseTeamMemberInviteData, fetchTeamsById } from '../../../teams/teamsSlice';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { toast } from 'sonner';
import { baseMemberDetailsData, baseMemberInviteData } from '../../../base/baseSlice';
import { CrossIcon, ExclamationIcon } from '../../../../components/icons/Icons';

type ChannelProps = {
    userDetails: UserDetails | null;
}

const TeamMemberDataDetails: React.FC<ChannelProps> = React.memo(({ }) => {
    const [dataLoading, setDataLoading] = useState(false);
    const [boardsFetched, setBoardsFetched] = useState<any[]>([]);
    const dispatch = useAppDispatch();
    const { userId } = useParams<{ userId: any }>();
    const [selectSearchRole, setSelectSearchRole] = useState("all")
    const roleSearchList = [{ name: "all" }, { name: "admin" }, { name: "manager" }, { name: "member" }, { name: "pending" }]
    const roleList = [{ name: "admin" }, { name: "manager" }, { name: "member" }]
    const baseIdString = localStorage.getItem('UserBaseId') || localStorage.getItem('createBaseId');
    const baseId = baseIdString ? JSON.parse(baseIdString) : null;
    const localStorageData = JSON.parse(localStorage.getItem('baseMembersDetails') || '{}');
    const baseMembers = localStorageData?.data?.baseMembers || [];
    const [addToBase, setAddToBase] = useState(false)
    const [selectRole, setSelectRole] = useState<any>("admin")
    const [addBaseLoading, setAddBaseLoading] = useState(false)
    const [teamId, setTeamId] = useState<number | null>(null);

    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState("")
    // fetch team by id




    // In Teams component
    useEffect(() => {
        const fetchTeams = async () => {
            setDataLoading(true);
            try {
                const baseResponse = await dispatch(fetchTeamsMemberList(userId));

                if (fetchTeamsMemberList.fulfilled.match(baseResponse)) {
                    setBoardsFetched(baseResponse.payload.data);
                } else {
                    setBoardsFetched([]);
                }
            } catch (error) {
                console.error("Error fetching teams:", error);
                setBoardsFetched([]);
            } finally {
                setDataLoading(false);
            }
        };
        if (userId) {
            fetchTeams();
        }
    }, [userId, dispatch]);


    const handleSearchRoleChange = useCallback((role: string) => {
        setSelectSearchRole(role);
    }, []);

    // add team member 
    const handleAddMember = () => {   
        setAddToBase(true)
        setTeamId(userId);
    }

    const add_baseHandler = () => {
        setAddBaseLoading(true)
        if (email.length === 0) {
            setEmailError("Enter your email address");
            setAddBaseLoading(false)
        } else {
            dispatch(baseTeamMemberInviteData({
                payload: { email, role: selectRole, channel_id: userId },
               userId:  baseId
            })).unwrap()
                .then((res: any) => {
                    toast.success(res?.message);
                    setAddToBase(false)
                })
                .catch((err: any) => {
                    setAddBaseLoading(false)
                })
                .finally(() => {
                    setAddBaseLoading(false);
                });
        }
    };

    useEffect(() => {
        const invitedByMembers = boardsFetched.map((boardItem: any) => {
            const member = baseMembers.find((member: any) =>
                member.id === boardItem.user_id || member.id === boardItem.user_id
            );
            return member;
        });
        const validMembers = invitedByMembers.filter((member: any) => member !== undefined);
        localStorage.setItem('baseMemberChannelInviteList', JSON.stringify(validMembers));

        return () => {
            // Cleanup code (if needed)
        };
    }, [boardsFetched, baseMembers]);  // Dependencies: re-run the effect if boardsFetched or baseMembers change

    const [baseMemberDetails, setBaseMemberDetails] = useState<any>(null);
    useEffect(() => {
        if (baseId) {
            const fetchData = async () => {

                try {
                    const baseResponse = await dispatch(baseMemberDetailsData(baseId));
                    setBaseMemberDetails(baseResponse.payload);
                } catch (error) {
                    console.error("Error fetching base member details:", error);
                }

            };
            fetchData();
        }
    }, [baseId, dispatch]);


    return (
        <main className='h-full flex'>
            <div className='w-full max-w-[788px] flex gap-x-[2rem] py-[0px]' style={{ margin: '0 auto' }}>
                <div className=' w-full justify-center'>
                    {/* <div className='bg-[#e0e0e0] w-full h-[1px] my-6'></div> */}
                    <div className=' mt-6 lg:mt-0 flex items-center  w-full gap-x-3'>

                        <div className='w-full lg:w-[40%]'>
                            <label htmlFor='searchEmail' className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased leading-5'>
                                Base Members
                            </label>
                            <div className='mt-[6px]'>
                                <input
                                    id='searchEmail'
                                    name='searchEmail'
                                    type='text'
                                    placeholder='Search by name'
                                    className="flex w-full grow border px-4 py-2 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                                />
                            </div>
                        </div>

                        <div className='w-full lg:w-[20%]'>
                            <div className='mt-[32px]'>
                                <DropDown onChangeValue={handleSearchRoleChange}
                                    data={roleSearchList}
                                    previousValue={selectSearchRole} />
                            </div>
                        </div>
                        <div className='w-full lg:w-[40%]'>
                            <div className='mt-[32px] flex justify-end'>
                                <button
                                    onClick={() => handleAddMember()}
                                    className='flex w-fit justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white
                    shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                >
                                    Add team Member
                                </button>

                            </div>

                        </div>
                    </div>

                    {dataLoading ? (
                        <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 191px)' }}>
                            <div className="simple-spinner-loader">
                                <span></span>
                            </div>
                        </div>
                    ) : boardsFetched.length === 0 ? (
                        <div className='w-full mt-5 flex h-[300px] justify-center items-center flex-col gap-y-3'>
                            <ExclamationIcon />
                            <p className='text-[#0000007a] text-[13px]'>No results found</p>
                        </div>
                    ) : (
                        <div className='w-full'>

                            {boardsFetched.length > 0 && (
                                <ul role="list" className="mt-8 divide-y divide-gray-100 bg-white rounded-md border-solid border-[1px] border-[#d1d1db] shadow-none
                  hover:ring-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100 focus:outline-indigo-600">
                                    {boardsFetched.map((items: any, index: number) => {
                                        const invitedByMember = baseMembers.find(
                                            (member: any) => member.id === items.user_id
                                        );

                                        const data = invitedByMember?.name?.charAt(0).toUpperCase()
                                        return (
                                            <li key={index} className="flex justify-between items-center gap-x-6 p-2 flex-wrap lg:flex-nowrap">
                                                <div className="flex min-w-0 gap-x-3 items-center">
                                                    {
                                                        invitedByMember?.profile_pic ?
                                                            <img
                                                                src={
                                                                    invitedByMember?.profile_pic
                                                                    && `${process.env.REACT_APP_API_BASE_URL}/${invitedByMember?.profile_pic}`}
                                                                alt="Selected file preview"
                                                                className="h-12 w-12 flex-none rounded-full text-gray-300"
                                                            /> :
                                                            <div className="flex shrink-0">
                                                                <span
                                                                    className="flex h-12 w-12 shrink-0 items-center justify-center rounded-full border-none text-[13px] font-medium text-white capitalize"
                                                                    style={{ backgroundColor: "rgb(107, 114, 128)" }}
                                                                >
                                                                    {data}
                                                                </span>
                                                            </div>
                                                    }
                                                    <div className="min-w-0 flex-auto">
                                                        <p className="text-sm font-semibold leading-4 text-gray-900 capitalize">
                                                            <span className="inset-x-0 -top-px bottom-0" />
                                                            {invitedByMember?.name}
                                                            {/* <Badge status={invitedByMember?.status} /> */}
                                                        </p>
                                                        <p className=" flex text-xs leading-5 text-gray-500">
                                                            {invitedByMember?.email}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='flex items-center justify-end'>
                                                    <div className='sm:col-span-3'>
                                                        <DropDown
                                                            onChangeValue={() => { }}
                                                            data={roleList}
                                                            previousValue={items?.role}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            )}


                        </div>

                    )}



                </div>
            </div>





            <Dialog open={addToBase} onClose={setAddToBase} className="relative z-10">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    onClick={() => setAddToBase(false)}
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    <span className="sr-only">Close</span>
                                    <CrossIcon />
                                </button>
                            </div>
                            <div className="flex flex-col gay-y-4">
                                <DialogTitle
                                    as="h3"
                                    className="text-base font-semibold leading-6 text-gray-900"
                                >
                                    Add Member
                                </DialogTitle>

                                <div className='w-full mt-5'>
                                    <label htmlFor='email' className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5'>
                                        Email
                                    </label>
                                    <div className='mt-[6px]'>
                                        <input
                                            id='email'
                                            name='email'
                                            type='email'
                                            value={email}
                                            onChange={(e) => {
                                                setEmailError("");
                                                setEmail(e.target.value)
                                            }}
                                            autoComplete='email'
                                            placeholder='Email address'
                                            className="flex w-full grow border px-4 py-2 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
                    focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                    border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                                        />
                                        <p className="mt-[6px] text-sm text-red-600" id="email-error">
                                            {emailError}
                                        </p>

                                    </div>
                                </div>
                                <div className='w-full mt-4'>
                                    <label htmlFor='country' className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5'>
                                        Role
                                    </label>
                                    <div className='mt-[6px]'>
                                        <DropDown onChangeValue={(e: any) => setSelectRole(e)} data={roleList} previousValue={selectRole} />
                                    </div>
                                </div>

                            </div>
                            <div className="flex gap-x-4 items-center justify-end mt-5">
                                <button
                                    onClick={add_baseHandler}
                                    type="submit"
                                    className="flex w-fit justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"

                                >
                                    {addBaseLoading ? (
                                        <div className='flex items-center  gap-[6px]'>
                                            Add to Base
                                            <div className="simple-spinner">
                                                <span></span>
                                            </div>
                                        </div>
                                    ) : (
                                        'Add to Base'
                                    )}
                                </button>

                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </main>
    )
});

export default TeamMemberDataDetails