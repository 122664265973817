
// src/routes/PrivateRoute.tsx
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks'; // Custom hook to access Redux store
import { setAuthStatus } from '../features/auth/authSlice';

const PrivateRoute: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.auth.token);


  useEffect(() => {
    if (token && !isAuthenticated) {
      dispatch(setAuthStatus());
    }
  }, [dispatch, token, isAuthenticated]);


  if (!isAuthenticated) {
    // Redirect to login page if not authenticated, and save the current location
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
