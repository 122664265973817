// @ts-nocheck
import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';
import MentionList from './VariableList';

import { addMentionList, getEditorInstance, getMentionList, updateMentionCount } from '../../editor';

let data = getMentionList()

export default {
  items: ({ query }) => {
    data = getMentionList()
    let dataArr = data.filter(item => item.name.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5);
    console.log(dataArr , "dataArr")
    return dataArr
  },

  render: () => {
    let component;
    let popup;
    let prop: any
    return {
      onStart: props => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });
        prop = props
        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        const { event } = props;

        if (event.key === 'Escape') {
          popup[0].hide();
          return true;
        }

        // Check if user pressed Enter to add a custom mention
        if (event.key === 'Enter') {
          //  / const { editor, range } = props;
          let editor = getEditorInstance()
          //  props.command({ id: "item" })
          const customText = editor.view.state['mention$1'].query.toLowerCase() //editor.state.doc.textBetween(range.from + 1, range.to, ' ');
          editor.chain().focus().insertContent('] ').run()


         
             const existingMention = data.find((mention) => mention.name === customText);
          
            if (existingMention) {
              // If name already exists, increment the count
        //  data=    updateMentionCount(existingMention.name ,existingMention.count+1)
            } else {
              // Otherwise, add a new object with count 1
        
              data = addMentionList(customText)
            }
          
          
          //  console.log("enter" , editor)
          // if (customText && !data.includes(customText)) {
            
            // Insert custom mention
            // prop.command( {label: customText } );
            // popup[0].hide();
            // event.preventDefault();
            // event.stopPropagation();
            return true;
          // }
        }
        if (event.key === ']') {
          //  / const { editor, range } = props;
          let editor = getEditorInstance()
          //  props.command({ id: "item" })
          const customText = editor.view.state['mention$1'].query.toLowerCase() //editor.state.doc.textBetween(range.from + 1, range.to, ' ');
            //  console.log("enter" , editor)
            editor.chain().focus().insertContent('] ').run()
          // if (customText && !data.includes(customText)) {
            const existingMention = data.find((mention) => mention.name === customText);
          
            if (existingMention) {
              // If name already exists, increment the count
            // data = updateMentionCount(existingMention.name ,existingMention.count+1)
            } else {
              // Otherwise, add a new object with count 1
        
              data = addMentionList(customText)
            }
            // Insert custom mention
            // prop.command( {label: customText } );
            // popup[0].hide();
            return true;
          // }
        }
     
        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};