// import { mergeAttributes, Node } from '@tiptap/core';
// import { DOMOutputSpec, Node as ProseMirrorNode } from '@tiptap/pm/model';
// import { PluginKey } from '@tiptap/pm/state';
// import Suggestion, { SuggestionOptions } from '@tiptap/suggestion';

// // Define the type for suggestion items
// interface MentionSuggestionItem {
//   text: string;
//   contextFunctions: {
//     addMention: (mention: string) => void;
//   };
// }

// export interface MentionNodeAttrs {
//   id: string | null;
//   label?: string | null;
// }

// // Update MentionOptions to use our specific item type
// export type MentionOptions = {
//   HTMLAttributes: Record<string, any>;
//   renderLabel?: (props: { options: MentionOptions; node: ProseMirrorNode }) => string;
//   renderText: (props: { options: MentionOptions; node: ProseMirrorNode }) => string;
//   renderHTML: (props: { options: MentionOptions; node: ProseMirrorNode }) => DOMOutputSpec;
//   deleteTriggerWithBackspace: boolean;
//   mentionContext?: {
//     mentionList: string[];
//     addMention: (mention: string) => void;
//   };
//   suggestion: Omit<SuggestionOptions<MentionSuggestionItem>, 'editor'>;
// }

// export const MentionPluginKey = new PluginKey('mention');

// export const createMentionExtension = () => {
//   const Mention = Node.create<MentionOptions>({
//     name: 'mention',

//     addOptions() {
//       return {
//         HTMLAttributes: {},
//         renderText({ options, node }) {
//           return `[${node.attrs.label ?? node.attrs.id}]`;
//         },
//         deleteTriggerWithBackspace: false,
//         renderHTML({ options, node }) {
//           return [
//             'span',
//             mergeAttributes(this.HTMLAttributes, options.HTMLAttributes),
//             `[${node.attrs.label ?? node.attrs.id}]`,
//           ];
//         },
//         suggestion: {
//           char: '[',
//           pluginKey: MentionPluginKey,
//           command: ({ editor, range, props }: { 
//             editor: any; 
//             range: { from: number; to: number }; 
//             props: MentionSuggestionItem 
//           }) => {
//             const { addMention } = props.contextFunctions;
//             const nodeAfter = editor.view.state.selection.$to.nodeAfter;
//             const overrideSpace = nodeAfter?.text?.startsWith(' ');

//             if (overrideSpace) {
//               range.to += 1;
//             }

//             const text = editor.state.doc.textBetween(range.from, range.to);
//             const mentionText = text.replace(/[\[\]]/g, '').trim();

//             if (mentionText) {
//               addMention(mentionText);

//               editor
//                 .chain()
//                 .focus()
//                 .insertContentAt(range, [
//                   {
//                     type: this.name,
//                     attrs: {
//                       id: mentionText,
//                       label: mentionText,
//                     },
//                   },
//                   {
//                     type: 'text',
//                     text: ' ',
//                   },
//                 ])
//                 .run();

//               editor.view.dom.ownerDocument.defaultView?.getSelection()?.collapseToEnd();
//             }
//           },
//           allow: ({ state, range }: { state: any; range: any }) => {
//             const $from = state.doc.resolve(range.from);
//             const type = state.schema.nodes[this.name];
//             const allow = !!$from.parent.type.contentMatch.matchType(type);

//             return allow;
//           },
//           items: ({ query, editor }: { query: string; editor: any }) => {
//             const { mentionList, addMention } = editor.options.mentionContext || {
//               mentionList: [],
//               addMention: () => {},
//             };

//             if (query.includes(']')) {
//               const mentionText = query.replace(/[\[\]]/g, '').trim();
//               if (mentionText) {
//                 return [{
//                   text: mentionText,
//                   contextFunctions: { addMention }
//                 }];
//               }
//             }

//             return mentionList
//               .filter(item => item.toLowerCase().startsWith(query.toLowerCase()))
//               .map(item => ({
//                 text: item,
//                 contextFunctions: { addMention }
//               }));
//           },
//         },
//       };
//     },

//     group: 'inline',
//     inline: true,
//     selectable: false,
//     atom: true,

//     addAttributes() {
//       return {
//         id: {
//           default: null,
//           parseHTML: element => element.getAttribute('data-id'),
//           renderHTML: attributes => {
//             if (!attributes.id) {
//               return {};
//             }
//             return {
//               'data-id': attributes.id,
//             };
//           },
//         },
//         label: {
//           default: null,
//           parseHTML: element => element.getAttribute('data-label'),
//           renderHTML: attributes => {
//             if (!attributes.label) {
//               return {};
//             }
//             return {
//               'data-label': attributes.label,
//             };
//           },
//         },
//       };
//     },

//     parseHTML() {
//       return [
//         {
//           tag: `span[data-type="${this.name}"]`,
//         },
//       ];
//     },

//     renderHTML({ node, HTMLAttributes }) {
//       return [
//         'span',
//         mergeAttributes({ 'data-type': this.name }, this.options.HTMLAttributes, HTMLAttributes),
//         `[${node.attrs.label ?? node.attrs.id}]`,
//       ];
//     },

//     addNodeView() {
//       return ({ node, getPos, editor }) => {
//         const dom = document.createElement('span');
//         dom.textContent = `[${node.attrs.label ?? node.attrs.id}]`;
//         dom.classList.add('mention');
        
//         const showPopup = (text: string) => {
//           const popup = document.createElement('div');
//           popup.classList.add('mention-popup');
//           popup.textContent = text;
          
//           Object.assign(popup.style, {
//             position: 'absolute',
//             background: '#fff',
//             border: '1px solid #ddd',
//             padding: '8px',
//             borderRadius: '4px',
//             top: `${dom.getBoundingClientRect().top + window.scrollY + 20}px`,
//             left: `${dom.getBoundingClientRect().left + window.scrollX}px`,
//             zIndex: '1000',
//           });
          
//           document.body.appendChild(popup);
          
//           setTimeout(() => {
//             document.body.removeChild(popup);
//           }, 2000);
//         };
        
//         const getRandomText = () => {
//           const messages = [
//             "Hello there!",
//             "You clicked on a mention!",
//             "Random text for you!",
//             "Here's a fun message!",
//             "Enjoy your day!"
//           ];
//           return messages[Math.floor(Math.random() * messages.length)];
//         };
        
//         dom.addEventListener('click', (event) => {
//           event.stopPropagation();
//           const randomText = getRandomText();
//           showPopup(randomText);
//         });
        
//         return {
//           dom,
//         };
//       };
//     },

//     addProseMirrorPlugins() {
//       return [
//         Suggestion({
//           editor: this.editor,
//           ...this.options.suggestion,
//         }),
//       ];
//     },
//   });

//   return Mention;
// };








import { mergeAttributes, Node } from '@tiptap/core';
import { DOMOutputSpec, Node as ProseMirrorNode } from '@tiptap/pm/model';
import { PluginKey } from '@tiptap/pm/state';
import Suggestion, { SuggestionOptions } from '@tiptap/suggestion';

// Define MentionNodeAttrs
export interface MentionNodeAttrs {
  id: string | null;
  label?: string | null;
}

export type MentionOptions<SuggestionItem = any, Attrs extends Record<string, any> = MentionNodeAttrs> = {
  HTMLAttributes: Record<string, any>;
  renderLabel?: (props: { options: MentionOptions<SuggestionItem, Attrs>; node: ProseMirrorNode }) => string;
  renderText: (props: { options: MentionOptions<SuggestionItem, Attrs>; node: ProseMirrorNode }) => string;
  renderHTML: (props: { options: MentionOptions<SuggestionItem, Attrs>; node: ProseMirrorNode }) => DOMOutputSpec;
  deleteTriggerWithBackspace: boolean;
  suggestion: Omit<SuggestionOptions<SuggestionItem, Attrs>, 'editor'>;
}

export const MentionPluginKey = new PluginKey('mention');

export const MentionData = Node.create<MentionOptions>({
  name: 'mentiondata',

  addOptions() {
    return {
      HTMLAttributes: {},
      renderText({ options, node }) {
        return `[${node.attrs.label ?? node.attrs.id}]`;
      },
      deleteTriggerWithBackspace: false,
      renderHTML({ options, node }) {
        return [
          'span',
          mergeAttributes(this.HTMLAttributes, options.HTMLAttributes, {
            'data-id': node.attrs.id,
            'data-name': node.attrs.label.toLowerCase() ?? node.attrs.id.toLowerCase(),
          }),
          `[${node.attrs.label.toLowerCase() ?? node.attrs.id.toLowerCase()}]`,
        ];
      },
      suggestion: {
        char: '[',
        allowSpaces:true,
        pluginKey: MentionPluginKey,
        command: ({ editor, range, props }) => {
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace = nodeAfter?.text?.startsWith(' ');

          if (overrideSpace) {
            range.to += 1;
          }

          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: props,
              },
              {
                type: 'text',
                text: ' ',
              },
            ])
            .run();

          editor.view.dom.ownerDocument.defaultView?.getSelection()?.collapseToEnd();
        },
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from);
          const type = state.schema.nodes[this.name];
          const allow = !!$from.parent.type.contentMatch.matchType(type);

          return allow;
        },
      },
    };
  },

  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: element => element.getAttribute('data-id'),
        renderHTML: attributes => {
          if (!attributes.id) {
            return {};
          }
          return {
            'data-id': attributes.id,
          };
        },
      },
      label: {
        default: null,
        parseHTML: element => element.getAttribute('data-label'),
        renderHTML: attributes => {
          if (!attributes.label) {
            return {};
          }
          return {
            'data-label': attributes.label,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        { 'data-type': this.name },
        this.options.HTMLAttributes,
        HTMLAttributes,
        { 'data-name': node.attrs.label ?? node.attrs.id  }
      ),
      `[${node.attrs.label ?? node.attrs.id}]`,
    ];  
  },

  addNodeView() {    
    return ({ node, getPos, editor }) => {
      const dom = document.createElement('span');
      dom.textContent = `[${node.attrs.label ?? node.attrs.id}]`;
      dom.classList.add('mention');
      dom.setAttribute('data-name', node.attrs.label ?? node.attrs.id);

      const showPopup = (text: string) => {
        const popup = document.createElement('div');
        popup.classList.add('mention-popup');
        popup.textContent = text;
  
        Object.assign(popup.style, {
          position: 'absolute',
          background: '#fff',
          border: '1px solid #ddd',
          padding: '8px',
          borderRadius: '4px',
          top: `${dom.getBoundingClientRect().top + window.scrollY + 20}px`,
          left: `${dom.getBoundingClientRect().left + window.scrollX}px`,
          zIndex: '1000',
        });
  
        document.body.appendChild(popup);
        setTimeout(() => {
          document.body.removeChild(popup);
        }, 2000);
      };
  
      const getRandomText = () => {
        const messages = [
          "Hello there!",
          "You clicked on a mention!",
          "Random text for you!",
          "Here's a fun message!",
          "Enjoy your day!",
        ];
        return messages[Math.floor(Math.random() * messages.length)];
      };
  
      dom.addEventListener('click', (event) => {
        event.stopPropagation();
        const randomText = getRandomText();
        showPopup(randomText);
      });
  
      return {
        dom,
      };
    };
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () => this.editor.commands.command(({ tr, state }) => {
        let isMention = false;
        const { selection } = state;
        const { empty, anchor } = selection;

        if (!empty) {
          return false;
        }

        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (node.type.name === this.name) {
            isMention = true;
            tr.insertText(
              this.options.deleteTriggerWithBackspace ? '' : this.options.suggestion.char || '',
              pos,
              pos + node.nodeSize,
            );

            return false;
          }
        });

        return isMention;
      }),
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
         ...this.options.suggestion,
      }),
    ];
  },
});