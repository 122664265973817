//@ts-nocheck
import { Node } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

export enum ColumnLayout {
  SidebarLeft = 'sidebar-left',
  SidebarRight = 'sidebar-right',
  TwoColumn = 'two-column',
  ThreeColumn = 'three-column',
}

export const Columns = Node.create({
  name: 'columns',
  group: 'block',
  content: 'column{2,3}',
  defining: true,
  isolating: true,

  addAttributes() {
    return {
      layout: {
        default: ColumnLayout.TwoColumn,
        parseHTML: element => element.getAttribute('data-layout') || ColumnLayout.TwoColumn,
        renderHTML: attributes => ({ 'data-layout': attributes.layout || ColumnLayout.TwoColumn }),
      },
      isSelected: {
        default: false,
        parseHTML: element => element.hasAttribute('data-selected'),
        renderHTML: attributes => attributes.isSelected ? { 'data-selected': 'true' } : {},
      }
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('columns-decoration'),
        props: {
          decorations: (state) => {
            const { doc, selection } = state;
            const decorations: Decoration[] = [];
            
            doc.descendants((node, pos) => {
              if (node.type.name === 'columns' || node.type.name === 'column') {
                const from = pos;
                const to = pos + node.nodeSize;
                
                // Check if current node is within selection
                if (selection.from >= from && selection.to <= to) {
                  decorations.push(
                    Decoration.node(from, to, {
                      class: 'has-focus'
                    })
                  );
                }
              }
            });
            
            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },

  addCommands() {
    return {
      setColumns: () => ({ chain, editor }) => {
        return chain()
          .focus()
          .insertContent({
            type: 'columns',
            content: [
              {
                type: 'column',
                attrs: { position: 'left' },
                content: [{ type: 'paragraph' }]
              },
              {
                type: 'column',
                attrs: { position: 'right' },
                content: [{ type: 'paragraph' }]
              }
            ]
          })
          .focus(editor.state.selection.$head.pos)
          .run();
      },

      setLayout: (layout: ColumnLayout) => ({ chain, editor }) => {
        const { $anchor } = editor.state.selection;
        let depth = $anchor.depth;
        let columnsNode;
        let pos;

        while (depth > 0) {
          const node = $anchor.node(depth);
          if (node.type.name === 'columns') {
            columnsNode = node;
            pos = $anchor.before(depth);
            break;
          }
          depth--;
        }

        if (!columnsNode || pos === undefined) return false;

        const currentColumns = columnsNode.content.childCount;
        const currentLayout = columnsNode.attrs.layout;

        if (currentLayout === layout) {
          return false;
        }

        return chain()
          .command(({ tr }) => {
            if (currentColumns === 2 && layout === ColumnLayout.ThreeColumn) {
              const newColumn = editor.schema.nodes.column.create(
                { position: 'center' },
                [editor.schema.nodes.paragraph.create()]
              );
              tr.insert(pos + columnsNode.nodeSize - 1, newColumn);
            }
            else if (currentColumns === 3 && 
                    (layout === ColumnLayout.TwoColumn || 
                     layout === ColumnLayout.SidebarLeft || 
                     layout === ColumnLayout.SidebarRight)) {
              // Find the center column position by iterating through the content
              let centerColumnPos = pos + 1; // Start after the columns node opening
              let columnIndex = 0;
              
              columnsNode.content.forEach((node, offset) => {
                if (columnIndex === 1) { // Center column
                  const from = centerColumnPos;
                  const to = from + node.nodeSize;
                  tr.delete(from, to);
                }
                centerColumnPos += node.nodeSize;
                columnIndex++;
              });
            }
            return true;
          })
          .updateAttributes('columns', { layout })
          .run();
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      {
        'data-type': 'columns',
        'class': `layout-${HTMLAttributes['data-layout'] || ColumnLayout.TwoColumn}`,
        ...(HTMLAttributes.isSelected ? { 'data-selected': 'true' } : {})
      },
      0
    ];
  },
  

  parseHTML() {
    return [
      {
        tag: 'div[data-type="columns"]',
      },
    ];
  },
});