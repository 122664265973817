import { TeamsResponse, createTeamsPayload, updateTeamsPayload } from '../features/questionSteps/questionStepTypes';
import { del, get, post, put } from '../utils/api';
import { formatUrl } from '../utils/urlFormatter';


const fetch_locations = '/{0}/{1}/questions.list';
const create_locations = '/{0}/questions.addMultiple';
const update_location = '/{0}/{1}/questions.update';
const fetch_locations_status = '/{0}/questions.list';
const fetch_pipeline_details = '/{0}/{1}/questions.detail'
const update_order_status = '/{0}/questions.updateOrder'
const delete_location = '/{0}/{1}/questions.delete'

const credentialsConfig = {
    withCredentials: true,
}


export const fetchLocationData = async (baseId: number, userId: number): Promise<TeamsResponse> => {
    const fetch_location_data = formatUrl(fetch_locations, [baseId, userId]);
    return get<TeamsResponse>(fetch_location_data, credentialsConfig);
};


export const fetchLocationDataStatus = async (baseId: number, status?: string): Promise<TeamsResponse> => {
    let fetch_location_data = formatUrl(fetch_locations_status, [baseId]);
    if (status) {
        fetch_location_data += `?status=${status}`;
    }
    return get<TeamsResponse>(fetch_location_data, credentialsConfig);
};


export const createLoationData = async (data: createTeamsPayload[], userId: number): Promise<TeamsResponse> => {
    const base_create_location = formatUrl(create_locations, [userId]);
    return post<TeamsResponse>(base_create_location, data , credentialsConfig);
};

export const updateLocationData = async (id: number, baseId: number, data: updateTeamsPayload): Promise<TeamsResponse> => {
    const update_teams_data = formatUrl(update_location, [id, baseId]);
    return put<TeamsResponse>(update_teams_data, data, credentialsConfig);
};

export const fetchPipelineDetails = async (baseId: number, userId: string): Promise<TeamsResponse> => {
    const fetch_pipeline_details1 = formatUrl(fetch_pipeline_details, [baseId, userId]);
    return get<TeamsResponse>(fetch_pipeline_details1, credentialsConfig);
};

export const updateOrderStatus = async (baseId: number, data: any): Promise<TeamsResponse> => {
    const update_order_status1 = formatUrl(update_order_status, [baseId]);
    return put<TeamsResponse>(update_order_status1, data, credentialsConfig);
};

// delete 
export const deleteLocationData = async (id: number, baseId: number): Promise<TeamsResponse> => {
    const delete_location_data = formatUrl(delete_location, [id, baseId]);
    return del<TeamsResponse>(delete_location_data, credentialsConfig);
};