// src/components/dashboard/DashboardLayout.tsx
import React, { useEffect, useState } from 'react';
import { ReactNode } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DocumentDetailSidebar from './DocumentDetailSidebar';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
// import { fetchTagsDataList } from '../../features/pipelineStages/pipelineStagesSlice';

interface UserDetails {
  id: number;
}

interface DashboardLayoutProps {
  children: ReactNode;
  userDetails: UserDetails;
}


const DocumentDetailsLayout: React.FC<DashboardLayoutProps> = ({ children }) => {

  const { baseId } = useParams<{ baseId: string }>(); // Extract baseId from URL
  const [userDetails, setUserDetails] = useState<any>(null);
  const bases = useAppSelector((state: RootState) => state?.base?.data?.data?.bases);
  const location = useLocation();
  useEffect(() => {

    if (bases && bases.length > 0) {
      const matchedBase = bases.find((base: { id: number }) => base.id.toString() === baseId);
      if (matchedBase) {
        setUserDetails(matchedBase);
      }
    }
    else if (baseId) {
      setUserDetails(baseId);
    }

    else {
      setUserDetails(null);

    }
  }, [baseId, bases]);



  // Check if current route is DocumentDetails
  const isDocumentDetails = location.pathname.includes('/d/');

  return (
    <div className="h-[calc(100vh_-_4rem)]">
      {/* <DocumentDetailSidebar  userDetails={userDetails}/> */}
      <div className=''>
        <main className={`${!isDocumentDetails ? 'p-0' : 'p-0'}`}>
          <div className='w-full'>
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default DocumentDetailsLayout;
