// @ts-nocheck
// src/features/document/documentSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ApiError, CreateDocumentActionPayload, DocumentResponse, DocumentState } from './documentItemTypes';
import { createItemMmeberActionTemplate, deleteItemMemberActionTemplate, fetchDocumentTemplate, fetchItemMemberTemplate } from '../../services/documentItemService';



export const fetchItemMemberItem = createAsyncThunk<
  DocumentResponse,
  { baseId: string; id: string; type?: string }, // `type` is optional
  { rejectValue: string }
>(
  'document/fetchItemMemberItem',
  async ({ baseId, id, type }, { rejectWithValue }) => {
    try {
      const response = await fetchItemMemberTemplate(baseId, id, type);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch document');
    }
  }
);


export const fetchDocumentItem = createAsyncThunk<
  DocumentResponse,
  { baseId: string; id: string; type?: string }, // `type` is optional
  { rejectValue: string }
>(
  'document/fetchDocumentItem',
  async ({ baseId, id, type }, { rejectWithValue }) => {
    try {
      const response = await fetchDocumentTemplate(baseId, id, type);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch document');
    }
  }
);


// document action template 
export const createDocumentActionData = createAsyncThunk<
  DocumentResponse,
  CreateDocumentActionPayload,
  { rejectValue: string }
>(
  'document/createDocumentAction',
  async (payload, { rejectWithValue }) => {
    try {
      if (!payload.baseId || isNaN(payload.baseId)) {
        return rejectWithValue('Valid base ID is required');
      }
      if (!payload.actionPayload) {
        return rejectWithValue('Action payload is required');
      }
      
      const response = await createItemMmeberActionTemplate(payload);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to create document');
    }
  }
);

export const deleteDocumentActionData = createAsyncThunk<
  DocumentResponse,
  { baseId: string; id: string },
  { rejectValue: string }
>(
  'document/deleteDocumentAction',
  async ({ baseId, id }, { rejectWithValue }) => {
    try {
      const response = await deleteItemMemberActionTemplate(baseId, id);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to delete document');
    }
  }
);


const initialState: DocumentState = {
    data: null,
    fetchStatus: 'idle',
    fetchError: null,
    updateStatus: 'idle',
    updateError: null,
    // Add other properties as needed
    itemMemberList: null,
    itemMemberListStatus: 'idle',
    itemMemberListError: null,
    createItemMemberStatus: 'idle',
    createItemMemberError: null,
    createDocumentAction: null,
    // Add other properties as needed
    deleteItemMemberStatus: 'idle',
    deleteItemMemberError: null
  
}


const documentItemSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
      resetDocumentState: (state) => {
        state.data = null;
        state.fetchStatus = 'idle';
        state.fetchError = null;
        state.updateStatus = 'idle';
        state.updateError = null;
        state.itemMemberList = null;
        state.itemMemberListStatus = 'idle';
        state.itemMemberListError = null

      },
      clearErrors: (state) => {
        state.fetchError = null;
        state.updateError = null;
        state.itemMemberListError = null
      }
    },
    extraReducers: (builder) => {
      builder
        // Cases for fetchDocument
        .addCase(fetchDocumentItem.pending, (state) => {
          state.fetchStatus = 'loading';
          state.fetchError = null;
        })
        .addCase(fetchDocumentItem.fulfilled, (state, action: PayloadAction<DocumentResponse>) => {
          state.fetchStatus = 'succeeded';
          state.data = action.payload.data;
        })
        .addCase(fetchDocumentItem.rejected, (state, action) => {
          state.fetchStatus = 'failed';
          state.fetchError = action.payload || 'Failed to fetch document';
        })
        .addCase(fetchItemMemberItem.pending, (state) => {
          state.itemMemberListStatus = 'loading';
          state.itemMemberListError = null;
        })
        .addCase(fetchItemMemberItem.fulfilled, (state, action: PayloadAction<DocumentResponse>) => {
          state.itemMemberListStatus = 'succeeded';
          state.itemMemberList = action.payload.data;
        })
        .addCase(fetchItemMemberItem.rejected, (state, action) => {
          state.itemMemberListStatus = 'failed';
          state.itemMemberListError = action.payload || 'Failed to fetch document';
        })

        // Cases for createDocumentAction
        .addCase(createDocumentActionData.pending, (state) => {
          state.createItemMemberStatus = 'loading';
          state.createItemMemberError = null;
        })
        .addCase(createDocumentActionData.fulfilled, (state, action: PayloadAction<DocumentResponse>) => {
          state.createItemMemberStatus = 'succeeded';
          state.createDocumentAction = action.payload.data;
        })
        .addCase(createDocumentActionData.rejected, (state, action) => {
          state.createItemMemberStatus = 'failed';
          state.createItemMemberError = action.payload || 'Failed to create document';
        })
        .addCase(deleteDocumentActionData.pending, (state) => {
          state.deleteItemMemberStatus = 'loading';
          state.deleteItemMemberError = null;
        })
        .addCase(deleteDocumentActionData.fulfilled, (state, action: PayloadAction<DocumentResponse>) => {
          state.deleteItemMemberStatus = 'succeeded';
          state.data = action.payload.data;
        })
        .addCase(deleteDocumentActionData.rejected, (state, action) => {
          state.deleteItemMemberStatus = 'failed';
          state.deleteItemMemberError = action.payload || 'Failed to delete document';
        });
        // // Cases for updateDocument
        // .addCase(updateDocument.pending, (state) => {
        //   state.updateStatus = 'loading';
        //   state.updateError = null;
        // })
        // .addCase(updateDocument.fulfilled, (state, action: PayloadAction<DocumentResponse>) => {
        //   state.updateStatus = 'succeeded';
        //   state.data = action.payload.data;
        // })
        // .addCase(updateDocument.rejected, (state, action) => {
        //   state.updateStatus = 'failed';
        //   state.updateError = action.payload || 'Failed to update document';
        // });
    }
  });
  

  export const { resetDocumentState, clearErrors } = documentItemSlice.actions;

  export default documentItemSlice.reducer;
