// src/services/baseService.ts
import { TeamsMembersResponse } from '../features/teamsMembers/teamsMemberTypes';
import { del, get } from '../utils/api';
import { formatUrl } from '../utils/urlFormatter';


const fetch_teams_members_list = '/{0}/{1}/channelMember.list';
const base_team_member_invitation_status = '/channel/{0}/{1}/invitations';
const base_team_member_remove = '/channel/invite/{0}/{1}/deleteInvite';

const credentialsConfig = {
    withCredentials: true,
}


export const fetchTeamsMembersData = async (
    baseId: number,
    teamId: number
  ): Promise<TeamsMembersResponse> => {
    // Ensure both baseId and teamId are passed to replace placeholders
    const fetch_members_data = formatUrl(fetch_teams_members_list, [baseId, teamId]);
  
    // Make the GET request to fetch data
    return get<TeamsMembersResponse>(fetch_members_data, credentialsConfig);
  };

export const fetchBaseTeamMemberInvitationStatus = async (baseId: number, teamId: number): Promise<TeamsMembersResponse> => {
    const team_invitation_status = formatUrl(base_team_member_invitation_status, [baseId, teamId]);
    return get<TeamsMembersResponse>(team_invitation_status, credentialsConfig);
};

export const deleteTeamMemberInvitation = async (baseId: number, invitationId: number): Promise<TeamsMembersResponse> => {
    const formattedUrl = formatUrl(base_team_member_remove, [baseId, invitationId]);
    return del<TeamsMembersResponse>(formattedUrl, credentialsConfig);
};