import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ApiError, DocumentResponse, DocumentState } from './documentItemFillTypes';
import {  fetchItemMemberTemplate } from '../../services/documentItemFillService';



export const fetchExecuteItemList = createAsyncThunk<
  DocumentResponse,
  { baseId: string; id: string; type?: string }, // `type` is optional
  { rejectValue: string }
>(
  'document/fetchItemMemberItem',
  async ({ baseId, id, type }, { rejectWithValue }) => {
    try {
      const response = await fetchItemMemberTemplate(baseId, id, type);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch document');
    }
  }
);



const initialState: DocumentState = {
    itemMemberList: null,
    itemMemberListStatus: 'idle',
    itemMemberListError: null
}


const documentItemFillSlice = createSlice({
    name: 'documentFill',
    initialState,
    reducers: {
      resetDocumentState: (state) => {
        state.itemMemberList = null;
        state.itemMemberListStatus = 'idle';
        state.itemMemberListError = null

      },
      clearErrors: (state) => {
        state.itemMemberListError = null
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchExecuteItemList.pending, (state) => {
          state.itemMemberListStatus = 'loading';
          state.itemMemberListError = null;
        })
        .addCase(fetchExecuteItemList.fulfilled, (state, action: PayloadAction<DocumentResponse>) => {
          state.itemMemberListStatus = 'succeeded';
          state.itemMemberList = action.payload.data;
        })
        .addCase(fetchExecuteItemList.rejected, (state, action) => {
          state.itemMemberListStatus = 'failed';
          state.itemMemberListError = action.payload || 'Failed to fetch document';
        })
    }
  });
  

  export const { resetDocumentState, clearErrors } = documentItemFillSlice.actions;

  export default documentItemFillSlice.reducer;
