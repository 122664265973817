// pipelineSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { deleteLocationData, fetchLocationDataStatus, fetchPipelineDetails, updateLocationData, updateOrderStatus } from '../../services/QuestionStepService';
import { ApiError, TeamsResponse, TeamsState, createTeamsPayload, updateTeamsPayload } from './questionStepTypes'
import { createLoationData, fetchLocationData } from '../../services/QuestionStepService';



export const fetchQuestionStepList = createAsyncThunk<
  TeamsResponse,
  { baseId: number; userId: number },
  { rejectValue: string }
>(
  'base/fetchLocationList',
  async ({ baseId, userId }, { rejectWithValue }) => {
    try {
      if (baseId === undefined) {
        throw new Error('Base ID is undefined');
      }
      const response = await fetchLocationData(baseId, userId);
      return response;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message || 'Failed to fetch base data');
    }
  }
);



// fetch location by status
export const fetchLocationListStatus = createAsyncThunk<
  TeamsResponse,
  { baseId: number; status?: string },
  { rejectValue: string }
>(
  'base/fetchLocationListStatus',
  async ({ baseId, status }, { rejectWithValue }) => {
    try {
      const response = await fetchLocationDataStatus(baseId, status);
      return response;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message || 'Failed to fetch base data');
    }
  }
);


// create teams 
export const createLocation = createAsyncThunk<
  TeamsResponse,
  { integrationSteps: createTeamsPayload[]; userId: number },
  { rejectValue: string }
>(
  'base/createLocation',
  async ({ integrationSteps, userId }, { rejectWithValue }) => {
    try {
      const response = await createLoationData(integrationSteps, userId);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to create location');
    }
  }
);



// update teams
export const updateLocation = createAsyncThunk<TeamsResponse, { payload: updateTeamsPayload; baseId: number, id: number },
  { rejectValue: string }
>(
  'base/updateLocation',
  async ({ payload, baseId, id }, { rejectWithValue }) => {
    try {
      const response = await updateLocationData(baseId, id, payload);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to invite member');
    }
  }
);

// pipeline details

export const fetchPipelineDetailsData = createAsyncThunk<
  TeamsResponse,
  { baseId: number; userId: string },
  { rejectValue: string }
>(
  'base/fetchPipelineDetails',
  async ({ baseId, userId }, { rejectWithValue }) => {
    try {
      if (baseId === undefined) {
        throw new Error('Base ID is undefined');
      }
      const response = await fetchPipelineDetails(baseId, userId);
      return response;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message || 'Failed to fetch pipeline details');
    }
  }
);

// update order 
// export const updatePipelineOrder = createAsyncThunk<
//   TeamsResponse,
//   { baseId: number; updates: { id: number; order: number }[] },
//   { rejectValue: string }
// >(
//   'base/updatePipelineOrder',
//   async ({ baseId, updates }, { rejectWithValue }) => {
//     try {
//       const response = await updateOrderStatus(baseId, updates);
//       return response;
//     } catch (err) {
//       const error = err as ApiError;
//       return rejectWithValue(error.response?.data?.message || 'Failed to update pipeline order');
//     }
//   }
// );

export const updatePipelineOrder = createAsyncThunk<
  TeamsResponse,
  { baseId: number; updates: { id: number; order: number }[] },
  { rejectValue: string }
>(
  'base/updatePipelineOrder',
  async ({ baseId, updates }, { rejectWithValue }) => {
    try {
      // Pass baseId and the data object as separate arguments
      const response = await updateOrderStatus(baseId, { updates });
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to update pipeline order');
    }
  }
);

// delete deleteLocationData
export const deleteLocation = createAsyncThunk<TeamsResponse, { baseId: number; id: number },
  { rejectValue: string }
>(
  'base/deleteLocationData',
  async ({ baseId, id }, { rejectWithValue }) => {
    try {
      const response = await deleteLocationData(baseId, id);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to invite member');
    }
  }
);

const initialState: TeamsState = {
  locationDetails: null,
  locationStatus: 'idle',
  locationStatusError: null,
  createTeamsStatus: 'idle',
  createTeamsError: null,
  createTeamsData: null,
  updateTeamsStatus: 'idle',
  updateTeamsError: null,
  updateTeamsData: null,
  deleteTeamsStatus: 'idle',
  deleteTeamsError: null,
  fetchTeamsByIdStatus: 'idle',
  fetchTeamsByIdError: null,
  fetchTeamsByIdData: null,
  baseMemberInviteStatus: 'idle',
  baseMemberInviteError: null,
  baseMemberInviteData: null,
  locationDetails1: null,
  locationStatus1: 'idle',
  locationStatusError1: null,

  // fetch pipeline details
  
  pipelineDetails: null,
  pipelineStatus: 'idle',
  pipelineStatusError: null,
  updateOrderStatus: 'idle',
  updateOrderError: null,
  // delete location 

  deleteLocationStatus: 'idle',
  deleteLocationError: null,
  //////////////// differ 
  selectedElement: null,
  isFormAsideOpen: false,

};

const questionStepSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    selectElementSteps: (state, action: PayloadAction<{ id: string; element: any }>) => {
      state.selectedElement = action.payload.element;
      state.isFormAsideOpen = true;
    },
    closeFormAside: (state) => {
      state.isFormAsideOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestionStepList.pending, (state) => {
        state.locationStatus = 'loading';
      })
      .addCase(fetchQuestionStepList.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.locationStatus = 'succeeded';
        state.locationDetails = action.payload.data;
        // localStorage.setItem('basePiplineStagesData', JSON.stringify(action.payload));
      })
      .addCase(fetchQuestionStepList.rejected, (state, action) => {
        state.locationStatus = 'failed';
        state.locationStatusError = action.payload || 'Failed to fetch boards';
      })
      .addCase(createLocation.pending, (state) => {
        state.createTeamsStatus = 'loading';
      })
      .addCase(createLocation.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.createTeamsStatus = 'succeeded';
        state.createTeamsData = action.payload;
      })
      .addCase(createLocation.rejected, (state, action) => {
        state.createTeamsStatus = 'failed';
        state.createTeamsError = action.payload || 'Failed to invite member';
      })
      .addCase(updateLocation.pending, (state) => {
        state.updateTeamsStatus = 'loading';
      })
      .addCase(updateLocation.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.updateTeamsStatus = 'succeeded';
        state.updateTeamsData = action.payload;
      })
      .addCase(updateLocation.rejected, (state, action) => {
        state.updateTeamsStatus = 'failed';
        state.updateTeamsError = action.payload || 'Failed to invite member';
      })
      .addCase(fetchLocationListStatus.pending, (state) => {
        state.locationStatus1 = 'loading';
      })
      .addCase(fetchLocationListStatus.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.locationStatus1 = 'succeeded';
        state.locationDetails1 = action.payload.data;
        // localStorage.setItem('basePiplineStagesData', JSON.stringify(action.payload));
      })
      .addCase(fetchLocationListStatus.rejected, (state, action) => {
        state.locationStatus1 = 'failed';
        state.locationStatusError1 = action.payload || 'Failed to fetch boards';
      })
      .addCase(fetchPipelineDetailsData.pending, (state) => {
        state.pipelineStatus = 'loading';
      })
      .addCase(fetchPipelineDetailsData.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.pipelineStatus = 'succeeded';
        state.pipelineDetails = action.payload.data;
        // localStorage.setItem('basePiplineStagesData', JSON.stringify(action.payload));
      })
      .addCase(fetchPipelineDetailsData.rejected, (state, action) => {
        state.pipelineStatus = 'failed';
        state.pipelineStatusError = action.payload || 'Failed to fetch boards';
      })
      .addCase(updatePipelineOrder.pending, (state) => {
        state.updateOrderStatus = 'loading';
      })
      .addCase(updatePipelineOrder.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.updateOrderStatus = 'succeeded';
        // Optionally update state with the response data if needed
      })
      .addCase(updatePipelineOrder.rejected, (state, action) => {
        state.updateOrderStatus = 'failed';
        state.updateOrderError = action.payload || 'Failed to update pipeline order';
      })
      .addCase(deleteLocation.pending, (state) => {
        state.deleteLocationStatus = 'loading';
      })
      .addCase(deleteLocation.fulfilled, (state, action: PayloadAction<TeamsResponse>) => {
        state.deleteLocationStatus = 'succeeded';
        // Optionally update state with the response data if needed
      })
      .addCase(deleteLocation.rejected, (state, action) => {
        state.deleteLocationStatus = 'failed';
        state.deleteLocationError = action.payload || 'Failed to delete location';
      });
  }
});


export const { selectElementSteps, closeFormAside } = questionStepSlice.actions;

export default questionStepSlice.reducer;

