import Mention from "@tiptap/extension-mention";

let editorInstance: null = null;

export const setEditorInstance = (editor :any) => {
  editorInstance = editor;
};

export const getEditorInstance = () => {
  return editorInstance;
};

//  let mentionList: string[] = [];

//  export const getMentionList=()=>{
//   return mentionList;
//  }

//  export const addMentionList=(name:any)=>{
//   if ( !mentionList.includes(name)) {
//     mentionList.push(name);
//   }
//   return mentionList;
//  }
//  export const deleteMentionList = (name: any) => {
//   const index = mentionList.indexOf(name);
//   if (index !== -1) {
//     mentionList.splice(index, 1);
//   }
//   return mentionList;
// }

let mentionList: { name: string; count: number }[] = [];

export const getMentionList = () => {
  return mentionList;
};

export const addMentionList = (name: string) => {
  // window.alert("addMentionList  > " + name)
  const existingMention = mentionList.find((mention) => mention.name === name);
   if(!existingMention){

     mentionList.push({ name, count: 1 });
   }
  // if (existingMention) {
  //   // If name already exists, increment the count
  //   existingMention.count += 1;
  // } else {
    // Otherwise, add a new object with count 1
  // }

  return mentionList;
};

// export const updateCountMentionList = (name: string) => {
//   // window.alert("audatecountMentionList  > " + name)
//   const existingMention = mentionList.find((mention) => mention.name === name);

//   if (existingMention) {

//     existingMention.count += 1;
//   } 

//   return mentionList;
// };

export const deleteMentionList = (name: string) => {
  const index = mentionList.findIndex((mention) => mention.name === name);

  if (index !== -1) {
    // If the name exists and count is more than 1, decrease the count
    if (mentionList[index].count > 1) {
      mentionList[index].count -= 1;
    } else {
      // Otherwise, remove the entry from the list
      mentionList.splice(index, 1);
    }
  }

  return mentionList;
};

// export const updateMentionCount = (name: string, newCount?: number) => {
//   const mention = mentionList.find((mention) => mention.name === name);

//   if (mention) {
//     newCount ? mention.count = newCount: mention.count +=1
    
//   }
//    else {
//     // If the mention does not exist, you can optionally add it with the new count
//     mentionList.push({ name, count: 1 });
//   }

//   return mentionList;
// };

export const updateMentionCount = (name: string, newCount: number) => {
 mentionList.forEach(mention=>{

   if (mention.name == name) {
     mention.count = newCount;
   }
 })

  //  else {
  //   mentionList.push({ name, count: newCount });
  // }

  return mentionList;
};