import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import DocumentHeader from './DocumentHeader';

const Documents = () => {
  const [searchTerm, setSearchTerm] = useState(''); 

  const handleSearchChange = (term: string) => {
      setSearchTerm(term); 
  };
  return (
    <>
    <DocumentHeader/>
     <Outlet  /> 
    </>
     
  );
};

export default Documents;
