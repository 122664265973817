// @ts-nocheck
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ApiError, DocumentResponse, DocumentState } from './docuementItemActivityTypes';
import { fetchDocumentActivityTemplate } from '../../services/documentItemActivityService';

export const fetchDocumentActivityItem = createAsyncThunk<
  DocumentResponse,
  { baseId: string; id: string; activity_type?: string; page?: number; limit?: number },
  { rejectValue: string }
>(
  'document/fetchDocumentActivityItem',
  async ({ baseId, id, activity_type, page = 1, limit = 25 }, { rejectWithValue }) => {
    try {
      const response = await fetchDocumentActivityTemplate(baseId, id, activity_type, page, limit);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch document');
    }
  }
);

const initialState: DocumentState = {
  data: null,
  fetchStatus: 'idle',
  fetchError: null,
  pagination: {
    currentPage: 1,
    totalPages: 0,
    hasMore: false
  }
};

const docuementItemActivitySlice = createSlice({
  name: 'document',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocumentActivityItem.pending, (state) => {
        state.fetchStatus = 'loading';
        state.fetchError = null;
      })
      .addCase(fetchDocumentActivityItem.fulfilled, (state, action) => {
        state.fetchStatus = 'succeeded';

        const pagination = {
          currentPage: action.payload.currentPage || 1,
          totalPages: action.payload.totalPages || 0,
          hasNextPage: action.payload.hasNextPage || false,
        };

        // If we're on the first page or pagination info is not present, replace the data
        if (!pagination.currentPage || pagination.currentPage === 1) {
          state.data = {
            itemActivity: action.payload.itemActivity || [],
          };
        } else {
          // Otherwise, append new data to the existing list of item activities
          state.data = {
            ...state.data,
            itemActivity: [
              ...(state.data?.itemActivity || []),
              ...(action.payload.itemActivity || []),
            ],
          };
        }

        // Update pagination state with values from the API response
        state.pagination = {
          currentPage: pagination.currentPage,
          totalPages: pagination.totalPages,
          hasNextPage: pagination.hasNextPage,
        };
      })
      .addCase(fetchDocumentActivityItem.rejected, (state, action) => {
        state.fetchStatus = 'failed';
        state.fetchError = action.payload || 'Failed to fetch document';
      });
  }
});

export default docuementItemActivitySlice.reducer;
