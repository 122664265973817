
//@ts-nocheck
import { ReactRenderer } from '@tiptap/react';
import tippy, { Instance } from 'tippy.js';

import { EmojiList } from './EmojiList';

// Define types for the emoji data structure
interface Emoji {
  shortcodes: string[];
  tags: string[];
}

interface Editor {
  storage: {
    emoji: {
      emojis: Emoji[];
    };
  };
}

interface Props {
  editor: Editor;
  query: string;
  clientRect: () => DOMRect;
  event?: KeyboardEvent;
}

// Define the type for the renderer's component
interface RendererComponent {
  updateProps: (props: Props) => void;
  destroy: () => void;
  ref?: {
    onKeyDown: (props: Props) => boolean;
  };
}

export default {
  items: ({ editor, query }: { editor: Editor; query: string }) => {
    return editor.storage.emoji.emojis
      .filter(({ shortcodes, tags }) => {
        const lowerQuery = query.toLowerCase();
        return (
          shortcodes.some((shortcode) => shortcode.startsWith(lowerQuery)) ||
          tags.some((tag) => tag.startsWith(lowerQuery))
        );
      })
      .slice(0, 5);
  },

  allowSpaces: false,

  render: () => {
    let component: RendererComponent;
    let popup: Instance[];

    return {
      onStart: (props: Props) => {
        component = new ReactRenderer(EmojiList, {
          props,
          editor: props.editor,
        }) as RendererComponent;

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate: (props: Props) => {
        component.updateProps(props);

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown: (props: Props) => {
        if (props.event?.key === 'Escape') {
          popup[0].hide();
          component.destroy();

          return true;
        }

        return component.ref?.onKeyDown(props) ?? false;
      },

      onExit: () => {
        popup[0]?.destroy();
        component?.destroy();
      },
    };
  },
};
