// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dropdown menu */
.emoji.dropdown-menu {
    background: #fff;
    border: 1px solid rgb(61 37 20 / 5%);
    border-radius: 0.7rem;
    box-shadow: 0px 12px 33px 0px rgba(0, 0, 0, .06), 0px 3.618px 9.949px 0px rgba(0, 0, 0, .04);
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    overflow: auto;
    padding: 0.4rem;
    position: relative;
}
.emoji.dropdown-menu button {
    align-items: center;
    background-color: transparent;
    display: flex;
    gap: 0.25rem;
    text-align: left;
    width: 100%;
}
.emoji.dropdown-menu button:hover, .dropdown-menu button:hover.is-selected {
    background-color: rgba(61, 37, 20, .12);
}
.emoji.dropdown-menu button.is-selected {
    background-color: rgba(61, 37, 20, .08);
}
.emoji.dropdown-menu button img {
    height: 1em;
    width: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/extensions/Emoji/EmojiList.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,gBAAgB;IAChB,oCAAoC;IACpC,qBAAqB;IACrB,4FAA4F;IAC5F,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,cAAc;IACd,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,mBAAmB;IACnB,6BAA6B;IAC7B,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,uCAAuC;AAC3C;AACA;IACI,uCAAuC;AAC3C;AACA;IACI,WAAW;IACX,UAAU;AACd","sourcesContent":["/* Dropdown menu */\n.emoji.dropdown-menu {\n    background: #fff;\n    border: 1px solid rgb(61 37 20 / 5%);\n    border-radius: 0.7rem;\n    box-shadow: 0px 12px 33px 0px rgba(0, 0, 0, .06), 0px 3.618px 9.949px 0px rgba(0, 0, 0, .04);\n    display: flex;\n    flex-direction: column;\n    gap: 0.1rem;\n    overflow: auto;\n    padding: 0.4rem;\n    position: relative;\n}\n.emoji.dropdown-menu button {\n    align-items: center;\n    background-color: transparent;\n    display: flex;\n    gap: 0.25rem;\n    text-align: left;\n    width: 100%;\n}\n.emoji.dropdown-menu button:hover, .dropdown-menu button:hover.is-selected {\n    background-color: rgba(61, 37, 20, .12);\n}\n.emoji.dropdown-menu button.is-selected {\n    background-color: rgba(61, 37, 20, .08);\n}\n.emoji.dropdown-menu button img {\n    height: 1em;\n    width: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
