// @ts-nocheck

// src/components/dashboard/DashboardLayout.tsx
import React, { useEffect, useState } from 'react';
import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from './Sidebar';
import { useAppSelector } from '../../../../app/hooks';
import { Outlet } from 'react-router-dom';
import LoadingSpinner from '../../../../components/common/LoadingSpinner';

interface UserDetails {
  id: number;
}

interface DashboardLayoutProps {
  children: ReactNode;
  userDetails: UserDetails; // Update to UserDetails type
}


const DetailsLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const { baseId } = useParams<{ baseId: string }>(); // Extract baseId from URL
  const [userDetails, setUserDetails] = useState<any>(null);
  const bases = useAppSelector((state: any) => state?.base?.data?.data?.bases); // Get bases from Redux state

  useEffect(() => {

    if (bases && bases.length > 0) {
      const matchedBase = bases.find((base: { id: number }) => base.id.toString() === baseId);
      if (matchedBase) {
        setUserDetails(matchedBase);
      }
    }
    else if (baseId) {
      setUserDetails(baseId);
    }

    else {
      setUserDetails(null);

    }
  }, [baseId, bases]);


  // if (!userDetails) {
  //   return <LoadingSpinner />;
  // }
  
  return (
    <div className="h-screen">
      <div className='py-[34px] lg:py-[70px] px-0 lg:px-10 h-full flex'>
        <div className='px-[2px] sm:px-6 lg:px-8 w-full flex justify-center'>
          <div className='w-full max-w-[788px] flex gap-x-[2rem]'>
            <div className='w-[20%]'>
              <Sidebar userDetails={userDetails} />
            </div>
            <div className=' w-[80%]'>
              <main className="flex-1 bg-white h-full overflow-auto">
                {children}
                {/* <Outlet />   */}
              </main>
            </div>


          </div>
        </div>
      </div>

    </div>
  );
};

export default DetailsLayout;
