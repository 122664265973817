import React from 'react'

function BaseTest() {
    return (
        <main className="flex-1 bg-white h-full overflow-auto">
            <main className="h-full flex">
                <div className=" w-full flex justify-center">
                    <div className="w-full ">
                  base
                    </div>
                </div>
            </main>
        </main>

    )
}

export default BaseTest