// import { Mark } from '@tiptap/core';
// import { Plugin, PluginKey } from 'prosemirror-state';
// import { getMentionList, addMentionList } from '../../editor';

// let variables = getMentionList(); // Initial variables

// export const deleteSuggestions = (data: any) => {
//   const index = variables.indexOf(data);
//   if (index > -1) {
//     variables = variables.filter((data1) => data1 !== data);
//   }
//   return variables;
// };

// export const ColoredBrackets = Mark.create({
//   name: 'coloredBrackets',

//   // Define the attributes for the colored brackets, including `data-name`
//   addAttributes() {
//     return {
//       class: {
//         default: 'mention',
//       },
//       name: {
//         default: null,
//       },
//     };
//   },

//   parseHTML() {
//     return [
//       {
//         tag: 'span.mention',
//         getAttrs: element => ({
//           name: element.getAttribute('data-name'), // Parse `data-name` attribute from HTML
//         }),
//       },
//     ];
//   },

//   renderHTML({ HTMLAttributes }) {
//     // Explicitly set the `data-name` attribute instead of just `name`
//     const { name, ...rest } = HTMLAttributes;
//     return [
//       'span',
//       { ...rest, 'data-name': name, contenteditable: 'false' },
//       `[${name}]`,
//     ];
//   },

//   addProseMirrorPlugins() {
//     return [
//       new Plugin({
//         key: new PluginKey('coloredBrackets'),
//         props: {
//           handleKeyDown(view, event) {
//             const { state } = view;

//             if (event.key === ']') {
//               const { $from } = state.selection;
//               const textBeforeCursor = $from.nodeBefore?.textContent || '';
//               const openBracketIndex = textBeforeCursor.lastIndexOf('[');

//               if (openBracketIndex > -1) {
//                 const variable = textBeforeCursor.slice(openBracketIndex + 1, $from.pos);

//                 if (variable.length > 0 && !variables.includes(variable)) {
//                   variables = addMentionList(variable);
//                 }
//               }
//             }
//             return false;
//           },

//           handlePaste(view, event) {
//             const pastedText = event.clipboardData?.getData('text/plain');
//             const regex = /\[([^\]]+)\]/g;
//             let match;

//             while ((match = regex.exec(pastedText || '')) !== null) {
//               const variable = match[1];

//               if (variable.length > 0 && !variables.includes(variable)) {
//                 variables = addMentionList(variable);
//               }
//             }

//             return false;
//           },
//         },

//         appendTransaction(transactions, oldState, newState) {
          
//           const regex = /\[([^\]]+)\]/g;
//           let tr = newState.tr;
//           let modified = false;
//           const extractedVariables = new Set();

//           newState.doc.descendants((node, pos) => {
//             if (node.isText) {
//               const text = node.text || '';
//               let match;

//               while ((match = regex.exec(text)) !== null) {
//                 const variable = match[1];
//                 extractedVariables.add(variable);

//                 const from = pos + match.index;
//                 const to = from + match[0].length;

//                 if (!newState.doc.rangeHasMark(from, to, newState.schema.marks.coloredBrackets)) {
//                   tr = tr.addMark(
//                     from,
//                     to,
//                     newState.schema.marks.coloredBrackets.create({ name: variable })
//                   );
//                   modified = true;
//                 }
//               }
//             }
//           });

//           extractedVariables.forEach(variable => {
//             if (!variables.includes(variable+'')) {
//               variables = addMentionList(variable);
//             }
//           });

//           return modified ? tr : null;
//         },
//       }),
//     ];
//   },
// });




import { Mark } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { getMentionList, addMentionList } from '../../editor';
import { marked } from 'marked';

let variables = getMentionList() 

export const deleteSuggestions = (data:any) => {
  variables = variables.filter((item) => item !== data);
  console.log(variables);
  return variables;
};

const findOrCreateVariable = (variableName:any) => {
  variableName=variableName.toLowerCase()
  let variable = variables.find(v => v === variableName);
  if (!variable) {

    variables = addMentionList(variableName); 
  }
  return variable ? variable :variableName;
};

export const ColoredBrackets = Mark.create({
  name: 'coloredBrackets',

  addAttributes() {
    return {
      class: {
        default: "mention"
      },

      'data-name': {
        default: null
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.mention[data-name]',
        getAttrs: (node) => ({
                'data-name': node.getAttribute('data-name'),
        }),
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', HTMLAttributes, 0];
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('coloredBrackets'),
        props: {
          handleKeyDown(view, event) {
            const { state } = view;

            // if (event.key === ']') {
            //   const { $from } = state.selection;
            //   const textBeforeCursor = $from.nodeBefore?.textContent || '';
            //   const openBracketIndex = textBeforeCursor.lastIndexOf('[');

            //   if (openBracketIndex > -1) {
            //     const variableName = textBeforeCursor.slice(openBracketIndex + 1, $from.pos);

            //     if (variableName.length > 0) {
            //       findOrCreateVariable(variableName);
            //     }
            //   }
            // }

            return false;
          },

          handlePaste(view, event) {
            const pastedText = event.clipboardData?.getData('text/plain');
            let content = marked(pastedText || "");
            const regex = /\[([^\]]+)\]/g;
            let match;

            while ((match = regex.exec(pastedText || "")) !== null) {
              const variableName = match[1];

              if (variableName.length > 0) {
                findOrCreateVariable(variableName);
              }
            }

            return false;
          },
        },

        appendTransaction(transactions, oldState, newState) {
          const regex = /\[([^\]]+)\]/g;
          let tr = newState.tr;
          let modified = false;

          newState.doc.descendants((node, pos) => {
            if (node.isText) {
              const text = node.text || "";
              let match;

              while ((match = regex.exec(text)) !== null) {
                const variableName = match[1];
                const variable = findOrCreateVariable(variableName);

                const from = pos + match.index;
                const to = from + match[0].length;

                // Only add the mark if it doesn't already have one
                if (!newState.doc.rangeHasMark(from, to, newState.schema.marks.coloredBrackets)) {
                  tr = tr.addMark(
                    from,
                    to,
                    newState.schema.marks.coloredBrackets.create({

                      'data-name': variable
                    })
                  );
                  modified = true;
                }
              }
            }
          });

          return modified ? tr : null;
        },
      }),
    ];
  },
});




// import { Mark } from '@tiptap/core';
// import { Plugin, PluginKey } from 'prosemirror-state';
// import { getMentionList, addMentionList } from '../../editor';

// let variables = getMentionList() 

// export const deleteSuggestions = (data:any) => {
//   variables = variables.filter((item) => item !== data);
//   console.log(variables);
//   return variables;
// };

// const findOrCreateVariable = (variableName:any) => {
//   let variable = variables.find(v => v === variableName);
//   if (!variable) {
//     variables = addMentionList(variableName); 
//   }
//   return variable ? variable : variableName;
// };

// export const ColoredBrackets = Mark.create({
//   name: 'coloredBrackets',

//   addAttributes() {
//     return {
//       class: {
//         default: "mention"
//       },
//       'data-name': {
//         default: null
//       }
//     };
//   },

//   parseHTML() {
//     return [
//       {
//         tag: 'span.mention[data-name]',
//         getAttrs: (node) => ({
//           'data-name': node.getAttribute('data-name'),
//         }),
//       },
//     ];
//   },

//   renderHTML({ HTMLAttributes }) {
//     return ['span', HTMLAttributes, 0];
//   },

//   addProseMirrorPlugins() {
//     return [
//       new Plugin({
//         key: new PluginKey('coloredBrackets'),

//         appendTransaction(transactions, oldState, newState) {
//           let tr = newState.tr;
//           let modified = false;

//           // Check if this is a content update from replaceSpanContent
//           const isContentReplacement = transactions.some(transaction => 
//             transaction.getMeta('isSpanContentReplacement')
//           );

//           // If it's a content replacement, don't process brackets
//           if (isContentReplacement) {
//             return null;
//           }

//           const processTextNode = (node: any, pos: number) => {
//             if (!node.isText) return;

//             const text = node.text || "";
//             const regex = /\[([^\]]+)\]/g;
//             let match;

//             // Get existing marks for this node
//             const marks = node.marks.filter(
//               (mark: any) => mark.type.name === 'coloredBrackets'
//             );

//             // If node has existing coloredBrackets marks, preserve them
//             if (marks.length > 0) {
//               return;
//             }

//             // Remove any existing marks that aren't associated with valid brackets
//             const from = pos;
//             const to = pos + text.length;
//             tr = tr.removeMark(from, to, newState.schema.marks.coloredBrackets);

//             // Add marks for new bracket pairs
//             while ((match = regex.exec(text)) !== null) {
//               const variableName = match[1];
              
//               if (variableName.length > 0) {
//                 const variable = findOrCreateVariable(variableName);
//                 const startPos = pos + match.index;
//                 const endPos = startPos + match[0].length;

//                 tr = tr.addMark(
//                   startPos,
//                   endPos,
//                   newState.schema.marks.coloredBrackets.create({
//                     'data-name': variable
//                   })
//                 );
//                 modified = true;
//               }
//             }
//           };

//           newState.doc.descendants(processTextNode);

//           return modified ? tr : null;
//         }
//       }),
//     ];
//   },
// });

// // Updated replaceSpanContent function
// export function replaceSpanContent(editor: any, name: string, variableValues: any) {
//     const tr = editor.state.tr;
//     let modified = false;

//     editor.state.doc.descendants((node: any, pos: number) => {
//         if (node.marks && node.marks.length) {
//             const bracketMark = node.marks.find(
//                 (mark: any) => mark.type.name === 'coloredBrackets' && 
//                               mark.attrs['data-name'] === name
//             );

//             if (bracketMark) {
//                 const newValue = variableValues[name] || "";
//                 tr.replaceWith(
//                     pos,
//                     pos + node.nodeSize,
//                     editor.state.schema.text(newValue, [bracketMark])
//                 );
//                 modified = true;
//             }
//         }
//     });

//     if (modified) {
//         // Add metadata to identify this as a content replacement
//         tr.setMeta('isSpanContentReplacement', true);
//         editor.view.dispatch(tr);
//     }
// }