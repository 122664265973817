import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ApiError, InputItem, InputResponse, UpsertItemInputDto } from './documentInputTypes';
import { addValueInputTemplate, deleteInputTemplate, fetchDocumentTemplate, fetcInputnData } from '../../services/itemInputService';


// Async thunk
export const fetchItemInputData = createAsyncThunk<
  InputResponse,
  { baseId: number; payload: UpsertItemInputDto },
  { rejectValue: string }
>(
  'input/fetchInputData',
  async ({ baseId, payload }, { rejectWithValue }) => {
    try {
 

      const response = await fetcInputnData(baseId, payload ); // Add missing fields
      return response;
    } catch (err: unknown) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch input data');
    }
  }
);


export const fetchItemInputDetails = createAsyncThunk<
InputItem,
  { baseId: number; id: string },
  { rejectValue: string }
>(
  'input/fetchItemInputDetails',
  async ({ baseId, id }, { rejectWithValue }) => {
    try {
      const response = await fetchDocumentTemplate(baseId, id);
      return response;
    } catch (err: unknown) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch document template');
    }
  }
);


export const deleteItemInputDetails = createAsyncThunk<
InputItem,
  { baseId: number; inputId: string },
  { rejectValue: string }
>(
  'input/deleteItemInputDetails',
  async ({ baseId, inputId }, { rejectWithValue }) => {
    try {
      const response = await deleteInputTemplate(baseId, inputId);
      return response;
    } catch (err: unknown) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to delete document template');
    }
  }
);

export const addValueInputData = createAsyncThunk<
InputItem,
  { baseId: number; inputId: string; payload: UpsertItemInputDto },
  { rejectValue: string }
>(
  'input/addValueInputData',
  async ({ baseId, inputId, payload }, { rejectWithValue }) => {
    try {
      const response = await addValueInputTemplate(baseId, inputId, payload);
      return response;
    } catch (err: unknown) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to add value to input data');
    }
  }
);

// State interface
interface InputState {
  inputData: InputResponse | null;
  inputLoading: boolean;
  inputError: string | null;
  documentDetails: InputItem | null;
  documentLoading: boolean;
  documentError: string | null;
  deleteDocumentDetails: InputItem | null;  
  deleteDocumentLoading: boolean;
  deleteDocumentError: string | null;
  addValueDocumentDetails: InputItem | null;
  addValueDocumentLoading: boolean;
  addValueDocumentError: string | null;
  
}

const initialState: InputState = {
  inputData: null,
  inputLoading: false,
  inputError: null,
  documentDetails: null,
  documentLoading: false,
  documentError: null,
  deleteDocumentDetails: null,
  deleteDocumentLoading: false,
  deleteDocumentError: null,
  addValueDocumentDetails: null,
  addValueDocumentLoading: false,
  addValueDocumentError: null
};

// Slice
const inputDataSlice = createSlice({
  name: 'input',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handling fetchItemInputData
      .addCase(fetchItemInputData.pending, (state) => {
        state.inputLoading = true;
        state.inputError = null;
      })
      .addCase(fetchItemInputData.fulfilled, (state, action: PayloadAction<InputResponse>) => {
        state.inputLoading = false;
        state.inputData = action.payload;
        state.inputError = null;
      })
      .addCase(fetchItemInputData.rejected, (state, action) => {
        state.inputLoading = false;
        state.inputError = action.payload as string;
      })

      // Handling fetchItemInputDetails
      .addCase(fetchItemInputDetails.pending, (state) => {
        state.documentLoading = true;
        state.documentError = null;
      })
      .addCase(fetchItemInputDetails.fulfilled, (state, action: PayloadAction<InputItem>) => {
        state.documentLoading = false;
        state.documentDetails = action.payload;
        state.documentError = null;
      })
      .addCase(fetchItemInputDetails.rejected, (state, action) => {
        state.documentLoading = false;
        state.documentError = action.payload as string;
      })
      // Handling deleteItemInputDetails
      .addCase(deleteItemInputDetails.pending, (state) => {
        state.deleteDocumentLoading = true;
        state.deleteDocumentError = null;
      })
      .addCase(deleteItemInputDetails.fulfilled, (state, action: PayloadAction<InputItem>) => {
        state.deleteDocumentLoading = false;
        state.deleteDocumentDetails = action.payload;
        state.deleteDocumentError = null;
      })
      .addCase(deleteItemInputDetails.rejected, (state, action) => {
        state.deleteDocumentLoading = false;
        state.deleteDocumentError = action.payload as string;
      })
      // Handling addValueInputData
      .addCase(addValueInputData.pending, (state) => {
        state.addValueDocumentLoading = true;
        state.addValueDocumentError = null;
      })
      .addCase(addValueInputData.fulfilled, (state, action: PayloadAction<InputItem>) => {
        state.addValueDocumentLoading = false;
        state.addValueDocumentDetails = action.payload;
        state.addValueDocumentError = null;
      })
      .addCase(addValueInputData.rejected, (state, action) => {
        state.addValueDocumentLoading = false;
        state.addValueDocumentError = action.payload as string;
      });
  },
});

// Export
export default inputDataSlice.reducer;
