

import React, {  useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { UserDetails } from '../../../auth/authTypes';
import { useAppDispatch } from '../../../../app/hooks';
import { fetchTeamsById } from '../../../teams/teamsSlice';
import { ExclamationIcon } from '../../../../components/icons';

type ChannelProps = {
  userDetails: UserDetails | null;
}

const TeamMemberDetails: React.FC<ChannelProps> = React.memo(({ }) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [boardsFetched, setBoardsFetched] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const { userId } = useParams<{ userId: any }>();
  const [TeamsFetched, setTeamsFetched] = useState<any | null>(null);
  const baseIdString = localStorage.getItem('UserBaseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;


  // fetch team by id

  useEffect(() => {
    const fetchTeams = async () => {
      setDataLoading(true); // Start loading
      try {
        const baseResponse = await dispatch(fetchTeamsById({ userId, id: baseId }));
        if (fetchTeamsById.fulfilled.match(baseResponse)) {
          setTeamsFetched(baseResponse.payload.data);
        } else {
          setTeamsFetched([]);
          console.error("Failed to fetch teams:", baseResponse.payload);
        }
      } catch (error) {
        console.error("Error fetching teams:", error);
        setTeamsFetched([]);
      } finally {
        setDataLoading(false); // Stop loading
      }
    };

    if (userId) {
      fetchTeams();
    }
  }, [userId, dispatch, baseId]);


  return (
    <main className='h-full flex'>
    <div className='w-full max-w-[788px] flex gap-x-[2rem] pb-[70px]' style={{ margin: '0 auto' }}>
      <div className='w-full flex justify-center'>
        {dataLoading ? (
          <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 191px)' }}>
            <div className="simple-spinner-loader">
              <span></span>
            </div>
          </div>
        ) : !TeamsFetched || TeamsFetched.length === 0 ? (
          <div className='w-full mt-5 flex h-[300px] justify-center items-center flex-col gap-y-3'>
           <ExclamationIcon />
          <p className='text-[#0000007a] text-[13px]'>No results found</p>
        </div>
        ) : (
          <div className='w-full'>
            <div className='flex flex-col gap-y-[6px]'>
              <div className='flex items-center gap-x-2'>
                <span className='flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-[2px] border-none text-[0.625rem] font-medium text-white capitalize'
                  style={{ backgroundColor: TeamsFetched?.color }}>
                </span>
                <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>{TeamsFetched?.name}</h2>
              </div>
              <p className='text-sm leading-6 text-[#5c5c5e] font-medium'>
                {TeamsFetched?.description}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  </main>
  )
});

export default TeamMemberDetails