

// src/components/dashboard/Sidebar.tsx
import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate, Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Transition } from '@headlessui/react'
import { Dialog } from '@headlessui/react'
import CustomMenuDropdown from '../common/CustomMenuDropdown';
import { UserDetails } from '../../features/auth/authTypes';
import { useAppDispatch } from '../../app/hooks';
import { logoutUser } from '../../features/auth/authSlice';
import { Toaster, toast } from 'sonner';
import { SettingIcon, FlagIcon } from '../icons';


function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}


interface SidebarProps {
  userDetails: UserDetails;
}

const Sidebar: React.FC<SidebarProps> = ({ userDetails }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation();
  const [updatedPathname, setUpdatedPathname] = useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [userId, setUserId] = useState<any>(null);
  const userBaseIdMain = userDetails?.id || userDetails || userId?.current_base
  const [userData, setUserData] = useState<any>(null);
  const [userBaseData, setUserBaseData] = useState<any>(null)

  useEffect(() => {
    if (userDetails && userBaseIdMain) {
      let newPathname = location.pathname;

      if (newPathname === `/${userBaseIdMain}/dashboard`) {
        newPathname = "dashboard";  // Change this line
      } else if (newPathname.startsWith(`/${userBaseIdMain}/`)) {
        newPathname = newPathname.replace(`/${userBaseIdMain}/`, "");
      }

      setUpdatedPathname(newPathname);
    } else {
      setUpdatedPathname(location.pathname);
    }
  }, [userDetails, location.pathname]);

  useEffect(() => {
    const userBaseDetailsString = localStorage.getItem('baseDetails');
    if (userBaseDetailsString) {
      try {
        const userBaseDetails = JSON.parse(userBaseDetailsString); // Declare the variable here
        setUserBaseData(userBaseDetails?.data);
      } catch (e) {
        console.error("Error parsing userDetails:", e);
      }
    }
  }, []);


  // user details
  useEffect(() => {
    const userDetailsString = localStorage.getItem('userDetails');
    if (userDetailsString) {
      try {
        const userDetailsData = JSON.parse(userDetailsString);
        setUserData(userDetailsData);
      } catch (e) {
        console.error("Error parsing userDetails:", e);
      }
    }
  }, []);

const data = []

  const navigation = [
    ...(userData?.role === 'admin'
      ? [{
        name: 'Detail',
        to: `/${userBaseIdMain}/settings/details`,
        // icon: IdentificationIcon,
        current: location.pathname === `/${userBaseIdMain}/settings/details`
      }]
      : []),
    {
      name: 'Member',
      to: `/${userBaseIdMain}/settings/member`,
      // icon: UsersIcon,
      current: location.pathname === `/${userBaseIdMain}/settings/member`
    },
    {
      name: 'Channel',
      to: `/${userBaseIdMain}/settings/channel`,
      // icon: Bars3Icon,
      current: location.pathname === `/${userBaseIdMain}/settings/channel`
    },
    {
      name: 'Tags',
      to: `/${userBaseIdMain}/settings/tags`,
      // icon: XMarkIcon,
      current: location.pathname === `/${userBaseIdMain}/settings/tags`
    },
    {
      name: 'Pipeline',
      to: `/${userBaseIdMain}/settings/pipeline`,
      // icon: XMarkIcon,
      current: location.pathname === `/${userBaseIdMain}/settings/pipeline`
    },
    {
      name: 'Questionnaire',
      to: `/${userBaseIdMain}/settings/questionnaire`,
      // icon: XMarkIcon,
      current: location.pathname === `/${userBaseIdMain}/settings/questionnaire`
    }
    
  ];

  
  // const navigation = [
  //     ...(userData?.role === 'admin'
  //         ? [{
  //             name: 'Detail',
  //             to: `/${userBaseIdMain}/settings/details`,
  //             // icon: IdentificationIcon,
  //             current: location.pathname === `/${userBaseIdMain}/settings/details`
  //         }]
  //         : []),
  //     ...(userData?.role === 'admin'
  //         ? [{
  //             name: 'Member',
  //             to: `/${userBaseIdMain}/settings/member`,
  //             // icon: UsersIcon,
  //             current: location.pathname === `/${userBaseIdMain}/settings/member`
  //         }]
  //         : []),
  //     ...(userData?.role === 'admin'
  //         ? [{
  //             name: 'Teams',
  //             to: `/${userBaseIdMain}/settings/teams`,
  //             // icon: Bars3Icon,
  //             current: location.pathname === `/${userBaseIdMain}/settings/teams`
  //         }]
  //         : []),
  //     ...(userData?.role === 'admin'
  //         ? [{
  //             name: 'Status',
  //             to: `/${userBaseIdMain}/settings/status`,
  //             // icon: XMarkIcon,
  //             current: location.pathname === `/${userBaseIdMain}/settings/status`
  //         }]
  //         : [])
  // ];


  useEffect(() => {
    const storedUserDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
    setUserId(storedUserDetails);
  }, []);

  const navigationProfile = [
    {
      name: 'Profile',
      to: `/user/${userId?.id}/profile`,
      current: location.pathname === `/user/${userId?.id}/profile`
    },
    {
      name: 'Security',
      to: `/user/${userId?.id}/security`,
      // icon: UsersIcon,
      current: location.pathname === `/user/${userId?.id}/security`
    }
  ];

  const isPathActive = (path: string) => {
    const currentPath = location.pathname;
    return currentPath === path || currentPath.startsWith(`${path}/`);
  };



  return (
    <div>
      {/* mobile sidebar */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-[82%] top-[-14px] flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>

                    </button>
                  </div>
                </Transition.Child>
                <div className="flex grow flex-col gap-y-4 overflow-y-auto bg-light-pink px-6 pb-4 py-4">
                  <div className='flex gap-[0.5rem] items-center h-[32px] px-[0.25rem]'>
                    <button
                      onClick={() => navigate(`/dashboard/dashboard/${userBaseIdMain}`)}
                      type='button'
                      className="bg-light-pink  hover:bg-gray-100 text-black-100
             focus-visible:outline-light-pink [&>span]:w-full  [&>span]:flex px-0  [&>span]:items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#0000007a"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-left w-[1rem] h-[1rem] text-[#0000007a]"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M15 6l-6 6l6 6" />
                      </svg>


                    </button>
                    <span className='leading-[1.2] font-semibold 
                whitespace-nowrap text-[0.8125rem]'> Settings   </span>
                  </div>
                  <nav className="flex flex-1 flex-col">


                    <ul role="list" className="-mx-2 space-y-1">
                      {navigation.map((item) => (
                        <li key={item.name}>
                          <a
                            href={item.to}
                            className={classNames(
                              item.current
                                ? ' text-black-100 bg-[#00000014] hover:bg-[rgb(0 0 0 / 6%)] cursor-pointer'
                                : 'text-black-100 hover:text-black-100 hover:bg-gray-100 cursor-pointer',
                              'group flex gap-x-[10px] p-0 text-[13px]  m-0 min-h-[28px] w-full cursor-pointer leading-5 items-center rounded-[0.375rem] font-medium text-wedges-gray-900 focus:outline-none px-2'
                            )}
                          >
                            {/* <item.icon
                                                            aria-hidden="true"
                                                            className={classNames(
                                                                item.current ? 'text-[#0000007a]' : 'text-red-100 group-hover:text-black-100',
                                                                'h-[1.1em] w-[1.1em] shrink-0'
                                                            )}
                                                        /> */}
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-[9] lg:flex lg:w-[225px] lg:flex-col">
        <div className="flex grow flex-col bg-white pt-[0] px-0 pb-4 sidebar-des border-r border-gray-100">
          <div className='flex gap-[0.5rem] items-center h-[48px] px-[14px]'>
            <div className='flex items-center gap-[0.5rem] h-[23px]'>
              <button
                onClick={() => navigate(`/${userBaseIdMain}/dashboard`)}
                type='button'
                className="bg-transparent  hover:bg-transparent text-black-100
             focus-visible:outline-light-pink [&>span]:w-full  [&>span]:px-0 px-0  [&>span]:items-center"
              >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="#0000007a"
                  role="img" focusable="false" aria-hidden="true" >
                  <path d="M10.7803 4.78033C11.0732 4.48744 11.0732 4.01256 10.7803 3.71967C10.4874 3.42678 10.0126 3.42678 9.71967 3.71967L5.71967 7.71967C5.42933 8.01001 5.42643 8.47986 5.71318 8.77376L9.61581 12.7738C9.90508 13.0702 10.3799 13.0761 10.6764 12.7868C10.9729 12.4976 10.9787 12.0227 10.6895 11.7262L7.30417 8.25649L10.7803 4.78033Z"></path></svg>


              </button>
              <span className='leading-[1.4375rem] font-medium  text-[0.9375rem] text-[#1a1a1a]'> Settings   </span>
            </div>

          </div>
          <nav className="flex overflow-y-auto h-[60%] flex-col px-[14px]">
            <ul role="list" className="flex flex-1 flex-col gap-y-2">
              {
                userData?.role === 'admin' &&
                <li>
                  <div className='flex items-center gap-2'>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="#0000007a"
                      role="img" focusable="false" aria-hidden="true">
                      <path d="M3.25 3C3.11193 3 2.99836 2.88751 3.01541 2.7505C3.13822 1.76368 3.97992 1 5 1H11C12.0201 1 12.8618 1.76368 12.9846 2.7505C13.0016 2.88751 12.8881 3 12.75 3L3.25 3Z">
                      </path>
                      <path fillRule="evenodd" clipRule="evenodd"
                        d="M3.5 4C3.22386 4 3 4.22386 3 4.5V14.5C3 14.7761 3.22386 15 3.5 15H5.5C5.77614 15 6 14.7761 6 14.5V12.5C6 12.2239 6.22386 12 6.5 12H9.5C9.77614 12 10 12.2239 10 12.5V14.5C10 
                          14.7761 10.2239 15 10.5 15H12.5C12.7761 15 13 14.7761 13 14.5V4.5C13 4.22386 12.7761 4 12.5 4H3.5ZM11 8C10.4477 8 10 8.44772
                           10 9V9.6C10 9.82091 10.1791 10 10.4 10H11.6C11.8209 10 12 9.82091 12 9.6V9C12 8.44772 11.5523 8 11 8ZM10 6C10 5.44772
                            10.4477 5 11 5C11.5523 5 12 5.44772 12 6V6.6C12 6.82091 11.8209 7 11.6 7H10.4C10.1791 7 10 6.82091 10 6.6V6ZM8 5C7.44772 
                            5 7 5.44772 7 6V6.6C7 6.82091 7.17909 7 7.4 7H8.6C8.82091 7 9 6.82091 9 6.6V6C9 5.44772 8.55228 5 8 5ZM7 9C7 8.44772 7.44772
                             8 8 8C8.55228 8 9 8.44772 9 9V9.6C9 9.82091 8.82091 10 8.6 10H7.4C7.17909 10 7 9.82091 7 9.6V9ZM5 8C4.44772 8 4 8.44772 4
                              9V9.6C4 9.82091 4.17909 10 4.4 10H5.6C5.82091 10 6 9.82091 6 9.6V9C6 8.44772 5.55228 8 5 8ZM4 6C4 5.44772 4.44772 5 5
                               5C5.55228 5 6 5.44772 6 6V6.6C6 6.82091 5.82091 7 5.6 7H4.4C4.17909 7 4 6.82091 4 6.6V6Z"></path>
                    </svg><span className='text-[#575859] font-medium text-[0.8125rem]'>Workspace</span></div>
                </li>
              }

              {
                userData?.role === 'admin' &&
                <li>
                  <ul role="list" className=" space-y-1">
                    {navigation.map((item) => {
                      // const isActive = location.pathname === item.to; // Check if the current path matches the item's path
                      const isActive = isPathActive(item.to);

                      return (
                        <li key={item.name} className='h-[28px] mb-[2px] py-[2px] sidebar-nav pl-[18px]'>
                          <Link
                            to={item.to}
                            className={classNames(
                              isActive
                                ? ' text-black-100 bg-[#00000014] hover:bg-[rgb(0 0 0 / 6%)] capitalize'
                                : 'text-black-100 hover:text-black-100 hover:bg-gray-10 capitalize',
                              'group flex gap-x-[10px] p-0 text-[0.8125rem]  m-0 min-h-[28px] capitalize w-full cursor-pointer leading-5 items-center rounded-[4px] font-medium text-wedges-gray-900 focus:outline-none pr-[2px] pl-[6px]')}
                          >
                            {/* <item.icon
                                                        aria-hidden="true"
                                                        className={classNames(
                                                            isActive ? 'text-[#0000007a]' : 'text-black-100 group-hover:text-black-100',
                                                            'h-[1.1em] w-[1.1em] shrink-0'
                                                        )}
                                                    /> */}
                            {item.name}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              }

              <li className='mt-[12px]'>
                <div className='flex items-center gap-2'>
                  <svg width="16" height="16" viewBox="0 0 16 16"
                    fill="#0000007a" role="img" focusable="false" aria-hidden="true" ><path d="M8 4a2 2 0 0 0-2 2v.5a2 2 0 0 0 4 0V6a2 2 0 0 0-2-2Z">
                    </path>
                    <path fillRule="evenodd" clipRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm-2.879-4.121-1.01 1.01a5.5 5.5 0 1 1 7.778 0l-1.01-1.01A3 3 0 0 0 8.757 10H7.243a3 3 0 0 0-2.122.879Z">
                    </path>
                  </svg>
                  <span className='text-[#575859] font-medium text-[0.8125rem]'>My Account</span>
                </div>
              </li>
              <li>
                <ul role="list" className=" space-y-1">
                  {navigationProfile.map((item) => {
                    // const isActive = location.pathname === item.to; // Check if the current path matches the item's path
                    const isActive = isPathActive(item.to);

                    return (
                      <li key={item.name} className='h-[28px] mb-[2px] py-[2px] sidebar-nav pl-[18px]'>
                        <Link
                          to={item.to}
                          className={classNames(
                            isActive
                              ? 'text-black-100 bg-[#00000014] hover:bg-[rgb(0 0 0 / 6%)]'
                              : 'text-black-100 hover:bg-gray-100',
                            'group flex gap-x-[10px] p-0 text-[13px] px-2 m-0 min-h-[28px] w-full cursor-pointer leading-5 items-center rounded-[0.375rem] font-medium'
                          )}
                        >
                          {/* <item.icon
                                                        aria-hidden="true"
                                                        className={classNames(
                                                            isActive ? 'text-[#0000007a]' : 'text-black-100 group-hover:text-black-100',
                                                            'h-[1.1em] w-[1.1em] shrink-0'
                                                        )}
                                                    /> */}
                          {item.name}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </li>
            </ul>
          </nav>
          <li className="list-none px-[12px] sidebar-nav mt-auto">
            {
              userData?.role === 'admin' &&
              <Link
                to={`/${userBaseIdMain}/settings/details`} // Adjust the path here to point to the correct settings route
                className='group flex gap-x-[10px] p-0 text-[13px] m-0 min-h-[32px] w-full cursor-pointer leading-5 items-center rounded-[0.375rem] font-medium text-wedges-gray-900 focus:outline-none px-2'
              >
                <SettingIcon
                  className="h-[1.1em] w-[1.1em] shrink-0 text-[#0000007a] group-hover:text-black-100"
                  aria-hidden="true"
                />
                Settings
              </Link>
            }

          </li>
          <li className="list-none px-[12px] sidebar-nav">
            <Link
              to="#"
              // onClick={haShare feedbackndleClickSetting}
              className='group flex gap-x-[10px] p-0 text-[13px] m-0 min-h-[28px] w-full cursor-pointer leading-5 items-center rounded-[0.375rem] font-medium text-wedges-gray-900 focus:outline-none px-2'
            >
              <FlagIcon className='h-[1.1em] w-[1.1em] shrink-0 text-[#0000007a] group-hover:text-black-100' />

              Share feedback
            </Link>
          </li>
        </div>
      </div>
    </div>

  );
};

export default Sidebar;