// @ts-nocheck
import { useState, Fragment, useEffect, useCallback } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { MenuItem, MenuItems, MenuButton } from '@headlessui/react'
import { Link, useParams } from 'react-router-dom'
import { Toaster, toast } from 'sonner';
import { createLocation, deleteLocation, fetchLocationListStatus, fetchTagsDataList, updateLocation } from '../../tags/tagsSlice'
import { createTeamsPayload } from '../../tags/tagsType'
import { useAppDispatch } from '../../../app/hooks'
import DropDown from '../../../components/common/DropDown'
import { CustomCheckIcon, ExclamationIcon, SearchIcon } from '../../../components/icons'


const colorList = [
  { name: "bg-blue-500" },
  { name: "bg-gray-500" },
  { name: "bg-blue-200" },
  { name: "bg-pink-500" },
  { name: "bg-indigo-500" },
  { name: "bg-yellow-500" },
  { name: "bg-green-500" },
  { name: "bg-cyan-500" },
  { name: "bg-red-600" },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const roleSearchList = [{ name: "active" }, { name: "deleted" },
{ name: "archived" }]

function Tags() {
  const [editInput, setEditInput] = useState<string | null>(null)
  const [labelName, setLabelName] = useState("")
  const [color, setColor] = useState("bg-gray-500")
  const [newGroup, setNewGroup] = useState(false)
  const [searchQuery, setSearchQuery] = useState("");
  const baseIdString = localStorage.getItem('UserBaseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const { userId } = useParams<{ userId: string }>();
  const [boardsFetched, setBoardsFetched] = useState<any[]>([]);
  const [dataLoading1, setDataLoading1] = useState(false);
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch();
  const [selectSearchRole, setSelectSearchRole] = useState("active")
  const [loading1, setLoading1] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };


  useEffect(() => {
    const fetchTeams = async () => {
      if (!baseId) return;
      setDataLoading1(true);
      try {
        const baseResponse = await dispatch(fetchTagsDataList({ baseId: Number(baseId) }));

        if (fetchTagsDataList.fulfilled.match(baseResponse)) {
          setBoardsFetched(baseResponse.payload.data);
          localStorage.setItem('baseTagsData', JSON.stringify(baseResponse.payload));
        } else {
          console.error("Error fetching pipeline details:", baseResponse.payload);
          setBoardsFetched([]);
        }
      } catch (error) {
        console.error("Error fetching pipeline details:", error);
        setBoardsFetched([]);
      } finally {
        setDataLoading1(false);
      }
    };

    fetchTeams();
  }, [baseId, dispatch, userId]);

  const handleEdit = async (itemId: string) => {
    try {
      const response = boardsFetched.find(team => team.id === itemId);
      if (response) {
        const { tag_name, tag_colour } = response;
        setLabelName(tag_name);
        setColor(tag_colour);
        setEditInput(itemId);
        setNewGroup(false);
      }
    } catch (error) {
      console.error('Error fetching item status for edit:', error);
    }
  };


  const handleUpdateSave = async (id: any) => {
    setLoading(true);
    const payload: createTeamsPayload = {
      base_id: baseId,
      tag_name: labelName,
      tag_colour: color,
    };
    try {
      if (baseId) {
        const response = await dispatch(updateLocation({
          payload,
          baseId: baseId,
          id: id
        }));
        if (response.meta.requestStatus === 'fulfilled') {
          toast.success('Updated Tags successfully!');
        } else if (response.meta.requestStatus === 'rejected') {
          toast.error('Tag with the same base and tag name already exists.');
        }

        setLabelName("")
        setColor("#6b7280")
      }
      setEditInput(null)
      setNewGroup(false);
      const baseResponse = await dispatch(fetchTagsDataList({ baseId: Number(baseId) }));

      if (baseResponse?.payload) {
        localStorage.setItem('baseTagsData', JSON.stringify(baseResponse.payload));
        window.dispatchEvent(new StorageEvent('storage', {
          key: 'baseTagsData',
          newValue: JSON.stringify(baseResponse.payload)
        }));
      }

    } catch (error) {
      console.error('Error creating item tag:', error);
      toast.error('Failed to create item tags');
    } finally {
      setLoading(false);
    }
  }


  const handleCancel = () => {
    setEditInput(null)
    setNewGroup(false)
  }

  const handleSave = async () => {
    setLoading(true);

    const payload: createTeamsPayload = {
      base_id: baseId,
      tag_name: labelName,
      tag_colour: color,
    };
    try {
      if (baseId) {
        const response = await dispatch(createLocation({ payload, userId: baseId }));
        if (response.meta.requestStatus === 'fulfilled') {
          toast.success('Create Tags successfully!');
        } else if (response.meta.requestStatus === 'rejected') {
          toast.error('Tag with the same base and tag name already exists.');
        }

        setLabelName("")
        setColor("#6b7280")
      }
      setEditInput(null)
      setNewGroup(false);
      const baseResponse = await dispatch(fetchTagsDataList({ baseId: Number(baseId) }));

      if (baseResponse?.payload) {
        localStorage.setItem('baseTagsData', JSON.stringify(baseResponse.payload));
        window.dispatchEvent(new StorageEvent('storage', {
          key: 'baseTagsData',
          newValue: JSON.stringify(baseResponse.payload)
        }));
      }

    } catch (error) {
      console.error('Error creating item tag:', error);
      toast.error('Failed to create item tags');
    } finally {
      setLoading(false);
    }
  };

  const handleNewGroup = () => {
    setNewGroup(true)
    setLabelName("")
    setColor("bg-gray-500")
  }

  // for delete item tags
  // const handleItemTagsDelete = async (itemId: number) => {
  //   if (!baseId) {
  //     toast.error('Base ID is not available');
  //     return;
  //   }

  //   try {
  //     const resultAction = await dispatch(deleteLocation({ baseId: parseInt(baseId), id: itemId }));

  //     if (deleteLocation.fulfilled.match(resultAction)) {
  //       toast.success('Item deleted successfully');
  //       const res = await dispatch(fetchTagsDataList({ baseId: parseInt(baseId) }));

  //       if (fetchTagsDataList.fulfilled.match(res)) {
  //         if (res.payload) {
  //           localStorage.setItem('baseTagsData', JSON.stringify(res.payload));
  //           window.dispatchEvent(
  //             new StorageEvent('storage', {
  //               key: 'baseTagsData',
  //               newValue: JSON.stringify(res.payload),
  //             })
  //           );
  //         }
  //       } else if (fetchTagsDataList.rejected.match(res)) {
  //         toast.error('Failed to refresh the pipeline stages list');
  //       }
  //     } else if (deleteLocation.rejected.match(resultAction)) {
  //       const error = resultAction.payload;
  //       toast.error(error || 'Failed to delete item');
  //     }
  //   } catch (error) {
  //     console.error('Error deleting item:', error);
  //     toast.error('An error occurred while deleting the item');
  //   }
  // };

  const handleDeleteBoard = useCallback(async (id: number, baseId: number) => {
    const payload: createTeamsPayload = {
      status: 'deleted',
      base_id: baseId
    };

    try {
      // Update existing team
      const response = await dispatch(updateLocation({
        payload,
        baseId: baseId,
        id: id
      }));

      if (response.meta.requestStatus === 'fulfilled') {
        toast.success('Status has been updated successfully');
        // Update the local storage and state
        const updatedBoards = boardsFetched.map(board =>
          board.id === id ? { ...board, status: 'deleted' } : board
        );

        localStorage.setItem('baseTagsData', JSON.stringify({ data: updatedBoards }));

        setBoardsFetched(updatedBoards);

        // setIsModalOpen(false);
        // setEditBoardData(null);
      } else {
        toast.error('Failed to update status');
      }
    } catch (error) {
      toast.error('An error occurred while updating the status');
      console.error(error);
    }
  }, [boardsFetched, dispatch]);

  const handleArchivedBoard = useCallback(async (id: number, baseId: number) => {
    const payload: createTeamsPayload = {
      status: 'archived',
      base_id: baseId
    };

    try {
      // Update existing team
      const response = await dispatch(updateLocation({
        payload,
        baseId: baseId,
        id: id
      }));

      if (response.meta.requestStatus === 'fulfilled') {
        toast.success('Status has been updated successfully');
        // Update the local storage and state
        const updatedBoards = boardsFetched.map(board =>
          board.id === id ? { ...board, status: 'archived' } : board
        );

        localStorage.setItem('baseTagsData', JSON.stringify({ data: updatedBoards }));

        setBoardsFetched(updatedBoards);

        // setIsModalOpen(false);
        // setEditBoardData(null);
      } else {
        toast.error('Failed to update status');
      }
    } catch (error) {
      toast.error('An error occurred while updating the status');
      console.error(error);
    }
  }, [boardsFetched, dispatch]);

  const handleSearchRoleChange = (role: string) => {
    setSelectSearchRole(role)
  }


  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'baseTagsData' && e.newValue) {
        const teamsDataArray = JSON.parse(e.newValue);
        setBoardsFetched(teamsDataArray.data || []);
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);


  const handleActive = async () => {
    setLoading1(true);
    try {
      const res = await dispatch(fetchLocationListStatus({ baseId, status: 'active' }));
      if (res.payload) {
        setBoardsFetched(res.payload.data);
        localStorage.setItem('baseTagsData', JSON.stringify(res.payload));
      } else {
        console.error('No payload received');
      }
    } catch (error) {
      console.error('Delete failed:', error);
      alert('Failed to delete location data. Please try again later.');
    } finally {
      setLoading1(false);
    }
  };

  const handleDelete = async () => {
    setLoading1(true);
    try {
      const res = await dispatch(fetchLocationListStatus({ baseId, status: 'deleted' }));
      if (res.payload) {
        setBoardsFetched(res.payload.data);
        localStorage.setItem('baseTagsData', JSON.stringify(res.payload));
      } else {
        console.error('No payload received');
      }
    } catch (error) {
      console.error('Delete failed:', error);
      alert('Failed to delete location data. Please try again later.');
    } finally {
      setLoading1(false);
    }
  };


  const handleSuspend = async () => {
    setLoading2(true)
    try {
      const res = await dispatch(fetchLocationListStatus({ baseId, status: 'archived' }));
      if (res.payload) {
        setBoardsFetched(res.payload.data)
        // Update local storage
        localStorage.setItem('baseTagsData', JSON.stringify(res.payload));
      } else {
        console.error('No payload received');
      }
    } catch (error) {
      console.error('Suspend failed:', error);
    } finally {
      setLoading2(false)
    }
  };


  return (
    <>
      <Toaster richColors />
      <main className='py-[34px] lg:py-[70px] px-0 lg:px-10 h-full flex'>
        <div className='px-[2px] sm:px-6 lg:px-8 w-full flex justify-center'>
          <div className='w-full max-w-[640px]'>
            <div className='flex flex-col gap-y-[6px]'>
              <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>Base Tags</h2>
              <p className='text-sm leading-6 text-[#5c5c5e] font-medium capitalize'>manage feedback tags</p>
            </div>
            <div className='bg-[#e0e0e0] w-full h-[1px] my-6'></div>
            <div className='mt-6 lg:mt-10 mb-8'>
              <p className=' text-[#5c5c5e] text-[13px] leading-[18px]'>
                Use labels and label groups to help organize and filter issues in your workspace.
                Labels created in this section are available for all teams to use. To create labels or label
                groups that only apply to certain teams, add them in the team-specific label settings.
              </p>
            </div>
            <div className=' mt-6 lg:mt-10 flex items-center  w-full gap-x-3 mb-8'>
              <div className='w-full lg:w-[40%]'>
                <div className="relative items-center">
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    name="search"
                    id="search"
                    placeholder='Search name and email'
                    className="flex w-full grow border px-4 py-2 pl-8 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
                  focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                  border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                  />
                  <div className="absolute inset-y-0 left-0 flex py-1.5 pr-1.5">

                    <SearchIcon />

                  </div>
                </div>
              </div>
              <div className='w-full lg:w-[20%]'>
                <div className='mt-[0]'>
                  <DropDown
                    onChangeValue={handleSearchRoleChange}
                    data={roleSearchList}
                    previousValue={selectSearchRole}
                    onDelete={handleDelete}
                    onArchived={handleSuspend}
                    onActive={handleActive}
                  />
                  {/* <DropDown onChangeValue={(value) => setSelectSearchRole(value)} data={roleSearchList} previousValue={selectSearchRole} /> */}

                </div>
              </div>
              <div className='w-full lg:w-[40%]'>
                <div className='flex items-center justify-end'>
                  <button
                    type='button'

                    onClick={handleNewGroup}
                    disabled={newGroup}
                    className='group px-2 py-1 inline-flex shrink-0 select-none items-center justify-center text-sm font-medium leading-6 transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8px rounded-lg dark:shadow:none border border-surface-200 shadow-wg-xs [--wg-border-width:1px] hover:bg-surface disabled:border-surface-50 dark:border-surface-100 py-4px text-surface-900 outline-primary disabled:text-surface-300'

                  >
                    Create Tags
                  </button>

                </div>
              </div>


            </div>


            {dataLoading1 ? (
              <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 449px)' }}>
                <div className="simple-spinner-loader">
                  <span></span>
                </div>
              </div>
            ) : (
              <div>
                <div className='flex flex-col gap-y-2'>
                  {newGroup && (
                    <div className='bg-[#edeef2] p-2 border border-solid border-[#e0e0e0] rounded mb-1'>

                      <div className='w-full'>
                        <form>
                          <div className='flex flex-row items-center gap-2 flex-1'>
                            <Menu as='div' className='relative flex items-center '>
                              <Menu.Button className=''>
                                <span className='sr-only'>Open user menu</span>
                                <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'>
                                  <div
                                    className={`border-0 bg-none text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm w-[9px] h-[9px] rounded-full border flex justify-center items-center ${color}`}
                                    style={{ backgroundColor: color }}
                                  >
                                  </div>
                                </div>

                              </Menu.Button>
                              <Transition
                                as={Fragment}
                                enter='transition ease-out duration-100'
                                enterFrom='transform opacity-0 scale-95'
                                enterTo='transform opacity-100 scale-100'
                                leave='transition ease-in duration-75'
                                leaveFrom='transform opacity-100 scale-100'
                                leaveTo='transform opacity-0 scale-95'>
                                <Menu.Items className='absolute flex flex-wrap left-0 top-8 z-10 mt-2.5 w-44 p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                                  {colorList.map((value: any, index) => {
                                    return (
                                      <div onClick={() => setColor(value.name)} key={index} className={`flex h-6 w-6 border ${value.name} rounded-full m-2 justify-center items-center`}>
                                        {(color === value.name) ? <CustomCheckIcon /> : null}
                                      </div>
                                    )
                                  })}
                                </Menu.Items>
                              </Transition>

                            </Menu>
                            <div className='w-full'>
                              <input
                                name='text'
                                type='text'
                                required
                                value={labelName}
                                onChange={(e) => setLabelName(e.target.value)}
                                placeholder='Enter Purpose'
                                // req
                                className="flex w-full grow border px-4 py-2  text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
                  focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                  border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                              />
                            </div>
                            <span className='bg-[#e3e3e3] w-[1px] h-[30px]'></span>
                            <div className='flex items-center gap-x-2 flex-1'>
                              <button
                                className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 
                                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                type='button'

                                onClick={handleCancel}

                              >
                                Cancel
                              </button>
                              <button
                                onClick={handleSave}
                                type='button'
                                className={` shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 
                                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
                           flex items-center gap-[8px] ${!labelName && 'opacity-50 cursor-not-allowed'
                                  }`}

                                disabled={!labelName}


                              >
                                Save
                                {loading && (
                                  <div className="simple-spinner">
                                    <span></span>
                                  </div>
                                )}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                  <>
                    {boardsFetched.length === 0 ? (
                      <div className='w-full mt-5 flex h-[300px] justify-center items-center flex-col gap-y-3'>
                        <ExclamationIcon />
                        <p className='text-[#0000007a] text-[13px]'>No results found</p>
                      </div>
                    ) : (
                      boardsFetched?.filter(item => item.tag_name.toLowerCase().includes(searchQuery.toLowerCase()))
                        .map((item: any) => (
                          <div className='p-2 rounded-[3px] bg-white border border-solid border-[#e3e3e3] mb-1 relative pl-4 feedback-tags-hover last-of-type:mb-[70px]'>
                            {editInput === item.id ? (
                              <div className='w-full'>
                                <form>
                                  <div className='flex flex-row items-center gap-2 flex-1'>

                                    <Menu as='div' className='relative flex items-center '>
                                      <Menu.Button className=''>
                                        <span className='sr-only'>Open user menu</span>
                                        <div className='px-2 flex items-center justify-center cursor-pointer h-[32px] w-[32px] bg-white border border-solid border-[#d8d8d86] rounded-md text-sm'>
                                          <div
                                            className={`border-0 bg-none text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm w-[9px] h-[9px] rounded-full border flex justify-center items-center ${color}`}
                                            style={{ backgroundColor: color }}
                                          >
                                          </div>
                                        </div>

                                      </Menu.Button>
                                      <Transition
                                        as={Fragment}
                                        enter='transition ease-out duration-100'
                                        enterFrom='transform opacity-0 scale-95'
                                        enterTo='transform opacity-100 scale-100'
                                        leave='transition ease-in duration-75'
                                        leaveFrom='transform opacity-100 scale-100'
                                        leaveTo='transform opacity-0 scale-95'>
                                        <Menu.Items className='absolute flex flex-wrap left-0 top-8 z-10 mt-2.5 w-44 p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                                          {colorList.map((value: any, index) => {
                                            return (
                                              <div onClick={() => setColor(value.name)} key={index} className={`flex h-6 w-6 border ${value.name} rounded-full m-2 justify-center items-center`}>
                                                {(color === value.name) ? <CustomCheckIcon /> : null}
                                              </div>
                                            )
                                          })}
                                        </Menu.Items>
                                      </Transition>

                                    </Menu>
                                    <div className='w-full'>
                                      <input
                                        name='text'
                                        type='text'
                                        required
                                        value={labelName}
                                        onChange={(e) => setLabelName(e.target.value)}
                                        placeholder='Enter Purpose'
                                        className="flex w-full grow border px-4 py-2 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
                                focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                                border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                                      />
                                    </div>
                                    <span className='bg-[#e3e3e3] w-[1px] h-[30px]'></span>
                                    <div className='flex items-center gap-x-2 flex-1'>
                                      <button
                                        type='button'
                                        onClick={handleCancel}
                                        className="group px-2 py-1 inline-flex shrink-0 select-none items-center justify-center text-sm font-medium leading-6 transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8px rounded-lg dark:shadow:none border border-surface-200 shadow-wg-xs [--wg-border-width:1px] hover:bg-surface disabled:border-surface-50 dark:border-surface-100 py-4px text-surface-900 outline-primary disabled:text-surface-300"
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        onClick={() => handleUpdateSave(item.id)}
                                        type='button'
                                        className={`shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 
                          focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
                             flex items-center gap-[8px] ${!labelName && 'opacity-50 cursor-not-allowed'
                                          }`}
                                        disabled={!labelName}


                                      >
                                        Save
                                        {loading && (
                                          <div className="simple-spinner">
                                            <span></span>
                                          </div>
                                        )}
                                      </button>
                                    </div>

                                  </div>
                                </form>
                              </div>
                            ) : (
                              <>
                                <div className='flex flex-row items-center justify-between flex-grow shrink-0'>
                                  <div className='flex items-center gap-x-[16px]'>
                                    <div
                                      className={` shrink-0 w-[9px] h-[9px] rounded-full ${item?.tag_colour}`}
                                      style={{ backgroundColor: item?.tag_colour }}

                                    ></div>
                                    <span className='flex-shrink whitespace-nowrap overflow-hidden text-ellipsis font-medium text-[#1b1b1b] text-sm leading-5 capitalize'>{item.tag_name}</span>
                                  </div>
                                  <div className='mr-[0px] flex  flex-row items-center flex-grow-0'>
                                    <div className='flex flex-row gap-[8px] items-center'>
                                      <div data-title="Edit" className='table-tooltip h-full flex items-center'>
                                        <button className='feedback-edit-btn-hover'>
                                          <svg
                                            onClick={() => handleEdit(item.id)}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="#0000007a"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="icon icon-tabler icons-tabler-outline icon-tabler-pencil w-[1rem] h-[1rem] text-[#0000007a]"
                                          >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" />
                                            <path d="M13.5 6.5l4 4" />
                                          </svg>

                                        </button>
                                      </div>
                                      <button className='feedback-more-btn-hover'>
                                        <Menu as="div" className="relative inline-block text-left">
                                          <div>
                                            <MenuButton className="rounded-md flex items-center justify-center">
                                              <span className="sr-only">Open options</span>
                                              <svg stroke="currentColor" fill="#a0a0a2" className='text-[#a0a0a2]' strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle>
                                              </svg>
                                            </MenuButton>
                                          </div>

                                          <Transition
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                          >
                                            {/* <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">

                                  <MenuItem>
                                    {({ focus }) => (
                                      <Link
                                        to="#"
                                        className={classNames(
                                          focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                        onClick={() => handleItemTagsDelete(item.id)}
                                      >
                                        Delete
                                      </Link>
                                    )}
                                  </MenuItem>
                                </div>
                              </MenuItems> */}
                                            <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                              <div className="py-1">
                                                <MenuItem>
                                                  {({ focus }) => (
                                                    <Link
                                                      to="#"
                                                      className={classNames(
                                                        focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                      )}
                                                      onClick={() => handleDeleteBoard(item.id, item.base_id)}
                                                    >
                                                      Delete
                                                    </Link>
                                                  )}
                                                </MenuItem>
                                                <MenuItem>
                                                  {({ focus }) => (
                                                    <Link
                                                      to="#"
                                                      onClick={() => handleArchivedBoard(item.id, item.base_id)}
                                                      className={classNames(
                                                        focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                      )}
                                                    >
                                                      Archived
                                                    </Link>
                                                  )}
                                                </MenuItem>

                                              </div>
                                            </MenuItems>
                                          </Transition>
                                        </Menu>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                            }
                          </div>
                        ))
                    )}
                  </>
                </div>

              </div>
            )}

          </div>
        </div>
      </main>

    </>
  )
}

export default Tags