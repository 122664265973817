import React from 'react';

type BadgeProps = {
  status: string;
};

const Badge: React.FC<BadgeProps> = ({ status }) => {
  let badgeColorClass = '';

  switch (status) {
    case 'active':
      badgeColorClass = 'bg-green-50 text-green-700 ring-green-600/20 capitalize';
      break;
    case 'pending':
      badgeColorClass = 'bg-yellow-50 text-yellow-800 ring-yellow-600/20 capitalize';
      break;
    case 'inactive':
      badgeColorClass = 'bg-red-50 text-red-700 ring-red-600/10 capitalize';
      break;
    case 'public':
      badgeColorClass = 'bg-blue-50 text-blue-700 ring-blue-600/10 capitalize';
      break;
    case 'privacy':
      badgeColorClass = 'bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize';
      break;
      case 'deleted':
        badgeColorClass = 'inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 capitalize';
        break;

    default:
      badgeColorClass = 'bg-gray-50 text-gray-600 ring-gray-500/10 capitalize';
  }

  return (
    <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${badgeColorClass} ring-1 ring-inset mx-1`}>
      {status}
    </span>
  );
};

export default Badge;
