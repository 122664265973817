// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { format, formatDistanceToNow } from 'date-fns';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { fetchDocumentActivityItem } from '../../features/docuementItemActivity/docuementItemActivitySlice';
import { useParams } from 'react-router-dom';

interface DocumentDetailsProps {
    isAsideOpen2: boolean;
    itemActivity: any;
    itemActivityLoading: boolean;
}

const DocumentDetailsActivity: React.FC<DocumentDetailsProps> = React.memo(({ isAsideOpen2, itemActivity, itemActivityLoading }) => {
    const dispatch = useAppDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [processedActivities, setProcessedActivities] = useState<any[]>([]); // Local state for processed activities
    const pagination = useAppSelector((state: RootState) => state.documentActivityItem.pagination);
    const { baseId } = useParams<{ baseId: string }>();
    const { id } = useParams<{ id: string }>();

    const localStorageData = JSON.parse(localStorage.getItem('baseMembersDetails') || '{}');
    const baseMembers = localStorageData?.data?.baseMembers || [];

    // Modified function to remove 'about' from timeago
    const formatTimeAgo = (date: Date): string => {
        const timeAgo = formatDistanceToNow(date, { addSuffix: true });
        return timeAgo.replace(/^about\s/, '').replace(/^less than\s/, '');
    };


    useEffect(() => {
        if (itemActivity?.itemActivity) {
            const activities = itemActivity.itemActivity;

            const processedData = activities.map((activity) => {
                const internalMemberId = activity.internal_member_id;
                const externalMemberId = activity.external_member_id;
                const createdAt = new Date(activity.created_at);

                // Determine if it's an external activity
                const isExternal = !internalMemberId && externalMemberId;

                // Get the name based on internal or external member ID
                let name = '';
                let contact = null;

                if (internalMemberId) {
                    // Internal member
                    name = getMemberName(internalMemberId);
                } else if (externalMemberId) {
                    // External member
                    contact = getContactDetails(externalMemberId);
                    name = contact?.contact_first_name || contact?.contact_last_name || 'Unknown User';
                }

                const lastName = contact?.contact_last_name || '';
                const email = contact?.contact_email || '';
                const firstName = contact?.contact_first_name || '';

                // Construct the name with last name and email
                const displayName = isExternal
                    ? `${firstName} ${lastName} [${email}]`
                    : name;

                const message = activity.activity_message;
                const timeAgo = formatTimeAgo(createdAt);
                const tooltipDate = format(createdAt, 'd MMMM yyyy, h:mm a');

                return {
                    id: internalMemberId || externalMemberId,
                    name: displayName,
                    message,
                    timeAgo,
                    tooltipDate,
                    isExternal,
                    email
                };
            });

            // Append new activities to the existing state
            setProcessedActivities(processedData);
        }
    }, [itemActivity]);


    // Utility function to find name by ID
    const getMemberName = (internalMemberId) => {
        const member = baseMembers.find((m) => m.id === internalMemberId);
        return member ? member.name : 'Unknown User';
    };

    // Utility function to get contact details by external_member_id
    const getContactDetails = (externalMemberId) => {
        const activities = itemActivity.itemActivity;
        const activity = activities.find((activity) => activity.external_member_id === externalMemberId);
        return activity?.contact ? activity.contact : null;
    };

    const handleLoadMore = async () => {
        // Ensure we use hasNextPage from the Redux store
        if (!pagination.hasNextPage) {
            return; // Exit if there's no more data
        }

        // Increment page and dispatch the action to fetch the next page
        const nextPage = pagination.currentPage + 1;

        // Dispatch the action to fetch the next page of data
        const res = await dispatch(fetchDocumentActivityItem({
            baseId,
            id,
            page: nextPage,
            limit: 25,
        }));

        // Check if the response has the required data and is an array
        const itemActivity = res?.payload?.itemActivity;

        if (Array.isArray(itemActivity)) {
            const processedData = itemActivity.map((activity: any) => {
                const internalMemberId = activity.internal_member_id;
                const externalMemberId = activity.external_member_id;
                const createdAt = new Date(activity.created_at);


                // Determine if it's an external activity
                const isExternal = !internalMemberId && externalMemberId;

                let name = '';
                let contact = null;
                let profilePicture = null;

                if (internalMemberId) {
                    // Internal member
                    name = getMemberName(internalMemberId);
                } else if (externalMemberId) {
                    // External member
                    contact = getContactDetails(externalMemberId);
                    name = contact?.contact_first_name || contact?.contact_last_name || 'Unknown User';
                }

                const lastName = contact?.contact_last_name || '';
                const email = contact?.contact_email || '';
                const firstName = contact?.contact_first_name || '';

                // Construct the name with last name and email
                const displayName = isExternal
                    ? `${firstName} ${lastName} [${email}]`
                    : name;

                const message = activity.activity_message;
                const timeAgo = formatTimeAgo(createdAt);
                const tooltipDate = format(createdAt, 'd MMMM yyyy, h:mm a');

                return {
                    id: internalMemberId || externalMemberId,
                    name: displayName,
                    profilePicture,
                    message,
                    timeAgo,
                    tooltipDate,
                    isExternal,
                    email
                };
            });

            setCurrentPage(nextPage);
            // Update the processed activities state by appending new data
            setProcessedActivities((prevActivities) => {
                return [...prevActivities, ...processedData];
            });

        } else {
            console.error("itemActivity is not an array:", itemActivity);
        }
    };
    

    return (
        <div
            className={`main-feedback-side flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 
                border-solid absolute  lg:fixed top-[60px] max-w-[80%] view-feedback-right-side border-r-0 ' `}
            style={{
                width: isAsideOpen2 ? '350px' : '350px',
                transform: isAsideOpen2 ? 'none' : 'none',
                right: isAsideOpen2 ? '51px' : '0px',
                marginRight: isAsideOpen2 ? '0px' : '-350px',
                height: isAsideOpen2 ? 'calc(100vh - +60px)' : '',
                ...(window.innerWidth <= 768 && {
                    width: isAsideOpen2 ? '300px' : '300px',
                    marginRight: isAsideOpen2 ? '0' : '-300px',
                }),
            }}
        >

            <div className='h-full'>
                <div className='h-auto flex flex-col justify-stretch min-h-[3rem] 
                                   border-b-0 border-l-0 border-solid border-light-gray-200 p-[1rem] pt-[0.9rem] items-start gap-4'>
                    <div className=" flex items-start justify-center w-full">
                        <div className="mx-auto w-full">
                            <p className="text-center text-wedges-gray text-sm" />
                            <div className="flex items-center max-w-full w-fit mb-5 font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] ml-[-4px] py-[5px] gap-2">
                                <span className="text-[#52555a] capitalize"> Activity</span>
                            </div>
                            {itemActivityLoading ? (
                                <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 120px)' }}>
                                    <div className="simple-spinner-loader">
                                        <span className='!w-6 !h-6'></span>
                                    </div>
                                </div>
                            ) : (
                                <div className="mb-2 mx-[-6px]">
                                    <ul role="list" className="space-y-6 mt-[0.9rem]">
                                        {processedActivities.map((activity, index) => {
                                            // Find the last index of the activity in the array
                                            const isLastItem = index === processedActivities.length - 1;

                                            return (
                                                <li key={index} className="relative flex gap-x-2 flex-col">
                                                    <div className="flex gap-x-2">
                                                        {/* Render divider line for all items except the last one */}
                                                        {!isLastItem && (
                                                            <div className="-bottom-6 absolute left-0 top-0 flex w-6 justify-center">
                                                                <div className="w-px bg-gray-200" />
                                                            </div>
                                                        )}

                                                        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                                            <div className="h-1 w-1 rounded-full bg-transparent ring-2 ring-[#7d7f83]" />
                                                        </div>

                                                        <span
                                                            className={`font-medium text-dark-black-100 mr-1 items-start text-[13px] relative text-ellipsis 
                        ${activity.isExternal ? 'inline-block' : 'flex'}`}
                                                        >
                                                            <span className='capitalize block'>
                                                                {activity.name}
                                                            </span>
                                                            <div className=''>
                                                                <div
                                                                    className={`text-dark-black-200 inline font-normal text-[13px]
                                ${activity.isExternal ? 'ml-0' : 'ml-1'}`}
                                                                >
                                                                    {activity.message}
                                                                </div>
                                                                <span
                                                                    data-title={activity.tooltipDate}
                                                                    className="table-tooltip before:!bottom-[20px] before:!left-[11%] inline text-[13px] ml-1 leading-4 w-full text-dark-black-200 text-start"
                                                                >
                                                                    {activity.timeAgo}
                                                                </span>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>


                                </div>

                            )}
                            {(pagination.hasMore || processedActivities.length >= 25) && (
                                <button
                                    onClick={handleLoadMore}
                                    disabled={itemActivityLoading}
                                    className='inline-flex items-center rounded-md  ml-[25px]
          text-xs font-medium text-yellow-800 mt-4'
                                >
                                    {itemActivityLoading ? 'Loading...' : 'Load More'}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default DocumentDetailsActivity;
