
//@ts-nocheck
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import TipTapEditor from './TipTapEditor';
import { motion, AnimatePresence } from 'framer-motion';
import { Listbox, ListboxButton, ListboxOptions, Transition } from '@headlessui/react'
import { Link, useParams } from 'react-router-dom';
import { Dialog } from '@headlessui/react'
import { getEditorInstance, getMentionList } from '../../editor';
import { ChevronDownIcon, CustomUserIcon } from '../icons';
import { baseMemberDetailsData } from '../../features/base/baseSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Switch } from '@headlessui/react'
import CustomMenuDropdown from '../common/CustomMenuDropdown';
import { createDocumentAction, createDocumentContact, fetchDocument, fetchDocumentAction, fetchDocumentContact, updateDocument } from '../../features/document/documentSlice';
import { toast } from 'sonner';
import LoadingSpinner from '../common/LoadingSpinner';
import { fetchBoardsList } from '../../features/teams/teamsSlice';
import { formatDistance } from 'date-fns';
import { debounce } from 'lodash';
import { fetchTeamsMemberList } from '../../features/teamsMembers/teamsMemberSlice';
interface DocumentDetailsProps {
  // Add any props you need
}

export interface DropdownItem {
  className?: string;
  icon?: ReactNode;
  label: string;
  shortcut?: any;
  disabled?: boolean;
  onClick?: () => void;
  href?: string; // Add href property with type string

}


export interface DropdownGroup {
  items: DropdownItem[];
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const variableTypeList = [
  { id: 1, name: 'Text' },
  { id: 2, name: 'Underline' },
  { id: 3, name: 'Email' },
  { id: 4, name: 'Phone Number' },
  { id: 5, name: 'Date' },
  { id: 6, name: 'Signature' },
  { id: 7, name: 'Image' },
  { id: 8, name: 'File' },
  { id: 9, name: 'Checkbox' },
]

const tabs = [
  { name: 'team', icon: 'fas fa-user' },
  { name: 'externally', icon: 'fas fa-users' },
];

const tabs1 = [
  { name: 'internal team', icon: 'fas fa-user' },
  { name: 'external contact', icon: 'fas fa-users' },
];

const tabs2 = [
  { name: 'internal team', icon: 'fas fa-user' },
  { name: 'external contact', icon: 'fas fa-users' },
];

const tabs3 = [
  { name: 'internal team', icon: 'fas fa-user' },
  { name: 'external contact', icon: 'fas fa-users' },
];

const tabs4 = [
  { name: 'internal team', icon: 'fas fa-user' },
  { name: 'external contact', icon: 'fas fa-users' },
];

const tabs5 = [
  { name: 'internal team', icon: 'fas fa-user' },
  { name: 'external contact', icon: 'fas fa-users' },
];

const tabs6 = [
  { name: 'internal team', icon: 'fas fa-user' },
  { name: 'external contact', icon: 'fas fa-users' },
];

const dropdownGroups: DropdownGroup[] = [
  {
    options: [
      {
        label: `Delete`,
        // icon: CustomUserIcon,
        // className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
        onClick: () => console.log('Delete clicked'),
      }
    ],
  },
];

const dropdownGroups1: DropdownGroup[] = [
  {
    options: [
      {
        label: `Delete`,
        // icon: CustomUserIcon,
        // className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
        onClick: () => console.log('Delete clicked'),
      }
    ],
  },
];

const dropdownGroups2: DropdownGroup[] = [
  {
    options: [
      {
        label: `Delete`,
        // icon: CustomUserIcon,
        // className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
        onClick: () => console.log('Delete clicked'),
      }
    ],
  },
];

const dropdownGroups3: DropdownGroup[] = [
  {
    options: [
      {
        label: `Delete`,
        // icon: CustomUserIcon,
        // className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
        onClick: () => console.log('Delete clicked'),
      }
    ],
  },
];

const dropdownGroups4: DropdownGroup[] = [
  {
    options: [
      {
        label: `Delete`,
        // icon: CustomUserIcon,
        // className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
        onClick: () => console.log('Delete clicked'),
      }
    ],
  },
];

const DocumentDetails: React.FC<DocumentDetailsProps> = React.memo(() => {
  let editor = getEditorInstance()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isAsideOpen, setIsAsideOpen] = useState(true);  // Initially open first aside
  const [isAsideOpen1, setIsAsideOpen1] = useState(false);  // Initially close second aside
  const [collapsed, setCollapsed] = useState(false);
  const [collapsed1, setCollapsed1] = useState(false);
  const [variables, setVariables] = useState<string[]>([]); // Initialize an empty list
  const [expandedVariable, setExpandedVariable] = useState<string | null>(null);
  const [variableType, setVariableType] = useState(variableTypeList[0])
  const [openFeedbackTab, setOpenFeedbackTab] = useState('team')
  const [email, setEmail] = useState('');
  const [baseMemberDetails, setBaseMemberDetails] = useState<any>([]);
  const [profile, setProfile] = useState('')
  const { baseId } = useParams<{ baseId: string }>(); // Extract baseId from URL
  const [isSelected, setIsSelected] = useState(false);
  const [isAsideOpen2, setIsAsideOpen2] = useState(false);  // Initially open first aside
  const [isAsideOpen3, setIsAsideOpen3] = useState(false);  // Initially close second aside
  const [isAsideOpen4, setIsAsideOpen4] = useState(false);
  const [collapsed2, setCollapsed2] = useState(false);
  const [collapsed3, setCollapsed3] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { data, fetchStatus, fetchError } = useAppSelector((state: RootState) => state.documentData);
  const [teams, setTeams] = useState<any>(null);
  const [selected, setSelected] = useState('')
  const [isEditing, setIsEditing] = useState(false);
  const [docName, setDocName] = useState<string>('');
  const [deleteSpanByNameFunction, setDeleteSpanByNameFunction] = useState(null);
  const [collapsed4, setCollapsed4] = useState(false);
  const [showDeleteConfirmation1, setShowDeleteConfirmation1] = useState(false);
  const [collapsed5, setCollapsed5] = useState(false);
  const [collapsed6, setCollapsed6] = useState(false);
  const [collapsed7, setCollapsed7] = useState(false);
  const [collapsed8, setCollapsed8] = useState(false);
  const [collapsed9, setCollapsed9] = useState(false);
  const [newAction, setNewAction] = useState(false)
  // Reference to the editor instance
  const [editorInstance, setEditorInstance] = useState(null);
  const [openActionTab, setOpenActionTab] = useState('internal team')
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRecipient, setSelectedRecipient] = useState(''); // New state for selected recipient
  const [recipients, setRecipients] = useState([]);
  const [newActionEnteral, setNewActionEnteral] = useState(false)
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [limit] = useState(25);
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [isActionLoading1, setIsActionLoading1] = useState(false)
  const [contactInfo, setContactInfo] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });

  const [contactLoading, setContactLoading] = useState(false)
  const [newAction1, setNewAction1] = useState(false)
  const [openActionTab1, setOpenActionTab1] = useState('internal team')
  const [baseMemberDetails1, setBaseMemberDetails1] = useState<any>([]);
  const [isActionLoading2, setIsActionLoading2] = useState(false)
  const [selectedRecipient1, setSelectedRecipient1] = useState('');
  const [newActionEnteral1, setNewActionEnteral1] = useState(false)
  const [search1, setSearch1] = useState('');
  const [page1, setPage1] = useState(1);
  const [limit1] = useState(25);
  const [isOpen1, setIsOpen1] = useState(false);
  const [recipients1, setRecipients1] = useState([]);
  const [contactInfo1, setContactInfo1] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });
  const [contactLoading1, setContactLoading1] = useState(false)


  const [newAction2, setNewAction2] = useState(false)
  const [openActionTab2, setOpenActionTab2] = useState('internal team')
  const [baseMemberDetails2, setBaseMemberDetails2] = useState<any>([]);
  const [isActionLoading3, setIsActionLoading3] = useState(false)
  const [selectedRecipient2, setSelectedRecipient2] = useState('');
  const [newActionEnteral2, setNewActionEnteral2] = useState(false)
  const [search2, setSearch2] = useState('');
  const [page2, setPage2] = useState(1);
  const [limit2] = useState(25);
  const [isOpen2, setIsOpen2] = useState(false);
  const [recipients2, setRecipients2] = useState([]);
  const [contactInfo2, setContactInfo2] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });
  const [contactLoading2, setContactLoading2] = useState(false)

  const [newAction3, setNewAction3] = useState(false)
  const [openActionTab3, setOpenActionTab3] = useState('internal team')
  const [baseMemberDetails3, setBaseMemberDetails3] = useState<any>([]);
  const [isActionLoading4, setIsActionLoading4] = useState(false)
  const [selectedRecipient3, setSelectedRecipient3] = useState('');
  const [newActionEnteral3, setNewActionEnteral3] = useState(false)
  const [search3, setSearch3] = useState('');
  const [page3, setPage3] = useState(1);
  const [limit3] = useState(25);
  const [isOpen3, setIsOpen3] = useState(false);
  const [recipients3, setRecipients3] = useState([]);
  const [contactInfo3, setContactInfo3] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });
  const [contactLoading3, setContactLoading3] = useState(false)


  const [newAction4, setNewAction4] = useState(false)
  const [openActionTab4, setOpenActionTab4] = useState('internal team')
  const [baseMemberDetails4, setBaseMemberDetails4] = useState<any>([]);
  const [isActionLoading5, setIsActionLoading5] = useState(false)
  const [selectedRecipient4, setSelectedRecipient4] = useState('');
  const [newActionEnteral4, setNewActionEnteral4] = useState(false)
  const [search4, setSearch4] = useState('');
  const [page4, setPage4] = useState(1);
  const [limit4] = useState(25);
  const [isOpen4, setIsOpen4] = useState(false);
  const [recipients4, setRecipients4] = useState([]);
  const [contactInfo4, setContactInfo4] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });
  const [contactLoading4, setContactLoading4] = useState(false)


  const [newAction5, setNewAction5] = useState(false)
  const [openActionTab5, setOpenActionTab5] = useState('internal team')
  const [baseMemberDetails5, setBaseMemberDetails5] = useState<any>([]);
  const [isActionLoading6, setIsActionLoading6] = useState(false)
  const [selectedRecipient5, setSelectedRecipient5] = useState('');
  const [newActionEnteral5, setNewActionEnteral5] = useState(false)
  const [search5, setSearch5] = useState('');
  const [page5, setPage5] = useState(1);
  const [limit5] = useState(25);
  const [isOpen5, setIsOpen5] = useState(false);
  const [recipients5, setRecipients5] = useState([]);
  const [contactInfo5, setContactInfo5] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    contact_company: '',
    contact_job_title: '',
  });
  const [contactLoading5, setContactLoading5] = useState(false)


  const [baseMemberDetailsData, setBaseMemberDetailsData] = useState<any>([]);
  const [variableType1, setVariableType1] = useState(baseMemberDetailsData[0]?.name || null);

  const [baseMemberDetailsData1, setBaseMemberDetailsData1] = useState<any>([]);
  const [variableType2, setVariableType2] = useState(baseMemberDetailsData1[0]?.name || null);

  const [baseMemberDetailsData2, setBaseMemberDetailsData2] = useState<any>([]);
  const [variableType3, setVariableType3] = useState(baseMemberDetailsData2[0]?.name || null);

  const [baseMemberDetailsData3, setBaseMemberDetailsData3] = useState<any>([]);
  const [variableType4, setVariableType4] = useState(baseMemberDetailsData3[0]?.name || null);

  const [baseMemberDetailsData4, setBaseMemberDetailsData4] = useState<any>([]);
  const [variableType5, setVariableType5] = useState(baseMemberDetailsData4[0]?.name || null);

  const [baseMemberDetailsData5, setBaseMemberDetailsData5] = useState<any>([]);
  const [variableType6, setVariableType6] = useState(baseMemberDetailsData5[0]?.name || null);


  const handleRecipientSelect = (recipient) => {
    setSelectedRecipient(recipient);
    setIsOpen(false);
    setSearch(''); // Clear the search term when selecting a recipient
    setNewActionEnteral(false);
  };

  const handleRecipientSelect1 = (recipient) => {
    setSelectedRecipient1(recipient);
    setIsOpen1(false);
    setSearch1(''); // Clear the search term when selecting a recipient
    setNewActionEnteral1(false);
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };

  const toggleCollapse2 = () => {
    setCollapsed2(!collapsed2);
  };

  const toggleCollapse3 = () => {
    setCollapsed3(!collapsed3);
  };

  const toggleCollapse4 = () => {
    setCollapsed4(!collapsed4);
  };

  const toggleCollapse5 = () => {
    setCollapsed5(!collapsed5);
  };

  const toggleCollapse6 = () => {
    setCollapsed6(!collapsed6);
  };

  const toggleCollapse7 = () => {
    setCollapsed7(!collapsed7);
  };

  const toggleCollapse8 = () => {
    setCollapsed8(!collapsed8);
  };

  const toggleCollapse9 = () => {
    setCollapsed9(!collapsed9);
  };

  const handleConfirmDelete = () => {
    setShowDeleteConfirmation(true);
  }

  const handleFirstBar = () => {
    setIsAsideOpen(!isAsideOpen);  // Open first aside
    setIsAsideOpen1(false); // Close second aside
    setIsAsideOpen2(false);
    setIsAsideOpen3(false);
    setIsAsideOpen4(false);
    setCollapsed1(false);  // Reset collapse state for second aside
  };

  const handleSecondBar = () => {
    setIsAsideOpen(false); // Close first aside
    setIsAsideOpen2(false);
    setIsAsideOpen3(false);
    setIsAsideOpen4(false);
    setIsAsideOpen1(!isAsideOpen1);  // Open second aside
    setCollapsed(false);    // Reset collapse state for first aside
  };

  const handleActivity = () => {
    setIsAsideOpen2(!isAsideOpen2);  // Open first aside
    setIsAsideOpen3(false); // Close second aside
    setIsAsideOpen(false);
    setIsAsideOpen1(false);
    setIsAsideOpen4(false);
    setCollapsed3(false);  // Reset collapse state for second aside
  };

  const handleChat = () => {
    setIsAsideOpen(false); // Close first aside
    setIsAsideOpen3(!isAsideOpen3);  // Open second aside
    setIsAsideOpen2(false);
    setIsAsideOpen1(false);
    setIsAsideOpen4(false);
    setCollapsed2(false);    // Reset collapse state for first aside
  };

  const handleAction = () => {
    setIsAsideOpen(false); // Close first aside
    setIsAsideOpen4(!isAsideOpen4);  // Open second aside
    setIsAsideOpen2(false);
    setIsAsideOpen3(false);
    setIsAsideOpen1(false);
    // setCollapsed3(false);    // Reset collapse state for first aside
  };


  useEffect(() => {
    const fetchVariables = () => {
      const updatedList = getMentionList();
      setVariables([...updatedList]); // Update the list
    };

    fetchVariables(); // Initial fetch

    // Example of how to trigger updates; replace with your actual triggers if needed
    const interval = setInterval(fetchVariables, 1000); // Check every second (adjust as needed)

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const variants = {
    hidden: { opacity: 1, y: 0 },
    visible: { opacity: 1, y: 0 },
  };


  const toggleAccordion = (variable: string) => {
    setExpandedVariable(prev => (prev === variable ? null : variable));
  };




  useEffect(() => {
    if (baseId) {
      const fetchData = async () => {
        const storedBaseMember = localStorage.getItem('baseMembersDetails');
        if (storedBaseMember) {
          setBaseMemberDetails(JSON.parse(storedBaseMember));
          setBaseMemberDetails1(JSON.parse(storedBaseMember));
          setBaseMemberDetails2(JSON.parse(storedBaseMember));
          setBaseMemberDetails3(JSON.parse(storedBaseMember));
          setBaseMemberDetails4(JSON.parse(storedBaseMember));
          setBaseMemberDetails5(JSON.parse(storedBaseMember));
          setProfile()
        } else {
          try {
            const baseResponse = await dispatch(baseMemberDetailsData(baseId));
            setBaseMemberDetails(baseResponse.payload);
            setBaseMemberDetails1(baseResponse.payload);
            setBaseMemberDetails2(baseResponse.payload);
            setBaseMemberDetails3(baseResponse.payload);
            setBaseMemberDetails4(baseResponse.payload);
            setBaseMemberDetails5(baseResponse.payload);
          } catch (error) {
            console.error("Error fetching base member details:", error);
          }
        }
      };
      fetchData();
    }
  }, [baseId, dispatch]);


  const handleToggle = () => {
    setIsEnabled(!isEnabled);
  };

  const handleSave = async () => {
    if (!editorInstance) {
      toast.error('Editor not initialized');
      return;
    }

    setIsSaving(true);
    try {
      // Get both HTML and JSON content from editor
      const htmlContent = editorInstance.getHTML();
      const jsonContent = editorInstance.getJSON();

      const payload = {
        id: id,
        baseId: baseId,
        doc_content: JSON.stringify(jsonContent)
        // channel_id: channelId,
        // doc_content: JSON.stringify({
        //   html: htmlContent,
        //   json: 
        //   jsonContent,
        //   timestamp: new Date().toISOString()
        // })
      };

      const result = await dispatch(updateDocument(payload)).unwrap();
      toast.success('Document saved successfully');
    } catch (error) {
      console.error('Failed to save document:', error);
      toast.error('Failed to save document');
    } finally {
      setIsSaving(false);
    }
  };


  // change channels 
  const handleChangeChannel = (newSelected) => {
    setSelected(newSelected);
    // Show toast notification when the value changes
    toast.success(`Channel changed to: ${newSelected.name}`);
  };

  const [documentDetailsSave, setDocumentDetailsSave] = useState('')
  useEffect(() => {
    if (baseId && id) {
      const fetchData = async () => {
        try {
          const res = await dispatch(fetchDocument({ baseId, id: id })).unwrap();
          setDocumentDetailsSave(res.data);
        } catch (err) {
          console.error("Failed to fetch document", err);
        }
      };
      fetchData();
    }
  }, [dispatch, baseId, id]);


  // teams 
  useEffect(() => {
    if (baseId) {
      const fetchTeams = async () => {
        const storedBase = localStorage.getItem('baseTeamsData');
        if (storedBase) {
          setTeams(JSON.parse(storedBase));
          setSelected(JSON.parse(storedBase)?.data[0])

        } else {
          try {
            const baseResponse = await dispatch(fetchBoardsList(userDetails?.id || baseId));
            setTeams(baseResponse.payload);
          } catch (error) {
            console.error("Error fetching base member details:", error);
          }
        }
      };
      fetchTeams();
    }
  }, [baseId, dispatch]);

  const createdTimeAgoSidebar = data?.data?.doc_createdat
    ? formatDistance(new Date(data.data.doc_createdat), new Date(), { includeSeconds: true }).replace(/^about\s/, '')
    : "";

  const updatedTimeAgoSidebar = data?.data?.doc_createdat
    ? formatDistance(new Date(data.data.doc_createdat), new Date(), { includeSeconds: true }).replace(/^about\s/, '')
    : "";


  // update doc name 
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDocName(e.target.value);
  };

  const handleNameClick = () => {
    setIsEditing(true); // Switch to editing mode when the user clicks the document name
  };

  useEffect(() => {
    if (data?.data?.doc_name) {
      setDocName(data.data.doc_name);
    }
  }, [data]);


  const handleNameBlur = async () => {
    if (docName) {
      try {
        const payload = {
          id: id,
          baseId: baseId,
          name: docName,
        };
        const result = await dispatch(updateDocument(payload)).unwrap();
        toast.success('Document Name Updated successfully');
      } catch (error) {
        console.error('Failed to save document:', error);
        toast.error('Failed to update document name');
      }
    }
    setIsEditing(false);
  };


  const handleColorChange = () => {
    if (!editorInstance?.setMentionsColor) {
      console.warn('Editor instance or color change method not available');
      return;
    }

    // Call the setMentionsColor method with your desired color
    editorInstance.setMentionsColor('#ff0000'); // or any other color
  };

  const handleEditorReady = (editor, deleteSpanByName) => {
    setEditorInstance(editor);
    setDeleteSpanByNameFunction(() => deleteSpanByName); // Set deleteSpanByName function
  };

  // Function to handle the deletion process based on count
  const handleDeleteClick = (name, count) => {
    if (count === 0) {
      // Directly delete if count is 0
      handledeleteSpanByName(name);
    } else {
      // Show confirmation dialog if count is 1 or more
      setShowDeleteConfirmation1(true);
    }
  };


  // Function to execute deletion
  const handledeleteSpanByName = (name) => {
    if (deleteSpanByNameFunction) {
      deleteSpanByNameFunction(name);
      setShowDeleteConfirmation1(false); // Close dialog after deletion
    } else {
      console.warn("Delete function is not available");
    }
  };


  const handleNewAction = () => {
    setNewAction(!newAction)
  }


  const handleNewActionEnteral = () => {
    setNewActionEnteral(true)
    setIsOpen(false)
    setOpenActionTab('external contact')
  }


  const debouncedSearch = useCallback(
    debounce((searchTerm: string) => {
      setPage(1);
      dispatch(fetchDocumentContact({
        baseId,
        page: 1,
        limit,
        search: searchTerm
      }));
    }, 500),
    [dispatch, baseId, limit]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    debouncedSearch(searchTerm);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchDocumentContact({
          baseId,
          page,
          limit,
          search
        })).unwrap();

        setRecipients(response.data.contact);
        setRecipients1(response.data.contact);
        setRecipients2(response.data.contact);
        setRecipients3(response.data.contact);
        setRecipients4(response.data.contact);
        setRecipients5(response.data.contact);
      } catch (error) {
        console.error("Error fetching document contacts:", error);
      }
    };

    fetchData();
  }, [dispatch, baseId, page, limit, search,
    limit1, limit2, limit3, limit4, limit5, search1,
    search2, search3, search4, search5, page1, page2, page3, page4, page5]);


  const [teamMemberFetched, setTeamMemberFetched] = useState<any[]>([]);

  useEffect(() => {
    if (baseId && documentDetailsSave?.channel_id) { // Add check for channel_id
      const fetchDataone = async () => {
        try {
          // Pass channel_id directly, not as an object
          const teamMemberResponse = await dispatch(
            fetchTeamsMemberList(documentDetailsSave.channel_id)
          ).unwrap();
          console.log(teamMemberResponse, "teamMemberResponse");

          setTeamMemberFetched(teamMemberResponse?.data || []);
        } catch (error) {
          console.error("Error fetching base member details:", error);
        }
      };
      fetchDataone();
    }
  }, [baseId, dispatch, documentDetailsSave]);




  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prevState) => ({ ...prevState, [name]: value }));
  };


  // document action
  const handleDocumentContactSave = async (e) => {
    e.preventDefault();
    setContactLoading(true);

    if (contactInfo.contact_email.trim().length === 0) {
      toast.error("Email is required");
      setContactLoading(false);
      return;
    }

    const payload = {
      contact_first_name: contactInfo.contact_first_name,
      contact_last_name: contactInfo.contact_last_name,
      contact_email: contactInfo.contact_email,
      contact_phone: contactInfo.contact_phone,
      contact_company: contactInfo.contact_company,
      contact_job_title: contactInfo.contact_job_title,
    };

    try {
      const response = await dispatch(createDocumentContact({
        data: payload,
        userId: baseId,
      }));



      if (response?.meta?.requestStatus === 'fulfilled') {
        setContactInfo({
          contact_first_name: "",
          contact_last_name: "",
          contact_email: "",
          contact_phone: "",
          contact_company: "",
          contact_job_title: "",
        });
        toast.success('Contact updated successfully!');
        setNewActionEnteral(false);
        if (response?.payload?.data) {
          setSelectedRecipient(response?.payload?.data);
        }

        // Call fetchDocumentContact again
        const fetchResponse = await dispatch(fetchDocumentContact({
          baseId,
          page,
          limit,
          search
        }));

        if (fetchResponse.payload?.data?.contact) {

          setRecipients(fetchResponse.payload.data.contact); // Assuming response.data.contact is the correct format
        } else {
          console.error('Contacts data not found after fetching.');
          toast.error('Failed to fetch updated contacts.');
        }

      } else {
        toast.error('Failed to update contact.');
      }

    } catch (error) {
      console.error("Failed to save contact:", error);
      toast.error(`An error occurred while saving: ${error.message || error}`);
    } finally {
      setContactLoading(false);
    }

  };


  const handleActionExternalContact = async () => {
    try {
      setIsActionLoading(true);

      if (!selectedRecipient?.id) {
        toast.error("Please select an external contact.");
        return; // Stop the function if external_contact_id is missing
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "collab",
        external_contact_id: selectedRecipient.id
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      if (result.status === 200) {
        const fetchResponse = await dispatch(fetchDocumentAction({
          baseId,
          id: id
        }));
        toast.success('Action created successfully!');
        //fetchDocumentAction

        return result;
      } else {
        throw new Error('Failed to collect action');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to process action';
      throw err;
    } finally {
      setIsActionLoading(false);
    }
  };

  const handleActionInternalTeam = async () => {
    try {
      setIsActionLoading1(true);

      if (!variableType1?.id) {
        toast.error("Please select an internal team.");
        return;
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "collab",
        internal_user_id: variableType1.id
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      if (result.status === 200) {
        const fetchResponse = await dispatch(fetchDocumentAction({
          baseId,
          id: id
        }));
        toast.success('Action created successfully!');
        return result;
      } else {
        throw new Error('Failed to collect action');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to process action';
      console.error('Error:', errorMessage);
      throw err;
    } finally {
      setIsActionLoading1(false);
    }
  };

  const handleNewAction1 = () => {
    setNewAction1(!newAction1)
  }


  const handleNewActionEnteral1 = () => {
    setNewActionEnteral1(true)
    setIsOpen1(false)
    setOpenActionTab1('external contact')
  }


  const debouncedSearch1 = useCallback(
    debounce((searchTerm: string) => {
      setPage1(1);
      // Dispatch the action to fetch data based on the search term
      dispatch(fetchDocumentContact({
        baseId,
        page: 1,
        limit1,
        search: searchTerm
      }));
    }, 500),
    [dispatch, baseId, limit1]
  );

  const handleSearchChange1 = (e) => {
    const searchTerm = e.target.value;
    setSearch1(searchTerm);
    dispatch(fetchDocumentContact({
      baseId,
      page: 1,
      limit1,
      search: searchTerm
    }));
  };


  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setContactInfo1((prevState) => ({ ...prevState, [name]: value }));
  };


  // document action
  const handleDocumentContactSave1 = async (e) => {
    e.preventDefault();
    setContactLoading1(true);

    if (contactInfo1.contact_email.trim().length === 0) {
      toast.error("Email is required");
      setContactLoading1(false);
      return;
    }

    const payload = {
      contact_first_name: contactInfo1.contact_first_name,
      contact_last_name: contactInfo1.contact_last_name,
      contact_email: contactInfo1.contact_email,
      contact_phone: contactInfo1.contact_phone,
      contact_company: contactInfo1.contact_company,
      contact_job_title: contactInfo1.contact_job_title,
    };

    try {
      const response = await dispatch(createDocumentContact({
        data: payload,
        userId: baseId,
      }));



      if (response?.meta?.requestStatus === 'fulfilled') {
        setContactInfo1({
          contact_first_name: "",
          contact_last_name: "",
          contact_email: "",
          contact_phone: "",
          contact_company: "",
          contact_job_title: "",
        });
        toast.success('Contact updated successfully!');
        setNewActionEnteral1(false);
        if (response?.payload?.data) {
          setSelectedRecipient1(response?.payload?.data);
        }

        // Call fetchDocumentContact again
        const fetchResponse = await dispatch(fetchDocumentContact({
          baseId,
          page,
          limit,
          search
        }));

        if (fetchResponse.payload?.data?.contact) {
          setRecipients1(fetchResponse.payload.data.contact);
        } else {
          console.error('Contacts data not found after fetching.');
          toast.error('Failed to fetch updated contacts.');
        }

      } else {
        toast.error('Failed to update contact.');
      }

    } catch (error) {
      console.error("Failed to save contact:", error);
      toast.error(`An error occurred while saving: ${error.message || error}`);
    } finally {
      setContactLoading1(false);
    }

  };


  const handleActionInternalTeam1 = async () => {
    try {
      setIsActionLoading2(true);

      if (!variableType2?.id) {
        toast.error("Please select an internal team.");
        return;
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "approval",
        internal_user_id: variableType2.id
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      if (result.status === 200) {
        const fetchResponse = await dispatch(fetchDocumentAction({
          baseId,
          id: id
        }));
        toast.success('Action created successfully!');
        return result;
      } else {
        throw new Error('Failed to collect action');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to process action';
      console.error('Error:', errorMessage);
      throw err;
    } finally {
      setIsActionLoading2(false);
    }
  };

  const handleActionExternalContact1 = async () => {
    try {
      setIsActionLoading2(true);

      if (!selectedRecipient1?.id) {
        toast.error("Please select an external contact.");
        return; // Stop the function if external_contact_id is missing
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "approval",
        external_contact_id: selectedRecipient1.id
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      if (result.status === 200) {
        const fetchResponse = await dispatch(fetchDocumentAction({
          baseId,
          id: id
        }));
        toast.success('Action created successfully!');
        return result;
      } else {
        throw new Error('Failed to collect action');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to process action';
      console.error('Error:', errorMessage);
      throw err;
    } finally {
      setIsActionLoading2(false);
    }
  };


  const handleRecipientSelect2 = (recipient) => {
    setSelectedRecipient2(recipient);
    setIsOpen2(false);
    setSearch2(''); // Clear the search term when selecting a recipient
    setNewActionEnteral2(false);
  };


  const handleNewAction2 = () => {
    setNewAction2(!newAction2)
  }


  const handleNewActionEnteral2 = () => {
    setNewActionEnteral2(true)
    setIsOpen2(false)
    setOpenActionTab2('external contact')
  }


  const debouncedSearch2 = useCallback(
    debounce((searchTerm: string) => {
      setPage2(1);
      // Dispatch the action to fetch data based on the search term
      dispatch(fetchDocumentContact({
        baseId,
        page: 1,
        limit2,
        search: searchTerm
      }));
    }, 500),
    [dispatch, baseId, limit2]
  );

  const handleSearchChange2 = (e) => {
    const searchTerm = e.target.value;
    setSearch2(searchTerm);
    dispatch(fetchDocumentContact({
      baseId,
      page: 1,
      limit2,
      search: searchTerm
    }));
  };


  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setContactInfo2((prevState) => ({ ...prevState, [name]: value }));
  };


  // document action
  const handleDocumentContactSave2 = async (e) => {
    e.preventDefault();
    setContactLoading2(true);

    if (contactInfo2.contact_email.trim().length === 0) {
      toast.error("Email is required");
      setContactLoading2(false);
      return;
    }

    const payload = {
      contact_first_name: contactInfo2.contact_first_name,
      contact_last_name: contactInfo2.contact_last_name,
      contact_email: contactInfo2.contact_email,
      contact_phone: contactInfo2.contact_phone,
      contact_company: contactInfo2.contact_company,
      contact_job_title: contactInfo2.contact_job_title,
    };

    try {
      const response = await dispatch(createDocumentContact({
        data: payload,
        userId: baseId,
      }));



      if (response?.meta?.requestStatus === 'fulfilled') {
        setContactInfo2({
          contact_first_name: "",
          contact_last_name: "",
          contact_email: "",
          contact_phone: "",
          contact_company: "",
          contact_job_title: "",
        });
        toast.success('Contact updated successfully!');
        setNewActionEnteral2(false);
        if (response?.payload?.data) {
          setSelectedRecipient2(response?.payload?.data);
        }

        // Call fetchDocumentContact again
        const fetchResponse = await dispatch(fetchDocumentContact({
          baseId,
          page,
          limit,
          search
        }));

        if (fetchResponse.payload?.data?.contact) {
          setRecipients2(fetchResponse.payload.data.contact);
        } else {
          console.error('Contacts data not found after fetching.');
          toast.error('Failed to fetch updated contacts.');
        }

      } else {
        toast.error('Failed to update contact.');
      }

    } catch (error) {
      console.error("Failed to save contact:", error);
      toast.error(`An error occurred while saving: ${error.message || error}`);
    } finally {
      setContactLoading2(false);
    }

  };


  const handleActionInternalTeam2 = async () => {
    try {
      setIsActionLoading3(true);

      if (!variableType3?.id) {
        toast.error("Please select an internal team.");
        return;
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "view",
        internal_user_id: variableType3.id
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      if (result.status === 200) {
        const fetchResponse = await dispatch(fetchDocumentAction({
          baseId,
          id: id
        }));
        toast.success('Action created successfully!');
        return result;
      } else {
        throw new Error('Failed to collect action');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to process action';
      console.error('Error:', errorMessage);
      throw err;
    } finally {
      setIsActionLoading3(false);
    }
  };

  const handleActionExternalContact2 = async () => {
    try {
      setIsActionLoading3(true);

      if (!selectedRecipient2?.id) {
        toast.error("Please select an external contact.");
        return; // Stop the function if external_contact_id is missing
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "view",
        external_contact_id: selectedRecipient2.id
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      if (result.status === 200) {
        const fetchResponse = await dispatch(fetchDocumentAction({
          baseId,
          id: id
        }));
        toast.success('Action created successfully!');
        return result;
      } else {
        throw new Error('Failed to collect action');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to process action';
      console.error('Error:', errorMessage);
      throw err;
    } finally {
      setIsActionLoading3(false);
    }
  };




  /////

  const handleRecipientSelect3 = (recipient) => {
    setSelectedRecipient3(recipient);
    setIsOpen3(false);
    setSearch3(''); // Clear the search term when selecting a recipient
    setNewActionEnteral3(false);
  };


  const handleNewAction3 = () => {
    setNewAction3(!newAction3)
  }


  const handleNewActionEnteral3 = () => {
    setNewActionEnteral3(true)
    setIsOpen3(false)
    setOpenActionTab3('external contact')
  }


  const debouncedSearch3 = useCallback(
    debounce((searchTerm: string) => {
      setPage3(1);
      // Dispatch the action to fetch data based on the search term
      dispatch(fetchDocumentContact({
        baseId,
        page: 1,
        limit3,
        search: searchTerm
      }));
    }, 500),
    [dispatch, baseId, limit3]
  );

  const handleSearchChange3 = (e) => {
    const searchTerm = e.target.value;
    setSearch3(searchTerm);
    dispatch(fetchDocumentContact({
      baseId,
      page: 1,
      limit3,
      search: searchTerm
    }));
  };




  const handleInputChange3 = (e) => {
    const { name, value } = e.target;
    setContactInfo3((prevState) => ({ ...prevState, [name]: value }));
  };


  // document action
  const handleDocumentContactSave3 = async (e) => {
    e.preventDefault();
    setContactLoading3(true);

    if (contactInfo3.contact_email.trim().length === 0) {
      toast.error("Email is required");
      setContactLoading3(false);
      return;
    }

    const payload = {
      contact_first_name: contactInfo3.contact_first_name,
      contact_last_name: contactInfo3.contact_last_name,
      contact_email: contactInfo3.contact_email,
      contact_phone: contactInfo3.contact_phone,
      contact_company: contactInfo3.contact_company,
      contact_job_title: contactInfo3.contact_job_title,
    };

    try {
      const response = await dispatch(createDocumentContact({
        data: payload,
        userId: baseId,
      }));



      if (response?.meta?.requestStatus === 'fulfilled') {
        setContactInfo3({
          contact_first_name: "",
          contact_last_name: "",
          contact_email: "",
          contact_phone: "",
          contact_company: "",
          contact_job_title: "",
        });
        toast.success('Contact updated successfully!');
        setNewActionEnteral3(false);
        if (response?.payload?.data) {
          setSelectedRecipient3(response?.payload?.data);
        }

        // Call fetchDocumentContact again
        const fetchResponse = await dispatch(fetchDocumentContact({
          baseId,
          page,
          limit,
          search
        }));

        if (fetchResponse.payload?.data?.contact) {
          setRecipients3(fetchResponse.payload.data.contact);
        } else {
          console.error('Contacts data not found after fetching.');
          toast.error('Failed to fetch updated contacts.');
        }

      } else {
        toast.error('Failed to update contact.');
      }

    } catch (error) {
      console.error("Failed to save contact:", error);
      toast.error(`An error occurred while saving: ${error.message || error}`);
    } finally {
      setContactLoading3(false);
    }

  };


  const handleActionInternalTeam3 = async () => {
    try {
      setIsActionLoading4(true);

      if (!variableType4?.id) {
        toast.error("Please select an internal team.");
        return;
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "fill",
        internal_user_id: variableType4.id
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      if (result.status === 200) {
        const fetchResponse = await dispatch(fetchDocumentAction({
          baseId,
          id: id
        }));
        toast.success('Action created successfully!');
        return result;
      } else {
        throw new Error('Failed to collect action');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to process action';
      console.error('Error:', errorMessage);
      throw err;
    } finally {
      setIsActionLoading4(false);
    }
  };

  const handleActionExternalContact3 = async () => {
    try {
      setIsActionLoading4(true);

      if (!selectedRecipient3?.id) {
        toast.error("Please select an external contact.");
        return; // Stop the function if external_contact_id is missing
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "fill",
        external_contact_id: selectedRecipient3.id
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      if (result.status === 200) {
        const fetchResponse = await dispatch(fetchDocumentAction({
          baseId,
          id: id
        }));
        toast.success('Action created successfully!');
        return result;
      } else {
        throw new Error('Failed to collect action');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to process action';
      console.error('Error:', errorMessage);
      throw err;
    } finally {
      setIsActionLoading4(false);
    }
  };



  ///////

  const handleRecipientSelect4 = (recipient) => {
    setSelectedRecipient4(recipient);
    setIsOpen4(false);
    setSearch4(''); // Clear the search term when selecting a recipient
    setNewActionEnteral4(false);
  };


  const handleNewAction4 = () => {
    setNewAction4(!newAction4)
  }


  const handleNewActionEnteral4 = () => {
    setNewActionEnteral4(true)
    setIsOpen4(false)
    setOpenActionTab4('external contact')
  }


  const debouncedSearch4 = useCallback(
    debounce((searchTerm: string) => {
      setPage4(1);
      // Dispatch the action to fetch data based on the search term
      dispatch(fetchDocumentContact({
        baseId,
        page: 1,
        limit4,
        search: searchTerm
      }));
    }, 500),
    [dispatch, baseId, limit4]
  );

  const handleSearchChange4 = (e) => {
    const searchTerm = e.target.value;
    setSearch4(searchTerm);
    dispatch(fetchDocumentContact({
      baseId,
      page: 1,
      limit4,
      search: searchTerm
    }));
  };



  const handleInputChange4 = (e) => {
    const { name, value } = e.target;
    setContactInfo4((prevState) => ({ ...prevState, [name]: value }));
  };


  // document action
  const handleDocumentContactSave4 = async (e) => {
    e.preventDefault();
    setContactLoading4(true);

    if (contactInfo4.contact_email.trim().length === 0) {
      toast.error("Email is required");
      setContactLoading4(false);
      return;
    }

    const payload = {
      contact_first_name: contactInfo4.contact_first_name,
      contact_last_name: contactInfo4.contact_last_name,
      contact_email: contactInfo4.contact_email,
      contact_phone: contactInfo4.contact_phone,
      contact_company: contactInfo4.contact_company,
      contact_job_title: contactInfo4.contact_job_title,
    };

    try {
      const response = await dispatch(createDocumentContact({
        data: payload,
        userId: baseId,
      }));



      if (response?.meta?.requestStatus === 'fulfilled') {
        setContactInfo4({
          contact_first_name: "",
          contact_last_name: "",
          contact_email: "",
          contact_phone: "",
          contact_company: "",
          contact_job_title: "",
        });
        toast.success('Contact updated successfully!');
        setNewActionEnteral4(false);
        if (response?.payload?.data) {
          setSelectedRecipient4(response?.payload?.data);
        }

        // Call fetchDocumentContact again
        const fetchResponse = await dispatch(fetchDocumentContact({
          baseId,
          page,
          limit,
          search
        }));

        if (fetchResponse.payload?.data?.contact) {
          setRecipients4(fetchResponse.payload.data.contact);
        } else {
          console.error('Contacts data not found after fetching.');
          toast.error('Failed to fetch updated contacts.');
        }

      } else {
        toast.error('Failed to update contact.');
      }

    } catch (error) {
      console.error("Failed to save contact:", error);
      toast.error(`An error occurred while saving: ${error.message || error}`);
    } finally {
      setContactLoading4(false);
    }

  };


  const handleActionInternalTeam4 = async () => {
    try {
      setIsActionLoading5(true);

      if (!variableType5?.id) {
        toast.error("Please select an internal team.");
        return;
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "accept",
        internal_user_id: variableType5.id
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      if (result.status === 200) {
        const fetchResponse = await dispatch(fetchDocumentAction({
          baseId,
          id: id
        }));
        toast.success('Action created successfully!');
        return result;
      } else {
        throw new Error('Failed to collect action');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to process action';
      console.error('Error:', errorMessage);
      throw err;
    } finally {
      setIsActionLoading5(false);
    }
  };

  const handleActionExternalContact4 = async () => {
    try {
      setIsActionLoading5(true);

      if (!selectedRecipient4?.id) {
        toast.error("Please select an external contact.");
        return; // Stop the function if external_contact_id is missing
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "accept",
        external_contact_id: selectedRecipient4.id
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      if (result.status === 200) {
        const fetchResponse = await dispatch(fetchDocumentAction({
          baseId,
          id: id
        }));
        toast.success('Action created successfully!');
        return result;
      } else {
        throw new Error('Failed to collect action');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to process action';
      console.error('Error:', errorMessage);
      throw err;
    } finally {
      setIsActionLoading5(false);
    }
  };





  ////

  const handleRecipientSelect5 = (recipient) => {
    setSelectedRecipient5(recipient);
    setIsOpen5(false);
    setSearch5(''); // Clear the search term when selecting a recipient
    setNewActionEnteral5(false);
  };


  const handleNewAction5 = () => {
    setNewAction5(!newAction5)
  }


  const handleNewActionEnteral5 = () => {
    setNewActionEnteral5(true)
    setIsOpen5(false)
    setOpenActionTab5('external contact')
  }


  const debouncedSearch5 = useCallback(
    debounce((searchTerm: string) => {
      setPage5(1);
      // Dispatch the action to fetch data based on the search term
      dispatch(fetchDocumentContact({
        baseId,
        page: 1,
        limit5,
        search: searchTerm
      }));
    }, 500),
    [dispatch, baseId, limit5]
  );

  const handleSearchChange5 = (e) => {
    const searchTerm = e.target.value;
    setSearch5(searchTerm);
    dispatch(fetchDocumentContact({
      baseId,
      page: 1,
      limit5,
      search: searchTerm
    }));
  };



  const handleInputChange5 = (e) => {
    const { name, value } = e.target;
    setContactInfo5((prevState) => ({ ...prevState, [name]: value }));
  };


  // document action
  const handleDocumentContactSave5 = async (e) => {
    e.preventDefault();
    setContactLoading5(true);

    if (contactInfo5.contact_email.trim().length === 0) {
      toast.error("Email is required");
      setContactLoading5(false);
      return;
    }

    const payload = {
      contact_first_name: contactInfo5.contact_first_name,
      contact_last_name: contactInfo5.contact_last_name,
      contact_email: contactInfo5.contact_email,
      contact_phone: contactInfo5.contact_phone,
      contact_company: contactInfo5.contact_company,
      contact_job_title: contactInfo5.contact_job_title,
    };

    try {
      const response = await dispatch(createDocumentContact({
        data: payload,
        userId: baseId,
      }));



      if (response?.meta?.requestStatus === 'fulfilled') {
        setContactInfo5({
          contact_first_name: "",
          contact_last_name: "",
          contact_email: "",
          contact_phone: "",
          contact_company: "",
          contact_job_title: "",
        });
        toast.success('Contact updated successfully!');
        setNewActionEnteral5(false);
        if (response?.payload?.data) {
          setSelectedRecipient5(response?.payload?.data);
        }

        // Call fetchDocumentContact again
        const fetchResponse = await dispatch(fetchDocumentContact({
          baseId,
          page,
          limit,
          search
        }));

        if (fetchResponse.payload?.data?.contact) {
          setRecipients5(fetchResponse.payload.data.contact);
        } else {
          console.error('Contacts data not found after fetching.');
          toast.error('Failed to fetch updated contacts.');
        }

      } else {
        toast.error('Failed to update contact.');
      }

    } catch (error) {
      console.error("Failed to save contact:", error);
      toast.error(`An error occurred while saving: ${error.message || error}`);
    } finally {
      setContactLoading5(false);
    }

  };


  const handleActionInternalTeam5 = async () => {
    try {
      setIsActionLoading6(true);

      if (!variableType6?.id) {
        toast.error("Please select an internal team.");
        return;
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "sign",
        internal_user_id: variableType6.id
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      if (result.status === 200) {
        const fetchResponse = await dispatch(fetchDocumentAction({
          baseId,
          id: id
        }));
        toast.success('Action created successfully!');
        return result;
      } else {
        throw new Error('Failed to collect action');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to process action';
      console.error('Error:', errorMessage);
      throw err;
    } finally {
      setIsActionLoading6(false);
    }
  };

  const handleActionExternalContact5 = async () => {
    try {
      setIsActionLoading6(true);

      if (!selectedRecipient5?.id) {
        toast.error("Please select an external contact.");
        return; // Stop the function if external_contact_id is missing
      }

      const actionPayload = {
        item_type: "doc",
        item_id: id,
        primary_action: "sign",
        external_contact_id: selectedRecipient5.id
      };

      const result = await dispatch(createDocumentAction({
        baseId,
        actionPayload
      })).unwrap();

      if (result.status === 200) {
        const fetchResponse = await dispatch(fetchDocumentAction({
          baseId,
          id: id
        }));
        toast.success('Action created successfully!');
        return result;
      } else {
        throw new Error('Failed to collect action');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to process action';
      console.error('Error:', errorMessage);
      throw err;
    } finally {
      setIsActionLoading6(false);
    }
  };


  // fetchDocumentAction api 
  const [isActionList, setIsActionList] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await dispatch(fetchDocumentAction({ baseId, id }));
        if (res.type.endsWith('fulfilled')) {
          setIsActionList(res.payload); // Set only the payload if successful
        } else {
          throw new Error(res.error?.message || 'Unknown error occurred'); // Handle the error from `rejectWithValue`
        }
      } catch (error) {
        console.error('Error fetching document:', error);
        // Optionally, you can set an error state here or show a message to the user
      }
    };

    fetchData();
  }, [baseId, id, dispatch]);


  // base team channel data
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [baseMemberDetailsArray, setBaseMemberDetailsArray] = useState<any[]>([]);

  const handlebaseChannelList = () => {
    const baseMembers = baseMemberDetails?.data?.baseMembers || [];
    console.log(baseMembers, "baseMembersbaseMembers");
    console.log(teamMemberFetched, "teamMemberFetched");


    const matchingMembers = teamMemberFetched.filter(teamMember => {
      return baseMembers.some(baseMember => baseMember.id === teamMember.user_id);
    });

    const matchingIds = matchingMembers.map(member => ({
      base_member_details: baseMembers.find(baseMember =>
        baseMember.id === member.user_id
      )
    }));
    console.log(matchingIds, "matchingIds");

    const filteredMembers = matchingIds.map(item => item.base_member_details);
    setBaseMemberDetailsArray(filteredMembers);
  };


  // Effect to call handlebaseChannelList when dropdown opens
  useEffect(() => {
    if (isDropdownOpen) {
      handlebaseChannelList();
    }
  }, [isDropdownOpen]);



  if (fetchStatus === 'loading') {
    return <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 50px)', width: 'calc(100% - 350px)' }}>
      <div className="simple-spinner-loader">
        <span className='!w-6 !h-6'></span>
      </div>
    </div>;
  }

  if (fetchStatus === 'failed') {
    return <div>Error loading document: {fetchError}</div>;
  }


  return (
    <div className="h-screen bg-white">
      <div className="sticky top-0 z-10">
        <div className="flex h-[60px] shrink-0 items-center gap-x-4 bg-white px-4 shadow-custom-1 sm:gap-x-6 sm:px-6 lg:px-4">
          <div className='flex items-center justify-between w-full'>
            {isEditing ? (
              <input
                className="text-black-100 font-medium text-smleading-8  cursor-pointer w-full
                         border-0 focus:border-0 focus:outline-none focus-within:border-none  !ring-0 !shadow-none !border-transparent"
                value={docName}
                onChange={handleNameChange}
                onBlur={handleNameBlur}
                autoFocus
                maxLength={100}
              />
            ) : (
              <div
                className="text-black-100 font-medium text-smleading-8  cursor-pointer w-full"
                onClick={handleNameClick} // Enable editing when clicked
              >
                {docName}
              </div>
            )}
            <div className='flex justify-between items-center gap-2'>
              <button
                onClick={handleSave}
                className='group inline-flex px-6 shrink-0 select-none items-center transition-colors duration-100 wg-antialiased focus:outline-0 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:pointer-events-none gap-0 px-8px py-1 rounded-lg text-white outline-primary disabled:opacity-50 bg-indigo-600 hover:bg-indigo-500
                 focus-visible:outline-indigo-600 justify-start [&>span]:flex [&>span]:items-center [&>span]:gap-[6px] text-[13px] font-medium'
              >
                {isSaving ? (
                  <div className='flex items-center  gap-[6px]'>
                    Save
                    <div className="simple-spinner">
                      <span></span>
                    </div>
                  </div>
                ) : (
                  'Save'
                )}
              </button>


            </div>
          </div>

        </div>
      </div>




      <div className='flex-1 p-0'>
        <div className='min-h-full max-w-full' style={{ margin: '0px auto' }}>
          <div className='flex items-stretch flex-row gap-0 w-full h-full relative overflow-x-hidden overflow-y-hidden'>
            <main className='relative flex-1 flex  '>
              <div
                //       className={`bg-white mq1023:max-w-full overflow-auto flex-1 w-full view-feedback-data
                // ${isAsideOpen ? 'mq1800:max-w-[68%] mq3000:max-w-[76.8%]' : 'mq1800:max-w-[96.5%] mq3000:max-w-[97.5%]'} 
                // ${isAsideOpen1 ? 'mq1800:max-w-[78%] mq3000:max-w-[76.8%]' : 'mq1800:max-w-[96.5%] mq3000:max-w-[76.9%]'}

                // `}

                //         className={`bg-white mq1023:max-w-full flex-1 w-full view-feedback-data
                //   ${isAsideOpen && !isAsideOpen1 ? 'desktop-1800-2400-aside-open desktop-991-1800-aside-open' : ''}
                //   ${isAsideOpen1 && !isAsideOpen ? 'desktop-1800-2400-aside-open1 desktop-991-1800-aside-open1' : ''}
                //   ${!isAsideOpen && !isAsideOpen1 ? 'desktop-1800-2400-default desktop-991-1800-default' : ''}
                //   ${isAsideOpen && isAsideOpen1 ? 'desktop-1800-2400-both-open desktop-991-1800-both-open' : ''}
                // `}
                className={`bg-white mq1023:max-w-full flex-1 w-full view-feedback-data
                             ${isAsideOpen && !isAsideOpen1 && !isAsideOpen2 && !isAsideOpen3 && !isAsideOpen4 ? 'desktop-1800-2400-aside-open desktop-991-1800-aside-open' : ''}
                            ${isAsideOpen1 && !isAsideOpen && !isAsideOpen2 && !isAsideOpen3 && !isAsideOpen4 ? 'desktop-1800-2400-aside-open1 desktop-991-1800-aside-open1' : ''}
                           ${isAsideOpen2 && !isAsideOpen && !isAsideOpen1 && !isAsideOpen3 && !isAsideOpen4 ? 'desktop-1800-2400-aside-open2 desktop-991-1800-aside-open2' : ''}
                           ${isAsideOpen3 && !isAsideOpen && !isAsideOpen1 && !isAsideOpen2 && !isAsideOpen4 ? 'desktop-1800-2400-aside-open3 desktop-991-1800-aside-open3' : ''}
                           ${isAsideOpen4 && !isAsideOpen && !isAsideOpen1 && !isAsideOpen2 && !isAsideOpen3 ? 'desktop-1800-2400-aside-open4 desktop-991-1800-aside-open4' : ''}

                             ${(isAsideOpen && isAsideOpen1) || (isAsideOpen2 && isAsideOpen3 && isAsideOpen4) ? 'desktop-1800-2400-both-open desktop-991-1800-both-open' : ''}
                      ${!isAsideOpen && !isAsideOpen1 && !isAsideOpen2 && !isAsideOpen3 && !isAsideOpen4 ? 'desktop-1800-2400-default desktop-991-1800-default' : ''}
                       `}
              >
                <div className='mb-14 mt-5 lg:mt-[35px]' >
                  <TipTapEditor isAsideOpen={isAsideOpen} isAsideOpen1={isAsideOpen1}
                    isAsideOpen2={isAsideOpen2} isAsideOpen3={isAsideOpen3}
                    isAsideOpen4={isAsideOpen4}
                    onEditorReady={handleEditorReady}
                    data={data}
                    onColorChange={handleColorChange}
                  />

                </div>
              </div>

              {/* info */}
              <div
                className={`main-feedback-side flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 
                        border-solid absolute  lg:fixed top-[60px] max-w-[80%] view-feedback-right-side border-r-0 ' `}
                style={{
                  width: isAsideOpen ? '350px' : '350px',
                  transform: isAsideOpen ? 'none' : 'none',
                  right: isAsideOpen ? '51px' : '0px',
                  marginRight: isAsideOpen ? '0px' : '-350px',
                  // transition: 'all 0.3s ease-in-out',

                  height: isAsideOpen ? 'calc(100vh - -60px)' : '',
                  ...(window.innerWidth <= 768 && {
                    width: isAsideOpen ? '300px' : '300px',
                    marginRight: isAsideOpen ? '0' : '-300px',
                    // transition: 'all 0.3s ease-in-out'
                  }),
                }}
              >
                <div className='h-full'>
                  <div className='h-auto flex flex-col justify-stretch min-h-[3rem] 
                                           border-b-0 border-l-0 border-solid border-light-gray-200 p-[1.5rem] pt-[0.9rem] items-start gap-4'>
                    <div className=" flex items-start justify-center w-full">
                      <div className="mx-auto w-full">
                        <p className="text-center text-wedges-gray text-sm" />
                        <div className="flex items-center max-w-full w-fit mb-5 font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] ml-[-12px] py-[5px] gap-2">
                          <span className="text-[#52555a] capitalize">#{data?.data?.doc_ref_id}</span>
                        </div>
                        <div className="mb-2 mx-[-6px]">
                          {/* <a
                            href="#"
                            onClick={toggleCollapse}
                            className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                          >

                            Details

                            <div className={`${collapsed ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                              <svg
                                width={24}
                                height={24}
                                fill="none"
                                className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                  d="M15.25 10.75 12 14.25l-3.25-3.5"
                                />
                              </svg>
                            </div>
                          </a> */}

                          {/* <AnimatePresence initial={false}>
                            {!collapsed && (
                              <motion.div
                                initial={{ height: 0, overflow: 'hidden', backgroundColor: 'white' }}
                                animate={{ height: 'auto', overflow: 'inherit', backgroundColor: 'white' }}
                                exit={{ height: 0, overflow: 'hidden', backgroundColor: 'white' }}
                                transition={{ height: { duration: 0.5 }, overflow: 'hidden', backgroundColor: 'red' }}
                              > */}
                          <div className="pr-4 pl-[0px]">
                            <div className='w-full'>
                              <div className="w-full flex items-center justify-between lg:justify-start py-1">
                                <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[30%]">
                                  Channels
                                </div>


                                <Listbox value={selected} onChange={handleChangeChannel}>
                                  {({ open }) => (
                                    <div className="relative w-[70%]">
                                      <ListboxButton className="relative justify-center flex items-center cursor-pointer capitalize rounded-md bg-white py-1 px-2 text-left text-[12px] text-[#52555a] font-medium bg-transparent border-solid hover:!border-light-gray-200 border border-white">
                                        <div className="flex items-center gap-2">

                                          {selected ? (
                                            <span className={` gap-2 truncate capitalize flex items-center`}>
                                              <span className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center 
                                              rounded-full border-none text-[0.625rem]
                                               font-medium text-white capitalize bg-[${selected.color}]`}></span>
                                              {selected.name}
                                            </span>
                                          ) : (
                                            "Select a team" // Placeholder if no team is selected
                                          )}
                                        </div>
                                      </ListboxButton>
                                      <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                        <ListboxOptions className="absolute z-10 w-[160px] left-[-50px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {teams && teams.data ? teams.data.map((board: any) => (
                                            <Listbox.Option
                                              key={board.id}
                                              className={({ active }) =>
                                                classNames(
                                                  active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                  !active ? 'text-dark-black-100 text-[13px]' : '',
                                                  'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                )
                                              }
                                              value={board}
                                            >
                                              {({ selected }) => (
                                                <div className="flex items-center gap-2">
                                                  <span
                                                    className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full border-none text-[0.625rem]
                                                             font-medium text-white capitalize bg-[${board.color}]`}
                                                  />
                                                  <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full  overflow-hidden text-ellipsis text-start')}>
                                                    {board?.name}
                                                  </span>
                                                </div>
                                              )}
                                            </Listbox.Option>
                                          )) : (
                                            <div className="text-center py-2 text-gray-500">No data available</div>
                                          )}
                                        </ListboxOptions>
                                      </Transition>
                                    </div>
                                  )}
                                </Listbox>


                              </div>
                              <div className="w-full flex items-center justify-between lg:justify-start py-1">
                                <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[30%]">
                                  Status
                                </div>
                                <div className="relative w-[70%]">
                                  <span className='flex items-center gap-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px]
                                  w-full  overflow-hidden text-ellipsis text-start font-medium'>

                                    {data?.data?.doc_status === 'completed' ? (
                                      <span
                                        className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize "
                                      // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                      >
                                        {data?.data?.doc_status}
                                      </span>
                                    ) : data?.data?.doc_status === 'rejected' ? (
                                      <span
                                        className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 capitalize"
                                      // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                      >
                                        {data?.data?.doc_status}
                                      </span>
                                    ) : data?.data?.doc_status === 'terminated' ? (
                                      <span
                                        className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10 capitalize"
                                      // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                      >
                                        {data?.data?.doc_status}
                                      </span>
                                    ) : data?.data?.doc_status === 'expired' ? (
                                      <span
                                        className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 capitalize"
                                      // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                      >
                                        {data?.data?.doc_status}
                                      </span>
                                    ) : data?.data?.doc_status === 'pending' ? (
                                      <span
                                        className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize"
                                      >
                                        {data?.data?.doc_status}
                                      </span>
                                    ) : data?.data?.doc_status === 'draft' ? (
                                      <span
                                        className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 capitalize"
                                      >
                                        {data?.data?.doc_status}
                                      </span>
                                    ) :
                                      null}
                                  </span>
                                </div>
                              </div>
                              <div className="w-full flex items-center justify-between lg:justify-start py-1">
                                <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[30%]">
                                  Sub  Status
                                </div>
                                <div className="relative w-[70%]">
                                  <span className='flex items-center gap-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px]
                                  w-full  overflow-hidden text-ellipsis text-start font-medium'>

                                    {
                                      data?.data?.doc_sub_status === 'completed-view' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize "
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {data?.data?.doc_sub_status}
                                        </span>
                                      ) : data?.data?.doc_sub_status === 'completed-accept' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize "
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {data?.data?.doc_sub_status}
                                        </span>
                                      ) : data?.data?.doc_sub_status === 'completed-sign' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize "
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {data?.data?.doc_sub_status}
                                        </span>
                                      ) : data?.data?.doc_sub_status === 'completed-payment' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize "
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {data?.data?.doc_sub_status}
                                        </span>
                                      ) : data?.data?.doc_sub_status === 'pending-accept' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize"
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {data?.data?.doc_sub_status}
                                        </span>
                                      ) : data?.data?.doc_sub_status === 'pending-sign' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize"
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {data?.data?.doc_sub_status}
                                        </span>
                                      ) : data?.data?.doc_sub_status === 'pending-payment' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize"
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {data?.data?.doc_sub_status}
                                        </span>
                                      ) : data?.data?.doc_sub_status === 'pending-approval' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize"
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {data?.data?.doc_sub_status}
                                        </span>
                                      ) : data?.data?.doc_sub_status === 'sent' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10 capitalize"
                                        // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                                        >
                                          {data?.data?.doc_sub_status}
                                        </span>
                                      ) : data?.data?.doc_sub_status === 'draft' ? (
                                        <span
                                          className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 capitalize"
                                        >
                                          {data?.data?.doc_sub_status}
                                        </span>
                                      ) :
                                        null}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* </motion.div>
                            )}
                          </AnimatePresence> */}
                        </div>

                        <div className=' mx-[-24px] min-w-[348px] w-full'>
                          <div className="w-full border-t border-l-0  border-solid border-light-gray-200 px-[1rem] pt-[1rem]">
                            <div className="w-full flex items-center justify-between lg:justify-start py-1">
                              <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[35%]">
                                Created
                              </div>
                              <div className="relative w-[65%]">
                                <span className="text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a] text-start break-words font-medium">
                                  {createdTimeAgoSidebar && createdTimeAgoSidebar} ago
                                </span>
                              </div>
                            </div>

                            <div className="w-full flex items-center justify-between lg:justify-start py-1">
                              <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[35%]">
                                Updated
                              </div>
                              <div className="relative w-[65%]">
                                <span className="text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a] text-start break-words font-medium">
                                  {updatedTimeAgoSidebar && updatedTimeAgoSidebar} ago
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='flex justify-start w-full mt-4 ml-[-8px]'>
                          <Link to=""
                            onClick={() => handleConfirmDelete()}
                            className="underline text-[13px] text-[#b91c1c]">Delete</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              {/* inputs */}
              <div
                className={`main-feedback-side flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 
                        border-solid absolute lg:fixed top-[60px] max-w-[80%] view-feedback-right-side border-r-0 ' `}
                style={{
                  width: isAsideOpen1 ? '350px' : '350px',
                  transform: isAsideOpen1 ? 'none' : 'none',
                  right: isAsideOpen1 ? '51px' : '0px',
                  marginRight: isAsideOpen1 ? '0' : '-350px',
                  // transition: 'all 0.3s ease-in-out',
                  height: isAsideOpen1 ? 'calc(100vh - (60px))' : '',
                  ...(window.innerWidth <= 768 && {
                    width: isAsideOpen1 ? '300px' : '300px',
                    marginRight: isAsideOpen1 ? '0' : '-300px',
                    // transition: 'all 0.3s ease-in-out'
                  }),
                }}
              >
                <div className='h-full'>
                  <div className='h-auto flex flex-col justify-stretch min-h-[3rem] 
                                           border-b-0 border-l-0 border-solid border-light-gray-200 pt-[0.9rem] items-start gap-4'>
                    <div className=" flex items-start justify-center w-full">
                      <div className="mx-auto w-full px-[1.5rem]">
                        <p className="text-center text-wedges-gray text-sm" />
                        <div className="flex items-center max-w-full w-fit mb-5 font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] mx-[-6px] px-[0.5rem] py-[5px] gap-2">
                          <span className="text-[#52555a] capitalize">    Inputs</span>
                        </div>

                        <div className="variable-list overflow-auto flex flex-col gap-y-3">
                          {variables.map((variable, index) => (
                            <div key={index} className="flex flex-col gap-y-2">
                              <div
                                className={`flex-auto ${expandedVariable === variable.name ? 'rounded-t-md' : 'rounded-md'} p-3 cursor-pointer ring-1 ring-inset ring-gray-200 flex justify-between`}

                              >
                                <div className='flex flex-col gap-y-[4px]'>
                                  <div className='flex items-center gap-1'>
                                    <span className='text-dark-black-200 text-[12px]'>{variableType.name}</span>
                                    <span className="ml-1 bg-blue-50 text-blue-700 ring-1 ring-inset ring-blue-700/10 rounded-full w-4 h-4 flex items-center justify-center text-xs font-medium">
                                      {variable.count}
                                    </span>
                                  </div>

                                  <span className="text-[#52555a] text-[0.8125rem] font-medium leading-[1.2] capitalize">{variable.name} </span>

                                  <span className="inline-flex w-fit items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                                    Username
                                  </span>
                                  {/* <span className="inline-flex w-fit items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                  test12@gmail.com
                                  </span> */}
                                </div>
                                <div className='flex items-start gap-x-3'>
                                  <svg
                                    // onClick={setSpanColorByName}
                                    onClick={() => handleColorChange('#ff0000')}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="icon icon-tabler icons-tabler-outline icon-tabler-edit  w-[1rem] h-[1rem] text-[#808080]"
                                  >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                    <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                    <path d="M16 5l3 3" />
                                  </svg>

                                  <span onClick={() => toggleAccordion(variable.name)}>
                                    <ChevronDownIcon />
                                  </span>
                                </div>

                              </div>

                              {/* Accordion content */}
                              {expandedVariable === variable.name && (
                                <div className="p-3 ring-1 ring-inset ring-gray-200 rounded-b-md mt-[-9px]">

                                  {/* <p>Details for {variable}</p> */}
                                  <div className="w-full pt-[1rem]">
                                    <div className="w-full flex items-center justify-between lg:justify-start py-1">
                                      {/* <div className="mr-2 py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[35%]">
                                        Input type
                                      </div> */}
                                      <div className="relative w-[100%]">
                                        <Listbox value={variableType} onChange={setVariableType}>
                                          {({ open }) => (
                                            <div>
                                              <label
                                                className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
          before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
           peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
           after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
          before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                              >
                                                Input type

                                              </label>
                                              <ListboxButton
                                                className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
          focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
           disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                              >
                                                <div className='flex items-center justify-between'>
                                                  <span
                                                    className="flex items-center truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
           overflow-hidden text-ellipsis text-start font-medium"
                                                  >
                                                    {variableType.name}

                                                  </span>
                                                  <ChevronDownIcon />
                                                </div>
                                              </ListboxButton>
                                              <Transition
                                                show={open}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                              >
                                                <ListboxOptions
                                                  className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                >
                                                  {variableTypeList.map((board: any) => (
                                                    <Listbox.Option
                                                      key={board.id}
                                                      className={({ active }) =>
                                                        classNames(
                                                          active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                          !active ? 'text-dark-black-100 text-[13px]' : '',
                                                          'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                        )
                                                      }
                                                      value={board}
                                                    >
                                                      {({ selected }) => (
                                                        <span
                                                          className={classNames(
                                                            selected ? 'font-semibold' : 'font-normal',
                                                            'block truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full overflow-hidden text-ellipsis text-start'
                                                          )}
                                                        >
                                                          {board?.name}
                                                        </span>

                                                      )}
                                                    </Listbox.Option>
                                                  ))}
                                                </ListboxOptions>
                                              </Transition>
                                            </div>
                                          )}
                                        </Listbox>
                                      </div>
                                    </div>
                                    <div className="w-full text-[12px] flex items-center justify-between lg:justify-start py-1 gap-x-4 pt-4">

                                      <label className='w-[32%] font-normal text-dark-black-200 text-[13px] py-1 px-0 '>  Assign to </label>



                                      <nav className='relative w-[68%] h-full bg-[#f4f5f7] rounded-md p-[6px]' aria-label='Tabs'>
                                        {tabs.map((tab) => (
                                          <motion.a
                                            key={tab.name}
                                            onClick={() => setOpenFeedbackTab(tab.name)}
                                            href={tab.href}
                                            initial="hidden"
                                            animate="visible"
                                            variants={variants}
                                            transition={{ duration: 0.3 }}
                                            className={classNames(
                                              tab.name === openFeedbackTab
                                                ? 'border-none bg-[#4a5562] rounded-md text-[#fff]'
                                                : 'py-1 w-1/2 cursor-pointer select-none focus:outline-none',
                                              'py-1 w-1/2 cursor-pointer select-none group inline-flex capitalize items-center text-[12px] font-medium rounded-md justify-center'
                                            )}
                                          >
                                            <span>{tab.name}</span>
                                          </motion.a>
                                        ))}
                                      </nav>


                                    </div>
                                    {openFeedbackTab === 'team' && (
                                      <motion.div
                                        className="mt-4"
                                        initial={{ opacity: 1, y: 0 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.8 }}
                                      >
                                        <div className="relative w-[100%]">
                                          <Listbox value={variableType1} onChange={setVariableType1}>
                                            {({ open }) => (
                                              <div>
                                                <label
                                                  className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
          before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
           peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
           after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
          before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                                >
                                                  Input type

                                                </label>
                                                <ListboxButton
                                                  className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
                                                 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
                                                        disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                                >
                                                  <div className='flex items-center justify-between'>
                                                    <span
                                                      className="flex items-center gap-x-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
                                                        overflow-hidden text-ellipsis text-start font-medium"
                                                    >
                                                      {variableType1 ? variableType1.name : "Select an option"}
                                                    </span>
                                                    <ChevronDownIcon />
                                                  </div>
                                                </ListboxButton>
                                                <Transition
                                                  show={open}
                                                  leave="transition ease-in duration-100"
                                                  leaveFrom="opacity-100"
                                                  leaveTo="opacity-0"
                                                >
                                                  <ListboxOptions
                                                    className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                  >
                                                    {baseMemberDetailsArray.length > 0 ? (
                                                      baseMemberDetailsArray.map((board: any) => (
                                                        <Listbox.Option
                                                          key={board.id}
                                                          className={({ active }) =>
                                                            classNames(
                                                              active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                              !active ? 'text-dark-black-100 text-[13px]' : '',
                                                              'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                            )
                                                          }
                                                          value={board}

                                                        >

                                                          {({ selected }) => (
                                                            <span
                                                              className={classNames(
                                                                selected ? 'font-semibold' : 'font-normal',
                                                                'flex items-center gap-x-2 truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full overflow-hidden text-ellipsis text-start'
                                                              )}
                                                            >
                                                              {
                                                                board.profile_pic ? (
                                                                  <img
                                                                    src={`${process.env.REACT_APP_API_BASE_URL}/${board.profile_pic}`}
                                                                    alt={`${board.name}'s profile`}
                                                                    className="w-5 h-5 object-cover rounded-full"
                                                                  />
                                                                ) : (
                                                                  <span className="w-5 h-5 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                                                    {board.name.split(' ').map((word) => word.charAt(0)).join('').toUpperCase()}
                                                                  </span>
                                                                )
                                                              }

                                                              {board?.name}
                                                            </span>

                                                          )}
                                                        </Listbox.Option>
                                                      ))
                                                    ) : (
                                                      <div className="px-3 py-2 text-[13px] text-gray-500 text-center">
                                                        Nothing here
                                                      </div>
                                                    )
                                                    }
                                                  </ListboxOptions>
                                                </Transition>
                                              </div>
                                            )}
                                          </Listbox>
                                        </div>

                                      </motion.div>
                                    )}
                                    {openFeedbackTab === 'externally' && (
                                      <motion.div
                                        className="mt-4"
                                        initial={{ opacity: 1, y: 0 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.8 }}
                                      >
                                        <div className="relative">
                                          <label
                                            htmlFor="item_customer_email_id"
                                            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                          >
                                            Email
                                          </label>
                                          <input
                                            id="item_customer_email_id"
                                            name="item_customer_email_id"
                                            maxLength={30}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}  // Corrected here
                                            type="email"
                                            className="block w-full rounded-md border-0 py-2 text-[#52555a] text-[13px] shadow-sm ring-1 
          ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                          />
                                        </div>

                                      </motion.div>
                                    )}
                                    <div className="w-full flex items-center justify-between lg:justify-start py-1 mt-3 gap-x-4">
                                      <div className="py-1 px-0 font-normal text-dark-black-200 text-[13px] w-[32%] ">
                                        Is mandatory
                                      </div>
                                      <div className="relative w-[68%] flex items-center">
                                        <Switch
                                          checked={isEnabled}
                                          onChange={handleToggle}
                                          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                        >
                                          <span className="sr-only">Use setting</span>
                                          <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                                          <span
                                            aria-hidden="true"
                                            className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out group-data-[checked]:bg-indigo-600"
                                          />
                                          <span
                                            aria-hidden="true"
                                            className="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                          />
                                        </Switch>
                                      </div>
                                    </div>
                                    <div className='flex items-center justify-between mt-3'>
                                      <button className='underline text-[13px] text-[#b91c1c]' onClick={() => handleDeleteClick(variable.name, variable.count)}>
                                        Delete
                                      </button>
                                      {/* Confirmation Dialog */}
                                      <Transition.Root show={showDeleteConfirmation1} as={Fragment}>
                                        <Dialog as="div" className="relative z-50" onClose={() => setShowDeleteConfirmation1(false)}>
                                          <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                          </Transition.Child>

                                          <div className="fixed inset-0 z-10 overflow-y-auto">
                                            <div className="flex min-h-full justify-center p-4 text-center items-center">
                                              <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                              >
                                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[500px] sm:p-6">
                                                  <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                      Are you sure you want to delete your Variable?
                                                    </p>
                                                  </div>
                                                  <div className="mt-5 sm:mt-4 flex gap-2 justify-end">
                                                    <button
                                                      type="button"
                                                      onClick={() => handledeleteSpanByName(variable.name,)}
                                                      className="w-fit inline-flex justify-center rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-indigo-600 focus:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
                                                    >
                                                      Delete
                                                    </button>
                                                    <button
                                                      type="button"
                                                      onClick={() => setShowDeleteConfirmation1(false)}
                                                      className="mt-3 inline-flex leading-6 w-fit justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                    >
                                                      Cancel
                                                    </button>
                                                  </div>
                                                </Dialog.Panel>
                                              </Transition.Child>
                                            </div>
                                          </div>
                                        </Dialog>
                                      </Transition.Root>
                                      <button className="flex w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm
                     hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      //  onClick={handleEnterClick}
                                      // onClick={(e) => setAiData(e)}
                                      >
                                        Enter
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

              {/* activity */}
              <div
                className={`main-feedback-side flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 
                        border-solid absolute  lg:fixed top-[60px] max-w-[80%] view-feedback-right-side border-r-0 ' `}
                style={{
                  width: isAsideOpen2 ? '350px' : '350px',
                  transform: isAsideOpen2 ? 'none' : 'none',
                  right: isAsideOpen2 ? '51px' : '0px',
                  marginRight: isAsideOpen2 ? '0px' : '-350px',
                  // transition: 'all 0.3s ease-in-out',

                  height: isAsideOpen2 ? 'calc(100vh - -60px)' : '',
                  ...(window.innerWidth <= 768 && {
                    width: isAsideOpen2 ? '300px' : '300px',
                    marginRight: isAsideOpen2 ? '0' : '-300px',
                    // transition: 'all 0.3s ease-in-out'
                  }),
                }}
              >
                <div className='h-full'>
                  <div className='h-auto flex flex-col justify-stretch min-h-[3rem] 
                                           border-b-0 border-l-0 border-solid border-light-gray-200 p-[1rem] pt-[0.9rem] items-start gap-4'>
                    <div className=" flex items-start justify-center w-full">
                      <div className="mx-auto w-full">
                        <p className="text-center text-wedges-gray text-sm" />
                        <div className="flex items-center max-w-full w-fit mb-5 font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] ml-[-4px] py-[5px] gap-2">
                          <span className="text-[#52555a] capitalize"> Activity</span>
                        </div>
                        <div className="mb-2 mx-[-6px]">
                          {/* <a
                            href="#"
                            onClick={toggleCollapse2}
                            className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                          >

                            Details

                            <div className={`${collapsed2 ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                              <svg
                                width={24}
                                height={24}
                                fill="none"
                                className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                  d="M15.25 10.75 12 14.25l-3.25-3.5"
                                />
                              </svg>
                            </div>
                          </a> */}

                          <ul role="list" className="space-y-6 mt-[0.9rem]">
                            <li className="relative flex gap-x-2 flex-col">
                              <div className=' flex gap-x-2'>
                                <div className="-bottom-6 absolute left-0 top-0 flex w-6 justify-center">
                                  <div className="w-px bg-gray-200" />
                                </div>
                                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                  <div className="h-1 w-1 rounded-full bg-transparent ring-2 ring-[#7d7f83]" />
                                </div>

                                <span className="font-medium text-dark-black-100 mr-1 flex items-center text-[13px]">
                                  Vikas Rishishwar
                                  <div className="text-dark-black-200 ml-1 flex items-center gap-1 font-normal text-[13px]">
                                    {" "}
                                    created the ticket
                                  </div>
                                </span>
                              </div>
                              <span className=' ml-[32px]  block text-[13px] leading-4 whitespace-normal max-w-[140px] w-full text-[#52555a] text-start break-words font-medium'>
                                1 month ago
                              </span>
                            </li>
                            <li className="relative flex gap-x-2">
                              <div className="-bottom-6 absolute left-0 top-0 flex w-6 justify-center">
                                <div className="w-px bg-gray-200" />
                              </div>
                              <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                <div className="h-1 w-1 rounded-full bg-transparent ring-2 ring-[#7d7f83]" />
                              </div>

                              <span className="font-medium text-dark-black-100 mr-1 flex items-center text-[13px]">
                                test12@gmail.com
                                <div className="text-dark-black-200 ml-1 flex items-center gap-1 font-normal text-[13px]">
                                  {" "}
                                  updated the email
                                </div>
                              </span>
                            </li>
                            <li className="relative flex gap-x-2">
                              <div className="-bottom-6 absolute left-0 top-0 flex w-6 justify-center">
                                <div className="w-px bg-gray-200" />
                              </div>
                              <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                <div className="h-1 w-1 rounded-full bg-transparent ring-2 ring-[#7d7f83]" />
                              </div>

                              <span className="font-medium text-dark-black-100 mr-1 flex items-center text-[13px]">
                                Vikas Rishishwar
                                <div className="text-dark-black-200 ml-1 flex items-center gap-1 font-normal text-[13px]">
                                  {" "}
                                  created the ticket
                                </div>
                              </span>
                            </li>
                            <li className="relative flex gap-x-2">
                              <div className="-bottom-6 absolute left-0 top-0 flex w-6 justify-center">
                                <div className="w-px bg-gray-200" />
                              </div>
                              <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                <div className="h-1 w-1 rounded-full bg-transparent ring-2 ring-[#7d7f83]" />
                              </div>

                              <span className="font-medium text-dark-black-100 mr-1 flex items-center text-[13px]">
                                Vikas Rishishwar
                                <div className="text-dark-black-200 ml-1 flex items-center gap-1 font-normal text-[13px]">
                                  {" "}
                                  created the ticket
                                </div>
                              </span>
                            </li>
                            <li className="relative flex gap-x-2">
                              <div className="-bottom-6 absolute left-0 top-0 flex w-6 justify-center">
                                {/* <div className="w-px bg-gray-200" /> */}
                              </div>
                              <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                <div className="h-1 w-1 rounded-full bg-transparent ring-2 ring-[#7d7f83]" />
                              </div>

                              <span className="font-medium text-dark-black-100 mr-1 flex items-center text-[13px]">
                                Vikas Rishishwar
                                <div className="text-dark-black-200 ml-1 flex items-center gap-1 font-normal text-[13px]">
                                  {" "}
                                  created the ticket
                                </div>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* chat */}
              <div
                className={`main-feedback-side flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 
                        border-solid absolute  lg:fixed top-[60px] max-w-[80%] view-feedback-right-side border-r-0 ' `}
                style={{
                  width: isAsideOpen3 ? '350px' : '350px',
                  transform: isAsideOpen3 ? 'none' : 'none',
                  right: isAsideOpen3 ? '51px' : '0px',
                  marginRight: isAsideOpen3 ? '0px' : '-350px',

                  height: isAsideOpen3 ? 'calc(100vh - -60px)' : '',
                  ...(window.innerWidth <= 768 && {
                    width: isAsideOpen3 ? '300px' : '300px',
                    marginRight: isAsideOpen3 ? '0' : '-300px',
                  }),
                }}
              >
                <div className='h-full'>
                  <div className='h-auto flex flex-col justify-stretch min-h-[3rem] 
                       border-b-0 border-l-0 border-solid border-light-gray-200 p-[1rem] pt-[0.9rem] items-start gap-4'>
                    <div className=" flex items-start justify-center w-full">
                      <div className="mx-auto w-full">
                        <p className="text-center text-wedges-gray text-sm" />
                        <div className="flex items-center max-w-full w-fit mb-5 font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] ml-[-4px] py-[5px] gap-2">
                          <span className="text-[#52555a] capitalize">Chat</span>
                        </div>
                        <>
                          {/* Chat Container */}
                          <div
                            style={{ minWidth: "100%", display: "table", height: 'calc(100vh - 180px)' }}
                          >
                            {/* Chat Message AI */}
                            <div className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover">
                              <div className=''>
                                <div className='flex items-center gap-3'>
                                  <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                    <span className="text-[7px] font-medium text-gray-700" />V
                                  </div>
                                  <p className="leading-relaxed">
                                    <span className=" text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">AI
                                      <div className="flex items-center gap-[6px] ml-[6px]">
                                        <span
                                          className="flex h-[4px] w-[4px] shrink-0 items-center justify-center rounded-full
                                    border-none text-[0.625rem] font-normal text-white capitalize bg-[#6b7280]"
                                        />
                                        <time
                                          dateTime="2024-11-08T10:23:08.000Z"
                                          className="flex-none text-xs leading-5 text-gray-500"
                                        >
                                          less than 10 seconds ago
                                        </time>
                                      </div>
                                    </span>
                                  </p>
                                </div>
                                <span className='text-sm leading-6 text-gray-500'>
                                  Hi, how can
                                  I help you today?

                                </span>
                              </div>
                              <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                <CustomMenuDropdown
                                  icon={
                                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                      <circle cx="12" cy="12" r="1"></circle>
                                      <circle cx="12" cy="5" r="1"></circle>
                                      <circle cx="12" cy="19" r="1"></circle>
                                    </svg>
                                  }
                                  altText="User profile"
                                  sections={dropdownGroups}
                                  menuItemsClassName="left-auto"
                                />
                              </div>
                            </div>
                          </div>
                          {/* Input box  */}
                          <div className="flex items-center pt-0">
                            <form className="flex items-center justify-center w-full space-x-2">
                              <input
                                className="flex w-full grow border px-4 py-2 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] focus:ring-indigo-500 transition-colors duration-100 placeholder:text-surface-500
                                outline-indigo-600   focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                             border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                                placeholder="Type your message"
                                defaultValue=""
                              />
                              <button
                                className="flex w-fit justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm
                             hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                Send
                              </button>
                            </form>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* action */}
              <div
                className={`main-feedback-side flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 
                        border-solid absolute  lg:fixed top-[60px] max-w-[80%] border-r-0 !overflow-y-auto overflow-x-hidden ' `}
                style={{
                  width: isAsideOpen4 ? '350px' : '350px',
                  transform: isAsideOpen4 ? 'none' : 'none',
                  right: isAsideOpen4 ? '51px' : '0px',
                  marginRight: isAsideOpen4 ? '0px' : '-350px',
                  // transition: 'all 0.3s ease-in-out',

                  height: isAsideOpen4 ? 'calc(100vh - +60px)' : '',
                  ...(window.innerWidth <= 768 && {
                    width: isAsideOpen4 ? '300px' : '300px',
                    marginRight: isAsideOpen4 ? '0' : '-300px',
                    // transition: 'all 0.3s ease-in-out'
                  }),
                }}
              >
                <div className='h-full'>
                  <div className='h-auto flex flex-col justify-stretch min-h-[3rem] 
                                           border-b-0 border-l-0 border-solid border-light-gray-200 p-[1rem] pt-[0.9rem] items-start gap-4'>
                    <div className=" flex items-start justify-center w-full">
                      <div className="mx-auto w-full">
                        <p className="text-center text-wedges-gray text-sm" />
                        <div className="flex items-center max-w-full w-fit mb-5 font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] ml-[-4px] py-[5px] gap-2">
                          <span className="text-[#52555a] capitalize"> Action</span>
                        </div>
                        <div className="mb-2">
                          <div className='flex justify-between items-center'>
                            <a
                              href="#"
                              onClick={toggleCollapse4}
                              className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                            >
                              <span className='flex mr-[6px] h-[8px] w-[8px] shrink-0
                            items-center justify-center rounded-[20px] border-none
                             bg-[#15803d]'>

                              </span>
                              Collaborator

                              <div className={`${collapsed4 ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                                <svg
                                  width={24}
                                  height={24}
                                  fill="none"
                                  className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M15.25 10.75 12 14.25l-3.25-3.5"
                                  />
                                </svg>
                              </div>
                            </a>
                            <button className='underline text-[13px] text-[#266df0]' onClick={handleNewAction}>
                              Add
                            </button>

                          </div>
                          {
                            newAction &&
                            <>
                              <nav className='relative w-[100%] my-5 h-full bg-[#f4f5f7] rounded-md p-[6px]' aria-label='Tabs'>
                                {tabs1.map((tab) => (
                                  <motion.a
                                    key={tab.name}
                                    onClick={() => setOpenActionTab(tab.name)}
                                    href={tab.href}
                                    initial="hidden"
                                    animate="visible"
                                    variants={variants}
                                    transition={{ duration: 0.3 }}
                                    className={classNames(
                                      tab.name === openActionTab
                                        ? 'border-none bg-[#4a5562] rounded-md text-[#fff]'
                                        : 'py-1 w-1/2 cursor-pointer select-none focus:outline-none',
                                      'py-1 w-1/2 cursor-pointer select-none group inline-flex capitalize items-center text-[12px] font-medium rounded-md justify-center'
                                    )}
                                  >
                                    <span>{tab.name}</span>
                                  </motion.a>
                                ))}

                              </nav>
                              {openActionTab === 'internal team' && (
                                <motion.div
                                  className="mt-4"
                                  initial={{ opacity: 1, y: 0 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ duration: 0.8 }}
                                >
                                  <div className="relative w-[100%]">
                                    <Listbox value={variableType1} onChange={setVariableType1}>
                                      {({ open }) => {
                                        setIsDropdownOpen(open);
                                        return (
                                          <div>
                                            <label
                                              className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
                  before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
                  peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
                  after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
                  before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                            >
                                              Input type
                                            </label>
                                            <Listbox.Button
                                              className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
                focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
                disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                            >
                                              <div className='flex items-center justify-between'>
                                                <span
                                                  className="flex items-center gap-x-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
                    overflow-hidden text-ellipsis text-start font-medium capitalize"
                                                >
                                                  {variableType1 ? variableType1.name : "Select an option"}

                                                </span>
                                                <ChevronDownIcon />
                                              </div>
                                            </Listbox.Button>
                                            <Transition
                                              show={open}
                                              leave="transition ease-in duration-100"
                                              leaveFrom="opacity-100"
                                              leaveTo="opacity-0"
                                            >
                                              <Listbox.Options
                                                className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                              >
                                                {baseMemberDetailsArray.length > 0 ? (
                                                  baseMemberDetailsArray.map((board: any) => (
                                                    <Listbox.Option
                                                      key={board.id}
                                                      className={({ active }) =>
                                                        classNames(
                                                          active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                          !active ? 'text-dark-black-100 text-[13px]' : '',
                                                          'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                        )
                                                      }
                                                      value={board}
                                                    >
                                                      {({ selected }) => (
                                                        <span
                                                          className={classNames(
                                                            selected ? 'font-semibold' : 'font-normal',
                                                            'flex items-center gap-x-2 truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full overflow-hidden text-ellipsis text-start'
                                                          )}
                                                        >
                                                          {
                                                            board.profile_pic ? (
                                                              <img
                                                                src={`${process.env.REACT_APP_API_BASE_URL}/${board.profile_pic}`}
                                                                alt={`${board.name}'s profile`}
                                                                className="w-5 h-5 object-cover rounded-full"
                                                              />
                                                            ) : (
                                                              <span className="w-5 h-5 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                                                {board.name.split(' ').map((word) => word.charAt(0)).join('').toUpperCase()}
                                                              </span>
                                                            )
                                                          }
                                                          {board?.name}
                                                        </span>
                                                      )}
                                                    </Listbox.Option>
                                                  ))
                                                ) : (
                                                  <div className="px-3 py-2 text-[13px] text-gray-500 text-center">
                                                    Nothing here
                                                  </div>
                                                )}
                                              </Listbox.Options>
                                            </Transition>
                                          </div>
                                        );
                                      }}
                                    </Listbox>
                                  </div>
                                  <div className='mt-5 flex justify-end'>
                                    <button
                                      onClick={handleActionInternalTeam}
                                      className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                      {isActionLoading1 ? (
                                        <>
                                          Create action
                                          <div className="simple-spinner">
                                            <span></span>
                                          </div>
                                        </>
                                      ) : (
                                        'Create action'
                                      )}
                                    </button>
                                  </div>
                                </motion.div>
                              )}
                              {openActionTab === 'external contact' && (
                                <motion.div
                                  className="mt-4"
                                  initial={{ opacity: 1, y: 0 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ duration: 0.8 }}
                                >
                                  <div className="relative">
                                    <button
                                      type="button"
                                      className="flex items-center justify-between h-[40px] px-4 w-full py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                                      onClick={() => setIsOpen(!isOpen)}
                                    >
                                      <span className="mr-2 text-[13px] text-[#374151] new-action">
                                        {
                                          selectedRecipient
                                            ? (
                                              <span className=" font-semibold">
                                                {selectedRecipient.contact_first_name || ''}
                                                {selectedRecipient.contact_last_name || ''}
                                                {!selectedRecipient.contact_last_name && !selectedRecipient.contact_first_name || ' - '}
                                              </span>
                                            )
                                            : 'Start typing name or email'
                                        }
                                        {selectedRecipient?.contact_email && (
                                          <span className="text-[14px]">
                                            {selectedRecipient.contact_email}
                                          </span>
                                        )}

                                      </span>

                                      <svg
                                        className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${isOpen ? 'transform rotate-180' : ''}`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </button>
                                    {isOpen && (
                                      <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                          <div className="px-4 py-2">
                                            <input
                                              type="text"
                                              className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                              placeholder="Start typing name or email"
                                              value={search}
                                              onChange={handleSearchChange}
                                            />
                                          </div>
                                          <div className='max-h-[200px] overflow-auto'>
                                            {recipients.map((recipient, index) => (
                                              <a
                                                key={index}
                                                href="#"
                                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                onClick={() => handleRecipientSelect(recipient)} // Update the onClick to handle selection
                                              >
                                                <div className="flex items-center">
                                                  <span
                                                    className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-black capitalize bg-gray-300">
                                                    {
                                                      recipient?.contact_first_name && recipient?.contact_last_name
                                                        ? recipient.contact_first_name.charAt(0).toLowerCase() // Show the first letter of first name in lowercase
                                                        : recipient?.contact_last_name
                                                          ? recipient.contact_last_name.charAt(0).toLowerCase() // Show the first letter of last name in lowercase
                                                          : recipient?.contact_email
                                                            ? recipient.contact_email.charAt(0).toLowerCase() // Show the first letter of email in lowercase
                                                            : null // If none of the above exist, return null
                                                    }




                                                  </span>
                                                  <div className='flex flex-col gap-y-[2px] ml-2'>
                                                    <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis capitalize">{recipient.contact_first_name} {recipient.contact_last_name}</span>
                                                    <span className="text-[12px] text-[#374151] leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis">{recipient.contact_email}</span>
                                                  </div>
                                                </div>
                                              </a>
                                            ))}
                                          </div>
                                          <a href="#" onClick={handleNewActionEnteral} className="flex items-center gap-x-1 px-4 py-2 hover:bg-gray-100">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={24}
                                              height={24}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth={2}
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              className="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus w-[1rem] h-[1rem] text-[#ff591e]"
                                            >
                                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                              <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                              <path d="M9 12h6" />
                                              <path d="M12 9v6" />
                                            </svg>

                                            <span className='font-semibold text-[#ff591e] hover:text-[#ff591e]'>
                                              Create new recipient
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                    <div className='mt-5 flex justify-end'>
                                      <button
                                        onClick={handleActionExternalContact}
                                        className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                        {isActionLoading ? (
                                          <>
                                            Create action
                                            <div className="simple-spinner">
                                              <span></span>
                                            </div>
                                          </>
                                        ) : (
                                          'Create action'
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                  {
                                    newActionEnteral &&
                                    <>
                                      <div className="">
                                        <div className='w-full mt-5'>
                                          <div className='flex gap-y-[24px] flex-col'>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_first_name"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                First Name
                                              </label>
                                              <input
                                                id="contact_first_name"
                                                name="contact_first_name"
                                                type="text"
                                                maxLength={30}
                                                value={contactInfo.contact_first_name}
                                                onChange={handleInputChange}
                                                // placeholder="Jane Smith"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_last_name"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Last Name
                                              </label>
                                              <input
                                                id="contact_last_name"
                                                name="contact_last_name"
                                                type="text"
                                                maxLength={30}
                                                value={contactInfo.contact_last_name}
                                                onChange={handleInputChange}
                                                // placeholder="Jane Smith"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_email"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Email
                                              </label>
                                              <input
                                                id="contact_email"
                                                name="contact_email"
                                                value={contactInfo.contact_email}
                                                onChange={handleInputChange}
                                                maxLength={30}
                                                type="email"

                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_phone"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Phone no.
                                              </label>
                                              <input
                                                id="contact_phone"
                                                name="contact_phone"
                                                type="number"
                                                maxLength={30}
                                                value={contactInfo.contact_phone}
                                                onChange={handleInputChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                            ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_company"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Company
                                              </label>
                                              <input
                                                id="contact_company"
                                                name="contact_company"
                                                value={contactInfo.contact_company}
                                                onChange={handleInputChange}
                                                maxLength={30}
                                                type="text"
                                                // placeholder="1234567890"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_job_title"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Job Title
                                              </label>
                                              <input
                                                id="contact_job_title"
                                                name="contact_job_title"
                                                value={contactInfo.contact_job_title}
                                                onChange={handleInputChange}
                                                type="text"
                                                maxLength={1000}
                                                // placeholder="Enter meta value"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>


                                            <div className='w-auto flex justify-end'>
                                              <button
                                                type="button"
                                                onClick={handleDocumentContactSave}
                                                className=" flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                              // onClick={() => handleSubmitItemCustomer(companyInfo)}
                                              // disabled={isUpdating}
                                              >

                                                {contactLoading ? (
                                                  <>
                                                    Create contact
                                                    <div className="simple-spinner">
                                                      <span></span>
                                                    </div>
                                                  </>
                                                ) : (
                                                  'Create contact'
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  }



                                </motion.div>
                              )}
                            </>
                          }



                          {
                            !newAction &&
                            <AnimatePresence initial={false}>
                              {!collapsed4 && (
                                <motion.div
                                  initial={{ height: 0 }}
                                  animate={{ height: 'auto' }}
                                  exit={{ height: 0, }}
                                  transition={{ height: { duration: 0.5 }, }}
                                  className="overflow-hidden"
                                >
                                  {
                                    isActionList?.data?.collab?.internal.length > 0 &&
                                    <span className='font-normal text-dark-black-200 text-[12px]'>Internal - </span>
                                  }

                                  {isActionList?.data?.collab?.internal &&
                                    isActionList.data.collab.internal.map((internalAction, index) => {
                                      // Assuming `baseMemberDetails` is an array and you want to find the member matching `internal_user_id`
                                      const baseMember = baseMemberDetails?.data?.baseMembers?.find(
                                        (member) => member.id === internalAction.internal_user_id
                                      );

                                      return (
                                        <div
                                          key={internalAction.id || index}
                                          className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                        >
                                          <div className="flex items-center gap-3">
                                            <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                              <span className="text-[10px] font-medium text-gray-700">
                                                {baseMember?.initials || 'A'}
                                              </span>
                                            </div>
                                            <p className="leading-relaxed">
                                              <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                {baseMember?.name && ` ${baseMember.name}`}
                                              </span>
                                            </p>
                                          </div>
                                          <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                            <CustomMenuDropdown
                                              icon={
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="12" cy="12" r="1"></circle>
                                                  <circle cx="12" cy="5" r="1"></circle>
                                                  <circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                              }
                                              altText="User profile"
                                              sections={dropdownGroups1}
                                              menuItemsClassName="left-auto top-[10px]"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}

                                  {
                                    isActionList?.data?.collab?.external?.length > 0 && (
                                      <>
                                        {
                                          [
                                            ...(isActionList?.data?.collab?.external || []),
                                            ...(isActionList?.data?.collab?.external || [])
                                          ]
                                            .slice(0, 1) // Take only the first item
                                            .map((item, index) => {
                                              return (
                                                <div key={index} className="flex items-center space-x-2">
                                                  {item.external_contact_id && (
                                                    <span className="font-normal text-dark-black-200 text-[12px]">External - </span>
                                                  )}
                                                </div>
                                              );
                                            })
                                        }
                                      </>
                                    )
                                  }





                                  {
                                    isActionList?.data?.collab?.external?.length > 0 &&
                                    isActionList.data.collab.external.map((internalAction, index) => {
                                      const contact = internalAction.external_contact_id
                                        ? internalAction.contact
                                        : null; // Check if external_contact_id exists and assign contact

                                      if (!contact) {
                                        return null; // Skip rendering if contact is null
                                      }

                                      return (
                                        <div
                                          key={internalAction.id || index}
                                          className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                        >
                                          <div className="flex items-center gap-3">
                                            <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                              <span className="text-[10px] font-medium text-gray-700 capitalize">
                                                {
                                                  contact.contact_first_name
                                                    ? contact.contact_first_name.charAt(0).toLowerCase()
                                                    : contact.contact_last_name
                                                      ? contact.contact_last_name.charAt(0).toLowerCase()
                                                      : contact.contact_email
                                                        ? contact.contact_email.charAt(0).toLowerCase()
                                                        : ''
                                                }
                                              </span>
                                            </div>
                                            <div className="flex flex-col">
                                              <p className="leading-relaxed">
                                                <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                  {contact.contact_first_name && `${contact.contact_first_name} `}
                                                  {contact.contact_last_name && `${contact.contact_last_name} `}
                                                </span>
                                              </p>
                                              <p className="leading-relaxed">
                                                <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                  {contact.contact_email && `${contact.contact_email}`}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="[&>div]:shrink feedback-delete-btn-hover">
                                            <CustomMenuDropdown
                                              icon={
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="12" cy="12" r="1"></circle>
                                                  <circle cx="12" cy="5" r="1"></circle>
                                                  <circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                              }
                                              altText="User profile"
                                              sections={dropdownGroups1}
                                              menuItemsClassName="left-auto top-[5px]"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })
                                  }





                                </motion.div>
                              )}
                            </AnimatePresence>
                          }
                        </div>
                        <div className='bg-[#e5e7eb] w-[-webkit-fill-available] h-[1px] mx-[-20px] px-[20px] my-5'></div>
                        <div className="mb-2">
                          <div className='flex justify-between items-center'>
                            <a
                              href="#"
                              onClick={toggleCollapse5}
                              className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                            >
                              <span className='flex mr-[6px] h-[8px] w-[8px] shrink-0
                            items-center justify-center rounded-[20px] border-none
                             bg-[#1d4ed8]'>

                              </span>
                              Approval

                              <div className={`${collapsed5 ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                                <svg
                                  width={24}
                                  height={24}
                                  fill="none"
                                  className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M15.25 10.75 12 14.25l-3.25-3.5"
                                  />
                                </svg>
                              </div>
                            </a>
                            <button className='underline text-[13px] text-[#266df0]' onClick={handleNewAction1}>
                              Add
                            </button>
                          </div>

                          {
                            newAction1 &&
                            <>
                              <nav className='relative w-[100%] my-5 h-full bg-[#f4f5f7] rounded-md p-[6px]' aria-label='Tabs'>
                                {tabs2.map((tab) => (
                                  <motion.a
                                    key={tab.name}
                                    onClick={() => setOpenActionTab1(tab.name)}
                                    href={tab.href}
                                    initial="hidden"
                                    animate="visible"
                                    variants={variants}
                                    transition={{ duration: 0.3 }}
                                    className={classNames(
                                      tab.name === openActionTab1
                                        ? 'border-none bg-[#4a5562] rounded-md text-[#fff]'
                                        : 'py-1 w-1/2 cursor-pointer select-none focus:outline-none',
                                      'py-1 w-1/2 cursor-pointer select-none group inline-flex capitalize items-center text-[12px] font-medium rounded-md justify-center'
                                    )}
                                  >
                                    <span>{tab.name}</span>
                                  </motion.a>
                                ))}

                              </nav>
                              {openActionTab1 === 'internal team' && (
                                <motion.div
                                  className="mt-4"
                                  initial={{ opacity: 1, y: 0 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ duration: 0.8 }}
                                >
                                  <div className="relative w-[100%]">
                                    <Listbox value={variableType2} onChange={setVariableType2}>
                                      {({ open }) => (
                                        <div>
                                          <label
                                            className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
    before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
     peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
     after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
    before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                          >
                                            Input type

                                          </label>
                                          <ListboxButton
                                            className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
                                           focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
                                                  disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                          >
                                            <div className='flex items-center justify-between'>
                                              <span
                                                className="flex items-center gap-x-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
                                                  overflow-hidden text-ellipsis text-start font-medium"
                                              >
                                                {variableType2 ? variableType2.name : "Select an option"}
                                              </span>
                                              <ChevronDownIcon />
                                            </div>
                                          </ListboxButton>
                                          <Transition
                                            show={open}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <ListboxOptions
                                              className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                            >
                                              {baseMemberDetailsArray.length > 0 ? (
                                                baseMemberDetailsArray.map((board: any) => (
                                                  <Listbox.Option
                                                    key={board.id}
                                                    className={({ active }) =>
                                                      classNames(
                                                        active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                        !active ? 'text-dark-black-100 text-[13px]' : '',
                                                        'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                      )
                                                    }
                                                    value={board}
                                                  >
                                                    {({ selected }) => (
                                                      <span
                                                        className={classNames(
                                                          selected ? 'font-semibold' : 'font-normal',
                                                          'flex items-center gap-x-2 truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full overflow-hidden text-ellipsis text-start'
                                                        )}
                                                      >
                                                        {
                                                          board.profile_pic ? (
                                                            <img
                                                              src={`${process.env.REACT_APP_API_BASE_URL}/${board.profile_pic}`}
                                                              alt={`${board.name}'s profile`}
                                                              className="w-5 h-5 object-cover rounded-full"
                                                            />
                                                          ) : (
                                                            <span className="w-5 h-5 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                                              {board.name.split(' ').map((word) => word.charAt(0)).join('').toUpperCase()}
                                                            </span>
                                                          )
                                                        }
                                                        {board?.name}
                                                      </span>
                                                    )}
                                                  </Listbox.Option>
                                                ))
                                              ) : (
                                                <div className="px-3 py-2 text-[13px] text-gray-500 text-center">
                                                  Nothing here
                                                </div>
                                              )}
                                            </ListboxOptions>
                                          </Transition>
                                        </div>
                                      )}
                                    </Listbox>
                                  </div>
                                  <div className='mt-5 flex justify-end'>
                                    <button
                                      onClick={handleActionInternalTeam1}
                                      className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                      {isActionLoading2 ? (
                                        <>
                                          Create action
                                          <div className="simple-spinner">
                                            <span></span>
                                          </div>
                                        </>
                                      ) : (
                                        'Create action'
                                      )}
                                    </button>
                                  </div>
                                </motion.div>
                              )}
                              {openActionTab1 === 'external contact' && (
                                <motion.div
                                  className="mt-4"
                                  initial={{ opacity: 1, y: 0 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ duration: 0.8 }}
                                >
                                  <div className="relative">
                                    <button
                                      type="button"
                                      className="flex items-center justify-between h-[40px] px-4 w-full py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                                      onClick={() => setIsOpen1(!isOpen1)}
                                    >
                                      <span className="mr-2 text-[13px] text-[#374151] new-action">
                                        {
                                          selectedRecipient1
                                            ? (
                                              <span className=" font-semibold">
                                                {selectedRecipient1.contact_first_name || ''}
                                                {selectedRecipient1.contact_last_name || ''}
                                                {!selectedRecipient1.contact_last_name && !selectedRecipient1.contact_first_name || ' - '}
                                              </span>
                                            )
                                            : 'Start typing name or email'
                                        }
                                        {selectedRecipient1?.contact_email && (
                                          <span className="text-[14px]">
                                            {selectedRecipient1.contact_email}
                                          </span>
                                        )}

                                      </span>

                                      <svg
                                        className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${isOpen1 ? 'transform rotate-180' : ''}`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </button>
                                    {isOpen1 && (
                                      <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                          <div className="px-4 py-2">
                                            <input
                                              type="text"
                                              className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                              placeholder="Start typing name or email"
                                              value={search1}
                                              onChange={handleSearchChange1}
                                            />
                                          </div>
                                          <div className='max-h-[200px] overflow-auto'>
                                            {recipients1.map((recipient, index) => (
                                              <a
                                                key={index}
                                                href="#"
                                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                onClick={() => handleRecipientSelect1(recipient)} // Update the onClick to handle selection
                                              >
                                                <div className="flex items-center">
                                                  <span
                                                    className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-black capitalize bg-gray-300">
                                                    {
                                                      recipient?.contact_first_name && recipient?.contact_last_name
                                                        ? recipient.contact_first_name.charAt(0).toLowerCase() // Show the first letter of first name in lowercase
                                                        : recipient?.contact_last_name
                                                          ? recipient.contact_last_name.charAt(0).toLowerCase() // Show the first letter of last name in lowercase
                                                          : recipient?.contact_email
                                                            ? recipient.contact_email.charAt(0).toLowerCase() // Show the first letter of email in lowercase
                                                            : null // If none of the above exist, return null
                                                    }




                                                  </span>
                                                  <div className='flex flex-col gap-y-[2px] ml-2'>
                                                    <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis capitalize">{recipient.contact_first_name} {recipient.contact_last_name}</span>
                                                    <span className="text-[12px] text-[#374151] leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis">{recipient.contact_email}</span>
                                                  </div>
                                                </div>
                                              </a>
                                            ))}
                                          </div>
                                          <a href="#" onClick={handleNewActionEnteral1} className="flex items-center gap-x-1 px-4 py-2 hover:bg-gray-100">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={24}
                                              height={24}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth={2}
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              className="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus w-[1rem] h-[1rem] text-[#ff591e]"
                                            >
                                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                              <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                              <path d="M9 12h6" />
                                              <path d="M12 9v6" />
                                            </svg>

                                            <span className='font-semibold text-[#ff591e] hover:text-[#ff591e]'>
                                              Create new recipient
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                    <div className='mt-5 flex justify-end'>
                                      <button
                                        onClick={handleActionExternalContact1}
                                        className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                        {isActionLoading1 ? (
                                          <>
                                            Create action
                                            <div className="simple-spinner">
                                              <span></span>
                                            </div>
                                          </>
                                        ) : (
                                          'Create action'
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                  {
                                    newActionEnteral1 &&
                                    <>
                                      <div className="">
                                        <div className='w-full mt-5'>
                                          <div className='flex gap-y-[24px] flex-col'>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_first_name"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                First Name
                                              </label>
                                              <input
                                                id="contact_first_name"
                                                name="contact_first_name"
                                                type="text"
                                                maxLength={30}
                                                value={contactInfo1.contact_first_name}
                                                onChange={handleInputChange1}
                                                // placeholder="Jane Smith"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_last_name"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Last Name
                                              </label>
                                              <input
                                                id="contact_last_name"
                                                name="contact_last_name"
                                                type="text"
                                                maxLength={30}
                                                value={contactInfo1.contact_last_name}
                                                onChange={handleInputChange1}
                                                // placeholder="Jane Smith"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_email"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Email
                                              </label>
                                              <input
                                                id="contact_email"
                                                name="contact_email"
                                                value={contactInfo1.contact_email}
                                                onChange={handleInputChange1}
                                                maxLength={30}
                                                type="email"

                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_phone"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Phone no.
                                              </label>
                                              <input
                                                id="contact_phone"
                                                name="contact_phone"
                                                type="number"
                                                maxLength={30}
                                                value={contactInfo1.contact_phone}
                                                onChange={handleInputChange1}
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                            ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_company"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Company
                                              </label>
                                              <input
                                                id="contact_company"
                                                name="contact_company"
                                                value={contactInfo1.contact_company}
                                                onChange={handleInputChange1}
                                                maxLength={30}
                                                type="text"
                                                // placeholder="1234567890"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_job_title"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Job Title
                                              </label>
                                              <input
                                                id="contact_job_title"
                                                name="contact_job_title"
                                                value={contactInfo1.contact_job_title}
                                                onChange={handleInputChange1}
                                                type="text"
                                                maxLength={1000}
                                                // placeholder="Enter meta value"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>


                                            <div className='w-auto flex justify-end'>
                                              <button
                                                type="button"
                                                onClick={handleDocumentContactSave1}
                                                className=" flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                              // onClick={() => handleSubmitItemCustomer(companyInfo)}
                                              // disabled={isUpdating}
                                              >

                                                {contactLoading1 ? (
                                                  <>
                                                    Create contact
                                                    <div className="simple-spinner">
                                                      <span></span>
                                                    </div>
                                                  </>
                                                ) : (
                                                  'Create contact'
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  }



                                </motion.div>
                              )}
                            </>
                          }



                          {
                            !newAction1 &&
                            <AnimatePresence initial={false}>
                              {!collapsed5 && (
                                <motion.div
                                  initial={{ height: 0 }}
                                  animate={{ height: 'auto' }}
                                  exit={{ height: 0, }}
                                  transition={{ height: { duration: 0.5 }, }}
                                  className="overflow-hidden"
                                >
                                  {isActionList?.data?.approval?.internal.length > 0 &&
                                    <span className='font-normal text-dark-black-200 text-[12px]'>Internal - </span>
                                  }
                                  {isActionList?.data?.approval?.internal &&
                                    isActionList.data.approval.internal.map((internalAction, index) => {
                                      // Assuming `baseMemberDetails` is an array and you want to find the member matching `internal_user_id`
                                      const baseMember = baseMemberDetails?.data?.baseMembers?.find(
                                        (member) => member.id === internalAction.internal_user_id
                                      );

                                      return (
                                        <div
                                          key={internalAction.id || index}
                                          className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                        >
                                          <div className="flex items-center gap-3">
                                            <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                              <span className="text-[10px] font-medium text-gray-700">
                                                {baseMember?.initials || 'A'}
                                              </span>
                                            </div>
                                            <p className="leading-relaxed">
                                              <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                {baseMember?.name && ` ${baseMember.name}`}
                                              </span>
                                            </p>
                                          </div>
                                          <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                            <CustomMenuDropdown
                                              icon={
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="12" cy="12" r="1"></circle>
                                                  <circle cx="12" cy="5" r="1"></circle>
                                                  <circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                              }
                                              altText="User profile"
                                              sections={dropdownGroups1}
                                              menuItemsClassName="left-auto top-[10px]"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}
                                  {
                                    isActionList?.data?.approval?.external?.length > 0 && (
                                      <>
                                        {
                                          // Combine the two lists and get only the first one.
                                          [
                                            ...(isActionList?.data?.approval?.external || []),
                                            ...(isActionList?.data?.approval?.external || [])
                                          ]
                                            .slice(0, 1) // Take only the first item
                                            .map((item, index) => {

                                              return (
                                                <div key={index} className="flex items-center space-x-2">
                                                  {item.external_contact_id && (
                                                    <span className="font-normal text-dark-black-200 text-[12px]">External - </span>
                                                  )}
                                                </div>
                                              );
                                            })
                                        }
                                      </>
                                    )
                                  }
                                  {
                                    isActionList?.data?.approval?.external?.length > 0 &&
                                    isActionList.data.approval.external.map((internalAction, index) => {


                                      // This assumes internalAction contains the 'contact_first_name', 'contact_last_name', 'contact_email', etc.
                                      return (
                                        <div
                                          key={internalAction.id || index}
                                          className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                        >
                                          <div className="flex items-center gap-3">
                                            <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                              <span className="text-[10px] font-medium text-gray-700 capitalize">
                                                {
                                                  internalAction?.contact?.contact_first_name
                                                    ? internalAction.contact.contact_first_name.charAt(0).toLowerCase()
                                                    : internalAction?.contact?.contact_last_name
                                                      ? internalAction.contact.contact_last_name.charAt(0).toLowerCase()
                                                      : internalAction?.contact?.contact_email
                                                        ? internalAction.contact.contact_email.charAt(0).toLowerCase()
                                                        : ''
                                                }


                                              </span>
                                            </div>
                                            <div className='flex flex-col'>
                                              <p className="leading-relaxed">
                                                <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                  {internalAction?.contact?.contact_first_name && `${internalAction?.contact?.contact_first_name} `}
                                                  {internalAction?.contact?.contact_last_name && `${internalAction?.contact?.contact_last_name} `}
                                                </span>
                                              </p>
                                              <p className="leading-relaxed">
                                                <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                  {internalAction?.contact?.contact_email && `${internalAction?.contact?.contact_email}`}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                          <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                            <CustomMenuDropdown
                                              icon={
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="12" cy="12" r="1"></circle>
                                                  <circle cx="12" cy="5" r="1"></circle>
                                                  <circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                              }
                                              altText="User profile"
                                              sections={dropdownGroups1}
                                              menuItemsClassName="left-auto top-[5px]"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })
                                  }
                                </motion.div>
                              )}
                            </AnimatePresence>
                          }

                        </div>
                        <div className='bg-[#e5e7eb] w-[-webkit-fill-available] h-[1px] mx-[-20px] px-[20px] my-5'></div>
                        <div className="mb-2">
                          <div className='flex justify-between items-center'>
                            <a
                              href="#"
                              onClick={toggleCollapse6}
                              className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                            >
                              <span className='flex mr-[6px] h-[8px] w-[8px] shrink-0
                            items-center justify-center rounded-[20px] border-none
                             bg-[#1d4ed8]'>

                              </span>
                              View

                              <div className={`${collapsed6 ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                                <svg
                                  width={24}
                                  height={24}
                                  fill="none"
                                  className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M15.25 10.75 12 14.25l-3.25-3.5"
                                  />
                                </svg>
                              </div>
                            </a>
                            <button className='underline text-[13px] text-[#266df0]' onClick={handleNewAction2}>
                              Add
                            </button>
                          </div>

                          {
                            newAction2 &&
                            <>
                              <nav className='relative w-[100%] my-5 h-full bg-[#f4f5f7] rounded-md p-[6px]' aria-label='Tabs'>
                                {tabs3.map((tab) => (
                                  <motion.a
                                    key={tab.name}
                                    onClick={() => setOpenActionTab2(tab.name)}
                                    href={tab.href}
                                    initial="hidden"
                                    animate="visible"
                                    variants={variants}
                                    transition={{ duration: 0.3 }}
                                    className={classNames(
                                      tab.name === openActionTab2
                                        ? 'border-none bg-[#4a5562] rounded-md text-[#fff]'
                                        : 'py-1 w-1/2 cursor-pointer select-none focus:outline-none',
                                      'py-1 w-1/2 cursor-pointer select-none group inline-flex capitalize items-center text-[12px] font-medium rounded-md justify-center'
                                    )}
                                  >
                                    <span>{tab.name}</span>
                                  </motion.a>
                                ))}

                              </nav>
                              {openActionTab2 === 'internal team' && (
                                <motion.div
                                  className="mt-4"
                                  initial={{ opacity: 1, y: 0 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ duration: 0.8 }}
                                >
                                  <div className="relative w-[100%]">
                                    <Listbox value={variableType3} onChange={setVariableType3}>
                                      {({ open }) => (
                                        <div>
                                          <label
                                            className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
    before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
     peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
     after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
    before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                          >
                                            Input type

                                          </label>
                                          <ListboxButton
                                            className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
                                           focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
                                                  disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                          >
                                            <div className='flex items-center justify-between'>
                                              <span
                                                className="flex items-center gap-x-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
                                                  overflow-hidden text-ellipsis text-start font-medium"
                                              >
                                                {variableType3 ? variableType3.name : "Select an option"}
                                              </span>
                                              <ChevronDownIcon />
                                            </div>
                                          </ListboxButton>
                                          <Transition
                                            show={open}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <ListboxOptions
                                              className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                            >
                                              {baseMemberDetailsArray.length > 0 ? (
                                                baseMemberDetailsArray.map((board: any) => (
                                                  <Listbox.Option
                                                    key={board.id}
                                                    className={({ active }) =>
                                                      classNames(
                                                        active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                        !active ? 'text-dark-black-100 text-[13px]' : '',
                                                        'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                      )
                                                    }
                                                    value={board}
                                                  >
                                                    {({ selected }) => (
                                                      <span
                                                        className={classNames(
                                                          selected ? 'font-semibold' : 'font-normal',
                                                          'flex items-center gap-x-2 truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full overflow-hidden text-ellipsis text-start'
                                                        )}
                                                      >
                                                        {
                                                          board.profile_pic ? (
                                                            <img
                                                              src={`${process.env.REACT_APP_API_BASE_URL}/${board.profile_pic}`}
                                                              alt={`${board.name}'s profile`}
                                                              className="w-5 h-5 object-cover rounded-full"
                                                            />
                                                          ) : (
                                                            <span className="w-5 h-5 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                                              {board.name.split(' ').map((word) => word.charAt(0)).join('').toUpperCase()}
                                                            </span>
                                                          )
                                                        }
                                                        {board?.name}
                                                      </span>
                                                    )}
                                                  </Listbox.Option>
                                                ))
                                              ) : (
                                                <div className="px-3 py-2 text-[13px] text-gray-500 text-center">
                                                  Nothing here
                                                </div>
                                              )}
                                            </ListboxOptions>
                                          </Transition>
                                        </div>
                                      )}
                                    </Listbox>
                                  </div>
                                  <div className='mt-5 flex justify-end'>
                                    <button
                                      onClick={handleActionInternalTeam2}
                                      className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                      {isActionLoading3 ? (
                                        <>
                                          Create action
                                          <div className="simple-spinner">
                                            <span></span>
                                          </div>
                                        </>
                                      ) : (
                                        'Create action'
                                      )}
                                    </button>
                                  </div>
                                </motion.div>
                              )}
                              {openActionTab2 === 'external contact' && (
                                <motion.div
                                  className="mt-4"
                                  initial={{ opacity: 1, y: 0 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ duration: 0.8 }}
                                >
                                  <div className="relative">
                                    <button
                                      type="button"
                                      className="flex items-center justify-between h-[40px] px-4 w-full py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                                      onClick={() => setIsOpen2(!isOpen2)}
                                    >
                                      <span className="mr-2 text-[13px] text-[#374151] new-action">
                                        {
                                          selectedRecipient2
                                            ? (
                                              <span className=" font-semibold">
                                                {selectedRecipient2.contact_first_name || ''}
                                                {selectedRecipient2.contact_last_name || ''}
                                                {!selectedRecipient2.contact_last_name && !selectedRecipient2.contact_first_name || ' - '}
                                              </span>
                                            )
                                            : 'Start typing name or email'
                                        }
                                        {selectedRecipient2?.contact_email && (
                                          <span className="text-[14px]">
                                            {selectedRecipient2.contact_email}
                                          </span>
                                        )}

                                      </span>

                                      <svg
                                        className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${isOpen2 ? 'transform rotate-180' : ''}`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </button>
                                    {isOpen2 && (
                                      <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                          <div className="px-4 py-2">
                                            <input
                                              type="text"
                                              className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                              placeholder="Start typing name or email"
                                              value={search2}
                                              onChange={handleSearchChange2}
                                            />
                                          </div>
                                          <div className='max-h-[200px] overflow-auto'>
                                            {recipients2.map((recipient, index) => (
                                              <a
                                                key={index}
                                                href="#"
                                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                onClick={() => handleRecipientSelect2(recipient)} // Update the onClick to handle selection
                                              >
                                                <div className="flex items-center">
                                                  <span
                                                    className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-black capitalize bg-gray-300">
                                                    {
                                                      recipient?.contact_first_name && recipient?.contact_last_name
                                                        ? recipient.contact_first_name.charAt(0).toLowerCase() // Show the first letter of first name in lowercase
                                                        : recipient?.contact_last_name
                                                          ? recipient.contact_last_name.charAt(0).toLowerCase() // Show the first letter of last name in lowercase
                                                          : recipient?.contact_email
                                                            ? recipient.contact_email.charAt(0).toLowerCase() // Show the first letter of email in lowercase
                                                            : null // If none of the above exist, return null
                                                    }




                                                  </span>
                                                  <div className='flex flex-col gap-y-[2px] ml-2'>
                                                    <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis capitalize">{recipient.contact_first_name} {recipient.contact_last_name}</span>
                                                    <span className="text-[12px] text-[#374151] leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis">{recipient.contact_email}</span>
                                                  </div>
                                                </div>
                                              </a>
                                            ))}
                                          </div>
                                          <a href="#" onClick={handleNewActionEnteral2} className="flex items-center gap-x-1 px-4 py-2 hover:bg-gray-100">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={24}
                                              height={24}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth={2}
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              className="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus w-[1rem] h-[1rem] text-[#ff591e]"
                                            >
                                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                              <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                              <path d="M9 12h6" />
                                              <path d="M12 9v6" />
                                            </svg>

                                            <span className='font-semibold text-[#ff591e] hover:text-[#ff591e]'>
                                              Create new recipient
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                    <div className='mt-5 flex justify-end'>
                                      <button
                                        onClick={handleActionExternalContact2}
                                        className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                        {isActionLoading3 ? (
                                          <>
                                            Create action
                                            <div className="simple-spinner">
                                              <span></span>
                                            </div>
                                          </>
                                        ) : (
                                          'Create action'
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                  {
                                    newActionEnteral2 &&
                                    <>
                                      <div className="">
                                        <div className='w-full mt-5'>
                                          <div className='flex gap-y-[24px] flex-col'>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_first_name"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                First Name
                                              </label>
                                              <input
                                                id="contact_first_name"
                                                name="contact_first_name"
                                                type="text"
                                                maxLength={30}
                                                value={contactInfo2.contact_first_name}
                                                onChange={handleInputChange2}
                                                // placeholder="Jane Smith"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_last_name"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Last Name
                                              </label>
                                              <input
                                                id="contact_last_name"
                                                name="contact_last_name"
                                                type="text"
                                                maxLength={30}
                                                value={contactInfo2.contact_last_name}
                                                onChange={handleInputChange2}
                                                // placeholder="Jane Smith"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_email"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Email
                                              </label>
                                              <input
                                                id="contact_email"
                                                name="contact_email"
                                                value={contactInfo2.contact_email}
                                                onChange={handleInputChange2}
                                                maxLength={30}
                                                type="email"

                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_phone"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Phone no.
                                              </label>
                                              <input
                                                id="contact_phone"
                                                name="contact_phone"
                                                type="number"
                                                maxLength={30}
                                                value={contactInfo2.contact_phone}
                                                onChange={handleInputChange2}
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                            ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_company"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Company
                                              </label>
                                              <input
                                                id="contact_company"
                                                name="contact_company"
                                                value={contactInfo2.contact_company}
                                                onChange={handleInputChange2}
                                                maxLength={30}
                                                type="text"
                                                // placeholder="1234567890"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_job_title"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Job Title
                                              </label>
                                              <input
                                                id="contact_job_title"
                                                name="contact_job_title"
                                                value={contactInfo2.contact_job_title}
                                                onChange={handleInputChange2}
                                                type="text"
                                                maxLength={1000}
                                                // placeholder="Enter meta value"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>


                                            <div className='w-auto flex justify-end'>
                                              <button
                                                type="button"
                                                onClick={handleDocumentContactSave2}
                                                className=" flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                              // onClick={() => handleSubmitItemCustomer(companyInfo)}
                                              // disabled={isUpdating}
                                              >

                                                {contactLoading2 ? (
                                                  <>
                                                    Create contact
                                                    <div className="simple-spinner">
                                                      <span></span>
                                                    </div>
                                                  </>
                                                ) : (
                                                  'Create contact'
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  }



                                </motion.div>
                              )}
                            </>
                          }



                          {
                            !newAction2 &&
                            <AnimatePresence initial={false}>
                              {!collapsed6 && (
                                <motion.div
                                  initial={{ height: 0 }}
                                  animate={{ height: 'auto' }}
                                  exit={{ height: 0, }}
                                  transition={{ height: { duration: 0.5 }, }}
                                  className="overflow-hidden"
                                >
                                  {isActionList?.data?.view?.internal.length > 0 &&
                                    <span className='font-normal text-dark-black-200 text-[12px]'>Internal - </span>
                                  }
                                  {isActionList?.data?.view?.internal &&
                                    isActionList.data.view.internal.map((internalAction, index) => {
                                      // Assuming `baseMemberDetails` is an array and you want to find the member matching `internal_user_id`
                                      const baseMember = baseMemberDetails?.data?.baseMembers?.find(
                                        (member) => member.id === internalAction.internal_user_id
                                      );

                                      return (
                                        <div
                                          key={internalAction.id || index}
                                          className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                        >
                                          <div className="flex items-center gap-3">
                                            <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                              <span className="text-[10px] font-medium text-gray-700">
                                                {baseMember?.initials || 'A'}
                                              </span>
                                            </div>
                                            <p className="leading-relaxed">
                                              <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                {baseMember?.name && ` ${baseMember.name}`}
                                              </span>
                                            </p>
                                          </div>
                                          <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                            <CustomMenuDropdown
                                              icon={
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="12" cy="12" r="1"></circle>
                                                  <circle cx="12" cy="5" r="1"></circle>
                                                  <circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                              }
                                              altText="User profile"
                                              sections={dropdownGroups1}
                                              menuItemsClassName="left-auto top-[10px]"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}

                                  {
                                    isActionList?.data?.view?.external?.length > 0 && (
                                      <>
                                        {
                                          // Combine the two lists and get only the first one.
                                          [
                                            ...(isActionList?.data?.view?.external || []),
                                            ...(isActionList?.data?.view?.external || [])
                                          ]
                                            .slice(0, 1) // Take only the first item
                                            .map((item, index) => {

                                              return (
                                                <div key={index} className="flex items-center space-x-2">
                                                  {item.external_contact_id && (
                                                    <span className="font-normal text-dark-black-200 text-[12px]">External - </span>
                                                  )}
                                                </div>
                                              );
                                            })
                                        }
                                      </>
                                    )
                                  }
                                  {
                                    isActionList?.data?.view?.external?.length > 0 &&
                                    isActionList.data.view.external.map((internalAction, index) => {


                                      // This assumes internalAction contains the 'contact_first_name', 'contact_last_name', 'contact_email', etc.
                                      return (
                                        <div
                                          key={internalAction.id || index}
                                          className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                        >
                                          <div className="flex items-center gap-3">
                                            <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                              <span className="text-[10px] font-medium text-gray-700 capitalize">
                                                {
                                                  internalAction?.contact?.contact_first_name
                                                    ? internalAction.contact.contact_first_name.charAt(0).toLowerCase()
                                                    : internalAction?.contact?.contact_last_name
                                                      ? internalAction.contact.contact_last_name.charAt(0).toLowerCase()
                                                      : internalAction?.contact?.contact_email
                                                        ? internalAction.contact.contact_email.charAt(0).toLowerCase()
                                                        : ''
                                                }


                                              </span>
                                            </div>
                                            <div className='flex flex-col'>
                                              <p className="leading-relaxed">
                                                <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                  {internalAction?.contact?.contact_first_name && `${internalAction?.contact?.contact_first_name} `}
                                                  {internalAction?.contact?.contact_last_name && `${internalAction?.contact?.contact_last_name} `}
                                                </span>
                                              </p>
                                              <p className="leading-relaxed">
                                                <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                  {internalAction?.contact?.contact_email && `${internalAction?.contact?.contact_email}`}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                          <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                            <CustomMenuDropdown
                                              icon={
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="12" cy="12" r="1"></circle>
                                                  <circle cx="12" cy="5" r="1"></circle>
                                                  <circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                              }
                                              altText="User profile"
                                              sections={dropdownGroups1}
                                              menuItemsClassName="left-auto top-[5px]"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })
                                  }


                                </motion.div>
                              )}
                            </AnimatePresence>
                          }

                        </div>
                        <div className='bg-[#e5e7eb] w-[-webkit-fill-available] h-[1px] mx-[-20px] px-[20px] my-5'></div>
                        <div className="mb-2">
                          <div className='flex justify-between items-center'>
                            <a
                              href="#"
                              onClick={toggleCollapse7}
                              className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                            >
                              <span className='flex mr-[6px] h-[8px] w-[8px] shrink-0
                            items-center justify-center rounded-[20px] border-none
                             bg-[#1d4ed8]'>

                              </span>
                              Fill

                              <div className={`${collapsed7 ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                                <svg
                                  width={24}
                                  height={24}
                                  fill="none"
                                  className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M15.25 10.75 12 14.25l-3.25-3.5"
                                  />
                                </svg>
                              </div>
                            </a>
                            <button className='underline text-[13px] text-[#266df0]' onClick={handleNewAction3}>
                              Add
                            </button>
                          </div>

                          {
                            newAction3 &&
                            <>
                              <nav className='relative w-[100%] my-5 h-full bg-[#f4f5f7] rounded-md p-[6px]' aria-label='Tabs'>
                                {tabs4.map((tab) => (
                                  <motion.a
                                    key={tab.name}
                                    onClick={() => setOpenActionTab3(tab.name)}
                                    href={tab.href}
                                    initial="hidden"
                                    animate="visible"
                                    variants={variants}
                                    transition={{ duration: 0.3 }}
                                    className={classNames(
                                      tab.name === openActionTab3
                                        ? 'border-none bg-[#4a5562] rounded-md text-[#fff]'
                                        : 'py-1 w-1/2 cursor-pointer select-none focus:outline-none',
                                      'py-1 w-1/2 cursor-pointer select-none group inline-flex capitalize items-center text-[12px] font-medium rounded-md justify-center'
                                    )}
                                  >
                                    <span>{tab.name}</span>
                                  </motion.a>
                                ))}

                              </nav>
                              {openActionTab3 === 'internal team' && (
                                <motion.div
                                  className="mt-4"
                                  initial={{ opacity: 1, y: 0 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ duration: 0.8 }}
                                >
                                  <div className="relative w-[100%]">
                                    <Listbox value={variableType4} onChange={setVariableType4}>
                                      {({ open }) => (
                                        <div>
                                          <label
                                            className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
    before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
     peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
     after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
    before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                          >
                                            Input type

                                          </label>
                                          <ListboxButton
                                            className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
                                           focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
                                                  disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                          >
                                            <div className='flex items-center justify-between'>
                                              <span
                                                className="flex items-center gap-x-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
                                                  overflow-hidden text-ellipsis text-start font-medium"
                                              >
                                                {variableType4 ? variableType4.name : "Select an option"}
                                              </span>
                                              <ChevronDownIcon />
                                            </div>
                                          </ListboxButton>
                                          <Transition
                                            show={open}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <ListboxOptions
                                              className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                            >
                                              {baseMemberDetailsArray.length > 0 ? (
                                                baseMemberDetailsArray.map((board: any) => (
                                                  <Listbox.Option
                                                    key={board.id}
                                                    className={({ active }) =>
                                                      classNames(
                                                        active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                        !active ? 'text-dark-black-100 text-[13px]' : '',
                                                        'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                      )
                                                    }
                                                    value={board}
                                                  >
                                                    {({ selected }) => (
                                                      <span
                                                        className={classNames(
                                                          selected ? 'font-semibold' : 'font-normal',
                                                          'flex items-center gap-x-2 truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full overflow-hidden text-ellipsis text-start'
                                                        )}
                                                      >
                                                        {
                                                          board.profile_pic ? (
                                                            <img
                                                              src={`${process.env.REACT_APP_API_BASE_URL}/${board.profile_pic}`}
                                                              alt={`${board.name}'s profile`}
                                                              className="w-5 h-5 object-cover rounded-full"
                                                            />
                                                          ) : (
                                                            <span className="w-5 h-5 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                                              {board.name.split(' ').map((word) => word.charAt(0)).join('').toUpperCase()}
                                                            </span>
                                                          )
                                                        }
                                                        {board?.name}
                                                      </span>
                                                    )}
                                                  </Listbox.Option>
                                                ))
                                              ) : (
                                                <div className="px-3 py-2 text-[13px] text-gray-500 text-center">
                                                  Nothing here
                                                </div>
                                              )}
                                            </ListboxOptions>
                                          </Transition>
                                        </div>
                                      )}
                                    </Listbox>
                                  </div>
                                  <div className='mt-5 flex justify-end'>
                                    <button
                                      onClick={handleActionInternalTeam3}
                                      className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                      {isActionLoading4 ? (
                                        <>
                                          Create action
                                          <div className="simple-spinner">
                                            <span></span>
                                          </div>
                                        </>
                                      ) : (
                                        'Create action'
                                      )}
                                    </button>
                                  </div>
                                </motion.div>
                              )}
                              {openActionTab3 === 'external contact' && (
                                <motion.div
                                  className="mt-4"
                                  initial={{ opacity: 1, y: 0 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ duration: 0.8 }}
                                >
                                  <div className="relative">
                                    <button
                                      type="button"
                                      className="flex items-center justify-between h-[40px] px-4 w-full py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                                      onClick={() => setIsOpen3(!isOpen3)}
                                    >
                                      <span className="mr-2 text-[13px] text-[#374151] new-action">
                                        {
                                          selectedRecipient3
                                            ? (
                                              <span className=" font-semibold">
                                                {selectedRecipient3.contact_first_name || ''}
                                                {selectedRecipient3.contact_last_name || ''}
                                                {!selectedRecipient3.contact_last_name && !selectedRecipient3.contact_first_name || ' - '}
                                              </span>
                                            )
                                            : 'Start typing name or email'
                                        }
                                        {selectedRecipient3?.contact_email && (
                                          <span className="text-[14px]">
                                            {selectedRecipient3.contact_email}
                                          </span>
                                        )}

                                      </span>

                                      <svg
                                        className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${isOpen3 ? 'transform rotate-180' : ''}`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </button>
                                    {isOpen3 && (
                                      <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                          <div className="px-4 py-2">
                                            <input
                                              type="text"
                                              className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                              placeholder="Start typing name or email"
                                              value={search3}
                                              onChange={handleSearchChange3}
                                            />
                                          </div>
                                          <div className='max-h-[200px] overflow-auto'>
                                            {recipients3.map((recipient, index) => (
                                              <a
                                                key={index}
                                                href="#"
                                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                onClick={() => handleRecipientSelect3(recipient)} // Update the onClick to handle selection
                                              >
                                                <div className="flex items-center">
                                                  <span
                                                    className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-black capitalize bg-gray-300">
                                                    {
                                                      recipient?.contact_first_name && recipient?.contact_last_name
                                                        ? recipient.contact_first_name.charAt(0).toLowerCase() // Show the first letter of first name in lowercase
                                                        : recipient?.contact_last_name
                                                          ? recipient.contact_last_name.charAt(0).toLowerCase() // Show the first letter of last name in lowercase
                                                          : recipient?.contact_email
                                                            ? recipient.contact_email.charAt(0).toLowerCase() // Show the first letter of email in lowercase
                                                            : null // If none of the above exist, return null
                                                    }




                                                  </span>
                                                  <div className='flex flex-col gap-y-[2px] ml-2'>
                                                    <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis capitalize">{recipient.contact_first_name} {recipient.contact_last_name}</span>
                                                    <span className="text-[12px] text-[#374151] leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis">{recipient.contact_email}</span>
                                                  </div>
                                                </div>
                                              </a>
                                            ))}
                                          </div>
                                          <a href="#" onClick={handleNewActionEnteral3} className="flex items-center gap-x-1 px-4 py-2 hover:bg-gray-100">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={24}
                                              height={24}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth={2}
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              className="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus w-[1rem] h-[1rem] text-[#ff591e]"
                                            >
                                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                              <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                              <path d="M9 12h6" />
                                              <path d="M12 9v6" />
                                            </svg>

                                            <span className='font-semibold text-[#ff591e] hover:text-[#ff591e]'>
                                              Create new recipient
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                    <div className='mt-5 flex justify-end'>
                                      <button
                                        onClick={handleActionExternalContact3}
                                        className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                        {isActionLoading4 ? (
                                          <>
                                            Create action
                                            <div className="simple-spinner">
                                              <span></span>
                                            </div>
                                          </>
                                        ) : (
                                          'Create action'
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                  {
                                    newActionEnteral3 &&
                                    <>
                                      <div className="">
                                        <div className='w-full mt-5'>
                                          <div className='flex gap-y-[24px] flex-col'>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_first_name"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                First Name
                                              </label>
                                              <input
                                                id="contact_first_name"
                                                name="contact_first_name"
                                                type="text"
                                                maxLength={30}
                                                value={contactInfo3.contact_first_name}
                                                onChange={handleInputChange3}
                                                // placeholder="Jane Smith"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_last_name"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Last Name
                                              </label>
                                              <input
                                                id="contact_last_name"
                                                name="contact_last_name"
                                                type="text"
                                                maxLength={30}
                                                value={contactInfo3.contact_last_name}
                                                onChange={handleInputChange3}
                                                // placeholder="Jane Smith"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_email"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Email
                                              </label>
                                              <input
                                                id="contact_email"
                                                name="contact_email"
                                                value={contactInfo3.contact_email}
                                                onChange={handleInputChange3}
                                                maxLength={30}
                                                type="email"

                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_phone"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Phone no.
                                              </label>
                                              <input
                                                id="contact_phone"
                                                name="contact_phone"
                                                type="number"
                                                maxLength={30}
                                                value={contactInfo3.contact_phone}
                                                onChange={handleInputChange3}
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                            ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_company"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Company
                                              </label>
                                              <input
                                                id="contact_company"
                                                name="contact_company"
                                                value={contactInfo3.contact_company}
                                                onChange={handleInputChange3}
                                                maxLength={30}
                                                type="text"
                                                // placeholder="1234567890"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_job_title"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Job Title
                                              </label>
                                              <input
                                                id="contact_job_title"
                                                name="contact_job_title"
                                                value={contactInfo3.contact_job_title}
                                                onChange={handleInputChange3}
                                                type="text"
                                                maxLength={1000}
                                                // placeholder="Enter meta value"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>


                                            <div className='w-auto flex justify-end'>
                                              <button
                                                type="button"
                                                onClick={handleDocumentContactSave3}
                                                className=" flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                              // onClick={() => handleSubmitItemCustomer(companyInfo)}
                                              // disabled={isUpdating}
                                              >

                                                {contactLoading3 ? (
                                                  <>
                                                    Create contact
                                                    <div className="simple-spinner">
                                                      <span></span>
                                                    </div>
                                                  </>
                                                ) : (
                                                  'Create contact'
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  }



                                </motion.div>
                              )}
                            </>
                          }



                          {
                            !newAction3 &&
                            <AnimatePresence initial={false}>
                              {!collapsed7 && (
                                <motion.div
                                  initial={{ height: 0 }}
                                  animate={{ height: 'auto' }}
                                  exit={{ height: 0, }}
                                  transition={{ height: { duration: 0.5 }, }}
                                  className="overflow-hidden"
                                >
                                  {isActionList?.data?.fill?.internal.length > 0 &&
                                    <span className='font-normal text-dark-black-200 text-[12px]'>Internal - </span>
                                  }
                                  {isActionList?.data?.fill?.internal &&
                                    isActionList.data.fill.internal.map((internalAction, index) => {
                                      // Assuming `baseMemberDetails` is an array and you want to find the member matching `internal_user_id`
                                      const baseMember = baseMemberDetails?.data?.baseMembers?.find(
                                        (member) => member.id === internalAction.internal_user_id
                                      );

                                      return (
                                        <div
                                          key={internalAction.id || index}
                                          className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                        >
                                          <div className="flex items-center gap-3">
                                            <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                              <span className="text-[10px] font-medium text-gray-700">
                                                {baseMember?.initials || 'A'}
                                              </span>
                                            </div>
                                            <p className="leading-relaxed">
                                              <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                {baseMember?.name && ` ${baseMember.name}`}
                                              </span>
                                            </p>
                                          </div>
                                          <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                            <CustomMenuDropdown
                                              icon={
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="12" cy="12" r="1"></circle>
                                                  <circle cx="12" cy="5" r="1"></circle>
                                                  <circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                              }
                                              altText="User profile"
                                              sections={dropdownGroups1}
                                              menuItemsClassName="left-auto top-[10px]"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}


                                  {
                                    isActionList?.data?.fill?.external?.length > 0 && (
                                      <>
                                        {
                                          // Combine the two lists and get only the first one.
                                          [
                                            ...(isActionList?.data?.fill?.external || []),
                                            ...(isActionList?.data?.fill?.external || [])
                                          ]
                                            .slice(0, 1) // Take only the first item
                                            .map((item, index) => {

                                              return (
                                                <div key={index} className="flex items-center space-x-2">
                                                  {item.external_contact_id && (
                                                    <span className="font-normal text-dark-black-200 text-[12px]">External - </span>
                                                  )}
                                                </div>
                                              );
                                            })
                                        }
                                      </>
                                    )
                                  }
                                  {
                                    isActionList?.data?.fill?.external?.length > 0 &&
                                    isActionList.data.fill.external.map((internalAction, index) => {


                                      // This assumes internalAction contains the 'contact_first_name', 'contact_last_name', 'contact_email', etc.
                                      return (
                                        <div
                                          key={internalAction.id || index}
                                          className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                        >
                                          <div className="flex items-center gap-3">
                                            <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                              <span className="text-[10px] font-medium text-gray-700 capitalize">
                                                {
                                                  internalAction?.contact?.contact_first_name
                                                    ? internalAction.contact.contact_first_name.charAt(0).toLowerCase()
                                                    : internalAction?.contact?.contact_last_name
                                                      ? internalAction.contact.contact_last_name.charAt(0).toLowerCase()
                                                      : internalAction?.contact?.contact_email
                                                        ? internalAction.contact.contact_email.charAt(0).toLowerCase()
                                                        : ''
                                                }


                                              </span>
                                            </div>
                                            <div className='flex flex-col'>
                                              <p className="leading-relaxed">
                                                <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                  {internalAction?.contact?.contact_first_name && `${internalAction?.contact?.contact_first_name} `}
                                                  {internalAction?.contact?.contact_last_name && `${internalAction?.contact?.contact_last_name} `}
                                                </span>
                                              </p>
                                              <p className="leading-relaxed">
                                                <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                  {internalAction?.contact?.contact_email && `${internalAction?.contact?.contact_email}`}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                          <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                            <CustomMenuDropdown
                                              icon={
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="12" cy="12" r="1"></circle>
                                                  <circle cx="12" cy="5" r="1"></circle>
                                                  <circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                              }
                                              altText="User profile"
                                              sections={dropdownGroups1}
                                              menuItemsClassName="left-auto top-[5px]"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })
                                  }

                                </motion.div>
                              )}
                            </AnimatePresence>
                          }

                        </div>
                        <div className='bg-[#e5e7eb] w-[-webkit-fill-available] h-[1px] mx-[-20px] px-[20px] my-5'></div>

                        <div className="mb-2">
                          <div className='flex justify-between items-center'>
                            <a
                              href="#"
                              onClick={toggleCollapse8}
                              className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                            >
                              <span className='flex mr-[6px] h-[8px] w-[8px] shrink-0
                            items-center justify-center rounded-[20px] border-none
                             bg-[#1d4ed8]'>

                              </span>
                              Accept

                              <div className={`${collapsed8 ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                                <svg
                                  width={24}
                                  height={24}
                                  fill="none"
                                  className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M15.25 10.75 12 14.25l-3.25-3.5"
                                  />
                                </svg>
                              </div>
                            </a>
                            <button className='underline text-[13px] text-[#266df0]' onClick={handleNewAction4}>
                              Add
                            </button>
                          </div>

                          {
                            newAction4 &&
                            <>
                              <nav className='relative w-[100%] my-5 h-full bg-[#f4f5f7] rounded-md p-[6px]' aria-label='Tabs'>
                                {tabs5.map((tab) => (
                                  <motion.a
                                    key={tab.name}
                                    onClick={() => setOpenActionTab4(tab.name)}
                                    href={tab.href}
                                    initial="hidden"
                                    animate="visible"
                                    variants={variants}
                                    transition={{ duration: 0.3 }}
                                    className={classNames(
                                      tab.name === openActionTab4
                                        ? 'border-none bg-[#4a5562] rounded-md text-[#fff]'
                                        : 'py-1 w-1/2 cursor-pointer select-none focus:outline-none',
                                      'py-1 w-1/2 cursor-pointer select-none group inline-flex capitalize items-center text-[12px] font-medium rounded-md justify-center'
                                    )}
                                  >
                                    <span>{tab.name}</span>
                                  </motion.a>
                                ))}

                              </nav>
                              {openActionTab4 === 'internal team' && (
                                <motion.div
                                  className="mt-4"
                                  initial={{ opacity: 1, y: 0 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ duration: 0.8 }}
                                >
                                  <div className="relative w-[100%]">
                                    <Listbox value={variableType5} onChange={setVariableType5}>
                                      {({ open }) => (
                                        <div>
                                          <label
                                            className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
    before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
     peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
     after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
    before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                          >
                                            Input type

                                          </label>
                                          <ListboxButton
                                            className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
                                           focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
                                                  disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                          >
                                            <div className='flex items-center justify-between'>
                                              <span
                                                className="flex items-center gap-x-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
                                                  overflow-hidden text-ellipsis text-start font-medium"
                                              >
                                                {variableType5 ? variableType5.name : "Select an option"}
                                              </span>
                                              <ChevronDownIcon />
                                            </div>
                                          </ListboxButton>
                                          <Transition
                                            show={open}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <ListboxOptions
                                              className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                            >
                                              {baseMemberDetailsArray.length > 0 ? (
                                                baseMemberDetailsArray.map((board: any) => (
                                                  <Listbox.Option
                                                    key={board.id}
                                                    className={({ active }) =>
                                                      classNames(
                                                        active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                        !active ? 'text-dark-black-100 text-[13px]' : '',
                                                        'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                      )
                                                    }
                                                    value={board}
                                                  >
                                                    {({ selected }) => (
                                                      <span
                                                        className={classNames(
                                                          selected ? 'font-semibold' : 'font-normal',
                                                          'flex items-center gap-x-2 truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full overflow-hidden text-ellipsis text-start'
                                                        )}
                                                      >
                                                        {
                                                          board.profile_pic ? (
                                                            <img
                                                              src={`${process.env.REACT_APP_API_BASE_URL}/${board.profile_pic}`}
                                                              alt={`${board.name}'s profile`}
                                                              className="w-5 h-5 object-cover rounded-full"
                                                            />
                                                          ) : (
                                                            <span className="w-5 h-5 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                                              {board.name.split(' ').map((word) => word.charAt(0)).join('').toUpperCase()}
                                                            </span>
                                                          )
                                                        }
                                                        {board?.name}
                                                      </span>
                                                    )}
                                                  </Listbox.Option>
                                                ))
                                              ) : (
                                                <div className="px-3 py-2 text-[13px] text-gray-500 text-center">
                                                  Nothing here
                                                </div>
                                              )}
                                            </ListboxOptions>
                                          </Transition>
                                        </div>
                                      )}
                                    </Listbox>
                                  </div>
                                  <div className='mt-5 flex justify-end'>
                                    <button
                                      onClick={handleActionInternalTeam4}
                                      className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                      {isActionLoading5 ? (
                                        <>
                                          Create action
                                          <div className="simple-spinner">
                                            <span></span>
                                          </div>
                                        </>
                                      ) : (
                                        'Create action'
                                      )}
                                    </button>
                                  </div>
                                </motion.div>
                              )}
                              {openActionTab4 === 'external contact' && (
                                <motion.div
                                  className="mt-4"
                                  initial={{ opacity: 1, y: 0 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ duration: 0.8 }}
                                >
                                  <div className="relative">
                                    <button
                                      type="button"
                                      className="flex items-center justify-between h-[40px] px-4 w-full py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                                      onClick={() => setIsOpen4(!isOpen4)}
                                    >
                                      <span className="mr-2 text-[13px] text-[#374151] new-action">
                                        {
                                          selectedRecipient4
                                            ? (
                                              <span className=" font-semibold">
                                                {selectedRecipient4.contact_first_name || ''}
                                                {selectedRecipient4.contact_last_name || ''}
                                                {!selectedRecipient4.contact_last_name && !selectedRecipient4.contact_first_name || ' - '}
                                              </span>
                                            )
                                            : 'Start typing name or email'
                                        }
                                        {selectedRecipient4?.contact_email && (
                                          <span className="text-[14px]">
                                            {selectedRecipient4.contact_email}
                                          </span>
                                        )}

                                      </span>

                                      <svg
                                        className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${isOpen4 ? 'transform rotate-180' : ''}`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </button>
                                    {isOpen4 && (
                                      <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                          <div className="px-4 py-2">
                                            <input
                                              type="text"
                                              className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                              placeholder="Start typing name or email"
                                              value={search4}
                                              onChange={handleSearchChange4}
                                            />
                                          </div>
                                          <div className='max-h-[200px] overflow-auto'>
                                            {recipients4.map((recipient, index) => (
                                              <a
                                                key={index}
                                                href="#"
                                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                onClick={() => handleRecipientSelect4(recipient)} // Update the onClick to handle selection
                                              >
                                                <div className="flex items-center">
                                                  <span
                                                    className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-black capitalize bg-gray-300">
                                                    {
                                                      recipient?.contact_first_name && recipient?.contact_last_name
                                                        ? recipient.contact_first_name.charAt(0).toLowerCase() // Show the first letter of first name in lowercase
                                                        : recipient?.contact_last_name
                                                          ? recipient.contact_last_name.charAt(0).toLowerCase() // Show the first letter of last name in lowercase
                                                          : recipient?.contact_email
                                                            ? recipient.contact_email.charAt(0).toLowerCase() // Show the first letter of email in lowercase
                                                            : null // If none of the above exist, return null
                                                    }




                                                  </span>
                                                  <div className='flex flex-col gap-y-[2px] ml-2'>
                                                    <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis capitalize">{recipient.contact_first_name} {recipient.contact_last_name}</span>
                                                    <span className="text-[12px] text-[#374151] leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis">{recipient.contact_email}</span>
                                                  </div>
                                                </div>
                                              </a>
                                            ))}
                                          </div>
                                          <a href="#" onClick={handleNewActionEnteral4} className="flex items-center gap-x-1 px-4 py-2 hover:bg-gray-100">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={24}
                                              height={24}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth={2}
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              className="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus w-[1rem] h-[1rem] text-[#ff591e]"
                                            >
                                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                              <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                              <path d="M9 12h6" />
                                              <path d="M12 9v6" />
                                            </svg>

                                            <span className='font-semibold text-[#ff591e] hover:text-[#ff591e]'>
                                              Create new recipient
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                    <div className='mt-5 flex justify-end'>
                                      <button
                                        onClick={handleActionExternalContact4}
                                        className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                        {isActionLoading5 ? (
                                          <>
                                            Create action
                                            <div className="simple-spinner">
                                              <span></span>
                                            </div>
                                          </>
                                        ) : (
                                          'Create action'
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                  {
                                    newActionEnteral4 &&
                                    <>
                                      <div className="">
                                        <div className='w-full mt-5'>
                                          <div className='flex gap-y-[24px] flex-col'>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_first_name"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                First Name
                                              </label>
                                              <input
                                                id="contact_first_name"
                                                name="contact_first_name"
                                                type="text"
                                                maxLength={30}
                                                value={contactInfo4.contact_first_name}
                                                onChange={handleInputChange4}
                                                // placeholder="Jane Smith"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_last_name"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Last Name
                                              </label>
                                              <input
                                                id="contact_last_name"
                                                name="contact_last_name"
                                                type="text"
                                                maxLength={30}
                                                value={contactInfo4.contact_last_name}
                                                onChange={handleInputChange4}
                                                // placeholder="Jane Smith"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_email"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Email
                                              </label>
                                              <input
                                                id="contact_email"
                                                name="contact_email"
                                                value={contactInfo4.contact_email}
                                                onChange={handleInputChange4}
                                                maxLength={30}
                                                type="email"

                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_phone"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Phone no.
                                              </label>
                                              <input
                                                id="contact_phone"
                                                name="contact_phone"
                                                type="number"
                                                maxLength={30}
                                                value={contactInfo4.contact_phone}
                                                onChange={handleInputChange4}
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                            ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_company"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Company
                                              </label>
                                              <input
                                                id="contact_company"
                                                name="contact_company"
                                                value={contactInfo4.contact_company}
                                                onChange={handleInputChange4}
                                                maxLength={30}
                                                type="text"
                                                // placeholder="1234567890"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_job_title"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Job Title
                                              </label>
                                              <input
                                                id="contact_job_title"
                                                name="contact_job_title"
                                                value={contactInfo4.contact_job_title}
                                                onChange={handleInputChange4}
                                                type="text"
                                                maxLength={1000}
                                                // placeholder="Enter meta value"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>


                                            <div className='w-auto flex justify-end'>
                                              <button
                                                type="button"
                                                onClick={handleDocumentContactSave4}
                                                className=" flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                              // onClick={() => handleSubmitItemCustomer(companyInfo)}
                                              // disabled={isUpdating}
                                              >

                                                {contactLoading4 ? (
                                                  <>
                                                    Create contact
                                                    <div className="simple-spinner">
                                                      <span></span>
                                                    </div>
                                                  </>
                                                ) : (
                                                  'Create contact'
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  }



                                </motion.div>
                              )}
                            </>
                          }



                          {
                            !newAction4 &&
                            <AnimatePresence initial={false}>
                              {!collapsed8 && (
                                <motion.div
                                  initial={{ height: 0 }}
                                  animate={{ height: 'auto' }}
                                  exit={{ height: 0, }}
                                  transition={{ height: { duration: 0.5 }, }}
                                  className="overflow-hidden"
                                >
                                  {isActionList?.data?.accept?.internal.length > 0 &&
                                    <span className='font-normal text-dark-black-200 text-[12px]'>Internal - </span>
                                  }
                                  {isActionList?.data?.accept?.internal &&
                                    isActionList.data.accept.internal.map((internalAction, index) => {
                                      // Assuming `baseMemberDetails` is an array and you want to find the member matching `internal_user_id`
                                      const baseMember = baseMemberDetails?.data?.baseMembers?.find(
                                        (member) => member.id === internalAction.internal_user_id
                                      );

                                      return (
                                        <div
                                          key={internalAction.id || index}
                                          className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                        >
                                          <div className="flex items-center gap-3">
                                            <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                              <span className="text-[10px] font-medium text-gray-700">
                                                {baseMember?.initials || 'A'}
                                              </span>
                                            </div>
                                            <p className="leading-relaxed">
                                              <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                {baseMember?.name && ` ${baseMember.name}`}
                                              </span>
                                            </p>
                                          </div>
                                          <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                            <CustomMenuDropdown
                                              icon={
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="12" cy="12" r="1"></circle>
                                                  <circle cx="12" cy="5" r="1"></circle>
                                                  <circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                              }
                                              altText="User profile"
                                              sections={dropdownGroups1}
                                              menuItemsClassName="left-auto top-[10px]"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}
                                  {
                                    isActionList?.data?.accept?.external?.length > 0 && (
                                      <>
                                        {
                                          // Combine the two lists and get only the first one.
                                          [
                                            ...(isActionList?.data?.accept?.external || []),
                                            ...(isActionList?.data?.accept?.external || [])
                                          ]
                                            .slice(0, 1) // Take only the first item
                                            .map((item, index) => {

                                              return (
                                                <div key={index} className="flex items-center space-x-2">
                                                  {item.external_contact_id && (
                                                    <span className="font-normal text-dark-black-200 text-[12px]">External - </span>
                                                  )}
                                                </div>
                                              );
                                            })
                                        }
                                      </>
                                    )
                                  }
                                  {
                                    isActionList?.data?.accept?.external?.length > 0 &&
                                    isActionList.data.accept.external.map((internalAction, index) => {


                                      // This assumes internalAction contains the 'contact_first_name', 'contact_last_name', 'contact_email', etc.
                                      return (
                                        <div
                                          key={internalAction.id || index}
                                          className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                        >
                                          <div className="flex items-center gap-3">
                                            <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                              <span className="text-[10px] font-medium text-gray-700 capitalize">
                                                {
                                                  internalAction?.contact?.contact_first_name
                                                    ? internalAction.contact.contact_first_name.charAt(0).toLowerCase()
                                                    : internalAction?.contact?.contact_last_name
                                                      ? internalAction.contact.contact_last_name.charAt(0).toLowerCase()
                                                      : internalAction?.contact?.contact_email
                                                        ? internalAction.contact.contact_email.charAt(0).toLowerCase()
                                                        : ''
                                                }


                                              </span>
                                            </div>
                                            <div className='flex flex-col'>
                                              <p className="leading-relaxed">
                                                <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                  {internalAction?.contact?.contact_first_name && `${internalAction?.contact?.contact_first_name} `}
                                                  {internalAction?.contact?.contact_last_name && `${internalAction?.contact?.contact_last_name} `}
                                                </span>
                                              </p>
                                              <p className="leading-relaxed">
                                                <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                  {internalAction?.contact?.contact_email && `${internalAction?.contact?.contact_email}`}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                          <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                            <CustomMenuDropdown
                                              icon={
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="12" cy="12" r="1"></circle>
                                                  <circle cx="12" cy="5" r="1"></circle>
                                                  <circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                              }
                                              altText="User profile"
                                              sections={dropdownGroups1}
                                              menuItemsClassName="left-auto top-[5px]"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })
                                  }
                                </motion.div>
                              )}
                            </AnimatePresence>
                          }

                        </div>
                        <div className='bg-[#e5e7eb] w-[-webkit-fill-available] h-[1px] mx-[-20px] px-[20px] my-5'></div>
                        <div className="mb-2">
                          <div className='flex justify-between items-center'>
                            <a
                              href="#"
                              onClick={toggleCollapse9}
                              className="h-8 items-center rounded-lg bg-transparent hover:bg-light-gray-100 text-dark-black-200 font-medium text-[0.8125rem] 
                                focus:outline-none px-2 justify-start inline-flex ml-[-8px]"
                            >
                              <span className='flex mr-[6px] h-[8px] w-[8px] shrink-0
                            items-center justify-center rounded-[20px] border-none
                             bg-[#1d4ed8]'>

                              </span>
                              Sign

                              <div className={`${collapsed9 ? 'rotate-180' : 'rotate-0'} h-6 w-6 flex-shrink-0 transform transition-transform duration-300`}>
                                <svg
                                  width={24}
                                  height={24}
                                  fill="none"
                                  className="h-6 w-6 fill-transparent stroke-current text-wedges-gray-400"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M15.25 10.75 12 14.25l-3.25-3.5"
                                  />
                                </svg>
                              </div>
                            </a>
                            <button className='underline text-[13px] text-[#266df0]' onClick={handleNewAction5}>
                              Add
                            </button>
                          </div>

                          {
                            newAction5 &&
                            <>
                              <nav className='relative w-[100%] my-5 h-full bg-[#f4f5f7] rounded-md p-[6px]' aria-label='Tabs'>
                                {tabs6.map((tab) => (
                                  <motion.a
                                    key={tab.name}
                                    onClick={() => setOpenActionTab5(tab.name)}
                                    href={tab.href}
                                    initial="hidden"
                                    animate="visible"
                                    variants={variants}
                                    transition={{ duration: 0.3 }}
                                    className={classNames(
                                      tab.name === openActionTab5
                                        ? 'border-none bg-[#4a5562] rounded-md text-[#fff]'
                                        : 'py-1 w-1/2 cursor-pointer select-none focus:outline-none',
                                      'py-1 w-1/2 cursor-pointer select-none group inline-flex capitalize items-center text-[12px] font-medium rounded-md justify-center'
                                    )}
                                  >
                                    <span>{tab.name}</span>
                                  </motion.a>
                                ))}

                              </nav>
                              {openActionTab5 === 'internal team' && (
                                <motion.div
                                  className="mt-4"
                                  initial={{ opacity: 1, y: 0 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ duration: 0.8 }}
                                >
                                  <div className="relative w-[100%]">
                                    <Listbox value={variableType6} onChange={setVariableType6}>
                                      {({ open }) => (
                                        <div>
                                          <label
                                            className='flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[" "] 
    before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all
     peer-disabled:before:border-transparent after:content[" "] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
     after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent 
    before:border-t-2 before:border-l-2 after:border-t-2 after:border-r-2 leading-tight text-gray-900 before:border-indigo-600 after:border-indigo-600'
                                          >
                                            Input type

                                          </label>
                                          <ListboxButton
                                            className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 
                                           focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0
                                                  disabled:cursor-not-allowed transition-all border-2 text-sm px-3 py-2.5 rounded-[7px] border-indigo-600 border-t-transparent"
                                          >
                                            <div className='flex items-center justify-between'>
                                              <span
                                                className="flex items-center gap-x-2 truncate text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full 
                                                  overflow-hidden text-ellipsis text-start font-medium"
                                              >
                                                {variableType6 ? variableType6.name : "Select an option"}
                                              </span>
                                              <ChevronDownIcon />
                                            </div>
                                          </ListboxButton>
                                          <Transition
                                            show={open}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <ListboxOptions
                                              className="absolute z-10 w-full left-[0px] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                            >
                                              {baseMemberDetailsArray.length > 0 ? (
                                                baseMemberDetailsArray.map((board: any) => (
                                                  <Listbox.Option
                                                    key={board.id}
                                                    className={({ active }) =>
                                                      classNames(
                                                        active ? 'bg-light-gray-100 text-dark-black-100 text-[13px]' : '',
                                                        !active ? 'text-dark-black-100 text-[13px]' : '',
                                                        'relative cursor-default select-none py-1.5 pl-3 pr-9 text-[13px]'
                                                      )
                                                    }
                                                    value={board}
                                                  >
                                                    {({ selected }) => (
                                                      <span
                                                        className={classNames(
                                                          selected ? 'font-semibold' : 'font-normal',
                                                          'flex items-center gap-x-2 truncate font-medium text-[13px] leading-4 whitespace-nowrap max-w-[140px] w-full overflow-hidden text-ellipsis text-start'
                                                        )}
                                                      >
                                                        {
                                                          board.profile_pic ? (
                                                            <img
                                                              src={`${process.env.REACT_APP_API_BASE_URL}/${board.profile_pic}`}
                                                              alt={`${board.name}'s profile`}
                                                              className="w-5 h-5 object-cover rounded-full"
                                                            />
                                                          ) : (
                                                            <span className="w-5 h-5 rounded-full bg-[#f4f5f7] flex items-center justify-center text-[11px] font-[500] text-[#52555a]">
                                                              {board.name.split(' ').map((word) => word.charAt(0)).join('').toUpperCase()}
                                                            </span>
                                                          )
                                                        }
                                                        {board?.name}
                                                      </span>
                                                    )}
                                                  </Listbox.Option>
                                                ))
                                              ) : (
                                                <div className="px-3 py-2 text-[13px] text-gray-500 text-center">
                                                  Nothing here
                                                </div>
                                              )}
                                            </ListboxOptions>
                                          </Transition>
                                        </div>
                                      )}
                                    </Listbox>
                                  </div>
                                  <div className='mt-5 flex justify-end'>
                                    <button
                                      onClick={handleActionInternalTeam5}
                                      className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                      {isActionLoading6 ? (
                                        <>
                                          Create action
                                          <div className="simple-spinner">
                                            <span></span>
                                          </div>
                                        </>
                                      ) : (
                                        'Create action'
                                      )}
                                    </button>
                                  </div>
                                </motion.div>
                              )}
                              {openActionTab5 === 'external contact' && (
                                <motion.div
                                  className="mt-4"
                                  initial={{ opacity: 1, y: 0 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ duration: 0.8 }}
                                >
                                  <div className="relative">
                                    <button
                                      type="button"
                                      className="flex items-center justify-between h-[40px] px-4 w-full py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
                                      onClick={() => setIsOpen5(!isOpen5)}
                                    >
                                      <span className="mr-2 text-[13px] text-[#374151] new-action">
                                        {
                                          selectedRecipient5
                                            ? (
                                              <span className=" font-semibold">
                                                {selectedRecipient5.contact_first_name || ''}
                                                {selectedRecipient5.contact_last_name || ''}
                                                {!selectedRecipient5.contact_last_name && !selectedRecipient5.contact_first_name || ' - '}
                                              </span>
                                            )
                                            : 'Start typing name or email'
                                        }
                                        {selectedRecipient5?.contact_email && (
                                          <span className="text-[14px]">
                                            {selectedRecipient5.contact_email}
                                          </span>
                                        )}

                                      </span>

                                      <svg
                                        className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${isOpen5 ? 'transform rotate-180' : ''}`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </button>
                                    {isOpen5 && (
                                      <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                          <div className="px-4 py-2">
                                            <input
                                              type="text"
                                              className="w-full bg-transparent border-none focus:ring-0 focus:outline-none p-0 text-[14px] text-[#374151]"
                                              placeholder="Start typing name or email"
                                              value={search5}
                                              onChange={handleSearchChange5}
                                            />
                                          </div>
                                          <div className='max-h-[200px] overflow-auto'>
                                            {recipients5.map((recipient, index) => (
                                              <a
                                                key={index}
                                                href="#"
                                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                onClick={() => handleRecipientSelect5(recipient)} // Update the onClick to handle selection
                                              >
                                                <div className="flex items-center">
                                                  <span
                                                    className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-black capitalize bg-gray-300">
                                                    {
                                                      recipient?.contact_first_name && recipient?.contact_last_name
                                                        ? recipient.contact_first_name.charAt(0).toLowerCase() // Show the first letter of first name in lowercase
                                                        : recipient?.contact_last_name
                                                          ? recipient.contact_last_name.charAt(0).toLowerCase() // Show the first letter of last name in lowercase
                                                          : recipient?.contact_email
                                                            ? recipient.contact_email.charAt(0).toLowerCase() // Show the first letter of email in lowercase
                                                            : null // If none of the above exist, return null
                                                    }




                                                  </span>
                                                  <div className='flex flex-col gap-y-[2px] ml-2'>
                                                    <span className="text-[12px] text-[#374151] font-semibold leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis capitalize">{recipient.contact_first_name} {recipient.contact_last_name}</span>
                                                    <span className="text-[12px] text-[#374151] leading-4 truncate  whitespace-nowrap w-[200px] overflow-hidden text-ellipsis">{recipient.contact_email}</span>
                                                  </div>
                                                </div>
                                              </a>
                                            ))}
                                          </div>
                                          <a href="#" onClick={handleNewActionEnteral5} className="flex items-center gap-x-1 px-4 py-2 hover:bg-gray-100">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={24}
                                              height={24}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth={2}
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              className="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus w-[1rem] h-[1rem] text-[#ff591e]"
                                            >
                                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                              <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                              <path d="M9 12h6" />
                                              <path d="M12 9v6" />
                                            </svg>

                                            <span className='font-semibold text-[#ff591e] hover:text-[#ff591e]'>
                                              Create new recipient
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                    <div className='mt-5 flex justify-end'>
                                      <button
                                        onClick={handleActionExternalContact5}
                                        className=' flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm 
                                    font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                     focus-visible:outline-2 focus-visible:outline-offset-2
                                      focus-visible:outline-indigo-600'>
                                        {isActionLoading6 ? (
                                          <>
                                            Create action
                                            <div className="simple-spinner">
                                              <span></span>
                                            </div>
                                          </>
                                        ) : (
                                          'Create action'
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                  {
                                    newActionEnteral5 &&
                                    <>
                                      <div className="">
                                        <div className='w-full mt-5'>
                                          <div className='flex gap-y-[24px] flex-col'>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_first_name"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                First Name
                                              </label>
                                              <input
                                                id="contact_first_name"
                                                name="contact_first_name"
                                                type="text"
                                                maxLength={30}
                                                value={contactInfo5.contact_first_name}
                                                onChange={handleInputChange5}
                                                // placeholder="Jane Smith"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_last_name"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Last Name
                                              </label>
                                              <input
                                                id="contact_last_name"
                                                name="contact_last_name"
                                                type="text"
                                                maxLength={30}
                                                value={contactInfo5.contact_last_name}
                                                onChange={handleInputChange5}
                                                // placeholder="Jane Smith"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_email"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Email
                                              </label>
                                              <input
                                                id="contact_email"
                                                name="contact_email"
                                                value={contactInfo5.contact_email}
                                                onChange={handleInputChange5}
                                                maxLength={30}
                                                type="email"

                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_phone"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Phone no.
                                              </label>
                                              <input
                                                id="contact_phone"
                                                name="contact_phone"
                                                type="number"
                                                maxLength={30}
                                                value={contactInfo5.contact_phone}
                                                onChange={handleInputChange5}
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                            ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_company"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Company
                                              </label>
                                              <input
                                                id="contact_company"
                                                name="contact_company"
                                                value={contactInfo5.contact_company}
                                                onChange={handleInputChange5}
                                                maxLength={30}
                                                type="text"
                                                // placeholder="1234567890"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>
                                            <div className="relative">
                                              <label
                                                htmlFor="contact_job_title"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-normal text-dark-black-200 text-[13px]"
                                              >
                                                Job Title
                                              </label>
                                              <input
                                                id="contact_job_title"
                                                name="contact_job_title"
                                                value={contactInfo5.contact_job_title}
                                                onChange={handleInputChange5}
                                                type="text"
                                                maxLength={1000}
                                                // placeholder="Enter meta value"
                                                className="block w-full rounded-md border-0 py-1.5 text-[#52555a] text-[13px] shadow-sm ring-1 
                                        ring-inset ring-gray-300 placeholder:text-[#52555a] font-medium focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                                              />
                                            </div>


                                            <div className='w-auto flex justify-end'>
                                              <button
                                                type="button"
                                                onClick={handleDocumentContactSave5}
                                                className=" flex items-center gap-2 w-fit justify-end rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                              // onClick={() => handleSubmitItemCustomer(companyInfo)}
                                              // disabled={isUpdating}
                                              >

                                                {contactLoading5 ? (
                                                  <>
                                                    Create contact
                                                    <div className="simple-spinner">
                                                      <span></span>
                                                    </div>
                                                  </>
                                                ) : (
                                                  'Create contact'
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  }



                                </motion.div>
                              )}
                            </>
                          }



                          {
                            !newAction5 &&
                            <AnimatePresence initial={false}>
                              {!collapsed9 && (
                                <motion.div
                                  initial={{ height: 0 }}
                                  animate={{ height: 'auto' }}
                                  exit={{ height: 0, }}
                                  transition={{ height: { duration: 0.5 }, }}
                                  className="overflow-hidden"
                                >
                                  {isActionList?.data?.sign?.internal.length > 0 &&
                                    <span className='font-normal text-dark-black-200 text-[12px]'>Internal - </span>
                                  }
                                  {isActionList?.data?.sign?.internal &&
                                    isActionList.data.sign.internal.map((internalAction, index) => {
                                      // Assuming `baseMemberDetails` is an array and you want to find the member matching `internal_user_id`
                                      const baseMember = baseMemberDetails?.data?.baseMembers?.find(
                                        (member) => member.id === internalAction.internal_user_id
                                      );

                                      return (
                                        <div
                                          key={internalAction.id || index}
                                          className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                        >
                                          <div className="flex items-center gap-3">
                                            <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                              <span className="text-[10px] font-medium text-gray-700">
                                                {baseMember?.initials || 'A'}
                                              </span>
                                            </div>
                                            <p className="leading-relaxed">
                                              <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                {baseMember?.name && ` ${baseMember.name}`}
                                              </span>
                                            </p>
                                          </div>
                                          <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                            <CustomMenuDropdown
                                              icon={
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="12" cy="12" r="1"></circle>
                                                  <circle cx="12" cy="5" r="1"></circle>
                                                  <circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                              }
                                              altText="User profile"
                                              sections={dropdownGroups1}
                                              menuItemsClassName="left-auto top-[10px]"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}
                                  {
                                    isActionList?.data?.sign?.external?.length > 0 && (
                                      <>
                                        {
                                          // Combine the two lists and get only the first one.
                                          [
                                            ...(isActionList?.data?.sign?.external || []),
                                            ...(isActionList?.data?.sign?.external || [])
                                          ]
                                            .slice(0, 1) // Take only the first item
                                            .map((item, index) => {

                                              return (
                                                <div key={index} className="flex items-center space-x-2">
                                                  {item.external_contact_id && (
                                                    <span className="font-normal text-dark-black-200 text-[12px]">External - </span>
                                                  )}
                                                </div>
                                              );
                                            })
                                        }
                                      </>
                                    )
                                  }

                                  {
                                    isActionList?.data?.sign?.external?.length > 0 &&
                                    isActionList.data.sign.external.map((internalAction, index) => {
                                      const contact = internalAction.external_contact_id
                                        ? internalAction.contact
                                        : null; // Check if external_contact_id exists and assign contact

                                      if (!contact) {
                                        return null; // Skip rendering if contact is null
                                      }

                                      return (
                                        <div
                                          key={internalAction.id || index}
                                          className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover"
                                        >
                                          <div className="flex items-center gap-3">
                                            <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                              <span className="text-[10px] font-medium text-gray-700 capitalize">
                                                {
                                                  contact.contact_first_name
                                                    ? contact.contact_first_name.charAt(0).toLowerCase()
                                                    : contact.contact_last_name
                                                      ? contact.contact_last_name.charAt(0).toLowerCase()
                                                      : contact.contact_email
                                                        ? contact.contact_email.charAt(0).toLowerCase()
                                                        : ''
                                                }
                                              </span>
                                            </div>
                                            <div className="flex flex-col">
                                              <p className="leading-relaxed">
                                                <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                  {contact.contact_first_name && `${contact.contact_first_name} `}
                                                  {contact.contact_last_name && `${contact.contact_last_name} `}
                                                </span>
                                              </p>
                                              <p className="leading-relaxed">
                                                <span className="text-dark-black-100 mr-1 leading-4 flex items-center text-[12px] font-medium">
                                                  {contact.contact_email && `${contact.contact_email}`}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="[&>div]:shrink feedback-delete-btn-hover">
                                            <CustomMenuDropdown
                                              icon={
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="12" cy="12" r="1"></circle>
                                                  <circle cx="12" cy="5" r="1"></circle>
                                                  <circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                              }
                                              altText="User profile"
                                              sections={dropdownGroups1}
                                              menuItemsClassName="left-auto top-[5px]"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })
                                  }

                                </motion.div>
                              )}
                            </AnimatePresence>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              {/* sidebar icons  */}
              <div className='flex flex-col gap-y-4 px-[10px] pb-[10px] pt-4 fixed right-0 bg-white  h-full border-l border-solid border-light-gray-200 '>
                <button
                  onClick={handleFirstBar}
                  data-title="Info"
                  style={{ transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform' }}
                  className={`${isAsideOpen ? 'bg-gray-200' : 'bg-white'} table-tooltip-sidebar table-tooltip-info rounded-md duration-200 font-[600] text-[0.8125rem] h-[30px] w-[30px] flex items-center justify-center border border-solid border-[#e7e7e8]`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.1em"
                    width="1.1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-info-square-rounded"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 9h.01" />
                    <path d="M11 12h1v4h1" />
                    <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                  </svg>
                </button>
                <button
                  onClick={handleSecondBar}
                  data-title="Inputs"
                  style={{
                    transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform',
                  }}
                  className={`${isAsideOpen1 ? 'bg-gray-200' : 'bg-white'} table-tooltip-sidebar table-tooltip-inputs rounded-md duration-200 font-[600] text-[0.8125rem] h-[30px] w-[30px] flex items-center justify-center border border-solid border-[#e7e7e8]`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.1em"
                    width="1.1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-variable"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M5 4c-2.5 5 -2.5 10 0 16m14 -16c2.5 5 2.5 10 0 16m-10 -11h1c1 0 1 1 2.016 3.527c.984 2.473 .984 3.473 1.984 3.473h1" />
                    <path d="M8 16c1.5 0 3 -2 4 -3.5s2.5 -3.5 4 -3.5" />
                  </svg>
                </button>

                {/* activity */}
                <button
                  onClick={handleActivity}
                  data-title="Activity"
                  style={{
                    transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform',
                  }}
                  className={`${isAsideOpen2 ? 'bg-gray-200' : 'bg-white'} table-tooltip-sidebar table-tooltip-sidebar1 rounded-md duration-200 font-[600] text-[0.8125rem] h-[30px] w-[30px] flex items-center justify-center border border-solid border-[#e7e7e8]`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.1em"
                    width="1.1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-arrows-sort"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 9l4 -4l4 4m-4 -4v14" />
                    <path d="M21 15l-4 4l-4 -4m4 4v-14" />
                  </svg>

                </button>
                {/* chat */}
                <button
                  onClick={handleChat}
                  data-title="Chat"
                  style={{
                    transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform',
                  }}
                  className={`${isAsideOpen3 ? 'bg-gray-200' : 'bg-white'} table-tooltip-sidebar rounded-md duration-200 font-[600] text-[0.8125rem] h-[30px] w-[30px] flex items-center justify-center border border-solid border-[#e7e7e8]`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.1em"
                    width="1.1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-message"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M8 9h8" />
                    <path d="M8 13h6" />
                    <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
                  </svg>


                </button>
                {/* Action */}
                <button
                  onClick={handleAction}
                  data-title="Action"
                  style={{
                    transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform',
                  }}
                  className={`${isAsideOpen4 ? 'bg-gray-200' : 'bg-white'} table-tooltip-sidebar rounded-md duration-200 font-[600] text-[0.8125rem] h-[30px] w-[30px] flex items-center justify-center border border-solid border-[#e7e7e8]`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.1em"
                    width="1.1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-repeat-once"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 12v-3a3 3 0 0 1 3 -3h13m-3 -3l3 3l-3 3" />
                    <path d="M20 12v3a3 3 0 0 1 -3 3h-13m3 3l-3 -3l3 -3" />
                    <path d="M11 11l1 -1v4" />
                  </svg>
                </button>
              </div>
            </main>

          </div>
        </div>
      </div>



      <Transition.Root show={showDeleteConfirmation} as={Fragment}>
        <Dialog as="div" className="relative z-50 md:z-10 lg:z-10" onClose={() => setShowDeleteConfirmation(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-start h-full items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[500px] sm:p-6">
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete your Document?
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-4 flex gap-2 justify-end">
                    <button
                      type="button"
                      // onClick={handleCustomerDelete}
                      className="w-fit inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium
                       text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none 
                       focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
                      disabled={deleteLoading}
                    >
                      {deleteLoading ? (
                        <>
                          Delete
                          <div className="simple-spinner">
                            <span></span>
                          </div>
                        </>
                      ) : (
                        'Delete'
                      )}
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowDeleteConfirmation(false)}
                      className="mt-3 inline-flex leading-6 w-fit justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>


    </div>
  );
});

export default DocumentDetails;