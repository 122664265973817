import React, { useState, KeyboardEvent, useEffect } from "react";
import { getColorForLabel } from "../../utils/colorUtils";

interface Tag {
  id?: any;
  name?: string;
  label?: string;
  value: string;
  isNew?: boolean;
  color?: string;
}

interface MultiSelectLabelFilterProps {
  selectedTags: Tag[];
  setSelectedTags: React.Dispatch<React.SetStateAction<Tag[]>>;
  handleBoardChange: (newValue: Tag[], fieldName: string) => void;
  options: any;
  setCreatedMenuOpen: (isOpen: boolean) => void;
  editingFilter: { category: string, id: string } | null;
  handleFilterUpdate: (category: string, oldId: string, newId: string, newValue: string, newIcon: React.ReactNode) => void;
}

const MultiSelectLabelFilter: React.FC<MultiSelectLabelFilterProps> = ({
  selectedTags,
  setSelectedTags,
  handleBoardChange,
  options,
  setCreatedMenuOpen,
  editingFilter,
  handleFilterUpdate
}) => {
  const [newTagValue, setNewTagValue] = useState<string>("");

  const handleCreateOrUpdateTag = (inputValue: string) => {
    const trimmedValue = inputValue.trim();
    if (trimmedValue) {
      const tagColor = getColorForLabel(trimmedValue);
      const newId = Date.now().toString();

      if (editingFilter && editingFilter.category === 'label') {
        handleFilterUpdate(
          'label',
          editingFilter.id,
          newId,
          trimmedValue,
          <span className="text-[#1d2025] w-2 h-2 rounded-full" style={{ backgroundColor: tagColor, borderColor: tagColor }}></span>
        );
        setSelectedTags(prevTags => 
          prevTags.map(tag => 
            tag.id === editingFilter.id 
              ? { ...tag, id: newId, value: trimmedValue, color: tagColor }
              : tag
          )
        );
      } else {
        const newTag = {
          value: trimmedValue,
          isNew: true,
          id: Date.now(),
          color: tagColor
        };
        setSelectedTags(prevTags => {
          const updatedTags = [...prevTags, newTag];
          handleBoardChange(updatedTags, 'tags');
          return updatedTags;
        });
        handleFilterUpdate(
          'label',
          newId,
          newId,
          trimmedValue,
          <span className="text-[#1d2025] w-2 h-2 rounded-full" style={{ backgroundColor: tagColor, borderColor: tagColor }}></span>
        );
      }
      setNewTagValue("");
      setCreatedMenuOpen(false);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleCreateOrUpdateTag(newTagValue);
    } else if (e.key === 'Escape') {
      setNewTagValue("");
      setCreatedMenuOpen(false);
    }
  };

  useEffect(() => {
    if (editingFilter && editingFilter.category === 'label') {
      const tagToEdit = selectedTags.find(tag => tag.id === editingFilter.id);
      if (tagToEdit) {
        setNewTagValue(tagToEdit.value);
      }
    }
  }, [editingFilter, selectedTags]);

  return (
    <div className='w-full'>
      <div className="flex items-center relative w-[220px]">
        <input
          type="text"
          value={newTagValue}
          onChange={(e) => setNewTagValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={editingFilter ? "Edit label" : "Add label"}
          className="border absolute top-[9px] w-full border-none rounded px-2 py-1 mr-2 bg-[#fff] text-[13px] multi-select-dropdown-shadow filter-dropdown focus:border-[#ccc]"
        />
        <button
          onClick={() => handleCreateOrUpdateTag(newTagValue)}
          className="absolute right-0 top-[20px] bg-transparent text-[#1e1e1e] rounded px-3 py-1 flex items-center"
        >
          {editingFilter ? (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default MultiSelectLabelFilter;
