// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { SlashCommand } from '../../extensions/SlashCommand';
import { Heading } from '../../extensions/Heading'
import { BlockquoteFigure } from '../../extensions/BlockquoteFigure';
// import { CodeBlock } from '../../extensions/CodeBlock';
import { Table, TableCell, TableHeader, TableRow } from '../../extensions/Table';
import { Toolbar } from '../ui/Toolbar'
// import { TableColumnMenu, TableRowMenu } from '../../extensions/Table/menus';
import { Icon } from '../ui/Icon';
import { HocuspocusProvider } from '@hocuspocus/provider'
import ImageBlockMenu from '../../extensions/ImageBlock/components/ImageBlockMenu';
// import { Button } from '../ui/Button';
import Column from '../../extensions/MultiColumn/Column';
import { ColumnsMenu } from '../../extensions/MultiColumn/menus';
import { Columns } from '../../extensions/MultiColumn/Columns';
import { ImageUpload } from '../../extensions/ImageUpload';
import ImageBlock from '../../extensions/ImageBlock/ImageBlock';
import { HorizontalRule } from '../../extensions/HorizontalRule';
import Placeholder from '@tiptap/extension-placeholder';
import Youtube from '@tiptap/extension-youtube'
import YouTubeEmbed from '../../extensions/YouTube/YouTubeComponent';
import { LinkMenu, TextMenu } from '../menus';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import FontFamily from '@tiptap/extension-font-family';
import TextStyle from '@tiptap/extension-text-style';
import TextAlign from '@tiptap/extension-text-align';
import Color from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import Mention from '@tiptap/extension-mention';
import suggestion from '../../extensions/mention/suggestions'
import History from '@tiptap/extension-history';
// 
import { memo } from 'react'
import { MentionData } from '../../extensions/variable/Variable';
// import { useMention } from '../../MentionProvider';
import suggetionsData from '../../extensions/variable/suggetionsData'
import { deleteMentionList, getMentionList, setEditorInstance, updateMentionCount } from '../../editor';
import { ColoredBrackets } from '../../extensions/variable/VariablePastPlugin';
import { EditLinkPopover } from '../menus/TextMenu/components/EditLinkPopover';
import * as Popover from '@radix-ui/react-popover'
import ColorPickerDropdown from '../panels/Colorpicker/Colorpicker';
import { useTextmenuCommands } from '../menus/TextMenu/hooks/useTextmenuCommands';
import { useTextmenuStates } from '../menus/TextMenu/hooks/useTextmenuStates';
import { ContentTypePicker } from '../menus/TextMenu/components/ContentTypePicker';
import { useTextmenuContentTypes } from '../menus/TextMenu/hooks/useTextmenuContentTypes';
import { FontFamilyPicker } from '../menus/TextMenu/components/FontFamilyPicker';
import { Groq } from "groq-sdk";
import Image from '@tiptap/extension-image'
import { marked } from "marked";
import { toast } from 'sonner';
import HardBreak from '@tiptap/extension-hard-break';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
// import ColorPickerDropdown from '../panels/Colorpicker/Colorpicker';
import { all, createLowlight } from 'lowlight'
// import Focus from '@tiptap/extension-focus'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchDocument } from '../../features/document/documentSlice';
import { useParams } from 'react-router-dom';
import Gapcursor from '@tiptap/extension-gapcursor';
import LoadingSpinner from '../common/LoadingSpinner';
import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import MarkdownIt from 'markdown-it';
import { generateJSON } from '@tiptap/core';
import Blockquote from '@tiptap/extension-blockquote';
import html2canvas from 'html2canvas';
import { DOMSerializer } from 'prosemirror-model'
import { fixTipTapContent } from '../../extensions/variable/migrate-to-tiptap-format'
// create a lowlight instance with all languages loaded
// interface MenuItem {
//   label: string;
//   action: () => void;
// }

interface ExtensionKitProps {
  provider?: HocuspocusProvider | null;
}

interface TipTapEditorProps {
  provider?: any;
  isAsideOpen?: boolean;
  isAsideOpen1?: boolean;
  isAsideOpen2?: boolean;
  isAsideOpen3?: boolean;
  isAsideOpen4?: boolean;
  onEditorReady?: (editor: any) => void;
  data?: any;
  onColorChange?: (name: string, color: string) => void;
  onDeleteSpanByName? :  (name: string) => void; 
}




interface ExtensionKitProps {
  provider?: HocuspocusProvider | null
}


let variablesList = getMentionList();
let isAiActive = false;

let dataofai: any;
let groq = new Groq({
  apiKey: process.env.REACT_APP_GROQ_API_KEY,
  dangerouslyAllowBrowser: true,
});

const getGroqChatCompletion = async (content: string) => {
  return groq.chat.completions.create({
    messages: [
      {
        role: "user",
        content: content,
      },
    ],
    model: "llama3-70b-8192",
    stream: true,
  });
};

const TipTapEditor = ({ 
  provider, 
  isAsideOpen, 
  isAsideOpen1, 
  isAsideOpen2, 
  isAsideOpen3,
  isAsideOpen4,
  onEditorReady, 
  data,
  onColorChange ,
  onDeleteSpanByName,
}: TipTapEditorProps) => {
  const [imageBase64, setImageBase64] = useState('');
  const [showYoutubeModal, setShowYoutubeModal] = useState(false);
  let [variableValue, setVariableValue] = useState('')
  let [aiResponse, setAiResponse] = useState("");
  const [variables, setVariables] = React.useState<string[]>(variablesList); // Example variables
  const [variableValues, setVariableValues] = React.useState<{ [key: string]: string }>({});
  const [isModalOpenCheckbox, setIsModalOpenCheckbox] = useState(false);
  const [isModalOpenVariable, setIsModalOpenVariable] = useState(true);
  const [name, setName] = useState('')
  const [loadingAiResponse, setLoadingAiResponse] = useState(false)
  const [displayName, setDisplayName] = useState('Input text');
  const [isTopZero, setIsTopZero] = useState(false);
  const [modalPosition, setModalPosition] = useState(-39);
  const MemoButton = memo(Toolbar.Button)
  const lowlight = createLowlight(all)
  const dispatch = useAppDispatch();
  const { baseId } = useParams<{ baseId: string }>();
  const { id } = useParams<{ id: string }>();

  const setMentionsColor = (color: string) => {
    if (!editor) return;
    
    // Get all mention elements
    const mentions = document.querySelectorAll('.mention');
    
    // Update the color for each mention
    mentions.forEach(mention => {
      (mention as HTMLElement).style.color = color;
    });
    
    // Update editor's state to persist the changes
    editor.commands.focus();
  };
  

  const EventHandler = Extension.create({
    name: 'eventHandler',

    addProseMirrorPlugins() {
      return [
        new Plugin({
          key: new PluginKey('eventHandler'),
          props: {
            handlePaste: (view, event) => {
              const clipboardText = event.clipboardData?.getData('text/plain');
              if (!clipboardText) return false;

              // Prevent default paste behavior
              event.preventDefault();

              // Use MarkdownIt to parse clipboard text as markdown and convert it to HTML
              const md = new MarkdownIt();
              const htmlContent = md.render(clipboardText);

              // Convert HTML to ProseMirror JSON format
              const contentJSON = generateJSON(htmlContent, [
                StarterKit,
                Link.configure({ openOnClick: false }),
                Blockquote,
                Table.configure({ resizable: true }),
                TableRow,
                TableCell,
                TableHeader
              ]);

              // Dispatch the transaction to insert the table content
              view.dispatch(
                view.state.tr.replaceSelectionWith(
                  view.state.schema.nodeFromJSON(contentJSON)
                )
              );

              return true;
            }
          }
        })
      ];
    }
  });

  const editor = useEditor({
    extensions: [
      EventHandler,
      ColoredBrackets,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      Columns,
      MentionData.configure({
        HTMLAttributes: {
          class: 'mention',
          style: 'color: #4f46e5', // Default color
        },
        suggestion: suggetionsData,
      }),
      Gapcursor,
      Image.configure({ inline: true, allowBase64: true }),
      Document,
      Paragraph,
      Text,
      HardBreak,
      Heading.configure({
        levels: [1, 2, 3, 4, 5, 6],
      }),
      HorizontalRule,
      Placeholder.configure({
        placeholder: 'Write something …',
      }),

      StarterKit
        .configure({
          document: false,
          paragraph: false,
          text: false,
          bulletList: true,
          orderedList: true,
          taskList: true,
          Blockquote,
          // blockquote: true,
          CodeBlockLowlight: false,
          horizontalRule: false,
          history: false
        }),
      Underline,
      Link,
      // BlockquoteFigure,
      CodeBlockLowlight.configure({
        lowlight,
      }),
      // Focus.configure({
      //   className: 'has-focus',
      //   mode: 'all',
      // }),
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      ImageUpload.configure({
        clientId: provider?.document?.clientID,
      }),
      ImageBlock.configure({
        // inline :
        // inline :true ,
        HTMLAttributes: {
          class: 'image-block',
        },
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Youtube.configure({
        HTMLAttributes: {
          class: 'youtube-video w-full aspect-video',
        },
        // inline: false,
        addPasteHandler: true,
      }),
      History.configure({
        depth: 10,
      }),
      FontFamily,
      TextStyle,
      Color,
      Highlight.configure({
        multicolor: true,
        HTMLAttributes: {
          class: 'highlighted-text',
        },
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion,
      }),
      Column,
      // TableHeader,
      SlashCommand.configure({
        suggestion: {
          items: ({ query }: any) => {
            return [
              {
                title: "Ai writer",
                command: ({ editor }: any) =>
                  editor.chain().focus()
                    .insertContent(`<div  className=" mt-6 mb-8 p-5">

                <h4 className=' p-5 m-5'>Use Ai to create content</h4>
                <textarea name="aitext" onBlur={ (e)=>setPromptText(e)} id=""  ></textarea> <br />
                <button className=' rounded pl-3 pr-3 mr-5 bg-gray-400 text-white' onClick={(e)=>setAiData(e)}>submit</button>
                <button className='rounded pl-3 pr-3 bg-blue-500 text-white' onClick={(e)=>addAiContent(e)}>Add Content</button>

                </div>`),
              },
              {
                title: 'Heading 1',
                command: ({ editor }: any) => editor.chain().focus().toggleHeading({ level: 1 }).run(),
              },
              {
                title: 'Heading 2',
                command: ({ editor }: any) => editor.chain().focus().toggleHeading({ level: 2 }).run(),
              },
              {
                title: 'Bullet List',
                command: ({ editor }: any) => editor.chain().focus().toggleBulletList().run(),
              },
              {
                title: 'Numbered List',
                command: ({ editor }: any) => editor.chain().focus().toggleOrderedList().run(),
              },
              {
                title: 'Task List',
                command: ({ editor }: any) => editor.chain().focus().toggleTaskList().run(),
              },
              {
                title: 'Table',
                command: ({ editor }: any) => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run(),
              },
              {
                title: 'Blockquote',
                command: ({ editor }: any) => editor.chain().focus().setBlockquote().run(),
              },
              {
                title: 'Code Block',
                command: ({ editor }) => editor.chain().focus().setCodeBlock().run(),
              },
              {
                title: 'Image',
                command: ({ editor }) => {
                  editor.chain().focus().setImageUpload().run();
                },
              },
              {
                title: 'Column',
                command: ({ editor }) => {
                  editor.chain().focus().setColumns().run();
                },
              },
              {
                title: 'Horizontal Rule',
                command: ({ editor }: any) => editor.chain().focus().setHorizontalRule().run(),
              },
              {
                title: 'YouTube',
                description: 'Add a YouTube video',
                command: ({ editor }) => {
                  // Access setShowYoutubeModal through the custom attribute
                  if (typeof editor.options.editorProps.customSetters?.setYoutubeModal === 'function') {
                    editor.options.editorProps.customSetters.setYoutubeModal(true);
                  }
                },
              },

            ].filter(item => item.title.toLowerCase().includes(query.toLowerCase()));
          },
        },
      }),
    ],
    onCreate: ({ editor }) => {
      // setEditorInstance(editor);
      
      editor.setMentionsColor = setMentionsColor;
      
      setEditorInstance(editor);
      if (onEditorReady) {
        onEditorReady(editor); // Pass the original editor instance
      }
    },    
    onUpdate: ({ editor }) => {
      // Optionally save content on update
      const content = editor.getJSON();
    },
    editorProps: {
      customSetters: {
        setYoutubeModal: setShowYoutubeModal
      },
      attributes: {
        class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none p-4 min-h-full  rounded-lg',
      },
    },
  });
  const commands = useTextmenuCommands(editor)
  const states = useTextmenuStates(editor)
  const menuContainerRef = useRef(null);
  const [showTableControls, setShowTableControls] = useState(false);
  const [promptText, setPromptText] = useState("");
  // const [controlsPosition, setControlsPosition] = useState({ top: 0, left: 0 });
  const blockOptions = useTextmenuContentTypes(editor)
  useEffect(() => {
    const handleTableSelection = () => {
      if (editor?.isActive('table')) {
        const { view } = editor;
        const { from } = view.state.selection;
        // const coords = view.coordsAtPos(from);

        setShowTableControls(true);
        // setControlsPosition({
        //   top: coords.top - 40,
        //   left: coords.left,
        // });
      } else {
        setShowTableControls(false);
      }
    };

    editor?.on('selectionUpdate', handleTableSelection);

    return () => {
      editor?.off('selectionUpdate', handleTableSelection);
    };
  }, [editor]);

  useEffect(() => {
    // Function to handle blur event
    // const handleEditorBlur = () => {
    //   console.log("Editor lost focus");
    //   console.log(getMentionList())
    //   // Any additional functionality can be added here
    // };

    // Add blur event listener
    editor.on('blur', updateMentionCountsOnBlur);

    // Cleanup function to remove event listener on component unmount
    return () => {
      editor.off('blur', updateMentionCountsOnBlur);
    };
  }, [editor]);


  const handleAddRowBefore = () => {
    editor.chain().focus().addRowBefore().run();
  };

  const handleAddRowAfter = () => {
    editor.chain().focus().addRowAfter().run();
  };

  const handleDeleteRow = () => {
    editor.chain().focus().deleteRow().run();
  };

  // Column operations
  const handleAddColumnBefore = () => {
    editor.chain().focus().addColumnBefore().run();
  };

  const handleAddColumnAfter = () => {
    editor.chain().focus().addColumnAfter().run();
  };

  const handleDeleteColumn = () => {
    editor.chain().focus().deleteColumn().run();
  };

  // Helper function to merge cells
  const handleMergeCells = () => {
    editor.chain().focus().mergeCells().run();
  };

  // Helper function to split cells
  const handleSplitCell = () => {
    editor.chain().focus().splitCell().run();
  };

  const handleYouTubeSubmit = (url: string) => {
    try {
      if (!editor) return;

      // Updated YouTube URL validation
      const isValidUrl = url.match(/^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/|v\/|.+\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})$/);
      if (!isValidUrl) {
        console.error('Invalid YouTube URL');
        return;
      }

      editor.chain().focus().setYoutubeVideo({
        src: url,
        width: 640,
        height: 480,
      }).run();

      setShowYoutubeModal(false);
    } catch (error) {
      console.error('Error adding YouTube video:', error);
    }
  };


  // const applyHighlight = (color) => {
  //   editor?.chain().focus().toggleHighlight({ color }).run();
  // };

  // const applyColor = (color) => {
  //   editor?.chain().focus().setColor(color).run();
  // };

  const addAiContent = () => {
    let content = marked(aiResponse);

    editor?.chain().focus().insertContent(content).run();
    // let htm =`<div  className=" mt-6 mb-8 p-5">

    //             <h4 className=' p-5 m-5'>Use Ai to create content</h4>
    //             <textarea name="aitext" onBlur={ (e)=>setPromptText(e)} id=""  ></textarea> <br />
    //             <button className=' rounded pl-3 pr-3 mr-5 bg-gray-400 text-white' onClick={(e)=>setAiData(e)}>submit</button>
    //             <button className='rounded pl-3 pr-3 bg-blue-500 text-white' onClick={(e)=>addAiContent(e)}>Add Content</button>

    //             </div>`
    // editor?.chain().focus().insertContent(htm).run();
    setAiResponse("");
    setPromptText("");
  };


  // Function to process and format AI response
  const formatAiResponse = (response: string) => {
    return (
      <div
        className="prose prose-sm max-w-none"
        dangerouslySetInnerHTML={{
          __html: marked(response, {
            gfm: true, // GitHub Flavored Markdown
            breaks: true, // Convert line breaks to <br>
            sanitize: false, // Allow HTML tags
            smartLists: true, // Use smart list behavior
            smartypants: true // Use smart punctuation
          })
        }}
      />
    );
  };

  let displeyData = "";

  // Modified setAiData function
  // const setAiData = async () => {
  //   if (promptText === "") {
  //     toast.error("Please enter prompt");
  //     return;
  //   }

  //   setDisplayName(promptText || 'Input text');
  //   setLoadingAiResponse(true);
  //   let accumulatedData = "";
  //   let buffer = "";

  //   try {
  //     const stream = await getGroqChatCompletion(promptText);

  //     for await (const chunk of stream) {
  //       let data = chunk.choices[0]?.delta?.content || "";
  //       buffer += data;

  //       const lastSpaceIndex = buffer.lastIndexOf(" ");

  //       if (lastSpaceIndex !== -1) {
  //         const completeText = buffer.slice(0, lastSpaceIndex + 1);
  //         accumulatedData += completeText;
  //         buffer = buffer.slice(lastSpaceIndex + 1);

  //         // Update the formatted response
  //         setAiResponse(accumulatedData);
  //       }
  //     }

  //     if (buffer.length > 0) {
  //       accumulatedData += buffer;
  //       setAiResponse(accumulatedData);
  //     }

  //   } catch (error) {
  //     toast.error("Error generating content");
  //   } finally {
  //     setLoadingAiResponse(false);
  //     setIsModalOpenVariable(false);
  //   }
  // };

  const setAiData = async () => {
    if (promptText === "") {
      toast.error("Please enter prompt");
      return;
    }

    setDisplayName(promptText || "Input text");
    setLoadingAiResponse(true);
    let accumulatedData = "";

    try {
      const response = await fetch("https://api.teamerhr.com/v1/open-api/tiptap.content", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content: promptText }),
      });

      if (!response.body) throw new Error("No response body");

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        // Decode the incoming chunk and parse only the latest data update
        const chunk = decoder.decode(value, { stream: true });

        // Extract JSON payload from the 'data:' field of each event
        const matches = chunk.match(/data:\s*(\{.*\})/g);
        if (matches) {
          matches.forEach(match => {
            const jsonData = JSON.parse(match.replace('data: ', ''));
            accumulatedData += jsonData.content;
            setAiResponse(accumulatedData); // Update progressively
          });
        }
      }
    } catch (error) {
      toast.error("Error generating content");
    } finally {
      setLoadingAiResponse(false);
      // setLoadingAiResponse(false);
      setIsModalOpenVariable(false);
    }
  };


  const getStyledHTMLContent = (htmlContent) => {
    const tempDiv = document.createElement('div');
    tempDiv.style.position = 'absolute';
    tempDiv.style.left = '-9999px';
    tempDiv.style.width = '678px';
    tempDiv.style.overflowWrap = 'break-word';
    tempDiv.style.wordWrap = 'break-word';
    tempDiv.style.whiteSpace = 'pre-wrap';

    // Append all stylesheets to the tempDiv
    Array.from(document.styleSheets).forEach((stylesheet) => {
      try {
        if (stylesheet.cssRules) {
          const cssRules = Array.from(stylesheet.cssRules).map(rule => rule.cssText).join('\n');
          const styleElement = document.createElement('style');
          styleElement.innerHTML = cssRules;
          tempDiv.appendChild(styleElement);
        }
      } catch (error) {
        console.warn('Could not access stylesheet:', error);
      }
    });

    tempDiv.innerHTML += htmlContent;
    document.body.appendChild(tempDiv); // Append tempDiv to the document for rendering

    return tempDiv;
  };

  function getHTMLContentBetween(
    editor: Editor,
    from: number,
    to: number
  ) {
    const { state } = editor
    const nodesArray: string[] = []

    state.doc.nodesBetween(from, to, (node, pos, parent) => {
      if (parent === state.doc) {
        const serializer = DOMSerializer.fromSchema(editor.schema)
        const dom = serializer.serializeNode(node)
        const tempDiv = document.createElement('div')
        tempDiv.appendChild(dom)
        nodesArray.push(tempDiv.innerHTML)

      }
    })

    return nodesArray.join('')
  }

  const extractSelectedText = async () => {
    //Only execute if there's an active selection
    if (editor && editor.state.selection.empty === false) {
      const { from, to } = editor.state.selection;
      const selectedHtml = editor.view.dom.innerHTML.slice(from, to);
      let data = getHTMLContentBetween(editor, from, to)
      const styledDiv = getStyledHTMLContent(data);

      // Use html2canvas to render styled HTML content to an image
      const canvas = await html2canvas(styledDiv, { width: 678, backgroundColor: null });
      const imgDataUrl = canvas.toDataURL('image/png');

      setImageBase64(imgDataUrl); // Update the state with image URL for display

      document.body.removeChild(styledDiv); // Clean up temporary div
    } else {
      console.log('No text selected.');
    }
  };


  function replaceSpanContent(name: string) {
    // Get the current HTML content of the editor
    const newValue = variableValues[name] || "";
    const currentHTML = editor.getHTML();

    const tempElement = document.createElement('div');
    tempElement.innerHTML = currentHTML;

    const spans = tempElement.querySelectorAll(`span.mention[data-name="${name}"]`);
    spans.forEach(span => {
      span.innerHTML = `${newValue}`; // Set the new value inside the span
    });

    editor.commands.setContent(tempElement.innerHTML);


  }
  // function deleteSpanByName(name) {
  //   const currentHTML = editor.getHTML();

  //   // Create a temporary element to manipulate the HTML
  //   const tempElement = document.createElement('div');
  //   tempElement.innerHTML = currentHTML;

  //   // Select all spans with the specified data-name attribute
  //   const spans = tempElement.querySelectorAll(`span.mention[data-name="${name}"]`);

  //   // Remove each matching span from the HTML
  //   spans.forEach(span => {
  //     span.remove();
  //   });

  //   // Update the editor with the modified content
  //   editor.commands.setContent(tempElement.innerHTML);
  //   let newVariable = deleteMentionList(name)
  //   setVariables(newVariable)
  // }

  const updateMentionCountsOnBlur = () => {
    const currentHTML = editor.getHTML();
    const tempElement = document.createElement('div');
    tempElement.innerHTML = currentHTML;
    let mentionList2 = getMentionList()
    mentionList2.forEach((mention) => {
      const spans = tempElement.querySelectorAll(`span.mention[data-name="${mention.name}"]`);
      const count = spans.length;

      // Update the mention count
      updateMentionCount(mention.name, count);
    });

    // Update the variables with the modified mentionList
    setVariables([...getMentionList()]);
  };


  const handleAddVariable = (variable: string) => {
    const value = variableValues[variable] || ""; // Get the current value from state
    const variablePlaceholder = `[${variable}]`;

    // Get the current HTML content
    const currentContent = editor?.getHTML() || "";

    // Create a DOM parser to work with the HTML content
    const parser = new DOMParser();
    const doc = parser.parseFromString(currentContent, "text/html");

    // Find all span elements with the class "mention"
    const mentions = doc.querySelectorAll("span.mention");

    // Loop through each mention and replace the inner text if it matches the variable placeholder
    mentions.forEach((mention) => {
      if (mention.textContent === variablePlaceholder) {
        mention.textContent = value; // Replace the placeholder with the actual value
      }
    });

    const updatedContent = doc.body.innerHTML;

    // Update the editor content
    editor?.commands.setContent(updatedContent);
  };

  const handleInputChange = (variable: string, value: string) => {
    const currentHTML = editor.getHTML();
    setVariableValues((prev) => ({ ...prev, [variable]: value })); // Update the value for the specific variable
  };



  // chat gpt

  const hanldeChatGpt = () => {
    setIsModalOpenCheckbox(!isModalOpenCheckbox);
    setIsTopZero(false)

    if (!isModalOpenCheckbox && aiResponse.length > 0) {
      // When opening the modal
      setModalPosition(-39); // Reset to initial position
    }
  }

  // useEffect(() => {
  //   if (aiResponse && editor) {
  //     editor.commands.setContent(aiResponse); // Set aiResponse content into the editor
  //   }
  // }, [aiResponse, editor]);

  const handleAiInsert = () => {
    let content = marked(aiResponse);
    editor?.chain().focus().insertContent(content).run();
    setAiResponse("");
    setIsModalOpenCheckbox(false)
    setIsTopZero(true);
    setIsModalOpenVariable(true);
    setPromptText('')
    setDisplayName('Text Input')
    setModalPosition(-39);
  };

  const handleAiCancel = () => {
    setAiResponse("");
    setIsModalOpenCheckbox(true)
    setIsTopZero(false)
    setIsModalOpenVariable(true);
    setPromptText('')
    setDisplayName('Text Input')
    setModalPosition(-39);
  };

  // Update editor content when document data is fetched
  useEffect(() => {
    if (editor && data?.data?.doc_content) {
      try {
        // Parse the JSON string content
        const parsedContent = JSON.parse(data.data.doc_content);
        // Set the parsed content in the editor
        editor.commands.setContent(parsedContent);
      } catch (error) {
        console.error('Error parsing document content:', error);
      }
    }
  }, [editor, data]);

  // function deleteSpanByName(name) {
  //   const currentHTML = editor.getHTML();
  //   const tempElement = document.createElement('div');
  //   tempElement.innerHTML = currentHTML;
  //   const spans = tempElement.querySelectorAll(`span.mention[data-name="${name}"]`);
  //   spans.forEach(span => span.remove());
  //   editor.commands.setContent(tempElement.innerHTML);
  //   const newVariable = deleteMentionList(name);
  //   setVariables(newVariable);
  // }

  // // Call onEditorReady to pass deleteSpanByName function
  // useEffect(() => {
  //   if (editor && onEditorReady) {
  //     onEditorReady(editor, deleteSpanByName); // Pass deleteSpanByName
  //   }
  // }, [editor, onEditorReady]);
  const deleteSpanByName = useCallback((name) => {
    if (!editor) return;
    
    const currentHTML = editor.getHTML();
    const tempElement = document.createElement('div');
    tempElement.innerHTML = currentHTML;
  
    const spans = tempElement.querySelectorAll(`span.mention[data-name="${name}"]`);
    spans.forEach(span => span.remove());
  
    editor.commands.setContent(tempElement.innerHTML);
  
    // Avoid directly calling setVariables here if possible
  }, [editor]);
  
  // Effect to handle editor initialization with onEditorReady
  useEffect(() => {
    if (editor && onEditorReady) {
      onEditorReady(editor, deleteSpanByName);
    }
  }, [editor, onEditorReady, deleteSpanByName]);


  if (!editor) {
    return null;
  }


  return (
    <div className='flex gap-x-5 h-full justify-between'>
      <div className='w-full max-w-[100%] bg-white rounded-lg tiptap-editor-container h-full '>

        <div className="relative max-w-full md:max-w-[710px] lg:max-w-[710px] m-auto flex flex-1 h-full overflow-visible" ref={menuContainerRef}>
          {showTableControls && (
            <div
              className=" absolute bottom-[172px] right-[18px] z-50 bg-white rounded-lg "
            >
              <div className="flex items-center p-2 gap-2">
                <div className="flex items-center">
                  <button
                    onClick={handleAddRowBefore}
                    className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded w-full"
                  >
                    <Icon name="ArrowUpToLine" className="w-4 h-4" />

                  </button>
                  <button
                    onClick={handleAddRowAfter}
                    className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded w-full"
                  >
                    <Icon name="ArrowDownToLine" className="w-4 h-4" />

                  </button>
                  <button
                    onClick={handleDeleteRow}
                    className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded w-full text-red-600"
                  >
                    <Icon name="Trash" className="w-4 h-4" />

                  </button>
                </div>
                <div className="flex items-center">
                  <button
                    onClick={handleAddColumnBefore}
                    className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded w-full"
                  >
                    <Icon name="ArrowLeftToLine" className="w-4 h-4" />
                  </button>
                  <button
                    onClick={handleAddColumnAfter}
                    className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded w-full"
                  >
                    <Icon name="ArrowRightToLine" className="w-4 h-4" />
                  </button>
                  <button
                    onClick={handleDeleteColumn}
                    className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded w-full text-red-600"
                  >
                    <Icon name="Trash" className="w-4 h-4" />
                  </button>
                  <button
                    onClick={handleMergeCells}
                    className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded w-full"
                  >
                    <Icon name="Combine" className="w-4 h-4" />
                  </button>
                  <button
                    onClick={handleSplitCell}
                    className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded w-full"
                  >
                    <Icon name="Split" className="w-4 h-4" />
                  </button>
                </div>
              </div>

            </div>
          )}

          <EditorContent editor={editor} className="flex-1" />
          <ColumnsMenu editor={editor} appendTo={menuContainerRef} />
          <ImageBlockMenu
            editor={editor}
            appendTo={menuContainerRef}
          />

          <TextMenu editor={editor}  extractSelectedText={extractSelectedText} />
          <LinkMenu editor={editor} appendTo={menuContainerRef} />
          {showYoutubeModal && (
            <YouTubeEmbed
              onSubmit={handleYouTubeSubmit}
              onClose={() => setShowYoutubeModal(false)}
            />
          )}
        </div>

        <div>
          {imageBase64 && <img style={{
            width: '100%',
            height: '100%',
            objectFit: 'scale-down',
          }} src={imageBase64} alt="Generated Preview" />}
        </div>

        <div
          className={`absolute p-2 ${isModalOpenCheckbox ? 'rounded-b-lg' : 'rounded-lg'}
              ${isAsideOpen ? 'mq1800:left-[6%] mq3000:left-[18.5%]' : isAsideOpen1 ? 'mq1800:left-[6%] mq3000:left-[18.5%]'
              : isAsideOpen2 ? 'mq1800:left-[6%] mq3000:left-[18.5%]' : isAsideOpen3 ? 'mq1800:left-[6%] mq3000:left-[18.5%]' : isAsideOpen4 ? 'mq1800:left-[6%] mq3000:left-[18.5%]'
                : isModalOpenCheckbox ? 'mq1800:left-[20.2%] mq3000:left-[28.5%]' : 'mq1800:left-[20.2%] mq3000:left-[28.7%]'} 
                 bottom-[10px] mx-5 bg-white dark:bg-black shadow-sm border border-neutral-200 flex items-center`}
        >
          {/* chat gpt modal */}
          <div open={isModalOpenCheckbox} onClose={() => { }} className={`${isModalOpenCheckbox ? 'common-slider-one' : 'common-slider-two'}`}
          >

            <div
              className={`fixed flex checkbox-modal h-[96px] w-[633.05px] max-w-[640px] bottom-0
           ${isModalOpenCheckbox ? '' : '!top-[51px]'}
           ${isTopZero ? '!top-[49px]' :
                  aiResponse.length > 0 ? 'top-[-415px] h-[240px]' :
                    !isTopZero ? 'top-[-39px]' :
                      `top-[${modalPosition}px]`}`}
            //    className={`${isModalOpenCheckbox ? 
            //     'fixed flex checkbox-modal h-[96px] w-[633.05px] max-w-[640px] bottom-0' : 
            //     'fixed flex checkbox-modal h-[96px] w-[633.05px] max-w-[640px] bottom-0 !top-[51px]'} 
            //  ${isTopZero ? '!top-[54px]' : aiResponse.length > 0 ? 'top-[-415px] h-[240px]' : 'top-[-39px]'}`}


            >
              <div
                className={`${aiResponse.length > 0 ?
                  'flex h-auto flex-col bg-white pt-2 pb-2 rounded-t-lg border border-neutral-200' :
                  'flex h-auto flex-col bg-white pt-3 pb-3 rounded-t-lg border border-neutral-200'}`}
              >
                <div className="px-2 sm:px-3">
                  <label htmlFor="name"
                    className={`${aiResponse.length > 0 ? 'text-[0.875rem] block mb-6  text-dark-black-100 capitalize' : 'text-[0.875rem] block mb-2  text-dark-black-100 capitalize'}`}
                  >
                    <span className='text-[16px] font-medium'> {displayName}</span>
                    {
                      aiResponse.length > 0 &&
                      <div className='border-b border-neutral-200 mx-[-12px] py-1'></div>
                    }

                    {aiResponse && (
                      <div className="overflow-auto h-[350px]  pt-2">
                        {formatAiResponse(aiResponse)}
                      </div>
                    )}
                  </label>
                  {
                    aiResponse.length > 0 &&
                    <div className='border-b border-neutral-200 mx-[-12px]'></div>
                  }
                  <div
                    className={`${aiResponse ? 'flex items-end justify-between gap-x-3 mt-2' : 'flex items-end justify-between gap-x-3 mt-0'}`}
                  >
                    {
                      isModalOpenVariable &&
                      <>
                        <div className='flex items-start flex-col justify-start w-full'>

                          <div className="relative w-full">
                            <input
                              id="name"
                              name="name"
                              type="text"
                              value={promptText} // Bind the textarea value to promptText
                              onChange={(e) => setPromptText(e.target.value)} // Use onChange to update promptText                       
                              className="flex w-full grow border px-4 py-2 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] focus:ring-indigo-500 transition-colors duration-100 placeholder:text-surface-500
                     outline-indigo-600   focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                  border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                            />
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="w-full flex items-center justify-between">
                            <div className="flex items-center gap-[0.5rem]">
                              <button className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm
                     hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                //  onClick={handleEnterClick}
                                onClick={(e) => setAiData(e)}
                              >
                                {loadingAiResponse ? (
                                  <div className='flex items-center  gap-[6px]'>
                                    Enter
                                    <div className="simple-spinner">
                                      <span></span>
                                    </div>
                                  </div>
                                ) : (
                                  'Enter'
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                    {
                      !isModalOpenVariable &&
                      <div className='flex justify-end gap-x-2 w-full'>

                        <button
                          className="flex w-fit justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm
                         focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 ring-1 
                         ring-inset ring-gray-300"
                          onClick={handleAiCancel}

                        >
                          Cancel
                        </button>
                        <button
                          onClick={handleAiInsert}
                          className="flex w-fit justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm
                       hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Insert
                        </button>
                      </div>
                    }
                  </div>
                </div>

              </div>
            </div>

          </div>




          <button
            onClick={hanldeChatGpt}
            data-title="Ai"
            style={{ transitionProperty: 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform' }}
            className={`${isModalOpenCheckbox ? 'bg-gray-200' : 'bg-white'} table-tooltip rounded-md duration-200 font-[600]  px-1.5 text-[0.8125rem] h-[28px] w-[28px] flex items-center justify-center border border-solid border-[#e7e7e8]`}

          >
            <svg xmlns="http://www.w3.org/2000/svg"
              height="1.3em" width="1.3em" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
              className="icon icon-tabler icons-tabler-outline icon-tabler-sparkles"
            ><path stroke="none" d="M0 0h24v24H0z"
              fill="none" />
              <path d="M16 18a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm0 -12a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm-7 12a6 6 0 0 1 
                     6 -6a6 6 0 0 1 -6 -6a6 6 0 0 1 -6 6a6 6 0 0 1 6 6z" /></svg>
          </button>

          <ContentTypePicker options={blockOptions} />
          <FontFamilyPicker onChange={commands.onSetFont} value={states.currentFont || ''} />
          <button
            data-title="Bold"
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'table-tooltip is-active flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto' : ' table-tooltip flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto'}
          >
            <Icon name="Bold" />
          </button>
          <button
            data-title="Italic"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive('Italic') ? 'table-tooltip is-active flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto' : 'table-tooltip flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto'}

          >
            <Icon name="Italic" />
          </button>
          <button
            data-title="Underline"
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={editor.isActive('Underline') ? 'table-tooltip is-active flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto' : 'table-tooltip flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto'}
          >
            <Icon name="Underline" />
          </button>
          <Toolbar.Divider />
          <button data-title="Undo"
            onClick={() => editor.chain().focus().undo().run()}
            className={editor.isActive('Undo') ? 'table-tooltip is-active flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto' : 'table-tooltip flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto'}
          >
            <Icon name="Undo" />
          </button>
          <button data-title="Redo"
            onClick={() => editor.chain().focus().redo().run()}
            className={editor.isActive('Redo') ? 'table-tooltip is-active flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto' : 'table-tooltip flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto'}
          >
            <Icon name="Redo" />
          </button>
          <Toolbar.Divider />
          <button
            data-title="strike"
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={editor.isActive('strike') ? 'table-tooltip is-active flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto' : 'table-tooltip flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto'}
          >
            <Icon name="Strikethrough" />
          </button>
          <button data-title="Code"
            onClick={() => editor.chain().focus().toggleCode().run()}
            className={editor.isActive('code') ? 'table-tooltip is-active flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto' : 'table-tooltip flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto'}
          >
            <Icon name="Code" />
          </button>
          <button
            data-title="Code block"
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            className={editor.isActive('codeBlock') ? 'table-tooltip is-active flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto' : 'table-tooltip flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto'}
          >
            <Icon name="FileCode" />
          </button>
          <EditLinkPopover onSetLink={(url: string, inNewTab?: boolean) =>
            editor
              .chain()
              .focus()
              .setLink({ href: url, target: inNewTab ? '_blank' : '' })
              .run()} />
          <Popover.Root>
            <Popover.Trigger asChild>
              <button data-title="Text color"
                className='table-tooltip flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 
              whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5
               hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300
                dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto'
              >
                <Icon name="Palette" />
              </button>
            </Popover.Trigger>
            <Popover.Content side="top" sideOffset={8} className="z-50">
              <ColorPickerDropdown
                currentColor={states.currentColor}
                currentHighlight={states.currentHighlight}
                onColorChange={commands.onChangeColor}
                onHighlightChange={color => commands.onChangeHighlight({ color })}
                onClearColor={commands.onClearColor}
                onClearHighlight={commands.onClearHighlight}
              />
            </Popover.Content>
          </Popover.Root>
          <button
            data-title="Formatting"
            onClick={() => {
              try {
                editor.chain()
                  .focus()
                  .unsetAllMarks()
                  .clearNodes()
                  .setParagraph()
                  .run();
              } catch (error) {
                console.error("Error in removing formatting:", error);
              }
            }}
            className={editor.isActive('codeBlock') ? 'table-tooltip is-active flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto' : 'table-tooltip flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto'}
          >
            <Icon name="RemoveFormatting" />
          </button>
          <Popover.Root>
            <Popover.Trigger asChild>
              <button data-title="More options" className='table-tooltip flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 
              whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5
               hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300
                dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto'>
                <Icon name="EllipsisVertical" />
              </button>
            </Popover.Trigger>
            <Popover.Content side="top" asChild>
              <Toolbar.Wrapper>
                <button
                  data-title="Align left"
                  onClick={() => editor.chain().focus().setTextAlign('left').run()}
                  className='table-tooltip flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 
              whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5
               hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300
                dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto'
                >
                  <Icon name="AlignLeft" />
                </button>
                <button
                  data-title="Align center"
                  onClick={() => editor.chain().focus().setTextAlign('center').run()}
                  className='table-tooltip flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 
              whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5
               hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300
                dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto'
                >
                  <Icon name="AlignCenter" />
                </button>
                <button
                  data-title="Align right"
                  onClick={() => editor.chain().focus().setTextAlign('right').run()}
                  className='table-tooltip flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 
              whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5
               hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300
                dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto'
                >
                  <Icon name="AlignRight" />
                </button>
                <button
                  data-title="Justify"
                  onClick={() => editor.chain().focus().setTextAlign('justify').run()}
                  className='table-tooltip flex group items-center  justify-center border text-sm font-semibold rounded-md disabled:opacity-50 
              whitespace-nowrap bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 hover:bg-black/5
               hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300
                dark:active:text-neutral-200 h-8 gap-1  px-2 w-auto'
                >
                  <Icon name="AlignJustify" />
                </button>
              </Toolbar.Wrapper>
            </Popover.Content>
          </Popover.Root>
        </div>

      </div>
      {/* <div>
          <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>Variable Management</h2>
          <table className=' mt-6 mb-8'>
            <thead>
              <tr className='text-center'>
                <th>Name</th>
                <th>Value</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {variables.map((variable, index) => (
                <tr key={index}>
                  <td>{variable}</td>
                  <td>
                    <input
                      type="text"
                      placeholder="Enter value"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                      onChange={(e) => handleInputChange(variable, e.target.value)} // Update value on change
                    />
                  </td>
                  <td className='text-center'>
                    <button
                      className="rounded-md bg-indigo-600 px-2 py-[4px] text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 
                      focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => replaceSpanContent(variable)}
                    >Add</button>
                    <button
                      className="rounded-md bg-indigo-600 px-2 py-[4px] text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 
                      focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => deleteSpanByName(variable)}
                    >delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}





    </div>

  );
};

export default TipTapEditor;
