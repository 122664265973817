// src/features/document/documentSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ApiError, CreateDocumentActionPayload, CreateDocumentContactPayload, CreateDocumentPayload, DocumentResponse, DocumentState, FetchDocumentContactParams, UpdateDocumentActionStatusPayload, UpdateDocumentPayload } from './documentTypes';
import { createDocumentActionTemplate, createDocumentContactTemplate, createDocumentTemplate, fetchDocumentActionTemplate, fetchDocumentContactTemplate, fetchDocumentTemplate, fetchDownloadPdf, updateDocumentStatusActionTemplate, updateDocumentTemplate } from '../../services/documentService';

export const createDocument = createAsyncThunk<
  DocumentResponse,
  CreateDocumentPayload,
  { rejectValue: string }
>(
  'document/createDocument',
  async (payload, { rejectWithValue }) => {
    try {
      if (!payload.name) {
        return rejectWithValue('Name is required');
      }
      if (!payload.baseId || isNaN(payload.baseId)) {
        return rejectWithValue('Valid base ID is required');
      }

      const response = await createDocumentTemplate(payload);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to create document');
    }
  }
);

// Update Document Thunk
export const updateDocument = createAsyncThunk<
  DocumentResponse,
  UpdateDocumentPayload,
  { rejectValue: string }
>(
  'document/updateDocument',
  async (payload, { rejectWithValue }) => {
    try {
      // if (!payload.name) {
      //   return rejectWithValue('Name is required');
      // }
      if (!payload.baseId || isNaN(payload.baseId)) {
        return rejectWithValue('Valid base ID is required');
      }

      const response = await updateDocumentTemplate(payload);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to update document');
    }
  }
);

//fetch document template 
export const fetchDocument = createAsyncThunk<
  DocumentResponse,
  { baseId: string; id: string },
  { rejectValue: string }
>(
  'document/fetchDocument',
  async ({ baseId, id }, { rejectWithValue }) => {
    try {
      const response = await fetchDocumentTemplate(baseId, id);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch document');
    }
  }
);


// document action template 
export const createDocumentAction = createAsyncThunk<
  DocumentResponse,
  CreateDocumentActionPayload,
  { rejectValue: string }
>(
  'document/createDocumentAction',
  async (payload, { rejectWithValue }) => {
    try {
      if (!payload.baseId || isNaN(payload.baseId)) {
        return rejectWithValue('Valid base ID is required');
      }
      if (!payload.actionPayload) {
        return rejectWithValue('Action payload is required');
      }
      
      const response = await createDocumentActionTemplate(payload);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to create document');
    }
  }
);
export const updateDocumentAction = createAsyncThunk<
  DocumentResponse,
  UpdateDocumentPayload,
  { rejectValue: string }
>(
  'document/updateDocumentAction',
  async (payload, { rejectWithValue }) => {
    try {
      // if (!payload.name) {
      //   return rejectWithValue('Name is required');
      // }
      if (!payload.baseId || isNaN(payload.baseId)) {
        return rejectWithValue('Valid base ID is required');
      }

      const response = await updateDocumentTemplate(payload);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to update document');
    }
  }
);

// export const fetchDocumentAction = createAsyncThunk<DocumentResponse,
//   { baseId: string; id: string },
//   { rejectValue: string }
// >(
//   'document/fetchDocumentAction',
//   async ({ baseId, id }, { rejectWithValue }) => {
//     try {
//       const response = await fetchDocumentActionTemplate(baseId, id);
//       return response;
//     } catch (err) {
//       const error = err as ApiError;
//       return rejectWithValue(error.response?.data?.message || 'Failed to fetch document');
//     }
//   }
// );

export const fetchDocumentAction = createAsyncThunk<DocumentResponse,
  { baseId: string; id: string; type?: string }, // Add type as an optional parameter
  { rejectValue: string }
>(
  'document/fetchDocumentAction',
  async ({ baseId, id, type }, { rejectWithValue }) => {
    try {
      // Pass the optional `type` parameter
      const response = await fetchDocumentActionTemplate(baseId, id, type);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch document');
    }
  }
);




// document contact 

export const createDocumentContact = createAsyncThunk<
  DocumentResponse,
  { data: CreateDocumentContactPayload; userId: number },
  { rejectValue: string }
>(
  'document/createDocumentContact',
  async ({ data, userId }, { rejectWithValue }) => {
    try {
      const response = await createDocumentContactTemplate(data, userId);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to create contact');
    }
  }
);

// Update the thunk to accept these parameters
export const fetchDocumentContact = createAsyncThunk<
  DocumentResponse,
  FetchDocumentContactParams,
  { rejectValue: string }
>(
  'document/fetchDocumentContact',
  async ({ baseId, page = 1, limit = 25, search = '' }, { rejectWithValue }) => {
    try {
      const response = await fetchDocumentContactTemplate(baseId, page, limit, search);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch contact');
    }
  }
);



// Update Document Thunk
export const updateActionStatusDocument = createAsyncThunk<
  DocumentResponse,
  UpdateDocumentActionStatusPayload,
  { rejectValue: string }
>(
  'document/updateActionStatusDocument',
  async (payload, { rejectWithValue }) => {
    try {
      if (!payload.baseId || isNaN(payload.baseId)) {
        return rejectWithValue('Valid base ID is required');
      }

      const response = await updateDocumentStatusActionTemplate(payload);
      return response;
    } catch (err) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to update document');
    }
  }
);


export const fetchDownloadPdfAction = createAsyncThunk<
DocumentResponse,
  { baseId: number; id: string },
  { rejectValue: string }
>(
  'input/fetchDownloadPdfAction',
  async ({ baseId, id }, { rejectWithValue }) => {
    try {
      const response = await fetchDownloadPdf(baseId, id);
      return response;
    } catch (err: unknown) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch document template');
    }
  }
);




const initialState: DocumentState = {
  data: null,
  createStatus: 'idle',
  createError: null,
  updateStatus: 'idle',
  updateError: null,
  fetchStatus: 'idle',
  fetchError: null,
  createContactStatus: 'idle',
  createContactError: null,
  fetchContactStatus: 'idle',
  fetchContactError: null,
  updateContactStatus: 'idle',
  updateContactError: null,
  createDocumentActionStatus: 'idle',
  createDocumentActionError: null,
  updateDocumentActionStatus: 'idle',
  updateDocumentActionError: null,
  fetchDocumentActionStatus: 'idle',
  fetchDocumentActionError: null,
  updateActionStatusDocument: 'idle',
  updateActionStatusDocumentError: null,
  downloadPdfStatus: 'idle',
  downloadPdfError: null
}


const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Cases for createDocument
      .addCase(createDocument.pending, (state) => {
        state.createStatus = 'loading';
        state.createError = null; // Clear previous errors
      })
      .addCase(createDocument.fulfilled, (state, action: PayloadAction<DocumentResponse>) => {
        state.createStatus = 'succeeded';
        state.data = action.payload;
      })
      .addCase(createDocument.rejected, (state, action) => {
        state.createStatus = 'failed';
        state.createError = action.payload || 'Failed to create document';
      })
      // Cases for updateDocument
      .addCase(updateDocument.pending, (state) => {
        state.updateStatus = 'loading';
        state.updateError = null; // Clear previous errors
      })
      .addCase(updateDocument.fulfilled, (state, action: PayloadAction<DocumentResponse>) => {
        state.updateStatus = 'succeeded';
        state.data = action.payload;
      })
      .addCase(updateDocument.rejected, (state, action) => {
        state.updateStatus = 'failed';
        state.updateError = action.payload || 'Failed to update document';
      })
      // Cases for fetchDocument
      .addCase(fetchDocument.pending, (state) => {
        state.fetchStatus = 'loading';
        state.fetchError = null; // Clear previous errors
      })
      .addCase(fetchDocument.fulfilled, (state, action: PayloadAction<DocumentResponse>) => {
        state.fetchStatus = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchDocument.rejected, (state, action) => {
        state.fetchStatus = 'failed';
        state.fetchError = action.payload || 'Failed to fetch document';
      })
      // Cases for createDocumentContact
      .addCase(createDocumentContact.pending, (state) => {
        state.createContactStatus = 'loading';
        state.createContactError = null; // Clear previous errors
      })
      .addCase(createDocumentContact.fulfilled, (state, action: PayloadAction<DocumentResponse>) => {
        state.createContactStatus = 'succeeded';
        state.data = action.payload;
      })
      .addCase(createDocumentContact.rejected, (state, action) => {
        state.createContactStatus = 'failed';
        state.createContactError = action.payload || 'Failed to create contact';
      })
      // create document action 
      .addCase(createDocumentAction.pending, (state) => {
        state.createStatus = 'loading';
        state.createError = null; // Clear previous errors
      })
      .addCase(createDocumentAction.fulfilled, (state, action: PayloadAction<DocumentResponse>) => {
        state.createStatus = 'succeeded';
        state.data = action.payload;
      })
      .addCase(createDocumentAction.rejected, (state, action) => {
        state.createStatus = 'failed';
        state.createError = action.payload || 'Failed to create document';
      })
      // Cases for updateDocumentAction
      .addCase(updateDocumentAction.pending, (state) => {
        state.updateStatus = 'loading';
        state.updateError = null; // Clear previous errors
      })
      .addCase(updateDocumentAction.fulfilled, (state, action: PayloadAction<DocumentResponse>) => {
        state.updateStatus = 'succeeded';
        state.data = action.payload;
      })
      .addCase(updateDocumentAction.rejected, (state, action) => {
        state.updateStatus = 'failed';
        state.updateError = action.payload || 'Failed to update document';
      })
      // Cases for fetchDocumentAction
      .addCase(fetchDocumentAction.pending, (state) => {
        state.fetchDocumentActionStatus = 'loading';
        state.fetchDocumentActionError = null; // Clear previous errors
      })
      .addCase(fetchDocumentAction.fulfilled, (state, action: PayloadAction<DocumentResponse>) => {
        state.fetchDocumentActionStatus = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchDocumentAction.rejected, (state, action) => {
        state.fetchDocumentActionStatus = 'failed';
        state.fetchDocumentActionError = action.payload || 'Failed to fetch document';
      })
      // Cases for updateActionStatusDocument
      .addCase(updateActionStatusDocument.pending, (state) => {
        state.updateActionStatusDocument = 'loading';
        state.updateActionStatusDocumentError = null; // Clear previous errors
      })
      .addCase(updateActionStatusDocument.fulfilled, (state, action: PayloadAction<DocumentResponse>) => {
        state.updateActionStatusDocument = 'succeeded';
        state.data = action.payload;
      })
      .addCase(updateActionStatusDocument.rejected, (state, action) => {
        state.updateActionStatusDocument = 'failed';
        state.updateActionStatusDocumentError = action.payload || 'Failed to update document';
      })
      // Cases for fetchDownloadPdfAction
      .addCase(fetchDownloadPdfAction.pending, (state) => {
        state.downloadPdfStatus = 'loading';
        state.downloadPdfError = null; // Clear previous errors
      })
      .addCase(fetchDownloadPdfAction.fulfilled, (state, action: PayloadAction<DocumentResponse>) => {
        state.downloadPdfStatus = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchDownloadPdfAction.rejected, (state, action) => {
        state.downloadPdfStatus = 'failed';
        state.downloadPdfError = action.payload || 'Failed to fetch document';
      });
  }
});

export default documentSlice.reducer;
