import { get, post } from '../utils/api';
import { AuthResponse, ForgotPayload, LoginPayload, OtpPayload, RegisterPayload, ResetPayload } from '../features/auth/authTypes';

// Endpoint paths
const LOGIN_ENDPOINT = '/auth/login';
const REGISTER_ENDPOINT = '/auth/register';
const FORGOT_PASSWORD_ENDPOINT = '/auth/forgot/password';
const RESET_PASSWORD_ENDPOINT = '/auth/reset/password';
const OTP_ENDPOINT = '/auth/twoStep';
const LOGOUT_ENDPOINT = '/auth/logout';


// Configuration for including credentials
const credentialsConfig = {
  withCredentials: true,
  headers: {
    'Host': 'api.feedbacks.app',
  }
};
 
export const login = async (credentials: LoginPayload): Promise<AuthResponse> => {
  return post<AuthResponse>(LOGIN_ENDPOINT, credentials, credentialsConfig);
};

export const register = async (data: RegisterPayload): Promise<AuthResponse> => {
  return post<AuthResponse>(REGISTER_ENDPOINT, data, credentialsConfig);
};

export const forgotPassword = async (data: ForgotPayload): Promise<AuthResponse> => {
  return post<AuthResponse>(FORGOT_PASSWORD_ENDPOINT, data, credentialsConfig);
}; 

export const resetPassword = async (data: ResetPayload): Promise<AuthResponse> => {
  return post<AuthResponse>(RESET_PASSWORD_ENDPOINT, data, credentialsConfig);
};

export const otpScreen = async (data: OtpPayload): Promise<AuthResponse> => {
  return post<AuthResponse>(OTP_ENDPOINT, data, credentialsConfig);
};

export const logoutUser = async (): Promise<AuthResponse> => {
  return get<AuthResponse>(LOGOUT_ENDPOINT, credentialsConfig);
};




