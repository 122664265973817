import React from 'react';
import { useParams } from 'react-router-dom';

const DocumentDetailsData = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <div>
      <h1>Document Details</h1>
      <p>Details for document ID: {id}</p>
      {/* Fetch and display document details based on the ID */}
    </div>
  );
};

export default DocumentDetailsData;
