import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createChatData } from '../../services/documentItemChatService';
import { ApiError, ChatResponse, InputResponse, UpsertItemInputDto } from './documentItemChatTypes';


// Async thunk
export const createItemchatData = createAsyncThunk<
  ChatResponse,
  { baseId: number; payload: UpsertItemInputDto },
  { rejectValue: string }
>(
  'input/createItemchatData',
  async ({ baseId, payload }, { rejectWithValue }) => {
    try {
      const response = await createChatData(baseId, payload );
      return response;
    } catch (err: unknown) {
      const error = err as ApiError;
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch input data');
    }
  }
);


// State interface
interface InputState {
  inputData: ChatResponse | null;
  inputLoading: boolean;
  inputError: string | null;
}

const initialState: InputState = {
  inputData: null,
  inputLoading: false,
  inputError: null
};

// Slice
const inputDataSlice = createSlice({
  name: 'input',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createItemchatData.pending, (state) => {
        state.inputLoading = true;
        state.inputError = null;
      })
      .addCase(createItemchatData.fulfilled, (state, action: PayloadAction<ChatResponse>) => {
        state.inputLoading = false;
        state.inputData = action.payload;
        state.inputError = null;
      })
      .addCase(createItemchatData.rejected, (state, action) => {
        state.inputLoading = false;
        state.inputError = action.payload as string;
      })
  },
});

export default inputDataSlice.reducer;
