// src/components/dashboard/DashboardLayout.tsx
import React, { useEffect, useState } from 'react';
import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import Sidebar from '../setting/Sidebar';

interface UserDetails {
    id: number;
}

interface DashboardLayoutProps {
    children: ReactNode;
    userDetails: UserDetails; // Update to UserDetails type
}


const UserLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
    const { baseId } = useParams<{ baseId: string }>(); // Extract baseId from URL
    const [userDetails, setUserDetails] = useState<any>(null);
    const bases = useAppSelector((state: any) => state?.base?.data?.data?.bases); // Get bases from Redux state

    useEffect(() => {
        if (bases && bases.length > 0) {
          const matchedBase = bases.find((base: { id: number }) => base.id.toString() === baseId);
          if (matchedBase) {
            setUserDetails(matchedBase);
          }
        }
        else if (baseId) {
          setUserDetails(userDetails?.current_base);
        }
        else {
          setUserDetails(null);
        }
      }, [baseId, bases]);


    return (
        <div className="h-screen">
            <Sidebar userDetails={userDetails} />
            <main className="flex-1 p-6 bg-white lg:pl-[225px] h-full overflow-auto">
                {children}
            </main>
        </div>
    );
};

export default UserLayout;
