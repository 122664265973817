import React from 'react';

const LoadingSpinner = () => (
  <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 50px)' }}>
    <div className="simple-spinner-loader">
      <span className='!w-6 !h-6'></span>
    </div>
  </div>
);

export default LoadingSpinner;
