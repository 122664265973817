import { ChatResponse, UpsertItemInputDto } from '../features/documentItemChat/documentItemChatTypes';
import { post } from '../utils/api';
import { formatUrl } from '../utils/urlFormatter';

const createChatEndpoint = '/{0}/itemActivity.create';

const credentialsConfig = {
  withCredentials: true,
};

export const createChatData = async (
  baseId: number,
  payload: UpsertItemInputDto
): Promise<ChatResponse> => {
  const fetchInputUrl = formatUrl(createChatEndpoint, [baseId]); 
  return post(fetchInputUrl, payload, credentialsConfig);
};

