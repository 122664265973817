import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
// import { _twoStep, changepassword } from './profileAPI';
import { Switch } from '@headlessui/react'
import { deleteUserAllSessionData, deleteUserSessionData, fetchUserSessionData, updateUserPassword, updateUserTwoStep } from '../../users/userSlice';
import { useAppDispatch } from '../../../app/hooks';
import { toast } from 'sonner';
import { forgotPassword, logoutUser } from '../../auth/authSlice';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { useNavigate } from 'react-router-dom';
import { UserSession } from '../../users/userTypes';
import { format } from 'date-fns';
import { DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { CrossIcon, ThreeDotIcon } from '../../../components/icons/Icons';



function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const UserSecurity = () => {
  const [checked, setChecked] = useState<any>(localStorage.getItem("userAuth") === "true" ? true : false)
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)
  const [oldPass, setOldPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [error, setError] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [data, setData] = useState<any>()
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string | null>('');
  const [loading, setLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false)
  const [logoutLoading, setLogoutLoading] = useState(false)
  const navigate = useNavigate();
  const [userSession, setUserSession] = useState<UserSession[]>([])
  const [logoutAllSession, setLogoutAllSession] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [activeSection, setActiveSection] = useState<string>('')

  const handleChange = async () => {
    setChecked(!checked);
    const selectValue = checked ? false : true;

    try {
      // Dispatch the async thunk action and unwrap the result
      const res = await dispatch(updateUserTwoStep(selectValue)).unwrap();

      if (res.status === 200 && res.data.user) {
        const user = res.data.user;
        await setChecked(user?.two_auth ?? true);  // Use `??` to handle undefined
        await localStorage.setItem("userAuth", String(user?.two_auth ?? true));
        setData(res);
        toast.success("Successfully two steps")
      } else {
        setData(res);
        setShowAlert(true);
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  const changepasswordHandler = async () => {
    if (oldPass.length === 0) {
      setError("Both fields are required");
    } else if (newPass.length <= 6) {
      setError("Password must be 6 digit");
    }
    setPasswordLoading(true)
    try {
      // Prepare payload
      const payload = { old_password: oldPass, password: newPass };
      const res = await dispatch(updateUserPassword(payload));
      if (res.meta.requestStatus === "fulfilled") {
        // Handle success
        setOldPass("");
        setNewPass("");
        setOpen(false);
        setData(res.payload);
        toast.success("Password Sucessfull change.");
      } else if (res.meta.requestStatus === "rejected") {
        // Handle error
        const errorMessage = typeof res.payload === "string" ? res.payload : "Failed to update password";
        toast.error(errorMessage);
      }
    } catch (err) {
      console.error("Error while updating password:", err);
      toast.error("An unexpected error occurred");
    } finally {
      setPasswordLoading(false)
    }

  };


  // useEffect(() => {
  //     getSessions()
  // }, [])

  // const getSessions = () => {
  //     _getSessions().then((res) => {
  //         console.log("res", res)

  //     }).catch((err) => {
  //         console.log("err", err)
  //     })
  // }

  const handleSignOut = () => {
    setLogoutLoading(true)
    const clearAllStorageData = () => {
      // Clear all localStorage
      localStorage.clear();

      // Specifically remove items related to authentication and persistence
      const keysToRemove = [
        "accessToken",
        "authToken",
        "persist:root",
        "_persist"
      ];

      keysToRemove.forEach(key => localStorage.removeItem(key));

      // If you're using cookies for any auth data, clear them here
      // For example:
      // document.cookie.split(";").forEach(c => {
      //   document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      // });
    };

    dispatch(logoutUser())
      .then((resultAction) => {
        if (logoutUser.fulfilled.match(resultAction)) {
          clearAllStorageData();
          toast.success('Successfully logged out!');

          // Use a slight delay to ensure storage clearing is complete
          setTimeout(() => {
            navigate('/login');
          }, 100);
        } else if (logoutUser.rejected.match(resultAction)) {
          const payload = resultAction.payload as { status?: number; message: string };
          if (payload.status === 401) {
            clearAllStorageData();
            toast.error('Session expired. Please log in again.');

            setTimeout(() => {
              window.location.href = '/login';
            }, 100);
          } else {
            toast.error('Logout failed. Please try again.');
          }
        }
      })
      .catch((error) => {
        console.error('Logout action failed', error);
        toast.error('Logout action failed. Please try again.');
      })
      .finally(() => {
        setLogoutLoading(false); // End loading
      });
  };

  useEffect(() => {
    const email = localStorage.getItem('email');
    const userDetailsString = localStorage.getItem('userDetails');

    let userDetails: { email?: string } | null = null;
    if (userDetailsString) {
      try {
        userDetails = JSON.parse(userDetailsString);
      } catch (e) {
        console.error("Error parsing userDetails:", e);
      }
    }

    const data = userDetails?.email;
    setEmail(email || data || '');
  }, []);


  // forgot password 
  const handleForgotPass = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!email) {
      toast.error("Email is required.");
      return;
    }
    setLoading(true)
    try {
      const result = await dispatch(forgotPassword({ email })).unwrap();
      toast.success(result.message);
    } catch (err: any) {
      console.error('Forgot password error:', err);
      toast.error(err?.message || "An unexpected error occurred.");
    } finally {
      setLoading(false); // Stop loading after action
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await dispatch(fetchUserSessionData()).unwrap();
        if (res && res.data && Array.isArray(res.data)) {
          setUserSession(res.data);
        }
      } catch (err) {
        console.log("Error fetching user data", err);
      }
    };

    fetchData();
  }, [dispatch]);


  // delete user session 
  const handleDeleteSession = async (sessionId: any) => {
    try {
      await dispatch(deleteUserSessionData({ sessionId })).unwrap();
      toast.success("Session deleted successfully");

      const res = await dispatch(fetchUserSessionData()).unwrap();
      if (res && res.data && Array.isArray(res.data)) {
        setUserSession(res.data);
      }
    } catch (err) {
      toast.error("Failed to delete session");
    }
  };

  // delete all user session

  const handleAllLogoutSession = () => {
    setLogoutAllSession(true)
  }

  const handleAllUserSessionDelete = async () => {
    setIsLoading(true);
    try {
      await dispatch(deleteUserAllSessionData()).unwrap();
      toast.success("All sessions deleted successfully");
      setLogoutAllSession(false);
    } catch (err) {
      toast.error("Failed to delete all sessions");
      console.error("Error deleting all sessions", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Add smooth scrolling functionality
    const handleSmoothScroll = (e: Event) => {
      e.preventDefault();
      const target = e.target as HTMLAnchorElement;
      const id = target.getAttribute('href')?.slice(1);
      if (id) {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth'
          });
          setActiveSection(id) // Update active section when clicked

        }
      }
    };

    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach(link => {
      link.addEventListener('click', handleSmoothScroll);
    });

    // Cleanup
    return () => {
      links.forEach(link => {
        link.removeEventListener('click', handleSmoothScroll);
      });
    };
  }, []);


  return (
    <>
      <div className='h-screen'>
        <div className=" py-[34px] lg:py-[70px] px-0 lg:px-10 h-full flex">
          <div className='px-[2px] sm:px-6 lg:px-8 w-full flex justify-center'>
            <div className='w-full max-w-[788px] flex gap-x-[2rem]'>
              <div className="w-[20%]">
                <div>
                  <div className="hidden lg:flex lg:flex-col h-full">
                    <div className="flex grow flex-col bg-white pt-0 px-0 pb-4 sidebar-des">
                      <nav className="flex overflow-y-auto h-1/2 flex-col">
                        <ul role="list" className="flex flex-1 flex-col gap-y-2">
                          <li>
                            <ul role="list" className=" space-y-1 flex flex-col gap-y-3 fixed">
                              <li className=" sidebar-nav">
                                <a
                                  href="#two-factor-security"
                                  className={classNames(
                                    '  hover:bg-gray-10 capitalize group  items-center flex gap-x-[10px] p-0 text-[0.8125rem] m-0 w-full cursor-pointer leading-5 rounded-[4px] font-medium  focus:outline-none pr-[2px] pl-[6px]',
                                    activeSection === 'two-factor-security' ? 'text-[#0000007a]' : ' text-black-100'
                                  )}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke={activeSection === 'two-factor-security' ? '#0000007a' : '#000'}
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"

                                    className={classNames(
                                      'icon icon-tabler icons-tabler-outline icon-tabler-lock  w-[1rem] h-[1rem]',
                                      activeSection === 'two-factor-security' ? 'text-[#0000007a]' : ' text-black-100'
                                    )}
                                  >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z" />
                                    <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
                                    <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
                                  </svg>


                                  Two factor security
                                </a>
                              </li>
                              <li className=" sidebar-nav">
                                <a
                                  href="#change-password"
                                  className={classNames(
                                    'hover:bg-gray-10 capitalize group items-center flex gap-x-[10px] p-0 text-[0.8125rem] m-0 w-full cursor-pointer leading-5 rounded-[4px] font-medium  focus:outline-none pr-[2px] pl-[6px]',
                                    activeSection === 'change-password' ? 'text-[#0000007a]' : ' text-black-100'
                                  )}
                                >

                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke={activeSection === 'change-password' ? '#0000007a' : '#000'}
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"

                                    className={classNames(
                                      'icon icon-tabler icons-tabler-outline icon-tabler-password-user w-[1rem] h-[1rem]',
                                      activeSection === 'change-password' ? 'text-[#0000007a]' : ' text-black-100'
                                    )}
                                  >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 17v4" />
                                    <path d="M10 20l4 -2" />
                                    <path d="M10 18l4 2" />
                                    <path d="M5 17v4" />
                                    <path d="M3 20l4 -2" />
                                    <path d="M3 18l4 2" />
                                    <path d="M19 17v4" />
                                    <path d="M17 20l4 -2" />
                                    <path d="M17 18l4 2" />
                                    <path d="M9 6a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                    <path d="M7 14a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2" />
                                  </svg>

                                  Change password
                                </a>
                              </li>
                              <li className=" sidebar-nav">
                                <a
                                  href="#forgot-password"
                                  className={classNames(
                                    ' hover:bg-gray-10 capitalize group flex gap-x-[10px] p-0 text-[0.8125rem] m-0 w-full cursor-pointer leading-5 items-center rounded-[4px] font-medium  focus:outline-none pr-[2px] pl-[6px]',
                                    activeSection === 'forgot-password' ? 'text-[#0000007a]' : ' text-black-100'
                                  )}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke={activeSection === 'forgot-password' ? '#0000007a' : '#000'}
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className={classNames(
                                      'icon icon-tabler icons-tabler-outline icon-tabler-lock-password w-[1rem] h-[1rem]',
                                      activeSection === 'forgot-password' ? 'text-[#0000007a]' : ' text-black-100'
                                    )}
                                  >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z" />
                                    <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
                                    <path d="M15 16h.01" />
                                    <path d="M12.01 16h.01" />
                                    <path d="M9.02 16h.01" />
                                  </svg>


                                  Forgot Password
                                </a>
                              </li>
                              <li className=" sidebar-nav">
                                <a

                                  href="#device-history"
                                  className={classNames(
                                    ' hover:bg-gray-10 capitalize group flex gap-x-[10px] p-0 text-[0.8125rem] m-0 w-full cursor-pointer leading-5 items-center rounded-[4px] font-medium  focus:outline-none pr-[2px] pl-[6px]',
                                    activeSection === 'device-history' ? 'text-[#0000007a]' : ' text-black-100'
                                  )}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke={activeSection === 'device-history' ? '#0000007a' : '#000'}
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className={classNames(
                                      'h-[1rem] w-[1rem] icon icon-tabler icons-tabler-outline icon-tabler-history',
                                      activeSection === 'device-history' ? 'text-[#0000007a]' : ' text-black-100'
                                    )}
                                  >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 8l0 4l2 2" />
                                    <path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" />
                                  </svg>


                                  Device History
                                </a>
                              </li>
                              <li className=" sidebar-nav">
                                <a
                                  href="#account-session"
                                  className={classNames(
                                    ' hover:bg-gray-10 capitalize group flex gap-x-[10px] p-0 text-[0.8125rem] m-0 w-full cursor-pointer leading-5 items-center rounded-[4px] font-medium  focus:outline-none pr-[2px] pl-[6px]',
                                    activeSection === 'account-session' ? 'text-[#0000007a]' : ' text-black-100'
                                  )}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke={activeSection === 'account-session' ? '#0000007a' : '#000'}
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className={classNames(
                                      'h-[1rem] w-[1rem] icon icon-tabler icons-tabler-outline icon-tabler-user-circle',
                                      activeSection === 'account-session' ? 'text-[#0000007a]' : ' text-black-100'
                                    )}
                                  >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                    <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                    <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
                                  </svg>
                                  Account Session
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" w-[80%]">
                <main className='flex-1 bg-white h-full'>
                  <div className=' w-full flex justify-center'>
                    <div className='w-full'>
                      <div className='flex flex-col gap-y-[6px]'>
                        <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>Privacy</h2>
                        <p className='text-sm leading-6 text-[#5c5c5e] font-medium'>
                          User Privacy information
                        </p>
                      </div>
                      <div className='mt-6 lg:mt-10 '>
                        <div className='py-4 border-b border-light-gray-200 flex justify-between' id="two-factor-security">
                          <div className='w-[70%] flex flex-col gap-y-2'>
                            <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b]'>Two factor security</p>
                            <p className='text-[0.8125rem] font-normal leading-4 text-[#5c5c5e]'>
                              Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in.
                            </p>
                          </div>
                          <div className='w-[25%] flex justify-end items-center'>


                            <Switch
                              checked={checked}
                              onChange={handleChange}
                              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-0 focus:ring-white focus:ring-offset-2"
                            >
                              <span className="sr-only">Use setting</span>
                              <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  checked ? 'bg-indigo-600' : 'bg-gray-200',
                                  'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                                )}
                              />
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  checked ? 'translate-x-5' : 'translate-x-0',
                                  'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                                )}
                              />
                            </Switch>

                          </div>
                        </div>
                        <div className='py-4 border-b border-light-gray-200  flex justify-between flex-wrap lg:flex-nowrap gap-2' id="change-password" >
                          <div className='lg:w-[65%] w-full flex flex-col gap-y-2'>
                            <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b]'>Change password</p>
                            <p className='text-[0.8125rem] font-normal leading-4 text-[#5c5c5e]'>We will email you a confirmation when changing your password, so please expect that email after submitting.</p>
                          </div>
                          <div className='lg:w-[35%] w-full flex justify-start lg:justify-end items-center '>
                            <button
                              onClick={() => setOpen(true)}
                              className="flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Change password
                            </button>
                          </div>
                        </div>
                        <div className='py-4 border-b border-light-gray-200  flex justify-between flex-wrap lg:flex-nowrap gap-2' id="forgot-password">
                          <div className='lg:w-[65%] w-full flex flex-col gap-y-2'>
                            <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b]'>Forgot Password</p>
                            <p className='text-[0.8125rem] font-normal leading-4 text-[#5c5c5e]'>We will email you a confirmation when changing your password, so please expect that email after submitting.</p>
                          </div>
                          <div className='lg:w-[35%] w-full flex justify-start lg:justify-end items-center '>
                            <button
                              type='submit'
                              className="flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              onClick={handleForgotPass}
                            >


                              {loading ? (
                                <div className='flex items-center  gap-[6px]'>
                                  Forgot Password
                                  <div className="simple-spinner">
                                    <span></span>
                                  </div>
                                </div>
                              ) : (
                                'Forgot Password'
                              )}
                            </button>
                          </div>
                        </div>
                        <div className='w-full  border-b border-light-gray-200  py-4 flex flex-col gap-y-2' id="device-history">
                          <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b]'>Device History</p>
                          <p className='text-[0.8125rem] font-normal leading-4 text-[#5c5c5e]'>If you see a device here that you believe wasn’t you, please contact our account fraud department immediately.Active Sessions</p>
                        </div>

                        <div className='flex flex-col gap-y-2 pb-10' id="account-session">
                          <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b] pt-4 pb-2'>Account Session</p>
                          {userSession.length > 0 ? (
                            userSession.map((session: UserSession, index) => {
                              const dateObj = new Date(session?.login_time);
                              // Format the date using date-fns
                              const formattedDateTime = format(dateObj, 'd MMM yyyy, h:mm a');

                              return (
                                <div key={index} className="flex-auto rounded-md p-3 cursor-pointer ring-1 ring-inset ring-gray-200 feedback-delete-hover">
                                  <div className="flex items-start">
                                    <div className="w-[65%] flex flex-col gap-y-2">
                                      <div className="flex justify-between gap-x-4">
                                        <div className="text-[13px] leading-5 text-gray-500 flex items-center gap-x-1">

                                          <div className="flex items-center mr-3">
                                            <p className='text-[0.8125rem] font-normal leading-4 text-[#5c5c5e]'>{session?.user_ip}</p>
                                          </div>
                                          {
                                            session.isCurrent === true && (
                                              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 w-fit">
                                                Current Session
                                              </span>
                                            )
                                          }

                                        </div>
                                      </div>
                                      <span className="font-medium text-dark-black-100 mr-1 leading-4">
                                        {session?.login_address}
                                      </span>

                                    </div>
                                    <div className='w-[30%] flex justify-end flex-col items-end gap-y-2'>
                                      <p className='text-[0.8125rem] font-normal leading-4 text-[#5c5c5e]'>Active since</p>
                                      <p className='text-[0.8125rem] font-medium leading-4 text-[#1b1b1b]'>{formattedDateTime}</p>
                                    </div>
                                    {
                                      session.isCurrent === false && (
                                        <div className="w-[5%]">
                                          <div className="[&>div]:shrink feedback-delete-btn-hover flex justify-end">
                                            <Menu as="div" className="relative inline-block text-left">
                                              <div>
                                                <MenuButton className="flex items-center rounded-full bg-transparent text-gray-400 hover:text-gray-600
                                             focus:outline-none ">
                                                  <span className="sr-only">Open options</span>
                                                  <ThreeDotIcon />
                                                </MenuButton>
                                              </div>

                                              <MenuItems
                                                transition
                                                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg
                                             ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 
                                            data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 
                                            data-[enter]:ease-out data-[leave]:ease-in"
                                              >
                                                <div className="py-1">

                                                  <MenuItem>
                                                    <button
                                                      type="submit"
                                                      // onClick={handleSignOut}
                                                      onClick={() => handleDeleteSession(session?.id)}
                                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                                    >
                                                      {logoutLoading ? (
                                                        <div className='flex items-center  gap-[6px]'>
                                                          Logout
                                                          <div className="simple-spinner">
                                                            <span></span>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        'Logout'
                                                      )}
                                                    </button>
                                                  </MenuItem>
                                                </div>
                                              </MenuItems>
                                            </Menu>
                                          </div>
                                        </div>
                                      )
                                    }

                                  </div>
                                </div>

                              );
                            })
                          ) : (
                            <p>No user sessions found.</p>
                          )}
                        </div>



                        <div className='flex justify-end pb-8'>
                          <button className='text-[13px] font-medium text-red-600 underline' onClick={handleAllLogoutSession}>Logout from all other sessions</button>
                        </div>



                        {/* 
                            <div className='flex justify-between py-4'>
                                <div className='w-[50%] flex flex-col gap-y-2'>
                                    <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b]'>Web</p>
                                    <p className='text-[0.8125rem] font-normal leading-4 text-[#5c5c5e]'>182.68.171.67</p>
                                </div>
                                <div className='w-[50%] text-end flex items-center gap-1 justify-end'>
                                    <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b]'>Date</p>
                                    <button
                                        type='submit'
                                        onClick={handleSignOut}
                                        className="flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        {logoutLoading ? (
                                            <div className='flex items-center  gap-[6px]'>
                                                Logout
                                                <div className="simple-spinner">
                                                    <span></span>
                                                </div>
                                            </div>
                                        ) : (
                                            'Logout'
                                        )}
                                    </button>

                                </div>
                            </div> */}
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>

          </div>

        </div>
      </div>





      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto ">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className='w-full flex justify-between items-center'>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Change password
                      </Dialog.Title>

                    </div>

                    <div className="mt-3  sm:mt-5">
                      <div className='mt-2'>
                        <label htmlFor="password" className="wg-label inline-flex shrink-0 items-center gap-1 text-surface-900 wg-antialiased">
                          Old Password
                        </label>
                      </div>
                      <div className="mt-2">
                        <input
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          value={oldPass}
                          onChange={(e) => {
                            setOldPass(e.target.value);
                            setError("")
                          }}
                          placeholder='Enter old password'
                          className="flex w-full grow border px-4 py-2 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
                                                    focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                                                    border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                        />
                        <Dialog.Title as="h6" className="mt-2 text-sm text-red-700">
                          {error}
                        </Dialog.Title>
                      </div>

                      <div className='my-2'>
                        <label htmlFor="password" className="wg-label inline-flex shrink-0 items-center gap-1 text-surface-900 wg-antialiased">
                          New Password
                        </label>
                      </div>
                      <div className="mt-2">
                        <input
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          value={newPass}
                          onChange={(e) => {
                            setNewPass(e.target.value);
                            setError("")
                          }}
                          placeholder='Enter new password'
                          className="flex w-full grow border px-4 py-2 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
                                                    focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                                                    border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={changepasswordHandler}
                    >
                      {passwordLoading ? (
                        <div className='flex items-center  gap-[6px]'>
                          Change
                          <div className="simple-spinner">
                            <span></span>
                          </div>
                        </div>
                      ) : (
                        'Update'
                      )}
                    </button>
                    <button
                      type="button"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"

                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>



      <Dialog open={logoutAllSession} onClose={setLogoutAllSession} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  onClick={() => setLogoutAllSession(false)}
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">Close</span>
                  <CrossIcon />
                </button>
              </div>
              <div className="sm:flex sm:items-start">

                <div className="mt-3 text-center sm:ml-0 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    Logout account
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to Logout from all other sessions?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleAllUserSessionDelete}

                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  {isLoading ? (
                    <div className='flex items-center  gap-[6px]'>
                      Logout
                      <div className="simple-spinner">
                        <span></span>
                      </div>
                    </div>
                  ) : (
                    'Logout'
                  )}
                </button>
                <button
                  type="button"
                  onClick={() => setLogoutAllSession(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>




    </>
  )
}
export default UserSecurity
