import { CreateDocumentActionPayload, CreateDocumentContactPayload, CreateDocumentPayload, DocumentResponse, UpdateDocumentPayload } from '../features/document/documentTypes';
import { get, post, put } from '../utils/api';
import { formatUrl } from '../utils/urlFormatter';

// Endpoint paths
const createDocumentEndpoint = '/{0}/document.create';
const updateDocumentEndpoint = '/{0}/{1}/document.update';
const fetchDocumentEndpoint = '/{0}/{1}/document.detail';


// document actions
const createDocumentAction = '/{0}/action.create'
const updateDocumentAction = '/{0}/{1}/documentAction.update'
const fetchDocumentAction = '/{0}/{1}/action.list'

// document contact
const createDocumentContact = '/{0}/contact.create'
const fetchDocumentContact = '/{0}/contact.list'


const credentialsConfig = {
  withCredentials: true,
};

export const createDocumentTemplate = async (payload: CreateDocumentPayload): Promise<DocumentResponse> => {
  const endpoint = formatUrl(createDocumentEndpoint, [payload.baseId]);
  return post<DocumentResponse>(
    endpoint, 
    { 
      doc_name: payload.name,
      channel_id: payload.channel_id 
    }, 
    credentialsConfig
  );
};

// update document template
export const updateDocumentTemplate = async (payload: UpdateDocumentPayload): Promise<DocumentResponse> => {
  const endpoint = formatUrl(updateDocumentEndpoint, [payload.baseId, payload.id]);

  return put<DocumentResponse>(
    endpoint,
    {
      doc_name: payload.name,
      channel_id: payload.channel_id,
      doc_content: payload.doc_content // Add this line to include doc_content in the request payload
    },
    credentialsConfig
  );
};

// fetch document template 
export const fetchDocumentTemplate = async (baseId: string, id: string): Promise<DocumentResponse> => {
  const endpoint = formatUrl(fetchDocumentEndpoint, [baseId, id]);
  return get<DocumentResponse>(endpoint, credentialsConfig);
};

// document actions

export const createDocumentActionTemplate = async (
  payload: CreateDocumentActionPayload
): Promise<DocumentResponse> => {
  const endpoint = formatUrl(createDocumentAction, [payload.baseId.toString()]);
  return post<DocumentResponse>(
    endpoint,
    payload.actionPayload,  // Send the action payload directly
    credentialsConfig
  );
};


export const updateDocumentActionTemplate = async (payload: UpdateDocumentPayload): Promise<DocumentResponse> => {
  const endpoint = formatUrl(updateDocumentAction, [payload.baseId, payload.id]);

  return put<DocumentResponse>(
    endpoint,
    {
      doc_name: payload.name,
      channel_id: payload.channel_id,
      doc_content: payload.doc_content 
    },
    credentialsConfig
  );
};

export const fetchDocumentActionTemplate = async (baseId: string, id: string): Promise<DocumentResponse> => {
  const endpoint = formatUrl(fetchDocumentAction, [baseId, id]);
  return get<DocumentResponse>(endpoint, credentialsConfig);
};

// document contact 
export const createDocumentContactTemplate = async (
  data: CreateDocumentContactPayload,
  userId: number
): Promise<DocumentResponse> => {
  const base_doc_contact = formatUrl(createDocumentContact, [userId]);
  return post<DocumentResponse>(base_doc_contact, data, credentialsConfig);
};


export const fetchDocumentContactTemplate = async (
  baseId: number,
  page: number,
  limit: number,
  search: string
): Promise<DocumentResponse> => {
  const fetch_doc_contact = formatUrl(fetchDocumentContact, [baseId]);
  
  const queryParams = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString(),
    ...(search && { search })
  });

  const url = `${fetch_doc_contact}?${queryParams.toString()}`;
  
  return get<DocumentResponse>(url, credentialsConfig);
};