// feedback.tsx

//@ts-nocheck
import React, { Fragment, HTMLProps, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  PaginationState,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
} from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { format } from 'date-fns';
import { BASE_URL, get } from '../../utils/api';
import { useDispatch } from 'react-redux';
import { toast } from 'sonner';
import { debounce } from 'lodash';
import { Dialog, Label, Listbox, Transition } from '@headlessui/react';
import DocumentHeader from './DocumentHeader';
import CustomMenuDropdown from '../../components/common/CustomMenuDropdown';
import { _baseRequest } from '../../utils/apis';
import { getToken } from '../../utils/localStorage';
import axios from 'axios';
import { fetchBoardsList } from '../../features/teams/teamsSlice';
import { useAppDispatch } from '../../app/hooks';
type Person = {
  item_base_status_id: any;
  id: any;
  created_at: string | number | Date;
  item_sentiment: any;
  item_assigned_to: any;
  item_label: any;
  board_id: any;
  item_refernce_id: ReactNode;
  name: string
  email: string
  age: string
  type: string
  typeColor: string,
  status: string,
  statusColor: string,
  progress?: string,
  avatar?: string;
}

interface topicTableProps {
  sectionHeight?: number;
}


interface DropdownItem {
  className?: string;
  icon?: ReactNode;
  label: string;
  shortcut?: any;
  disabled?: boolean;
  onClick?: () => void;
  href?: string;

}


function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className="h-4 w-4 rounded border-gray-300 text-indigo-600 ring-0 outline-none focus:outline-none focus:shadow-none focus:ring-transparent"
      {...rest}
    />
  )
}

var date = new Date();
var formattedDate = date.toLocaleDateString('en-US', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
});


export const colorMap = {
  numbers: {
    "0": "#ffc107", "1": "#ff9900", "2": "#ffa07a", "3": "#e67e73", "4": "#d35400",
    "5": "#e74c3c", "6": "#f39c12", "7": "#ff8c00", "8": "#ffb6c1", "9": "#ff69b4"
  },
  alphabets: {
    "a": "#ff9900", "b": "#ffc107", "c": "#ffa07a", "d": "#e67e73", "e": "#d35400",
    "f": "#e74c3c", "g": "#f39c12", "h": "#ff8c00", "i": "#ffb6c1", "j": "#ff69b4",
    "k": "#ffc499", "l": "#ffa57d", "m": "#e6b8a3", "n": "#d2b48c", "o": "#ff9900",
    "p": "#ffc107", "q": "#ffa07a", "r": "#e67e73", "s": "#d35400", "t": "#e74c3c",
    "u": "#f39c12", "v": "#ff8c00", "w": "#ffb6c1", "x": "#ff69b4", "y": "#ffc499",
    "z": "#ffa57d"
  }
};

export const getColorForLabel = (label: string): string => {
  const firstChar = label?.charAt(0).toLowerCase();
  if (firstChar >= '0' && firstChar <= '9') {
    return colorMap.numbers[firstChar as keyof typeof colorMap.numbers] || '#808080';
  } else if (firstChar >= 'a' && firstChar <= 'z') {
    return colorMap.alphabets[firstChar as keyof typeof colorMap.alphabets] || '#808080';
  }
  return '#808080';
};


// export default function FeedbackTable() {
const DocumentList: React.FC<topicTableProps> = ({ sectionHeight, activeFilters, itemAssignedTo }: any) => {
  const [data, setData] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
    currentPage: 1,
    hasNextPage: false,
    totalPages: 1,
    totalItems: 0,
  })
  const [rowSelection, setRowSelection] = React.useState({})
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const baseIdString = localStorage.getItem('UserBaseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [isModalOpenCheckbox, setIsModalOpenCheckbox] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [deleteOk, setDeleteOk] = useState(false)
  const [rowSelectionDelete, setRowSelectionDelete] = useState<number[]>([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const isInitialMount = useRef(true);
  const [initialLoading, setInitialLoading] = useState(true);
  // Fetch channelData from localStorage
  const channelDataString = localStorage.getItem('channelData');
  const channelData = channelDataString ? JSON.parse(channelDataString) : [];
  const [teams, setTeams] = useState(null);
  const [tagsList, setTagsList] = useState(null);
  // item status data 
  const itemStatus = localStorage.getItem('itemStatusList');
  const statusData = itemStatus ? JSON.parse(itemStatus) : [];

  // item tags data 
  const itemTags = localStorage.getItem('itemTagsList');
  const tagsData = itemTags ? JSON.parse(itemTags) : [];

  // item assign to
  const itemAssign = localStorage.getItem('baseMembersDetails');
  const assignData = itemAssign ? JSON.parse(itemAssign) : [];

  // item priority
  const itemPriority = localStorage.getItem('priorities');
  const priorityData = itemPriority ? JSON.parse(itemPriority) : [];
  const dispatch = useAppDispatch();

  const _get_ItemFeedbackList = async (
    pageIndex: number,
    pageSize: number, // changed from 'limit' to 'pageSize'
    filters: Filters,
    assignedTo: boolean
  ) => {
    const baseId = localStorage.getItem('UserBaseId') || localStorage.getItem('createBaseId');
    if (!baseId) return;

    try {
      setLoading(true);

      // Ensure baseId is valid for URL construction
      const parsedBaseId = isNaN(Number(baseId)) ? baseId : JSON.parse(baseId);
      const url = `${BASE_URL}/${parsedBaseId}/document.list`;
      const params = new URLSearchParams({
        page: pageIndex.toString(),
        limit: pageSize.toString(),
      });

      // Add filters logic
      if (filters) {
        Object.entries(filters).forEach(([filterType, filterValues]) => {
          if (filterType === 'search') return; // Handle search separately

          if (Array.isArray(filterValues) && filterValues.length > 0) {
            const keyPrefix = getKeyPrefix(filterType);

            filterValues.forEach((filterObj: FilterValue) => {
              let value: string | undefined;

              // Filter handling logic
              if (filterType === 'Boards' || filterType === 'status' || filterType === 'assign') {
                value = filterObj.id;
              } else if (filterType === 'label' || filterType === 'priority' || filterType === 'sentiment') {
                value = filterObj.value;
              } else if (filterType === 'Created at') {
                const [startDate, endDate] = (filterObj.value || '').split(' - ').map(dateStr => {
                  const date = parseDate(dateStr);
                  date.setHours(12, 0, 0, 0);
                  return date;
                });
                params.append('created_from', formatDate(startDate));
                params.append('created_till', formatDate(endDate));
                return;
              } else if (filterType === 'Updated at') {
                const [startDate, endDate] = (filterObj.value || '').split(' - ').map(dateStr => {
                  const date = parseDate(dateStr);
                  date.setHours(12, 0, 0, 0);
                  return date;
                });
                params.append('updated_from', formatDate(startDate));
                params.append('updated_till', formatDate(endDate));
                return;
              }

              if (value !== undefined) {
                params.append(keyPrefix, value.toString());
              }
            });
          }
        });

        // Handle search filter
        if (filters.search) {
          params.append('search', filters.search);
        }
      }

      // Handle assigned items
      if (assignedTo) {
        const loginUserId = localStorage.getItem("userAuthId");
        if (loginUserId) {
          params.append('item_assigned_to', loginUserId);
        }
      }

      // Make the GET request
      const response = await axios.get(url, {
        params: params, // This will automatically append the params as query string
        withCredentials: true, // This sets cookies only for this request
        headers: {
          Authorization: `Bearer ${getToken()}`,  // If you use an auth token
        },
      });

      return response;
    } catch (error) {
      console.error('Error fetching items:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Helper function to get the key prefix based on filter type
  const getKeyPrefix = (filterType) => {
    switch (filterType) {
      case 'Boards':
        return 'board_id';
      case 'label':
        return 'item_label';
      case 'status':
        return 'item_base_status_id';
      case 'assign':
        return 'item_assigned_to';
      case 'priority':
        return 'item_priority';
      case 'sentiment':
        return 'item_sentiment';
      case 'Created at':
        return 'created_from';
      case 'Updated at':
        return 'updated_from';
      default:
        return filterType.toLowerCase();
    }
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split(' ');
    const monthIndex = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].indexOf(month);
    return new Date(year, monthIndex, parseInt(day));
  };

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const fetchItemFeedbackListData = useCallback(debounce(async (pageIndex, pageSize, filters, isInitialFetch = false, itemAssignedTo) => {
    setLoading(true);
    try {
      const response = await _get_ItemFeedbackList(pageIndex, pageSize, filters, itemAssignedTo);

      if (response.status === 200) {
        const newData = response.data.data.documents;
        const newPagination = {
          currentPage: response.data.data.currentPage,
          hasNextPage: response.data.data.hasNextPage,
          totalPages: response.data.data.totalPages,
          pageSize: response.data.data.limit,
          pageIndex: response.data.currentPage - 1,
          total: response.data.data.totalDocuments,
        };

        setData(newData);
        setPagination(newPagination);
        setTotalPages(response.data.data.totalPages);
        localStorage.setItem('cachedDocumentListData', JSON.stringify({
          data: newData,
          pagination: newPagination,
          timestamp: Date.now(),
        }));
        if (!isInitialFetch) {
          console.log('Data updated in background');
        }
      }
    } catch (error) {
      console.error('Error fetching item feedback list:', error);
    } finally {
      setLoading(false);
      if (isInitialFetch) {
        setInitialLoading(false);
      }
    }
  }, 500), []); // Adjust the debounce delay as needed

  useEffect(() => {
    const handleSidebarItemClick = () => {
      setPagination({
        pageIndex: 0,
        pageSize: 25,
        currentPage: 1,
        hasNextPage: false,
        totalPages: 1,
        total: 0,
      });
      setTotalPages(0);
      fetchItemFeedbackListData(1, 25, true);
    };

    window.addEventListener('paginationresetsetate', handleSidebarItemClick);

    return () => {
      window.removeEventListener('paginationresetsetate', handleSidebarItemClick);
    };
  }, [fetchItemFeedbackListData]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const cachedData = localStorage.getItem('cachedDocumentListData');
      if (cachedData) {
        setLoading(true);
        const { data: cachedItems, pagination: cachedPagination } = JSON.parse(cachedData);
        setData(cachedItems);
        setPagination(prevPagination => ({
          ...prevPagination,
          ...cachedPagination,
        }));
        setTotalPages(cachedPagination.totalPages);
        setLoading(false);
      } else {
        fetchItemFeedbackListData(pagination.currentPage, pagination.pageSize, activeFilters, true, itemAssignedTo);
      }
    } else {
      fetchItemFeedbackListData(pagination.currentPage, pagination.pageSize, activeFilters, true, itemAssignedTo);
    }
  }, [fetchItemFeedbackListData, pagination.currentPage, pagination.pageSize, activeFilters, itemAssignedTo]);


  const handlePrevPage = () => {
    setLoading(false)
    if (pagination.currentPage > 1) {
      setPagination(prev => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const handleNextPage = () => {
    setLoading(false)
    if (pagination.hasNextPage) {
      setPagination(prev => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  useEffect(() => {
    const handleSidebarItemClick = () => {
      setData([]);
      setPagination({
        pageIndex: 0,
        pageSize: 25,
        currentPage: 1,
        hasNextPage: false,
        totalPages: 1,
        total: 0,
      });
      setTotalPages(0);
      fetchItemFeedbackListData(1, 25, true);
    };

    window.addEventListener('sidebarItemClicked', handleSidebarItemClick);

    return () => {
      window.removeEventListener('sidebarItemClicked', handleSidebarItemClick);
    };
  }, [fetchItemFeedbackListData]);


  const handleSelect = (row: Row<Person>) => {
    setSelectedRows((prevSelectedRows) => {
      const newRowState = { ...prevSelectedRows }
      newRowState[row.id] = !prevSelectedRows[row.id]
      return newRowState
    })
  }

  const columns = useMemo<ColumnDef<Person>[]>(() => [
    {
      id: 'select',
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: (e) => {
              table.getToggleAllRowsSelectedHandler()(e);
              handleCheckboxClick(table);
            },
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: (e) => {
                row.getToggleSelectedHandler()(e);
                handleCheckboxClick(row, row.original.id);
              },
            }}
          />
        </div>
      ),
    },
    {
      accessor: 'doc_ref_id',
      header: 'ID',
      cell: (info) => {
        const { doc_status, id, doc_ref_id } = info.row.original;
        const redirectUrl = doc_status === 'draft' 
          ? `/${baseId}/d/${id}` 
          : doc_status === 'pending' 
          ? `/${baseId}/d/${id}/pending`
          : '/';  // default or fallback URL if doc_status doesn't match
    
        return (
          <Link to={redirectUrl} className='w-full'>
            <span className="flex items-center gap-[1rem] text-[13px] text-[#52555a]">
              #{doc_ref_id}
            </span>
          </Link>
        );
      },
    },
    
    {
      accessor: 'name',
      header: 'Name',
      cell: info => {
        const { doc_status, id } = info.row.original;
        const redirectUrl = doc_status === 'draft' 
          ? `/${baseId}/d/${id}` 
          : doc_status === 'pending' 
          ? `/${baseId}/d/${id}/pending`
          : '/';  // default or fallback URL if doc_status doesn't match
        return (
          <Link to={redirectUrl} className='w-full'>
            <div className=' relative'>
              <div className=' table-tooltip hover:overflow-visible tabletooltip-title'>
                <div className=' text-[#1b1b1b] text-[13px] font-medium line-clamp-2 '>
                  {info?.row?.original?.doc_name}
                </div>
              </div>

            </div>

          </Link>
        );
      },
      sort: (a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name),
    },
    {
      accessor: 'shared',
      header: 'Shared',
      cell: info => {
        const { doc_status, id } = info.row.original;
        const redirectUrl = doc_status === 'draft' 
          ? `/${baseId}/d/${id}` 
          : doc_status === 'pending' 
          ? `/${baseId}/d/${id}/pending`
          : '/';  // default or fallback URL if doc_status doesn't match
        return (
          <Link to={redirectUrl} className='w-full'>
            <div className=' relative'>
              <div className='table-tooltip flex items-center max-w-fit inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10'>
                test12@gmail.com
              </div>
            </div>
          </Link>
        );
      },
      sort: (a: { shared: string }, b: { shared: any }) => a.shared.localeCompare(b.shared),
    },
    {
      accessor: 'status',
      header: 'Status',
      cell: info => {
        const { doc_status, id } = info.row.original;
        const redirectUrl = doc_status === 'draft' 
          ? `/${baseId}/d/${id}` 
          : doc_status === 'pending' 
          ? `/${baseId}/d/${id}/pending`
          : '/';  // default or fallback URL if doc_status doesn't match
        const status = info.row.original.doc_status?.trim();
        return (
          <div className=' relative'>
            <Link to={redirectUrl} className='w-full'>
              <div className=' table-tooltip flex items-center max-w-fit gap-[6px]'>
                {status === 'completed' ? (
                  <span
                    className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize "
                  // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                  >
                    {status}
                  </span>
                ) : status === 'rejected' ? (
                  <span
                    className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 capitalize"
                  // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                  >
                    {status}
                  </span>
                ) : status === 'terminated' ? (
                  <span
                    className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10 capitalize"
                  // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                  >
                    {status}
                  </span>
                ) : status === 'expired' ? (
                  <span
                    className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 capitalize"
                  // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                  >
                    {status}
                  </span>
                ) : status === 'pending' ? (
                  <span
                    className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize"
                  >
                    {status}
                  </span>
                ) : status === 'draft' ? (
                  <span
                    className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 capitalize"
                  >
                    {status}
                  </span>
                ) :
                  null}
              </div>
            </Link>
          </div>

        )

      },
      sort: (a: { status: string }, b: { status: any }) => a.status.localeCompare(b.status),
    },
    {
      accessor: 'sub_status',
      header: 'Sub Status',
      cell: info => {
        const { doc_status, id } = info.row.original;
        const redirectUrl = doc_status === 'draft' 
          ? `/${baseId}/d/${id}` 
          : doc_status === 'pending' 
          ? `/${baseId}/d/${id}/pending`
          : '/';  // default or fallback URL if doc_status doesn't match
        const status = info.row.original.doc_sub_status?.trim();
        return (
          <div className=' relative'>
            <Link to={redirectUrl} className='w-full'>
              <div className=' table-tooltip flex items-center max-w-fit gap-[6px]'>
                {
                  status === 'completed-view' ? (
                    <span
                      className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize "
                    // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                    >
                      {status}
                    </span>
                  ) : status === 'completed-accept' ? (
                    <span
                      className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize "
                    // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                    >
                      {status}
                    </span>
                  ) : status === 'completed-sign' ? (
                    <span
                      className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize "
                    // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                    >
                      {status}
                    </span>
                  ) : status === 'completed-payment' ? (
                    <span
                      className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize "
                    // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                    >
                      {status}
                    </span>
                  ) : status === 'pending-accept' ? (
                    <span
                      className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize"
                    // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                    >
                      {status}
                    </span>
                  ) : status === 'pending-sign' ? (
                    <span
                      className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize"
                    // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                    >
                      {status}
                    </span>
                  ) : status === 'pending-payment' ? (
                    <span
                      className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize"
                    // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                    >
                      {status}
                    </span>
                  ) : status === 'pending-approval' ? (
                    <span
                      className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize"
                    // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                    >
                      {status}
                    </span>
                  ) : status === 'sent' ? (
                    <span
                      className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10 capitalize"
                    // style={{ borderColor: bgColor, borderWidth: 1, borderStyle: 'solid' }}
                    >
                      {status}
                    </span>
                  ) : status === 'draft' ? (
                    <span
                      className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 capitalize"
                    >
                      {status}
                    </span>
                  ) :
                    null}
              </div>
            </Link>
          </div>

        )

      },
      sort: (a: { substatus: string }, b: { substatus: any }) => a.substatus.localeCompare(b.substatus),
    },
    {
      accessor: 'tags',
      header: 'Tags',
      cell: info => {
        const { doc_status, id } = info.row.original;
        const redirectUrl = doc_status === 'draft' 
          ? `/${baseId}/d/${id}` 
          : doc_status === 'pending' 
          ? `/${baseId}/d/${id}/pending`
          : '/';  // default or fallback URL if doc_status doesn't match
        const tagsId = info.row.original.doc_tag;
        const tags = tagsList?.data?.find(team => team.id.toString() === tagsId);

        const tagsName = tags ? tags.tag_name : null;
        const tagsColor = tags ? tags.tag_colour : null;

        // If tagsName is null, return null and don't render anything
        if (!tagsName) return null;


        return (
          <Link to={redirectUrl} className='w-full'>
            <div className='relative'>
              <div className=' table-tooltip flex items-center max-w-fit font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] py-[5px] gap-[6px]'>
                <span
                  className={`flex h-[8px] w-[8px] shrink-0 items-center justify-center rounded-full
                                                    border-none text-[0.625rem] font-medium text-white capitalize ${tagsColor}`}

                ></span>
                <span className='text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 whitespace-nowrap max-w-[80px] w-full overflow-hidden text-ellipsis text-start'>
                  {tagsName}
                </span>

              </div>
            </div>
          </Link>
        );
      },
      sort: (a: { shared: string }, b: { shared: any }) => a.shared.localeCompare(b.shared),
    },
    {
      accessor: 'channel',
      header: 'Channel',
      cell: info => {
        const { doc_status, id } = info.row.original;
        const redirectUrl = doc_status === 'draft' 
          ? `/${baseId}/d/${id}` 
          : doc_status === 'pending' 
          ? `/${baseId}/d/${id}/pending`
          : '/';  // default or fallback URL if doc_status doesn't match

        const channelId = info.row.original.channel_id;
        // Ensure `teams` and `teams.data` are defined
        const currentChannel = teams?.data?.find(team => team.id.toString() === channelId);

        // Extract the channel name or default to 'Unknown'
        const channelName = currentChannel ? currentChannel.name : 'Unknown';

        return (
          <div className='relative'>
            <Link to={redirectUrl} className='w-full'>
              <div className='table-tooltip flex items-center max-w-fit gap-[6px]'>
                <span className="flex items-center gap-[1rem] text-[13px] text-[#52555a]">
                  {channelName}
                </span>
              </div>
            </Link>
          </div>
        );
      },
      sort: (a: any, b: any) => a.channel.localeCompare(b.channel),
    },
    {
      accessor: 'activity',
      header: 'Activity',
      cell: info => {
        const { doc_status, id } = info.row.original;
        const redirectUrl = doc_status === 'draft' 
          ? `/${baseId}/d/${id}` 
          : doc_status === 'pending' 
          ? `/${baseId}/d/${id}/pending`
          : '/';  // default or fallback URL if doc_status doesn't match
        const formattedDate = format(new Date(info.row.original.doc_createdat), 'MMM d');
        const formattedDatetooltip = format(new Date(info.row.original.doc_createdat), 'MMM d');
        return (
          <Link to={redirectUrl} className='w-full'>
            <div data-title={formattedDatetooltip} className="table-tooltip text-[#52555a] font-normal capitalize truncate text-[13px] leading-4 whitespace-nowrap max-w-[80px] w-full overflow-hidden text-ellipsis text-start">
              {formattedDate}
            </div>
          </Link>
        );
      },
      sort: (a: { doc_createdat: string }, b: { doc_createdat: string }) => new Date(a.doc_createdat).getTime() - new Date(b.doc_createdat).getTime(),
    },

  ], [teams]);

  // teams 
  useEffect(() => {
    if (baseId) {
      const fetchTeams = async () => {
        const storedBase = localStorage.getItem('baseTeamsData');
        if (storedBase) {
          setTeams(JSON.parse(storedBase));
        } else {
          try {
            const baseResponse = await dispatch(fetchBoardsList(userDetails?.id || baseId));
            setTeams(baseResponse.payload);
          } catch (error) {
            console.error("Error fetching base member details:", error);
          }
        }
      };
      fetchTeams();
    }
  }, [baseId, dispatch]);


  useEffect(() => {
    setSelectedRowsCount(Object.keys(rowSelection)?.length);
  }, [rowSelection]);


  const handleCheckboxClick = (row, id) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [row.id]: !prevSelectedRows[row.id]
    }));
    setRowSelectionDelete((prevSelection) => {

      if (prevSelection?.includes(id)) {
        return prevSelection.filter((itemId) => itemId !== id);
      } else {
        return [...prevSelection, id];
      }
    });

    setIsModalOpenCheckbox(true);
  };

  const handleConfirmDelete = (id) => {
    setDeleteOk(true)
  }

  const handleDeleteConfirm = async () => {
    setDeleteLoading(true);
    try {
      let groupIdsToDelete = Array.isArray(rowSelectionDelete) ? rowSelectionDelete : [rowSelectionDelete];

      const deleteResponse = await dispatch(deleteItemFeedback(baseId, groupIdsToDelete));

      if (!deleteResponse || !deleteResponse.data) {
        throw new Error('Invalid delete response received');
      }

      // Process the delete response
      const successfulDeletes = deleteResponse.data.filter((item) => item.isSuccess).map((item) => item.id);
      const failedDeletes = deleteResponse.data.filter((item) => !item.isSuccess);

      if (successfulDeletes.length > 0) {
        toast.success(`Successfully deleted item(s)`);
      }
      // successfulDeletes.forEach((id) => {
      //   toast.success(`Successfully deleted item ${id}`);
      // });
      failedDeletes.forEach((item) => {
        toast.error(`Failed to delete item ${item.id}: ${item.reason}`);
      });

      if (successfulDeletes.length > 0) {
        const updatedResponse = await _get_ItemFeedbackList(
          pagination.pageIndex + 1,
          pagination.pageSize,
          activeFilters,
          false,
          itemAssignedTo
        );

        if (updatedResponse.status === 200) {
          const newData = updatedResponse.data.items;
          const newPagination = {
            currentPage: updatedResponse.data.currentPage,
            hasNextPage: updatedResponse.data.hasNextPage,
            totalPages: updatedResponse.data.totalPages,
            pageSize: updatedResponse.data.pageSize,
            pageIndex: updatedResponse.data.currentPage - 1,
            // total: updatedResponse.data.totalInsight,
          };

          setData(newData);
          setPagination(newPagination);
          setTotalPages(updatedResponse.data.totalPages);

          // Update local storage with new data
          localStorage.setItem('cachedDocumentListData', JSON.stringify({
            data: newData,
            pagination: newPagination,
            timestamp: Date.now(),
          }));

          // Reset states
          setRowSelectionDelete([]);
          setDeleteOk(false);
          setIsModalOpenCheckbox(false);
          setSelectedRows({});
          setRowSelection({});
          setSelectedRowsCount(0);
        } else {
          console.error('Error fetching updated item feedback list:', updatedResponse);
          toast.error('Failed to fetch updated item feedback list');
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`An error occurred: ${error.response.data.message}`);
      } else {
        console.error('Error deleting insight groups:', error);
        toast.error('An error occurred while deleting insight groups. Please try again later.');
      }
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleDeleteSingleRow = async (rowId?: string) => {
    setDeleteLoading(true);
    try {
      let idsToDelete: string[];

      if (rowId) {
        // Single row deletion
        idsToDelete = [rowId];
      }

      const deleteResponse = await dispatch(deleteItemFeedback(baseId, idsToDelete));

      if (!deleteResponse || !deleteResponse.data) {
        throw new Error('Invalid delete response received');
      }

      // Process the delete response
      const successfulDeletes = deleteResponse.data.filter((item) => item.isSuccess).map((item) => item.id);
      const failedDeletes = deleteResponse.data.filter((item) => !item.isSuccess);

      if (successfulDeletes.length > 0) {
        toast.success(`Successfully deleted item(s)`);
      }
      // successfulDeletes.forEach((id) => {
      //   toast.success(`Successfully deleted item ${id}`);
      // });
      failedDeletes.forEach((item) => {
        toast.error(`Failed to delete item ${item.id}: ${item.reason}`);
      });

      if (successfulDeletes.length > 0) {
        const updatedResponse = await _get_ItemFeedbackList(
          pagination.pageIndex + 1,
          pagination.pageSize,
          activeFilters,
          false,
          itemAssignedTo
        );

        if (updatedResponse.status === 200) {
          const newData = updatedResponse.data.items;
          const newPagination = {
            currentPage: updatedResponse.data.currentPage,
            hasNextPage: updatedResponse.data.hasNextPage,
            totalPages: updatedResponse.data.totalPages,
            pageSize: updatedResponse.data.pageSize,
            pageIndex: updatedResponse.data.currentPage - 1,
            // total: updatedResponse.data.totalInsight,
          };

          setData(newData);
          setPagination(newPagination);
          setTotalPages(updatedResponse.data.totalPages);

          // Update local storage with new data
          localStorage.setItem('cachedDocumentListData', JSON.stringify({
            data: newData,
            pagination: newPagination,
            timestamp: Date.now(),
          }));

          // Reset states
          setRowSelectionDelete([]);
          setDeleteOk(false);
          setIsModalOpenCheckbox(false);
          setSelectedRows({});
          setRowSelection({});
          setSelectedRowsCount(0);
        } else {
          toast.error('Failed to fetch updated item feedback list');
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`An error occurred: ${error.response.data.message}`);
      } else {
        toast.error('An error occurred while deleting insight groups. Please try again later.');
      }
    } finally {
      setDeleteLoading(false);
    }
  };


  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    onPaginationChange: setPagination,


  });

  // console.log(data, "dataaaa");
  const data1 = [2, 45, 5, 344, 55]

  useEffect(() => {
    const applyStyles = () => {
      const tableElement = document.querySelector('.feedback-col') as HTMLElement | null;
      const tableElement1 = document.querySelector('.feedback-thead') as HTMLElement | null;
      const isTabScreen = window.matchMedia('(max-width: 1499px)').matches;
      const isSmallScreen = window.matchMedia('(max-width: 767px)').matches;
      const hasSearchFilter = !!activeFilters?.search;

      // Apply margin-top to tableElement based on screen size
      if (tableElement) {
        if (isSmallScreen) {
          tableElement.style.marginTop = sectionHeight ? `calc(50px + ${sectionHeight}px)` : '50px';
        } else if (isTabScreen) {
          tableElement.style.marginTop = sectionHeight ? `calc(45px + ${sectionHeight}px)` : '101px';
        } else {
          tableElement.style.marginTop = sectionHeight ? `calc(142px + ${sectionHeight}px)` : '142px';
        }
      }

      // Apply top style to tableElement1 based on screen size and search filter
      if (tableElement1) {
        if (isSmallScreen) {
          tableElement1.style.top = sectionHeight ? `calc(80px + ${sectionHeight}px)` : '80px';
        } else if (isTabScreen) {
          tableElement1.style.top = sectionHeight ? `calc(101px + ${sectionHeight}px)` : '101px';
        } else {
          tableElement1.style.top = sectionHeight ? `calc(101px + ${sectionHeight}px)` : '101px';
        }

        if (sectionHeight) {
          tableElement1.style.borderTop = '1px solid #e5e7eb';
        } else {
          tableElement1.style.borderTop = 'none';
        }
      }
    };

    applyStyles();

    window.addEventListener('resize', applyStyles);
    return () => {
      window.removeEventListener('resize', applyStyles);
    };
  }, [sectionHeight]);

  const numbers = [1, 5, 3, 9, 2];
  const maxNumber = numbers.reduce((max, num) => (num > max ? num : max), numbers[0]);


  // tags 

  useEffect(() => {
    if (baseId) {
      const fetchTeams = async () => {
        const storedBase = localStorage.getItem('baseTagsData');
        if (storedBase) {
          setTagsList(JSON.parse(storedBase));
          // setSelectedTags(JSON.parse(storedBase))
        } else {
          try {
            const baseResponse = await dispatch(fetchTagsDataList({ baseId: userDetails?.id || Number(baseId) }));
            setTagsList(baseResponse.payload);
            // setSelectedTags(baseResponse.payload)
          } catch (error) {
            console.error("Error fetching base member details:", error);
          }
        }
      };
      fetchTeams();
    }
  }, [baseId, dispatch]);



  return (
    <>

      <div className='mx-auto max-w-full'>
        <div className="px-4 sm:px-6 lg:px-8 feedback-table">
          <div className="flow-root h-full">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 h-full">
              <div className="inline-block min-w-full py-2 align-middle h-full max-w-full">
                <div className="relative  h-full block pb-[5rem]">
                  <table className="block border-collapse overflow-x-auto w-full feedback-col overflow-y-auto feedback-table-height h-full">
                    <thead
                      className='mq1499:sticky mq3000:fixed bg-white grid feedback-thead feedback-table-row'
                    >
                      {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id} className='bg-white flex relative w-full'>
                          {headerGroup.headers.map(header => {

                            return (
                              <th key={header.id} colSpan={header.colSpan} scope="col"
                                className={`sticky bg-white flex flex-1 py-[0.75rem] px-[1rem] cursor-pointer text-[0.75rem] items-center justify-start
                                   leading-[1rem] text-[#52555a] font-[500] border-b  border-solid border-light-gray-200 min-w-[50px] md:min-w-[160px] lg:min-w-[50px]
                                  ${header.id === 'select' ? 'max-w-[50px] [&>div]:px-0' : ''} 
                                    ${header.id === 'ID' ? 'max-w-[60px]' : ''}
                                       ${header.id === 'Name' ? 'max-w-[-webkit-fill-available] !min-w-[320px]' : ''}
                                     ${header.id === 'Shared' ? '!min-w-[160px]' : ''}
                                        ${header.id === 'Tags' ? '!min-w-[110px]' : ''}
                                     ${header.id === 'Status' ? '!min-w-[110px]' : ''}
                                      ${header.id === 'Sub Status' ? '!min-w-[110px]' : ''}
                                      ${header.id === 'Channel' ? '!min-w-[106px]' : ''}
                                     ${header.id === 'Activity' ? '!min-w-[106px]' : ''}
                                  `}
                              >
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? 'cursor-pointer select-none '
                                      : '',
                                    onClick: header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {{
                                    asc: ' 🔼',
                                    desc: ' 🔽',
                                  }[header.column.getIsSorted() as string] ?? null}
                                </div>
                              </th>
                            )
                          })}
                          <th
                            className={`sticky bg-white flex flex-1 py-[0.75rem] px-[1rem] cursor-pointer text-[0.75rem] items-center justify-start
                             leading-[1rem] text-[#52555a] font-[500] border-r border-b  border-solid border-light-gray-200 min-w-10 max-w-[56px]`}
                          ></th>
                        </tr>
                      ))}
                    </thead>
                    <div>

                      {loading ? (
                        <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 191px)' }}>
                          <div className="simple-spinner-loader">
                            <span></span>
                          </div>
                        </div>
                      ) : pagination.totalPages === 0 ? (
                        <div className="flex items-center justify-center z-50" style={{ height: 'calc(100vh - 191px)' }}>
                          <p>No data found</p>
                        </div>
                      ) : (

                        <tbody className="grid">

                          {table.getRowModel().rows.map((row) => {
                            // Log the entire row data to check if data is available in rows.

                            const allRowsSelected = table.getIsAllRowsSelected();
                            const dropdownGroups: DropdownGroup[] = [
                              {
                                options: [
                                  {
                                    label: `Delete`,
                                    className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
                                    onClick: () => handleDeleteSingleRow(row.original.id),
                                  },
                                  {
                                    label: `Archive`,
                                    className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
                                    // onClick: () => handleDeleteSingleRow(row.original.id),
                                  },
                                ],
                              },
                            ];
                            return (
                              <tr
                                key={row.id}
                                data-selected={allRowsSelected ? true : selectedRows[row.id]}
                                onClick={() => handleSelect(row)}
                                className={`border-b border-solid border-light-gray-200 bg-white flex w-full relative ${selectedRows[row.id] ? 'bg-gray-100' : ''}`}
                              >

                                {row.getVisibleCells().map((cell) => (
                                  <td
                                    key={cell.id}
                                    className={`flex items-center justify-start text-start px-[1rem] py-[0.9rem] leading-[1rem]
                                         flex-1 text-dark-black-200 max-w-[350px]
                                         ${/(_select)$/.test(cell.id) ? 'max-w-[50px] [&>div]:px-0' : ''} 
                                         ${/(_ID)$/.test(cell.id) ? 'max-w-[60px]' : ''}
                                         ${/(_Name)$/.test(cell.id) ? '!max-w-[-webkit-fill-available] min-w-[320px]' : ''}
                                         ${/(_Shared)$/.test(cell.id) ? '!min-w-[160px] pl-1' : ''}
                                             ${/(_Tags)$/.test(cell.id) ? '!min-w-[110px]' : ''}
                                         ${/(_Status)$/.test(cell.id) ? '!min-w-[110px]' : ''}
                                         ${/(_Sub Status)$/.test(cell.id) ? '!min-w-[110px]' : ''}
                                         ${/(_Channel)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                                         ${/(_Activity)$/.test(cell.id) ? '!min-w-[106px]' : ''}
                                       `}
                                  >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}

                                  </td>
                                ))}
                                <td
                                  className={`flex items-center text-start px-[0.75rem] py-[1rem] leading-[1rem] flex-1 max-w-[50px] text-dark-black-200 justify-center [&>button]:w-auto [&>button]:bg-transparent [&>button]:hover:bg-transparent`}
                                >
                                  <CustomMenuDropdown
                                    sections={dropdownGroups}
                                    icon={
                                      <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        aria-hidden="true"
                                        focusable="false"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="12" cy="5" r="1"></circle>
                                        <circle cx="12" cy="19" r="1"></circle>
                                      </svg>
                                    }
                                    menuItemsClassName="left-auto"
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>



                      )}
                    </div>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed z-10 bg-white border-t border-solid border-light-gray-200 py-[0.5rem] px-[1rem] bottom-0 pagination-bottom ">
        <div className="relative flex items-center justify-between">



          <div className="flex items-center gap-2">
            <span className="flex items-center gap-1">
              <div className='text-[0.875rem] font-[500] text-[#000000eb]'>Page
              </div>
            </span>
            <span className="flex items-center gap-1">

              <input
                type="number"
                value={pagination.currentPage}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) : 1;
                  setPagination((prevPagination) => ({
                    ...prevPagination,
                    currentPage: page,
                  }));
                }}
                className="w-[4rem] h-[2rem] text-[0.8125rem] px-[0.75rem] rounded-md border border-solid border-light-gray-100"
              />

              <span className='mr-1 text-[#000000eb] text-[0.8125rem]'>  of{' '}

                {totalPages}
              </span>
            </span>


            <div className='flex items-center gap-1'>

              <select
                value={table.getState().pagination.pageSize}
                onChange={e => {
                  table.setPageSize(Number(e.target.value))
                }}
                className="w-[6rem] h-[2rem] text-[0.8125rem] px-[0.75rem] rounded-md border border-solid border-light-gray-100 block"
              >
                {[25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
              <div className='mr-1 text-[#000000eb] text-[0.8125rem] ml-[14px]'>
                {pagination.total} Items
              </div>
            </div>


          </div>
          <div className='flex gap-2'>
            <button
              className="border rounded py-2 px-2"
              onClick={handlePrevPage}
              disabled={pagination.currentPage === 1}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-2 w-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
              </svg>

            </button>
            <button
              className="border rounded py-2 px-2"
              onClick={handleNextPage}
              disabled={!pagination.hasNextPage}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-2 w-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>

            </button>
          </div>
        </div>
      </div>
      <div open={isModalOpenCheckbox} onClose={() => { }} className={`relative z-10 w-full ${isModalOpenCheckbox ? 'common-slider-one' : 'common-slider-two'}`}
      >
        {selectedRowsCount > 0 && (
          <div className="fixed inset-y-0 right-0 flex checkbox-modal-list h-[100%] w-[800px] max-w-[800px] bottom-0 top-[-70px]">
            <div className="flex h-auto flex-col overflow-y-scroll bg-white py-6 rounded-lg" style={{ boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06)' }}>
              <div className="px-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <span className='text-dark-black-100 text-[13px] font-[700]'>{selectedRowsCount} selected</span>
                  <div className="flex items-center">
                    <div className="w-full flex items-center justify-between">
                      <div className="flex items-center gap-[0.5rem]">
                        <button className='text-[13px] text-dark-black-100 font-[600] [&>span]:font-[600] h-[32px] rounded-md flex-row-reverse border-[#e7e7e8] shadow-none'
                        >
                          Add tags
                        </button>
                        <button
                          onClick={(id) => handleConfirmDelete(id)}
                          className='text-[13px] text-dark-black-100 font-[600] [&>span]:font-[600] h-[32px] rounded-md flex-row-reverse border-[#e7e7e8] shadow-none'
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}
      </div>
      <Transition.Root show={deleteOk} as={Fragment}>
        <Dialog as="div" className="relative z-50 md:z-10 lg:z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-start h-full items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  transition
                  className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4
                   data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full
                    sm:max-w-[500px] sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                >
                  <span className='font-[600]'>Delete
                    <span> {selectedRowsCount} Ticket</span>
                  </span>

                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete your insights?
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-4 flex gap-2 justify-end">
                    <button
                      type="button"
                      // onClick={handleDeleteConfirm}
                      className={`bg-red-600 hover:hover:bg-red-500 focus-visible:outline-red-600 relative
                      [&>span]:flex [&>span]:items-center [&>span]:gap-[8px]`}
                    >
                      Delete
                      {deleteLoading && (
                        <div className="simple-spinner">
                          <span></span>
                        </div>
                      )}
                    </button>
                    <button
                      type="button"
                      onClick={() => setDeleteOk(false)}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>

              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );

}


export default DocumentList;