import {  DocumentResponse } from '../features/documentItemFill/documentItemFillTypes';
import { get } from '../utils/api';
import { formatUrl } from '../utils/urlFormatter';


const fetchItemMemberDetails = '/{0}/{1}/execute.items';

const credentialsConfig = {
  withCredentials: true,
};


export const fetchItemMemberTemplate = async (
  baseId: string,
  id: string,
  type?: string
): Promise<DocumentResponse> => {
  const endpoint = formatUrl(fetchItemMemberDetails, [baseId, id]);
  const urlWithParams = type ? `${endpoint}?type=${type}` : endpoint;
  return get<DocumentResponse>(urlWithParams, credentialsConfig);
};

