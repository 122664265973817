// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { Link, useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'sonner';
import { UserDetails } from '../../auth/authTypes';
import { useAppDispatch } from '../../../app/hooks';
import { fetchLocationListStatus, fetchQuestionList, updateLocation } from '../../questionnaire/questionnaireSlice';
import Badge from '../../../components/common/Badge';
import DropDown from '../../../components/common/DropDown';
import { createTeamsPayload } from '../../questionnaire/questionnaireTypes';
import { ExclamationIcon, ThreeDotIcon } from '../../../components/icons';
import CreateQuestionnaire from './CreateQuestionnaire';


type ChannelProps = {
  userDetails: UserDetails | null;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const roleSearchList = [{ name: "active" }, { name: "deleted" },
{ name: "archived" }]

const Questionnaire: React.FC<ChannelProps> = React.memo(({ }) => {
  const baseIdString = localStorage.getItem('UserBaseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [searchVal, setSearchVal] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editBoardData, setEditBoardData] = useState<{ id: number; baseId: number } | null>(null);
  const [boardsFetched, setBoardsFetched] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [dataLoading, setDataLoading] = useState(false);
  const [selectSearchRole, setSelectSearchRole] = useState("active")
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const navigate = useNavigate();


  // In Teams component
  useEffect(() => {
    const teamInfoString = localStorage.getItem('baseQuestionnaireData');
    if (teamInfoString) {
      const teamInfo = JSON.parse(teamInfoString);
      setBoardsFetched(teamInfo.data);
      setDataLoading(false);
    } else {
      const fetchTeams = async () => {
        setDataLoading(true);
        try {
          const baseResponse = await dispatch(fetchQuestionList(baseId));

          if (baseResponse?.payload) {
            setBoardsFetched(baseResponse.payload.data);
            localStorage.setItem('baseQuestionnaireData', JSON.stringify(baseResponse.payload));
          } else {
            setBoardsFetched([]);
          }
        } catch (error) {
          console.error("Error fetching teams:", error);
          setBoardsFetched([]);
        } finally {
          setDataLoading(false);
        }
      };

      if (baseId) {
        fetchTeams();
      }
    }
  }, [baseId, dispatch]);


  const handleDeleteBoard = useCallback(async (id: number, baseId: number) => {
    const payload: createTeamsPayload = {
      status: 'deleted',
      id: id
    };

    try {
      // Update existing team
      const response = await dispatch(updateLocation({
        payload,
        userId: baseId,
        id: id
      }));

      if (response?.payload) {
        toast.success(response?.payload?.message || 'Status has been updated successfully');
        // Update the local storage and state
        const updatedBoards = boardsFetched.map(board =>
          board.id === id ? { ...board, status: 'deleted' } : board
        );

        localStorage.setItem('baseQuestionnaireData', JSON.stringify({ data: updatedBoards }));

        setBoardsFetched(updatedBoards);

        setIsModalOpen(false);
        setEditBoardData(null);
      } else {
        toast.error('Failed to update status');
      }
    } catch (error) {
      toast.error('An error occurred while updating the status');
      console.error(error);
    }
  }, [boardsFetched, dispatch]);

  const handleArchivedBoard = useCallback(async (id: number, baseId: number) => {
    const payload: createTeamsPayload = {
      status: 'archived',
      id: id
    };

    try {
      // Update existing team
      const response = await dispatch(updateLocation({
        payload,
        userId: baseId,
        id: id
      }));

      if (response?.payload) {
        toast.success(response?.payload?.message || 'Status has been updated successfully');
        // Update the local storage and state
        const updatedBoards = boardsFetched.map(board =>
          board.id === id ? { ...board, status: 'archived' } : board
        );

        localStorage.setItem('baseQuestionnaireData', JSON.stringify({ data: updatedBoards }));

        setBoardsFetched(updatedBoards);

        setIsModalOpen(false);
        setEditBoardData(null);
      } else {
        toast.error('Failed to update status');
      }
    } catch (error) {
      toast.error('An error occurred while updating the status');
      console.error(error);
    }
  }, [boardsFetched, dispatch]);

  // Add a new useEffect to handle updates from localStorage
  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'baseQuestionnaireData' && e.newValue) {
        const teamsDataArray = JSON.parse(e.newValue);
        setBoardsFetched(teamsDataArray.data || []);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);


  const handleEdit = useCallback((id: number, baseId: number) => {
    const teamToEdit = boardsFetched.find(team => team.id === id);
    if (teamToEdit) {
      setEditBoardData(teamToEdit);
      setIsModalOpen(true);
    }
  }, [boardsFetched]);

  const openModal = () => {
    setEditBoardData(null);  // Add this line
    setIsModalOpen(true);
  };

  const handleTeamsData = (id: any) => {
    navigate(`/${baseId}/settings/questionnaire/${id}`);
  }

  const handleSearchRoleChange = (role: string) => {
    setSelectSearchRole(role)
  }

  const handleActive = async () => {
    setLoading1(true);
    try {
      const res = await dispatch(fetchLocationListStatus({ baseId, status: 'active' }));
      if (res.payload) {
        setBoardsFetched(res.payload.data);
        localStorage.setItem('baseQuestionnaireData', JSON.stringify(res.payload));
      } else {
        console.error('No payload received');
      }
    } catch (error) {
      console.error('Delete failed:', error);
      alert('Failed to delete location data. Please try again later.');
    } finally {
      setLoading1(false);
    }
  };


  const handleDelete = async () => {
    setLoading1(true);
    try {
      const res = await dispatch(fetchLocationListStatus({ baseId, status: 'deleted' }));
      if (res.payload) {
        setBoardsFetched(res.payload.data);
        localStorage.setItem('baseQuestionnaireData', JSON.stringify(res.payload));
      } else {
        console.error('No payload received');
      }
    } catch (error) {
      console.error('Delete failed:', error);
      alert('Failed to delete location data. Please try again later.');
    } finally {
      setLoading1(false);
    }
  };


  const handleSuspend = async () => {
    setLoading(true)
    try {
      const res = await dispatch(fetchLocationListStatus({ baseId, status: 'archived' }));
      if (res.payload) {
        setBoardsFetched(res.payload.data)
        // Update local storage
        localStorage.setItem('baseQuestionnaireData', JSON.stringify(res.payload));
      } else {
        console.error('No payload received');
      }
    } catch (error) {
      console.error('Suspend failed:', error);
    } finally {
      setLoading(false)
    }
  };

  return (
    <>
      <Toaster richColors />

      <div className=''>

        <main className='py-[34px] lg:py-[70px] px-0 lg:px-10 h-full flex'>
          <div className='px-[2px] sm:px-6 lg:px-8 w-full flex justify-center'>
            <div className='w-full max-w-[640px]'>
              <div className='flex flex-col gap-y-[6px]'>
                <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>Base Questionnaire</h2>
                <p className='text-sm leading-6 text-[#5c5c5e] font-medium'>
                  Base Questionnaire Description
                </p>
              </div>


              <div className=' mt-6 lg:mt-10 flex items-center  w-full gap-x-3'>

                <div className='w-full lg:w-[40%]'>
                  <label htmlFor='searchEmail' className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased leading-5'>
                    Questionnaire
                  </label>
                  <div className='mt-[6px]'>
                    <input
                      type="text"
                      value={searchVal}
                      onChange={(e) => setSearchVal(e.target.value)}
                      name="search"
                      id="search"
                      placeholder='Search name and email'
                      className="flex w-full grow border px-4 py-2 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
                      focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                      border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                    />
                  </div>
                </div>

                <div className='w-full lg:w-[20%]'>
                  <div className='mt-[32px]'>
                    <DropDown
                      onChangeValue={handleSearchRoleChange}
                      data={roleSearchList}
                      previousValue={selectSearchRole}
                      onDelete={handleDelete}
                      onArchived={handleSuspend}
                      onActive={handleActive}
                    />
                    {/* <DropDown onChangeValue={(value) => setSelectSearchRole(value)} data={roleSearchList} previousValue={selectSearchRole} /> */}

                  </div>
                </div>
                <div className='w-full lg:w-[40%]'>
                  <div className='mt-[32px] flex justify-end'>
                    <button
                      onClick={openModal}
                      type='button'
                      className=" inline-flex shrink-0  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                       focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Create Questionnaire
                    </button>
                  </div>
                </div>

              </div>
              <div className='mt-6 w-full'>
                {dataLoading ? (
                  <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 191px)' }}>
                    <div className="simple-spinner-loader">
                      <span></span>
                    </div>
                  </div>
                ) : boardsFetched.length === 0 ? (
                  <div className='w-full mt-5 flex h-[300px] justify-center items-center flex-col gap-y-3'>
                    <ExclamationIcon />
                    <p className='text-[#0000007a] text-[13px]'>No results found</p>
                  </div>
                ) : (
                  <>
                    {boardsFetched?.filter((post) => {
                      if (searchVal === "") {
                        return post;
                      } else if (
                        post?.questionset_name?.toLowerCase().includes(searchVal.toLowerCase()) ||
                        post?.questionset_description?.toLowerCase().includes(searchVal.toLowerCase())
                      ) {
                        return post;
                      }
                    })
                      .map((items: any, index: number) => {
                        return (
                          <div key={index} className='w-full flex border-b border-light-gray-200'>
                            <ul className='list-none w-full'>
                              <li className='flex flex-row items-center justify-between text-[0.875rem] px-[0rem] py-4 w-full'>
                                <div className='flex shrink-0 pr-0'>
                                  <span
                                    className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-none text-[0.625rem] font-medium text-white capitalize"
                                    style={{ backgroundColor: items?.questionset_colour }}
                                  >
                                    {items.questionset_name[0]}
                                  </span>
                                </div>
                                <div className='flex-1 px-4' onClick={() => handleTeamsData(items?.id)}>
                                  <div className='flex justify-start items-center gap-x-2'>
                                    <p className='text-[0.8125rem] font-medium leading-5 text-[#1b1b1b] capitalize'>{items.questionset_name}</p>
                                    {
                                      items.ai_check === 'yes' &&
                                      <span className="inline-flex capitalize items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        ai assist
                                      </span>
                                    }
                                  </div>
                                  <p className='text-[0.8125rem] font-normal leading-4 text-[#5c5c5e]'>{items?.questionset_description}</p>
                                </div>
                                <div>
                                  <Badge status={items?.status} />


                                </div>
                                <div className='px-2 flex items-center'>
                                  <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                      <MenuButton className="rounded-md flex items-center justify-center hover:bg-light-gray-200 focus:bg-light-gray-200 w-[32px] h-[32px]">
                                        <span className="sr-only">Open options</span>
                                        <ThreeDotIcon />
                                      </MenuButton>
                                    </div>

                                    <Transition
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                          <MenuItem>
                                            {({ focus }) => (
                                              <div
                                                onClick={() => handleEdit(items.id, items.base_id)}
                                                className={classNames(
                                                  focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm'
                                                )}
                                              >
                                                Edit
                                              </div>
                                            )}
                                          </MenuItem>
                                          <MenuItem>
                                            {({ focus }) => (
                                              <Link
                                                to="#"
                                                className={classNames(
                                                  focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm'
                                                )}
                                                onClick={() => handleDeleteBoard(items.id, items.base_id)}
                                              >
                                                Delete
                                              </Link>
                                            )}
                                          </MenuItem>
                                          <MenuItem>
                                            {({ focus }) => (
                                              <Link
                                                to="#"
                                                onClick={() => handleArchivedBoard(items.id, items.base_id)}
                                                className={classNames(
                                                  focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm'
                                                )}
                                              >
                                                Archived
                                              </Link>
                                            )}
                                          </MenuItem>

                                        </div>
                                      </MenuItems>
                                    </Transition>
                                  </Menu>
                                </div>
                              </li>
                            </ul>
                          </div>
                        );
                      })}
                  </>
                )}
              </div>

            </div>
          </div>
        </main>
      </div>

      <CreateQuestionnaire
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        editBoardData={editBoardData}
        baseId={baseId}
        setEditBoardData={setEditBoardData}
      />
    </>
  )
});
export default Questionnaire
