// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { format, formatDistanceToNow } from 'date-fns';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { fetchDocumentActivityItem } from '../../../features/docuementItemActivity/docuementItemActivitySlice';
import { useParams } from 'react-router-dom';
import CustomMenuDropdown from '../../../components/common/CustomMenuDropdown';
import { createItemchatData } from '../../../features/documentItemChat/documentItemChatSlice';
import { toast } from 'sonner';

interface DocumentDetailsChatProps {
    isAsideOpen3: boolean;
    itemActivityChat: any;
    itemActivityLoading: boolean;
}

interface DropdownItem {
    className?: string;
    icon?: ReactNode;
    label: string;
    shortcut?: any;
    disabled?: boolean;
    onClick?: () => void;
    href?: string; // Add href property with type string

}


interface DropdownGroup {
    options: DropdownItem[];
}


const dropdownGroups: DropdownGroup[] = [
    {
        options: [
            {
                label: `Delete`,
                // icon: CustomUserIcon,
                // className: "py-[0.375rem] px-[0.75rem] bg-white text-[#000000eb] text-[13px] hover:bg-[#e7e7e8]",
                onClick: () => console.log('Delete clicked'),
            }
        ],
    },
];

const SendDocumentDetailsChat: React.FC<DocumentDetailsChatProps> = React.memo(({ isAsideOpen3, itemActivityChat, itemActivityLoading }) => {
    const dispatch = useAppDispatch();
    const { baseId } = useParams<{ baseId: string }>();
    const { id } = useParams<{ id: string }>();
    const [chatLoading, setChatLoading] = useState(false)
    const [description, setDescription] = useState('')
    const localStroage = localStorage.getItem('userDetails')
    const userDetails = JSON.parse(localStroage)
    const [processedActivities, setProcessedActivities] = useState<any[]>([]); // Local state for processed activities
    const localStorageData = JSON.parse(localStorage.getItem('baseMembersDetails') || '{}');
    const baseMembers = localStorageData?.data?.baseMembers || [];
    const pagination = useAppSelector((state: RootState) => state.documentActivityItem.pagination);
    const [currentPage, setCurrentPage] = useState(1);


    // Modified function to remove 'about' from timeago
    const formatTimeAgo = (date: Date): string => {
        const timeAgo = formatDistanceToNow(date, { addSuffix: true });
        return timeAgo.replace(/^about\s/, '').replace(/^less than\s/, '');
      };


    const handleChatData = async () => {
        if (!description.trim()) {
            toast.error('Description is empty.');
            return;
        }
        try {
            setChatLoading(true)
            const payload = {
                base_id: Number(baseId),
                item_id: id,
                item_type: 'doc',
                activity_type: 'messaged',
                activity_message: description,
                internal_member_id: userDetails.id
            };

            // Dispatch the createItemchatData thunk
            const result = await dispatch(
                createItemchatData({
                    baseId,
                    payload,
                })
            ).unwrap();

            // Fetch updated input list
            const res = await dispatch(fetchDocumentActivityItem({
                baseId,
                id,
                activity_type: 'messaged',
                page: 1,
                limit: 25
            }));

            if (res.type.endsWith('fulfilled')) {
                const activities = res.payload.itemActivity;

                const processedData = activities.map((activity: any) => {
                    const internalMemberId = activity.internal_member_id;
                    const externalMemberId = activity.external_member_id;
                    const createdAt = new Date(activity.created_at);

                    // Determine if it's an external activity
                    const isExternal = !internalMemberId && externalMemberId;

                    // Get the name based on internal or external member ID
                    let name = '';
                    let contact = null;
                    let profilePicture = null;

                    if (internalMemberId) {
                        // Internal member
                        const memberDetails = getMemberDetails(internalMemberId);                        
                        name = memberDetails.name;
                        profilePicture = memberDetails?.profilePicture;
                    } else if (externalMemberId) {
                        // External member
                        contact = getContactDetails(externalMemberId);
                        name = contact?.contact_first_name || contact?.contact_last_name || 'Unknown User';
                    }

                    const lastName = contact?.contact_last_name || '';
                    const email = contact?.contact_email || '';
                    const firstName = contact?.contact_first_name || '';

                    // Construct the name with last name and email
                    const displayName = isExternal
                        ? `${firstName} ${lastName} [${email}]`
                        : name;

                    const message = activity.activity_message;
                    const timeAgo = formatTimeAgo(createdAt);
                    const tooltipDate = format(createdAt, 'd MMMM yyyy, h:mm a');

                    return {
                        id: internalMemberId || externalMemberId,
                        name: displayName,
                        profilePicture,
                        message,
                        timeAgo,
                        tooltipDate,
                        isExternal,
                        email
                    };
                });

                // Set the processed activities directly
                setProcessedActivities(processedData);

                // Clear the description
                setDescription('');

                toast.success('Message sent successfully');
            } else {
                throw new Error(res.error?.message || 'Failed to fetch input details');
            }
        } catch (error) {
            console.error('Failed to send message:', error);
            toast.error('Failed to send message');
        } finally {
            setChatLoading(false);
        }
    };

    useEffect(() => {
        if (itemActivityChat?.itemActivity) {
            const activities = itemActivityChat.itemActivity;
            const processedData = activities.map((activity: any) => {
                const internalMemberId = activity.internal_member_id;
                const externalMemberId = activity.external_member_id;
                const createdAt = new Date(activity.created_at);


                // Determine if it's an external activity
                const isExternal = !internalMemberId && externalMemberId;

                // Get the name based on internal or external member ID
                let name = '';
                let contact = null;
                let profilePicture = null;

                if (internalMemberId) {
                    // Internal member
                    const memberDetails = getMemberDetails(internalMemberId);                        
                    name = memberDetails.name;
                    profilePicture = memberDetails?.profilePicture;
                } else if (externalMemberId) {
                    // External member
                    contact = getContactDetails(externalMemberId);
                    name = contact?.contact_first_name || contact?.contact_last_name || 'Unknown User';
                }

                const lastName = contact?.contact_last_name || '';
                const email = contact?.contact_email || '';
                const firstName = contact?.contact_first_name || '';

                // Construct the name with last name and email
                const displayName = isExternal
                    ? `${firstName} ${lastName} [${email}]`
                    : name;

                const message = activity.activity_message;
                const timeAgo = formatTimeAgo(createdAt);
                const tooltipDate = format(createdAt, 'd MMMM yyyy, h:mm a');

                return {
                    id: internalMemberId || externalMemberId,
                    name: displayName,
                    profilePicture,
                    message,
                    timeAgo,
                    tooltipDate,
                    isExternal,
                    email
                };
            });

            // Append new activities to the existing state
            setProcessedActivities((prevActivities) => {
                // Prevent duplicate entries by checking existing IDs
                const newActivities = processedData.filter(
                    newActivity => !prevActivities.some(
                        existingActivity => existingActivity.id === newActivity.id
                    )
                );
                return [...prevActivities, ...newActivities];
            });
        }
    }, [itemActivityChat]);

    // Utility function to find member details by ID
    const getMemberDetails = (internalMemberId) => {
        const member = baseMembers.find((m) => m.id === internalMemberId);
        return {
            name: member ? member.name : 'Unknown User',
            profilePicture: member ? member.profile_pic : null,
        };
    };



    // Utility function to get contact details by external_member_id
    const getContactDetails = (externalMemberId) => {
        const activities = itemActivityChat.itemActivity;
        const activity = activities.find((activity) => activity.external_member_id === externalMemberId);
        return activity?.contact ? activity.contact : null;
    };


    const handleLoadMore = async () => {
        // Ensure we use hasNextPage from the Redux store
        if (!pagination.hasNextPage) {
            return; // Exit if there's no more data
        }
    
        // Increment page and dispatch the action to fetch the next page
        const nextPage = pagination.currentPage + 1;
    
        // Dispatch the action to fetch the next page of data
        const res = await dispatch(fetchDocumentActivityItem({
            baseId,
            id,
            activity_type: 'messaged',
            page: nextPage,
            limit: 25,
        }));
    
        // Check if the response has the required data and is an array
        const itemActivity = res?.payload?.itemActivity;
    
        if (Array.isArray(itemActivity)) {   
            const processedData = itemActivity.map((activity: any) => {
                const internalMemberId = activity.internal_member_id;
                const externalMemberId = activity.external_member_id;
                const createdAt = new Date(activity.created_at);


                // Determine if it's an external activity
                const isExternal = !internalMemberId && externalMemberId;

                let name = '';
                let contact = null;
                let profilePicture = null;

                if (internalMemberId) {
                    // Internal member
                    const memberDetails = getMemberDetails(internalMemberId);                        
                    name = memberDetails.name;
                    profilePicture = memberDetails?.profilePicture;
                } else if (externalMemberId) {
                    // External member
                    contact = getContactDetails(externalMemberId);
                    name = contact?.contact_first_name || contact?.contact_last_name || 'Unknown User';
                }

                const lastName = contact?.contact_last_name || '';
                const email = contact?.contact_email || '';
                const firstName = contact?.contact_first_name || '';

                // Construct the name with last name and email
                const displayName = isExternal
                    ? `${firstName} ${lastName} [${email}]`
                    : name;

                const message = activity.activity_message;
                const timeAgo = formatTimeAgo(createdAt);
                const tooltipDate = format(createdAt, 'd MMMM yyyy, h:mm a');

                return {
                    id: internalMemberId || externalMemberId,
                    name: displayName,
                    profilePicture,
                    message,
                    timeAgo,
                    tooltipDate,
                    isExternal,
                    email
                };
            });

            setCurrentPage(nextPage);    
            // Update the processed activities state by appending new data
            setProcessedActivities((prevActivities) => {
                return [...processedData, ...prevActivities];
            });
    
        } else {
            console.error("itemActivity is not an array:", itemActivity);
        }
    };
    

    return (
        <div
            className={`main-feedback-side flex flex-col min-w-[200px] bg-white border-t-0 border border-light-gray-200 
                border-solid absolute  lg:fixed top-[60px] max-w-[80%] view-feedback-right-side border-r-0 ' `}
            style={{
                width: isAsideOpen3 ? '350px' : '350px',
                transform: isAsideOpen3 ? 'none' : 'none',
                right: isAsideOpen3 ? '51px' : '0px',
                marginRight: isAsideOpen3 ? '0px' : '-350px',

                height: isAsideOpen3 ? 'calc(100vh - +60px)' : '',
                ...(window.innerWidth <= 768 && {
                    width: isAsideOpen3 ? '300px' : '300px',
                    marginRight: isAsideOpen3 ? '0' : '-300px',
                }),
            }}
        >
            <div className='h-full'>
                <div className='h-auto flex flex-col justify-stretch min-h-[3rem] 
               border-b-0 border-l-0 border-solid border-light-gray-200 p-[1rem] pt-[0.9rem] items-start gap-4'>
                    <div className=" flex items-start justify-center w-full">
                        <div className="mx-auto w-full">
                            <p className="text-center text-wedges-gray text-sm" />
                            <div className="flex items-center max-w-full w-fit mb-5 font-[500] leading-[1.2] bg-light-gray-100 rounded-full text-[0.75rem] px-[0.5rem] ml-[-4px] py-[5px] gap-2">
                                <span className="text-[#52555a] capitalize">Chat</span>
                            </div>
                            <>
                                {/* Chat Container */}
                                <div
                                    style={{ minWidth: "100%", display: "table", height: 'calc(100vh - 250px)' }}
                                >
                                    {/* Chat Message AI */}
                                    {(pagination.hasMore || processedActivities.length >= 25) && (
                                <button
                                    onClick={handleLoadMore}
                                    disabled={itemActivityLoading}
                                    className='inline-flex items-center rounded-md 
          text-xs font-medium text-yellow-800 mt-1'
                                >
                                    {itemActivityLoading ? 'Loading...' : 'Load More'}
                                </button>
                            )}

                                    {itemActivityLoading ? (
                                        <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 120px)' }}>
                                            <div className="simple-spinner-loader">
                                                <span className='!w-6 !h-6'></span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="mb-2 ">
                                            <ul role="list" className="space-y-4 mt-[0.9rem]">
                                                {processedActivities.map((activity, index) => (                                                   
                                                    <div className="flex gap-1.5 mb-4 text-gray-600 text-sm flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200 justify-between feedback-delete-hover">
                                                        <div className=''>
                                                         
                                                            <div className='flex items-center gap-2'>
                                                                <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                                                    <span className="text-[7px] font-medium text-gray-700" />
                                                                    <div className="relative flex-none w-5 h-5 rounded-full bg-gray-200 text-[10px] flex items-center justify-center">
                                                                        {activity?.profilePicture ? (
                                                                            <img
                                                                                src={`${process.env.REACT_APP_API_BASE_URL}/${activity.profilePicture}`}
                                                                                alt={activity?.name || "Profile Picture"}
                                                                                className="w-full h-full rounded-full object-cover"
                                                                            />
                                                                        ) : (
                                                                            <span className="text-[9px] font-medium text-gray-700">
                                                                                {activity?.name ? activity.name.charAt(0).toUpperCase() : ''}
                                                                            </span>
                                                                        )}
                                                                    </div>

                                                                </div>
                                                                <p className="leading-relaxed">
                                                                    <span className=" text-dark-black-100 mr-1  capitalize leading-4 flex items-center text-[12px] font-medium">
                                                                        {activity.name}
                                                                        <div className="flex items-center gap-[6px] ml-[6px]">
                                                                            <span
                                                                                className="flex h-[4px] w-[4px] shrink-0 items-center justify-center rounded-full
                            border-none text-[0.625rem] font-normal text-white capitalize bg-[#6b7280]"
                                                                            />
                                                                            <time
                                                                                data-title={activity.tooltipDate}

                                                                                className=" table-tooltip before:!bottom-[20px]  flex-none text-xs leading-5 text-gray-500"
                                                                            >
                                                                                {activity.timeAgo}
                                                                            </time>
                                                                        </div>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <span className='text-sm leading-6 text-gray-500 capitalize mt-[2px] block'>
                                                                {activity.message}
                                                            </span>
                                                        </div>
                                                        <div className='[&>div]:shrink feedback-delete-btn-hover'>
                                                            <CustomMenuDropdown
                                                                icon={
                                                                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                        <circle cx="12" cy="12" r="1"></circle>
                                                                        <circle cx="12" cy="5" r="1"></circle>
                                                                        <circle cx="12" cy="19" r="1"></circle>
                                                                    </svg>
                                                                }
                                                                altText="User profile"
                                                                sections={dropdownGroups}
                                                                menuItemsClassName="left-auto"
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </ul>

                                        </div>

                                    )}



                                </div>
                                {/* Input box  */}
                                <div className="flex items-end flex-col gap-y-4 pt-2">
                                    <textarea
                                        id='description'
                                        name='description'
                                        type='description'
                                        rows={2}
                                        maxLength={1500}
                                        autoComplete='description'
                                        onChange={(e) => setDescription(e.target.value)}

                                        placeholder='Enter Description'
                                        value={description}
                                        className="flex grow rounded-lg border border-[#e5e7eb] bg-background px-4 py-[5px] text-sm leading-6 shadow-wg-xs transition-colors duration-100 placeholder:text-surface-500
				               outline-primary focus:outline focus:outline-2 focus:-outline-offset-2 w-full
				                text-surface-900 hover:border-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100 focus:outline-indigo-600"
                                    />
                                    <button
                                        onClick={handleChatData}
                                        className=" w-fit justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm
                     hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center gap-2"
                                    >
                                        {chatLoading ? (
                                            <>
                                                Send
                                                <div className="simple-spinner">
                                                    <span></span>
                                                </div>
                                            </>
                                        ) : (
                                            'Send'
                                        )}
                                    </button>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default SendDocumentDetailsChat;
