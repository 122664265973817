// src/services/baseService.ts
import { BaseResponse, CreateBasePayload, UpdateBasePayload, UploadBaseCoverPayload, baseMemberInvitePayload } from '../features/base/baseTypes';
import { del, get, post, put } from '../utils/api';
import { formatUrl } from '../utils/urlFormatter';

// Endpoint paths
const base_list = '/base';
const create_base = '/base';
const update_base = '/base/{0}';
const upload_base_cover = 'base/{0}/add/cover';
const upload_base_logo = 'base/{0}/logo';
const base_details = '/base/{0}/baseDetail';
const base_member = '/base/{0}/settings/members';
const base_member_invite_url = '/base/{0}/invite';
const base_member_invitation_status = '/base/{0}/invitations';
const base_member_remove = '/base/{0}/invitation/{1}';
const base_invite_data = '/base/invite';
const base_invite_accept = '/base/invite/{0}';
const base_invite_reject = '/base/invite/{0}';
const base_member_role_change = '/base/{0}/member/{1}'
const del_base_member_status = '/base/{0}/member/{1}/{2}';
const switch_base_member = '/base/{0}/switch' 


const credentialsConfig = {
  withCredentials: true,
};


export const fetchBaseData = async (): Promise<BaseResponse> => {
  return get<BaseResponse>(base_list, credentialsConfig);
};

export const createBaseData = async (data: CreateBasePayload): Promise<BaseResponse> => {
  return post<BaseResponse>(create_base, data, credentialsConfig);
};

export const updateBaseData = async (data: UpdateBasePayload, userId: number): Promise<BaseResponse> => {
  const update_base_url = formatUrl(update_base, [userId]);
  return put<BaseResponse>(update_base_url, data, credentialsConfig);
};


export const uploadBaseCoverData = async (data: UploadBaseCoverPayload, userId: number): Promise<BaseResponse> => {
  const update_base_url = formatUrl(upload_base_cover, [userId]);
  const formData = new FormData();
  formData.append('image', data.image);
  return post<BaseResponse>(update_base_url, formData, {
    ...credentialsConfig,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};


export const uploadBaseLogoData = async (data: UploadBaseCoverPayload, userId: number): Promise<BaseResponse> => {
  const update_base_url = formatUrl(upload_base_logo, [userId]);
  const formData = new FormData();
  formData.append('image', data.image);
  return post<BaseResponse>(update_base_url, formData, {
    ...credentialsConfig,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};


export const fetchBaseDetailsData = async (userId: number | undefined): Promise<BaseResponse> => {
  if (userId === undefined) {
    throw new Error('User ID is undefined');
  }
  const update_base_url = formatUrl(base_details, [userId]);
  return get<BaseResponse>(update_base_url, credentialsConfig);
};


export const fetchBaseMemberData = async (userId: number): Promise<BaseResponse> => {
  const update_base_member = formatUrl(base_member, [userId]);
  return get<BaseResponse>(update_base_member, credentialsConfig);
};

export const fetchBaseMemberData1 = async (userId: number, status?: string): Promise<BaseResponse> => {
  let update_base_member = formatUrl(base_member, [userId]);

  if (status) {
    update_base_member += `?status=${status}`;
  }

  return get<BaseResponse>(update_base_member, credentialsConfig);
};



export const baseMemberInvite = async (data: baseMemberInvitePayload, userId: number): Promise<BaseResponse> => {
  const base_member_invite = formatUrl(base_member_invite_url, [userId]);
  return post<BaseResponse>(base_member_invite, data, credentialsConfig);
};

export const fetchBaseMemberInvitationStatus = async (baseId: number): Promise<BaseResponse> => {
  const base_invitation_status = formatUrl(base_member_invitation_status, [baseId]);
  return get<BaseResponse>(base_invitation_status, credentialsConfig);
};

export const deleteMemberInvitation = async (baseId: number, invitationId: number): Promise<BaseResponse> => {
  const formattedUrl = formatUrl(base_member_remove, [baseId, invitationId]);
  return del<BaseResponse>(formattedUrl, credentialsConfig);
};

export const fetchBaseInvite = async (): Promise<BaseResponse> => {
  return get<BaseResponse>(base_invite_data, credentialsConfig); 
};

export const updateBaseInvitationAccept = async (baseId: number): Promise<BaseResponse> => {
  const invite_accept_base_url = formatUrl(base_invite_accept, [baseId]);
  // Send an empty object as payload
  return put<BaseResponse>(invite_accept_base_url, {}, credentialsConfig);
};


export const deleteBaseInvitationReject = async (baseId: number): Promise<BaseResponse> => {
  const invite_reject_base_url = formatUrl(base_invite_reject, [baseId]);
  // Send an empty object as payload
  return del<BaseResponse>(invite_reject_base_url, credentialsConfig);
};

export const updateBaseMemberRole = async (baseId: number, memberId: number, role: string): Promise<BaseResponse> => {
  const base_member_role_url = formatUrl(base_member_role_change, [baseId, memberId]);
  return put<BaseResponse>(base_member_role_url, { role }, credentialsConfig);
};

// delete base member status API function
export const deleteBaseMemberStatus = async (baseId: number, memberId: number, status: string): Promise<BaseResponse> => {
  const formattedUrl = formatUrl(del_base_member_status, [baseId, memberId, status]);
  return del<BaseResponse>(formattedUrl, credentialsConfig);
};

// swithc base
export const fetcSwitchBaseMemberData = async (userId: number): Promise<BaseResponse> => {
  const update_base_member = formatUrl(switch_base_member, [userId]);
  return get<BaseResponse>(update_base_member, credentialsConfig);
};

