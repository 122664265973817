import { InputItem, InputResponse, UpsertItemInputDto } from '../features/documentInput/documentInputTypes';
import { post, get, del } from '../utils/api';
import { formatUrl } from '../utils/urlFormatter';

const updateInputEndpoint = '/{0}/inputItem.createOrUpdate';
const fetchInputEndpoint = '/{0}/{1}/itemInput.list';
const deleteInputEndpoint = '/{0}/{1}/itemInput.delete';
const inputItemAddValue = '/{0}/{1}/inputItem.addValue'

const credentialsConfig = {
  withCredentials: true,
};

export const fetcInputnData = async (
  baseId: number,  // Add baseId as a separate parameter
  payload: UpsertItemInputDto
): Promise<InputResponse> => {
  const fetchInputUrl = formatUrl(updateInputEndpoint, [baseId]);  // Use baseId here
  return post(fetchInputUrl, [payload], credentialsConfig);
};


export const fetchDocumentTemplate = async (baseId: number, id: string): Promise<InputItem> => {
  const endpoint = formatUrl(fetchInputEndpoint, [baseId, id]);
  return get<InputItem>(endpoint, credentialsConfig);
};

export const deleteInputTemplate = async (baseId: number, inputId: string): Promise<InputItem> => {
  const endpoint = formatUrl(deleteInputEndpoint, [baseId, inputId]);
  return del<InputItem>(endpoint, credentialsConfig);
};

export const addValueInputTemplate = async (baseId: number, inputId: string, payload: UpsertItemInputDto): Promise<InputItem> => {
  const endpoint = formatUrl(inputItemAddValue, [baseId, inputId]);
  return post(endpoint, payload, credentialsConfig);
};