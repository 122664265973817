// @ts-nocheck

import React, { useEffect, useState, useCallback, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DropDown from '../../../components/common/DropDown'
import Badge from '../../../components/common/Badge'
import { RootState } from '../../../app/store'
import { ApiResponse, MemberData } from '../../base/baseTypes'
import { baseMemberDetailsData, baseMemberDetailsDataStatus1, baseMemberInviteData, deleteBaseMemberStatusData, updateBaseMemberRoleChnage } from '../../base/baseSlice'
import { useAppDispatch } from '../../../app/hooks'
import { Toaster, toast } from 'sonner';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../../components/common/LoadingSpinner'
import { ThreeDotIcon } from '../../../components/icons'
import { CrossIcon, ExclamationIcon } from '../../../components/icons/Icons'

const roleList = [{ name: "admin" }, { name: "manager" }, { name: "member" }]
const roleSearchList = [{ name: "all" }, { name: "admin" }, { name: "manager" }, { name: "member" }, { name: "pending" }, { name: "deleted" },
{ name: "suspended" }]

interface MemberProps {
  userDetails: UserDetails | null;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const BaseMember: React.FC<MemberProps> = React.memo(({ userDetails }) => {
  const [selectRole, setSelectRole] = useState<any>("admin")
  const [selectSearchRole, setSelectSearchRole] = useState("all")

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [data, setData] = useState<any>()
  const [memberData, setMemberData] = useState<MemberData[]>([]);

  const dispatch = useAppDispatch();
  const { error } = useSelector((state: RootState) => state.base);
  const baseIdString = localStorage.getItem('UserBaseId') || localStorage.getItem('createBaseId');
  const baseId = baseIdString ? JSON.parse(baseIdString) : null;
  const [addBaseLoading, setAddBaseLoading] = useState(false)
  const [addToBase, setAddToBase] = useState(false)
  const [filteredMemberData, setFilteredMemberData] = useState<MemberData[]>([])
  const [searchEmail, setSearchEmail] = useState("")
  const [userRole, setUserRole] = useState<string | null>(null);
  const baseUserInfoString1 = localStorage.getItem('userDetails')
  const UserBaseId = baseUserInfoString1 ? JSON.parse(baseUserInfoString1) : null;
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [loading2, setLoading2] = useState(false)

  useEffect(() => {

  }, []);

  useEffect(() => {
    if (baseId) {
      const fetchData = async () => {
        const baseInfo = localStorage.getItem('baseMembersDetails');
        if (baseInfo) {
          const memberDataArray: ApiResponse = JSON.parse(baseInfo);
          setMemberData(memberDataArray.data.baseMembers);
          setFilteredMemberData(memberDataArray.data.baseMembers)
          localStorage.setItem('baseMembersDetails', JSON.stringify(memberDataArray));
        }
        else {
          try {
            const baseResponse = await dispatch(baseMemberDetailsData(userDetails?.id || baseId));
            console.log(baseResponse,"baseResponsebaseResponse");
            setMemberData(baseResponse.payload.data.baseMembers);
            setFilteredMemberData(baseResponse.payload.data.baseMembers)
            localStorage.setItem('baseMembersDetails', JSON.stringify(baseResponse.payload));
          } catch (error) {
            console.error("Error fetching base member details:", error);
          }
        }
      };
      fetchData();
    }
  }, [baseId, dispatch]);


  useEffect(() => {
    filterMembers()
  }, [searchEmail, selectSearchRole, memberData])


  useEffect(() => {
    const storedMemberData = localStorage.getItem('baseMembersDetails');


    if (storedMemberData) {
      const parsedData = JSON.parse(storedMemberData); // Parse the storedMemberData
      setMemberData(parsedData.data.baseMembers); // Access the data.baseMembers property
    }
  }, []);


  useEffect(() => {
    return () => {
      //   dispatch(clearBaseInfo());
    };
  }, [dispatch]);

  useEffect(() => {
    const baseInfo = localStorage.getItem('baseMembersDetails');
    const baseUserInfoString = localStorage.getItem('userDetails');

    if (baseInfo && baseUserInfoString) {
      const memberDataArray = JSON.parse(baseInfo);
      const baseUserInfo = JSON.parse(baseUserInfoString);

      setMemberData(memberDataArray.data.baseMembers);
      const matchingMember = memberDataArray.data.baseMembers.find(
        (member: MemberData) => member.id === baseUserInfo.id
      );

      if (matchingMember) {
        setUserRole(matchingMember.role);
      }
    }
  }, []);


  // delete 
  const handleDeleteBoard = useCallback(async (id: number, status: string) => {
    try {
      const res = await dispatch(deleteBaseMemberStatusData({ baseId, memberId: id, status }));

      if (res?.payload?.message) {
        toast.success(res.payload.message);
        const baseMembersData = localStorage.getItem('baseMembersDetails');

        if (baseMembersData) {
          const parsedBaseMembersData = JSON.parse(baseMembersData);

          const updatedMembers = parsedBaseMembersData.data.baseMembers.map((member: any) => {
            if (member.id === id) {
              return { ...member, status: status };
            }
            return member;
          });

          localStorage.setItem('baseMembersDetails', JSON.stringify({
            ...parsedBaseMembersData,
            data: {
              ...parsedBaseMembersData.data,
              baseMembers: updatedMembers,
            },
          }));
          setMemberData(updatedMembers);
          setFilteredMemberData(updatedMembers);
        }
      }
    } catch (error) {
      toast.error('Error deleting item!');
    }
  }, [dispatch, baseId]);

  // suspened board
  const handleSuspendedBoard = useCallback(async (id: number, status: string) => {
    try {
      const res = await dispatch(deleteBaseMemberStatusData({ baseId, memberId: id, status }));

      if (res?.payload?.message) {
        toast.success(res.payload.message);
        const baseMembersData = localStorage.getItem('baseMembersDetails');

        if (baseMembersData) {
          const parsedBaseMembersData = JSON.parse(baseMembersData);

          const updatedMembers = parsedBaseMembersData.data.baseMembers.map((member: any) => {
            if (member.id === id) {
              return { ...member, status: status };
            }
            return member;
          });

          localStorage.setItem('baseMembersDetails', JSON.stringify({
            ...parsedBaseMembersData,
            data: {
              ...parsedBaseMembersData.data,
              baseMembers: updatedMembers,
            },
          }));
          setMemberData(updatedMembers);
          setFilteredMemberData(updatedMembers);
        }
      }
    } catch (error) {
      toast.error('Error deleting item!');
    }
  }, [dispatch, baseId]);


  const handleDelete = async () => {
    setLoading1(true)
    try {
      const res = await dispatch(baseMemberDetailsDataStatus1({ userId: baseId, status: 'deleted' }));
      if (res.payload) {
        setFilteredMemberData(res.payload.data.baseMembers)
        // Update local storage
        localStorage.setItem('baseMembersDetails', JSON.stringify(res.payload));
      } else {
        console.error('No payload received');
      }
    } catch (error) {
      console.error('Delete failed:', error);
    } finally {
      setLoading1(false)
    }
  };

  const handleSuspend = async () => {
    setLoading(true)
    try {
      const res = await dispatch(baseMemberDetailsDataStatus1({ userId: baseId, status: 'suspended' }));
      if (res.payload) {
        setFilteredMemberData(res.payload.data.baseMembers)
        // Update local storage
        localStorage.setItem('baseMembersDetails', JSON.stringify(res.payload));
      } else {
        console.error('No payload received');
      }
    } catch (error) {
      console.error('Suspend failed:', error);
    } finally {
      setLoading(false)
    }
  };

  const handlePending = async () => {
    setLoading2(true)
    try {
      const res = await dispatch(baseMemberDetailsDataStatus1({ userId: baseId, status: 'pending' }));
      if (res.payload) {
        setFilteredMemberData(res.payload.data.baseMembers)
        // Update local storage
        localStorage.setItem('baseMembersDetails', JSON.stringify(res.payload));
      } else {
        console.error('No payload received');
      }
    } catch (error) {
      console.error('Delete failed:', error);
    } finally {
      setLoading2(false)
    }
  };


  if (error) {
    return <div>Error: {error}</div>;
  }


  const add_baseHandler = () => {
    setAddBaseLoading(true)
    const userId = baseId
    if (email.length === 0) {
      setEmailError("Enter your email address");
      setAddBaseLoading(false)
    } else {
      dispatch(baseMemberInviteData({
        payload: { email, role: selectRole }, // Changed 'selectRole' to 'role'
        userId
      })).unwrap()
        .then((res) => {
          setData(res);
          toast.success(res?.message);
          setAddToBase(false)
        })
        .catch((err) => {
          setAddBaseLoading(false)
        })
        .finally(() => {
          setAddBaseLoading(false); // End loading
        });
    }
  };

  const handleAddMember = () => {
    setAddToBase(true)
  }



  // local search 

  const filterMembers = () => {
    let filtered = memberData

    if (searchEmail) {
      filtered = filtered.filter(member =>
        member.email.toLowerCase().includes(searchEmail.toLowerCase())
      )
    }

    if (selectSearchRole !== "all") {
      filtered = filtered.filter(member =>
        member.role.toLowerCase() === selectSearchRole.toLowerCase() ||
        (selectSearchRole === "pending" && member.status === "pending")
      )
    }

    setFilteredMemberData(filtered)
  }

  const handleSearchEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchEmail(e.target.value)
  }

  const handleSearchRoleChange = (role: string) => {
    setSelectSearchRole(role)
  }


  const handleRoleChange = async (userId: number, newRole: string) => {
    const isChangingSelfRole = userDetails?.id === userId;

    if (isChangingSelfRole && userRole === 'admin') {
      // toast.error("Administrators cannot change their own role");
      return;
    }

    if (baseId && userId && newRole) {
      try {
        const response = await dispatch(updateBaseMemberRoleChnage({
          baseId,
          userId,
          roleId: newRole
        }));

        if (response.meta.requestStatus === 'fulfilled') {
          toast.success("Role updated successfully");
          setMemberData(prevData =>
            prevData.map(member =>
              member.id === userId ? { ...member, role: newRole } : member
            )
          );

          // Update filtered data if it exists
          setFilteredMemberData(prevData =>
            prevData.map(member =>
              member.id === userId ? { ...member, role: newRole } : member
            )
          );

          // Update local storage
          const baseMemberDetailsString = localStorage.getItem('baseMembersDetails');
          if (baseMemberDetailsString) {
            const baseMemberDetails = JSON.parse(baseMemberDetailsString);
            baseMemberDetails.data.baseMembers = baseMemberDetails.data.baseMembers.map(
              (member: any) => member.id === userId
                ? { ...member, role: newRole }
                : member
            );
            localStorage.setItem('baseMembersDetails', JSON.stringify(baseMemberDetails));
          }
          if (isChangingSelfRole) {
            setUserRole(newRole);
          }

        } else {
          toast.error("Failed to update role");
        }
      } catch (error) {
        console.error("Error updating member role:", error);
        toast.error("Error updating role");
      }
    }
  };

  // Modified DropDown rendering in the member list
  const renderRoleDropdown = (member: MemberData) => {

    const isCurrentUserAdmin = userRole === 'admin';
    const isChangingSelf = UserBaseId?.id === member.id;


    // If current user is admin and trying to change their own role, disable the dropdown
    const isDisabled = isCurrentUserAdmin && isChangingSelf;

    return (
      <div className={`sm:col-span-3 ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
        <DropDown
          onChangeValue={(newRole) => handleRoleChange(member.id, newRole)}
          data={roleList.map(role => ({ name: role.name }))}
          previousValue={member.role}
          disabled={isDisabled}
        />
      </div>
    );
  };


  return (
    <main className='h-full flex'>
      <div className=' w-full flex justify-center'>
        <div className='w-full'>
          <div className='flex flex-col gap-y-[6px]'>

            <h2 className='text-[20px] lg:text-[24px] font-medium leading-8 text-[#1b1b1b] tracking-[-0.01rem]'>Personal Information</h2>
            <p className='text-sm leading-6 text-[#5c5c5e] font-medium'>
              Use a permanent address where you can receive mail.
            </p>

          </div>
          <div className='bg-[#e0e0e0] w-full h-[1px] my-6'></div>
          <div className=' mt-6 lg:mt-10 flex items-center  w-full gap-x-3'>

            <div className='w-full lg:w-[40%]'>
              <label htmlFor='searchEmail' className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased leading-5'>
                Base Members
              </label>
              <div className='mt-[6px]'>
                <input
                  id='searchEmail'
                  name='searchEmail'
                  type='text'
                  placeholder='Search by name'
                  className="flex w-full grow border px-4 py-2 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
                    focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                    border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                />
              </div>
            </div>

            <div className='w-full lg:w-[20%]'>
              <div className='mt-[32px]'>
                <DropDown
                  onChangeValue={handleSearchRoleChange}
                  data={roleSearchList}
                  previousValue={selectSearchRole}
                  onDelete={handleDelete}
                  onSuspend={handleSuspend}
                  onPending={handlePending}
                />
                {/* <DropDown onChangeValue={(value) => setSelectSearchRole(value)} data={roleSearchList} previousValue={selectSearchRole} /> */}

              </div>
            </div>
            <div className='w-full lg:w-[40%]'>
              <div className='mt-[32px] flex justify-end'>
                <button
                  onClick={handleAddMember}
                  className='flex w-fit justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white
             shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                >
                  Add Base Member
                </button>
              </div>

            </div>
          </div>


          {/* {filteredMemberData.length > 0 && ( */}

          {loading || loading1 ? (
            <div className="flex items-center justify-center relative z-50" style={{ height: 'calc(100vh - 291px)' }}>
              <div className="simple-spinner-loader">
                <span></span>
              </div>
            </div>
          ) : filteredMemberData.length === 0 ? (
            <div className='w-full mt-5 flex h-[300px] justify-center items-center flex-col gap-y-3'>
              <ExclamationIcon />
              <p className='text-[#0000007a] text-[13px]'>No results found</p>
            </div>
          ) : (
            <ul
              role="list"
              className="mt-8 divide-y divide-gray-100 bg-white rounded-md border-solid border-[1px] border-[#d1d1db] shadow-none
    hover:ring-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100 focus:outline-indigo-600"
            >
              {filteredMemberData.map((items: any, index) => {
                const data = items?.name?.charAt(0).toUpperCase();
                return (
                  <li
                    key={index}
                    className="flex justify-between items-center gap-x-6 p-2 flex-wrap lg:flex-nowrap"
                  >
                    <div className="flex min-w-0 gap-x-3 items-center">
                      {items.profile_pic ? (
                        <img
                          src={`${process.env.REACT_APP_API_BASE_URL}/${items.profile_pic}`}
                          alt="Selected file preview"
                          className="h-12 w-12 flex-none rounded-full text-gray-300"
                        />
                      ) : (
                        <div className="flex shrink-0">
                          <span
                            className="flex h-12 w-12 shrink-0 items-center justify-center rounded-full border-none text-[13px] font-medium text-white capitalize"
                            style={{ backgroundColor: "rgb(107, 114, 128)" }}
                          >
                            {data}
                          </span>
                        </div>
                      )}
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-4 text-gray-900 capitalize">
                          {items.name}
                          <Badge status={items?.status} />
                        </p>
                        <p className="flex text-xs leading-5 text-gray-500">{items?.email}</p>
                      </div>
                    </div>
                    <div className="flex items-center justify-end">
                      <div className="sm:col-span-3">
                        {renderRoleDropdown(items)}
                      </div>

                      {userRole === "admin" && (
                        <div className="px-2 flex items-center">
                          {!(UserBaseId?.id === items.id) && (
                            <Menu as="div" className="relative inline-block text-left">
                              <div>
                                <MenuButton className="rounded-md flex items-center justify-center hover:bg-light-gray-200 focus:bg-light-gray-200 w-[32px] h-[32px]">
                                  <span className="sr-only">Open options</span>
                                  <ThreeDotIcon />
                                </MenuButton>
                              </div>
                              <Transition
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <MenuItem>
                                      {({ focus }) => (
                                        <Link
                                          to="#"
                                          className={classNames(
                                            focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                            "block px-4 py-2 text-sm"
                                          )}
                                          onClick={() => handleDeleteBoard(items.id, "deleted")}
                                        >
                                          Delete
                                        </Link>
                                      )}
                                    </MenuItem>
                                    <MenuItem>
                                      {({ focus }) => (
                                        <Link
                                          to="#"
                                          className={classNames(
                                            focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                            "block px-4 py-2 text-sm"
                                          )}
                                          onClick={() => handleSuspendedBoard(items.id, "suspended")}
                                        >
                                          Suspended
                                        </Link>
                                      )}
                                    </MenuItem>
                                  </div>
                                </MenuItems>
                              </Transition>
                            </Menu>
                          )}
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}


          {/* )} */}

        </div>


      </div>
      {/* <AlertPopup data={data} show={showAlert} setShow={() => setShowAlert(false)} /> */}
      {/* <SliderOvers setData={(e: any) => setData(e)} memberData={memberData} memberId={memberId} baseId={baseId} show={showSlider} setShow={() => setShowSlider(false)} setShowAlert={() => setShowAlert(true)} /> */}




      <Dialog open={addToBase} onClose={setAddToBase} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  onClick={() => setAddToBase(false)}
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">Close</span>
                  <CrossIcon />
                </button>
              </div>
              <div className="flex flex-col gay-y-4">
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  Add Member
                </DialogTitle>

                <div className='w-full mt-5'>
                  <label htmlFor='email' className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5'>
                    Email
                  </label>
                  <div className='mt-[6px]'>
                    <input
                      id='email'
                      name='email'
                      type='email'
                      value={email}
                      onChange={(e) => {
                        setEmailError("");
                        setEmail(e.target.value)
                      }}
                      autoComplete='email'
                      placeholder='Email address'
                      className="flex w-full grow border px-4 py-2 text-sm leading-6 shadow-[0 1px 2px 0 rgba(18, 18, 23, 0.05)] transition-colors duration-100 placeholder:text-surface-500 outline-indigo-600
                    focus:outline focus:outline-2 focus:-outline-offset-2 bg-background text-text-100 hover:border-[#a9a9bc] dark:hover:border-surface-200
                    border-[#d1d1db] dark:border-surface-100 focus:outline-indigo-600 h-[36px] rounded-[0.375rem]"
                    />
                    <p className="mt-[6px] text-sm text-red-600" id="email-error">
                      {emailError}
                    </p>

                  </div>
                </div>
                <div className='w-full mt-4'>
                  <label htmlFor='country' className='wg-label inline-flex shrink-0 items-center gap-1 text-[14px] text-[#2f2f31] wg-antialiased  leading-5'>
                    Role
                  </label>
                  <div className='mt-[6px]'>
                    <DropDown onChangeValue={(e: any) => setSelectRole(e)} data={roleList} previousValue={selectRole} />
                  </div>
                </div>

              </div>
              <div className="flex gap-x-4 items-center justify-end mt-5">
                <button
                  onClick={add_baseHandler}
                  type="submit"
                  className="flex w-fit justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"

                >
                  {addBaseLoading ? (
                    <div className='flex items-center  gap-[6px]'>
                      Add to Base
                      <div className="simple-spinner">
                        <span></span>
                      </div>
                    </div>
                  ) : (
                    'Add to Base'
                  )}
                </button>

              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>






    </main>

  )
});
export default BaseMember
