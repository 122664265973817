import { CreateDocumentActionPayload, DocumentResponse } from '../features/documentItem/documentItemTypes';
import { get, post } from '../utils/api';
import { formatUrl } from '../utils/urlFormatter';


const fetchDocumentActivityEndpoint = '/{0}/{1}/itemActivity.list';


const credentialsConfig = {
  withCredentials: true,
};

  

export const fetchDocumentActivityTemplate = async (
    baseId: string,
    id: string,
    activity_type?: string,
    page: number = 1,
    limit: number = 25
  ): Promise<DocumentResponse> => {
    const endpoint = formatUrl(fetchDocumentActivityEndpoint, [baseId, id]);
    
    // Construct query parameters
    const queryParams = new URLSearchParams();
    if (activity_type) queryParams.append('activity_type', activity_type);
    queryParams.append('page', page.toString());
    queryParams.append('limit', limit.toString());
    
    const urlWithParams = `${endpoint}?${queryParams.toString()}`;
    return get<DocumentResponse>(urlWithParams, credentialsConfig);
  };